// eslint-disable-next-line no-unused-vars
import {
  getCbbSites,
  getSiteList,
  addNewSite,
  getCbbUserList,
  getDetailSite,
  updateSite,
  deleteSite,
  getCbbSitesByAdminSite,
  getUserSiteList,
  updateCurrentSite,
  getCbbDatabase,
  saveChannel,
  getListChannel,
  deleteChannel,
  getLastValueWaterLevelValueRealtime,
  saveWaterLevelValueRealtime
} from "@/api/sites";

const actions = {
    getSiteList({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getSiteList(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getSiteDetail({ commit, state }, siteId) {
        return new Promise((resolve, reject) => {
            getDetailSite(siteId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    addNewSite({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            addNewSite(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbSites({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            getCbbSites(data)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbSitesByAdminSite({ commit, state }) {
        return new Promise((resolve, reject) => {
            getCbbSitesByAdminSite()
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getUserSiteList({ commit, state }) {
        return new Promise((resolve, reject) => {
            getUserSiteList()
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbUserList({ commit, state }, userType) {
        return new Promise((resolve, reject) => {
            getCbbUserList(userType)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateSite({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            updateSite(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteSite({ commit, state }, roleId) {
        return new Promise((resolve, reject) => {
            deleteSite(roleId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
  updateCurrentSite({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateCurrentSite(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCbbDatabase({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        getCbbDatabase(payload)
            .then(response => {
                const data = response.data;

                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
saveChannel({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        saveChannel(payload)
            .then(response => {
                const data = response.data;

                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
getListChannel({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        getListChannel(payload)
            .then(response => {
                const data = response.data;
                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
deleteChannel({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        deleteChannel(payload)
            .then(response => {
                const data = response.data;
                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
getLastValueWaterLevelValueRealtime({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        getLastValueWaterLevelValueRealtime(payload)
            .then(response => {
                const data = response.data;
                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
saveWaterLevelValueRealtime({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
        saveWaterLevelValueRealtime(payload)
            .then(response => {
                const data = response.data;
                resolve(data); // resolve
            })
            .catch(error => {
                reject(error);
            });
    });
},
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

