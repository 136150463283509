<template>
  <v-dialog v-model="showDialog" style='z-index:1035;' max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5"> {{ $t(title) }}</span>
        <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-container style="margin-top:10px !important;" class="pt-0 pb-0">
            <div class="row">
              <div class="col-12">
                <h6 class="mb-1">{{ $t("labels.roleManager.roleName") }}<i class="text-danger">{{
                    !checkDetail ? ' *' : ''
                  }}</i></h6>
                <v-text-field v-model.trim="roleInfo.name" :disabled="checkDetail" hide-details="auto" outlined
                              maxlength="40" :rules="roleNameRule" required dense></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-0">
                <h6 class="mb-1">{{ $t("labels.roleManager.description") }}</h6>
                <v-textarea v-model.trim="roleInfo.description" :disabled="checkDetail" auto-grow outlined
                            maxlength="100" rows="3" :rules="descriptionRules" row-height="15">
                </v-textarea>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12 pt-0">
                <h6 class="mb-1">{{ $t("labels.roleManager.permissions") }}<i
                  class="text-danger">{{ !checkDetail ? ' *' : '' }}</i></h6>
                <v-card>
                  <!-- <v-treeview shaped hoverable activatable :items="items"></v-treeview> -->
                  <v-data-table fixed-header :headers="headers" :items="RoleList"
                                :expanded.sync="expanded" item-key="name" show-expand :single-expand="true"
                                class="elevation-1 table-sc table-new-role" mobile-breakpoint="0" style="overflow-y: scroll"
                                :hide-default-header="true"
                                :hide-default-footer="true"
                                disable-pagination>
                    <template slot="no-data">
                      {{ $t("notification.noData") }}
                    </template>
                    <template #header="{ props: { headers } }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th style="width:30px !important;"></th>
                        <th class="text-uppercase" scope="col">
                          {{ $t(headers[1].text) }}
                        </th>
                        <th style="width:100px !important;" class="text-uppercase" scope="col">
                          {{ $t(headers[2].text) }}
                        </th>
                        <th style="width:130px !important;" class="text-uppercase" scope="col">
                          {{ $t(headers[3].text) }}
                        </th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>
                        <td>
                          <div @click="expand(!isExpanded)"
                               v-if="item.children !== undefined && item.children.length > 0"
                               class="d-flex justify-content-center">
                            <v-icon color="gray" size="25" class="mr-2 dark mt-0"
                                    v-if="!isExpanded">
                              mdi-chevron-down
                            </v-icon>
                            <v-icon color="gray" size="25" class="mr-2 dark mt-0" v-else>
                              mdi-chevron-up
                            </v-icon>
                          </div>

                        </td>
                        <td v-if="$i18n.locale === 'en'">
                          {{ $t(item.nameEn) }}
                        </td>
                        <td v-if="$i18n.locale === 'jp'">
                          {{ $t(item.nameJp) }}
                        </td>
                        <td class="text-center">
                          <input type="checkbox" :checked="item.view === true"
                                 :disabled="checkDetail || item.action === true"
                                 @change="handleTickViewActionParent($event, item, 0)">
                        </td>
                        <td class="text-center">
                          <input type="checkbox" :checked="item.action === true"
                                 :disabled="checkDetail" @change="handleTickViewActionParent($event, item, 1)">
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <tr v-for="(row, index) in item.children" :key="index">
                        <td v-if="index === 0" :rowspan="item.children.length"></td>
                        <td class="text-left">
                          <span style="color: black" v-if="$i18n.locale === 'en'">{{ $t(row.nameEn) }}</span>
                          <span style="color: black" v-if="$i18n.locale === 'jp'">{{ $t(row.nameJp) }}</span>
                        </td>
                        <td class="text-center">
                          <input type="checkbox" :checked="row.view === true" :key="index"
                                 :disabled="checkDetail || row.action === true"
                                 @change="handleTickViewActionParent($event, row, 0)">
                        </td>
                        <td class="text-center">
                          <input type="checkbox" :checked="row.action === true"
                                 :disabled="checkDetail"
                                 @change="handleTickViewActionParent($event, row, 1)">
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" text
                 @click.prevent="handleSaveData">
            {{ $t("labels.save") }}
          </v-btn>
          <v-btn v-if="!checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
            {{ $t("commonPopup.cancel") }}
          </v-btn>
          <v-btn v-if="checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from '@/utils/eventBus.js'
import {mapActions} from 'vuex';
import {
  getCookieSiteUser, setCurrentPageCode
} from "@/utils/auth";
import $ from 'jquery';

export default {
  name: "RoleModal",
  props: ['visible', 'titleModal'],
  data() {
    return {
      loading: false,
      privilegeSelected: [],
      expanded: [],
      singleExpand: true,
      title: "",
      showDialog: false,
      checkDetail: false,
      editFlag: false,
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {text: 'labels.browse', value: 'view', width: '100', sortable: false},
        {text: 'genba.table.edit', value: 'action', width: '100', sortable: false},
      ],
      RoleList: [],
      roleNameRule: [
        (value) => !!value || this.$t("labels.roleManager.roleName") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.roleManager.roleName") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      descriptionRules: [
        (value) => {
          if (value === undefined || value === null || value.length === 0) {
            return [];
          }
          return value && !!value.trim() || this.$t("labels.roleManager.description") + this.ERROR_CODE['NOT_CORRECT_FORMAT'];
        },
      ],
      roleInfo: {
        name: '',
        description: '',
        SiteId: 1,
        Privileges: ''
      },
      ERROR_CODE: '',
      valid: false,
      PAGE_CODE: 'CS_RM'
    }
  },
  async beforeMount() {
    if (this.checkDetail === true) {
      this.loading = true;
    }
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/roles/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/roles/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    this.handleGetMenuRole();

    EventBus.$on('handleOpenRoleEditModal', (value) => {
      this.title = value.title;
      this.roleInfo = value.editedItem;
      this.showDialog = value.flag;
      if (value.flag) {
        $('#main-header').css("pointer-events", "none");
      } else {
        $('#main-header').css("pointer-events", "");
      }
      this.checkDetail = value.checkDetail;
      this.editFlag = value.editFlag;
      this.$refs.form?.resetValidation();
      this.handleInitRoleDataTable(value);
      this.privilegeSelected = value.editedItem['privileges'] ? value.editedItem['privileges'] : []
      this.loading = false;
    });
  },
  beforeDestroy() {
    EventBus.$off('handleOpenRoleEditModal');
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/roles/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/roles/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  },
  methods: {
    ...mapActions("roles", {
      addRole: "addRole",
      updateRole: "updateRole",
      getMenuRole: "getMenuRole",
    }),
    handleGetMenuRole() {
      this.getMenuRole().then(response => {
        if (response.code === 0) {
          console.log('[handleGetMenuRole]', response.data)
          this.RoleList = response.data;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {

      });
    },
    handleInitRoleDataTable(value) {
      const privileges = value.editedItem.privileges;

      if (privileges !== undefined && privileges !== null) {
        privileges.filter(role => {
          this.RoleList.filter(menu => {

            if (menu.code === role.code) {
              if (role.action === "READ") menu.view = true;
              if (role.action === "OPS") menu.action = true;

              if (menu.action) menu.view = true;

            }

            console.log('[handleInitRoleDataTable]', menu.children);

            if (menu.children !== undefined && menu.children !== null) {

              menu.children.filter(subMenu => {
                if (subMenu.code === role.code) {
                  if (role.action === "READ") subMenu.view = true;
                  if (role.action === "OPS") subMenu.action = true;

                  if (subMenu.action) subMenu.view = true;
                }
              })
            }
          })
        })
      }
    },
    handleSaveData() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.roleInfo.SiteId = getCookieSiteUser();
      if (this.roleInfo.name === '') {
        this.commonErrorVue(this.ERROR_CODE["E_010_02_1"], 'warning');
        return;
      }
      if (this.privilegeSelected.length === 0) {
        this.commonErrorVue(this.ERROR_CODE["E_010_02_2"], 'warning');
        return;
      }
      this.roleInfo.Privileges = this.privilegeSelected;
      this.loading = true;

      if (this.roleInfo.id > 0) {
        this.handleUpdateRole();
      } else {
        this.handleAddRole();
      }
    },
    handleAddRole() {
      this.loading = true;

      this.addRole(this.roleInfo)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetRoleList', {});
            this.commonConfirmOKVue(this.ERROR_CODE["SAVE_OK"])
            this.handleResetRoleList();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            $('#main-header').css("pointer-events", "");
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleUpdateRole() {
      this.loading = true;
      this.updateRole(this.roleInfo)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetRoleList', {});
            this.commonConfirmOKVue(this.ERROR_CODE["SAVE_OK"])
            this.handleResetRoleList();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleTickViewActionParent(event, item, action) {

      if (event.target.checked) {
        if (action === 0) {
          item.view = true;
          const viewIndex = this.privilegeSelected.findIndex(event => event.code === item.code);
          if (viewIndex === -1) this.privilegeSelected.push({code: item.code, action: "READ"});
          if (item.children !== undefined && item.children !== null) {
            item.children.find(child => {
              child.view = item.view;
              const childIndex = this.privilegeSelected.findIndex(event => event.code === child.code);
              if (childIndex === -1) this.privilegeSelected.push({code: child.code, action: "READ"});
            })
          }
          this.handleCheckViewIM();
        }
        if (action === 1) {
          item.action = true;
          item.view = item.action

          if (this.privilegeSelected.length > 0) {
            this.privilegeSelected.filter(role => {
              if (role.code === item.code && role.action === "READ") {
                role.action = "OPS"
                if (item.children !== undefined && item.children !== null) {
                  item.children.find(child => {
                    child.action = item.action;
                    child.view = item.action
                    this.privilegeSelected.filter(roleChild => {
                      if (child.action) {
                        roleChild.action = "OPS"
                      } else {
                        let roleIndex = this.privilegeSelected.findIndex(value => value.code === child.code);
                        if (roleIndex === -1) this.privilegeSelected.push({
                          code: child.code,
                          action: (item.action || child.action) ? "OPS" : "READ"
                        });
                      }
                    })
                  })
                }
              } else {
                if (item.children !== undefined && item.children !== null) {
                  let parentIndex = this.privilegeSelected.findIndex(value => value.code === item.code);
                  if (parentIndex === -1) this.privilegeSelected.push({
                    code: item.code,
                    action: (item.action) ? "OPS" : "READ"
                  });
                  item.children.filter(child => {
                    child.action = item.action;
                    child.view = item.action
                    let roleIndex = this.privilegeSelected.findIndex(value => value.code === child.code);
                    if (roleIndex === -1) this.privilegeSelected.push({
                      code: child.code,
                      action: (item.action || child.action) ? "OPS" : "READ"
                    });
                  })
                } else {
                  let roleIndex = this.privilegeSelected.findIndex(value => value.code === item.code);
                  if (roleIndex === -1) this.privilegeSelected.push({
                    code: item.code,
                    action: (item.action) ? "OPS" : "READ"
                  });
                }
              }
            });
          } else {
            this.privilegeSelected.push({code: item.code, action: (item.action) ? "OPS" : "READ"});
            if (item.children !== undefined && item.children !== null) {
              item.children.filter(child => {
                child.action = item.action;
                child.view = item.action
                this.privilegeSelected.push({code: child.code, action: (item.action || child.action) ? "OPS" : "READ"});
              })
            }
          }

          this.handleCheckActionIM();
          this.handleCheckViewIM();
        }
      } else {
        if (action === 0) {
          item.view = false;

          this.handleUncheckChildViewIM(event, item);
          const roleIndex = this.privilegeSelected.findIndex(event => event.code === item.code && event.action === "READ");
          if (roleIndex !== -1) this.privilegeSelected.splice(roleIndex, 1);
        }
        if (action === 1) {
          item.action = false;
          item.view = item.action;

          this.handleUncheckActionIM(event, item);

          const roleIndex = this.privilegeSelected.findIndex(event => event.code === item.code);
          if (roleIndex !== -1) this.privilegeSelected.splice(roleIndex, 1);
        }
      }
      console.log('[handleTickViewActionParent][ITEM]', JSON.stringify(this.privilegeSelected));
    },
    handleCheckViewIM() {
      this.RoleList.filter(role => {
        if (role.children !== undefined && role.children !== null && role.children.length > 0) {
          let countFailedChild = 0;
          role.children.filter(child => {
            if (child.view === true) countFailedChild++;
          })

          if (countFailedChild > 0) {
            role.view = true;
            let parentIndex = this.privilegeSelected.findIndex(e => e.code === role.code);
            if (parentIndex === -1) this.privilegeSelected.push({code: role.code, action: "READ"});
          }
        }
      })
    },
    handleUncheckChildViewIM(event, item) {
      if (item.children === undefined || item.children === null) {
        console.log("Null item.children")
      } else if (item.children.length > 0) {
        item.children.filter(child => {
          child.view = event.target.checked;
          const roleIndex = this.privilegeSelected.findIndex(event => event.code === child.code && event.action === "READ");
          if (roleIndex !== -1) {
            this.privilegeSelected.splice(roleIndex, 1);
          }
        })
      }

      this.RoleList.filter(role => {
        if (role.children !== undefined && role.children !== null && role.children.length > 0) {
          const parentCode = role.children.findIndex(value => value.code === item.code);

          let countFailedChild = 0;
          role.children.filter(child => {
            if (child.view === false) countFailedChild++;
          })

          if (countFailedChild === role.children.length && parentCode !== -1) {
            role.view = false;
            const parentRoleIndex = this.privilegeSelected.findIndex(event => event.code === role.code && event.action === "READ");
            if (parentRoleIndex !== -1) this.privilegeSelected.splice(parentRoleIndex, 1);
          }
        }
      })

    },
    handleUncheckActionIM(event, item) {

      if (item.children !== undefined && item.children !== null && item.children.length > 0) {
        item.children.filter(child => {
          child.view = event.target.checked;
          child.action = event.target.checked;

          const roleIndex = this.privilegeSelected.findIndex(event => event.code === child.code);
          if (roleIndex !== -1) this.privilegeSelected.splice(roleIndex, 1);
        })

      }

      this.RoleList.filter(role => {
        if (role.children !== undefined && role.children !== null && role.children.length > 0) {
          const parentCode = role.children.findIndex(value => value.code === item.code);
          let countFailedChild = 0;
          role.children.filter(child => {
            if (child.action === false) countFailedChild++;
          })

          if (countFailedChild === role.children.length && parentCode !== -1) {
            role.view = false;
            role.action = false;
            const parentRoleIndex = this.privilegeSelected.findIndex(event => event.code === role.code && event.action === "OPS");
            if (parentRoleIndex !== -1) this.privilegeSelected.splice(parentRoleIndex, 1);
          }
        }
      })
    },
    handleCheckActionIM() {
      this.RoleList.filter(role => {
        if (role.children !== undefined && role.children !== null && role.children.length > 0) {
          let countFailedChild = 0;
          role.children.filter(child => {
            if (child.action === true) countFailedChild++;
          })
          if (countFailedChild > 0) {
            role.action = true;
            let parentIndex = this.privilegeSelected.findIndex(e => e.code === role.code);
            if (parentIndex === -1) this.privilegeSelected.push({code: role.code, action: "OPS"});
          }
        }
      })
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_CLOSE'], () => {
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
          this.handleResetRoleList();
        });
      } else {
        this.showDialog = false
        $('#main-header').css("pointer-events", "");
        this.handleResetRoleList();
      }
    },
    handleResetRoleList() {
      this.RoleList.filter(value => {
        console.log(value);
        value.view = false;
        value.action = false;
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            child.view = false;
            child.action = false;
          })
        }
      });
      this.privilegeSelected = [];
      this.roleInfo = {
        name: '',
        description: '',
        SiteId: 1,
        Privileges: ''
      };
      this.$refs.form?.resetValidation();
    }
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 1180px) {
  .text-box-ipad {
    padding-right: 15px;
  }
}

@media (max-width: 820px) {
  .text-box-ipad {
    padding-right: 15px;
  }
}

.table-new-role {
  height: 300px;
  max-height: 300px;
}

td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
  border-bottom: 2px solid #ebebeb !important;
  box-shadow: none !important;
}

th {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
  border-top: 2px solid #ebebeb !important;
  border-bottom: 2px solid #ebebeb !important;
}

input[type=checkbox] {
  transform: scale(1.5);
}

.view-column {
  padding-left: 12px !important;
}

.v-card__actions {
  border-top: 1px solid #ebebeb;
}
::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin-left: 4.55rem !important;
  }
  .expand {
    margin-top: 1.5rem !important;
  }
  .text-box-iphone {
    margin-left: 1.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
    .sign-ipad {
      margin-left: 1rem !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
