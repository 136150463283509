<template>
  <div class="row div-app-ipad">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t("labels.userManager.userId")
                              }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" maxlength="40" v-model="searchUserInfo.UserId" outlined
                            dense></v-text-field>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t("labels.userManager.userName")
                              }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" v-model="searchUserInfo.Username" outlined dense></v-text-field>
            </div>
          </div>
        </div>


        <div class="d-flex justify-content-end mr-3">
          <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetUserManagementList">
            <v-icon right dark class="mr-1">
              fas fa-search
            </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
          <v-btn class="bg-secondary font-weight-bold" @click.prevent="clearFilter">
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{ $t("labels.clear") }}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table :headers="headers" :items="userList"
                        :pagination.sync="pagination" :total-items="pagination.totalItems"
                        sort-by="calories" class="elevation-2" mobile-breakpoint="0" hide-default-header
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50],
                            'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
          >
            <template slot="no-data">
              {{ $t("notification.noData") }}
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.value"
                  class="text-uppercase"
                  scope="col"
                >
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn class="add-button-container" depressed color="primary" style="background-color: #3d76ad"
                       v-if="ACTION === 'OPS'"
                       @click="createItem(1)">
                  <v-icon color="white" size="20" class="mr-2 dark mt-0 add-button">
                    mdi-plus-box
                  </v-icon>
                  <span class="add-button" style="font-size: 20px">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.userName.slice(0, 14) }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.userName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <font-awesome-icon v-if="item.status === 1" icon="fa-solid fa-circle-check" color="green"
                                 size="lg"/>
              <font-awesome-icon v-else icon="fa-solid fa-circle-xmark" color="red" size="lg"/>

            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <button class="m-1" @click="detailItem(item)">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="editItem(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2" @click="confirmDeleteItem(item)"
                     v-if="ACTION === 'OPS'">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <UserModal @clicked="onClickChild" @close="modelEdit = false"/>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import UserModal from "@/modals/User/UserModal"
import {EventBus} from "@/utils/eventBus";
import moment from "moment";
import {mapActions} from "vuex";
import {
  getCookiePrivileges,
  getCookieSiteUser, setCurrentPageCode
} from "@/utils/auth";
import $ from 'jquery';

export default {
  name: "UserManagement",
  components: {
    UserModal
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', align: 'center', sortable: true, value: 'index', width: "50px"},
        {text: this.$t('labels.userManager.userId'), value: 'userId', align: 'center', sortable: true},
        {text: this.$t('labels.userManager.userName'), value: 'userName', align: 'center', sortable: true},
        {text: this.$t('labels.userManager.role'), value: 'roleName', align: 'center', sortable: true},
        {text: this.$t('labels.userManager.status'), value: 'status', sortable: false, align: 'center'},
        {text: this.$t('labels.userManager.createBy'), value: 'createBy', sortable: false, align: 'center'},
        {text: this.$t('labels.userManager.createDate'), value: 'createDate', sortable: false, align: 'center'},
        {text: this.$t('labels.userManager.updateBy'), value: 'updateBy', sortable: false, align: 'center'},
        {text: this.$t('labels.userManager.updateDate'), value: 'updateDate', sortable: false, align: 'center'},
        {text: this.$t('labels.userManager.actions'), value: 'actions', sortable: false, align: 'center'},
      ]
    }
  },
  data() {
    return {
      loading: false,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_UM',
      ACTION: 'OPS',
      alert: false,
      userIdDeleteSelected: 0,
      currentLocale: this.$i18n.locale,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dialogx: false,
      selected1: null,
      selected: null,
      modelDetail: false,
      modelEdit: false,
      modelCreate: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: '#', align: 'center', sortable: true, value: 'index', width: "50px"},
        {text: 'labels.userManager.userId', value: 'userId', align: 'left', sortable: true},
        {text: 'labels.userManager.userName', value: 'userName', align: 'left', sortable: true},
        {text: 'labels.userManager.role', value: 'roleName', align: 'left', sortable: true},
        {text: 'labels.userManager.status', value: 'status', sortable: false, align: 'center'},
        {text: 'labels.userManager.createBy', value: 'createBy', sortable: false, align: 'left'},
        {text: 'labels.userManager.createDate', value: 'createDate', sortable: false, align: 'center'},
        {text: 'labels.userManager.updateBy', value: 'updateBy', sortable: false, align: 'left'},
        {text: 'labels.userManager.updateDate', value: 'updateDate', sortable: false, align: 'center'},
        {text: 'labels.userManager.actions', value: 'actions', sortable: false, align: 'center'},
      ],
      headerRequest: {
        'PAGE-CODE': 'CS_UM'
      },
      pagination: {
        descending: !!this.$route.query.desc,
        sortBy: 'name',
        rowsPerPage: 5,
        page: 1,
        totalItems: 0,
      },
      userList: [],
      editedIndex: -1,
      editedItem: {
        indexUser: 0,
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createUser: '',
        email: '',
        phonenumber: '',
        address: '',
        updateUser: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      },
      searchUserInfo: {
        UserId: "",
        Username: "",
        siteId: 1,
        PageSize: 5,
        PageNumber: 1
      },
      isSubmitAction: false,
      flagAdd: false,
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/users/${currentLang}.js`)
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
    'searchUserInfo.UserId': function () {
      // this.searchUserInfo.UserId = this.searchUserInfo.UserId.split(" ").join("");
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },

  created() {
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }
  },
  mounted() {
    this.handleGetUserManagementList();
  },
  methods: {
    ...mapActions("users", {
      getUserManagementList: "getUserManagementList",
      deleteUserManagement: "deleteUserManagement"
    }),
    handleGetUserManagementList() {
      this.loading = true;
      this.searchUserInfo.siteId = getCookieSiteUser()
      let object = {
        json: this.searchUserInfo,
        header: this.headerRequest
      }
      this.getUserManagementList(object)
        .then(response => {
          if (response.code === 0) {

            console.log('[handleGetUserManagementList]', response.data);
            this.userList = response.data.userList;
            this.pagination.rowsPerPage = response.data.meta.pageSize;
            this.pagination.totalItems = response.data.meta.total;
            this.pagination.page = response.data.meta.pageNum;
            console.log(this.pagination);
            if (response.data.userList !== null && response.data.userList.length > 0) {
              for (let i = 0; i < this.userList.length; i++) {
                this.userList[i].indexUser = this.userList[i].index;
                this.userList[i].index = i + 1;
                this.userList[i].createDate = moment(this.userList[i].createdDate).startOf().format('YYYY/MM/DD');

                this.userList[i].updateDate = moment(this.userList[i].updatedDate).format('YYYY/MM/DD');
              }
            }
            console.log(this.userList)
            this.loading = false;
          } else {
            console.log("[handleGetUserManagementList]", response);
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {

          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    onClickChild(value) {
      $('#main-header').css("pointer-events", "");
      this.handleGetUserManagementList();
    },
    clearFilter() {
      this.searchUserInfo.UserId = "";
      this.searchUserInfo.Username = "";
      this.handleGetUserManagementList();
    },
    initialize() {
      this.handleGetUserManagementList();
    },
    // eslint-disable-next-line
    createItem(numer) {
      this.editedItem = {}
      this.editedItem.indexUser = 0
      this.editedItem.status = 1
      $('#main-header').css("pointer-events", "none");
      this.modelCreate = true
      EventBus.$emit('handleOpenUserEditModal', {
        title: this.$t('labels.userManager.createPopupTitle'),
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        checkAdd: true,
        editMode: false
      });
      $('#main-header').css("pointer-events", "none");
      this.modelEdit = true;
    },
    editItem(item) {
      this.editedIndex = this.userList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showPassword = false;
      console.log(this.editedItem);
      EventBus.$emit('handleOpenUserEditModal', {
        title: this.$t('labels.userManager.editPopupTitle'),
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        checkAdd: false,
        editMode: true
      });
      $('#main-header').css("pointer-events", "none");
      this.modelEdit = true;
    },
    detailItem(item) {
      this.editedIndex = this.userList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = "vti@12345";
      this.editedItem.role = 1;
      console.log(this.editedItem)
      EventBus.$emit('handleOpenUserEditModal', {
        title: this.$t('labels.userManager.detailPopupTitle'),
        editedItem: this.editedItem,
        flag: true,
        checkDetail: true,
        checkAdd: false,
        editMode: true
      });
      $('#main-header').css("pointer-events", "none");
      this.modelDetail = true

    },
    confirmDeleteItem(item) {
      this.userIdDeleteSelected = item.userId;


      this.commonConfirmVue(this.$t('confirm.msgDelete'), () => {
        let object = {
          index: this.userIdDeleteSelected,
          siteId: getCookieSiteUser(),
          header: this.headerRequest
        }
        this.deleteUserManagement(object)
          .then(response => {
            if (response.code === 'S_CM_01') {
              // this.dialogDelete = false;
              this.handleGetUserManagementList();
              this.commonConfirmOKVue(this.ERROR_CODE['DELETE_SUCCESS'])
            } else {
              console.log("[handleDeleteItem]", response);
              this.handleGetUserManagementList();
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {

            this.isSubmitAction = false;
          });
      }, () => {
        this.userIdDeleteSelected = 0;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1180px) {
  /* For ipad 768px: */
  .row {
    width: 101% !important;
  }

}

@media (max-width: 820px) {
  /* For ipad 768px: */
  .row {
    width: 101 !important;
  }
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}

@media only screen and (max-width: 540px) and (max-height: 900px) {
  .add-button {
    font-size: 0.5rem !important;
  }
  .add-button-container {
    height: 1.5rem !important;
    width: 0.5rem !important;
  }
}

::v-deep{
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.75rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 0.75rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
    .v-data-footer__pagination {
      margin-left: 4.55rem !important;
    }
    .v-card__text {
      margin-left: -1rem !important;
      width: 115% !important;
    }
    .v-data-table>.v-data-table__wrapper>table {
    width: 1300px !important;
    } 
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}


</style>
