import request from "../utils/request";

const CS_WS = "CS_WS";

export function getDeviceList(siteId) {
    return request({
        url: `/Warning/getDeviceList/${siteId}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function getDeviceProperty(deviceCode) {
    return request({
        url: `/Warning/getDeviceProperty/${deviceCode}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}
export function getPatrolLightList(siteId) {
  return request({
    url: `/Warning/getPatrolLightList/${siteId}`,
    method: "get",
    headers: {
      'PAGE-CODE': CS_WS
    }
  });
}
export function savePatrolLight(patrolLight) {
  return request({
    url: `/Warning/savePatrolLight`,
    method: "post",
    data : patrolLight,
    headers: {
      'PAGE-CODE': CS_WS
    }
  });
}
export function deletePatrolLight(id) {
  return request({
    url: `/Warning/removePatrolLight/${id}`,
    method: "get",
    headers: {
      'PAGE-CODE': CS_WS
    }
  });
}
export function testPatrolLightCommand(data) {
  return request({
    url: `/Warning/testPatrolLightCommand/${data.status}`,
    method: "post",
    data : data.item,
    headers: {
      'PAGE-CODE': CS_WS
    }
  });
}
export function autoTurnOffPatrolLight(data) {
  return request({
    url: `/Warning/autoTurnOffPatrolLight`,
    method: "post",
    data : data,
    headers: {
      'PAGE-CODE': CS_WS
    }
  });
}
export function getWarningCategories(dataCode) {
    return request({
        url: `/Warning/getWarningCategory/${dataCode}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function getStation(data) {
    return request({
        url: `/Warning/getStation/${data.siteId}/${data.deviceCode}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function getWarningList(data) {
    return request({
        url: !data.isTsDevice ? `/Warning/getWarningList/${data.siteId}/${data.deviceCode}/${data.properties}`: `/Warning/getWarningList/${data.siteId}/${data.deviceCode}/${data.stationId}/${data.tsStation}/${data.orgNo}/${data.propertiesTs}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function addWarning(data) {
    return request({
        url: `/Warning/addWarning/${data.siteId}`,
        method: "post",
        data: data.data,
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function updateWarning(data) {
    return request({
        url: `/Warning/updateWarning/${data.siteId}`,
        method: "post",
        data: data.data,
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function removeWarning(data) {
    return request({
        url: `/Warning/removeWarning/${data.siteId}/${data.Id}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_WS
        }
    });
}

export function getWarningByFrameId(frameId) {
  return request({
    url: `/Warning/GetWarningByFrameId/${frameId}`,
    method: "post",
    headers: {
        'PAGE-CODE': CS_WS
    }
  });
}

export function GetWarningSettingSvgByFrameId(frameId) {
    return request({
      url: `/Warning/GetWarningSettingSvgByFrameId/${frameId}`,
      method: "post",
      headers: {
          'PAGE-CODE': CS_WS
      }
    });
  }
