import Vue from 'vue'
import VueRouter from "vue-router";
// Use arrow function as lazy loading
import Dashboard from '../pages/Dashboard.vue';
import MainPage from '../pages/Main.vue'
import LoginPage from "@/pages/LoginPage";
import AdminPage from "@/pages/AdminPage";
import NotFound from "../pages/NotFoundPage.vue";
import ConstructionSitePage from "@/pages/ConstructionSitePage";
import AdminSiteManager from "@/pages/AdminSiteManager";
import RoleManager from "@/pages/role/RoleManagement";
import UserManagement from "@/pages/user/UserManagement";
import SystemUserManagement from "@/pages/systemUser/SystemUserManagement";
import LayoutSetting from "@/pages/layoutSetting/LayoutSetting";
import SettingFrame from "@/pages/Setting/SettingFrame";
import SettingMail from "@/pages/Setting/SettingMail";
import AlarmSettings from "@/pages/alarmSettings/AlarmSettings";
import SearchData from "@/pages/searchData/SearchData";
import FileManager from "@/pages/files/FileManager";
import CameraPage from "../pages/CameraPage";
import ScreenSharePage from "../pages/ScreenSharePage"
import DeviceMaster from "../pages/DeviceMaster";
import {getCookiePrivileges, getCookieUserType, setMenuAccesses} from "@/utils/auth";

Vue.use(VueRouter);

let loginPage = {
  path: '/login',
  name: 'ログイン',
  component: LoginPage
};
let adminPage = {
  path: '/admin',
  name: '',
  component: AdminPage
};
let cameraPage = {
  path: '/camera',
  name: 'KCloud Camera',
  component: CameraPage
};
let screenSharePage = {
  path: '/screenShare',
  name: 'Screen Share Page',
  component: ScreenSharePage
}
let notFoundPage = {
  path: '/notfound',
  name: 'Not Found Page',
  component: NotFound
};
const menuSideBar = [
  {
    id: "1",
    parentId: "0",
    path: '',
    name: 'labels.dashboard',
    component: Dashboard,
  },
  {
    id: "2",
    parentId: "0",
    name: "labels.manageinfo",
    path: '/manage-info'
  },
  {
    id: "3",
    parentId: "2",
    path: '/construction',
    name: 'labels.sitemanager',
    component: ConstructionSitePage,
  },
  {
    id: "4",
    parentId: "2",
    path: '/adminsitemanager',
    name: 'labels.adminsitemanager',
    component: AdminSiteManager,
  },
  {
    id: "5",
    parentId: "0",
    name: "labels.informationManager",
    path: '/information-info'
  },
  {
    id: "6",
    parentId: "5",
    path: '/role-management',
    name: 'labels.roleManagement',
    component: RoleManager
  }, {
    id: "7",
    parentId: "5",
    path: '/user-management',
    name: 'labels.userManagement',
    component: UserManagement
  }, {
    id: "8",
    parentId: "5",
    path: '/system-user-management',
    name: 'labels.systemUserManagement',
    component: SystemUserManagement
  }, {
    id: "9",
    parentId: "0",
    path: '/layout-setting',
    name: 'labels.layoutSetting.title',
    component: LayoutSetting
  },
  {
    id: "10",
    parentId: "0",
    path: '/alarm-setting',
    name: 'alarmSetting.scName',
    component: AlarmSettings
  },
  {
    id: "11",
    parentId: "0",
    path: '/search-data',
    name: 'exportData.title',
    component: SearchData
  },
  {
    id: "12",
    parentId: "0",
    path: '/file-manage',
    name: 'fileManager.title',
    component: FileManager
  },
  {
    id: "13",
    parentId: "1",
    path: '/settingframe',
    name: 'labels.frameSetting',
    component: SettingFrame
  },
  {
    id: "14",
    parentId: "1",
    path: '/settingmail',
    name: 'labels.mailSetting.mailsetting',
    component: SettingMail

  },{
    id: "15",
    parentId: "10",
    path: '/device-master',
    name: 'labels.deviceMasterManagement',
    component: DeviceMaster 
  }, 
]
const router = new VueRouter({
  linkActiveClass: 'active',
  base: "/kcloud",
  mode: 'history',
  routes: [
    loginPage,
    adminPage,
    {
      path: '/',
      name: 'MainPage',
      component: MainPage,
      children: menuSideBar
    },
    cameraPage,
    screenSharePage,
    notFoundPage
  ]
})
// eslint-disable-next-line
router.afterEach((to, from) => {
})
// This function is used for check authentication or user's permission
router.beforeEach((to, from, next) => {
  if (to.path === '/construction' || to.path === '/system-user-management' || to.path === '/adminsitemanager' || to.path === '/device-master') {
    setMenuAccesses(2);
  } else {
    setMenuAccesses(1);
  }
  next();
})

export default router
