export const FrameTypesValue = {
    LineStack: "LS",
    GirdMultipleLineChart: "GLS",
    MonitorWebCamera: "MWC",
    MonitorSafieCamera: "MSC",
    GaugeChart: "GC",
    TableChart: "TC",
    MultipleYAxisMixLineBarChart: "MAC",
    ScatterChart: "SC",
    SVG: "SVG",
    LineRace: "LR",
    SVGTS: "SVG-TS",
    ScreenShare : "SCS"
}

export const ResponseCode = {
    SUCCESS: 0,
    SAVE_SUCCESS: "S_CM_02",
    DELETE_SUCCESS: "S_CM_01",
    SEND_SUCCESS: "S_064_01",
    TURN_ON_SUCCESS: "S_CM_03",
    TURN_OFF_SUCCESS: "S_CM_04",
    AUTO_TURN_OFF_SUCCESS : "S_CM_05",
    CANCEL_AUTO_TURN_OFF : "S_CM_06"
}

export const NullInt = -9999

export const WarningLevelValue = {
    PRIMARY: "primary_threshold",
    SECONDARY: "secondary_threshold",
    TERTIARY: "tertiary_threshold"
}

export const LR_MODE = {
    ONE_DAY: "ONE_DAY",
    ONE_MONTH: "ONE_MONTH",
    ONE_YEAR: "ONE_YEAR"
}
