<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5" style="font-size: 0.9rem !important;">{{ $t(nameLabel) }}</span>
        <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 600px;">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-container class="pt-0 pb-0">
            <div class="row mt-0">
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userid") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="20" v-model.trim="editedItem.userId" hide-details="auto" :disabled="editMode"
                              :rules="userIdRules"
                              outlined dense></v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.password") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <div v-if="!checkDetail">
                  <v-text-field hide-details="auto"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="!(editPasswordFlag === true && editMode) ? passwordRules : []"
                                maxlength="30"
                                :append-outer-icon="editMode ? (editPasswordFlag ? 'mdi-pencil' : 'mdi-window-close') : ''"
                                :append-icon="(showIconEye || !editMode || !editPasswordFlag) ? (showPassword ? 'fas fa-eye' : 'fa-eye-slash') : ''"
                                name="input-10-2" class="input-group--focused"
                                @click:append="showPassword = !showPassword"
                                @click:append-outer="editPasswordFlag = !editPasswordFlag"
                                outlined dense v-model.trim="editedItem.password"
                                :readonly="editPasswordFlag === true && editMode"
                                placeholder="********"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-text-field type="password" v-model.trim="editedItem.password" name="input-10-2"
                                placeholder="********"
                                class="input-group--focused" outlined dense :disabled="checkDetail"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="row mt-0 extend-padding">
              <div class="col-6">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("adminSite.userName") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field v-model.trim="editedItem.userName" maxlength="50" hide-details="auto" :disabled="checkDetail"
                              :rules="fullNameRules"
                              outlined dense></v-text-field>
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.phonenumber") }}</h6>
                </div>
                <v-text-field maxlength="30" v-model.trim="editedItem.phoneNumber" hide-details="auto"
                              :disabled="checkDetail" :rules="phoneNumberRules"
                              outlined dense></v-text-field>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("adminSite.email") }}</h6>
                </div>
                <v-text-field maxlength="100" v-model.trim="editedItem.email" hide-details="auto" :disabled="checkDetail"
                              :rules="emailRules"
                              outlined dense></v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("adminSite.status") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-select :items="optionsStatus" v-model="editedItem.status" :disabled="checkDetail"
                          :rules="requiredRule(editedItem.status,'adminSite.status')"
                          outlined dense></v-select>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12 pt-0 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("adminSite.site") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-select outlined dense :items="valuesSites" key="value"
                                item-value="value"
                                item-text="text"
                                v-model="editedItem.siteList" multiple chips :disabled="checkDetail"
                                :rules="requiredRule(editedItem.siteList,'adminSite.site')">
                  <template v-slot:[`item`]="{ item }">
                    <v-icon
                      v-if="editedItem.siteList !== null && editedItem.siteList.includes(item.value)"
                      color="primary"
                      class="mr-3">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else class="mr-3">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-toolbar-title v-on="on">
                          <span>{{ (item.text).slice(0, 40) }} {{ '...' }}</span>
                        </v-toolbar-title>
                      </template>
                      <option>{{ item.text }}</option>
                    </v-tooltip>
                  </template>
                  <template #selection="{item}">
                    <v-chip
                      :key="JSON.stringify(item.value)"
                      close
                      class="chip--select-multi"
                      @input="editedItem.siteList.selectItem(item.value)"
                      @click:close="handleRemoveSite(item)"
                    >
                      {{ (item.text).slice(0, 20) }}{{'...'}}
                    </v-chip>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12 pt-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("adminSite.address") }}</h6>
                  <!-- <h5 class="text-danger mb-0" v-if="!checkDetail">*</h5> -->
                </div>
                <v-text-field v-model.trim="editedItem.address" hide-details="auto" :disabled="checkDetail" maxlength="200"
                              outlined dense :rules="addressRules"></v-text-field>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6">
                <h6 class="mb-1 label-black">{{ $t("labels.createdate") }}</h6>
                <v-text-field v-model="editedItem.createDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6">
                <h6 class="mb-1 label-black">{{ $t("labels.usercreate") }}</h6>
                <v-text-field v-model="editedItem.createBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6 pb-0">
                <h6 class="mb-1 label-black">{{ $t("labels.updatedate") }}</h6>
                <v-text-field v-model="editedItem.updateDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6 pb-0">
                <h6 class="mb-1 label-black">{{ $t("labels.userupdate") }}</h6>
                <v-text-field v-model="editedItem.updateBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!checkDetail"
          class="text-white mr-1"
          style="background-color: #3d76ad"
          text
          @click="handleSaveData"
        >
          {{ $t("labels.save") }}
        </v-btn>
        <v-btn v-if="checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.close") }}
        </v-btn>

        <v-btn v-if="!checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from '@/utils/eventBus.js'
import {mapActions} from "vuex";
import moment from "moment";
import $ from 'jquery';
import {getRememberKCloudID} from "@/utils/auth";

export default {
  name: "AdminSiteEditModal",
  props: ['visible'],
  components: {},
  data() {
    return {
      valid: false,
      loading: false,
      nameLabel: '',
      showDialog: false,
      checkDetail: false,
      showPassword: false,
      editMode: false,
      showIconEye: false,
      editPasswordFlag: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      rulesText: [
        value => !!value || "labels.username",
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      value: [
        {name: 'Javascript', main: 'js'},
        {name: 'Vue.js', main: 'vu'},
      ],
      editedItem: {
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createUser: '',
        email: '',
        phonenumber: '',
        address: '',
        updateUser: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        siteList: [],
        curator: ['']
      },
      optionsStatus: [
        {value: 1, text: this.$t('statusOptions.active')},
        {value: 2, text: this.$t('statusOptions.deactivate')},
      ],
      valuesSites: [],
      selectedIndex: 0,
      flagAdd: false,
      userIdRules: [
        (value) => !!value || this.$t("labels.userManager.userId") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => /^[a-z0-9]+$/i.test(value) || this.$t("labels.userManager.userId") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) => (value !== undefined && value.length >= 8) || this.checkActionCheckMinLength(this.$t("labels.userManager.userId"), 8),
      ],
      fullNameRules: [
        (value) => !!value || this.$t("labels.userManager.userName") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.systemUserManager.userName") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      passwordRules: [
        (value) => !!value || this.$t("labels.userManager.password") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => /^[A-Za-z0-9`~!$%^*()_@./#&+-=]*$/i.test(value) || this.$t("labels.userManager.password") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) => (value !== undefined && value !== null && value.length >= 8) || this.checkActionCheckMinLength(this.$t("labels.userManager.password"), 8),
      ],
      phoneNumberRules: [
        (value) => (/^\d+$/.test(value) || (value === null || value === undefined || value.length === 0)) || this.$t("labels.userManager.phoneNumber") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      emailRules: [
        (value) =>(/^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/.test(value) || (value === null || value === undefined || value.length === 0)) || this.ERROR_CODE['EMAIL_NOT_CORRECT_FORMAT']
      ],
      siteRules: [
        (value) => !!value || this.$t("adminSite.site") + this.ERROR_CODE['IS_REQUIRED']
      ],
      addressRules: [
        (value) => {
          if (value === undefined || value === null || value.length === 0) {
            return [];
          }
          return value && !!value.trim() || this.$t("adminSite.address") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
        }
      ],
      requiredRule(value, label) {
        const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
        if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
          return errorMsg
        } else {
          return [];
        }
      },
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/users/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      
      this.optionsStatus = [
        {value: 1, text: this.$t('statusOptions.active')},
        {value: 2, text: this.$t('statusOptions.deactivate')},
      ]
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  },
  async beforeMount() {
    if (this.checkDetail === true) {
      this.loading = true;
    }
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  created() {
    this.$forceUpdate();
    EventBus.$on('handleOpenAdminManagementEditModal', (value) => {
      this.editedItem.userId = value.editedItem.userId;
      this.showDialog = value.flag;
      if (value.flag) {
        $('#main-header').css("pointer-events", "none");
      } else {
        $('#main-header').css("pointer-events", "");
      }
      this.checkDetail = value.checkDetail;
      this.nameLabel = value.nameLabel;
      this.flagAdd = value.flagAdd;
      this.editMode = value.editMode;
      this.handleGetCbbSitesByAdminSite();
      this.$refs.form?.resetValidation();
      if (value.checkDetail || value.editMode) {
        this.handleGetDetailAdminSite();
      }
      // this.$refs.form.resetValidation();

    });
  },
  methods: {
    ...mapActions("users", {
      adminSiteDetail: "adminSiteDetail",
      addAdminSite: "addAdminSite",
      updateAdminSite: "updateAdminSite"
    }),
    ...mapActions("sites", {
      getCbbSitesByAdminSite: "getCbbSitesByAdminSite",
    }),
    handleRemoveSite(item) {
      this.editedItem.siteList.splice(this.editedItem.siteList.indexOf(item.value), 1);
      this.editedItem.siteList = [...this.editedItem.siteList];
    },
    handleSaveData() {
      this.$refs.form.validate()
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.flagAdd) {
        this.handleUpdateUserAdminSite();
      } else {
        this.handleAddUserSite();
      }
    },
    handleGetCbbSitesByAdminSite() {
      this.getCbbSitesByAdminSite()
        .then(response => {
          if (response.code === 0) {
            if (response.data !== null && response.data.length > 0) {
              this.valuesSites = []
              for (let i = 0; i < response.data.length; i++) {
                let Site = {
                  value: response.data[i].id,
                  text: response.data[i].id + " - " + response.data[i].siteName
                }
                this.valuesSites.push(Site);
              }
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleGetDetailAdminSite() {
      const userId = this.editedItem.userId;
      this.loading = true
      this.adminSiteDetail(userId)
        .then(response => {
          if (response.code === 0) {
            this.editedItem = response.data;
            if (response.data !== null) {
              this.editedItem.createDate = moment(this.editedItem.createdDate).format('YYYY/MM/DD');
              this.editedItem.updateDate = moment(this.editedItem.updatedDate).format('YYYY/MM/DD');
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(() => {
          this.loading = false;
          this.isSubmitAction = false;
        });
    },
    handleAddUserSite() {
      this.loading = true;

      let obj = {
        userid: this.editedItem.userId,
        password: this.editedItem.password,
        Username: this.editedItem.userName,
        phoneNumber: this.editedItem.phoneNumber,
        email: this.editedItem.email,
        status: this.editedItem.status,
        SiteList: this.editedItem.siteList,
        role: this.editedItem.roleId,
        address: this.editedItem.address,
      }
      this.addAdminSite(obj)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('clicked', "");
            this.commonConfirmOKVue(this.ERROR_CODE[response.code])
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.handleRestartUserInfo();
          this.loading = false;
          this.isSubmitAction = false;
        });
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_CLOSE'], () => {
          $('#main-header').css("pointer-events", "");
          this.showDialog = false
          this.handleRestartUserInfo();
        });
      } else {
        $('#main-header').css("pointer-events", "");
        this.showDialog = false
        this.handleRestartUserInfo();
      }
    },
    handleUpdateUserAdminSite() {
      this.loading = true;

      let obj = {
        index: this.editedItem.index,
        userid: this.editedItem.userId,
        password: this.editedItem.password,
        Username: this.editedItem.userName,
        phoneNumber: this.editedItem.phoneNumber,
        email: this.editedItem.email,
        status: this.editedItem.status,
        SiteList: this.editedItem.siteList,
        role: this.editedItem.roleId,
        address: this.editedItem.address,
      }
      this.updateAdminSite(obj)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('clicked', "ancd");
            this.commonConfirmOKVue(this.ERROR_CODE[response.code])
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(() => {
          this.handleRestartUserInfo();
          this.loading = false;
          this.isSubmitAction = false;
        });
    },
    checkActionCheckMinLength(field, number) {
      var text = this.ERROR_CODE['E_052_01_13'];
      text = text.replace("[X]", this.$t(field)).replace("[Y]", number);
      return text;
    },
    handleRestartUserInfo() {
      this.editedItem = {
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createUser: '',
        email: '',
        phonenumber: '',
        address: '',
        updateUser: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: [''],
        siteList: []
      };
      this.editMode = false;
      this.checkDetail = false;
      this.showPassword = false;
      this.showIconEye  = false;
      this.editPasswordFlag = true;
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
.extend-padding{
  padding-top:  2%;
}
.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait){
  .v-card {
    margin-top: 5rem !important;
  }
  }
}
</style>
