// eslint-disable-next-line no-unused-vars
import {
  getFileList,
  uploadFile,
  removeFile,
  downloadFile,
  getBucketSize,
  responseGetBucketSize,
  downloadSVGFile
} from "@/api/file";

const actions = {
  getFileList({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getFileList(data.data, data.siteId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  uploadFile({commit, state}, data) {
    return new Promise((resolve, reject) => {
      uploadFile(data.data, data.siteId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeFile({commit, state}, data) {
    return new Promise((resolve, reject) => {
      removeFile(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadFile({commit, state}, data) {
    return new Promise((resolve, reject) => {
      downloadFile(data)
        .then(response => {
          const fileName = response.headers['file-name'];
          const data = response.data;

          resolve({data: data, fileName: fileName}); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBucketSize({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getBucketSize(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadSVGFile({commit, state}, data) {
    return new Promise((resolve, reject) => {
      downloadSVGFile(data)
        .then(response => {

          resolve(response); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

