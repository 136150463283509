<template>
  <div class="pt-1 input-ipad select2-style input-iphone">
    <Select2
      v-model="selectedSite"
      :options="items"
      :placeholder="$i18n.locale === 'en' ? 'Select construction site' : '現場を選択してください'"
      style="background-color:#1c76c3;"
      @change="myChangeEvent($event)"
    />
  </div>
</template>
<script>
import {EventBus} from "@/utils/eventBus";
import Select2 from "v-select2-component"

import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  getCookieUserType,
  setCookieSiteUser,
  getCookieSiteUser,
} from "@/utils/auth";
import sidebarLinks from "@/sidebarLinks";
import {mapActions} from "vuex";

export default {
  name: "changeSite",
  components: {Select2},
  data: () => ({
    items: [],
    selectedSite: 0,
    siteName: ""
  }),
  mounted() {
    document.addEventListener('DOMContentLoaded', function () {
      // INSERT CODE HERE
    });
    // let autocompleteInput = this.$refs.autocomplete.$refs.input
    // autocompleteInput.activeElement.blur();
  },
  created() {
    // coding continue ...
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }
    this.handleGetUserList()
    sidebarLinks.filter(v => {
      v.display = true;
    })

    this.userType = parseInt(getCookieUserType());
    this.selectedSite = getCookieSiteUser();
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/auth/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/auth/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods: {
    ...mapActions("sites", {
      getUserSiteList: "getUserSiteList"
    }),
    handleGetUserList() {
      this.getUserSiteList()
        .then(response => {
          if (response.code === 0) {
            if (response.data !== null && response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                let obj = {
                  id: response.data[i].id,
                  text: response.data[i].siteName
                }
                this.items.push(obj)
              }
              let item  = this.items.find(element => element === getCookieSiteUser());
              if(item != undefined)
                this.selectedSite = item.text
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log("[handleLogout] err", err);
        })
        .finally(final => {
        });
    },
    menuActionClick(item) {
      this.formatSelected = item.title;
      this.globalFontsize = item.value;
      EventBus.$emit('handleChangeGlobalFontSize', {size: this.globalFontsize})
    },
    onChange: function (e) {
      if (e != null) {
        setCookieSiteUser(e);
        this.selectedSite = e;
        this.$router.go();
      }
    },
    myChangeEvent(val) {
      if (val != null) {
        setCookieSiteUser(val)
        this.selectedSite = val
        this.$router.go();
      }
    }
  },
}
</script>
<style lang="scss">
.select2-results__options {
  max-height: 350px !important;
}

.v-icon.outlined {
  border: 1px solid currentColor;
}

.v-icon.smallSize {
  font-size: 0.8rem;
  height: 1.3rem;
  width: 1.3rem;
}

.v-icon.normalSize {
  font-size: 1.2rem;
  height: 29px;
  width: 29px;
}

.v-icon.largeSize {
  font-size: 1.4rem;
  width: 2rem;
  height: 2rem;
}

@media only screen and (max-width: 768px) {
  .input-ipad {
    width: 100%;
  }
}

.select2-style {
  width: 300px;
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .select2-container {
    width: 200px !important;
  }
}

</style>
