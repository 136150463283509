<template>
  <div>
    <div class="wrapper">
      <MenuHeader/>
      <menu-bar ref="menuBar" type="sidebar" :sidebar-links="$sidebar.sidebarLinks"></menu-bar>
      <div class="content-wrapper mr-1 navigator-iphone" @click="handleClickOutSideBar" :style="clientWidth">
        <NavigatorMain :name="name"></NavigatorMain>
        <router-view></router-view>
      </div>
      <FooterPage/>
    </div>
  </div>
</template>

<script>
import MenuHeader from "@/modules/header/header.vue";
import NavigatorMain from "../modules/NavigatorMain.vue";
import FooterPage from "@/components/Footer.vue";

export default {
  name: "MainPage",
  components: {
    MenuHeader,
    FooterPage,
    NavigatorMain,
  },
  data: () => ({
    name: '',
    windowWith: '',
  }),
  computed: {
    clientWidth(){
      return this.$vuetify.breakpoint.width
    }
  },
  mounted() {
    this.name = this.$router.history.current.name;
  },
  beforeUpdate() {
  },
  updated() {
    if (this.name !== this.$router.history.current.name) {
      this.name = this.$router.history.current.name;
    }
  },
  methods: {
    handleClickOutSideBar() {
      if (!document.body.classList.contains('sidebar-collapse')) {
        document.body.classList.add('sidebar-collapse')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 1180px) {
  /* For ipad 768px: */

}

.content-wrapper{
  margin-left: 0!important;
  background-color: #FFFF !important;
}

@media (max-width: 820px) {
  .content-wrapper {
    margin-top: 68px;
  }
}

@media (max-width: 540px) {
  .navigator-iphone {
    margin-top: 5px;
  }
}

</style>
