<template>
  <div ref="StackedLineMain" class="card card-iphone" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;"
                  v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) : "" }}</span>
          </template>
        </v-tooltip>
      </h3>

      <div v-if="(!isFrameSetting)" class="card-tools">
        <ButtonView v-if="isDashboard" :frameId="frameId"/>
        <ButtonViewSensor v-if="isDashboard" :frameId="frameId"/>
        <ButtonExportPdf v-if="usePdf === 1 && isDashboard" :frameId="frameId"/>
        <ButtonExportCsv v-if="useCsv === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <ButtonExportExcel v-if="useExcel === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <button v-if="isShowEdit"
          type="button"
          class="btn btn-sm" @click="clickClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div ref="tagMain" class="card-body">
      <v-chart ref="chart" class="chart" :option="option" :autoresize="true" :key="index"/>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <!-- <div class="card-footer bg-transparent">
      <div class="row"></div>
    </div> -->
  </div>
</template>
<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart, ScatterChart} from "echarts/charts";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  BrushComponent,
  MarkLineComponent,
  MarkAreaComponent,
  MarkPointComponent,
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";
import {UniversalTransition} from "echarts/features";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import {mapActions} from "vuex";
import {getCookieSiteUser} from "@/utils/auth";

use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TitleComponent,
  BrushComponent,
  MarkLineComponent,
  MarkAreaComponent,
  MarkPointComponent,
  ScatterChart
]);
export default {
  name: "SC",
  components: {
    VChart, ButtonView, ButtonViewSensor, ButtonExportCsv, ButtonExportExcel, ButtonExportPdf
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    width: Number,
    height: Number,
    series: Array,
    legend: Object,
    // title: Object,
    xAxis: Array,
    yAxis: Array,
    title: String,
    frameId: Number,
    isDashboard: Boolean,
    isFrameSetting: Boolean,
    isShowEdit: Boolean,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
  },
  data() {
    return {
      index: 0,
      heightReal: 0,
      loading: false,
      option: {
        grid: {
          left: '3%',
          right: '7%',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: this.tooltip
          // function (params) {
          //   if (params.value.length > 1) {
          //     return (
          //       params.seriesName +
          //       ' :<br/>' +
          //       params.value[0] 
          //       + '<br/>' +
          //       // this.xAxis[0].unit + ' ' +
          //       params.value[1]
          //       // this.yAxis[0].unit + ' '
          //     );
          //   } else {
          //     return (
          //       params.name +
          //       ' : ' +
          //       params.value 
          //       // +
          //       // this.yAxis[0].unit + ' '
          //     );
          //   }
          // }
          ,
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          }
        },
        // brush: {},
        legend: {
          data: [],
          left: 'center',
          bottom: 10
        },
        xAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} '
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} '
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [],
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            showDataShadow: false,
            handleIcon:
              'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%'
          },
          {
            type: 'inside',
            orient: 'vertical'
          },
          {
            type: 'slider',
            orient: 'vertical',
            showDataShadow: false,
            handleIcon:
              'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%'
          }
        ],
      },
    };
  },
  created() {
    this.option.legend = this.legend;
    this.option.series = this.series;
    this.option.xAxis = this.xAxis;
    this.option.yAxis = this.yAxis;

    if (this.isDashboard) {
      this.option.series.forEach(s=>{
        s.data = [];
      });
    }

  },
  mounted() {
    if(this.isDashboard){
      this.handleMonitorSingleSCData()
    }
    this.$emit(`ChartReady`, this.frameId);
  },
  beforeDestroy() {
    // this.$dashboardHub.$off(`MonitorTempRealtime_${this.frameId}`)
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  watch: {
    "series": function ($event) {
      if(!this.isDashboard)
        this.option.series = this.series;
      this.index++;
    },
    'xAxis': function ($event) {
      if(!this.isDashboard)
        this.option.xAxis.data = $event;
    },
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  },
  methods: {
    ...mapActions("dashboard", {
      getRealtimeSingleFrameData: "getRealtimeSingleFrameData",
      getSCData: "getSCData"
    }),
    tooltip(params){
      if (params.value.length > 1) {
        return (
          params.seriesName +
          ' :<br/>' +
          params.value[0] + ' ' +
          this.xAxis[0].unit + '<br/>' +
          params.value[1] +
          this.yAxis[0].unit
        );
      } else {
        return (
          params.name +
          ' : ' +
          params.value 
          +
          this.yAxis[0].unit + ' '
        );
      }
    },
    clickClose(){
      this.$emit(`closeFrame`, this.frameId);
    },
    handleMonitorSingleSCData() {
      this.loading = true;
      const siteId = getCookieSiteUser();
      this.getSCData({siteId:siteId,frameId:this.frameId}).then(response => {
        if (response.code === 0) {
          this.option.series.forEach(s=>{
            // const dataRealTime = [];
            // Object.keys(response.data[s.name]).map((key, index) => { dataRealTime.push[key,response.data[s.name][key]] });
            s.data = response.data[s.name].map(i=>[i.key,i.value]);
          });
        }
      })
      .catch(err => {
        if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
      })
      .finally(final => {
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

.card-iphone {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.chart {
  margin-left: 0.5rem;
}





</style>
