import request from "../utils/request";
import response from "../utils/request";

export function getCbbDevice(payload) {
    return request({
        url: "/Device/getDevice",
        method: "post",
        data: payload
    });
}

export function getDeviceBySite(payload) {
    return request({
        url: "/Device/getDeviceBySite",
        method: "post",
        data: payload
    });
}
export function getDeviceByFrameId(frameId) {
    return request({
        url: `/Device/getDeviceByFrameId/${frameId}`,
        method: "post"
    });
}

export function getDataByDeviceCode(payload) {
    return request({
        url: "/Device/getDataByDeviceCode",
        method: "post",
        data: payload
    });
}
