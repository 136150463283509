<template>
  <v-footer dark padless class="footer">
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title
        class="justify-content-md-center text-md-start  justify-content-end white--text mr-2 text-ipad">
        <strong class="subheading"> Copyright(c) KEISOKU NET SERVICE Co., Ltd. All Rights Reserved,</strong>
      </v-card-title>
    </v-card>
  </v-footer>
</template>

<script>
import {version} from "../../package.json";
import {DateTime} from "luxon";

export default {
  name: "FooterPage",
  props: {
    classWrapper: {
      type: String,
      default: ""
    },
    classTextColor: {
      type: String,
      default: "white--text"
    }
  },
  data: () => ({
    version: version,
    currentYear: DateTime.now().toFormat("y"),
  }),
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1450px) {
  /* For ipad 768px: */
  .text-ipad {
    font-size: 17px;
  }
  .footer {
    display: none;
  }
}


</style>
