import request from "../utils/request";

export function getDeviceList(payload) {
    return request({
        url: "/Device/getDeviceList",
        method: "post",
        data: payload
    });
}

export function addDevice(payload) {
    return request({
        url: "/Device/addDevice",
        method: "post",
        data: payload
    });
}

export function getDeviceDetail(deviceCode) {
    return request({
        url: "Device/deviceDetail/" + deviceCode,
        method: "post"
    });
}

export function updateDevice(payload) {
    return request({
        url: "/Device/updateDevice",
        method: "put",
        data: payload
    });
}

export function deleteDevice(deviceCode) {
    return request({
        url: "/Device/deleteDevice/" + deviceCode,
        method: "post",
        data: {}
    });
}
