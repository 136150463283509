<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ ($i18n.locale === 'en') ? 'User Information' : 'ユーザー情報' }}</span>
        <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-container class="pt-0 pb-0">
            <div class="row mt-2">
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.userId") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="20" v-model="editedItem.userId" hide-details="auto" :disabled="checkDetail"
                              outlined dense>
                </v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.password") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <div v-if="!checkDetail">
                  <v-text-field hide-details="auto"
                                :type="showPassword ? 'text' : 'password'"
                                maxlength="30"
                                :append-outer-icon="editMode ? (showIconEye ? 'mdi-window-close' : 'mdi-pencil') : ''"
                                :append-icon="(showIconEye || !editMode || !editPasswordFlag) ? (showPassword ? 'fas fa-eye' : 'fa-eye-slash') : ''"
                                name="input-10-2" class="input-group--focused"
                                @click:append="showPassword = !showPassword"
                                @click:append-outer="editPasswordFlag = !editPasswordFlag"
                                outlined dense v-model.trim="editedItem.password"
                                :readonly="editPasswordFlag === true && editMode"
                                placeholder="********"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-text-field maxlength="30" type="password" v-model="editedItem.password" name="input-10-2"
                                placeholder="********"
                                class="input-group--focused" outlined dense :disabled="checkDetail"></v-text-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.userName") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="50" v-model="editedItem.userName" hide-details="auto" :disabled="checkDetail"
                              outlined dense>
                </v-text-field>
              </div>

              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.phoneNumber") }}</h6>
                </div>
                <v-text-field maxlength="30" v-model="editedItem.phoneNumber" hide-details="auto"
                              :disabled="checkDetail"
                              outlined dense>
                </v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.email") }}</h6>
                </div>
                <v-text-field maxlength="100" v-model="editedItem.email" hide-details="auto" :disabled="checkDetail"
                              outlined dense>
                </v-text-field>
              </div>

              <div class="col-xl-6 col-sm-12 pb-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.status") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-select :items="optionsStatus" v-model="editedItem.status" :disabled="checkDetail"
                          outlined dense></v-select>
              </div>
            </div>


            <div class="row">
              <div class="col-12 pt-0">
                <div class="d-flex justify-content-start ">
                  <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.role") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-autocomplete outlined dense :items="optionsRoles" key="value" item-text="text" item-value="value"
                                v-model="editedItem.role" :disabled="checkDetail" @change="changeRole"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row">
              <div class="col-12 pt-0">
                <h6 class="mb-1 label-black">{{ $t("labels.userManager.address") }}</h6>
                <v-textarea maxlength="200" v-model="editedItem.address" auto-grow outlined rows="5" row-height="15"
                            :disabled="checkDetail" >
                </v-textarea>
              </div>
            </div>

            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6">
                <h6 class="mb-1 label-black">{{ $t("labels.userManager.createDate") }}</h6>
                <v-text-field v-model="editedItem.createDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6">
                <h6 class="mb-1 label-black">{{ $t("labels.userManager.createBy") }}</h6>
                <v-text-field v-model="editedItem.createBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6 pb-0">
                <h6 class="mb-1 label-black">{{ $t("labels.userManager.updateDate") }}</h6>
                <v-text-field v-model="editedItem.updateDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6 pb-0">
                <h6 class="mb-1 label-black">{{ $t("labels.userManager.updateBy") }}</h6>
                <v-text-field v-model="editedItem.updateBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.userManager.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";
import {getCookieSiteUser, getRememberKCloudID} from "@/utils/auth";
import moment from "moment";
import $ from 'jquery';

export default {
  name: "UserManagerProfileModal",
  props: {
    dialog: Boolean,
    detailFlag: Boolean
  },
  data() {
    return {
      valid: false,
      loading: false,
      title: "",
      showDialog: false,
      editMode: false,
      checkDetail: false,
      showPassword: false,
      showIconEye: false,
      editPasswordFlag: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      editedItem: {
        indexUser: 0,
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createBy: '',
        email: '',
        phoneNumber: '',
        address: '',
        updateBy: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      },
      optionsStatus: [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ],
      optionsRoles: [],
      userInfo: {
        index: 0,
        siteId: 0
      },
      headerRequest: {
        'PAGE-CODE': 'CS_UM'
      },
      PAGE_CODE: 'CS_UM',
      isSubmitAction: false,
      items: [],
      flagAdd: false,
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/users/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      this.optionsStatus = [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ];
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
    dialog(val) {
      this.showDialog = val
      this.handleGetDetailUserManagement();
    },
    detailFlag(val) {
      this.checkDetail = val;
    }
  },
  async beforeMount() {
    if (this.checkDetail === true) {
      this.loading = true;
    }
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  created() {
    this.handleGetRoleListBySiteId();
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions("users", {
      getDetailUserManagement: "getDetailUserManagement",
      addUserManagement: "addUserManagement",
      updateUserManagement: "updateUserManagement"
    }),
    ...mapActions("roles", {
      getRoleListBySiteId: "getRoleListBySiteId"
    }),
    handleGetRoleListBySiteId() {
      let obj = {
        siteId: getCookieSiteUser(),
        PAGE_CODE: this.PAGE_CODE
      }
      this.optionsRoles = []
      this.getRoleListBySiteId(obj)
        .then(response => {
          if (response.code === 0) {
            if (response.data !== null && response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                let option = {
                  value: response.data[i].id,
                  text: response.data[i].name
                }
                this.optionsRoles.push(option)
              }
            }

          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleGetDetailUserManagement() {
      this.userInfo.userId = getRememberKCloudID();
      this.userInfo.siteId = getCookieSiteUser();
      let object = {
        json: this.userInfo,
        header: this.headerRequest
      }
      this.getDetailUserManagement(object)
        .then(response => {
          if (response.code === 0) {
            this.editedItem = response.data.userdetail;
            if (response.data.userdetail !== null) {
              this.editedItem.createDate = moment(this.editedItem.createdDate).format('YYYY/MM/DD');
              this.editedItem.updateDate = moment(this.editedItem.createdDate).format('YYYY/MM/DD');
              this.editedItem.role = this.editedItem.roleId;
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_CLOSE'], () => {
          this.$emit('update:dialog', false);
          $('#main-header').css("pointer-events", "");
          this.handleResetUserInfo();
        });
      } else {
        this.$emit('update:dialog', false);
        $('#main-header').css("pointer-events", "");
        this.handleResetUserInfo();
      }
    },
    checkActionCheckMinLength(field, number) {
      let text = this.ERROR_CODE['E_052_01_13'];
      text = text.replace("[X]", this.$t(field)).replace("[Y]", number);
      return text;
    },
    changeRole(e) {
      this.editedItem.role = e
    },
    handleResetUserInfo() {
      this.editedItem = {
        indexUser: 0,
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createBy: '',
        email: '',
        phoneNumber: '',
        address: '',
        updateBy: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      }
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}

.v-input--has-state fieldset {
  border: 2px solid red !important;
}

.v-messages__message {
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
