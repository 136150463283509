// eslint-disable-next-line no-unused-vars
import {
  addWarning,
  getDeviceList,
  getDeviceProperty,
  getStation, getWarningByFrameId,
  getWarningList,
  removeWarning,
  updateWarning,
  getWarningCategories,
  getPatrolLightList,
  savePatrolLight,
  deletePatrolLight, testPatrolLightCommand, autoTurnOffPatrolLight
} from "@/api/warning";

const actions = {
  getDeviceList({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getDeviceList(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDeviceProperty({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getDeviceProperty(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPatrolLightList({commit, state}, data){
    return new Promise((resolve, reject) => {
      getPatrolLightList(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  savePatrolLight({commit, state}, data){
    return new Promise((resolve, reject) => {
      savePatrolLight(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deletePatrolLight({commit, state}, data){
    return new Promise((resolve, reject) => {
      deletePatrolLight(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  testPatrolLightCommand({commit, state}, data){
    return new Promise((resolve, reject) => {
      testPatrolLightCommand(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  autoTurnOffPatrolLight({commit, state}, data){
    return new Promise((resolve, reject) => {
      autoTurnOffPatrolLight(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWarningCategories({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getWarningCategories(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getStation({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getStation(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWarningList({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getWarningList(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addWarning({commit, state}, data) {
    return new Promise((resolve, reject) => {
      addWarning(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateWarning({commit, state}, data) {
    return new Promise((resolve, reject) => {
      updateWarning(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeWarning({commit, state}, data) {
    return new Promise((resolve, reject) => {
      removeWarning(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWarningByFrameId({commit, state}, frameId) {
    return new Promise((resolve, reject) => {
      getWarningByFrameId(frameId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

