<template>
  <v-dialog content-class="pt-10" v-model="showDialog" max-width="1500px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light title-container">
        <span class="text-h5 label-black title-iphone">{{ $t(nameLabel) }}</span>
        <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
          <v-icon id="close-button" class="button-iphone">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <div class="">
          <grid-layout :layout="layout" :col-num="12" :row-height="frametypeChoice === 'MWC' && frametypeChoice === 'SCS' ? 170 : 100"
                       :maxRows="10"
                       :margin="margin"
                       :is-draggable="true" :is-resizable="true" :responsive="true" :vertical-compact="true"
                       :use-css-transforms="true">

            <grid-item v-for="item in layout" :static="item.static" :x="item.x" :y="item.y" :w="item.w"
                       :h="item.h" :i="item.i" :key="item.id" @resize="resizeEvent" @resized="resizedEvent"
                       :is-resizable="true" @container-resized="containerResizedEvent">
              <div v-if="item.type === 'toolbox'">
                <div class="">
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                      <!-- Frame GC -->
                      <div v-if="frametypeChoice === 'GC'" class="row m-1">
                        <div class="col-xl-6 col-lg-6 col-12" style="padding-left: 0px !important;">
                          <div class="d-flex justify-content-start">
                            <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.frametype") }}
                            </h6>
                            <h5 class="text-danger mb-0">*</h5>
                          </div>
                          <div class="input-group-append">
                            <v-select :items="frameType" :item-text="$i18n.locale === 'en' ? 'nameEn': 'nameJp' "
                                      item-value="code"
                                      v-model="frametypeChoice" outlined required dense
                                      @change="changeChart(frametypeChoice)"
                                      :disabled="checkDetail || editFlag">
                            </v-select>
                          </div>
                        </div>
                      <div class="col-xl-6 col-lg-6 col-12" style="padding-right: 0px !important;">
                        <div class="d-flex justify-content-start frameName-GC">
                          <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.framename") }}
                          </h6>
                          <h5 class=" text-danger mb-0">*</h5>
                        </div>
                        <div class="input-group-append inputName-GC">
                          <v-text-field hide-details="auto" outlined dense maxlength="200"
                                        :rules="requiredRule(editedItem.frameName, 'labels.framesetting.framename')"
                                        v-model="editedItem.frameName" :disabled="checkDetail">
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                    <!-- Frame GC -->


                    <div v-if="frametypeChoice !== 'GC'" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.frametype") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select :items="frameType" :item-text="$i18n.locale === 'en' ? 'nameEn': 'nameJp' "
                                  item-value="code"
                                  v-model="frametypeChoice" outlined required dense
                                  @change="changeChart(frametypeChoice)"
                                  :disabled="checkDetail || editFlag">
                        </v-select>
                        <v-btn
                          v-if="(frametypeChoice === 'SVG' || frametypeChoice === 'SVG-TS') && editFlag === false && checkDetail === false"
                          depressed
                          color="primary " class="ml-2 mt-1"
                          style="background-color: #3d76ad; "
                          @click="uploadFile(1)">
                          <v-icon color="white" size="16" class="mr-2 dark mt-0">
                            fa fa-upload
                          </v-icon>
                          <span style="font-size: 16px" class="font-weight-bold">{{
                              $t('fileManager.import')
                            }}</span>
                        </v-btn>
                      </div>
                    </div>
                    <div v-if="frametypeChoice !== 'GC'" class="mb-4">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.framename") }}
                        </h6>
                        <h5 class=" text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-text-field hide-details="auto" outlined dense maxlength="200"
                                      :rules="requiredRule(editedItem.frameName, 'labels.framesetting.framename')"
                                      v-model="editedItem.frameName" :disabled="checkDetail">
                        </v-text-field>
                      </div>
                    </div>
                    <div v-if="stationflg" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.station") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select @change="handleStation()" v-model="selectedStation" :items="stationListFilter"
                                  :rules="requiredRule(selectedStation, 'labels.framesetting.station')"
                                  :no-data-text="$t('exportData.noData')"
                                  validate-on-blur
                                  required
                                  item-text="label"
                                  item-value="value" outlined dense :disabled="checkDetail"></v-select>
                      </div>
                    </div>
                    <div v-if="checkIP">
                      <div v-if="!IPFlg" class="row">
                        <div class="mt-1 col-12">
                          <div class="d-flex justify-content-start ">
                            <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.link") }}
                            </h6>
                            <h5 class=" text-danger mb-0">*</h5>
                          </div>
                          <div class="input-group-append">
                            <v-text-field hide-details="auto"
                                          maxlength="50"
                                          :rules="requiredRule(editedItem.link,'labels.framesetting.link')"
                                          outlined dense v-model="editedItem.link" :disabled="checkDetail">
                            </v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="deviceflg" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.device") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select v-model="deviceCode" :items="deviceList"
                                  :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'"
                                  @change="handleChangeDeviceSCTC($event)"
                                  :rules="requiredRule(deviceCode, 'labels.framesetting.device')"
                                  outlined required dense
                                  item-value="value"
                                  item-disabled="disable"
                                  :disabled="checkDetail || (frametypeChoice === 'TC' && editFlag)">
                        </v-select>
                      </div>
                    </div>

                    <div v-if="isTSSC" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.orgNo") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select v-model="orgNo" :items="ItemOrgInfoAll"
                                  :item-text="($i18n.locale === 'en') ?'orgNameEn':'orgName'"
                                  @change="handleChangeOrgNoSC($event)"
                                  :rules="isTSSC ? requiredRule(orgNo, 'labels.framesetting.orgNo') : []"
                                  outlined required dense
                                  item-value="orgNo"
                                  :disabled="checkDetail">
                        </v-select>
                      </div>
                    </div>

                    <!-- Frame Table -->
                    <div v-if="frametypeChoice === 'TC'" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.station") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <!-- TS -->
                        <v-select
                          @change="handleStation()" v-model="selectedStation" :items="stationList"
                          :rules="requiredRule(selectedStation, 'labels.framesetting.station')"
                          validate-on-blur
                          required
                          item-text="label"
                          tem-value="value" outlined dense :disabled="checkDetail">
                        </v-select>
                      </div>

                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.automaticUpdateInterval") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select @change="handleStation()" v-model="editedItem.autoUpdateTime" :items="listOptionTimeComputed"
                                  :rules="requiredRule(editedItem.autoUpdateTime, 'labels.framesetting.automaticUpdateInterval')"
                                  validate-on-blur
                                  required
                                  item-text="text"
                                  item-value="value" outlined dense :disabled="checkDetail">
                        </v-select>
                      </div>

                      <div v-if="!isTableTs"  class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.exportType") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div v-if="!isTableTs" class="input-group-append">
                        <v-select @change="handleStation()" v-model="editedItem.exportType" :items="listOptionExportTypeComputed"
                                  :rules="requiredRule(editedItem.exportType, 'labels.framesetting.exportType')"
                                  validate-on-blur
                                  required
                                  item-text="text"
                                  item-value="value" outlined dense :disabled="isTableTs || checkDetail">
                        </v-select>
                      </div>

                      <!-- Channel no -->
                      <div v-if="isTableTs" class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.channelNo") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div v-if="isTableTs" class="input-group-append">
                        <v-select @change="handleTCTSOrg()" v-model="editedItem.channelNo" :items="ItemOrgInfoAll"
                                  :rules="requiredRule(editedItem.channelNo, 'labels.framesetting.channelNo')"
                                  validate-on-blur
                                  required
                                  :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'"
                                  item-value="orgNo" outlined dense :disabled="checkDetail">
                        </v-select>
                      </div>
                    </div>
                    <!-- Frame Table -->

                    <!-- //Scatter -->
                    <div v-if="frametypeChoice === 'SC'" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.data1") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select v-model="dataSc1" :items="dataListSc1"
                                  item-text="name"
                                  @change="handleChangeDataSC($event,1)"
                                  :rules="requiredRule(dataSc1, 'labels.framesetting.data1')"
                                  outlined required dense
                                  item-value="value"
                                  item-disabled="disable"
                                  :disabled="checkDetail">
                        </v-select>
                      </div>
                    </div>
                    <div v-if="frametypeChoice === 'SC'" class="mt-2">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{ $t("labels.framesetting.data2") }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <v-select v-model="dataSc2" :items="dataListSc2"
                                  item-text="name"
                                  @change="handleChangeDataSC($event,2)"
                                  :rules="requiredRule(dataSc2, 'labels.framesetting.data2')"
                                  outlined required dense
                                  item-value="value" item-disabled="disable"
                                  :disabled="checkDetail">
                        </v-select>
                      </div>
                    </div>
                    <!-- //From date -->
                    <div v-if="frametypeChoice === 'SC'" class="mt-3">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{
                            $t("labels.framesetting.ScatterChartFromDateTime")
                          }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-12">
                            <v-menu ref="menu" v-model="menuFrom"
                                    :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fromFormatted" :rules="fromDateRules" :readonly="true"
                                              append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                              hide-details="auto" outlined dense
                                              @click:append="menuFrom = true" :disabled="checkDetail"
                                              @blur="dateFrom = parseDate(fromFormatted)">
                                </v-text-field>
                              </template>
                              <v-date-picker :disabled="checkDetail" v-model="dateFrom" :allowed-dates="disableStartDates"
                                             no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuFrom = false">
                                  {{ $t("exportData.cancel") }}
                                </v-btn>
                                <v-btn text color="primary"
                                       @click="menuFrom = false">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- //To date -->
                    <div v-if="frametypeChoice === 'SC'" class=" mt-5">
                      <div class="d-flex justify-content-start ">
                        <h6 class="mr-1 mb-0 label-black">{{
                            $t("labels.framesetting.ScatterChartToDateTime")
                          }}
                        </h6>
                        <h5 class="text-danger mb-0">*</h5>
                      </div>
                      <div class="input-group-append">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-12">
                            <v-menu ref="menu" v-model="menuTo" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="toFormatted" :disabled="checkDetail" :rules="toDateRules" :readonly="true"
                                              append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                              hide-details="auto" outlined dense
                                              @click:append="menuTo = true"
                                              @blur="dateTo = parseDate(toFormatted)">
                                </v-text-field>
                              </template>
                              <v-date-picker :disabled="checkDetail" v-model="dateTo" no-title :allowed-dates="disableEndDates"
                                             scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuTo = false">
                                  {{ $t("exportData.cancel") }}
                                </v-btn>
                                <v-btn text color="primary"
                                       @click="menuTo = false">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>

                    </div>
                  </v-form>
                </div>

              </div>
              <div v-else-if="item.type === 'Chart'">
                <component ref="chartComponent" :is="modurnComponent" :title="editedItem.frameName" :key="modurn"
                           :height="item.height" :type="frametypeChoice"
                           :yAxis="yAxis" :xAxis="xAxis" :legend="legend" :series.sync="series"
                           :typeDefault="typeDefault"
                           :link="editedItem.link"
                           :frameId="editedItem.frameId"
                           :deviceCodeProp.sync="deviceCode"
                           :content="svgContent"
                           :listTable="listTable"
                           :isFrameSetting="true"
                           :selectedRowProp.sync="selectedRow"
                           :yOffset="yOffset"
                           :isDetailProp="checkDetail"
                           @change-pointer="onChangePointer"
                           @change-header="onChangeHeader"
                           @ChartReady="onChartCreated"
                />
              </div>
              <div v-else-if="item.type === 'table'">
                <v-form
                  ref="form2"
                  v-model="valid2"
                  lazy-validation>
                  <!-- Frame type table -->
                  <v-data-table v-if="frametypeChoice === 'TC'"
                      :items="listHeaderFrameTable"
                      hide-default-footer
                      disable-pagination
                      class="elevation-2 table-TC" mobile-breakpoint="0" fixed-header locale="ja"
                  >
                    <template slot="no-data">
                        {{ $t("notification.noData") }}
                    </template>
                    <template #header="{ props: {} }">
                      <thead class="v-data-table-header">
                        <tr>
                          <th :rowspan="2">
                            #
                          </th>
                          <th :rowspan="2">
                            {{ $t("labels.framesetting.data") }}
                          </th>
                          <th :rowspan="2">
                            {{ $t("labels.framesetting.headerName") }}
                          </th>
                          <th  :rowspan="2">
                            {{ $t("labels.framesetting.display") }}
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:body="props">
                      <draggable
                        v-model="listHeaderFrameTable"
                        tag="tbody"
                        @end="onDragAndDrop"
                      >
                        <tr
                          v-for="(item) in props.items"
                          :key="item.index"
                        >
                          <td class="mx-auto text-center" v-if="item.index"> <span> {{ item.index }} </span> </td>
                          <td v-if="item.index"> {{ item.dataName }} </td>
                          <td v-if="item.index">
                            <v-text-field
                              outlined dense type="text"
                              hide-details="false"
                              :rules="requiredRule(item.text, 'labels.framesetting.headerName')"
                              validate-on-blur
                              required
                              :disabled="checkDetail"
                              v-model="item.text"
                              @change="onUpdateTableHeader"
                              >
                            </v-text-field>
                          </td>
                          <td class="d-flex justify-center" v-if="item.index">
                            <v-checkbox
                              :disabled="checkDetail"
                              v-model="item.isDisplay"
                              color="primary"
                              :value="item.isDisplay"
                              @change="onUpdateTableHeader"
                            ></v-checkbox>
                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                  <!-- Frame type table -->
                  <v-data-table v-if="frametypeChoice === 'SC'" :headers="headers" :items="listTable" disable-pagination
                                class="elevation-2 table-SC" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header
                                @click:row="(item) => onSelectRow(item)" hide-default-footer :footer-props="{
              'items-per-page-options': [200]
            }"
            >
            <template slot="no-data">
                      {{ $t("notification.noData") }}
                    </template>
                    <template #header="{ props: {} }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th :rowspan="2">
                          #
                        </th>
                        <th :rowspan="2">
                          {{ $t("labels.framesetting.station") }}
                        </th>
                        <th v-if="isIncludesDevice1000 || isTSSC" :rowspan="2">
                          {{ $t("labels.framesetting.tsStation") }}
                        </th>
                        <th v-if="frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'"
                            :rowspan="2">
                          {{ $t("labels.framesetting.color") }}
                        </th>
                        <th  :rowspan="2">
                          {{ $t("genba.table.action") }}
                        </th>

                      </tr>
                      </thead>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat v-if="!checkDetail">
                        <v-spacer></v-spacer>
                        <v-btn depressed color="primary" style="background-color: #3d76ad"
                               :disabled="!isFullDataLineTable" @click="createItem">
                          <v-icon color="white" size="20" class="mr-2 dark mt-0">
                            mdi-plus-box
                          </v-icon>
                          <span style="font-size: 17px" class="font-weight-bold">{{
                              $t("labels.add")
                            }}</span>
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.device`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select style="width:150px;" v-if="frametypeChoice !== 'SVG-TS' && frametypeChoice !== 'SC'" v-model="item.device" :items="item.Itemdevices"
                                  item-text="name" :rules="deviceRules"
                                  @click="handleClickDevice(item)"
                                  @change="handleChangeDevice(item)" item-value="value"
                                  hide-details="true" outlined dense
                                  item-disabled="disable"
                                  :disabled="checkDetail || item.stationname == null">
                        </v-select>
                        <p v-else class="m-auto">TS</p>
                      </div>
                    </template>
                    <template v-if="isIncludesDeviceChannel" v-slot:[`item.channelNo`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="item.channelFlg == 1 && frametypeChoice !== 'SVG'" style="width:150px;" v-model="item.channelNo" :items="item.ItemChannelList"
                                  @change="handleChangeChannelNo(item)"
                                  item-text="name" item-value="value" :rules="requiredRule(item.channelNo, 'labels.framesetting.channelName')"
                                  hide-details="true" outlined dense :disabled="checkDetail || item.device === null">
                        </v-select>
                        <v-select v-else-if="item.channelFlg == 1 && frametypeChoice === 'SVG'" v-model="item.channelNoLst" :items="item.ItemChannelList"
                                  item-text="name" :rules="[]"
                                  chips multiple deletable-chips
                                  @change="handleChangeChannelNo(item, $event)"
                                  hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device === null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-if="isIncludesDevice1000 || isTSSC" v-slot:[`item.tsStation`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="([1000].includes(item.device) && !isTSSC)"  style="width:150px;" v-model="item.tsStation" :items="item.ItemTsStations"
                                  @change="handleChangeTsStationOtherChart(item)"
                                  item-text="name" item-value="value" :rules="item.device === 1000 ? requiredRule(item.tsStation, 'labels.framesetting.tsStation') : []"
                                  hide-details="true" outlined dense :disabled="checkDetail || item.device !== 1000">
                        </v-select>
                        <v-select v-else-if="isTSSC"  style="width:150px;" v-model="item.tsStation" :items="item.ItemTsStations"
                                  item-disabled="disable"
                                  @change="handleChangeTsStationSC(item)"
                                  item-text="name" item-value="value" :rules="requiredRule(item.tsStation, 'labels.framesetting.tsStation')"
                                  hide-details="true" outlined dense :disabled="checkDetail || item.stationname == null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-if="isIncludesDevice1000" v-slot:[`item.orgNo`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="[1000].includes(item.device)"  style="width:150px;" v-model="item.orgNo" :items="item.ItemOrgNo"
                                  :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'" item-value="orgNo" :rules="item.device === 1000 ? requiredRule(item.orgNo, 'labels.framesetting.orgNo') : []"
                                  @change="handleChangeOrgNo(item)"
                                  hide-details="true" outlined dense :disabled="checkDetail  || item.device !== 1000 || item.tsStation == null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-slot:[`item.data`]="{ item }">
                      <div class="d-flex align-items-center">
                        <v-select style="width:150px;" v-if="frametypeChoice !== 'SVG'" v-model="item.data" :items="item.ItemData"
                                  item-text="name" :rules="dataRules"
                                  @change="handleChangeData(item, $event)" item-value="value"
                                  item-disabled="disable" hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device == null || (item.device === 1000 && item.orgNo == null) || (item.channelFlg == 1 && item.channelNo == null)">
                        </v-select>

                        <v-select v-if="frametypeChoice === 'SVG'" v-model="item.dataCodes" :items="item.ItemData"
                                  item-text="name" :rules="dataRules"
                                  chips multiple deletable-chips
                                  @change="handleChangeData(item, $event)"
                                  hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device == null || (frametypeChoice === 'SVG' && item.channelFlg == 1 && item.channelNoLst.length === 0)">
                        </v-select>
                      </div>
                    </template>

                    <template v-slot:[`item.legendName`]="{ item }">
                      <v-text-field :style="frametypeChoice !== 'GC' ? 'width:150px;' :''" maxlength="200" v-model="item.legendName" :rules="requiredRule(item.legendName, 'labels.framesetting.legendName')" @change="handleChangeLegendName(item, $event)" outlined dense hide-details="true" :disabled="checkDetail || item.data == null"></v-text-field>
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                      <input type="checkbox" style="width: 16px; height: 16px"
                             class="form-check-input-sub"
                             @change="onChangeView(item)"
                             :disabled="!item.horizontal && !item.vertical" checked
                      />
                    </template>
                    <template v-slot:[`item.color`]="{ item }">

                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             id="exampleColorInput" v-model="item.color" title="Choose your color"
                             @change="handleChaneColor(item)" :disabled="checkDetail">

                    </template>
                    <!-- Warning 1 -->
                    <template v-slot:[`item.display1`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <!-- <v-checkbox v-if="item.hasWarning === true"
                          v-model="item.display1" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay1(item.display1, item)"
                                      :disabled="checkDetail || item.data == null">
                          </v-checkbox>
                          <v-icon v-else color="red" size="25" class="mr-2 dark mt-0">
                            mdi-close-box
                          </v-icon> -->
                          <v-checkbox v-model="item.display1" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay1(item.display1, item)"
                                      :disabled="checkDetail || item.data == null">
                          </v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color1`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color1" title="Choose your color"
                                 @change="handleChangeColorW1(item)"
                                 :disabled="checkDetail || item.data == null ">
                                <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 1 -->
                    <!-- Warning 2 -->
                    <template v-slot:[`item.display2`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <v-checkbox v-model="item.display2" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay2(item.display2, item)"
                                      :disabled="checkDetail || item.data == null"></v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color2`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color2" title="Choose your color"
                                 @change="handleChangeColorW2(item)"
                                 :disabled="checkDetail || item.data == null" >
                                 <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 2 -->
                    <!-- Warning 3 -->
                    <template v-slot:[`item.display3`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <v-checkbox v-model="item.display3" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay3(item.display3, item)"
                                      :disabled="checkDetail || item.data == null"></v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color3`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color3" title="Choose your color"
                                 @change="handleChangeColorW3(item)"
                                 :disabled="checkDetail || item.data == null">
                                  <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 3 -->
                    <template v-slot:[`item.line`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.selected" :disabled="checkDetail"
                                       @change="handleChaneTypeChart(item)">
                          <v-radio value="line"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.bar`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.selected" :disabled="checkDetail"
                                       @change="handleChaneTypeChart(item)">
                          <v-radio value="bar"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.chart1`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.posChart" :disabled="checkDetail"
                                       @change="handleChanePosChart(item)">
                          <v-radio value="chart1" :disabled="true"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.chart2`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.posChart" :disabled="checkDetail"
                                       @change="handleChanePosChart(item)">
                          <v-radio value="chart2" :disabled="true"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.stationname`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select style="width:150px;" v-model="item.stationname" :items="(frametypeChoice !== 'SC') ? stationList : item.stationList" item-text="label"
                                  @change="handleChangeStation(item)" item-value="value" :rules="stationIdRules" :item-disabled="isTSSC?'':'disable'"
                                  hide-details="true" outlined dense :disabled="checkDetail">
                        </v-select>
                      </div>
                    </template>
                    <template v-slot:[`item.pointer`]="{ item }">
                      <v-select v-model="item.pointer" :items="dataPointer" item-text="name"
                                append-icon=""
                                item-value="value" item-disabled="disable" single-line return-object
                                hide-details="true" solo disabled></v-select>
                    </template>
                    <template v-slot:[`item.colorPointer`]="{ item }">
                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             :disabled="checkDetail" v-model="item.colorPointer" @change="onChanecolorPointer(item)"
                             title="Choose your color">
                    </template>
                    <template v-slot:[`item.tablecolor`]="{ item }">
                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             :disabled="checkDetail" v-model="item.tablecolor" title="Choose your color">
                    </template>
                    <template v-slot:[`item.vertical`]="{ item }">
                      {{ item.vertical }}
                    </template>
                    <template v-slot:[`item.horizontal`]="{ item }">
                      {{ item.horizontal }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <div>
                        <button v-if="listTable.length > 1" class="m-1" @click.prevent="onDeleteLine(item)" :disabled="checkDetail">
                          <i class="fas fa-trash-alt  fa-lg pt-2 pb-2" :class="checkDetail?'text-dark':'text-danger'">
                          </i>
                        </button>
                      </div>
                    </template>

          </v-data-table>
                  <v-data-table v-if="!flgTable && frametypeChoice !== 'SC'" :headers="headers" :items="listTable" disable-pagination
                                class="elevation-2 table-Frame" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header
                                @click:row="(item) => onSelectRow(item)" hide-default-footer :footer-props="{
              'items-per-page-options': [200]
            }"
                  >
                    <template slot="no-data">
                      {{ $t("notification.noData") }}
                    </template>
                    <template #header="{ props: {} }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th :rowspan="2">
                          #
                        </th>
                        <th :rowspan="2">
                          {{ $t("labels.framesetting.station") }}
                        </th>
                        <th v-if="frametypeChoice !== 'SC'" :rowspan="2">
                          {{ $t("labels.framesetting.device") }}
                        </th>
                        <th v-if="isIncludesDeviceTS || isTSSC" :rowspan="2">
                          {{ $t("labels.framesetting.tsStation") }}
                        </th>
                        <th v-if="isIncludesDeviceTS && frametypeChoice !== 'SC'" :rowspan="2">
                          {{ $t("labels.framesetting.orgNo") }}
                        </th>
                        <th v-if="isIncludesDeviceChannel && frametypeChoice !== 'SC'" :rowspan="2">
                          {{ $t("labels.framesetting.channelName") }}
                        </th>
                        <th v-if="frametypeChoice === 'SVG'" :rowspan="2">
                          {{ $t("labels.framesetting.pointer") }}
                        </th>
                        <th v-if="frametypeChoice === 'SVG-TS' || frametypeChoice === 'SVG-TS'" :rowspan="2">
                          {{ $t("labels.framesetting.svgViewLabels") }}
                        </th>
                        <th v-if="frametypeChoice === 'SVG' || frametypeChoice === 'SVG-TS'" :rowspan="2">
                          {{ $t("labels.framesetting.colorPointer") }}
                        </th>
                        <th :rowspan="2" v-if="frametypeChoice !== 'SVG-TS' && frametypeChoice !== 'SC'">
                          {{ $t("labels.framesetting.data") }}
                        </th>
                        <th :rowspan="2" v-if="frametypeChoice === 'LS' || frametypeChoice === 'GLS' || frametypeChoice === 'MAC' || frametypeChoice === 'LR'"> <!--apply linestack chart -->
                          {{ $t("labels.framesetting.legendName") }}
                        </th>
                        <th v-if="frametypeChoice === 'MAC'" :rowspan="2">
                          {{ $t("labels.framesetting.line") }}
                        </th>
                        <th v-if="frametypeChoice === 'MAC'" :rowspan="2">
                          {{ $t("labels.framesetting.bar") }}
                        </th>
                        <th v-if="frametypeChoice === 'SVG' || frametypeChoice === 'SVG-TS'" :rowspan="2">
                          {{ $t("labels.framesetting.vertical") }}
                        </th>
                        <th v-if="frametypeChoice === 'SVG' || frametypeChoice === 'SVG-TS'" :rowspan="2">
                          {{ $t("labels.framesetting.horizontal") }}
                        </th>
                        <th v-if="frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'"
                            :rowspan="2">
                          {{ $t("labels.framesetting.color") }}
                        </th>
                        <th
                          v-if="frametypeChoice !== 'SC' && frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'"
                          :colspan="2">
                          {{ $t('alarmSetting.primaryThreshold') }}
                        </th>
                        <th
                          v-if="frametypeChoice !== 'SC' && frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'"
                          :colspan="2">
                          {{ $t('alarmSetting.secondaryThreshold') }}
                        </th>
                        <th
                          v-if="frametypeChoice !== 'SC' && frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'"
                          :colspan="2">
                          {{ $t('alarmSetting.tertiaryThreshold') }}
                        </th>
                        <th v-if="frametypeChoice === 'GLS'" :rowspan="2">
                          {{ $t("labels.framesetting.chart1") }}
                        </th>
                        <th v-if="frametypeChoice === 'GLS'" :rowspan="2">
                          {{ $t("labels.framesetting.chart2") }}
                        </th>
                        <th v-if="frametypeChoice === 'GC'" :rowspan="2">
                          {{ $t("labels.framesetting.displayName") }}
                        </th>
                        <th  :rowspan="2">
                          {{ $t("genba.table.action") }}
                        </th>

                      </tr>
                      <tr
                        v-if="frametypeChoice !== 'SC' && frametypeChoice !== 'SVG' && frametypeChoice !== 'GC' && frametypeChoice !== 'SVG-TS'">
                        <th>
                          {{ $t('alarmSetting.display') }}
                        </th>
                        <th>
                          {{ $t("labels.framesetting.color") }}
                        </th>
                        <th>
                          {{ $t('alarmSetting.display') }}
                        </th>
                        <th>
                          {{ $t("labels.framesetting.color") }}
                        </th>
                        <th>
                          {{ $t('alarmSetting.display') }}
                        </th>
                        <th>
                          {{ $t("labels.framesetting.color") }}
                        </th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat v-if="!checkDetail">
                        <v-spacer></v-spacer>
                        <v-btn depressed color="primary" style="background-color: #3d76ad"
                               :disabled="!isFullDataLineTable" @click="createItem">
                          <v-icon color="white" size="20" class="mr-2 dark mt-0">
                            mdi-plus-box
                          </v-icon>
                          <span style="font-size: 17px" class="font-weight-bold">{{
                              $t("labels.add")
                            }}</span>
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-if="frametypeChoice === 'SVG' || frametypeChoice === 'SVG-TS'"
                              v-slot:[`item.order`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        {{ item.order }}
                      </div>
                    </template>
                    <template v-slot:[`item.device`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select style="width:150px;" v-if="frametypeChoice !== 'SVG-TS' && frametypeChoice !== 'SC'" v-model="item.device" :items="item.Itemdevices"
                                  item-text="name" :rules="deviceRules"
                                  @click="handleClickDevice(item)"
                                  @change="handleChangeDevice(item)" item-value="value"
                                  hide-details="true" outlined dense
                                  item-disabled="disable"
                                  :disabled="checkDetail || item.stationname == null">
                        </v-select>
                        <p v-else class="m-auto">TS</p>
                      </div>
                    </template>
                    <template v-if="isIncludesDeviceChannel" v-slot:[`item.channelNo`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="item.channelFlg == 1 && frametypeChoice !== 'SVG'" style="width:150px;" v-model="item.channelNo" :items="item.ItemChannelList"
                                  @change="handleChangeChannelNo(item)"
                                  item-text="name" item-value="value" :rules="requiredRule(item.channelNo, 'labels.framesetting.channelName')"
                                  hide-details="true" outlined dense :disabled="checkDetail || item.device === null">
                        </v-select>
                        <v-select v-else-if="item.channelFlg == 1 && frametypeChoice === 'SVG'" v-model="item.channelNoLst" :items="item.ItemChannelList"
                                  item-text="name" :rules="[]"
                                  chips multiple deletable-chips
                                  @change="handleChangeChannelNo(item, $event)"
                                  hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device === null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-if="isIncludesDeviceTS || isTSSC" v-slot:[`item.tsStation`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="(isDeviceTsOrSimilarTs(item.device) && !isTSSC)"  style="width:150px;" v-model="item.tsStation" :items="item.ItemTsStations"
                                  @change="handleChangeTsStationOtherChart(item)"
                                  item-text="name" item-value="value" :rules="isDeviceTsOrSimilarTs(item.device) ? requiredRule(item.tsStation, 'labels.framesetting.tsStation') : []"
                                  hide-details="true" outlined dense :disabled="checkDetail || !isDeviceTsOrSimilarTs(item.device)">
                        </v-select>
                        <v-select v-else-if="isTSSC"  style="width:150px;" v-model="item.tsStation" :items="item.ItemTsStations"
                                  item-disabled="disable"
                                  @change="handleChangeTsStationSC(item)"
                                  item-text="name" item-value="value" :rules="requiredRule(item.tsStation, 'labels.framesetting.tsStation')"
                                  hide-details="true" outlined dense :disabled="checkDetail || item.stationname == null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-if="isIncludesDeviceTS" v-slot:[`item.orgNo`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select v-if="isDeviceTsOrSimilarTs(item.device)"  style="width:150px;" v-model="item.orgNo" :items="item.ItemOrgNo"
                                  :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'" item-value="orgNo" :rules="isDeviceTsOrSimilarTs(item.device) ? requiredRule(item.orgNo, 'labels.framesetting.orgNo') : []"
                                  @change="handleChangeOrgNo(item)"
                                  hide-details="true" outlined dense :disabled="checkDetail  || !isDeviceTsOrSimilarTs(item.device) || item.tsStation == null">
                        </v-select>
                        <v-text-field v-else style="width:150px;" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </div>
                    </template>
                    <template v-slot:[`item.data`]="{ item }">
                      <div class="d-flex align-items-center">
                        <v-select style="width:150px;" v-if="frametypeChoice !== 'SVG'" v-model="item.data" :items="item.ItemData"
                                  item-text="name" :rules="dataRules"
                                  @change="handleChangeData(item, $event)" item-value="value"
                                  item-disabled="disable" hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device == null || (isDeviceTsOrSimilarTs(item.device) && item.orgNo == null) || (item.channelFlg == 1 && item.channelNo == null)">
                        </v-select>

                        <v-select v-if="frametypeChoice === 'SVG'" v-model="item.dataCodes" :items="item.ItemData"
                                  item-text="name" :rules="dataRules"
                                  chips multiple deletable-chips
                                  @change="handleChangeData(item, $event)"
                                  hide-details="true" outlined dense
                                  :disabled="checkDetail || item.device == null || (frametypeChoice === 'SVG' && item.channelFlg == 1 && item.channelNoLst.length === 0)">
                        </v-select>
                      </div>
                    </template>

                    <template v-slot:[`item.legendName`]="{ item }">
                      <v-text-field :style="frametypeChoice !== 'GC' ? 'width:150px;' :''" maxlength="200" v-model="item.legendName" :rules="requiredRule(item.legendName, 'labels.framesetting.legendName')" @change="handleChangeLegendName(item, $event)" outlined dense hide-details="true" :disabled="checkDetail || item.data == null"></v-text-field>
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                      <input type="checkbox" style="width: 16px; height: 16px"
                             class="form-check-input-sub"
                             @change="onChangeView(item)"
                             :disabled="!item.horizontal && !item.vertical" checked
                      />
                    </template>
                    <template v-slot:[`item.color`]="{ item }">

                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             id="exampleColorInput" v-model="item.color" title="Choose your color"
                             @change="handleChaneColor(item)" :disabled="checkDetail">

                    </template>
                    <!-- Warning 1 -->
                    <template v-slot:[`item.display1`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <!-- <v-checkbox v-if="item.hasWarning === true"
                          v-model="item.display1" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay1(item.display1, item)"
                                      :disabled="checkDetail || item.data == null">
                          </v-checkbox>
                          <v-icon v-else color="red" size="25" class="mr-2 dark mt-0">
                            mdi-close-box
                          </v-icon> -->
                          <v-checkbox v-model="item.display1" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay1(item.display1, item)"
                                      :disabled="checkDetail || item.data == null">
                          </v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color1`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color1" title="Choose your color"
                                 @change="handleChangeColorW1(item)"
                                 :disabled="checkDetail || item.data == null ">
                                <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 1 -->
                    <!-- Warning 2 -->
                    <template v-slot:[`item.display2`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <v-checkbox v-model="item.display2" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay2(item.display2, item)"
                                      :disabled="checkDetail || item.data == null"></v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color2`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color2" title="Choose your color"
                                 @change="handleChangeColorW2(item)"
                                 :disabled="checkDetail || item.data == null" >
                                 <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 2 -->
                    <!-- Warning 3 -->
                    <template v-slot:[`item.display3`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <v-checkbox v-model="item.display3" hide-details="true" class="mt-0"
                                      @change="checkWarningDisplay3(item.display3, item)"
                                      :disabled="checkDetail || item.data == null"></v-checkbox>
                        </div>

                      </div>
                    </template>
                    <template v-slot:[`item.color3`]="{ item }">
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <div class="ml-2">
                          <input type="color" class="form-control-color"
                                 style="width:60px;height:45px" id="exampleColorInput"
                                 v-model="item.color3" title="Choose your color"
                                 @change="handleChangeColorW3(item)"
                                 :disabled="checkDetail || item.data == null">
                                  <!-- || item.hasWarning === false"> -->
                        </div>
                      </div>
                    </template>
                    <!-- END Warning 3 -->
                    <template v-slot:[`item.line`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.selected" :disabled="checkDetail"
                                       @change="handleChaneTypeChart(item)">
                          <v-radio value="line"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.bar`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.selected" :disabled="checkDetail"
                                       @change="handleChaneTypeChart(item)">
                          <v-radio value="bar"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.chart1`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.posChart" :disabled="checkDetail"
                                       @change="handleChanePosChart(item)">
                          <v-radio value="chart1" :disabled="true"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:[`item.chart2`]="{ item }">
                      <div class="col-12">
                        <v-radio-group v-model="item.posChart" :disabled="checkDetail"
                                       @change="handleChanePosChart(item)">
                          <v-radio value="chart2" :disabled="true"/>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-if="frametypeChoice !== 'SC'">
                      <div class="col-12">
                        <v-menu ref="menu1" v-model="item.menufrom" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px"
                                min-width="auto" :disabled="checkDetail">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="item.datefrom" prepend-icon="mdi-calendar"
                                          v-bind="attrs" v-on="on" hide-details="true"
                                          :disabled="checkDetail"></v-text-field>
                          </template>
                          <v-date-picker v-model="item.datefrom" no-title
                                         @input="item.menufrom = false" :disabled="checkDetail">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </template>
                    <template v-if="frametypeChoice !== 'SC'">
                      <div class="col-12">
                        <v-menu ref="menu" v-model="item.menuto" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px"
                                min-width="auto" :disabled="checkDetail">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="item.dateto" prepend-icon="mdi-calendar"
                                          v-bind="attrs" v-on="on" hide-details="true"
                                          :disabled="checkDetail"></v-text-field>
                          </template>
                          <v-date-picker v-model="item.dateto" no-title
                                         @input="item.menuto = false" :disabled="checkDetail">
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </template>
                    <template v-slot:[`item.stationname`]="{ item }">
                      <div class="d-flex align-items-center mb-3 mt-3">
                        <v-select style="width:150px;" v-model="item.stationname" :items="(frametypeChoice !== 'SC') ? stationList : item.stationList" item-text="label"
                                  @change="handleChangeStation(item)" item-value="value" :rules="stationIdRules" :item-disabled="isTSSC?'':'disable'"
                                  hide-details="true" outlined dense :disabled="checkDetail">
                        </v-select>
                      </div>
                    </template>
                    <template v-slot:[`item.pointer`]="{ item }">
                      <v-select v-model="item.pointer" :items="dataPointer" item-text="name"
                                append-icon=""
                                item-value="value" item-disabled="disable" single-line return-object
                                hide-details="true" solo disabled></v-select>
                    </template>
                    <template v-slot:[`item.colorPointer`]="{ item }">
                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             :disabled="checkDetail" v-model="item.colorPointer" @change="onChanecolorPointer(item)"
                             title="Choose your color">
                    </template>
                    <template v-slot:[`item.tablecolor`]="{ item }">
                      <input type="color" class="form-control-color" style="width:60px;height:45px"
                             :disabled="checkDetail" v-model="item.tablecolor" title="Choose your color">
                    </template>
                    <template v-slot:[`item.vertical`]="{ item }">
                      {{ item.vertical }}
                    </template>
                    <template v-slot:[`item.horizontal`]="{ item }">
                      {{ item.horizontal }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <div>
                        <button v-if="listTable.length > 1" class="m-1" @click.prevent="onDeleteLine(item)" :disabled="checkDetail">
                          <i class="fas fa-trash-alt  fa-lg pt-2 pb-2" :class="checkDetail?'text-dark':'text-danger'">
                          </i>
                        </button>
                      </div>
                    </template>
                  </v-data-table>
                </v-form>
                <div v-if="checkDetail && !showDetailByFrameTypeFlg" class="row mt-0 updateFrame-info1">
                  <div class="col-6">
                    <h6 class="mb-1 label-black">{{ $t("genba.table.createdDate") }}</h6>
                    <v-text-field v-model="editedItem.createDate" hide-details="auto" outlined dense
                                  :disabled="true">
                    </v-text-field>
                  </div>
                  <div class="col-6">
                    <h6 class="mb-1 label-black">{{ $t("genba.table.createdUser") }}</h6>
                    <v-text-field v-model="editedItem.createUser" hide-details="auto" outlined dense
                                  :disabled="true">
                    </v-text-field>
                  </div>
                </div>
                <div v-if="checkDetail && !showDetailByFrameTypeFlg" class="row mt-2">
                  <div class="col-6 pb-0">
                    <h6 class="mb-1 label-black">{{ $t("genba.table.updatedDate") }}</h6>
                    <v-text-field v-model="editedItem.updateDate" hide-details="auto" outlined dense
                                  :disabled="true">
                    </v-text-field>
                  </div>
                  <div class="col-6 pb-0">
                    <h6 class="mb-1 label-black">{{ $t("genba.table.updatedUser") }}</h6>
                    <v-text-field v-model="editedItem.updateUser" hide-details="auto" outlined dense
                                  :disabled="true">
                    </v-text-field>
                  </div>
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
        <div>
          <div v-if="checkDetail && showDetailByFrameTypeFlg" class="row mt-0 updateFrame-info">
            <div class="col-6">
              <h6 class="mb-1 label-black">{{ $t("genba.table.createdDate") }}</h6>
              <v-text-field v-model="editedItem.createDate" hide-details="auto" outlined dense
                            :disabled="true">
              </v-text-field>
            </div>
            <div class="col-6">
              <h6 class="mb-1 label-black">{{ $t("genba.table.createdUser") }}</h6>
              <v-text-field v-model="editedItem.createUser" hide-details="auto" outlined dense
                            :disabled="true">
              </v-text-field>
            </div>
          </div>
          <div v-if="checkDetail && showDetailByFrameTypeFlg" class="row mt-2">
            <div class="col-6 pb-0">
              <h6 class="mb-1 label-black">{{ $t("genba.table.updatedDate") }}</h6>
              <v-text-field v-model="editedItem.updateDate" hide-details="auto" outlined dense
                            :disabled="true">
              </v-text-field>
            </div>
            <div class="col-6 pb-0">
              <h6 class="mb-1 label-black">{{ $t("genba.table.updatedUser") }}</h6>
              <v-text-field v-model="editedItem.updateUser" hide-details="auto" outlined dense
                            :disabled="true">
              </v-text-field>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1 mb-0" style="background-color: #3d76ad" text
               @click="saveFrame(event)">
          {{ $t("labels.layoutSetting.save") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ checkDetail ? $t("labels.close") : $t("commonPopup.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <UploadSVGModal @close="modalUpload = false" dialog @EmitUploadSVG="handleUploadSVGFile"/>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/utils/eventBus";
import {GridItem, GridLayout} from "vue-grid-layout";
import StackedLine from "../../components/Charts/LS";
import EchartRainFall from "../../components/Charts/GLS"
import ScatterChart from "../../components/Charts/SC"
import EChartMultiY from "../../components/Charts/MAC"
import TableChart from "../../components/Charts/TC"
import Camera from "../../components/Charts/MWC"
import ScreenShare from "../../components/Charts/SCS"
import SVGChart from "../../components/Charts/SVG"
import ChartWeather from "@/components/Charts/GC";
import LineRace from "@/components/Charts/LR";
import {getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import {getCbbStation} from "@/api/station";
import {ResponseCode, FrameTypesValue} from "@/constants/constants"
import {mapActions} from "vuex";
import moment from "moment";
import {getDeviceBySite} from '@/api/device';
import UploadFileModal from "@/modals/uploadFile/UploadFileModal";
import UploadSVGModal from "@/modals/uploadFile/UploadSVGModal";
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';
import Draggable from 'vuedraggable';

export default {
  name: "FrameModal",
  components: {
    GridLayout,
    GridItem,
    StackedLine,
    EchartRainFall,
    ScatterChart,
    EChartMultiY,
    TableChart,
    Camera,
    ScreenShare,
    SVGChart,
    ChartWeather,
    LineRace,
    UploadSVGModal,
    Draggable
  },
  props:{
    frameList : Array
  },
  data() {
    return {
      margin: [10, 10],
      yOffset: 80,
      currentFrame: null,
      editFlag: false,
      valid: true,
      valid2: true,
      modalUpload: false,
      menuFrom: false,
      menuTo: false,
      dateFrom: null,
      dateTo: null,
      fromFormatted: null,
      toFormatted: null,
      loading: false,
      titleChart: {
        text: "Title Chart"
      },
      nameLabel: "",
      showDialog: false,
      checkDetail: false,
      profile: false,
      stationflg: false,
      editedItem: { link: null },
      ERROR_CODE: '',
      layout: [
        {
          x: 0,
          y: 5,
          w: 7,
          h: 6,
          i: "Chart",
          id: 1,
          type: "Chart",
          height: 650,
          width: 1200, static: true
        },
        {
          x: 0,
          y: 0,
          w: 5,
          h: 4,
          i: "toolbox",
          id: 2,
          type: "toolbox",
          height: 420,
          width: 500,
          static: true
        },
        {
          x: 0,
          y: 10,
          w: 12,
          h: 3,
          i: "table",
          id: 3,
          type: "table",
          height: 420,
          width: 500,
          static: true
        },
      ],
      frameType: [],
      stationList: [],
      selectType: [],
      selectedStation: undefined,
      headers: [
        {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
        {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
        {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
        // {text: 'labels.framesetting.channel', value: 'channel', align: 'center', sortable: false},
        {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
        {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
        {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
        {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
      ],
      listTable: [],
      Itemdevices: [],
      ItemData: [],
      ItemdevicesAll: [],
      ItemDataAll: [],
      ItemOrgInfoAll:[],
      ItemColor: [
        {name: "", value: "1"},
      ],
      menu: false,
      series: [],
      legend: {data: [],type: 'scroll',left: 10},
      yAxis: [],
      xAxis: [],
      modurn: '',
      modurnComponent: '',
      flgTable: false,
      frametypeChoice: '',
      deviceflg: false,
      dataPointer: [
        {name: "●", value: "circle"},
        {name: "□", value: "rect"},
        {name: "◊", value: "diamond"},
        {name: "▲", value: "triangle"},
        {name: "▅ ", value: "roundRect"},
        {name: "✦", value: "pin"},
        {name: "∆", value: "arrow"},
      ],
      checkList: false,
      checkIP: false,
      svgContent: null,
      IPFlg: false,
      typeDefault: 0,
      autoTitle: '',
      deviceCode: undefined, //init is camera
      orgNo: null,
      frameId: null,
      deviceList: [],
      dataListSc1: [],
      dataListSc2: [],
      dataSc1: undefined,
      dataSc2: undefined,
      selectedDevice: undefined,
      selectedRow: -1,
      showDetailByFrameTypeFlg: true, //true is Frame table/camera,
      fileName: null,
      link: null,
      requiredRule(value, label) {
        const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
        if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
          return errorMsg
        } else {
          return [];
        }
      },
      stationIdRules: [
        (value) => !!value || ""
      ],
      deviceRules: [
        (value) => !!value || ""
      ],
      dataRules: [
        (value) => !!value || ""
      ],
      fromDateRules: [
        (value) => !!value || this.$t("labels.framesetting.fromDateTime") + this.ERROR_CODE['IS_REQUIRED']
      ],
      toDateRules: [
        (value) => !!value || this.$t("labels.framesetting.toDateTime") + this.ERROR_CODE['IS_REQUIRED']
      ],
      selectedDeviceList: [],
      PAGE_CODE: 'CS_GM',
      listHeaderFrameTable: [],
      listSelectedStation: [],
      channelList: [],
      frameSupportDataLogger: [FrameTypesValue.SVG, FrameTypesValue.LineStack, FrameTypesValue.GaugeChart, FrameTypesValue.TableChart],
      deviceTSList: []
    }
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
    }
  },
  computed: {
    isFullDataLineTable() {
      if (this.listTable.length > 0) {
        // eslint-disable-next-line
        const item = this.listTable[this.listTable.length - 1];
        const data = this.frametypeChoice === FrameTypesValue.SVG ? item.dataCodes : item.data;
        let isFull = true;
        if (this.frametypeChoice === FrameTypesValue.SVG) {
          isFull = item.stationname !== null && item.device !== null && item.dataCodes.length > 0;
        } else if (this.frametypeChoice === FrameTypesValue.SVGTS) {
          isFull = item.stationname && item.horizontal && item.vertical;
        } else if (this.frametypeChoice === FrameTypesValue.GirdMultipleLineChart) {
          isFull = !(this.listTable.length == 2);
        } else if (this.frametypeChoice === FrameTypesValue.ScatterChart) {
          if(!this.isDeviceTsOrSimilarTs(Number(item.device)))
          {
            isFull = item.stationname !== undefined && this.stationList.length > this.listTable.length;
          }
          else
          {
            isFull = (item.stationname !== undefined && item.stationname !== null) && (item.tsStation !== undefined && item.tsStation !== null);
          }
        } else if (this.frametypeChoice === FrameTypesValue.MultipleYAxisMixLineBarChart) {
          isFull = this.listTable.length < 6 && item.stationname !== null && item.device !== null && item.data;
        }
        else {
          isFull = item.stationname !== null && item.device !== null && item.data;
        }
        return isFull;
      }
      return true;
    },
    isIncludesDeviceTS(){
      if (this.listTable.length > 0) {
        const deviceList = this.listTable.map(x=> x.device);
        // return deviceList.includes(1000)
        return this.deviceTSList.some(r=> deviceList.includes(r));
      }
      return true;
    },
    isIncludesDeviceChannel(){
      if (this.listTable.length > 0) {
        const channelFlgList = this.listTable.map(x=> x.channelFlg);
        return channelFlgList.includes(1)
      }
      return true;
    },
    isTSSC(){
      return this.frametypeChoice === 'SC' && this.deviceCode !== undefined && this.isDeviceTsOrSimilarTs(Number(this.deviceCode))
    },
    listOptionDayComputed() {
      return [
        {value: 1, text: this.$t('labels.framesetting.1day')},
        {value: 2, text: this.$t('labels.framesetting.2day')},
        {value: 3, text: this.$t('labels.framesetting.3day')},
        {value: 4, text: this.$t('labels.framesetting.4day')},
        {value: 5, text: this.$t('labels.framesetting.5day')},
        {value: 6, text: this.$t('labels.framesetting.6day')},
        {value: 7, text: this.$t('labels.framesetting.7day')},
      ]
    },
    listOptionExportTypeComputed() {
      return [
      {value: 1, text: this.$t('labels.framesetting.realTime')},
        {value: 2, text: this.$t('labels.framesetting.10minutes')},
      ]
    },
    listOptionTimeComputed() {
      return [
        {value: 1, text: this.$t('timeAutoUpdate.1s')},
        {value: 5, text: this.$t('timeAutoUpdate.5s')},
        {value: 10, text: this.$t('timeAutoUpdate.10s')},
        {value: 30, text: this.$t('timeAutoUpdate.30s')},
        {value: 60, text: this.$t('timeAutoUpdate.1m')},
        {value: 300, text: this.$t('timeAutoUpdate.5m')},
        {value: 600, text: this.$t('timeAutoUpdate.10m')},
        {value: 1800, text: this.$t('timeAutoUpdate.30m')},
        {value: 3600, text: this.$t('timeAutoUpdate.60m')},
        {value: 7200, text: this.$t('timeAutoUpdate.120m')},
      ]
    },
    isTableTs() {
      return this.isDeviceTsOrSimilarTs(Number(this.deviceCode))
    },
    stationListFilter(){
      if(this.stationflg){
        return this.stationList.filter(item => !this.frameList.some(frame => frame.siteStationId === item.value && this.editedItem.frameId !== frame.id))
      }
      return this.stationList
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      await import(`@/utils/errors/frames/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/frames/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // =========================================================== stop import ERROR_CODE by language
    },
    showDialog(value) {
      if (value === false) {
        this.legend = {data: [],type: 'scroll',left: 10};
        this.series = []
        this.yAxis = [];
        this.xAxis = [];
        this.modurn = ""
        this.checkDetail = false;
        this.checkIP = false
        this.stationflg = false
        this.flgTable = false
        this.deviceflg = false
        this.listTable = []
        this.selectedRow = -1
        this.valid = true
        this.valid2 = true
      } else {
        this.selectedDeviceList = [];
        this.listSelectedStation = [];
        this.selectedStation = undefined;
        this.deviceCode = undefined;
        this.initCbbData();
        if(this.$refs.form!=undefined &&this.$refs.form[0]!=undefined)
          this.$refs.form[0].resetValidation();
        if(this.$refs.form2!=undefined &&this.$refs.form2[0]!=undefined)
          this.$refs.form2[0].resetValidation();
      }
    },
    frametypeChoice: function () {
      this.initCbbData();
    },
    'headers': function ($event) {
      this.headers = $event;
    },
    'dateFrom'(val) {
      this.fromFormatted = this.formatDate(this.dateFrom)
    },
    'dateTo'(val) {
      this.toFormatted = this.formatDate(this.dateTo)
    },
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);

    this.handleGetListTypeFrame();
    this.getListDeviceMaster();

    EventBus.$on('handleOpenFrameSettingEditModal', (value) => {
      this.nameLabel = value.nameLabel;
      this.editedItem = value.editedItem;
      this.showDialog = value.flag;
      if (value.flag) {
        $('#main-header').css("pointer-events", "none");
      } else {
        $('#main-header').css("pointer-events", "");
      }
      this.checkDetail = value.checkDetail;
      this.modurn = value.editedItem.modurn;
      this.editFlag = value.editFlag;
      this.autoTitle = "";
      this.dateFrom = null;
      this.dateTo = null;
      /* eslint-disable */
      // this.listTable = value.editedItem.listTable
      if (value.checkDetail || value.editFlag) {
        this.loading = true;
        this.frameId = value.editedItem.id;
        let siteID = getCookieSiteUser();
        // site id is not exist
        if (!siteID) {
          return;
        }
        this.getFrameDetail({SiteId: siteID,FrameId: value.editedItem.id})
          .then(async (response) => {
            if (response.code === 0) {
              this.editedItem = {
                frameId: response.data.id,
                frameName: response.data.name,
                frameType: response.data.typeFrameCode,
                createDate: moment(response.data.createdDate).format('YYYY/MM/DD'),
                updateDate: moment(response.data.updatedDate).format('YYYY/MM/DD'),
                createUser: response.data.createdBy,
                updateUser: response.data.updatedBy,
                link: response.data?.linkIp
              }
              this.changeChart(response.data.typeFrameCode);
              if (response.data.typeFrameCode === FrameTypesValue.TableChart) {
                // this.changeChart(FrameTypesValue.TableChart);
                // this.editedItem.day = response.data?.limitTime;
                this.editedItem.exportType = response.data?.exportType;
                this.editedItem.autoUpdateTime = response.data?.autoUpdateTime;
                this.listHeaderFrameTable = response.data?.headers;
                response.data?.frameData.forEach(element => {
                  this.listSelectedStation.push(element?.siteStationId);
                  this.selectedStation = element?.siteStationId;
                  this.editedItem.channelNo = element?.orgNo;
                });
                this.deviceCode = response.data.frameData[0].deviceCode.toString();
                if(this.isDeviceTsOrSimilarTs(Number(response.data.frameData[0].deviceCode.toString()))) {
                  this.handleGetCbbOrgInfo({deviceCode: response.data.frameData[0].deviceCode.toString()})
                }
              } else if (response.data.typeFrameCode === FrameTypesValue.MonitorWebCamera) {
                // this.changeChart(FrameTypesValue.MonitorWebCamera);
                this.IPFlg = false;
                this.editedItem.port = response.data?.port;
                this.selectedStation = response.data?.siteStationId;
              } else if (response.data.typeFrameCode === FrameTypesValue.MonitorSafieCamera) {
                // this.changeChart(FrameTypesValue.MonitorSafieCamera);
                this.selectedStation = response.data?.siteStationId;
                this.editedItem.link = response.data?.linkIp;
              } else if (response.data.typeFrameCode === FrameTypesValue.SVG) {
                // this.changeChart(FrameTypesValue.SVG);
                this.editedItem.link = response.data?.linkIp;
              } else if (response.data.typeFrameCode === FrameTypesValue.ScatterChart) {
                if(this.isDeviceTsOrSimilarTs(Number(response.data.frameData[0].deviceCode.toString()))) {
                  await this.handleGetCbbOrgInfo({deviceCode: response.data.frameData[0].deviceCode.toString()})
                }
              }
              //set date from and date to scatter chart
              this.dateFrom = response.data.fromDateTime;
              this.dateTo = response.data.toDateTime;
              //set frame type choice
              this.frametypeChoice = response.data.typeFrameCode;
              // this.setHeaderAndCompByType(response.data.typeFrameCode);
              this.autoTitle = response.data.title;
              this.selectedStation = response.data?.siteStationId;
              this.series = response.data.series ? response.data.series : []
              this.yAxis = response.data.yAxis ? response.data.yAxis : []
              this.xAxis = response.data.xaxis ? response.data.xaxis : []
              this.legend = response.data.legend ? response.data.legend : []
              this.selectedDeviceList = response.data.frameData.map((item, index) => {
                var item = {
                  index: item.index,
                  stationId: item.siteStationId,
                  deviceCode: item.deviceCode
                }
                return item;
              });
              this.listTable = response.data.frameData.map((item, index) => {
                const locationArr = item.location !== null ? item.location.split("/") : ['', ''];
                const dataObj = response.data.typeFrameCode !== FrameTypesValue.SVG && response.data.typeFrameCode !== FrameTypesValue.ScatterChart && response.data.typeFrameCode !== FrameTypesValue.TableChart ? this.getDataObjByDeviceData(item, response) : null;
                const selectedDataCodes = item.dataCodes ? item.dataCodes.split("/") : [];
                const selectedChannelNos = item.channelNoLst ? item.channelNoLst.split("/") : [];
                var item = {
                  order: index + 1,
                  index: item.index,
                  stationname: item.siteStationId,
                  device: item.deviceCode,
                  data: item.dataCode,
                  dataCodes: selectedDataCodes,
                  color: item.color,
                  display1: item.display1,
                  color1: item.display1 ? item.color1 : '#3ba272',
                  display2: item.display2,
                  color2: item.display2 ? item.color2 : '#fac858',
                  display3: item.display3,
                  color3: item.display3 ? item.color3 : '#ee6666',
                  selected: item.typeChart,
                  line: "",
                  bar: "",
                  posChart: item.posChart,
                  Itemdevices: item.itemdevices ? item.itemdevices.map((i, index) => {
                    var disable = !this.frameSupportDataLogger.includes(response.data.typeFrameCode) && i.channelFlg == 1
                    return {value: i.deviceCode, name: this.$i18n.locale === 'en' ? i.nameEn : i.nameJp, icon: i.icon, channelFlg: i.channelFlg, disable: disable}
                  }) : [],
                  ItemData: this.getDeviceDataInitByDevice(item, response),
                  dataName: this.$i18n.locale === 'en' ? dataObj?.nameEn :dataObj?.nameJp,
                  nameEn: dataObj?.nameEn,
                  nameJp: dataObj?.nameJp,
                  unit: dataObj?.unit,
                  icon: dataObj?.icon,
                  vertical: locationArr[0],
                  horizontal: locationArr[1],
                  colorPointer: item?.colorPointer,
                  hasWarning: item.hasWarning,
                  legendName: item.legendName,
                  tsStation: this.isDeviceTsOrSimilarTs(item.deviceCode) ? item.tsStation : null,
                  orgNo: this.isDeviceTsOrSimilarTs(item.deviceCode) ? item.orgNo : null,
                  ItemTsStations: item.itemTsStations && this.isDeviceTsOrSimilarTs(item.deviceCode) ? item.itemTsStations.map((i, index) => {
                    var dataI = {value: i.id, name: i.stationName, disable: false };
                    response.data.frameData.forEach(v => {
                      if (item.siteStationId === v.siteStationId && i.id === v.tsStation) {
                        dataI.disable = true;
                      }
                    });
                    return dataI;
                  }): [],
                  ItemOrgNo: item.itemOrgNo && this.isDeviceTsOrSimilarTs(item.deviceCode) ? item.itemOrgNo : [],
                  channelFlg: item.channelFlg,
                  channelNo: item.channelNo,
                  ItemChannelList: item.itemChannelList && item.channelFlg == 1 ? item.itemChannelList.map((i, index) => {
                    var dataI = {value: i.channelNo, name: i.channelName, disable: false };
                    // response.data.frameData.forEach(v => {
                    //   if (item.siteStationId === v.siteStationId && i.id === v.tsStation) {
                    //     dataI.disable = true;
                    //   }
                    // });
                    return dataI;
                  }): [],
                  channelNoLst: selectedChannelNos
                }
                return item;
              });
              if (response.data.typeFrameCode === FrameTypesValue.SVG) {
                this.listTable.forEach(element => {
                  this.setSymbolByDecieId(element);
                });
              }
              if(response.data.typeFrameCode === FrameTypesValue.ScatterChart) {
                this.deviceCode = this.listTable[0].device.toString();
                this.dataSc1 = this.listTable[0].data.split('/')[0];
                this.dataSc2 = this.listTable[0].data.split('/')[1];
                this.orgNo = this.listTable[0].orgNo;
                this.loadScatterInfo(this.deviceCode);
              }
              this.handleHeaderByDevice();
              this.initCbbData();
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            // this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
            this.loading = false;
            this.isSubmitAction = false;
          });
      } else {
        this.initCbbData();
        this.frametypeChoice = this.modurn;
        if (this.modurn === 'LS') {
          this.resizeComponent('Chart', 650, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          this.modurnComponent = "StackedLine"
        }
        this.InitDataTableChart();
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          // {text: 'labels.framesetting.channel', value: 'channel', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.legendName', value: 'legendName', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      }
      this.selectType = []
    });


  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/frames/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/frames/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {

  },
  methods: {
    ...mapActions("station", {
      getCbbSiteStation: "getCbbSiteStation",
      getCbbSiteDeviceByStation: "getCbbSiteDeviceByStation",
      getCbbDeviceData: "getCbbDeviceData",
      getCbbStationTSByDevice: "getCbbStationTSByDevice",
      getCbbOrgInfo: "getCbbOrgInfo",
      getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr"
    }),
    ...mapActions("frame", {
      getFrameDetail: "getFrameDetail",
      getTypeFrame: "getTypeFrame",
      addFrame: 'addFrame',
      updateFrame: 'updateFrame',
      checkWarningByDataCode: "checkWarningByDataCode"
    }),
    ...mapActions("sites", {
      getListChannel: "getListChannel",
    }),
    ...mapActions("deviceMaster", {
      getDeviceList: "getDeviceList"
    }),
    getDataObjByDeviceData(item, response){
      if(this.isDeviceTsOrSimilarTs(item.deviceCode)){
        const orgData = item.itemOrgNo?.find(x=>x.orgNo === item.orgNo)?.orgData;
        const dataInfo = orgData.find(element => element.dataMapping.toString() == item.dataCode.toString());
        return {...dataInfo, nameEn:dataInfo.headerEn, nameJp:dataInfo.headerJp}
      }
      else{
        return item.itemData.find(element => element.dataCode.toString() == item.dataCode.toString())
      }
    },
    getDeviceDataInitByDevice(item, response){
      if(this.isDeviceTsOrSimilarTs(item.deviceCode)){
        const orgData = item.itemOrgNo?.find(x=>x.orgNo === item.orgNo)?.orgData;
        return orgData !== null && orgData !== undefined ? orgData.map((i, index) => {
          var dataI = {value: i.dataMapping, name: this.$i18n.locale === 'en' ? i.headerEn :i.headerJp, unit: i.unit, disable: false, icon: null};
          this.listTable.forEach(v => {
            if (item.stationname === v.stationname && item.device === v.device && item.orgNo === v.orgNo && i.dataMapping === v.data) {
              dataI.disable = true;
            }
          });
          return dataI;
        }) : []

      }
      else{
        return (item.itemData ? item.itemData.map((i, index) => {
          var dataI = {value: i.dataCode, name: this.$i18n.locale === 'en' ? i.nameEn : i.nameJp, unit: i.unit, disable: false, icon: i.icon};
          response.data.frameData.forEach(v => {
            if (item.siteStationId === v.siteStationId && item.deviceCode === v.deviceCode && i.dataCode === v.dataCode) {
              dataI.disable = true;
            }
          });
          return dataI;
        }) : [])
      }
    },
    disableStartDates(val) {
        return this.dateTo == null ? true : val <= this.dateTo;
    },
    disableEndDates(val) {
        return this.dateFrom == null ? true : val >= this.dateFrom;
    },
    handleGetListTypeFrame() {
      const siteId = getCookieSiteUser();
      this.getTypeFrame({siteId: siteId, flag: 0}).then(response => {
        if (response.code === 0) {
          this.frameType = response.data;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {

      })
    },
    getListDeviceMaster() {
      this.getDeviceList({})
        .then(response => {
          if (response.code === 0) {
            this.deviceTSList = response.data.deviceList.map(x => x.code);
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    isDeviceTsOrSimilarTs(deviceCode){
      return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(deviceCode);
    },
    onBlur() {
      var trimText = this.editedItem.frameName.trim()
      this.editedItem = {...this.editedItem, frameName: "trimText"}
    },
    handleStation() {
      if (this.frametypeChoice === "GC") {
        var tempItem = this.initValueOneItem();
        tempItem.index = 0;
        tempItem.order = 1;
        if (this.selectedStation !== undefined && this.selectedStation !== null) {
          this.loading = true;
          this.getCbbSiteDeviceByStation({StationId: this.selectedStation})
            .then(response => {
              if (response.code === 0) {
                this.ItemdevicesAll = response.data.map((item, index) => {
                  return {value: item.deviceCode, name: this.$i18n.locale === 'en' ? item.nameEn : item.nameJp}
                });
                tempItem.Itemdevices = response.data.map((item, index) => {
                  var disable = (!this.frameSupportDataLogger.includes(this.frametypeChoice) && item.channelFlg == 1) || (this.isIncludesDeviceTS && item.channelFlg == 1) || (this.isIncludesDeviceChannel && this.isDeviceTsOrSimilarTs(item.deviceCode))
                  return {value: item.deviceCode, name: this.$i18n.locale === 'en' ? item.nameEn : item.nameJp, icon: item.icon, channelFlg: item.channelFlg, disable: disable}
                });
                tempItem.stationname = this.selectedStation
                this.listTable = [tempItem];
                this.loading = false;
              }
            })
            .finally(final => {
              this.isSubmitAction = false;
              this.loading = false;
            });
        }
      } else if (this.frametypeChoice === FrameTypesValue.TableChart) {
        if (this.isTableTs) {
          this.$refs.chartComponent[0].stations = [];
          this.$refs.chartComponent[0].stations.push(this.selectedStation);
          if(this.editedItem?.channelNo != undefined && this.selectedStation != undefined) {
            this.$refs.chartComponent[0].channelNo = this.editedItem?.channelNo;
            this.$refs.chartComponent[0].getTableChartData();
          }
        } else {
          this.$refs.chartComponent[0].stations = this.selectedStation?.split();
          // this.$refs.chartComponent[0].limitTime = this.editedItem?.day;
          this.$refs.chartComponent[0].exportType = this.editedItem?.exportType;
          this.$refs.chartComponent[0].autoUpdateTime = this.editedItem?.autoUpdateTime;
          this.onUpdateTableHeader();
          this.$refs.chartComponent[0].getTableChartData();
        }
      }
    },
    handleTCTSOrg() {
      if (this.frametypeChoice === FrameTypesValue.TableChart) {
        if (this.isTableTs) {
          this.$refs.chartComponent[0].stations = [];
          this.$refs.chartComponent[0].stations.push(this.selectedStation);
          if(this.editedItem?.channelNo != undefined && this.selectedStation != undefined) {
            this.$refs.chartComponent[0].channelNo = this.editedItem?.channelNo;
            this.$refs.chartComponent[0].getTableChartData();
          }
        } else {
          this.$refs.chartComponent[0].stations = this.listSelectedStation;
          // this.$refs.chartComponent[0].limitTime = this.editedItem?.day;
          this.$refs.chartComponent[0].exportType = this.editedItem?.exportType;
          this.$refs.chartComponent[0].autoUpdateTime = this.editedItem?.autoUpdateTime;
          this.$refs.chartComponent[0].getTableChartData();
        }
      }
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_CLOSE'], () => {
          if(this.$refs.form[0]!=undefined)
            this.$refs.form[0].reset();
          if(this.$refs.form2[0]!=undefined)
            this.$refs.form2[0].reset();
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
        });
      } else {
        if(this.$refs.form[0]!=undefined)
          this.$refs.form[0].reset();
        if(this.$refs.form2[0]!=undefined)
          this.$refs.form2[0].reset();
        this.showDialog = false
        $('#main-header').css("pointer-events", "");
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    onCloseModal() {
      this.showDialog = false;
      $('#main-header').css("pointer-events", "");
      this.autoTitle = '';

    },
    onSelectRow(item) {
      this.selectedRow = item.index;
    },
    checkWarningDisplay1(isChecked, item) {
      let data = [];
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_1";
      const value = Math.floor(Math.random() * 50)
      for (let i = 0; i < 1008; i++) {
        data.push(value);
      }
      if (isChecked) {
        let obj = {
          key: key,
          dataCode: item.data,
          xAxisIndex: this.series[indexSerie].xAxisIndex,
          yAxisIndex: this.series[indexSerie].xAxisIndex,
          name: key,
          type: 'line',
          color: item.color1,
          symbolSize: 0,
          data: data,
          smooth: true
        }
        // this.legend.data.push(key);
        this.series.push(obj);
      } else {
        const itemSelected = this.series.findIndex(e => e.key === key);
        // const legendSelected = this.legend.data.findIndex(e => e === key);
        // this.legend.data.splice(legendSelected, 1);
        this.series.splice(itemSelected, 1);
      }
    },
    handleChangeColorW1(item) {
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_1";
      const itemSelected = this.series.findIndex(e => e.key === key);
      if (itemSelected > -1) {
        this.series[itemSelected].color = item.color1;
      }
    },
    checkWarningDisplay2(isChecked, item) {
      let data = [];
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_2";
      const value = Math.floor((Math.random() * 20) + 50)
      for (let i = 0; i < 1008; i++) {
        data.push(value);
      }
      if (isChecked) {
        let obj = {
          key: key,
          dataCode: item.data,
          xAxisIndex: this.series[indexSerie].xAxisIndex,
          yAxisIndex: this.series[indexSerie].xAxisIndex,
          name: key,
          type: 'line',
          color: item.color2,
          symbolSize: 0,
          data: data,
          smooth: true
        }
        // this.legend.data.push(key);
        this.series.push(obj);
      } else {
        const itemSelected = this.series.findIndex(e => e.key === key);
        // const legendSelected = this.legend.data.findIndex(e => e === key);
        // this.legend.data.splice(legendSelected, 1);
        this.series.splice(itemSelected, 1);
      }

    },
    handleChangeColorW2(item) {
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_2";
      const itemSelected = this.series.findIndex(e => e.key === key);
      if (itemSelected > -1) {
        this.series[itemSelected].color = item.color2;
      }
    },
    checkWarningDisplay3(isChecked, item) {
      let data = [];
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_3";
      const value = Math.floor((Math.random() * 30) + 70)
      for (let i = 0; i < 1008; i++) {
        data.push(value);
      }
      if (isChecked) {
        let obj = {
          key: key,
          dataCode: item.data,
          xAxisIndex: this.series[indexSerie].xAxisIndex,
          yAxisIndex: this.series[indexSerie].xAxisIndex,
          name: key,
          type: 'line',
          color: item.color3,
          symbolSize: 0,
          data: data,
          smooth: true
        }
        // this.legend.data.push(key);
        this.series.push(obj);
      } else {
        const itemSelected = this.series.findIndex(e => e.key === key);
        // const legendSelected = this.legend.data.findIndex(e => e === key);
        // this.legend.data.splice(legendSelected, 1);
        this.series.splice(itemSelected, 1);
      }

    },
    handleChangeColorW3(item) {
      const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_3";
      const itemSelected = this.series.findIndex(e => e.key === key);
      if (itemSelected > -1) {
        this.series[itemSelected].color = item.color3;
      }
    },
    handleChaneColor(item) {
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      this.series[indexSerie].color = item.color;
      let indexYAxis = this.yAxis.length > 0 ? this.yAxis.findIndex((e => e.index === item.index)) : -1;
      if (indexYAxis > -1 && this.frametypeChoice === "MAC") {
        this.yAxis[indexYAxis].axisLine.lineStyle.color = item.color;
      }

    },
    handleChaneTypeChart(item) {
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      this.series[indexSerie].type = item.selected;

    },
    handleChanePosChart(item) {
      let indexSerie = this.series.findIndex((e => e.index === item.index))
      let indexTable = this.listTable.findIndex((e => e.index === item.index))
      // this.legend.data = this.legend.data.filter((i) => i !== item.data)
      this.series[indexSerie].xAxisIndex = item.posChart === "chart1" ? 0 : 1
      this.series[indexSerie].yAxisIndex = item.posChart === "chart1" ? 0 : 1
      //delete warning
      for (let index = 1; index < 4; index++) {
        const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_" + index;
        const itemSelected = this.series.findIndex(e => e.key === key);
        if (itemSelected > -1) {
          this.series[itemSelected].xAxisIndex = item.posChart === "chart1" ? 0 : 1
          this.series[itemSelected].yAxisIndex = item.posChart === "chart1" ? 0 : 1
        }

      }

    },
    handleChangeLegendName(item){
      let indexSerie = this.series.findIndex((e => e.index == item.index));
      if (indexSerie > -1) {
        this.series[indexSerie].name = item.legendName;
      }
      this.legend.data = this.series.filter(item => {return !item.hasOwnProperty('key'); }).map(item=>{return item.name;});
      let indexTable = this.listTable.findIndex((e => e.index === item.index))
      this.$set(this.listTable, indexTable, this.listTable[indexTable])
    },
    handleChangeData(item) {
      this.currentFrame = item;
      if (this.frametypeChoice === FrameTypesValue.SVG || this.frametypeChoice === FrameTypesValue.SVGTS) {
        if(item.channelFlg === 1){
          const deviceData = item.ItemData?.find((i) => {
            return i?.value === item.dataCodes[0];
          });
          var dataNames = item.channelNoLst.map(x => {
            var i = item.ItemChannelList.find(item => item.value == x);
            return `${i.name} ${deviceData.name}?${deviceData.unit}`;
          });
          this.$refs.chartComponent[0].setToolTip(item.index, dataNames);
        }
        else{
          var dataNames = item.dataCodes.map(x => {
            var i = item.ItemData.find(item => item.value == x);
            return `${i.name}?${i.unit}`;
          });
          this.$refs.chartComponent[0].setToolTip(item.index, dataNames);
        }
      } else {
        const dataObj = item.ItemData?.find(element => {
          return element.value.toString() === item.data.toString() ? element.name : '';
        });
        const channelObj = item.ItemChannelList?.find(element => {
          return element.value.toString() === item.channelNo.toString() ? element.name : '';
        });

        const dataName = (dataObj.name != undefined ? dataObj.name : "").concat((dataObj.unit != undefined ? "(" + dataObj.unit + ")" : ""));
        const channelName = (channelObj != undefined ? channelObj.name : "");
        //

        // set data name and unit listTable
        let indexTable = this.listTable.findIndex((e => e.index === item.index))
        this.handleCheckWarningByDataCode(item.stationname,item.device,item.data, this.listTable[indexTable])
        let legendName = this.makeLegendNameByChannel(item);
        this.listTable[indexTable].dataName = this.makeLegendNameByChannel(item);
        this.listTable[indexTable].legendName = legendName;
        this.listTable[indexTable].unit = dataObj.unit;
        this.listTable[indexTable].icon = dataObj.icon;
        this.$set(this.listTable, indexTable, this.listTable[indexTable])
        let indexYAxis = this.yAxis.length > 0 ? this.yAxis.findIndex((e => e.index === item.index)) : -1;
        if (this.frametypeChoice === FrameTypesValue.GirdMultipleLineChart) {
          let gridIndex = item.posChart == "chart1" ? 0 : 1;
          indexYAxis = this.yAxis.length > 0 ? this.yAxis.findIndex((e => e.gridIndex === gridIndex)) : -1;
        }
        if (indexYAxis > -1) {
          if (this.frametypeChoice === FrameTypesValue.MultipleYAxisMixLineBarChart) {
            this.yAxis[indexYAxis].axisLabel.formatter = '{value}';
            // + (dataObj.unit != undefined ? (dataObj.unit) : "");
          } else {
            // this.yAxis[indexYAxis].name = dataName;
          }
        }
        let indexSerie = this.series.findIndex((e => e.index == item.index));
        if (indexSerie > -1) {
          this.legend.data.push(legendName);
          this.series[indexSerie].color = item.color;
          this.series[indexSerie].name = legendName;
          this.series[indexSerie].dataCode = item.data;
          this.series[indexSerie].station = item.stationname;
          this.series[indexSerie].channelNo = item.channelNo;
          this.series[indexSerie].tsStationOrg = item.tsStation ? `${item.stationname}_${item.tsStation}_${item.orgNo}` : null;
        }
        this.legend.data = this.series.filter(item => {return !item.hasOwnProperty('key'); }).map(item=>{return item.name;});
        const dataSelected = this.listTable.filter(x=> x.stationname === item.stationname && x.device === item.device && x.tsStation == item.tsStation && x.orgNo == item.orgNo).map(i=>i.data);
        this.listTable.forEach(it => {
          if(it.stationname == item.stationname && it.device == item.device && it.tsStation == item.tsStation && it.orgNo == item.orgNo) {
            it.ItemData.forEach(t=>{
              if(dataSelected.includes(t.value) && it.data != t.value) {
                t.disable = true;
              }
              else if(!dataSelected.includes(t.value) && it.data == t.value) {
                t.disable = false;
              } else {
                t.disable = false;
              }
            });
          }
        });
      }
    },
    handleChangeDataSC(item,type) {
      if(type == 1){
        let indexX = this.dataListSc1.findIndex(it => it.value == item);
        this.xAxis[0].axisLabel.formatter = '{value} '.concat(this.dataListSc1[indexX].unit);
        this.xAxis[0].unit = this.dataListSc1[indexX].unit;
        this.dataListSc2.forEach(it => {
          if(it.value == item){
            it.disable = true;
          } else {
            it.disable = false;
          }
        });
        this.listTable.forEach(it=>{
          it.data = item+'/'+this.dataSc2
        });
      }else{
        let indexY = this.dataListSc1.findIndex(it => it.value == item);
        this.yAxis[0].axisLabel.formatter = '{value} '.concat(this.dataListSc2[indexY].unit);
        this.yAxis[0].unit = this.dataListSc2[indexY].unit;
        this.dataListSc1.forEach(it => {
          if(it.value == item){
            it.disable = true;
          } else {
            it.disable = false;
          }
        });
        this.listTable.forEach(it=>{
          it.data = this.dataSc1+'/'+item;
        });
      };
    },
    handleCheckWarningByDataCode(SiteStationId,DeviceCode,DataCode, item) {
            var SiteId = getCookieSiteUser();
            this.checkWarningByDataCode({SiteID: SiteId, SiteStationId: SiteStationId,DeviceCode: DeviceCode,DataCode:DataCode})
                .then(response => {
                    if (response.code === 0) {
                        item.hasWarning = response.data;
                        this.loading = false;

                    } else {
                        item.hasWarning = false;
                        this.loading = false;
                        this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                    if(err.code != undefined)
                      this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
                })
                .finally(final => {
                    this.loading = false;
                    this.isSubmitAction = false;
                });
        },
    handleChangeTsStationSC(item){
      let seriIndex = this.series.findIndex(s=>s.index == item.index);
      if(seriIndex > -1){
        this.series[seriIndex].name= this.isTSSC ? item.tsStation : item.stationname;
      }
      this.legend.data = this.series.map(t=>t.name);
      const tsStationSelected = this.listTable.filter(x=> x.stationname === item.stationname).map(i=>i.tsStation);
        this.listTable.forEach(it => {
          if(it.stationname == item.stationname) {
            it.ItemTsStations.forEach(t=>{
              if(tsStationSelected.includes(t.value) && it.tsStation != t.value) {
                t.disable = true;
              }
              else if(!tsStationSelected.includes(t.value) && it.tsStation == t.value) {
                t.disable = false;
              } else {
                t.disable = false;
              }
            });
          }
        });

    },
    handleChangeTsStationOtherChart(item){
      let rq = {
        SiteId: getCookieSiteUser(),
        StationId: item.stationname,
        DeviceCode: item.device,
        SokGrTs: item.tsStation
      }
      this.loading = true;
      this.getCbbOrgInfoBySokGr(rq)
        .then(response => {
          if (response.code === 0) {
            let indexTable = this.listTable.findIndex((e => e.index === item.index))
            this.listTable[indexTable].ItemOrgNo = response.data;
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
        if (this.frametypeChoice === FrameTypesValue.SVG || this.frametypeChoice === FrameTypesValue.SVGTS) {
          item.dataCodes = [];
          var channelFlg = item.Itemdevices.find(x => x.value === item.device)?.channelFlg;
          var tsStationName = item.ItemTsStations.find(x => x.value === item.tsStation)?.name;
          this.$refs.chartComponent[0].setLabel(item.index, item.device, item.Itemdevices, item.stationname, this.stationList, channelFlg, tsStationName, this.isDeviceTsOrSimilarTs(item.device));
        }

    },
    handleChangeStation(item) {
      if(this.frametypeChoice == FrameTypesValue.ScatterChart){
        item.data = this.dataSc1+'/'+this.dataSc2;
        item.data = this.dataSc1+'/'+this.dataSc2;
        item.orgNo = this.orgNo;
        var dataSc = [];
        for (let index = 0; index < 36; index++) {
          dataSc.push([Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)])
        }
        let seriIndex = this.series.findIndex(s=>s.index == item.index);
        if(seriIndex > -1){
          this.series[seriIndex].name= this.isTSSC ? '' : item.stationname;
        } else {
          let objSeries = {
            index: item.index,
            name: this.isTSSC ? '' : item.stationname,
            type: 'scatter',
            color: item.color,
            emphasis: {
              focus: 'series'
            },
            // prettier-ignore
            data: dataSc,
            markArea: {
              silent: true,
              itemStyle: {
                color: 'transparent',
                borderWidth: 1,
                borderType: 'dashed'
              },
              data: [
                [
                  {
                    // name: 'Female Data Range',
                    xAxis: 'min',
                    yAxis: 'min'
                  },
                  {
                    xAxis: 'max',
                    yAxis: 'max'
                  }
                ]
              ]
            },
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            }
          };
          this.series.push(objSeries);
        }
        this.legend.data = this.series.map(t=>t.name);
        this.listTable.forEach(it => {
          it.stationList.forEach(t=>{
            if(this.listTable.filter(l=>(l.stationname == t.value && l.index != it.index)).length > 0){
              t.disable = true;
            }else{
              t.disable = false;
            }
          });
        });
        if(this.isTSSC){
          this.handleGetCbbStationTS(item, this.deviceCode, this.orgNo);
        }
      } else {
        this.handleGetCbbDevice(item, item.stationname)
      }
      // this.autoTitle = this.makeTitleLineStack();
      // this.titleChart.text = this.makeTitleLineStack();
    },
    handleHeaderByDevice(){
      const indexColumnStation = this.headers.findIndex((e => e.value === 'stationname'));
      const indexColumnDevice = this.headers.findIndex((e => e.value === 'device'));
      const indexColumnTsStation = this.headers.findIndex((e => e.value === 'tsStation'));
      const indexColumnOrgNo = this.headers.findIndex((e => e.value === 'orgNo'));
      const indexColumnChannelNo = this.headers.findIndex((e => e.value === 'channelNo'))
      const tsStationColumn = {text: 'labels.framesetting.tsStation', value: 'tsStation', align: 'center', sortable: false};
      const orgNoColumn = {text: 'labels.framesetting.orgNo', value: 'orgNo', align: 'center', sortable: false};
      const channelNoColumn = {text: 'labels.framesetting.channelName', value: 'channelNo', align: 'center', sortable: false};
      if(this.isTSSC){
        if(indexColumnTsStation === -1){
          this.headers.splice(indexColumnStation + 1,0, tsStationColumn);
          this.$set(this.headers,indexColumnStation + 1, tsStationColumn);
          return;
        }
      }
      if(this.frametypeChoice === 'SC' && !this.isDeviceTsOrSimilarTs(Number(this.deviceCode))){
        if(indexColumnTsStation > -1){
          this.headers.splice(indexColumnTsStation,1);
          return;
        }
      }
      if(this.isIncludesDeviceTS){
        if(indexColumnTsStation === -1){
          this.headers.splice(indexColumnDevice + 1,0, tsStationColumn);
          this.$set(this.headers,indexColumnDevice + 1, tsStationColumn);
        }
        if(indexColumnOrgNo === -1){
          this.headers.splice(indexColumnDevice + 2,0, orgNoColumn);
          this.$set(this.headers, indexColumnDevice + 2, orgNoColumn);
        }
        return;
      }
      else{
        if(indexColumnTsStation > -1 && indexColumnOrgNo > -1){
          this.headers.splice(indexColumnTsStation,2);
          // this.$set(this.headers, indexColumnTsStation, tsStationColumn);
          return;
        }
      }
      if(this.isIncludesDeviceChannel){
        if(indexColumnChannelNo === -1){
          this.headers.splice(indexColumnDevice + 1,0, channelNoColumn);
          this.$set(this.headers,indexColumnDevice + 1, channelNoColumn);
          return;
        }
      }
      else{
        if(indexColumnChannelNo > -1){
          this.headers.splice(indexColumnChannelNo,1);
          return;
        }
      }
    },
    handleChangeDevice(item) {
      const currDevice = item.Itemdevices.find(x => x.value == item.device);
      if(!currDevice.isDeviceTs){
        this.handleGetListChannel(item, item.stationname, item.device);
        this.handleGetCbbDeviceData(item, item.device);
      }
      else{
        this.handleGetCbbStationTS(item, item.device, null);
        // this.handleGetCbbOrgInfo();
      }
      if (this.frametypeChoice === FrameTypesValue.SVG || this.frametypeChoice === FrameTypesValue.SVGTS) {
        item.dataCodes = [];
        var channelFlg = item.Itemdevices.find(x => x.value === item.device)?.channelFlg;
        this.$refs.chartComponent[0].setLabel(item.index, item.device, item.Itemdevices, item.stationname, this.stationList, channelFlg, "", this.isDeviceTsOrSimilarTs(item.device));
        const selectedDevice = {
          index: item.index,
          stationId: item.stationname,
          deviceCode: item.device
        }
        const isExistDevice = this.selectedDeviceList.find(x => x.index === item.index);
        if (isExistDevice) {
          this.selectedDeviceList[item.index].deviceCode = item.device;
        } else {
          this.selectedDeviceList.push(selectedDevice);
        }
      }
    },
    loadScatterInfo(item){
      let siteID = getCookieSiteUser();
      if(this.isTSSC){
        console.log('second')
        const orgData = this.ItemOrgInfoAll?.find(x=>x.orgNo === this.orgNo)?.orgData;
        this.dataListSc1 = orgData.map((i, index) => {
          var dataI = {value: i.dataMapping, name: (this.$i18n.locale === 'en') ? i.headerEn : i.headerJp, unit: i.unit, disable: false, icon: null};
          return dataI;
        });
        this.dataListSc2 = cloneDeep(this.dataListSc1);
        this.dataListSc2.forEach(it => {
          if(it.value == this.dataSc1){
            it.disable = true;
          } else {
            it.disable = false;
          }
        });
        this.dataListSc1.forEach(it => {
          if(it.value == this.dataSc2){
            it.disable = true;
          } else {
            it.disable = false;
          }
        });
      }
      else{
        this.loading = true;
        this.getCbbDeviceData({DeviceCode: item})
        .then(response => {
          if (response.code === 0) {
            this.dataListSc1 = response.data.map((i, index) => {
              var dataI = {value: i.dataCode, name: this.$i18n.locale === 'en' ? i.nameEn : i.nameJp, unit: i.unit, disable: false, icon: i.icon};
              return dataI;
            });
            this.dataListSc2 = cloneDeep(this.dataListSc1);
            this.dataListSc2.forEach(it => {
              if(it.value == this.dataSc1){
                it.disable = true;
              } else {
                it.disable = false;
              }
            });
            this.dataListSc1.forEach(it => {
              if(it.value == this.dataSc2){
                it.disable = true;
              } else {
                it.disable = false;
              }
            });
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
      }
      this.loading = true;
      this.getCbbSiteStation({DeviceCode: item,SiteId: siteID}).then(response => {
        if (response.code === 0) {
          this.stationList = cloneDeep(response.data);
          this.listTable.forEach(data=>{
            data.stationList = cloneDeep(response.data);
          });
          this.listTable.forEach(it => {
            // if(it.index != itemFirst.index){
            it.stationList.forEach(t=>{
              if(this.listTable.filter(l=>(l.stationname == t.value && l.index != it.index)).length > 0){
                t.disable = true;
              }else{
                t.disable = false;
              }
              // let i =itemFirst.stationList.findIndex(s=>s.value == t.value);
              // if(!t.disable && t.value != itemFirst.stationname) {
              //   itemFirst.stationList[i].disable = true;
              // } else {
              //   itemFirst.stationList[i].disable = false;
              // }
            });
            // }
          });
          this.loading = false;
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          this.loading = false;
        }
      }).catch(err => {
        if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
      });
    },
    handleChangeDeviceSCTC(item) {
      if(this.frametypeChoice == FrameTypesValue.ScatterChart || this.frametypeChoice == FrameTypesValue.TableChart){
        if(this.isDeviceTsOrSimilarTs(Number(item))) {
          this.orgNo = null
          this.handleGetCbbOrgInfo({deviceCode: item})
        }
        if(!this.isTSSC){
          this.orgNo = null;
          this.loading = true;
          this.getCbbDeviceData({DeviceCode: item})
          .then(response => {
            if (response.code === 0) {
              this.dataListSc1 = response.data.map((i, index) => {
                var dataI = {value: i.dataCode, name: this.$i18n.locale === 'en' ? i.nameEn : i.nameJp, unit: i.unit, disable: false, icon: i.icon};
                return dataI;
              });
              this.dataListSc2 = cloneDeep(this.dataListSc1);
              this.loading = false;
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
              this.loading = false;
            }
          })
          .catch(err => {
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
              this.loading = false;
          })
          .finally(final => {
            this.isSubmitAction = false;
            this.loading = false;
          });
        }
        else{
          this.dataListSc1 = [];
          this.dataListSc2 = [];
        }
        var tempItem = this.initValueOneItem();
        tempItem.index = 0;
        tempItem.order = 1;
        tempItem.device = this.deviceCode;
        if(this.deviceCode != undefined){
          let siteID = getCookieSiteUser();
          this.loading = true;
          this.getCbbSiteStation({DeviceCode: item,SiteId: siteID}).then(response => {
            if (response.code === 0) {
              tempItem.stationList = response.data;
              this.stationList = response.data;
              this.listTable = [tempItem];
              this.loading = false;
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
              this.loading = false;
            }
          }).catch(err => {
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
              this.loading = false;
          });
          if(this.frametypeChoice == FrameTypesValue.ScatterChart){
            this.legend.data = [];
            this.series = [];
            this.xAxis[0].axisLabel.formatter = '';
            this.yAxis[0].axisLabel.formatter = '';
          }
        }
        this.dataSc1 = undefined;
        this.dataSc2 = undefined;
      }
      this.handleHeaderByDevice();
    },
    handleChangeOrgNoSC(item){
      const orgData = this.ItemOrgInfoAll?.find(x=>x.orgNo === this.orgNo)?.orgData;
      this.dataListSc1 = orgData.map((i, index) => {
        var dataI = {value: i.dataMapping, name: this.$i18n.locale === 'en' ? i.headerEn : i.headerJp, unit: i.unit, disable: false, icon: null};
        return dataI;
      });
      this.dataListSc2 = cloneDeep(this.dataListSc1);
      var tempItem = this.initValueOneItem();
      tempItem.index = 0;
      tempItem.order = 1;
      tempItem.device = this.deviceCode;
      this.legend.data = [];
      this.series = [];
      this.xAxis[0].axisLabel.formatter = '';
      this.yAxis[0].axisLabel.formatter = '';

      this.dataSc1 = undefined;
      this.dataSc2 = undefined;
    },
    setSymbolByDecieId(item) {
      let indexTable = this.listTable.findIndex((e => e.index === item.index));
      let selectedDevice = this.listTable[indexTable].Itemdevices.find(element => {
          return element.value === item.device;
        }
      )
      const symbol = selectedDevice?.icon || 'circle'
      this.listTable[indexTable].oldSymbol = symbol;
      this.listTable[indexTable].pointer = symbol;
      this.series[indexTable].symbol = symbol;
    },
    makeTitleLineStack() {
      var deviceNameAll = this.listTable.map((item, index) => {
        const deviceName = item.Itemdevices.find(element => {
          return element.value.toString() == item.device.toString() ? element.name : '';
        });
        return deviceName ? deviceName.name : ""
      }).join('/')
      var stationNameAll = this.listTable.map((item, index) => {
        const stationName = this.stationList.find(element => {
          return element.value.toString() == item.stationname.toString() ? element.label : '';
        });
        return stationName ? stationName.label : ""
      }).join('/')
      return deviceNameAll + " " + stationNameAll;
    },
    handleGetCbbDeviceData(item, deviceCode) {
      this.loading = true;
      this.getCbbDeviceData({DeviceCode: deviceCode})
        .then(response => {
          if (response.code === 0) {
            let indexTable = this.listTable.findIndex((e => e.index === item.index))
            this.listTable[indexTable].ItemData = response.data.map((i, index) => {
              var dataI = {value: i.dataCode, name: this.$i18n.locale === 'en' ? i.nameEn : i.nameJp, unit: i.unit, disable: false, icon: i.icon};
              this.listTable.forEach(v => {
                if (item.stationname === v.stationname && item.device === v.device && i.dataCode === v.data) {
                  dataI.disable = true;
                }
              });
              return dataI;
            });
            var channelFlg = this.listTable[indexTable].Itemdevices.find(x => x.value === item.device)?.channelFlg;
            this.listTable[indexTable].data = null;
            this.listTable[indexTable].tsStation = null;
            this.listTable[indexTable].orgNo = null;
            this.listTable[indexTable].legendName = null;
            this.$set(this.listTable, indexTable, {...this.listTable[indexTable], channelFlg: channelFlg});
            if (this.frametypeChoice === FrameTypesValue.SVG) {
              this.setSymbolByDecieId(item)
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
          this.handleHeaderByDevice();
        });

    },
    handleGetCbbStationTS(item, deviceCode, orgNo) {
      let siteID = getCookieSiteUser();
      this.loading = true;
      this.getCbbStationTSByDevice({DeviceCode: deviceCode, SiteId: siteID, StationId: item.stationname, OrgNo: orgNo})
        .then(response => {
          if (response.code === 0) {
            let indexTable = this.listTable.findIndex((e => e.index === item.index))
            this.listTable[indexTable].ItemTsStations = response.data.map((i, index) => {
              var dataI = {value: i.id, name: i.stationName, disable: false };
              this.listTable.forEach(v => {
                if (item.stationname === v.stationname && i.id === v.tsStation) {
                  dataI.disable = true;
                }
              });
              return dataI;
            });
            this.listTable[indexTable].tsStation = undefined;
            this.$set(this.listTable, indexTable, item);
            if (this.frametypeChoice === FrameTypesValue.SVG) {
              this.setSymbolByDecieId(item)
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
          this.handleHeaderByDevice();
        });

    },
    async handleGetCbbOrgInfo(request) {
      this.loading = true;
      await this.getCbbOrgInfo(request)
        .then(response => {
          if (response.code === 0) {
            this.ItemOrgInfoAll= response.data;
            console.log('first');
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    handleChangeOrgNo(item){
      let indexTable = this.listTable.findIndex((e => e.index === item.index))
      const orgData = item.ItemOrgNo?.find(x=>x.orgNo === item.orgNo)?.orgData;
      const dataSelected = this.listTable.filter(x=> item.stationname == x.stationname && item.device == x.device && item.orgNo == x.orgNo && item.tsStation == x.tsStation).map(i=>i.data);
      this.listTable[indexTable].ItemData = orgData.map((i, index) => {
        var dataI = {value: i.dataMapping, name: this.$i18n.locale === 'en' ? i.headerEn : i.headerJp, unit: i.unit, disable: false, icon: null};
        // this.listTable.forEach(v => {
        //   if (item.stationname == v.stationname && item.device == v.device && item.orgNo == v.orgNo && item.data == v.data) {
        //     if(dataSelected.includes(item.data))
        //       dataI.disable = true;
        //   }
        // });
        if(dataSelected.includes(i.dataMapping))
          dataI.disable = true;
        return dataI;
      });
      // this.listTable.forEach(it => {
      //     if(it.stationname == item.stationname && it.Itemdevices[0].name == item.Itemdevices[0].name && item.orgNo == v.orgNo) {
      //       it.ItemData.forEach(t=>{
      //         if(dataSelected.includes(t.value) && it.data != t.value) {
      //           t.disable = true;
      //         }
      //         else if(!dataSelected.includes(t.value) && it.data == t.value) {
      //           t.disable = false;
      //         } else {
      //           t.disable = false;
      //         }
      //       });
      //     }
      //   });
      this.listTable[indexTable].data = null;
      this.listTable[indexTable].dataCodes = [];
      this.$set(this.listTable, indexTable, item);
    },
    handleChangeChannelNo(item){
      let indexTable = this.listTable.findIndex((e => e.index === item.index))
      const datas = [...this.listTable[indexTable].ItemData];
      this.listTable[indexTable].data = null;
      this.listTable[indexTable].dataCodes = [];
      this.listTable[indexTable].legendName = null;
      this.$set(this.listTable, indexTable, item);
      const dataSelected = this.listTable.filter(x=> item.stationname == x.stationname && item.device == x.device && item.channelNo == x.channelNo).map(i=>i.data);
      this.listTable[indexTable].ItemData = datas.map((i, index) => {
        var dataI = {...i, disable: false};
        if(dataSelected.includes(i.value))
          dataI.disable = true;
        return dataI;
      });
    },
    makeLegendNameByChannel(item){
      const dataObj = item.ItemData?.find(element => {
        return element.value.toString() === item.data.toString() ? element.name : '';
      });
      const channelObj = item.ItemChannelList?.find(element => {
        return element.value.toString() === item.channelNo.toString() ? element.name : '';
      });

      const dataName = (dataObj.name != undefined ? dataObj.name : "");
      const channelName = (channelObj != undefined ? channelObj.name : "");
      if(this.frametypeChoice === FrameTypesValue.GaugeChart){
        return channelName.concat(" ").concat(dataName)?.trim();
      }
      else{
        return channelName.concat(" ").concat(dataName).concat((dataObj.unit != undefined && dataObj.unit != null && dataObj.unit != "" ? "(" + dataObj.unit + ")" : ""))?.trim();
      }
    },
    handleGetListChannel(item, siteStationId, deviceCode){
      let siteID = getCookieSiteUser();
      this.loading = true;
      const request = { siteId: siteID,siteStationId: siteStationId,deviceCode: deviceCode };
      this.getListChannel(request).then((response) => {
          if (response.code === ResponseCode.SUCCESS) {
            let indexTable = this.listTable.findIndex((e => e.index === item.index))
            this.listTable[indexTable].ItemChannelList = response.data.map((i, index)=>{
              var channel = {value: i.channelNo, name: i.channelName};
              return channel;
            });
            this.$set(this.listTable, indexTable, this.listTable[indexTable]);
          }
          }).catch((error) => {
          }).finally(() => {
            this.loading = false;
          })
    },

    handleGetCbbDevice(item, stationId) {
      let request = {StationId: stationId};
      if (this.frametypeChoice === FrameTypesValue.SVG) {
        const listDevice = [];
        this.selectedDeviceList.forEach(device => {
          if (device.stationId === stationId) {
            listDevice.push(device.deviceCode);
          }
        })
        request = {
          StationId: stationId,
          SelectedDeviceCode: listDevice
        }
      }
      this.loading = true;
      this.getCbbSiteDeviceByStation(request)
        .then(response => {
          if (response.code === 0) {
            if (item !== null) {
              let indexTable = this.listTable.findIndex((e => e.index === item.index))
              this.listTable[indexTable].Itemdevices = response.data.map((item, index) => {
                var disable = (!this.frameSupportDataLogger.includes(this.frametypeChoice) && item.channelFlg == 1) || (this.isIncludesDeviceTS && item.channelFlg == 1) || (this.isIncludesDeviceChannel && this.isDeviceTsOrSimilarTs(item.deviceCode))
                return {value: item.deviceCode, name: this.$i18n.locale === 'en' ? item.nameEn : item.nameJp, icon: item.icon, channelFlg: item.channelFlg, isDeviceTs: item.isDeviceTs, disable: disable}
              });
              this.listTable[indexTable].device = null;
              this.listTable[indexTable].data = null;
              this.listTable[indexTable].ItemData = [];
              this.listTable[indexTable].ItemOrgNo = [];
              this.listTable[indexTable].tsStation = null;
              this.listTable[indexTable].orgNo = null;
              this.listTable[indexTable].legendName = null;
              this.$set(this.listTable, indexTable, item);
              this.loading = false;
            } else {
              this.ItemdevicesAll = response.data.map((item, index) => {
                return {value: item.deviceCode, name: this.$i18n.locale === 'en' ? item.nameEn : item.nameJp, icon: item.icon}
              });
              this.loading = false;
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
          this.handleHeaderByDevice();
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    resizeEvent: function (i, newH, newW, newHPx, newWPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.h = newH;
      chart.w = newW;
      chart.height = parseInt(newHPx);
      this.$set(this.layout, index, chart);
    },
    resizedEvent: function (i, newX, newY, newHPx, newWPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.height = parseInt(newHPx);
      this.$set(this.layout, index, chart);
    },
    containerResizedEvent: function (i, newH, newW, newHPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.h = newH;
      chart.w = newW;
      chart.height = parseInt(newHPx);
      this.$set(this.layout, index, chart);
    },
    // handle change pointer color by index
    onChanecolorPointer(item) {
      if (item) {
        this.$refs.chartComponent[0].setTextColorTooltip(item.index, item.colorPointer)
      }
    },
    // binding x asis and y asis from SVG chart component into row of table
    onChangePointer(pointer) {
      this.listTable[pointer.index].vertical = pointer.vertical;
      this.listTable[pointer.index].horizontal = pointer.horizontal;
    },
    createItem() {
      let siteID = getCookieSiteUser();
      var tempItem = {}
      tempItem.index = this.listTable.length;
      tempItem.order = this.listTable.length + 1;
      tempItem.stationname = null
      tempItem.tsStation = null
      tempItem.orgNo = null
      tempItem.channelNo = null
      tempItem.device = null;
      tempItem.data = null;
      tempItem.legendName = null;
      tempItem.hasWarning = false;
      tempItem.color = "#5470c6";
      tempItem.warning = {}
      let date = [];
      let data = [Math.random() * 10];
      if (this.modurn !== FrameTypesValue.SVG) {
        var coeff = 1000 * 60 * 10;
        let base = +new Date(Math.round(Date.now()/ coeff) * coeff - 7 * 24 * 60 * 60 * 1000)

        // let oneDay = 24 * 3600 * 1000;
        let tenMin = 600 * 1000;
        for (let i = 0; i < 1008; i++) {
          var now = new Date((base += tenMin));
          var mm = now.getMonth() + 1; // getMonth() is zero-based
          var dd = now.getDate();
          var hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
          var minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
          var dateStr = [now.getFullYear(),
          (mm > 9 ? '' : '0') + mm,
          (dd > 9 ? '' : '0') + dd
          ].join('/');
          var timeStr = [hour, minutes].join(':')
          date.push([dateStr, timeStr].join(' '));
          data.push((Math.random() - 0.4) * 10 + data[data.length - 1]);
        }
      }

      if (this.modurn === FrameTypesValue.SVG || this.modurn === FrameTypesValue.SVGTS) {
        tempItem.pointer = "circle";
        tempItem.colorPointer = "#0035fa";
        tempItem.dataCodes = [];
        tempItem.vertical = null;
        tempItem.horizontal = null;
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        tempItem.channelNoLst = [];
        let obj = {
          label: {
            formatter: "{b}",
            position: "right",
            show: true,
            fontSize: 20,
            color: "#0035fa"
          },
          index: this.listTable.length,
          name: "",
          type: "effectScatter",
          coordinateSystem: "geo",
          geoIndex: 0,
          symbol: "circle",
          oldSymbol: "circle",
          view: true,
          smooth:true,
          itemStyle: {
            color: "#0035fa",
          },
          tooltip: {
            textStyle: {
              color: "#050505",
            }
          },
          data: [
            {
              value: [],
              name: "",
              tooltip: [],
            }
          ],
        }
        // this.legend.data.push('data ' + Number(this.listTable.length) + 1)
        this.series.push(obj)
      } else if (this.modurn === 'GLS') {
        if(Number(this.listTable.length) == 1 ){
          tempItem.posChart = this.listTable[this.listTable.length - 1].posChart == "chart1" ? "chart2" : "chart1";
          tempItem.chart1 = this.listTable[this.listTable.length - 1].posChart == "chart1" ? false : true;
          tempItem.chart2 = this.listTable[this.listTable.length - 1].posChart == "chart1" ? true : false;
        } else {
          tempItem.posChart = "chart1";
          tempItem.chart1 = true;
          tempItem.chart2 = false;
        }
        tempItem.selected = ''
        tempItem.display1 = false;
        tempItem.color1 = '#3ba272';
        tempItem.display2 = false;
        tempItem.color2 = '#fac858';
        tempItem.display3 = false;
        tempItem.color3 = '#ee6666';
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        var objXAxis = {
          gridIndex: tempItem.chart1 ? 0 : 1,
          type: 'category',
          boundaryGap: false,
          axisLine: {onZero: true},
          data: date,
          position: !tempItem.chart1 ? 'top' : 'bottom'
        };
        var objYAxis = {
          gridIndex: tempItem.chart1 ? 0 : 1,
          name: '',
          type: 'value',
          inverse: !tempItem.chart1 ? true : false
        };
        var objSeries = {
          index: this.listTable.length,
          name: '',
          type: 'line',
          color: '#5470c6',
          xAxisIndex: objXAxis.gridIndex,
          yAxisIndex: objYAxis.gridIndex,
          symbolSize: 8,
          // prettier-ignore
          data: data,
          connectNulls: true
        };
        if (this.yAxis.length < 2) {
          this.yAxis.push(objYAxis);
          this.xAxis.push(objXAxis);
        }
        let indexXaxis = this.xAxis.findIndex(item => item.gridIndex == (tempItem.chart1 ? 0 : 1));
        let indexYaxis = this.yAxis.findIndex(item => item.gridIndex == (tempItem.chart1 ? 0 : 1));
        objSeries.xAxisIndex = indexXaxis;
        objSeries.yAxisIndex = indexYaxis;
        // this.legend.data.push('data ' + Number(this.listTable.length) + 1);
        this.series.push(objSeries)
        let index = 0;
        this.series = this.series.map((item) => {
          if(item.hasOwnProperty('index'))
            item.index = index++;
          return item;
        });
      } else if (this.modurn === 'MAC') {
        tempItem.selected = 'line'
        tempItem.display1 = false;
        tempItem.color1 = '#3ba272';
        tempItem.display2 = false;
        tempItem.color2 = '#fac858';
        tempItem.display3 = false;
        tempItem.color3 = '#ee6666';
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        var objYAxis = {
          index: this.listTable.length,
          type: "value",
          name: "",
          position: Number(this.listTable.length) > 0 ? "right" : "left",
          offset: Number(this.listTable.length) > 1 ? (this.yOffset * Number(this.listTable.length - 1)) : 0,
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#5470c6',
            },
          },
          axisLabel: {
            formatter: "",
          },
        };
        var objSeries = {
          index: this.listTable.length,
          name: '',
          type: 'line',
          yAxisIndex: Number(this.listTable.length),
          color: '#5470c6',
          data: data,
          connectNulls: true
        };
        // this.legend.data.push('data ' + Number(this.listTable.length) + 1)
        this.yAxis.push(objYAxis)
        this.series.push(objSeries)
      } else if (this.modurn === 'SC') {
        tempItem.fromdate = ''
        tempItem.todate = ''
        tempItem.menufrom = false
        tempItem.menuto = false
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        tempItem.device = this.deviceCode;
        if(this.deviceCode == undefined)
          this.stationList = [];
        else if(this.isDeviceTsOrSimilarTs(Number(this.deviceCode)))
          tempItem.tsStation = undefined;
        tempItem.stationList = cloneDeep(this.stationList);
        tempItem.stationList.forEach(station=>{

          if(this.listTable.filter(l=>l.stationname == station.value).length > 0){
            station.disable = true;
          }else{
            station.disable = false;
          }
        });
        // var dataSc = [];
        // for (let index = 0; index < 36; index++) {
        //   const pointValue = Math.floor(Math.random() * 100);
        //   dataSc.push([pointValue, pointValue])
        // }

        // let obj = {
        //   index: this.listTable.length,
        //   name: 'data ' + Number(this.listTable.length),
        //   type: 'scatter',
        //   emphasis: {
        //     focus: 'series'
        //   },
        //   color: "#5470c6",
        //   // prettier-ignore
        //   data: dataSc,

        //   markPoint: {
        //     data: [
        //       {type: 'max', name: 'Max'},
        //       {type: 'min', name: 'Min'}
        //     ]
        //   },

        // }
        // this.legend.data.push('data ' + Number(this.listTable.length) + 1)
        // this.series.push(obj)
      } else if (this.modurn === 'LS') {
        tempItem.display1 = false;
        tempItem.color1 = '#3ba272';
        tempItem.display2 = false;
        tempItem.color2 = '#fac858';
        tempItem.display3 = false;
        tempItem.color3 = '#ee6666';
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        let obj = {
          index: this.listTable.length,
          name: 'data ' + Number(this.listTable.length),
          type: 'line',
          color: '#5470c6',
          data: data,
          smooth: true,
          connectNulls: true
        }
        this.legend.data.push('data ' + Number(this.listTable.length) + 1)
        this.series.push(obj)
      } else if (this.modurn === 'LR') {
        tempItem.display1 = false;
        tempItem.color1 = '#3ba272';
        tempItem.display2 = false;
        tempItem.color2 = '#fac858';
        tempItem.display3 = false;
        tempItem.color3 = '#ee6666';
        tempItem.Itemdevices = [];
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
        let obj = {
          index: this.listTable.length,
          name: 'data ' + Number(this.listTable.length),
          type: 'line',
          showSymbol: false,
          color: '#5470c6',
          smooth: true,
          data: data,
          connectNulls: true
        }
        this.legend.data.push('data ' + Number(this.listTable.length) + 1)
        this.series.push(obj)
      } else if (this.modurn === 'GC') {
        // Task #215563
        // tempItem.stationname = this.selectedStation;
        tempItem.Itemdevices = [];
        // Task #215563
        tempItem.dataName = null;
        tempItem.unit = null;
        tempItem.icon = null;
        tempItem.ItemData = [];
        tempItem.ItemOrgNo = [];
        tempItem.ItemTsStations = [];
      }

      this.listTable.push(tempItem)
    },
    onDeleteLine(item) {
      let indexSerie = this.series.findIndex((e => e.index == item.index))
      let indexTable = this.listTable.findIndex((e => e.index == item.index))

      // Remove item from selected devices case Frame SVG
      if (this.frametypeChoice === FrameTypesValue.SVG) {
        const index = this.selectedDeviceList.findIndex(element => {
          element.stationId !== item.stationname && element.deviceCode !== item.device;
        })
        this.selectedDeviceList = this.selectedDeviceList.splice(index, 1);
        this.selectedRow = -1;
      }
      if (this.frametypeChoice == FrameTypesValue.GirdMultipleLineChart) {
        let gridIndex = item.posChart == "chart1" ? 0 : 1;
        let indexY = this.yAxis.findIndex((e => e.gridIndex == gridIndex))
        let indexX = this.xAxis.findIndex((e => e.gridIndex == gridIndex))
        this.yAxis.splice(indexY, 1);
        this.xAxis.splice(indexX, 1);
        if(this.yAxis.length == 1 && this.xAxis.length == 1){
          this.series.forEach(item=>{item.xAxisIndex = this.yAxis.length - 1;item.yAxisIndex = this.yAxis.length - 1;})
        }
      } else if(this.frametypeChoice == FrameTypesValue.MultipleYAxisMixLineBarChart){
        let indexY = this.yAxis.findIndex((e => e.index == item.index));
        this.yAxis.splice(indexY, 1);
      }
      this.listTable.splice(indexTable, 1);
      this.series.splice(indexSerie, 1);
      this.listTable = this.listTable.map((item, index) => {
        item.index = index;
        item.order = index + 1;
        return item;
      });
      let index = 0;
      this.series = this.series.map((item) => {
        if(item.hasOwnProperty('index'))
          item.index = index++;
        return item;
      });
      this.legend.data = this.series.filter(item => {return !item.hasOwnProperty('key'); }).map(item=>{return item.name;});
      //delete warning
      for (let index = 1; index < 4; index++) {
        const key = "w_" + item.stationname + "_" + item.device + "_" + item.data + "_" + index;
        const itemSelected = this.series.findIndex(e => e.key === key);
        if (itemSelected > -1) {
          this.series.splice(itemSelected, 1);
        }
      }
      if(this.frametypeChoice == FrameTypesValue.ScatterChart){
        this.listTable.forEach(it => {
          // if(it.index != itemFirst.index){
          it.stationList.forEach(t=>{
            if(this.listTable.filter(l=>(l.stationname == t.value && l.index != it.index)).length > 0){
              t.disable = true;
            }else{
              t.disable = false;
            }
            // let i =itemFirst.stationList.findIndex(s=>s.value == t.value);
            // if(!t.disable && t.value != itemFirst.stationname) {
            //   itemFirst.stationList[i].disable = true;
            // } else {
            //   itemFirst.stationList[i].disable = false;
            // }
          });
          // }
        });
      } else {
        const dataSelected = this.listTable.map(i=>i.data);
        this.listTable.forEach(it => {
          if(it.Itemdevices[0].name == item.Itemdevices[0].name) {
            it.ItemData.forEach(t=>{
              if(dataSelected.includes(t.value) && it.data != t.value) {
                t.disable = true;
              }
              else if(!dataSelected.includes(t.value) && it.data == t.value) {
                t.disable = false;
              } else {
                t.disable = false;
              }
            });
          }
        });
      }
      this.handleHeaderByDevice();

    },
    pushDataItem(item) {
      let objectItem = {}
      if (Object.keys(item.data).length !== 0) {
        if (!this.legend.data.some(e => e === item.data.name)) {
          let temp = []
          this.legend.data.push(item.data.name)
          for (let i = 0; i < this.listTable.length; i++) {
            if (Object.keys(this.listTable[i].data).length !== 0) {
              temp.push(this.listTable[i].data.name)
            }
          }
          let NameTemp = ""
          if (this.legend.data.length > temp.length) {
            for (let i = 0; i < this.legend.data.length; i++) {
              if (!temp.includes(this.legend.data[i])) {
                NameTemp = this.legend.data[i]
                this.legend.data.splice(i, 1);
              }
            }
            let indexSerie = this.series.findIndex((e => e.name === NameTemp))
            this.series.splice(indexSerie, 1);
            objectItem.name = item.data.name
            objectItem.type = "line"
            objectItem.data = this.random()
            objectItem.itemStyle = {
              normal: {
                color: item.color
              },
            }
            this.series.push(objectItem)
          } else {
            objectItem.name = item.data.name
            objectItem.type = "line"
            objectItem.data = this.random()
            objectItem.itemStyle = {
              normal: {
                color: item.color
              },
            }
            this.series.push(objectItem)
          }
        } else {
          if (this.series.some(e => e.name === item.data.name && e.itemStyle.normal.color !== item.color)) {
            let indexSerie = this.series.findIndex((e => e.name === item.data.name))
            this.series[indexSerie].itemStyle.normal.color = item.color
          }
        }
        this.pushDataInChart()
      }
    },
    changeChart(item) {
      this.listSelectedStation = [];
      this.selectedStation = undefined;
      this.$refs.form[0].resetValidation();
      this.$refs.form2[0].resetValidation();
      this.listTable = [];
      this.selectedRow = -1;
      switch (item) {
        case 'TC':
          this.checkIP = false
          this.stationflg = false
          this.flgTable = true
          this.deviceflg = true
          this.modurn = item
          this.frametypeChoice = item
          this.setHeaderAndCompByType(item)
          this.resizeComponent('Chart', 650, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          this.modurnComponent = "TableChart",
          this.showDetailByFrameTypeFlg = false,
          // this.editedItem.day = 1;
          this.listHeaderFrameTable = [];
          this.editedItem.exportType = 1;
          this.editedItem.autoUpdateTime = 600;
          break;
        case 'MSC':
          this.modurnComponent = 'Camera';
          this.checkIP = false
          this.IPFlg = true
          this.stationflg = false
          this.flgTable = true
          this.deviceflg = false
          this.modurn = item.value
          this.resizeComponent('Chart', 500, 5, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 0, 0, 0, 0, 4)
          this.deviceCode = 501
          this.showDetailByFrameTypeFlg = true
          break;
        case 'LS':
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item;
          this.modurnComponent = "StackedLine"
          this.resizeComponent('Chart', 650, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          //set header
          this.setHeaderAndCompByType(item);
          this.yAxis = [];
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = []
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          this.showDetailByFrameTypeFlg = false
          break;
        case 'GLS':
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item;
          this.modurnComponent = "EchartRainFall"
          this.resizeComponent('Chart', 500, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          this.setHeaderAndCompByType(item);
          this.xAxis = [];
          this.yAxis = [];
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = [];
          this.showDetailByFrameTypeFlg = false
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          break;
        case 'SC':
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = true
          this.modurn = item
          this.stationList = []
          this.modurnComponent = "ScatterChart"
          let realh = this.calFrameH(800);
          let realHeight = this.calFrameHeight(realh);
          this.resizeComponent('Chart', realHeight, realh, 7, 5, 0)
          this.resizeComponent('toolbox', realHeight, realh, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          this.setHeaderAndCompByType(item);
          this.xAxis = [
            {
              type: 'value',
              scale: true,
              axisLabel: {
                formatter: ''
              },
              splitLine: {
                show: false
              }
            }
          ];
          this.yAxis =  [
            {
              type: 'value',
              scale: true,
              axisLabel: {
                formatter: ''
              },
              splitLine: {
                show: false
              }
            }
          ];
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = [];
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          this.showDetailByFrameTypeFlg = false
          this.fromFormatted = moment().format("YYYY/MM/DD");
          this.toFormatted = moment().format("YYYY/MM/DD");
          this.dateFrom = moment().format("YYYY-MM-DD");
          this.dateTo = moment().format("YYYY-MM-DD");
          break;
        case 'MAC':
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item
          this.modurnComponent = 'EChartMultiY'
          this.setHeaderAndCompByType(item);
          this.resizeComponent('Chart', 650, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          this.listTable = [];
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = [];
          this.yAxis = [];
          this.xAxis = [];
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          this.showDetailByFrameTypeFlg = false
          break;
        case 'GC':
          this.checkIP = false
          // Task #215563
          this.stationflg = false
          // Task #215563
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item;
          this.modurnComponent = "ChartWeather"
          this.setHeaderAndCompByType(item);
          this.resizeComponent('Chart', 320, 3, 14, 0, 1)
          this.resizeComponent('toolbox', 420, 1, 14, 0, 0)
          this.resizeComponent('table', 420, 3, 14, 0, 4)
          this.xAxis = [];
          this.yAxis = [];
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = [];
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          this.showDetailByFrameTypeFlg = false
          break
        case FrameTypesValue.SVG:
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item.value
          this.resizeComponent('Chart', 420, 4, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 4)
          this.modurnComponent = "SVGChart"
          this.modurn = item
          this.setHeaderAndCompByType(item);
          this.xAxis = [];
          this.yAxis = [];
          this.legend = {
            data: []
            ,type: 'scroll',left: 10
          };
          this.series = []
          if (!this.checkDetail && !this.editFlag) {
            this.createItem();
          }
          this.showDetailByFrameTypeFlg = false
          break
        case 'LR':
          this.legend = {data: [],type: 'scroll',left: 10};
          this.series = [];
          this.yAxis = [];
          // this.xAxis = [];
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.typeDefault = 1
          this.modurn = item
          this.modurnComponent = 'LineRace'
          this.setHeaderAndCompByType(item);
          this.resizeComponent('Chart', 650, 6, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 6)
          if (!this.checkDetail && !this.editFlag) {
            this.InitDataTableChart();
          }
          this.showDetailByFrameTypeFlg = false
          break;
        case 'MWC':
          this.modurnComponent = 'Camera';
          this.checkIP = true
          this.IPFlg = false
          this.stationflg = true
          this.flgTable = true
          this.deviceflg = false
          this.modurn = "Camera"
          this.resizeComponent('Chart', 500, 5, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 0, 0, 0, 0, 4)
          this.deviceCode = 500
          this.showDetailByFrameTypeFlg = true
          break;
        case 'SCS':
          this.modurnComponent = 'ScreenShare';
          this.checkIP = false
          this.IPFlg = false
          this.stationflg = true
          this.flgTable = true
          this.deviceflg = false
          this.modurn = "ScreenShare"
          this.resizeComponent('Chart', 500, 5, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 0, 0, 0, 0, 4)
          this.deviceCode = 502
          this.showDetailByFrameTypeFlg = true
          break;
        case FrameTypesValue.SVGTS:
          this.checkIP = false
          this.stationflg = false
          this.flgTable = false
          this.deviceflg = false
          this.modurn = item.value
          this.resizeComponent('Chart', 420, 4, 7, 5, 0)
          this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
          this.resizeComponent('table', 420, 3, 12, 0, 4)
          this.modurnComponent = "SVGChart"
          this.modurn = item
          this.setHeaderAndCompByType(item);
          this.xAxis = [];
          this.yAxis = [];
          this.legend = {
            data: [],type: 'scroll',left: 10
          };
          this.series = [];
          if (!this.checkDetail && !this.editFlag) {
            this.createItem();
          }
          this.showDetailByFrameTypeFlg = false
          break
        default:
        // code block
      }
    },
    calFrameHeight: function(h){
      return 100 * h + (h - 1) * this.margin[0];
    },
    calFrameH: function(frameHeight){
      return Math.round((frameHeight + this.margin[0])/(100 + this.margin[0]));
    },
    resizeComponent(nameComponent, height, h, w, x, y) {
      var indexInChart = this.layout.findIndex(object => {
        return object.type === nameComponent;
      })
      this.layout[indexInChart].h = h
      this.layout[indexInChart].height = height
      this.layout[indexInChart].w = w
      this.layout[indexInChart].x = x
      this.layout[indexInChart].y = y
      this.resizedEvent(this.layout[indexInChart].i, this.layout[indexInChart].h, this.layout[indexInChart].w, this.layout[indexInChart].height, this.layout[indexInChart].width)
    },
    random() {
      let temps = []
      for (let i = 0; i < 8; i++) {
        let x = (Math.random() * 220);
        temps[i] = x;
      }
      return temps
    },
    initCbbData() {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      let request = {
        "siteId": siteID
      }
      if (this.frametypeChoice === FrameTypesValue.MonitorWebCamera || this.frametypeChoice === FrameTypesValue.MonitorSafieCamera) {
        const deviceCode = this.frametypeChoice === FrameTypesValue.MonitorWebCamera ? "500" : "501";
        request = {
          "siteId": siteID,
          "deviceCode": deviceCode
        }
      } else if (this.frametypeChoice === FrameTypesValue.ScreenShare) {
        request = {
          "siteId": siteID,
          "deviceCode": "502",
        }
      } else if (this.frametypeChoice === FrameTypesValue.SVGTS) {
        request = {
          "siteId": siteID,
          "deviceCode": "1000",
        }
      } else if (this.frametypeChoice === FrameTypesValue.TableChart) {
        request = {
          "siteId": siteID,
          "deviceCode": this.deviceCode ? this.deviceCode : -1,
        }
      }
      getCbbStation(request).then(response => {
        if (response.data && response.data.code === ResponseCode.SUCCESS) {
          if(this.frametypeChoice != FrameTypesValue.ScatterChart){
            this.stationList = response?.data?.data;
          }
          if (this.selectedStation !== undefined && this.selectedStation !== null) {
            this.handleGetCbbDevice(null, this.selectedStation)
          }
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
        });
      // get device by site
      getDeviceBySite(request).then(response => {
        if (response.data && response.data.code === ResponseCode.SUCCESS) {
          this.deviceList = response?.data?.data.map((item, i)=>{
            var disable = !this.frameSupportDataLogger.includes(this.frametypeChoice) && item.channelFlg == 1
            return {...item, disable: disable}
          });
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
      });
      // this.handleGetCbbOrgInfo();
    },
    saveFrame: function (event) {
      this.loading = true;
      this.editedItem.frameType = this.frametypeChoice;
      this.editedItem.title = this.autoTitle;
      this.$refs.form[0].validate();
      this.$refs.form2[0].validate();

      if (this.validateAddFrame()) {
        this.loading = false;
        return;
      }
      if (!this.$refs.form[0].validate() || !this.$refs.form2[0].validate()) {
        this.loading = false;
        return;
      }
      const frameData = this.listTable.map((item, index) => {
        const locaiton = item?.vertical + "/" + item?.horizontal;
        let selectedDataCodes = "";
        if(item.dataCodes) {
          item.dataCodes.forEach((element, index) => {
            if (index === item.dataCodes.length - 1) {
              selectedDataCodes = selectedDataCodes + element.toString();
            } else {
              selectedDataCodes = selectedDataCodes + element.toString() + "/";
            }
          })
        }
        let selectedChannelNos = "";
        if(item.channelNoLst) {
          item.channelNoLst.forEach((element, index) => {
            if (index === item.channelNoLst.length - 1) {
              selectedChannelNos = selectedChannelNos + element.toString();
            } else {
              selectedChannelNos = selectedChannelNos + element.toString() + "/";
            }
          })
        }
        return {
          Index: item.index,
          SiteStationId: item.stationname,
          DeviceCode: item.device,
          DataCode: item.data,
          DataCodes: selectedDataCodes,
          Color: item.color,
          Display1: item.display1,
          Color1: item.color1,
          Display2: item.display2,
          Color2: item.color2,
          Display3: item.display3,
          Color3: item.color3,
          TypeChart: item.selected,
          vertical: item?.vertical,
          horizontal: item?.horizontal,
          colorPointer: item?.colorPointer,
          location: locaiton,
          posChart: item.posChart,
          legendName: item.legendName,
          tsStation: item.tsStation,
          orgNo: item.orgNo,
          channelNo: item.channelNo,
          channelNoLst: selectedChannelNos.length > 0 ? selectedChannelNos : null
        }
      });
      let displayStationTsList = [];
      if (this.frametypeChoice === FrameTypesValue.TableChart && this.isDeviceTsOrSimilarTs(Number(this.deviceCode))) {
        displayStationTsList = this.$refs.chartComponent[0].getDisplayStationTsList();
        this.listSelectedStation = [];
        this.listSelectedStation.push(this.selectedStation);
      }
      let request = {
        frameId: this.frameId,
        siteID: getCookieSiteUser(),
        frameName: this.editedItem.frameName,
        linkIp: this.editedItem.link,
        port: this.editedItem.port,
        frameType: this.frametypeChoice,
        title: this.autoTitle,
        stationId: this.selectedStation,
        fileName: this.fileName,
        deviceCode: this.deviceCode,
        legend: this.legend,
        xaxis: this.xAxis,
        yaxis: this.yAxis,
        series: this.series,
        fromDateTime: this.dateFrom,
        toDateTime: this.dateTo,
        frameData: frameData,
        // limitTime: this.editedItem?.day,
        // listStation: this.listSelectedStation,
        listStation: this.selectedStation?.split(),
        headers: this.listHeaderFrameTable,
        displayStationTsList: displayStationTsList,
        ChannelNo: this.editedItem.channelNo,
        exportType: this.editedItem?.exportType,
        autoUpdateTime: this.editedItem?.autoUpdateTime,
      }
      if (this.editFlag) {
        this.handleUpdateFrame(request);
      } else {
        this.handleAddFrame(request);
      }
    },
    handleAddFrame(request) {
      this.addFrame(request).then(response => {
        if (response?.code === ResponseCode.SUCCESS || response?.result?.code === ResponseCode.SUCCESS) {
          this.$emit('handleGetListFrame', {});
          this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK']);
          this.loading = false;
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
        } else {
          const errorCode = response.code ? response.code : response?.result?.code;
          this.commonNotifyVue(this.ERROR_CODE[errorCode], 'warning');
        }
      })
        .catch(err => {
          const errorCode = err.code ? err.code : err?.result?.code;
          this.commonNotifyVue(this.ERROR_CODE[errorCode], 'warning');
        })
        .finally(final => {
          this.loading = false;
        });
    },
    handleUpdateFrame(request) {
      this.updateFrame(request).then(response => {
        if (response?.data?.code === ResponseCode.SUCCESS || response?.result?.code === ResponseCode.SUCCESS || response?.code === ResponseCode.SUCCESS) {
          this.$emit('handleGetListFrame', {});
          this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK']);
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
        } else {
          const errorCode = response.code ? response.code : response?.result?.code;
          this.commonNotifyVue(this.ERROR_CODE[errorCode], 'warning');
        }
      })
        .catch(err => {
          const errorCode = err.data.code ? err.data.code : err?.result?.code;
          this.commonNotifyVue(this.ERROR_CODE[errorCode], 'warning');
        })
        .finally(final => {
          this.loading = false;
        });
    },
    checkIfDuplicateExists(arr) {
        return new Set(arr).size !== arr.length
    },
    validateChartCommon(){
      for (let index = 0; index < this.listTable.length; index++) {
            const stationId = this.listTable[index].stationname;
            const device = this.listTable[index].device;
            const data = this.listTable[index].data;
            const legendName = this.listTable[index].legendName;
            const tsStation = this.listTable[index].tsStation;
            const orgNo = this.listTable[index].orgNo;
            const channelNo = this.listTable[index].channelNo;
            if (stationId === undefined || stationId === null || stationId === '') {
              this.commonErrorVue(this.$t("labels.framesetting.station") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (device === undefined || device === null || device === '') {
              this.commonErrorVue(this.$t("labels.framesetting.device") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (tsStation === undefined || tsStation === null || tsStation === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.tsStation") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (orgNo === undefined || orgNo === null || orgNo === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.orgNo") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.listTable[index].channelFlg == 1 && (channelNo === undefined || channelNo === null || channelNo === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.channelName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (data === undefined || data === null || data === '') {
              this.commonErrorVue(this.$t("labels.framesetting.data") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (legendName === undefined || legendName === null || legendName === '') {
              this.commonErrorVue(this.$t("labels.framesetting.legendName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
          }
          var listLegend = this.listTable.map(item=>{return item.legendName;});
          var isDupplicate = this.checkIfDuplicateExists(listLegend);
          if(isDupplicate){
            this.commonErrorVue(this.$t("labels.framesetting.legendName") + this.ERROR_CODE['IS_DUPLICATE'], 'warning');
            return true;
          }
      return false;
    },
    validateAddFrame() {
      let hasErrros = false;
      if (!this.$refs.form[0].validate()) {
        return true;
      }
      switch (this.frametypeChoice) {
        case 'LS':
          hasErrros = this.validateChartCommon();
          return hasErrros;
        case 'TC':
          this.listHeaderFrameTable.forEach(element => {
            if(element?.text.trim().length === 0) {
              this.commonErrorVue(this.$t("labels.framesetting.headerName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              hasErrros = true;
              return;
            }
          })
          return hasErrros;
        case 'GLS':
          hasErrros = this.validateChartCommon();
          return hasErrros;
        case 'SC':
          if (this.dateFrom === undefined || this.dateFrom === null) {
            hasErrros = true;
            this.commonErrorVue(this.$t("labels.framesetting.ScatterChartFromDateTime") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
            return true;
          } else if (this.dateTo === undefined || this.dateTo === null) {
            hasErrros = true;
            this.commonErrorVue(this.$t("labels.framesetting.ScatterChartToDateTime") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
            return true;
          }
          for (let index = 0; index < this.listTable.length; index++) {
            const stationId = this.listTable[index].stationname;
            const device = this.listTable[index].device;
            const tsStation = this.listTable[index].tsStation;
            if (stationId === undefined || stationId === null || stationId === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.station") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (tsStation === undefined || tsStation === null || tsStation === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.tsStation") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
          }
          return hasErrros;
        case 'MAC':
          hasErrros = this.validateChartCommon();
          return hasErrros;
        case 'GC':
          for (let index = 0; index < this.listTable.length; index++) {
            const stationId = this.listTable[index].stationname;
            const device = this.listTable[index].device;
            const data = this.listTable[index].data;
            const channelNo = this.listTable[index].channelNo;
            const tsStation = this.listTable[index].tsStation;
            const orgNo = this.listTable[index].orgNo;
            const legendName = this.listTable[index].legendName;
            if (stationId === undefined || stationId === null || stationId === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.station") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (device === undefined || device === null || device === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.device") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.listTable[index].channelFlg == 1 && (channelNo === undefined || channelNo === null || channelNo === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.channelName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (tsStation === undefined || tsStation === null || tsStation === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.tsStation") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (orgNo === undefined || orgNo === null || orgNo === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.orgNo") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (data === undefined || data === null || data === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.data") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (legendName === undefined || legendName === null || legendName === '') {
              this.commonErrorVue(this.$t("labels.framesetting.legendName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
          }
          var listLegend = this.listTable.map(item=>{return item.legendName;});
          var isDupplicate = this.checkIfDuplicateExists(listLegend);
          if(isDupplicate){
            this.commonErrorVue(this.$t("labels.framesetting.legendName") + this.ERROR_CODE['IS_DUPLICATE'], 'warning');
            return true;
          }
          return hasErrros;
        case FrameTypesValue.SVG:
          for (let index = 0; index < this.listTable.length; index++) {
            const stationId = this.listTable[index].stationname;
            const device = this.listTable[index].device;
            const dataCodes = this.listTable[index].dataCodes;
            const tsStation = this.listTable[index].tsStation;
            const orgNo = this.listTable[index].orgNo;
            const channelNoLst = this.listTable[index].channelNoLst;
            if (stationId === undefined || stationId === null || stationId === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.station") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (device === undefined || device === null || device === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.device") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (tsStation === undefined || tsStation === null || tsStation === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.tsStation") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.isDeviceTsOrSimilarTs(device) && (orgNo === undefined || orgNo === null || orgNo === '')) {
              this.commonErrorVue(this.$t("labels.framesetting.orgNo") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (this.listTable[index].channelFlg == 1 && (channelNoLst === undefined || channelNoLst === null || channelNoLst.length === 0)) {
              this.commonErrorVue(this.$t("labels.framesetting.channelName") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (dataCodes === undefined || dataCodes === null || dataCodes.length === 0) {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.data") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (!this.listTable[index].vertical || !this.listTable[index].horizontal) {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.vertical") + " " + this.$t("labels.framesetting.horizontal") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
          }
          return hasErrros;
        case FrameTypesValue.SVGTS:
          for (let index = 0; index < this.listTable.length; index++) {
            const stationId = this.listTable[index].stationname;
            const device = this.listTable[index].device;
            const dataCodes = this.listTable[index].dataCodes;
            if (stationId === undefined || stationId === null || stationId === '') {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.station") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
            if (!this.listTable[index].vertical || !this.listTable[index].horizontal) {
              hasErrros = true;
              this.commonErrorVue(this.$t("labels.framesetting.vertical") + " " + this.$t("labels.framesetting.horizontal") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
              return true;
            }
          }
          return hasErrros;
        case 'LR':
          hasErrros = this.validateChartCommon();
          return hasErrros;
        default:
          return hasErrros;
      }
    },
    isBlank(value) {
      // case undefined
      if (!value) {
        return true;
      }
      const str = value.toString().trim();
      return str.length > 0 ? false : true;
    },
    setHeaderAndCompByType(typeFrameCode) {
      if (typeFrameCode === "LS") {
        this.modurn = "LS";
        this.modurnComponent = "StackedLine";
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          // {text: 'labels.framesetting.channel', value: 'channel', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.legendName', value: 'legendName', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      }
      if (typeFrameCode === "LR") {
        this.modurn = "LR";
        this.modurnComponent = "LineRace";
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.legendName', value: 'legendName', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      } else if (typeFrameCode === "MAC") {
        this.modurn = "MAC";
        this.modurnComponent = "EChartMultiY";
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.legendName', value: 'legendName', align: 'center', sortable: false},
          {text: 'Line', value: 'line', align: 'center', sortable: false},
          {text: 'Bar', value: 'bar', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];

      } else if (typeFrameCode === "GLS") {
        this.modurn = "GLS";
        this.modurnComponent = "EchartRainFall";
        this.resizeComponent('Chart', 650, 6, 7, 5, 0)
        this.resizeComponent('toolbox', 420, 4, 5, 0, 0)
        this.resizeComponent('table', 420, 3, 12, 0, 6)
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.legendName', value: 'legendName', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color1', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color2', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'display3', align: 'center', sortable: false},
          {text: 'labels.framesetting.warning', value: 'color3', align: 'center', sortable: false},
          {text: 'Chart1', value: 'chart1', align: 'center', sortable: false},
          {text: 'Chart2', value: 'chart2', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      } else if (typeFrameCode === "SC") {
        this.modurn = "SC";
        this.modurnComponent = "ScatterChart";
        let realh = this.calFrameH(710);
        let realHeight = this.calFrameHeight(realh);
        this.resizeComponent('Chart', realHeight, realh, 7, 5, 0)
        this.resizeComponent('toolbox', realHeight, realh, 5, 0, 0)
        this.resizeComponent('table', 420, 3, 12, 0, realh)
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          // {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          // {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.color', value: 'color', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      } else if (typeFrameCode === FrameTypesValue.SVG) {
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.pointer', value: 'pointer', align: 'center', sortable: false},
          {text: 'labels.framesetting.colorPointer', value: 'colorPointer', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.vertical', value: 'vertical', align: 'center', sortable: false},
          {text: 'labels.framesetting.horizontal', value: 'horizontal', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ]
      } else if (typeFrameCode === FrameTypesValue.TableChart) {
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.headerName', value: 'text', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'isDisplay', align: 'center', sortable: false},
        ]
      } else if (typeFrameCode === FrameTypesValue.SVGTS) {
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.view', value: 'view', align: 'center', sortable: false},
          {text: 'labels.framesetting.colorPointer', value: 'colorPointer', align: 'center', sortable: false},
          {text: 'labels.framesetting.vertical', value: 'vertical', align: 'center', sortable: false},
          {text: 'labels.framesetting.horizontal', value: 'horizontal', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ]
      } else if (typeFrameCode === "GC") {
        this.checkIP = false
        // Task #215563
        this.stationflg = false
        // Task #215563
        this.flgTable = false
        this.deviceflg = false
        this.modurn = "GC";
        this.modurnComponent = "ChartWeather";
        this.resizeComponent('Chart', 320, 3, 14, 0, 0)
        this.resizeComponent('toolbox', 420, 3, 5, 0, 3)
        this.resizeComponent('table', 420, 3, 7, 5, 3)
        this.headers = [
          {text: '#', align: 'center', sortable: false, value: 'order', width: "50px"},
          {text: 'labels.framesetting.station', value: 'stationname', align: 'center', sortable: false},
          {text: 'labels.framesetting.device', value: 'device', align: 'center', sortable: false},
          {text: 'labels.framesetting.data', value: 'data', align: 'center', sortable: false},
          {text: 'labels.framesetting.displayName', value: 'legendName', align: 'center', sortable: false},
          {text: 'genba.table.action', value: 'actions', align: 'center', sortable: false},
        ];
      }
    },
    InitDataTableChart() {
      //
      var coeff = 1000 * 60 * 10;
      let base = +new Date(Math.round(Date.now()/ coeff) * coeff - 7 * 24 * 60 * 60 * 1000)
      // let oneDay = 24 * 3600 * 1000;
      let tenMin = 600 * 1000;
      let date = [];
      let data = [Math.random() * 10];
      for (let i = 0; i < 1008; i++) {
        var now = new Date((base += tenMin));

        var mm = now.getMonth() + 1; // getMonth() is zero-based
        var dd = now.getDate();
        //
        var hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
        var minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
        var ss = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
        //
        var dateStr = [now.getFullYear(),
          (mm > 9 ? '' : '0') + mm,
          (dd > 9 ? '' : '0') + dd
        ].join('/');
        var timeStr = [hour, minutes].join(':')
        date.push([dateStr, timeStr].join(' '));
        data.push((Math.random() - 0.4) * 10 + data[data.length - 1]);
      }
      //
      var tempItem = this.initValueOneItem();
      tempItem.index = this.listTable.length;
      tempItem.order = this.listTable.length + 1;

      //
      var objXAxis = {}
      var objYAxis = {}
      var objSeries = {}
      if (this.modurn === "LS") {
        objSeries = {
          index: this.listTable.length,
          name: 'data ' + Number(this.listTable.length),
          type: 'line',
          color: '#5470c6',
          data: data,
          smooth: true,
          connectNulls: true
        }
        this.legend.data = ['data ' + Number(this.listTable.length) + 1]
        this.xAxis = [...date]
        this.series = [objSeries]
        this.listTable = [tempItem]
        return;
      } else if (this.modurn === "GC") {
        tempItem.Itemdevices = this.ItemdevicesAll
        tempItem.stationname = this.selectedStation
        this.listTable = [tempItem];
        return;
      } else if (this.modurn === "GLS") {
        tempItem.posChart = "chart1";
        objXAxis = {
          gridIndex: this.listTable.length,
          type: 'category',
          boundaryGap: false,
          axisLine: {onZero: true},
          data: date,
          position: Number(this.listTable.length) > 0 ? 'top' : 'bottom'
        };
        objYAxis = {
          index: this.listTable.length,
          gridIndex: this.listTable.length,
          name: '',
          type: 'value',
          inverse: Number(this.listTable.length) > 0 ? true : false
        };
        objSeries = {
          index: this.listTable.length,
          name: '',
          type: 'line',
          color: '#5470c6',
          xAxisIndex: Number(this.listTable.length) === 1 ? 1 : 0,
          yAxisIndex: Number(this.listTable.length) === 1 ? 1 : 0,
          symbolSize: 8,
          data: data,
          connectNulls: true
        };
        this.legend.data = ['data ' + Number(this.listTable.length) + 1]
        this.xAxis = [objXAxis]
        this.yAxis = [objYAxis]
        this.series = [objSeries]
        this.listTable = [tempItem]
        return;
      } else if (this.modurn === "SC") {
        tempItem.fromdate = ''
        tempItem.todate = ''
        tempItem.menufrom = false
        tempItem.menuto = false;
        tempItem.stationList = this.stationList;
        // var dataSc = [];
        // for (let index = 0; index < 36; index++) {
        //   // const pointValue = Math.floor(Math.random() * 100);
        //   dataSc.push([Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)])
        // }
        // objSeries = {
        //   index: this.listTable.length,
        //   name: 'data ' + Number(this.listTable.length),
        //   type: 'scatter',
        //   // emphasis: {
        //   //   focus: 'series'
        //   // },
        //   color: "#5470c6",
        //   data: dataSc,
        //   markPoint: {
        //     data: [
        //       {type: 'max', name: 'Max'},
        //       {type: 'min', name: 'Min'}
        //     ]
        //   },

        // }
        // this.legend.data = ['data ' + Number(this.listTable.length) + 1]
        // this.series = [objSeries]
        this.listTable = [tempItem];
        return;
      } else if (this.modurn === "MAC") {
        tempItem.selected = 'line'
        objYAxis = {
          index: this.listTable.length,
          type: "value",
          name: "",
          position: Number(this.listTable.length) > 0 ? "right" : "left",
          offset: Number(this.listTable.length) > 1 ? 50 : 0,
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#5470c6',
            },
          },
          axisLabel: {
            formatter: "{value}",
          },
        };
        objSeries = {
          index: this.listTable.length,
          name: '',
          type: 'line',
          yAxisIndex: Number(this.listTable.length),
          color: '#5470c6',
          data: data,
          connectNulls: true
        };
        this.xAxis = [...date]
        this.yAxis = [objYAxis]
        this.series = [objSeries]
        this.listTable = [tempItem]
        return;
      } else if (this.modurn === "LR") {
        objSeries = {
          index: this.listTable.length,
          name: 'data ' + Number(this.listTable.length),
          type: 'line',
          showSymbol: false,
          color: '#5470c6',
          smooth: true,
          data: data,
          connectNulls: true
        }
        this.legend.data = ['data ' + Number(this.listTable.length) + 1]
        this.series = [objSeries]
        this.listTable = [tempItem];
        return;
      }
      this.xAxis = [...date]
      this.legend.data = ['data ' + Number(this.listTable.length) + 1]
      this.series = [obj]
      this.listTable = [tempItem]
    },
    initValueOneItem() {
      var tempItem = {}
      tempItem.stationname = null
      tempItem.device = null;
      tempItem.tsStation = null;
      tempItem.orgNo = null;
      tempItem.channelNo = null;
      tempItem.data = null;
      tempItem.legendName = null;
      tempItem.hasWarning = false;
      tempItem.color = "#5470c6";
      tempItem.display1 = false;
      tempItem.color1 = '#3ba272';
      tempItem.display2 = false;
      tempItem.color2 = '#fac858';
      tempItem.display3 = false;
      tempItem.color3 = '#ee6666';
      tempItem.Itemdevices = [];
      tempItem.ItemData = [];
      tempItem.ItemOrgNo = [];
      tempItem.ItemTsStations = [];
      tempItem.ItemChannelList = [];
      tempItem.dataName = null;
      tempItem.unit = null;
      tempItem.icon = null;
      tempItem.channelNoLst = [];
      return tempItem;
    },
    uploadFile() {
      this.modalUpload = true;
      EventBus.$emit('EmitOpenUploadSVGModal', this.modalUpload)
    },
    handleUploadSVGFile($event) {
      this.$refs.chartComponent[0].handleChangeSVGChart($event.content);
      // this.svgContent = $event.content;
      this.fileName = $event.fileName;
    },
    onChangeView(item) {
      this.$refs.chartComponent[0].setHideShowPointer(item)
    },
    // Handle for frame table
    onChangeHeader(headers) {
      this.listHeaderFrameTable = headers;
    },
    onDragAndDrop() {
      let index = 1;
      this.listHeaderFrameTable.forEach(element => {
        if(element.index) {
          element.index = index++
        }
      });
      this.$refs.chartComponent[0].onChangeHeader(this.listHeaderFrameTable);
    },
    onUpdateTableHeader() {
      this.$refs.chartComponent[0].onChangeHeader(this.listHeaderFrameTable);
    },
    onChartCreated() {
      if (this.frametypeChoice !== FrameTypesValue.TableChart) {
        return;
      }
      if (this.isTableTs) {
        this.$refs.chartComponent[0].stations = [];
        this.$refs.chartComponent[0].stations.push(this.selectedStation);
        this.$refs.chartComponent[0].channelNo = this.editedItem?.channelNo;
      } else {
        this.$refs.chartComponent[0].stations = this.listSelectedStation;
      }
      // this.$refs.chartComponent[0].limitTime = this.editedItem?.day;
      this.$refs.chartComponent[0].exportType = this.editedItem?.exportType;
      this.$refs.chartComponent[0].autoUpdateTime = this.editedItem?.autoUpdateTime;
      this.$refs.chartComponent[0].getTableChartData();
    },
     // Handle for frame table
    handleClickDevice(item) {
      if(this.frametypeChoice === FrameTypesValue.SVG) {
        const isExistDevice = this.selectedDeviceList.find(x => x.index === item.index);
        if (isExistDevice) {
          this.selectedDeviceList[item.index].deviceCode = 0;
        }
        this.handleGetCbbDevice(item, item.stationname);
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.label-black {
  color: black !important;
}

@media (max-width: 540px) {

  .title-iphone {
    font-size: 20px !important;
  }
  .button-iphone {
    top: -35px !important;
    right: -20px !important;
  }
}
::v-deep{
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.75rem !important;
    height: 26px;
    padding: 0px 6px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
    max-width: 8rem !important;
    min-width: 4rem !important;
  }
  .v-data-footer__pagination {
    margin-left: 4.55rem !important;
  }
  .v-data-table>.v-data-table__wrapper>table {
    width: 1300px !important;
  }
  .v-select__selection {
    max-width: 90%;
    font-size: 1rem !important;
  }
  .card[data-v-c0f4d8c8]{
      margin-top: 11rem !important;
    }
  .card[data-v-0b3bedcf] {
    margin-top: 23rem !important;
  }
  .card[data-v-881a98f8] {
    margin-top: 11rem !important;
  }
  .card[data-v-0b3bedcf] {
    margin-top: 25rem !important;
  }
  .card[data-v-f553056c]{
    margin-top: 41rem !important;
  }
  .card[data-v-2761dcf9]{
    margin-top: 17rem !important;
  }
  .card[data-v-70dc55d6]{
    margin-top: 8rem !important;
  }
  .table-SC {
    margin-top: 44rem !important;
  }
  .table-TC {
    margin-top: 40rem !important;
  }
  .table-Frame {
    margin-top: 12rem !important;
  }
  .card[data-v-f1e9542e]{
    margin-top: 36rem !important;
  }
  .frameName-GC {
    margin-left: -1rem !important;
  }
  .inputName-GC {
    margin-left: -1rem !important;
    width: 100% !important;
  }
  .title-container {
    margin-top: 2rem !important;
  }
  .button-iphone {
    margin-top: 4rem !important;
  }
  .updateFrame-info {
    margin-top: 23rem !important;
  }
    .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr:nth-child(2) > th{
    top: 0px !important;
  }
}

@media (min-width: 501px) and (max-width: 768px){
  .card[data-v-c0f4d8c8]{
      margin-top: 11rem !important;
    }
    .card[data-v-0b3bedcf] {
      margin-top: 23rem !important;
    }
    .card[data-v-881a98f8] {
      margin-top: 11rem !important;
    }
    .card[data-v-0b3bedcf] {
      margin-top: 25rem !important;
    }
    .card[data-v-f553056c]{
      margin-top: 41rem !important;
    }
    .card[data-v-2761dcf9]{
      margin-top: 17rem !important;
    }
    .card[data-v-70dc55d6]{
      margin-top: 8rem !important;
    }
    .table-SC {
      margin-top: 44rem !important;
    }
    .table-TC {
      margin-top: 40rem !important;
    }
    .table-Frame {
      margin-top: 12rem !important;
    }
    .card[data-v-f1e9542e]{
      margin-top: 36rem !important;
    }
    .frameName-GC {
      margin-left: -1rem !important;
    }
    .inputName-GC {
      margin-left: -1rem !important;
      width: 100% !important;
    }
    .updateFrame-info {
    margin-top: 17rem !important;
  }

}


  @media (min-width: 768px) and (max-width: 1400px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
      max-width: 8rem !important;
      min-width: 4rem !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
    .card[data-v-c0f4d8c8]{
      margin-top: 11rem !important;
    }
    .card[data-v-0b3bedcf] {
      margin-top: 23rem !important;
    }
    .card[data-v-881a98f8] {
      margin-top: 11rem !important;
    }
    .card[data-v-0b3bedcf] {
      margin-top: 25rem !important;
    }
    .card[data-v-f553056c]{
      margin-top: 41rem !important;
    }
    .card[data-v-2761dcf9]{
      margin-top: 17rem !important;   // Screen sharing chart
    }
    .card[data-v-70dc55d6]{
      margin-top: 8rem !important;
    }
    .table-SC {
      margin-top: 44rem !important;
    }
    .table-TC {
      margin-top: 40rem !important;
    }
    .table-Frame {
      margin-top: 12rem !important;
    }
    .card[data-v-f1e9542e]{
      margin-top: 36rem !important;
    }
    .frameName-GC {
      margin-left: -1rem !important;
    }
    .inputName-GC {
      margin-left: -1rem !important;
      width: 100% !important;
    }
    .updateFrame-info {
      margin-top: 17rem !important;
  }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
