import Vue from 'vue';
import swal from 'sweetalert2'
import XLSX from "xlsx";
import moment from "moment";
import $ from 'jquery';


Vue.mixin({
  data() {
    return {
      glbPageIsLoading: false,
      requiredRule(value, label) {
        const errorMsg = [this.$t(label) + this.ERROR_CODES['IS_REQUIRED']];
        if (!value ||  value?.toString()?.trim().length === 0 ){
            return errorMsg
        } else {
            return [];
        }
      },
      ERROR_CODES: undefined
    }
  },
  methods: {
    commonNotifyVue(message, type) {
      this.$notify({
        message: message,
        type: type // info | warning | danger | success | primary
      });
    },
    commonConfirmVue(message, callbackYes, callbackNo) {
      swal({
        title: '',
        html: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill mr-2',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$t('commonPopup.yes'),
        cancelButtonText: this.$t('commonPopup.cancel'),
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: true,
        allowEnterKey: false,
        focusConfirm: true,
        onOpen: () => swal.getConfirmButton().focus()
      }).then((confirmed) => {
        if (confirmed) {
          callbackYes();
        }
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          callbackNo();
        }
      }).catch(swal.noop);
    },
    commonConfirmOKVue(message, callbackYes, callbackNo) {
      swal({
        title: '',
        html: message,
        type: 'success',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill mr-2',
        confirmButtonText: 'OK',
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then((confirmed) => {
        if (confirmed) {
          callbackYes();
        }
      }, (dismiss) => {
        if (dismiss === 'close') {
          callbackNo();
        }
      }).catch(swal.noop);
    },
    commonConfirmInputVue(message, callbackYes, callbackNo, a = 0, x = '', y = '', name = '') {
      let kaka = '';
      if (a === 1) {
        if (name !== '') {
          kaka = 'タイムカード' + x + "-" + y + "(" + name + ")";
        } else {
          kaka = 'タイムカード' + x + "-" + y;
        }
      }

      swal({
        title: message,
        html: `<div class="form-group pt-3 pb-3"><input id="glbCommonSwalInputConfirm1991" type="text" class="form-control" value="` + kaka + `"/></div>`,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill mr-2',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then((confirmed) => {
        if (confirmed) {

          let dataInput = document.getElementById("glbCommonSwalInputConfirm1991").value;
          callbackYes(dataInput);

        }
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          callbackNo();
        }
      }).catch(swal.noop);
    },
    commonConfirmPasswordVue(message, callbackYes, callbackNo) {
      swal({
        title: message,
        html: `<div class="form-group pt-3 pb-3"><input id="glbCommonSwalPasswordConfirm1991" type="password" class="form-control" /></div>`,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill mr-2',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then((confirmed) => {
        if (confirmed) {
          let dataPassword = document.getElementById("glbCommonSwalPasswordConfirm1991").value;
          callbackYes(dataPassword);
        }
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          callbackNo();
        }
      }).catch(swal.noop);
    },
    commonErrorVue(message) {
      swal({
        title: '',
        html: message,
        type: 'error',
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'OK',
        buttonsStyling: false,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true
      }).catch(swal.noop);
    },
    commonInfoVue(message) {
      swal({
        title: '',
        html: message,
        type: 'success',
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'OK',
        buttonsStyling: false,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true
      }).catch(swal.noop);
    },
    commonWarningVue(message) {
      swal({
        title: '',
        html: message,
        type: 'warning',
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'OK',
        buttonsStyling: false,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true
      }).catch(swal.noop);
    },
    commonReadFileCSV(e, arrayData) {
      let dataFileReaderCSV = [];

      // const scope = this;
      let promiseReader = new Promise((resolve) => {

        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }

        let file = e.target.files[0];

        let extension = file.name.split('.').pop().toLowerCase();

        Object.size = function (obj) {
          let size = 0, key;
          for (key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) size++;
          }
          return size;
        };

        if (extension === 'csv') {
          let reader = new FileReader();

          reader.onload = function (e) {
            let data = e.target.result;
            if (data !== null && data !== undefined && data.trim() !== '') {
              let arrayAll = data.split('\n');
              for (let k = 0; k < arrayAll.length; k++) {
                let strRowOne = arrayAll[k];
                if (strRowOne == null || strRowOne.trim().length === 1 || strRowOne.trim().length === 0) {
                  continue;
                }

                strRowOne = strRowOne.replace(/[\r]/g, '').replace(/[\n]/g, '').replace(/"/g, '');
                let oneArr = strRowOne.split(',');
                if (oneArr.length > 0) {
                  dataFileReaderCSV.push(oneArr);
                }
              }
            }
            resolve('complete');
          };
          reader.readAsText(file, 'Shift_JIS');
        } else {
          console.log('[commonReadFileCSV]: not CSV !');
        }
      });

      promiseReader.then(() => {
        arrayData(dataFileReaderCSV);
      });
    },
    commonExportFileCSV(fileName, arrayObj) {
      if (arrayObj.length > 0) {
        let arrayDataObjConvert = [];

        for (let k = 0; k < arrayObj.length; k++) {
          let objOne = arrayObj[k];

          let rowTitleName = "";
          let rowTitle = "";
          let rowData = "";

          for (let [key, value] of Object.entries(objOne)) {
            if (value === true) {
              value = 1;
            } else if (value === false) {
              value = 0;
            } else if (value === null) {
              value = "";
            } else if (isNaN(value) === true) {
              value = '"' + value + '"';
            }

            rowData += value + ",";
            rowTitle += key + ",";
          }

          if (k === 0 && arrayObj[0] === null) {
            arrayDataObjConvert.push(rowTitle);
          } else if (k === 0 && arrayObj[0] !== null) {
            arrayDataObjConvert.push(rowTitleName);
          }
          arrayDataObjConvert.push(rowData.replace(/["]/g, "").replace(/[\r]/g, '').replace(/[\n]/g, ''));
        }

        let csvFile = arrayDataObjConvert.slice(1).join('\n').replace(/(^\[)|(]$)/mg, '');
        // let convertCsvDate = this.convertToShiftJIS(csvFile);
        if (fileName === null || fileName.trim() === '') {
          fileName = "export_data";
        }
        let filename = fileName + '.csv';
        let blob = new Blob(["\uFEFF" + csvFile], {type: 'text/csv;charset=utf-8;'});

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute('charset', 'text/csv;charset=utf-8;');
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    },
    // convertToShiftJIS(target) {
    //   const unicode_arr = Encoding.stringToCode(target);
    //   const shift_js_arr = Encoding.convert(unicode_arr, {
    //     to: 'UTF-8',
    //     from: 'UNICODE'
    //   });
    //
    //   return new Uint8Array(shift_js_arr);
    // },
    commonReadFileExcel(event, result) {
      let dataArray = [];

      let promiseReader = new Promise( (resolve) => {
        let files = event.target.files, f = files[0];
        let reader = new FileReader();
        reader.onload = function (event) {
          let data = new Uint8Array(event.target.result);
          let workbook = XLSX.read(data, {type: 'array'});
          let sheetName = workbook.SheetNames[0]
          /* DO SOMETHING WITH workbook HERE */
          let worksheet = workbook.Sheets[sheetName];
          dataArray = XLSX.utils.sheet_to_json(worksheet);
          resolve('complete');
        };
        reader.readAsArrayBuffer(f);
      });

      promiseReader.then(() => {
        result(dataArray);
      });
    },
    commonArrGroupByKey(key_group, arrObj) {
      let arrayGroupFinal = [];

      if (key_group === null || key_group === undefined || arrObj === null || arrObj === undefined || arrObj.length === 0) {
        arrayGroupFinal = [];
      } else {
        let groups = {};
        for (let i = 0; i < arrObj.length; i++) {
          let groupIdBy = arrObj[i][key_group];

          if (!groups[groupIdBy]) {
            groups[groupIdBy] = [];
          }

          groups[groupIdBy].push(arrObj[i]);
        }

        arrayGroupFinal = [];
        for (let keygroupIdBy in groups) {
          let objFinal = {};

          objFinal[key_group] = keygroupIdBy;
          objFinal['data'] = groups[keygroupIdBy];

          arrayGroupFinal.push(objFinal);
        }

        arrayGroupFinal.sort(function (a, b) {
          return (b.groupOrderById) - (a.groupOrderById);
        });
      }

      return arrayGroupFinal;
    },
    commonFormatNumberToHHMMSS(sec_num) {
      sec_num = parseInt(sec_num);

      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ':' + minutes + ':' + seconds;
    },
    commonFormatStrToDate(str_date, opt) {
      let str = '';
      if (str_date !== undefined && str_date !== "") {
        if (opt === 1) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
        } else if (opt === 2) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
        } else if (opt === 3) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm').format('HH:mm');
        } else if (opt === 4) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm').format('HH');
        } else if (opt === 5) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm').format('mm');
        } else if (opt === 6) {
          str = moment(str_date).format("YYYY/MM/DD");
        } else if (opt === 7) {
          str = moment(str_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        }
      }

      return str;
    },
    commonIfNullFormatToStr(val) {
      let str = '';
      if (val !== null && val !== undefined) {
        str = val.trim();
        str = str.replace(/[\r]/g, '').replace(/[\n]/g, '').replace(/"/g, '');
      }

      return str;
    },
    // import error code
    async getErrorCode(fileName) {
      const currentLang = this.$i18n.locale
      await import(`@/utils/errors/${fileName}/${currentLang}.js`)
          .then((DATA) => {
              this.ERROR_CODES = DATA.ERROR_CODE
          })
          .catch(async () => {
              await import(`@/utils/errors/frames/${currentLang}.js`)
                  .then((DATA) => {
                      this.ERROR_CODES = DATA.ERROR_CODE
                  })
          })
    },
    handleCommonDialog(value) {
      if (value) {
        $('#main-header').css("pointer-events","none");
      } else {
        $('#main-header').css("pointer-events","");
      }
    }
  },
});
