<template>
  <v-dialog v-model="showDialog" max-width="1200px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5 label-black">{{ title }}</span>
        <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container class="pt-0 pb-0">
            <div class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                                    <h6 class="input-group-addon mb-1 label-black">{{
                                        $t("exportData.time")
                                      }}&nbsp;</h6>
                                    <h5 class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-12">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-12">
                          <v-select v-on:change="onChangeTimeOptions" :items="timeOptions"
                                    v-model="timeTypeSelected"
                                    :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'"
                                    item-value="value" dense outlined>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                    <h6 v-if="timeTypeSelected === 1" class="input-group-addon mb-1 label-black">{{ $t("exportData.period") }}&nbsp;</h6>
                    <h5 v-if="timeTypeSelected === 1" class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                    <h6 v-if="timeTypeSelected !== 1" class="input-group-addon mb-1 label-black" style="min-width: 9em;">{{$t("exportData.duration")}}</h6>
                </div>
                <div class="input-group-append">
                  <div v-if="timeTypeSelected === 1" class="row">
                    <div class="col-xl-6 col-lg-6 col-12">
                      <div class="row">
                        <div class="col-xl-11 col-lg-11 col-11">
                          <v-menu ref="menu" v-model="menuFrom"
                                  :close-on-content-click="false" transition="scale-transition"
                                  offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :readonly="true" v-model="fromFormatted"
                                            :rules="fromDateRules" append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details="auto" outlined
                                            dense @click:append="menuFrom = true"
                                            :disabled="checkDetail"
                                            @blur="dateFrom = parseDate(fromFormatted)">
                              </v-text-field>
                            </template>
                            <v-date-picker :disabled="checkDetail"
                                           :allowed-dates="disableStartDates" v-model="dateFrom"
                                           no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menuFrom = false">
                                {{ $t("exportData.ok") }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </div>
                        <div class="row sign">
                          <div style="padding-left: 0px !important;text-align:center;margin-top: 5px;"
                             class="col-xl-1 col-lg-1 col-1">
                            <b style="font-size: 20px;">〜</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-12">
                      <div class="row justify-content-start">
                        <div class="col-11">
                          <v-menu ref="menu" v-model="menuTo" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :readonly="true" v-model="toFormatted"
                                            :disabled="checkDetail" :rules="toDateRules"
                                            append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                            hide-details="auto" outlined dense
                                            @click:append="menuTo = true"
                                            @blur="dateTo = parseDate(toFormatted)">
                              </v-text-field>
                            </template>
                            <v-date-picker :disabled="checkDetail"
                                           :allowed-dates="disableEndDates" v-model="dateTo" no-title
                                           scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menuTo = false">
                                {{ $t("exportData.ok") }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-xl-12 col-lg-12 col-12">
                      <v-select v-model="durationSelected" :items="durationOptions"
                                :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'"
                                item-value="value" dense outlined>
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                                    <h6 class="input-group-addon mb-1 label-black">{{$t("exportData.frame")}}&nbsp;</h6>
                                    <h5 class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <v-select v-on:change="onChangeFrameOptions" v-model="frameSelected"
                            :rules="true?frameIdRules:[]" :items="frameList" dense outlined>
                  </v-select>
                </div>

              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                                    <h6 class="input-group-addon mb-1 label-black" style="min-width: 9em;">{{$t("exportData.outputInterval")}}</h6>
                </div>
                <div class="input-group-append">
                  <v-select v-model="displayInterval" :items="displayIntervalList" :disabled="deviceCode === null || frameSelected == 0"
                            :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value"
                            dense outlined>
                  </v-select>
                </div>
              </div>
            </div>
            <!-- device code and station -->
            <div class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                    <h6 class="input-group-addon mb-1 label-black">{{$t("exportData.device")}}&nbsp;</h6>
                    <h5 v-if="!isChart(frameTypeSelected)" class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <v-select :disabled="frameSelected === 0" v-model="deviceCode" :items="deviceList"
                            :rules="!isChart(frameTypeSelected) ? deviceCodeRules : []"
                            @change="onChangeDeviceOptions"
                            :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value"
                            dense outlined>
                  </v-select>
                </div>

              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{$t("exportData.stationName")}}&nbsp;</h6>
                  <h5 v-if="!isChart(frameTypeSelected)" class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <v-autocomplete v-if="!isDevice1000" :disabled="deviceCode === null" :items="handleLabelItems(stationList)" chips
                                  :rules="!isChart(frameTypeSelected) ? stationRules : []"
                                  v-model="valueStation" multiple dense item-text="label" item-value="value"
                                  outlined deletable-chips>
                  </v-autocomplete>
                  <v-select v-else v-model="valueStationMainTS" :items="handleLabelItems(stationList)"
                        item-text="label" item-value="value" dense outlined @change="handleChangeStation(e)"
                        :rules="!isChart(frameTypeSelected) ? requiredRule(valueStationMainTS, 'exportData.stationName') : []"
                        :disabled="deviceCode === null">
                  </v-select>
                </div>
              </div>
            </div>

            <div v-if="isDevice1000" class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                    <h6 class="input-group-addon mb-1 label-black">{{$t("exportData.stationTs")}}&nbsp;</h6>
                    <h5 v-if="!isChart(frameTypeSelected)" class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <v-select :items="tsStationLst" v-model="tsStationSelected" @change="handleChangeTsStation(e)"
                        :item-text="($i18n.locale === 'en') ? 'name' : 'nameJp'" item-value="value" dense outlined
                        :rules="!isChart(frameTypeSelected) ? requiredRule(tsStationSelected, 'exportData.stationTs') : []"
                        :disabled="checkDetail || valueStationMainTS == null">
                  </v-select>
                </div>

              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                    <h6 class="input-group-addon mb-1 label-black">{{$t("exportData.orgNo")}}&nbsp;</h6>
                    <h5 v-if="!isChart(frameTypeSelected)" class="text-danger mb-0 h-text-danger" style="min-width: 9em;">*</h5>
                </div>
                <div class="input-group-append">
                  <v-select :items="ItemOrgNo" v-model="orgNoSelected" :disabled="checkDetail || tsStationSelected == null"
                              :rules="!isChart(frameTypeSelected) ? requiredRule(orgNoSelected, 'exportData.orgNo') : []"
                              dense :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'" item-value="orgNo" outlined deletable-chips>
                  </v-select>
                </div>
              </div>
            </div>
            <div v-if="graphCheck" class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.splitNumber")}}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <v-text-field type="number"
                      onKeyPress="if(this.value.length==5) return false;"
                      oninput="if(this.value < 1 || this.value.length === 0 ) this.value = 1;if(this.value > 99 ) this.value = 99;"
                      v-model="splitNumber" hide-details="auto" outlined dense>
                  </v-text-field>
                </div>
                <div class="col-xl-12 col-lg-12 col-12 splitNumberMoreDetail">
                    <span class="input-group-addon mb-1 labelInput label-black splitNumberMoreDetail">{{
                                    $t("exportData.splitNumberMoreDetail")
                    }}</span>
                </div>
              </div>
              <div v-if="this.isTableChartTs()" class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.min") }}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <v-select v-model="displayScaleMin" value="displayScaleMin" type="number"
                      suffix="mm" :items="displayScaleDropdown" dense outlined>
                  </v-select>
                </div>
              </div>
            </div>
            <div v-if="graphCheck && this.isTableChartTs()" class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.max") }}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <v-select v-model="displayScaleMax" value="displayScaleMax" type="number"
                      suffix="mm" :items="displayScaleDropdown" dense outlined>
                  </v-select>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.auxiliaryLine") }}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <v-select v-model="auxiliaryLine" suffix="mm" :items="auxiliaryLineDropdown"
                      dense outlined>
                  </v-select>
                </div>
              </div>
            </div>
            <div v-if="graphCheck" class="row m-1">
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.ngOption")}}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <div class="input-group input-group-sm">
                      <v-checkbox style="margin-top: 5px !important;" v-model="ngCheck">
                          <template v-slot:label>
                            <span class="radioLabel label-black">{{
                                      $t("exportData.ngText")
                              }}</span>
                          </template>
                      </v-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-12">
                <div class="input-group input-group-sm">
                  <h6 class="input-group-addon mb-1 label-black">{{ $t("exportData.thresholdOption")}}&nbsp;</h6>
                </div>
                <div class="input-group-append">
                  <div class="input-group input-group-sm">
                      <v-checkbox style="margin-top: 5px !important;" v-model="thresholdCheck">
                          <template v-slot:label>
                            <span class="radioLabel label-black">{{
                                  $t("exportData.thresholdLegendMsg")
                          }}</span>
                          </template>
                      </v-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-1">
              <div style="display: flex !important;"
                   class="col-xl-12 col-lg-12 col-12 justify-content-center">
                <v-checkbox v-model="csvCheck" color="primary" class="setting-export-checkBox"
                            hide-details>
                  <template v-slot:label>
                    <span class="checkboxLabel label-black">{{ $t("exportData.csv") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="excelCheck" label="Report" class="setting-export-checkBox"
                            color="primary" hide-details>
                  <template v-slot:label>
                    <span class="checkboxLabel label-black">{{ $t("exportData.report") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="graphCheck" label="Graph" class="setting-export-checkBox"
                            color="primary" hide-details>
                  <template v-slot:label>
                    <span class="checkboxLabel label-black">{{ $t("exportData.graph") }}</span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" text
               @click="handleSaveReportSetting(event)">
          {{ $t("labels.systemUserManager.save") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("exportData.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/eventBus.js'
import { mapActions } from 'vuex';
import {
  getCookieSiteUser, setCurrentPageCode
} from "@/utils/auth";
import $ from 'jquery';
import { getCbbStation } from "@/api/station";
import { ResponseCode, FrameTypesValue } from "@/constants/constants"

export default {
    name: "SettingExportModal",
    props: ['visible', 'titleModal', 'deviceTSList'],
    data() {
        return {
            deviceList: [],
            stationList: [],
            deviceCode: null,
            valid: false,
            loading: false,
            title: "",
            showDialog: false,
            checkDetail: false,
            editMode: false,
            showPassword: false,
            showIconEye: false,
            timeTypeSelected: 2,
            timeOptions: [
                { value: 1, labelEN: "Period", labelJP: "日付指定" },
                { value: 2, labelEN: "Duration", labelJP: "期間" },
            ],
            frameList: [],
            frameSelected: 0,
            frameTypeSelected: null,
            displayIntervalList: [
                { value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
                { value: 60, labelEN: "1 hours", labelJP: "1時間" },
                { value: 120, labelEN: "2 hours", labelJP: "2時間" },
                { value: 180, labelEN: "3 hours", labelJP: "3時間" },
                { value: 360, labelEN: "6 hours", labelJP: "6時間" },
                { value: 720, labelEN: "12 hours", labelJP: "12時間" },
                { value: 1440, labelEN: "24 hours", labelJP: "24時間" },
            ],

            durationOptions: [
                { value: "1h", labelEN: "1 hour", labelJP: "1時間" },
                { value: "2h", labelEN: "2 hours", labelJP: "2時間" },
                { value: "3h", labelEN: "3 hours", labelJP: "3時間" },
                { value: "4h", labelEN: "4 hours", labelJP: "4時間" },
                { value: "6h", labelEN: "6 hours", labelJP: "6時間" },
                { value: "12h", labelEN: "12 hours", labelJP: "12時間" },
                { value: "1w", labelEN: "1 week", labelJP: "1週間" },
                { value: "1m", labelEN: "1 month", labelJP: "1ヶ月" },
                { value: "2m", labelEN: "2 months", labelJP: "2ヶ月" },
                { value: "3m", labelEN: "3 months", labelJP: "3ヶ月" },
                { value: "4m", labelEN: "4 months", labelJP: "4ヶ月" },
                { value: "6m", labelEN: "6 months", labelJP: "6ヶ月" },
                { value: "12m", labelEN: "12 months", labelJP: "12ヶ月" },

            ],
            displayInterval: 0,
            durationSelected: "1w",
            menuFrom: false,
            menuTo: false,
            dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fromFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            toFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            csvCheck: false,
            excelCheck: false,
            graphCheck: false,
            ngCheck: false,
            thresholdCheck: false,
            frameIdRules: [
                (value) => value !== 0 || this.$t("exportData.frame") + this.ERROR_CODES['IS_REQUIRED'], // null or empty check
            ],
            deviceCodeRules: [
                (value) => value !== null && value !== "0" || this.$t("exportData.device") + this.ERROR_CODES['IS_REQUIRED'], // null or empty check
            ],
            stationRules: [
                (value) => value.length > 0/*always have 1 values(eq "") in this arrays*/  || this.$t("exportData.stationName") + this.ERROR_CODES['IS_REQUIRED'], // null or empty check
            ],
            PAGE_CODE: 'CS_DS',
            valueStation: [],
            valueStationMainTS: null,
            tsStationLst: [],
            tsStationSelected: null,
            orgNoSelected: null,
            ItemOrgNo:[],
            requiredRule(value, label) {
              const errorMsg = [this.$t(label) + this.ERROR_CODES['IS_REQUIRED']];
              if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
                return errorMsg
              } else {
                return [];
              }
            },
            splitNumber: 5,
            frameSupportExport: [FrameTypesValue.LineStack,
            FrameTypesValue.MultipleYAxisMixLineBarChart,
            FrameTypesValue.GirdMultipleLineChart,
            FrameTypesValue.GaugeChart,
            FrameTypesValue.LineRace,
            FrameTypesValue.ScatterChart,
            FrameTypesValue.SVG,
            FrameTypesValue.TableChart],
            displayScaleMin: -10,
            displayScaleMax: 10,
            displayScaleDropdown:
                [
                    { text: '1000', value: 1000 },
                    { text: '900', value: 900 },
                    { text: '800', value: 800 },
                    { text: '700', value: 700 },
                    { text: '600', value: 600 },
                    { text: '500', value: 500 },
                    { text: '400', value: 400 },
                    { text: '300', value: 300 },
                    { text: '200', value: 200 },
                    { text: '150', value: 150 },
                    { text: '100', value: 100 },
                    { text: '50', value: 50 },
                    { text: '45', value: 45 },
                    { text: '40', value: 40 },
                    { text: '35', value: 35 },
                    { text: '30', value: 30 },
                    { text: '25', value: 25 },
                    { text: '20', value: 20 },
                    { text: '15', value: 15 },
                    { text: '10', value: 10 },
                    { text: '5', value: 5 },
                    { text: '4', value: 4 },
                    { text: '3', value: 3 },
                    { text: '2', value: 2 },
                    { text: '1', value: 1 },
                    { text: '0', value: 0 },
                    { text: '-1', value: -1 },
                    { text: '-2', value: -2 },
                    { text: '-3', value: -3 },
                    { text: '-4', value: -4 },
                    { text: '-5', value: -5 },
                    { text: '-10', value: -10 },
                    { text: '-15', value: -15 },
                    { text: '-20', value: -20 },
                    { text: '-25', value: -25 },
                    { text: '-30', value: -30 },
                    { text: '-35', value: -35 },
                    { text: '-40', value: -40 },
                    { text: '-45', value: -45 },
                    { text: '-50', value: -50 },
                    { text: '-100', value: -100 },
                    { text: '-150', value: -150 },
                    { text: '-200', value: -200 },
                    { text: '-300', value: -300 },
                    { text: '-400', value: -400 },
                    { text: '-500', value: -500 },
                    { text: '-600', value: -600 },
                    { text: '-700', value: -700 },
                    { text: '-800', value: -800 },
                    { text: '-900', value: -900 },
                    { text: '-1000', value: -1000 }],
            auxiliaryLine: 5,
            auxiliaryLineDropdown:
                [{ text: '±1', value: 1 },
                { text: '±2', value: 2 },
                { text: '±5', value: 5 },
                { text: '±10', value: 10 },
                { text: '±20', value: 20 },
                { text: '±50', value: 50 },
                { text: '±100', value: 100 }]

        }
    },
    computed: {
      isDevice1000(){
        return this.isDeviceTsOrSimilarTs(this.deviceCode);
      },
    },
  created() {
        setCurrentPageCode(this.PAGE_CODE);
        EventBus.$on('handleOpenExportSetting', (value) => {
            this.title = value.title;
            this.editedItem = value.editedItem;
            this.showDialog = value.flag;
            this.checkDetail = value.checkDetail;
            this.editMode = value.editMode;
            let now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.fromFormatted = this.formatDate(now);
            this.toFormatted = this.formatDate(now);
            this.deviceCode = null;
            this.valueStation = [];
            this.getValuesOptionsFrame();
            this.$refs.form?.resetValidation();
        });
    },
    watch: {
        "$i18n.locale": async function () {
            this.getErrorCode("report");
        },
        'dateFrom'(val) {
            this.fromFormatted = this.formatDate(this.dateFrom)
        },
        'dateTo'(val) {
            this.toFormatted = this.formatDate(this.dateTo)
        },
    },
    async beforeMount() {
        // import ERROR_CODES by language
        this.getErrorCode("report");
    },
    methods: {
        ...mapActions("frame", {
            getListFrameOfLayout: "getListFrameOfLayout"
        }),
        ...mapActions("report", {
            SaveReportSetting: "SaveReportSetting",
            LoadReportSetting: "LoadReportSetting"
        }),
        ...mapActions("device", {
            getDeviceByFrameId: "getDeviceByFrameId"
        }),
        ...mapActions("station", {
          getCbbStationTSByDevice: "getCbbStationTSByDevice",
          getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr"
        }),
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${year}/${month}/${day}`
        },
        parseDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        disableStartDates(val) {
            return val <= this.dateTo;
        },
        disableEndDates(val) {
            return val >= this.dateFrom;
        },
        onChangeFrameOptions(FrameId) {
            this.frameSelected = FrameId;
            var frameInfo = this.frameList.find(x => x.value === FrameId);
            this.frameTypeSelected = frameInfo?.type;
            this.handleGetCbbDevice();
            this.handleLoadReportSetting();
        },
        handleGetCbbDevice() {
            var frameInfo = this.frameList.find(x => x.value === this.frameSelected);
            this.getDeviceByFrameId(this.frameSelected)
                .then(response => {
                    if (response.code === 0) {
                        this.deviceList = response.data.map((item, index) => {
                            return item;
                        });
                        var emplyOpt = { label: "---", value: null, labelEN: "All device", labelJP: "すべての計器", valueInt: 0}
                        if(this.isChart(frameInfo?.type)) this.deviceList.unshift(emplyOpt);
                    } else {
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                    }
                })
                .catch(err => {
                  if(err.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                    this.isSubmitAction = false;
                });
        },
        handleLabelItems(list) {
            list.forEach((item) => {
                if (item.label.length > 20) {
                item.label = `${item.label.substring(0, 20)}...`;
                console.log(item.label, '[label]')
                }
            })
            return list
        },
        onChangeDeviceOptions(deviceSelected) {
            this.valueStation = [];
            if (!this.isDeviceTsOrSimilarTs(deviceSelected)) {
                this.displayIntervalList = [
                    { value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
                    { value: 60, labelEN: "1 hours", labelJP: "1時間" },
                    { value: 120, labelEN: "2 hours", labelJP: "2時間" },
                    { value: 180, labelEN: "3 hours", labelJP: "3時間" },
                    { value: 360, labelEN: "6 hours", labelJP: "6時間" },
                    { value: 720, labelEN: "12 hours", labelJP: "12時間" },
                    { value: 1440, labelEN: "24 hours", labelJP: "24時間" },
                ]
                this.displayInterval = 0
            } else {
                this.displayIntervalList = [
                {value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
                ];
                this.displayInterval = 0;
            }
            this.getCbbStationByDevice(deviceSelected);
        },
        getCbbStationByDevice(deviceSelected) {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            var request = {
                "siteId": siteID,
                "deviceCode": deviceSelected
            }
            getCbbStation(request).then(response => {
                if (response.data && response.data.code === ResponseCode.SUCCESS) {
                    this.stationList = response?.data?.data;
                } else {
                    this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                }
            })
                .catch(err => {
                  if(err.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                });

        },
        async handleLoadReportSetting() {
            this.loading = true;
            var FrameId = this.frameSelected;
            var frameInfo = this.frameList.find(x => x.value === this.frameSelected);
            this.frameTypeSelected = frameInfo?.type;
            this.LoadReportSetting(FrameId)
                .then(response => {
                    if (response.code === 0) {
                        if (response.data === null) {
                            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                            this.csvCheck = false;
                            this.excelCheck = false;
                            this.graphCheck = false;
                            this.timeTypeSelected = 2;
                            this.durationSelected = "1w";
                            this.displayInterval = 0;
                            this.loading = false;
                            this.deviceCode = null;
                            this.valueStation = [];
                            this.valueStationMainTS = null;
                            this.tsStationSelected = null;
                            this.orgNoSelected = null;
                            this.splitNumber = 5;
                            this.ngCheck = false;
                            this.thresholdCheck = false;
                            this.displayScaleMin = -10;
                            this.displayScaleMax = 10;
                            this.auxiliaryLine = 5;
                            this.$refs.form.resetValidation();
                        }
                        else {
                            var settingInfo = response.data;
                            this.dateFrom = settingInfo.fromDate !== undefined && settingInfo.fromDate !== null && settingInfo.fromDate !== "" ? settingInfo.fromDate.substr(0, 10) : null;
                            this.dateTo = settingInfo.toDate !== undefined && settingInfo.toDate !== null && settingInfo.toDate !== "" ? settingInfo.toDate.substr(0, 10) : null;
                            this.csvCheck = settingInfo.csv === 1 ? true : false;
                            this.excelCheck = settingInfo.excel === 1 ? true : false;
                            this.graphCheck = settingInfo.pdf === 1 ? true : false;
                            this.timeTypeSelected = settingInfo.typeTime;
                            this.durationSelected = settingInfo.duration;
                            if(settingInfo.deviceCode != null) {
                              this.onChangeDeviceOptions(settingInfo.deviceCode.toString());
                            }
                            this.displayInterval = settingInfo.outputInterval !== undefined && settingInfo.outputInterval !== null && settingInfo.outputInterval !== "" ? parseInt(settingInfo.outputInterval) : 0;
                            this.deviceCode = settingInfo.deviceCode != null ? settingInfo.deviceCode.toString() : null;

                            this.valueStation = settingInfo.stationLst !== null ? settingInfo.stationLst.split("/") : [];
                            this.valueStationMainTS = this.isDeviceTsOrSimilarTs(settingInfo.deviceCode) ? settingInfo.stationLst: null;
                            this.tsStationSelected = this.isDeviceTsOrSimilarTs(settingInfo.deviceCode) && settingInfo.sokGr !== null && settingInfo.sokNo !== null ? settingInfo.sokInfo : null;
                            this.orgNoSelected = settingInfo.orgNo;
                            if(this.isDeviceTsOrSimilarTs(settingInfo.deviceCode)){
                              this.handleChangeTsStation();
                              this.handleGetCbbStationTS(settingInfo.stationLst, settingInfo.deviceCode, null);
                            }

                            this.splitNumber = settingInfo.splitNumber != null ? settingInfo.splitNumber : 5;
                            this.ngCheck = settingInfo.ngCheck == 1 ? true: false;
                            this.thresholdCheck = settingInfo.thresholdCheck == 1 ? true: false;
                            this.displayScaleMin = settingInfo.minimumValueOfAxis != null ? settingInfo.minimumValueOfAxis : -10;
                            this.displayScaleMax = settingInfo.maximumValueOfAxis != null ? settingInfo.maximumValueOfAxis : 10;
                            this.auxiliaryLine = settingInfo.auxiliaryLine != null ? settingInfo.auxiliaryLine : 5;
                            this.loading = false;
                          }

                    } else {
                        this.loading = false;
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                    if(err.code != undefined)
                      this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                    this.isSubmitAction = false;

                });
        },
        handleSaveReportSetting() {
            this.$refs.form.validate()
            if (!this.$refs.form.validate()) {
                return;
            }
            if ((this.timeTypeSelected === 1) && (this.fromFormatted === null || this.dateFrom === null || this.fromFormatted === undefined || this.dateFrom === undefined)) {
                this.commonNotifyVue(this.ERROR_CODES['E_080_01'], 'warning');
                return;
            }

            if (this.frameSelected == 0){
                this.commonNotifyVue(this.ERROR_CODES['E_080_08'], 'warning');
                return;
            }
            var request = {
                FrameId: this.frameSelected,
                SiteId: getCookieSiteUser(),
                Csv: this.csvCheck === true ? 1 : 2,
                Excel: this.excelCheck === true ? 1 : 2,
                Pdf: this.graphCheck === true ? 1 : 2,
                TimeType: this.timeTypeSelected,
                StartDate: this.dateFrom,
                EndDate: this.dateTo,
                Duration: this.durationSelected,
                OutputInterval: this.displayInterval,
                DeviceCode: this.deviceCode,
                StationLst: this.valueStation,
                TsStationMain: this.valueStationMainTS,
                SokInfo: this.tsStationSelected,
                OrgNo: this.orgNoSelected,
                SplitNumber: this.splitNumber,
                NgCheck: this.ngCheck === true ? 1 : 2,
                ThresholdCheck : this.thresholdCheck === true ? 1 : 2,
                MinimumValueOfAxis: this.isTableChartTs() ? this.displayScaleMin : null,
                MaximumValueOfAxis: this.isTableChartTs() ? this.displayScaleMax : null,
                AuxiliaryLine: this.isTableChartTs() ? this.auxiliaryLine : null
            }
            this.SaveReportSetting(request)
                .then(response => {
                    if (response.code === 0) {
                        this.loading = false;
                        this.showDialog = false;
                        $('#main-header').css("pointer-events", "");
                        this.handleResetFormItem();
                        this.commonConfirmOKVue(this.ERROR_CODES['SAVE_OK']);
                    } else {
                        this.loading = false;
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                    if(err.code != undefined)
                      this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                    this.loading = false;
                    this.isSubmitAction = false;
                });

        },
        getValuesOptionsFrame() {
            var siteId = getCookieSiteUser();
            this.getListFrameOfLayout(siteId)
                .then(response => {
                    if (response.code === 0) {
                        this.frameList = response.data.map(x => {
                            return { value: x.id, text: x.name, type: x.type }
                        });
                        this.loading = false;

                    } else {
                        this.loading = false;
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                    if(err.code != undefined)
                      this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                    this.loading = false;
                    this.isSubmitAction = false;
                });
        },
        onChangeTimeOptions(timeType) {
            this.timeTypeSelected = timeType;
            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.durationSelected = "1w";
        },
        handleConfirmCloseModal() {
            this.commonConfirmVue(this.ERROR_CODES['CF_052_01_01'], () => {
                this.showDialog = false
                $('#main-header').css("pointer-events", "");
                this.handleResetFormItem();
            });
        },
        handleChangeStation(data){
          this.handleGetCbbStationTS(this.valueStationMainTS, this.deviceCode, null)
        },
        handleGetCbbStationTS(stationId, deviceCode, orgNo) {
          this.loading = true;
          let siteID = getCookieSiteUser();
          this.getCbbStationTSByDevice({DeviceCode: deviceCode, SiteId: siteID, StationId: stationId, OrgNo: orgNo})
            .then(response => {
              if (response.code === 0) {
                this.tsStationLst = response.data.map((i, index) => {
                  var dataI = {value: i.id, name: i.stationName, nameJp: i.stationName };
                  return dataI;
                });
                this.tsStationLst.unshift({value: 'all', name: 'All Points', nameJp: '全測点' })
                this.loading = false;
              } else {
                this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                this.loading = false;
              }
            })
            .catch(err => {
              if(err.code != undefined)
                this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
                this.loading = false;
            })
            .finally(final => {
              this.isSubmitAction = false;
              // this.loading = false;
            });

        },
        handleChangeTsStation(){
          // this.loading = true;
          let rq = {
            SiteId: getCookieSiteUser(),
            StationId: this.valueStationMainTS,
            DeviceCode: this.deviceCode,
            SokGrTs: this.tsStationSelected,
            IsViewDataSensor: true,
            FrameId: this.frameSelected
          }
          this.getCbbOrgInfoBySokGr(rq)
              .then(response => {
                if (response.code === 0) {
                  this.ItemOrgNo = response.data;
                  // this.loading = false;
                } else {
                  this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                  // this.loading = false;
                }
              })
              .catch(err => {
                if(err.code != undefined)
                  this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
                  // this.loading = false;
              })
              .finally(final => {
                this.isSubmitAction = false;
                // this.loading = false;
              });

          },
        handleResetFormItem() {
            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.csvCheck = false;
            this.excelCheck = false;
            this.graphCheck = false;
            this.timeTypeSelected = 2;
            this.durationSelected = "1w";
            this.frameSelected = 0;
            this.frameTypeSelected = null;
            this.displayInterval = 0;
            this.loading = false;
            this.deviceCode = null;
            this.valueStation = [];
            this.ngCheck = false;
            this.thresholdCheck = false;
            this.displayScaleMin = -10;
            this.displayScaleMax = 10;
            this.auxiliaryLine = 5;
            this.$refs.form.resetValidation();
        },
        isChart(typeFrame){
          return this.frameSupportExport.includes(typeFrame);
        },
        isTableChartTs() {
            const deviceLst = []
            this.deviceList.forEach(x => {
              if(x.value){
                deviceLst.push(Number(x.value))
              }
            });
            return this.deviceTSList !== null && this.deviceTSList.length > 0 && deviceLst.every(r => this.deviceTSList.includes(r));
        },
        isDeviceTsOrSimilarTs(deviceCode){
            return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(Number(deviceCode));
        },
    }
}
</script>

<style lang="scss" scoped>


.sign {
  margin-top: 3px;
}
.setting-export-checkBox {
    margin-right: 50px !important
  }
.v-card__actions {
    border-top: 1px solid #ebebeb;
}

.label-black {
    color: black !important;
}

.checkboxLabel {
    margin-top: 8px !important;
    font-weight: 100;
    color: black;
}
.radioLabel {
    margin-top: 8px !important;
    font-weight: 100;
    color: black;
}


::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  .v-card {
    margin-top: 2rem !important;
  }
  @media only screen and (min-width:768px) {
    .sign {
      margin-left: 2px !important;
    }
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait){
  .v-card {
    margin-top: 5rem !important;
  }
  .setting-export-checkBox {
    margin-right: 0px !important
  }
  .v-dialog > .v-card > .v-card__text {
    padding: 0 0 0 0 !important;
  }
}
  @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
    .v.card {
      margin-top: 4em !important
    }
  }
}
</style>
