<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("labels.systemUserManager.userId")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" outlined dense v-model="searchUserInfo.userId"></v-text-field>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("labels.systemUserManager.siteId")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" outlined dense v-model="searchUserInfo.siteId"></v-text-field>
            </div>
          </div>
        </div>

        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("labels.systemUserManager.userName")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" outlined dense v-model="searchUserInfo.username"></v-text-field>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("labels.systemUserManager.userType")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="optionsRoles" v-model="searchUserInfo.role" :disabled="checkDetail" outlined dense>
              </v-select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mr-3">
          <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetUserList">
            <v-icon right dark class="mr-1">
              fas fa-search
            </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
          <v-btn class="bg-secondary font-weight-bold" @click.prevent="handleClearSearchingCondition">
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{ $t("labels.clear") }}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2 card-body-ipad">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table
            :headers="headers" :items="usersList" sort-by="id" class="elevation-2 table-iphone"
            :footer-props="{
               'items-per-page-options': [15, 30, 50],
               'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
            }"
            mobile-breakpoint="0"
            hide-default-header fixed-header
          >
            <template slot="no-data">
              <div class="font-size-iphone">
                {{ $t("notification.noData") }}
              </div>
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th v-for="header in headers" :key="header.value" class="text-uppercase font-size-iphone" scope="col">
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:no-data>
              <span class="align-items-center">
                {{ $t('labels.systemUserManager.emptyTable') }}
              </span>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.userName.slice(0, 10) }}</span>
                  <span v-if="item.userName.length > 10"> {{ '...' }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.userName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" style="background-color: #3d76ad" @click="createItem"
                       v-if="ACTION === 'OPS'">
                  <v-icon color="white" size="20" class="mr-2 dark mt-0">
                    mdi-plus-box
                  </v-icon>
                  <span style="font-size: 20px">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.siteId`]="{ item }">
              <span v-if="item.userType === 1">{{ $t('labels.systemUserManager.allSite') }}</span>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ (item.siteList !== undefined && item.siteList !== null) ? item.siteList.join(",").slice(0, 20) : '' }} </span>
                  <span v-if="(item.siteList !== undefined && item.siteList !== null) ? item.siteList.join(',').length > 10 : false"> {{ '...' }}</span>
                </template>
                <span v-for="(site, index) in item.siteList" v-bind:key="index">{{ site }}, </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.userType`]="{ item }">
              <span v-if="item.userType === 1">{{ $t('labels.systemUserManager.roleOptions.admin') }}</span>
              <span v-if="item.userType === 2">{{ $t('labels.systemUserManager.roleOptions.manager') }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <font-awesome-icon v-if="item.status === 1" icon="fa-solid fa-circle-check" color="green" size="lg"/>
              <font-awesome-icon v-else icon="fa-solid fa-circle-xmark" color="red" size="lg"/>

            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <button class="m-1" @click="detailItem(item)">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="editItem(item)">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2" v-if="ACTION === 'OPS'">
                  </i>
                </button>
                <button class="m-1" @click="handleInactiveUser(item)"
                        v-if="item.userId !== loginUser && ACTION === 'OPS'">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <SystemUserModal @close="modelEdit = false"/>
  </div>
</template>

<script>
import SystemUserModal from "@/modals/SystemUser/SystemUserModal"
import {EventBus} from "@/utils/eventBus";
import {mapActions} from 'vuex'
import moment from "moment";
import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  getCookiePrivileges,
  getCookieUserType,
  getRememberKCloudID, setCurrentPageCode
} from "@/utils/auth";
import $ from 'jquery';

const i18nConfig = require('@/i18n.lang')
export default {
  name: "SystemUserManagement",
  components: {
    SystemUserModal,
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    },
  },
  async beforeMount() {
    this.handleGetComboboxUsers()
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {
    setTimeout(() => {
      this.handleGetUserList()
    }, 1000);

  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', align: 'center', sortable: true, value: 'id'},
        {text: this.$t('labels.systemUserManager.userId'), value: 'userId', align: 'left', sortable: true},
        {
          text: this.$t('labels.systemUserManager.userName'),
          value: 'userName',
          align: 'center',
          sortable: true,
          fixed: true
        },
        {text: this.$t('labels.systemUserManager.userType'), value: 'userType', align: 'left', sortable: true},
        {text: this.$t('labels.systemUserManager.siteId'), value: 'siteId', align: 'left', sortable: true},
        {text: this.$t('labels.systemUserManager.status'), value: 'status', sortable: false, align: 'center'},
        {text: this.$t('labels.systemUserManager.createdUser'), value: 'createBy', sortable: false, align: 'left'},
        {text: this.$t('labels.systemUserManager.createdDate'), value: 'createdDate', sortable: false, align: 'center'},
        {text: this.$t('labels.systemUserManager.updatedUser'), value: 'updateBy', sortable: false, align: 'left'},
        {text: this.$t('labels.systemUserManager.updatedDate'), value: 'updatedDate', sortable: false, align: 'center'},
        {text: this.$t('labels.systemUserManager.actions'), value: 'actions', sortable: false, align: 'left'},
      ];
      this.optionsStatus = [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ];
      this.optionsRoles = [
        {value: 1, text: this.$t('labels.systemUserManager.roleOptions.admin')},
        {value: 2, text: this.$t('labels.systemUserManager.roleOptions.manager')},
      ];
    }
  },
  data() {
    return {
      ERROR_CODE: '',
      PAGE_CODE: 'SA_UM',
      ACTION: 'OPS',
      currentLocale: this.$i18n.locale,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dialogx: false,
      selected1: null,
      checkDetail: false,
      selected: null,
      options: [
        {value: null, text: 'Please select an option'},
        {value: 'a', text: 'This is First option'},
        {value: 'b', text: 'Selected Option'},
        {value: {C: '3PO'}, text: 'This is an option with object value'},
        {value: 'd', text: 'This one is disabled'}
      ],
      optionsStatus: [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ],
      optionsRoles: [
        {value: 0, text: this.$t('labels.systemUserManager.roleOptions.all')},
        {value: 1, text: this.$t('labels.systemUserManager.roleOptions.admin')},
        {value: 2, text: this.$t('labels.systemUserManager.roleOptions.manager')},
      ],
      modelDetail: false,
      modelEdit: false,
      modelCreate: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: '#', align: 'center', sortable: true, value: 'id'},
        {text: 'labels.systemUserManager.userId', value: 'userId', align: 'left', sortable: true},
        {text: 'labels.systemUserManager.userName', value: 'userName', align: 'left', sortable: true},
        {text: 'labels.systemUserManager.userType', value: 'userType', align: 'left', sortable: true},
        {text: 'labels.systemUserManager.siteId', value: 'siteId', align: 'left', sortable: true},
        {text: 'labels.systemUserManager.status', value: 'status', sortable: false, align: 'center'},
        {text: 'labels.systemUserManager.createdUser', value: 'createBy', sortable: false, align: 'left'},
        {text: 'labels.systemUserManager.createdDate', value: 'createdDate', sortable: false, align: 'center'},
        {text: 'labels.systemUserManager.updatedUser', value: 'updateBy', sortable: false, align: 'left'},
        {text: 'labels.systemUserManager.updatedDate', value: 'updatedDate', sortable: false, align: 'center'},
        {text: 'labels.systemUserManager.actions', value: 'actions', sortable: false, align: 'left'},
      ],
      usersList: [],
      comboboxUser: [],
      editedIndex: -1,
      selectedUser: {
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: 1,
        siteId: 0,
        siteName: '',
        createdDate: '',
        updatedDate: '',
        device: [''],
        createdUser: '',
        createBy: '管理者',
        email: '',
        phoneNumber: '',
        address: '',
        updatedUser: '',
        updateBy: '管理者',
        userType: 0,
      },
      searchUserInfo: {
        userId: null,
        username: null,
        siteId: null,
        role: 0
      },
      currentLoginUserType: null,
      loginUser: '',
      loading: false
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.close()
    },
    '$i18n.locale': async function (){
      const currentLang = this.$i18n.locale
      await import(`@/utils/errors/users/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
    'searchUserInfo.userId': function () {
      // this.searchUserInfo.userId = this.searchUserInfo.userId.split(" ").join("")
    }
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    this.loginUser = getRememberKCloudID();
    this.currentLoginUserType = getCookieUserType();

    EventBus.$on('handleAddUpdateUserInfo', (value) => {
      console.log('[Emit][handleAddUpdateUserInfo]', value);
      this.handleGetComboboxUsers();
      this.handleGetUserList();
    });

    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
      })
    }

  },
  beforeDestroy() {
    EventBus.$off('handleAddUpdateUserInfo');
    EventBus.$off('handleOpenSystemUserEditModal');
  },
  methods: {
    ...mapActions("users", {
      getUserList: "getUserList",
      deleteUser: "deleteUser"
    }),
    handleGetUserList() {
      this.loading = true;
      this.searchUserInfo.username = (this.searchUserInfo.username !== undefined && this.searchUserInfo.username !== null) ? this.searchUserInfo.username.toLowerCase() : '';
      this.searchUserInfo.userId = (this.searchUserInfo.userId !== undefined && this.searchUserInfo.userId !== null) ? this.searchUserInfo.userId.toLowerCase() : '';
      this.searchUserInfo.siteId = (this.searchUserInfo.siteId !== undefined && this.searchUserInfo.siteId !== null) ? this.searchUserInfo.siteId.toLowerCase() : '';

      this.getUserList(this.searchUserInfo)
          .then(response => {
            if (response.code === 0) {
              this.usersList = response.data;
              if (response.data !== null && response.data.length > 0) {
                for (let i = 0; i < this.usersList.length; i++) {
                  this.usersList[i].id = i + 1;
                  this.usersList[i].createdDate = moment(this.usersList[i].createdDate).format('YYYY/MM/DD');
                  this.usersList[i].updatedDate = moment(this.usersList[i].updatedDate).format('YYYY/MM/DD');
                }
              }
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
            this.isSubmitAction = false;
            this.loading = false;
          });
    },
    handleGetComboboxUsers() {
      this.getUserList({
        userId: null,
        username: null,
        siteId: null,
        role: 0
      })
        .then(response => {
          if (response.code === 0) {
            this.comboboxUser = response.data;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    // eslint-disable-next-line
    createItem() {
      this.selectedUser = {}
      this.modelCreate = true
      $('#main-header').css("pointer-events","none");
      EventBus.$emit('handleOpenSystemUserEditModal', {
        title: this.$t('labels.systemUserManager.createPopupTitle'),
        flag: true,
        checkDetail: false,
        editMode: false
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events","none");
    },
    editItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.selectedUser = Object.assign({}, item)
      this.showPassword = false;
      EventBus.$emit('handleOpenSystemUserEditModal', {
        title: this.$t('labels.systemUserManager.updatePopupTitle'),
        selectedUser: this.selectedUser,
        flag: true,
        checkDetail: false,
        editMode: true
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events","none");
    },
    detailItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.selectedUser = Object.assign({}, item)
      EventBus.$emit('handleOpenSystemUserEditModal', {
        title: this.$t('labels.systemUserManager.detailPopupTitle'),
        selectedUser: this.selectedUser,
        flag: true,
        checkDetail: true
      });
      this.modelDetail = true
      $('#main-header').css("pointer-events","none");
    },
    handleInactiveUser(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.selectedUser = Object.assign({}, item)

      this.commonConfirmVue(this.ERROR_CODE['CF_052_01_02'], () => {
        this.deleteUser(this.selectedUser)
            .then(response => {
              if (response.code === 0) {
                this.commonInfoVue(this.ERROR_CODE['S_CM_01'], 'success')
                this.handleGetComboboxUsers();
                this.handleGetUserList();
              } else {
                this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
              }
            })
            .catch(err => {
              console.log(err);
              if(err.code != undefined)
                this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            })
            .finally(final => {
              this.isSubmitAction = false;
            });
      })
    },
    handleClearSearchingCondition() {
      this.searchUserInfo = {
        userId: null,
        username: null,
        siteId: null,
        role: 0
      }

      this.handleGetUserList();
    }
  }
}

</script>


<style lang="scss" scoped>

  .colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}

.btn-add {
  background-color: #0d6efd !important;
  color: white !important;
}

.my_border {
  border-left: 5px solid red;
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}
@media (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .card-body-ipad {
    width: 100% !important;
  }
}
::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 5.55rem !important;
  }

  }

  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1.2rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}



</style>
