<template>
  <li class="nav-item" ref="menu-item">
    <a
      class="nav-link p-0d-flex align-items-center justify-content-center"
      v-if="menuItem.display"
      :class="{ active: isMainActive || isOneOfChildrenActive }"
      @click="handleMainMenuAction"
    >
      <!-- <i class="nav-icon fas fa-tachometer-alt"></i> -->
      <v-icon style="font-size: 20px !important; padding-bottom: 5px;" class="nav-icon fas" small>{{
          $t(menuItem.icon)
        }}
      </v-icon>
      <p v-if="$i18n.locale === 'en'" class=" font-weight-bold">{{ $t(menuItem.nameEn) }}</p>
      <p v-else class=" font-weight-bold">{{ $t(menuItem.nameJp) }}</p>
      <i v-if="isExpandable" class="fas fa-angle-left right "></i>
    </a>
    <ul class="nav nav-treeview" v-for="item in menuItem.children" :key="item.path">
      <li class="nav-item ml-2 d-flex justify-content-start align-content-start align-items-start" style="width: 220px">
        <router-link
          :to="item.path"
          class="nav-link"
          style="backdrop-filter: blur(5px);"
          exact
          exact-active-class="active"
        >
          <!-- <i class="far fa-circle nav-icon"></i> -->
          <v-icon style="font-size: 20px !important; padding-bottom: 5px;" class="far nav-icon" small>{{
              $t(item.icon)
            }}
          </v-icon>
          <template>
            <p v-if="$i18n.locale === 'en'" style="color: black">{{ $t(item.nameEn) }}</p>
            <p v-else style="color: black">{{ $t(item.nameJp)}}</p>
          </template>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>

export default {
  name: "MenuItem",
  props: {
    menuItem: Object,
    icon: String,
    type: String,
    placeholder: String,
    autocomplete: String
  },
  data: () => ({
    isMenuExtended: false,
    isExpandable: false,
    isMainActive: false,
    isOneOfChildrenActive: false
  }),
  updated(){
    console.log(this.menuItem);
  },
  methods: {
    handleMainMenuAction() {
      if (this.isExpandable) {
        this.toggleMenu();
        return;
      }
      this.$router.replace(this.menuItem.path);
    },
    toggleMenu() {
      this.isMenuExtended = !this.isMenuExtended;
      let vm = this
      if(this.isMenuExtended){
        setTimeout(function (){
          if(vm.$refs["menu-item"].classList.contains("menu-open")){
              return
          }
          vm.$refs["menu-item"].classList.add("menu-is-opening");
          vm.$refs["menu-item"].classList.add("menu-open");
          for (let child of vm.$refs['menu-item'].childNodes) {
            if (child.tagName === 'UL') {
              child.style.display = 'block';
            }
          }
        },100)
      }else {
        setTimeout(function (){
          if(!vm.$refs["menu-item"].classList.contains("menu-open")){
            return
          }
          vm.$refs["menu-item"].classList.remove("menu-is-opening", "menu-open");
          for (let child of vm.$refs['menu-item'].childNodes) {
            if (child.tagName === 'UL') {
              child.style.display = 'none';
            }
          }
        },100)

      }
    },
    calculateIsActive(url) {
      this.isMainActive = false;
      this.isOneOfChildrenActive = false;
      if (this.isExpandable) {
        this.menuItem.children.forEach((item) => {
          if (item.path === url) {
            this.isOneOfChildrenActive = true;
            this.isMenuExtended = true;
            this.$refs["menu-item"].classList.add("menu-is-opening", "menu-open");
            for (let child of this.$refs['menu-item'].childNodes) {
              if (child.tagName === 'UL') {
                child.style.display = 'block';
              }
            }
          }
        });
      } else if (this.menuItem.path === url) {
        this.isMainActive = true;
      }
      if (!this.isMainActive && !this.isOneOfChildrenActive) {
        this.isMenuExtended = false;
      }
    }
  },
  mounted() {
    this.isExpandable =
      this.menuItem &&
      this.menuItem.children &&
      this.menuItem.children.length > 0;
    this.calculateIsActive(this.$router.currentRoute.path);
    this.$router.afterEach((to) => {
      this.calculateIsActive(to.path);
    });
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}

.nav-treeview.nav-item {
  width: 220px !important;
}
</style>;
