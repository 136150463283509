<template>
  <div class="card card-iphone" :style="style">
    <div ref="headerChart" class="card-header bg-gray-light border-0">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;" v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) :
            "" }}</span>
          </template>
        </v-tooltip>
      </h3>

      <div class="card-tools">
        <button class="btn bg-btn-card-tool btn-sm mx-2" type="button" v-on:click.prevent="handleOpenDialogSafieCamera"
          v-if="isDashboard && type !== 'MWC'">
          <v-icon dark>mdi-login</v-icon>
        </button>
        <button v-if="(link !== undefined || type === 'MWC') && type !== 'MSC' && isDashboard" v-on:click="openInNewWindow('MWC')" type="button"
          class="btn bg-btn-card-tool btn-sm">
          <v-icon dark>mdi-desktop-mac</v-icon>
        </button>
        <button v-if="(link !== undefined || type === 'MSC') && type !== 'MWC' && isDashboard" v-on:click="openInNewWindow('MSC')" type="button"
          class="btn bg-btn-card-tool btn-sm">
          <v-icon dark>mdi-desktop-mac</v-icon>
        </button>
        <button v-if="isShowEdit" type="button" class="btn btn-sm" @click="clickClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div ref="cardBody"  class="card-body">
       <safie-streaming-player ref="safieVideo" v-if="type !== 'MWC'"></safie-streaming-player>
       <div class="col-12 d-flex justify-content-center" v-if="type === 'MWC'" style="flex-basis: 0; flex-grow: 1">
          <iframe :src="`${linkUrl}/nphControlCamera?Direction=`" name="_cameraFrame" hidden></iframe>
          <div v-if="link !== undefined" :style="{width: iframeWidth}" style="min-height: 225px;" ref="mwcIframe">
            <iframe
                :src="`${linkUrl}/ImageViewer?Resolution=${iframeWidth}x${iframeHeight}&Quality=Standard&Mode=Motion&Interval=1`"
                scrolling="no"
                frameborder="yes"
                @load="iframeIsLoaded"
                style="overflow: hidden !important; width: 100% !important; height: 100%"
                id="frame">
            </iframe>
          </div>
          <div v-else style="width: 100%; max-height: 180px;" class="d-flex align-items-center justify-content-center">
            <img
                class="img-item"
                src="@/assets/img/camera_frame.png"
                style="max-width: 100%; max-height: 100%"
                id="frame"
                alt=""/>
          </div>
        </div>
        <div>
          <table class="table table-style" v-if="type === 'MWC'">
            <thead>
            <tr>
              <th scope="col" class="text-white" style="background-color: #3d76ad !important;">{{
                  $t("cameraFrame.action")
                }}
              </th>
              <th scope="col" class="text-white" style="background-color: #3d76ad !important;">{{
                  $t("cameraFrame.pan")
                }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr style="border-bottom: 1px solid #ebebeb">
              <td class="w-50">
                <div class="d-flex justify-content-center ">
                  <div class="''">
                    <div class="d-flex justify-content-center py-1">
                      <div class="hover-icon">
                        <v-btn v-on:click="handleZoomInOutOnly(1)" class="btn btn-sm btn-primary">
                          <font-awesome-icon
                            icon="fa-solid fa-magnifying-glass-plus"
                            :size="zoomIn"
                            @mouseover="zoomIn = 'lg'"
                            @mouseout="zoomIn = 'lg'"
                          />
                        </v-btn>

                      </div>
                      <div class="hover-icon">
                        <v-btn v-on:click="handleZoomInOutOnly(-1)" class="btn btn-sm btn-primary">
                          <font-awesome-icon
                            icon="fa-solid fa-magnifying-glass-minus"
                            :size="zoomOut"
                            @mouseover="zoomOut = 'lg'"
                            @mouseout="zoomOut = 'lg'"
                          />
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <div class="hover-icon">
                        <v-btn class="btn btn-sm btn-primary" v-on:click="handleCaptureScreen">
                          <font-awesome-icon icon="fa-solid fa-camera" size="lg"/>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="w-50">
                <div class="d-flex justify-content-center align-items-center button-group-ipad row p-0 m-0">
                  <div class="col-12 d-flex justify-content-center p-0 m-0 py-1">
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutCamera('TiltUp')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-up"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                  </div>
                  <div :class="checkWidth ? 'd-flex' : 'col-12 d-flex justify-content-center p-0 m-0'">
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutCamera('PanLeft')" class="btn btn-sm btn-primary"
                        size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-left"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutCamera('TiltDown')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-down"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutCamera('PanRight')" class="btn btn-sm btn-primary"
                        size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-right"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
    </div>
  </div>
</template>

<script>
import { getSafieToken } from "@/utils/auth";
import { mapActions } from "vuex";
require("../../assets/js/safie");

export default {
  name: "MWC",
  components: {},
  props: {
    height: Number,
    width: Number,
    type: String,
    title: String,
    link: String,
    isShowEdit: Boolean,
    isResize: String,
    frameId: Number,
    isDashboard: Boolean
  },
  data() {
    return {
      signalRConnection: undefined,
      zoomIn: "lg",
      zoomOut: "lg",
      safieLink: null,
      showPassword: false,
      heightContainer: 0,
      loginCameraFlg: false,
      iframeWidth: 320,
      iframeHeight: 240,
      loginSafie: false,
      safieOrigin: "https://safie.link/",
      safieIframeSrc: "https://safie.link/swc/1.0.1/iframe.html",
      playerOptions: {
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "application/x-mpegURL",
          src: "",
          withCredentials: false,
        }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        },
        html5: {
          vhs: {
            withCredentials: true
          }
        },
      },
      player: null,
      checkWidth: true,
    };
  },
  mounted() {
    this.setWidth();
    this.$emit(`ChartReady`, this.frameId);
    this.$dashboardHub.$on(`SafieLogin`, () => {
      this.updateDeviceStream();
    });
    this.signalRConnection = this.$dashboardHub.init();
    this.$dashboardHub.NotifySafieLogin(this.signalRConnection);
    this.setClassGroupButton()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    this.$dashboardHub.stop(this.signalRConnection);
  },
  watch: {
    width: {
      handler () {
       this.setClassGroupButton()
      }
    }
  },
  computed: {
    style() {
      return "height: " + this.height + "px";
    },
    linkUrl() {
      if (this.link) {
        return this.link;
      } else {
        return "";
      }
    },
    login() {
      return this.loginSafe;
    }
  },
  methods: {
    ...mapActions("dashboard", {
      getSafieDevice: "getSafieDevice",
    }),
    setWidth() {
      const mwcIframe = this.$refs?.mwcIframe;
      if (mwcIframe) {
        if (mwcIframe.clientHeight > 480) {
          mwcIframe.style.width = "640px";
          this.iframeWidth = 640;
          this.iframeHeight = 480;
        } else {
          mwcIframe.style.width = "320px";
          this.iframeWidth = 320;
          this.iframeHeight = 240;
        }
      }
    },
    setClassGroupButton () {
      if (this.width < 465) {
          this.checkWidth = false
        } else {
          this.checkWidth = true
        }
    },
    styleContainer() {
      return "height: " + this.heightContainer + "px";
    },
    getHeightContainer() {
      this.heightContainer = this.height - 58
      return this.heightContainer
    },
    iframeIsLoaded() {
      this.loginCameraFlg = true;
    },
    clickClose() {
      this.$emit(`closeFrame`, this.frameId);
    },
    openInNewWindow(cam) {
      let routeData = []
      let newWindow = null
      if (cam === 'MWC') {
          routeData = this.$router.resolve({
          path: '/camera',
          query: { link: this.linkUrl },
          append: true
        });
        newWindow = window.open(routeData.href, this.title,
          'width=800px,height=800px,toolbar=0,menubar=0,location=0');
      } else {
        routeData = 'https://safie.link/app/auth/login'
        newWindow = window.open(routeData);
      }
      if (window.focus) {
        newWindow.focus()
      }
    },
    handleZoomInOutCamera(flg) {
      let win = window.open(`${this.link}/nphControlCamera?Direction=${flg}&Resolution=640x480&Quality=Standard&RPeriod=0&Size=STD&PresetOperation=Move&Language=1`, '_cameraFrame');

      if (window.focus) {
        win.focus()
      }


    },
    handleZoomInOutOnly(flg) {
      let win = window.open(`${this.link}/cgi-bin/directctrl?zoom=${flg}&Language=1`, '_cameraFrame');
      
      if (window.focus) {
        win.focus()
      }


    },
    handleCaptureScreen() {
      let screen = window.open(`${this.link}/SnapshotJPEG?Resolution=800x600&Quality=Clarity&Language=1`);

      if (window.focus) {
        screen.focus()
      }
    },
    handleOpenDialogSafieCamera() {
      this.safieLink = `https://www.kns-cloud.com/safie/safie.aspx?domain=${process.env.VUE_APP_FULL_URL_HUB}&connectionid=${this.signalRConnection.connection.connectionId}`
      let dialog = window.open(this.safieLink, "_blank", 'width=600px,height=600px,toolbar=0,menubar=0,location=0')
      if (window.focus) {
        dialog.focus()
      }
    },
    newGuid() {
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (e) {
        const t = (16 * Math.random()) | 0;
        return ("x" === e ? t : (3 & t) | 8).toString(16);
      });
    },
    updateDeviceStream() {
      this.getSafieDevice().then((response) => {
        if(this.$refs.safieVideo != undefined){
          let safieStreamingPlayer = this.$refs.safieVideo.instance;
          safieStreamingPlayer.on('error', (error) => {
              console.error(error);
          });
          // 初期化
          safieStreamingPlayer.defaultProperties = {
              defaultAccessToken: getSafieToken(),
              defaultDeviceId: response.data.list[0].device_id,
          };
          // safieStreamingPlayer.iframe.width = this.$refs.cardBody.clientWidth
          // safieStreamingPlayer.iframe.height = this.$refs.cardBody.clientHeight
          safieStreamingPlayer.play();
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 1180px) {
  .button-group-ipad {
    flex-direction: column;
  }

  .button-group {
    padding-top: 5% !important;
  }

  .hover-icon {
    padding-bottom: 10px;
  }
}

.button-group {
  padding-top: 9% !important;
}

.hidden-resizeable {
  display: none !important;
}

.card {
  height: 100% !important;
}

.container {
  height: 100% !important;
}

.hover-icon:hover {
  color: red;
  cursor: pointer;
}

input[type="image"] {
  width: 480px;
  height: 320px;
}

.button-div {
  padding-top: 1%;
  padding-bottom: 1%;
}

.button-div-icon {
  padding-top: 12%;
}

.hover-icon {
  margin-right: 3px !important;
}

.img-item {
  padding: 5px 0;
}

.table-style {
  margin-top: 0;
  margin-bottom: 8px !important;
}

.table-height {
  height: 280px;
}
.bg-btn-card-tool{
  background-color: #3d76ad !important;
}

@media only screen and (max-width: 500px) and (max-height: 900px) and (orientation: portrait) {
  .normal-table table thead tr th {
    font-size: 0.75rem !important;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 28px;
    min-width: 40px;
  }
  .table {
    width: 300px !important;
  }
  .card-body {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

</style>
