import request from "../utils/request";

export function getSiteList(payload) {
    return request({
        url: "/Site/getSiteList",
        method: "post",
        data: payload
    });
}
export function addNewSite(payload) {
    return request({
        url: "/Site/addSite",
        method: "post",
        data: payload
    });
}

export function getCbbSites(flag) {
    return request({
        url: `/Site/getCbbSites/${flag}`,
        method: "post",
    });
}
export function getCbbSitesByAdminSite() {
    return request({
        url: "/Site/getCbbSitesByAdminSite",
        method: "post",
    });
}
export function getUserSiteList() {
    return request({
        url: "/Site/getUserSiteList",
        method: "post",
    });
}
export function getCbbUserList(userType) {
    return request({
        url: "/Users/getCbbUserList/" + userType,
        method: "post",
    });
}
export function getDetailSite(siteId) {
    return request({
        url: "Site/siteDetail/" + siteId,
        method: "post"
    });
}
export function updateSite(payload) {
    return request({
        url: "/Site/updateSite",
        method: "put",
        data: payload
    });
}
export function deleteSite(siteId) {
    return request({
        url: "/Site/deleteSite/" + siteId,
        method: "post",
        data: {}
    });
}

export function updateCurrentSite(siteId) {
  return request({
    url: `/Site/updateCurrentSite/${siteId}`,
    method: "post",
    data: {}
  });
}
export function getCbbDatabase(payload) {
    return request({
      url: `/Site/getCbbDatabasesTs`,
      method: "post",
      data: payload
    });
}
  export function saveChannel(payload) {
    return request({
        url: "/Site/saveChannel",
        method: "post",
        data: payload
    });
}
export function getListChannel(payload) {
    return request({
        url: "/Site/getListChannel",
        method: "post",
        data: payload
    });
}
export function deleteChannel(payload) {
    return request({
        url: `/Site/deleteChannel`,
        method: "post",
        data: payload
    });
}
export function getLastValueWaterLevelValueRealtime(payload) {
    return request({
        url: "/Site/getLastValueWaterLevelValueRealtime",
        method: "post",
        data: payload
    });
}
export function saveWaterLevelValueRealtime(payload) {
    return request({
        url: "/Site/saveWaterLevelValueRealtime",
        method: "post",
        data: payload
    });
}