/* eslint-disable */
export function onTrimSpace(text) {
  if(text === undefined || text === null || text === ''){
    return text;
  }
  return text.trim();
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z\d.-]+(:[a-zA-Z\d.&%$-]+)*@)*((25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d?)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}|([a-zA-Z\d-]+\.)*[a-zA-Z\d-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

export function validEmail(email) {
  if(email === undefined || email === null || email === ''){
    return true;
  }
  const re = /^[A-Za-z0-9+_.-]+@(.+)$/i;
  return re.test(email)
}

export function validPhone(phone) {
  if(phone === undefined || phone === null || phone === ''){
    return true;
  }
  const re = /^\d+$/;
  return re.test(phone);
}

export function checkRegexUsername(username) {
  let usernameRegex = /^[a-zA-Z\d.]{5,20}$/
  return usernameRegex.test(username)
}

export function checkRegexPassword(password) {
  let passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$");
  return passwordRegex.test(password)
}

export function checkRegexDate(date) {
  let dateRegEx = /^\d{4}[-/]\d{2}[-/]\d{2}$/;
  return dateRegEx.test(date);
}

export function validateDate(date_string) {
  if(!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date_string)) {
    return false;
  }

  // Parse the date parts to integers
  let parts = date_string.split("-");
  let year = parseInt(parts[0], 10);
  let month = parseInt(parts[1], 10);
  let day = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if(year < 1753 || year > 9998 || month === 0 || month > 12) {
    return false;
  }

  let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return !(day < 0 && day >= monthLength[month - 1]);
}

export function checkRegexSpaceAndSpecialCharacter(string){
  let regex = /[^a-z0-9]/gi;
  return regex.test(string);
}
export function checkMinLength(string){
  return string.split("").length >= 8;
}

export function formatXAisDate(date){
  let str = date.replace('T','').replace('Z','');
  let year = str.substring(0, 4);
  let month = str.substring(4, 6);
  let day = str.substring(6, 8);
  let hour = str.substring(8, 10);
  let minute = str.substring(10, 12);
  let second = str.substring(12, 14);
  return new Date(year, month-1, day, hour, minute, second).toLocaleString('ja-JP');
}
