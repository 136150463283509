<template>
  <div class="card">
    <div class="container">
      <div class="row pt-1">
        <div class="col-12 pb-0 pt-0">
          <div class="col-12 d-flex justify-content-center">
            <iframe :src="`${cameraLink}/nphControlCamera?Direction=`" name="_cameraFramePopup" hidden></iframe>
            <input type="image" name="NewPosition" :src="`${cameraLink}/nphMotionJpeg?Resolution=640x480&Quality=Standard`" style="height: 100% !important; width: 100% !important;"  alt="">
          </div>
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-white" style="background-color: #3d76ad !important;">ズーム</th>
              <th scope="col" class="text-white" style="background-color: #3d76ad !important;">パン／チルト</th>
              <th scope="col" class="text-white" style="background-color: #3d76ad !important;">キャプチャー</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border-bottom: 1px solid #ebebeb">
              <td class="w-25">
                <div class="d-flex justify-content-center">
                  <div class="d-flex justify-content-between align-content-center">
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutOnly(1)" class="btn btn-sm btn-primary">
                        <font-awesome-icon
                          icon="fa-solid fa-magnifying-glass-plus"
                          :size="zoomIn"
                          @mouseover="zoomIn = 'lg'"
                          @mouseout="zoomIn = 'lg'"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon ml-3">
                      <v-btn v-on:click="handleZoomInOutOnly(-1)" class="btn btn-sm btn-primary">
                        <font-awesome-icon
                          icon="fa-solid fa-magnifying-glass-minus"
                          :size="zoomOut"
                          @mouseover="zoomOut = 'lg'"
                          @mouseout="zoomOut = 'lg'"
                        />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>
              <td class="w-50">
                <div class="d-flex justify-content-center p-0">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="hover-icon">
                      <v-btn v-on:click="handleZoomInOutCamera('TiltUp')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-up"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon ml-3">
                    <v-btn v-on:click="handleZoomInOutCamera('PanLeft')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-left"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon ml-3">
                      <v-btn v-on:click="handleZoomInOutCamera('PanRight')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-right"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                    <div class="hover-icon ml-3">
                      <v-btn v-on:click="handleZoomInOutCamera('TiltDown')" class="btn btn-sm btn-primary" size="sm">
                        <font-awesome-icon
                          icon="fa-solid fa-arrow-down"
                          size="lg"
                        />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>
              <td class="w-25">
                <div class="d-flex justify-content-center">
                  <div class="d-flex justify-content-between">
                    <div class="hover-icon">
                      <v-btn class="btn btn-sm btn-primary" v-on:click="handleCaptureScreen">
                        <font-awesome-icon icon="fa-solid fa-camera" size="lg" />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- /.card-body -->

    <!-- /.card-footer -->
  </div>
</template>
<script>
export default {
  name: "CameraPage",
  components: {},
  data() {
    return {
      zoomIn: "lg",
      zoomOut: "lg",
      heightContainer: 0,
      cameraLink: null
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    this.cameraLink =  urlParams.get('link');
  },
  computed: {
    style() {
      const abc = {temp: this.getHeightContainer()};
      return "height: " + this.height + "px";
    }
  },
  methods: {
    styleContainer() {
      return "height: " + this.heightContainer + "px";
    },
    getHeightContainer() {
      this.heightContainer = this.height - 58;
      return this.heightContainer;
    },
    handleZoomInOutCamera(flg) {
      let win = window.open(`${this.cameraLink}/nphControlCamera?Direction=${flg}&Resolution=320x240&Quality=Standard&RPeriod=0&Size=STD&PresetOperation=Move&Language=1`, '_cameraFramePopup');
      if (window.focus) {
        win.focus()
      }
    },
    handleZoomInOutOnly(flg) {
      let win = window.open(`${this.cameraLink}/cgi-bin/directctrl?zoom=${flg}&Language=1`, '_cameraFramePopup');

      if (window.focus) {
        win.focus()
      }
    },
    handleCaptureScreen(){
      let screen = window.open(`${this.cameraLink}/SnapshotJPEG?Resolution=800x600&Quality=Clarity&Language=1`);
      if (window.focus) {
        screen.focus()
      }
    }
  }
};
</script>
<style>

.hover-icon:hover {
  color: red;
  cursor: pointer;
}

input[type="image"] {
  width: 480px;
  height: 320px;
}
</style>
