<template>
  <DropDown style="border-radius: 20px" size="150">
    <template v-slot:dropdown-button>
      <i class="flag-icon fa-15x" :class="flagIcon"></i>
    </template>
    <template v-slot:dropdown-menu>
      <a
        class="dropdown-item"
        :class="{ 'active': selectedLanguage === language.key }"
        @click="changeLanguage(language.key)"
        v-for="(language, index) in languages"
        :key="index"
      >
        <i class="flag-icon mr-2" :class="language.flag"></i>
        {{ $t(language.label) }}
      </a>
    </template>
  </DropDown>
</template>

<script>
import DropDown from "../../components/dropdown/dropdown.vue";
import {cookiesSetI18Lang} from "@/utils/auth";

export default {
  components: {DropDown},
  name: "LanguagesDropdown",
  data() {
    return {
      languages: [
        {
          key: "en",
          flag: "flag-icon-us",
          label: "languages.english",
        },
        {
          key: "jp",
          flag: "flag-icon-jp",
          label: "languages.japan",
        }
      ],
      selectedLanguage: "",
    }
  },
  computed: {
    flagIcon: function () {
      if (this.selectedLanguage === 'jp') {
        return "flag-icon-jp"
      }
      return "flag-icon-us";
    },
  },
  methods: {
    changeLanguage: function (langCode) {
      if (this.$i18n.locale !== langCode) {
        this.$i18n.locale = langCode;
        this.selectedLanguage = langCode;
        cookiesSetI18Lang(langCode)
        if (this.selectedLanguage == 'jp') {
          this.$vuetify.lang.current = 'ja'
        } else {
          this.$vuetify.lang.current = 'en'
        }
      }
    },
  },
  mounted() {
    this.selectedLanguage = this.$i18n.locale;
  },
};
</script>

<style>
.dropdown-item {
  cursor: pointer;
}

.fa-15x {
  font-size: 1.5em;
}
</style>
