<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ $t(nameLabel) }}</span>
        <v-btn @click="onClose" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <v-form ref="templateMailForm" v-model="valid" lazy-validation>
            <div class="row mt-0">
              <div class="col-6" v-if="ACTION === 'OPS'">
                <div>
                  <h6 class="mb-1 font-weight-bold">
                    {{ $t("labels.mailSetting.title") }}
                    <span class="text-danger mb-0 h-text-danger"></span>
                  </h6>
                  <v-text-field
                    v-model="title"
                    @change="isChange = true"
                    hide-details="auto"
                    dense
                    outlined
                    validate-on-blur
                    maxlength="100"
                  ></v-text-field>
                </div>
                <div class="pt-2">
                  <h6 class="mb-1 font-weight-bold">
                    {{ $t("labels.mailSetting.content") }}
                    <span class="text-danger mb-0 h-text-danger"></span>
                  </h6>
                  <v-textarea
                    ref="ta"
                    dense
                    outlined
                    v-model="content"
                    @change="isChange = true"
                    maxlength="500"
                    name="input"
                    validate-on-blur
                  ></v-textarea>
                </div>
              </div>
              <div class="col-12" v-else>
                <div>
                  <h6 class="mb-1 font-weight-bold">
                    {{ $t("labels.mailSetting.title") }}
                    <span class="text-danger mb-0 h-text-danger"></span>
                  </h6>
                  <v-text-field
                    v-model="title"
                    @change="isChange = true"
                    hide-details="auto"
                    dense
                    outlined
                    validate-on-blur
                    maxlength="100"
                  ></v-text-field>
                </div>
                <div class="pt-2">
                  <h6 class="mb-1 font-weight-bold">
                    {{ $t("labels.mailSetting.content") }}
                    <span class="text-danger mb-0 h-text-danger"></span>
                  </h6>
                  <v-textarea
                    ref="ta"
                    dense
                    outlined
                    v-model="content"
                    @change="isChange = true"
                    maxlength="500"
                    name="input"
                    validate-on-blur
                  ></v-textarea>
                </div>
              </div>
              <div class="col-6">
                <div class="row pt-5">
                  <div class="col-6">
                    <v-btn
                      color="primary"
                      class="col-12 pl-3 pr-3 font-size-iphone"
                      style="background-color: #3d76ad"
                      @click="onClickDevice"
                      v-if="ACTION === 'OPS'"
                      >{{ $t("labels.mailSetting.device") }}</v-btn
                    >
                  </div>
                  <div class="col-6">
                    <v-btn
                      color="primary"
                      class="col-12 pl-3 pr-3 font-size-iphone data-real-time"
                      style="background-color: #3d76ad"
                      @click="onClickDataRealtime"
                      v-if="ACTION === 'OPS'"
                      >{{ $t("labels.mailSetting.datarealtime") }}</v-btn
                    >
                  </div>
                  <div class="col-6">
                    <v-btn
                      color="primary"
                      class="col-12 pl-3 pr-3 font-size-iphone"
                      style="background-color: #3d76ad"
                      @click="onClickStation"
                      v-if="ACTION === 'OPS'"
                      >{{ $t("labels.mailSetting.station") }}</v-btn
                    >
                  </div>
                  <div class="col-6">
                    <v-btn
                      color="primary"
                      class="col-12 pl-3 pr-3 font-size-iphone date-time"
                      style="background-color: #3d76ad"
                      @click="onClickDateTime"
                      v-if="ACTION === 'OPS'"
                      >{{ $t("labels.mailSetting.datetime") }}</v-btn
                    >
                  </div>
                  <div class="col-6">
                    <v-btn
                      color="primary"
                      class="col-12 pl-3 pr-3 font-size-iphone"
                      style="background-color: #3d76ad"
                      @click="onClickProperties"
                      v-if="ACTION === 'OPS'"
                      >{{ $t("labels.mailSetting.properties") }}</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-white mr-1"
          style="background-color: #3d76ad"
          text
          @click.prevent="save"
          v-if="ACTION === 'OPS'"
        >
          {{ $t("labels.save") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="onClose">
          {{ $t("labels.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="isLoadingOverlay">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import {getCookiePrivileges, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import { getMailTemplate, saveMailTemplate } from "@/api/mailTemplate";
import { ResponseCode } from "@/constants/constants";

export default {
  name: "TemplateMailModal",
  data() {
    return {
      valid : true,
      showDialog: false,
      nameLabel: "",
      title: "",
      content: "",
      isLoadingOverlay: false,
      isChange : false,
      PAGE_CODE: 'CS_MS',
      ACTION: 'OPS'
    };
  },
  watch: {
    "$i18n.locale": async function () {
      // start import ERROR_CODES by language
      await this.getErrorCode("mail");
    },
    showDialog: function () {
      this.$refs.templateMailForm?.resetValidation();
      this.valid = true;
      this.isChange = false;
    },
  },
  async beforeMount() {
    // import ERROR_CODES by language
    await this.getErrorCode("mail");
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);

    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    EventBus.$on("handleOpenTemplateMailEditModal", (value) => {
      this.nameLabel = value.nameLabel;
      this.showDialog = value.flag;
      this.initData();
    });
  },
  methods: {
    save() {
      if (!this.$refs.templateMailForm.validate()) {
        return;
      }
      this.isLoadingOverlay = true;
      const request = {
        SiteId: getCookieSiteUser(),
        Title: this.title,
        Content: this.content,
      };
      saveMailTemplate(request)
        .then((response) => {
          if (response.data.code === ResponseCode.SAVE_SUCCESS) {
            this.commonNotifyVue(
              this.ERROR_CODES[response.data.code],
              "success"
            );
            this.handleCommonDialog(false);
            this.showDialog = false;
          } else {
            this.commonNotifyVue(
              this.ERROR_CODES[response.data.code],
              "warning"
            );
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.isLoadingOverlay = false;
          // this.handleCommonDialog(false);
          this.showDialog = false;
        });
    },
    initData() {
      this.isLoadingOverlay = true;
      const request = {
        SiteId: getCookieSiteUser(),
      };
      getMailTemplate(request)
        .then((response) => {
          if (response.data && response.data.code === ResponseCode.SUCCESS) {
            this.title = response.data?.mailTemplate?.title;
            this.content = response.data?.mailTemplate?.content;
          } else {
            this.commonNotifyVue(this.ERROR_CODES[response.data.code], "warning");
            this.title = ""
            this.content = ""
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.isLoadingOverlay = false;
        });
    },
    onClickDevice() {
      const code = this.$t("labels.mailSetting.templateMail.device");
      this.insertDataIntoTextArea(code);
    },
    onClickDataRealtime() {
      const code = this.$t("labels.mailSetting.templateMail.realtime");
      this.insertDataIntoTextArea(code);
    },
    onClickStation() {
      const code = this.$t("labels.mailSetting.templateMail.station");
      this.insertDataIntoTextArea(code);
    },
    onClickDateTime() {
      const code = this.$t("labels.mailSetting.templateMail.dateTime");
      this.insertDataIntoTextArea(code);
    },
    onClickProperties(){
      const code = this.$t("labels.mailSetting.templateMail.properties");
      this.insertDataIntoTextArea(code);
    },
    insertDataIntoTextArea(code) {
      const textarea = this.$refs.ta.$refs.input;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = textarea.length;
      }
      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);
      this.content = before + code + after;
      const end = this.content.length;
      textarea.setSelectionRange(end, end);
      textarea.focus();
    },
    onClose() {
      if (this.isChange) {
        this.commonConfirmVue(
          this.$t("commonPopup.titleConfirmDiscard"),
          () => {
            this.handleCommonDialog(false);
            this.showDialog = false;
          },
          () => {
            return;
          }
        );
      } else {
        this.handleCommonDialog(false);
        this.showDialog = false;
      }
    }
  },
};
</script>

<style scoped lang="scss">

@media (max-width: 540px) {
  .font-size-iphone {
    font-size: 0.4rem !important;
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .v-card__text {
    padding: 0 24px 0 0 !important;
  }
}
}
</style>
