import request from "../utils/request";

export function login(payload) {
    return request({
        url: "/Auth/login",
        method: "post",
        data: payload
    });
}

export function logout(payload) {
    return request({
        url: "/Auth/logout",
        method: "post",
        data: payload
    });
}