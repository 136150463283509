<template>
  <div class="pt-1 LCD hide-clock-iphone">
    <div class="d-flex digital-clock">
      <div class="am_pm pr-2">{{ currentDay }}</div>
      <div class="hours">{{ hours }}</div>
      <div class="divider">:</div>
      <div class="minutes">{{ minutes }}</div>
      <div class="divider">:</div>
      <div class="seconds">{{ seconds }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalClock",
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      curDay: '',
      curMonth: '',
      curYear: '',
      currentDay: ''
    }
  },
  mounted() {
    setInterval(() => this.setTime(), 1000)
  },
  methods: {
    setTime() {
      const date = new Date();

      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours;
      minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes;
      seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
      this.curDay = date.getDate()
      this.curMonth = date.getMonth()
      this.curYear = date.getFullYear()
      this.currentDay = this.format(new Date(this.curYear, this.curMonth, this.curDay))
    },
    format(inputDate) {
      let date, month, year;

      date = inputDate.getDate();
      month = inputDate.getMonth() + 1;
      year = inputDate.getFullYear();

      date = date
        .toString()
        .padStart(2, '0');

      month = month
        .toString()
        .padStart(2, '0');

      return `${year}/${month}/${date}`;
    }
  }
}
</script>

<style scoped>
.LCD {
  margin-top: 5px;
  margin-right: 20px;
  padding-right: 10px;
  padding-left: 10px;
  background: #FFFFFF;
  border: 0.5px solid #FFFFFF;
  border-radius: 8px;
  height: 38px;
}

.LCD > div {
  font-size: 19px;
}

.digital-clock {
  line-height: inherit;
}

@media (max-width: 1366px) {
  .hide-clock-iphone {
    display: none;
  }
}

</style>
