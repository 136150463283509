<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t('fileManager.fileName')
                              }}</span>
            </div>
            <div class="input-group-append fileManager-fileName">
              <v-text-field outlined dense v-model="fileSearch.fileName"></v-text-field>
            </div>

          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t('fileManager.uploadDate')
                              }}</span>
            </div>
           
            <div class="input-group-append input-group-append-ipad">
              <div class="w-100 d-flex">
                <div class="" style="width: 48%;">
                  <div class="row input-date-mg-ip">
                    <div class="col-xl-12 col-lg-12 col-12">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                              transition="scale-transition" offset-y
                              min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field class="start-date" v-model="fromFormatted" append-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on" hide-details="auto" outlined dense
                                        @click:append="menu = true"
                                        @blur="fileSearch.startDate = parseDate(fromFormatted)"
                                        readonly
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker  v-model="fileSearch.startDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            {{ $t('labels.cancel') }}
                          </v-btn>
                          <v-btn text color="primary"
                                 @click="$refs.menu.save(fileSearch.startDate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <div style="width: 8%;" class="d-lg-flex justify-content-center sign-ipad sign-iphone">
                  <b class="m-0 mt-1 calendar-seperation" style="font-size: 20px;">〜</b>
                </div>
                <div class="" style="width: 48%;">
                  <div class="row justify-content-end input-date-mg-ip">
                    <div class="col-xl-12 col-lg-12 col-12 pl-3 input-end-date">
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                              transition="scale-transition" offset-y
                              min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="toFormatted" append-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on" hide-details="auto" outlined dense
                                        @click:append="menu2 = true"
                                        @blur="fileSearch.endDate = parseDate(toFormatted)"
                                        readonly
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker class="end-date-picker" v-model="fileSearch.endDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            {{ $t('labels.cancel') }}
                          </v-btn>
                          <v-btn text color="primary"
                                 @click="$refs.menu2.save(fileSearch.endDate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mr-3">
          <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetFileList">
            <v-icon right dark class="mr-1">
              fas fa-search
            </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
          <v-btn class="bg-secondary font-weight-bold" @click.prevent="handleResetSearching">
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{ $t("labels.clear") }}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table :headers="headers" :items="fileData" class="elevation-2" mobile-breakpoint="0" hide-default-header
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50],
                            'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
          >
            <template slot="no-data">
              {{ $t("notification.noData") }}
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th v-for="header in headers" :key="header.value" class="text-uppercase" scope="col">
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-toolbar class="fileManager-tableHeader" flat>
                <div class="row mb-1">
                  <div class="col-xl-10 col-lg-9 col-md-9 col-9">
                    <div class="row" style="padding-top:16px !important;">
                       <span class="store-capacity">
                       <v-icon color="white" size="20" class="mr-2 dark mt-0 store-capacity">
                                                    fa fa-cloud
                      </v-icon> {{ $t('fileManager.storage') }} {{ $t('fileManager.usage') }}:
                         <strong >{{ bucketSize.toFixed(2) }}/10 GB</strong>
                      </span>
                    </div>
                    <div class="row">
                      <v-progress-linear
                        striped
                        color="blue-grey" height="20">
                        <template v-slot:default="{}">
                          <p style="color:white; font-size: 15px;" class="pt-3">{{ (bucketSize / 10 * 100).toFixed(2) }}%</p>
                        </template>
                      </v-progress-linear>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-3 fileManager-upload-container">
                    <div class="d-flex justify-content-end mt-5">
                      <v-btn depressed color="primary " class="ml-5 fileManager-upload"
                             style="background-color: #3d76ad; "
                             @click="uploadFile()" v-if="ACTION === 'OPS'">
                        <v-icon color="white" size="20" class="mr-2 dark mt-0 fileManager-uploadIcon">
                          fa fa-upload
                        </v-icon>
                        <span style="font-size: 20px" class="font-weight-bold fileManager-uploadText">{{
                            $t('fileManager.upload')
                          }}</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <div>
                <button class="m-1" @click.prevent="handleDownloadFile(item)">
                  <i class="fa fa-download text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click.prevent="handleRemoveFile(item)">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2" v-if="ACTION === 'OPS'">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <UploadFileModal :multiple="true" @close="modalUpload = false" dialog/>
  </div>
</template>

<script>
import UploadFileModal from "@/modals/uploadFile/UploadFileModal";
import {getCookiePrivileges, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import {EventBus} from "@/utils/eventBus";
import {mapActions} from "vuex";

export default {
  name: "FileManager",
  components: {
    UploadFileModal
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50"},
        {text: this.$t('fileManager.fileName'), align: 'left', sortable: false, value: 'filename'},
        {text: this.$t('fileManager.type'), value: 'type', align: 'center', sortable: false},
        {text: this.$t('fileManager.uploadBy'), value: 'createdBy', align: 'center', sortable: false},
        {text: this.$t('fileManager.uploadDate'), value: 'createdDate', align: 'center', sortable: false},
        {text: this.$t('fileManager.actions'), value: 'actions', align: 'center', sortable: false, width: "150"}
      ]
    }

  },
  data() {
    return {
      ERROR_CODE: '',
      PAGE_CODE: 'CS_FM',
      ACTION: 'OPS',
      menu: false,
      menu2: false,
      currentLocale: this.$i18n.locale,
      fromFormatted: null,
      toFormatted: null,
      fileSearch: {
        fileName: null,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10)
      },
      valueStation: [],
      valueStationSelected: [],
      fileData: [],
      modalUpload: false,
      bucketSize: 0,
      headers: [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50"},
        {text: this.$t('fileManager.fileName'), align: 'left', sortable: false, value: 'filename'},
        {text: this.$t('fileManager.type'), value: 'type', align: 'center', sortable: false},
        {text: this.$t('fileManager.uploadBy'), value: 'createdBy', align: 'center', sortable: false},
        {text: this.$t('fileManager.uploadDate'), value: 'createdDate', align: 'center', sortable: false},
        {text: this.$t('fileManager.actions'), value: 'actions', align: 'center', sortable: false, width: "150"}
      ],
      loading: false,
    }
  },
  async beforeMount() {
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/files/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/files/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
  },
  mounted() {
    this.handleGetFileList();
    this.handleGetBucketSize();
  },
  computed: {},
  watch: {
    '$i18n.locale': async function () {
      const currentLang = this.$i18n.locale
      await import(`@/utils/errors/files/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/files/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
    'fileSearch.startDate'() {
      this.fromFormatted = this.formatDate(this.fileSearch.startDate);
    },
    'fileSearch.endDate'() {
      this.toFormatted = this.formatDate(this.fileSearch.endDate);
    },
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    this.fromFormatted = this.formatDate(this.fileSearch.startDate);
    this.toFormatted = this.formatDate(this.fileSearch.endDate);

    EventBus.$on('EmitGetFileList', (value) => {
      if(value) this.handleGetFileList();
    })
    EventBus.$on('EmitGetBucketSize', (value) => {
      if(value) this.handleGetBucketSize();
    })
  },
  methods: {
    ...mapActions('file', {
      getFileList: 'getFileList',
      removeFile: 'removeFile',
      downloadFile: 'downloadFile',
      getBucketSize: 'getBucketSize',
    }),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    parseDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    uploadFile() {
      this.modalUpload = true;
      EventBus.$emit('EmitOpenUploadFileModal', this.modalUpload)
    },
    handleGetFileList() {
      this.loading = true;
      const siteId = getCookieSiteUser();
      this.getFileList({data: this.fileSearch, siteId: siteId}).then((response) => {
        if (response.code === 0) {
          this.fileData = response.data;
          let index = 1;
          this.fileData.forEach(data => {
            data.index = index++;
          });
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      }).catch((error) => {
        console.log('[handleGetFileList]', error);
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        this.loading = false;
      })
    },
    handleResetSearching() {
      this.fileSearch = {
        fileName: null,
        startDate: null,
        endDate: null
      };

      this.handleGetFileList()
    },
    handleRemoveFile(item) {
      this.loading = true;

      this.commonConfirmVue(this.ERROR_CODE['CF_052_01_02'], () => {
        this.removeFile(item.id).then((response) => {
          if (response.code === 0) {
            this.commonConfirmOKVue(this.ERROR_CODE["DELETE_SUCCESFULLY"]);
            this.handleGetFileList();
            this.handleGetBucketSize();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
          this.loading = false;
        })
      }, () => {
        this.loading = false;
      })
    },
    handleGetBucketSize() {
      const siteId = getCookieSiteUser();
      this.getBucketSize(siteId).then((response) => {
        if (response.code === 0) {
          this.bucketSize = response.data
        } else {
        this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      })
    },
    handleDownloadFile(item) {
      this.loading = true;
      const siteId = getCookieSiteUser();
      this.downloadFile({siteId: siteId, id: item.id}).then((response) => {
        let res_data = {};
        try {
          res_data = JSON.parse(new TextDecoder().decode(response.data));
        }
        catch(err){
          res_data["code"] = "0"
        }
        if (res_data.code === "0") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', response.fileName);
          document.body.appendChild(link);
          link.click();
          this.commonConfirmOKVue(this.ERROR_CODE['S_CM_03']);
        } else {
          this.commonNotifyVue(this.ERROR_CODE[res_data.code], 'warning');
        }
      }).catch((error) => {
        this.commonNotifyVue(this.ERROR_CODE['E_070_04'], 'warning');
      }).finally(() => {
        this.loading = false;
      });
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep{
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 7.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}



.colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}

.btn-add {
  background-color: #0d6efd !important;
  color: white !important;
}

.my_border {
  border-left: 5px solid red;
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}


.custom-select {
  background: none;
  background-color: white;
}

.color-red {
  color: red;
}

.titleType {
  background-color: #3c8dbc;
  height: 40px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  padding-left: 8px;
  border-radius: 6px
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .input-date-mg-ip {
    margin-left: 0px !important;
  }
  .input-end-date {
    padding-left: 6px !important;
  }
}
.input-group-append-ipad {
  margin-left: -2px;
}

@media (max-width: 1180px) {
  .input-group-append-ipad {
    margin-left: -15px;
  }
}

@media (max-width: 1000px) {

.input-group-append-ipad {
  margin-left: 0px;
}

@media only screen and (max-width: 500px) and (max-height: 1000px) {
  .fileManager-uploadText {
    font-size: 7px !important;
  }
  .fileManager-uploadIcon {
    font-size: 7px !important;
    height: 1px !important;
  }
  .fileManager-upload {
    width: 1.5rem !important;
    height: 1.2rem !important;
    margin-top: 1.6rem !important;
  }
  .fileManager-fileName {
    width: 92% !important;
  }
  .store-capacity {
    font-size: 10px !important;
    margin-top: 1rem !important;
  }
  .v-menu__content {
    top: 27.5rem !important;
    left: 3rem !important;
  }
  

}

}
</style>
