import router from '../router/router'
import {getCookiePrivileges, getCookieUserType, getToken} from '@/utils/auth' // get token from cookie


const whiteList = ['/login']; // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({path: '/admin'})
    } else {
      if (to.path === '/login' || to.path === '/admin') {
        next();
      } else {
        const currentUSer = getCookieUserType();
        const menuAccess = getCookiePrivileges();

        const whiteListRouter = ['/notfound', '/camera', '/screenShare'];

        if (parseInt(currentUSer) !== 1) {
          if (menuAccess !== undefined && menuAccess !== null) {
            JSON.parse(menuAccess).filter(value => {
              whiteListRouter.push(value.path);
              if (value.children !== undefined && value.children.length > 0) {
                value.children.forEach(child => {
                  whiteListRouter.push(child.path);
                })
              }
            });
            if (whiteListRouter.includes(to.path)) {
              return next();
            } else {
              return next({path: '/notfound'})
            }
          }
        } else {
          next();
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({path: '/login'})
    }
  }
  // next();
});

router.afterEach(() => {
});
