import request from "../utils/request";

const CS_GM = "CS_GM";


export function getListFrame(payload) {
  return request({
    url: "/Frame/getFrameList",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function getTypeFrame(data) {
  return request({
    url: `/Frame/getTypeFrame/${data.siteId}/${data.flag}`,
    method: "get",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function getFrameByType(header) {
  return request({
    url: `/Frame/getFrameByType/${header.siteId}/${header.code}`,
    method: "get",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function getFrameDataByType(params) {
  return request({
    url: `/Frame/getFrameData/${params.siteId}/${params.frameId}`,
    method: "get",
    headers: {
      'PAGE-CODE': CS_GM,
    }
  });
}

export function addFrame(payload) {
  return request({
    url: "/Frame/addFrame",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function updateFrame(payload) {
  return request({
    url: "/Frame/UpdateFrame",
    method: "put",
    data: payload,
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function getFrameDetail(siteId,frameId) {
  return request({
    url: `/Frame/getFrameDetail/${siteId}/${frameId}` ,
    method: "post",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function deleteFrame(siteId,frameId) {
  return request({
    url: `/Frame/DeleteFrame/${siteId}/${frameId}` ,
    method: "post",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function initTableChart(payload) {
  return request({
    url: "/Frame/initTableChart",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': payload.PAGE_CODE
    }
  });
}
export function getListFrameOfLayout(siteID) {
  return request({
    url: `/Frame/getFrameListOfLayout/${siteID}`,
    method: "post",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}
export function checkWarningByDataCode(SiteID,SiteStationId,DeviceCode,DataCode) {
  return request({
    url: `/Frame/checkWarningByDataCode/${SiteID}/${SiteStationId}/${DeviceCode}/${DataCode}`,
    method: "post",
    headers: {
      'PAGE-CODE': CS_GM
    }
  });
}

export function getSVGImage(url) {
  return request({
    url: url,
    method: "get",
  });
}
