<template>
  <div
    ref="dropdownMenu"
    class="dropdown-menu dropdown-menu-right show dropdown-menu-lg"
    :style="fixStyles"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    size: String,
  },
  data: () => ({
    dropdownMenuElement: null,
  }),
  computed: {
    // a computed getter
    fixStyles: function () {

      if (this.dropdownMenuElement) {
        const windowWidth = document.getElementById("app").offsetWidth;
        const offsetLeft =
          this.dropdownMenuElement.getBoundingClientRect().left;
        const offsetWidth = this.dropdownMenuElement.offsetWidth;
        const visiblePart = windowWidth - offsetLeft;
        console.log(this.size)
        if (offsetLeft < 0) {
          return {
            left: "inherit",
            right: `${offsetLeft - 5}px`,
            minWidth : this.size + 'px !important'
          };
        } else if (visiblePart < offsetWidth) {
          return { left: "inherit", right: `0px`, minWidth : this.size + 'px !important',};
        }
        if(this.size === '150'){
          return { left: "inherit", right: `20px` ,minWidth : this.size + 'px !important',width: this.size + 'px !important'};
        }else
        return { left: "inherit", right: `0px` ,minWidth : this.size + 'px !important'};
      }
      return { left: "inherit", right: `0px` ,minWidth : this.size + 'px !important',};
    },
  },
  mounted() {
    this.dropdownMenuElement = this.$refs.dropdownMenu;
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}
</style>