import {
  cookiesGetI18Lang,
  cookiesRemoveI18Lang,
  getCookieSiteUser, getCurrentPageCode,
  getRememberKCloudID,
  getToken, removeCookiePrivileges,
  removeCookieSiteUser,
  removeCookieUserType, removeCurrentPageCode,
  removeMenuAccesses,
  removeToken
} from "@/utils/auth";
import axios from "axios";
import {CONSTANTS} from "@/utils/constant";
import swal from "sweetalert2";

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_API;
let COMMON_ERROR_CODE = {
  en: {
    6699: "Some error occurred. Please contact admin for assistance.",
    777: "Your account does not have privileges.",
    0: "Saved successfully!",
    555: "Your access token has expired, please login again",
    E_CM_01: "Site ID does not exist.",
    E_CM_02: "You are not in charge of this site.",
    E_CM_04: "Your account does not have privileges",
    E_CM_05: "User ID is invalid.",
    E_CM_06: "The construction site is not working.",
  },
  jp: {
    6699: "エラーが発生しました。管理者に連絡して支援を求めてください。 ",
    777: "ご使用のアカウントはこの操作を許可されません。  ",
    0: "情報が正常に保存されました。",
    555: "アクセストークンの期限が切れているので、再度ログインしてください。",
    E_CM_01: "この現場番号はデータベースにありません。",
    E_CM_02: "この現場は本アカウントの担当ではありません。 ",
    E_CM_04: "ご使用のアカウントはこの操作を許可されません。",
    E_CM_05: "このユーザーIDは無効です。",
    E_CM_06: "この現場が稼働していません。",
  },
};
// create an axios instance
const service = axios.create({
  url: process.env.VUE_APP_SERVICE_API, // api base_url
  timeout: 36000000, // request timeout
  withCredentials: true,
  transformRequest: [
    (data, headers) => {
      // do something with data format
      if (data !== undefined) {

        if (headers['Content-Type'].includes('application/json')) {
          data = JSON.stringify(data);

          const rex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;
          data = data.replace(rex, match => `[e-${match.codePointAt(0).toString(16)}]`);
        }
      }

      return data;
    }
  ],
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': '*/*'
  }
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    const token = getToken();
    const userId = getRememberKCloudID();
    const siteId = getCookieSiteUser();
    const pageCode = getCurrentPageCode();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["X-USERID"] = userId;
      config.headers["X-SiteID"] = siteId;
      config.headers["PAGE-CODE"] = pageCode;
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

function GetLanguageByLocale() {
  import(`@/utils/errors/common/en.js`)
    .then((DATA) => {
      COMMON_ERROR_CODE = DATA.ERROR_CODE
    })
    .catch(async () => {
      await import(`@/utils/errors/common/en.js`)
        .then((DATA) => {
          COMMON_ERROR_CODE = DATA.ERROR_CODE
        })
    })
}

// response interceptor
service.interceptors.response.use(
  response => {
    const {data} = response;
    let ERROR_CODES = [];
    // if data.code == 333 || 555 || 777 => logout

    if (data.code === CONSTANTS.TOKEN_EXPIRED || data.code === CONSTANTS.TOKEN_INCORRECT
      || data.code === CONSTANTS.TOKEN_NOT_EXIST || data.code === CONSTANTS.UNAUTHORIZED
      || data.code === CONSTANTS.E_CM_06 || data.code === CONSTANTS.E_CM_04 || data.code === CONSTANTS.E_CM_01 || data.code === CONSTANTS.E_CM_02) {

      let currentLang = cookiesGetI18Lang();
      if(currentLang != undefined)
        swal({
          title: '',
          html: COMMON_ERROR_CODE[currentLang][data.code],
          type: 'error',
          showCancelButton: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false,
          allowOutsideClick: true,
          allowEscapeKey: true,
          allowEnterKey: true
        }).catch(swal.noop);
      removeToken();
      removeMenuAccesses();
      removeCookieUserType();
      removeCookieSiteUser();
      removeCurrentPageCode();
      removeCookiePrivileges();
      cookiesRemoveI18Lang();
      setTimeout(() => {
        window.location = '/login';
      }, 1000);
    }

    return response;
  },
  error => {
    let responseErr = {
      code: 6699,
      message: "Error: Network Error"
    };

    if (process.env.NODE_ENV === 'production') {
      removeToken();
      removeMenuAccesses();
      if(error.response.status == 403)
        window.location = '/login';
    }


    return Promise.reject(responseErr);
  }
);


export default service;
