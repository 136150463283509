import {
    getDeviceList,
    addDevice,
    updateDevice,
    deleteDevice,
    getDeviceDetail
} from "@/api/deviceMaster";
  
const actions = {
        getDeviceList({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                getDeviceList(payload)
                    .then(response => {
                        const data = response.data;
                        resolve(data); 
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getDeviceDetail({ commit, state }, deviceCode) {
            return new Promise((resolve, reject) => {
                getDeviceDetail(deviceCode)
                    .then(response => {
                        const data = response.data;
                        resolve(data); 
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        addDevice({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                addDevice(payload)
                    .then(response => {
                        const data = response.data;
                        resolve(data); 
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateDevice({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                updateDevice(payload)
                    .then(response => {
                        const data = response.data;
                        resolve(data); 
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteDevice({ commit, state }, deviceCode) {
            return new Promise((resolve, reject) => {
                deleteDevice(deviceCode)
                    .then(response => {
                        const data = response.data;
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    };
  
  const state = () => ({});
  const getters = {};
  const mutations = {};
  
  export default {
      namespaced: true,
      state,
      getters,
      actions,
      mutations
  }
  
  