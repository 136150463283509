import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
import ja from 'vuetify/src/locale/ja.ts'
import en from 'vuetify/src/locale/en.ts'

Vue.use(Vuetify);
Vue.component('my-component', {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'ja'
    },
  },
})
export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  lang: {
    locales: {ja, en},
    current: 'ja,',
  },
  theme: {
    options: {
      customProperties: true,
    },
  }

});
