// eslint-disable-next-line no-unused-vars
import { getCbbSiteStation, getCbbSiteDeviceByStation, getCbbDeviceData, getCbbStationTSByDevice, getCbbOrgInfo, getCbbOrgInfoBySokGr, getCbbStationTSByFrameId } from "@/api/station";

const actions = {
    getCbbSiteStation({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbSiteStation(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbSiteDeviceByStation({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbSiteDeviceByStation(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbDeviceData({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbDeviceData(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbStationTSByDevice({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbStationTSByDevice(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbStationTSByFrameId({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbStationTSByFrameId(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbOrgInfo({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbOrgInfo(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCbbOrgInfoBySokGr({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbOrgInfoBySokGr(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

