import request from "../utils/request";

const CS_RM = "CS_RM";

export function getRoleList(payload) {
    return request({
        url: "/Roles/getListRole",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_RM
        }
    });
}
export function addRole(payload) {
    return request({
        url: "/Roles/addRole",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_RM
        }
    });
}
export function getRoleDetail(payload) {
    return request({
        url: "/Roles/getRoleDetail",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_RM
        }
    });
}
export function updateRole(payload) {
    return request({
        url: "/Roles/updateRole",
        method: "put",
        data: payload,
        headers: {
            'PAGE-CODE': CS_RM
        }
    });
}
export function deleteRole(roleId) {
    return request({
        url: "/Roles/deleteRole/" + roleId,
        method: "post",
        data: {},
        headers: {
            'PAGE-CODE': CS_RM
        }
    });
}

export function getMenuRole() {
  return request({
    url: "/Roles/getMenuRole/",
    method: "get",
    headers: {
      'PAGE-CODE': CS_RM
    }
  });
}


export function getRoleListBySiteId(payload) {
  return request({
    url: "/Roles/getListRoleBySiteId",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': payload.PAGE_CODE
    }
  });
}
