<template>
  <div>
    <div class="inner" style="padding: 10px">
      <span
        class="box-title box-title-sm box-title-lg"
        style="color: black"
        v-if="$i18n.locale === 'en'"
        >{{ item.legendName }}</span
      >
      <span
        class="box-title box-title-sm box-title-lg"
        style="color: black"
        v-else-if="$i18n.locale === 'jp'"
        >{{ item.legendName }}</span
      >
      <h4 style="color: #376CA5" class="font-weight-bolder">
        {{ humanReadableDirection }}
      </h4>
    </div>
    <div class="icon">
      <div class="d-flex justify-content-center wind-compass-wrapper">
        <div class="info-box1">
          <span class="info-box-icon bg-info1 elevation-1">
            <!-- EDIT HERE 24/09/2022 -->
            <section class="windCompass" ref="windCompass">
              <img
                src="../../assets/img/arrow.png"
                class="w-4 arrow"
                ref="arrow"
              />
            </section>
            <!-- END EDIT HERE 24/09/2022 -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "wind-compass",
  props: {
    windDirection: {
      type: Number,
      required: true,
    },
    arrowAnimationSpeed: {
      type: String,
      required: true,
    },
    speedData: {
      type: Number,
      required: true,
    },
    indexW: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required:true,
    }
  },
  data() {
    return {
      dispHoui16: [
        "北",
        "北北東",
        "北東",
        "東北東",
        "東",
        "東南東",
        "南東",
        "南南東",
        "南",
        "南南西",
        "南西",
        "西南西",
        "西",
        "西北西",
        "北西",
        "北北西",
      ],
    };
  },
  watch: {
    windDirection() {
      this.animateArrow();
    },
  },
  mounted() {
    this.animateArrow();
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
    }
  },
  computed: {
    humanReadableDate() {
      return moment().format("MM/DD/YYYY hh:mm");
    },
    speed() {
      return `${this.speedData}  km/h`;
    },
    humanReadableDirection() {
      // console.log("this.indexW>>>>", this.indexW);
      // return this.dispHoui16[this.indexW];
      if (this.windDirection < 11.25) {
        return this.$t("compass.N");
      }
      if (this.windDirection >= 11.25 && this.windDirection <= 33.75) {
        return this.$t("compass.NNE");
      }
      if (this.windDirection > 33.75 && this.windDirection <= 56.25) {
        return this.$t("compass.NE");
      }
      if (this.windDirection > 56.25 && this.windDirection <= 78.75) {
        return this.$t("compass.ENE");
      }
      if (this.windDirection > 78.75 && this.windDirection <= 101.25) {
        return this.$t("compass.E");
      }
      if (this.windDirection > 101.25 && this.windDirection <= 123.75) {
        return this.$t("compass.ESE");
      }
      if (this.windDirection > 123.75 && this.windDirection <= 146.25) {
        return this.$t("compass.SE");
      }
      if (this.windDirection > 146.25 && this.windDirection <= 168.75) {
        return this.$t("compass.SSE");
      }
      if (this.windDirection > 168.75 && this.windDirection <= 191.25) {
        return this.$t("compass.S");
      }
      if (this.windDirection > 191.25 && this.windDirection <= 213.75) {
        return this.$t("compass.SSW");
      }
      if (this.windDirection > 213.75 && this.windDirection <= 236.25) {
        return this.$t("compass.SW");
      }
      if (this.windDirection > 236.25 && this.windDirection <= 258.75) {
        return this.$t("compass.WSW");
      }
      if (this.windDirection > 258.75 && this.windDirection <= 281.25) {
        return this.$t("compass.W");
      }
      if (this.windDirection > 281.25 && this.windDirection <= 303.75) {
        return this.$t("compass.WNW");
      }
      if (this.windDirection > 303.75 && this.windDirection <= 326.25) {
        return this.$t("compass.NW");
      }
      if (this.windDirection > 326.25 && this.windDirection <= 348.75) {
        return this.$t("compass.NNW");
      }
      return this.$t("compass.N");
    },
  },
  methods: {
    animateArrow() {
      const windCompass = this.$refs.windCompass;

      // EDIT HERE 24/09/2022:
      // const compassDisplay = this.$refs.compassDisplay; 
      const transition = `${
        (parseInt(this.arrowAnimationSpeed) - 200) / 1000
      }s ease-out`;
      // EDIT HERE 24/09/2022:
      // compassDisplay.style.transition = transition;
      windCompass.style.transition = transition;
      let targetDirectionDegrees = this.windDirection;
      if (360 - targetDirectionDegrees < targetDirectionDegrees) {
        targetDirectionDegrees = -(360 - targetDirectionDegrees);
      }
      windCompass.style.transform = `rotate(${targetDirectionDegrees}deg)`;
      // EDIT HERE 24/09/2022
      // compassDisplay.style.transform = `rotate(${
      //   targetDirectionDegrees * -1
      // }deg)`;
    },
  },
};
</script>
<style lang="scss" scoped>
// EDIT HERE 24/09/2022
.windCompass {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
// END EDIT HERE 24/09/2022

.wind-compass-wrapper {
  bottom: 0;
  right: 0;
  position: absolute;
}

// EDIT HERE 24/09/2022
// .compassDisplay {
//   width: 100%;
//   height: 100%;
//   @apply rounded-full;
//   @apply border-4;
//   @apply border-gray-300;
//   @apply flex;
//   @apply flex-col;
//   @apply justify-center;
//   @apply items-center;
//   @apply mx-auto;
// }
// END EDIT HERE 24/09/2022

.info-box1 {
  box-shadow: none;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  display: inline-flex;
  min-height: 80px;
  margin-bottom: 0rem;
  padding: 0.5rem;
  position: relative;
}

.info-box1 .info-box-icon {
  border-radius: 4rem;
  -ms-flex-align: center;
  align-items: center;
  display: inline-flex;
  font-size: 1.875rem;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 80px;
  height: 80px;
}

// EDIT HERE 24/09/2022
.info-box-icon .windCompass {
  width: 100%;
  height: 30px;
}
// END EDIT HERE 24/09/2022

.bg-info1 {
  background-image: url("../../assets/img/wind.png"),
    url("../../assets/img/circle2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.arrow {
  transform: rotate(90deg) translateX(-50%);
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply left-0;
  width: 20px;
}
</style>
