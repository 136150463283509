import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import users from "@/store/users";
import sites from "@/store/sites";
import roles from "@/store/roles";
import device from "@/store/device";
import deviceMaster from '@/store/deviceMaster';
import dashboard from "@/store/dashboard";
import frame from "@/store/frame";
import station from "@/store/station";
import layout from "@/store/layout";
import warning from "@/store/warning";
import report from "@/store/report";
import file from "@/store/file";


Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    users,
    sites,
    roles,
    device,
    dashboard,
    frame,
    layout,
    station,
    warning,
    report,
    file,
    deviceMaster
  }
});

export default store
