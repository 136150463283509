// eslint-disable-next-line no-unused-vars
import {getRoleList, addRole, getRoleDetail, updateRole, deleteRole, getMenuRole, getRoleListBySiteId} from "@/api/roles";

const actions = {
    getRoleList({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            getRoleList(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    addRole({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            addRole(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getRoleDetail({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            getRoleDetail(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateRole({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            updateRole(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteRole({commit, state}, roleId) {
        return new Promise((resolve, reject) => {
            deleteRole(roleId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
  getMenuRole({commit, state}) {
    return new Promise((resolve, reject) => {
      getMenuRole()
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRoleListBySiteId({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getRoleListBySiteId(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

