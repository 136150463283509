// eslint-disable-next-line no-unused-vars
import {
  getUserList,
  updateUser,
  addUser,
  deleteUser,
  getUserManagementList,
  addUserManagement,
  updateUserManagement,
  deleteUserManagement,
  getDetailUserManagement,
  getAdminSiteList,
  adminSiteDetail,
  addAdminSite,
  updateAdminSite,
  DeleteAdminSite, getKnsUserDetail
} from "@/api/users";

const actions = {
  getUserList({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getUserList(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getKnsUserDetail({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getKnsUserDetail(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addUser({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      addUser(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateUser({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      updateUser(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteUser({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      deleteUser(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUserManagementList({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getUserManagementList(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addAdminSite({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      addAdminSite(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateAdminSite({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      updateAdminSite(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDetailUserManagement({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getDetailUserManagement(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addUserManagement({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      addUserManagement(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateUserManagement({commit, state}, payload, header) {
    return new Promise((resolve, reject) => {
      updateUserManagement(payload, header)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteUserManagement({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      deleteUserManagement(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAdminSiteList({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getAdminSiteList(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  adminSiteDetail({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      adminSiteDetail(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteAdminSite({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      DeleteAdminSite(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const state = () => ({

});
const getters = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

