<template>
  <div class="" style="background-color: #fff">
    <div class="">
      <div class="d-flex breadcrumb-iphone">
        <div class="">
          <h2 class="ml-3 pt-1 font-weight-bolder text-navigator">{{ $t(name) }}</h2>
        </div>
        <div class="pt-0 pb-0 ml-auto mr-3 breadcrumb-items-iphone">
          <ol class="breadcrumb mb-0" style="background-color:  #fff;">
            <li class="breadcrumb-item ">
              <p style="color: #0d6efd">{{ $t("labels.home") }}</p>
            </li>
            <li class="breadcrumb-item active">{{ $t(name) }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigatorMain",
  props: ['name'],
  data: () => ({
    menu: MENU,
    link: '',
    isExpandable: false,
  }),
  mounted() {
    this.link = window.location.pathname;
    this.getNamePath(this.link);
  },
  computed: {
    /* eslint-disable */
    reloadname() {
      this.link = window.location.pathname;
      this.getNamePath(this.link);
    }
  },
  methods: {
    getNamePath(linkPath) {
      for (var i = 0; i < this.menu.length; i++) {
        this.isExpandable =
          this.menu[i] &&
          this.menu[i].children &&
          this.menu[i].children.length > 0;
        if (this.isExpandable) {
          for (var j = 0; j < this.menu[i].children.length; j++) {
            if (this.menu[i].children[j].path === linkPath) {
              this.name = this.menu[i].children[j].name;
            }
          }
        } else {
          if (this.menu[i].path === linkPath) {
            this.name = this.menu[i].name;
          }
        }

      }
    }
  }
}
export const MENU = [];
</script>

<style lang="scss" scoped>
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .text-navigator {
   font-size: 25px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}


/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* your css rules for ipad landscape */

}

@media (max-width: 540px){  
  .text-navigator {
   font-size: 15px;
   margin-top: 9px;
  }
  .breadcrumb-items-iphone {
    font-size: 12px;
  }
  .breadcrumb-iphone {
    flex-direction: column !important;
  }
}

@media (max-height: 540px){
  .text-navigator {
   font-size: 25px;
   margin-top: 9px;
  }
  .breadcrumb-items-iphone {
    font-size: 20px;
  }
  .breadcrumb-iphone {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 640px){  
  .text-navigator {
   font-size: 20px;
   margin-top: 9px;
  }
  .breadcrumb-items-iphone {
    font-size: 20px;
  }
}

</style>
