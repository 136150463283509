<template>
  <li class="nav-item dropdown" ref="dropdown">
    <button class="nav-link pb-0 pr-0" @click="toggleDropdown">
      <slot name="dropdown-button"></slot>
    </button>

    <DropdownMenu v-if="isOpen" :size="size">
      <slot name="dropdown-menu"></slot>
    </DropdownMenu>
  </li>
</template>

<script>
import DropdownMenu from './dropdown-menu.vue'
export default {
  name: "DropDown",
  components: {
    DropdownMenu
  },
  props: {
    size: String,
    nameComponent : String,
  },
  data: () => ({
    dropdownElement: null,
    isOpen: false,
  }),
  computed: {
    // a computed getter
    fixStyles: function () {
      if (this.dropdownMenuElement) {
        const windowWidth = document.getElementById("app").offsetWidth;
        const offsetLeft =
          this.dropdownMenuElement.getBoundingClientRect().left;
        const offsetWidth = this.dropdownMenuElement.offsetWidth;
        const visiblePart = windowWidth - offsetLeft;

        if (offsetLeft < 0) {
          return {
            left: "inherit",
            right: `${offsetLeft - 5}px`,
          };
        } else if (visiblePart < offsetWidth) {
          return { left: "inherit", right: `0px` };
        }
        return { left: "inherit", right: `0px` };
      }
      return { left: "inherit", right: `0px` };
    },
  },
  methods: {
    documentClick(event) {
      const target = event.target;
      if (
        this.dropdownElement !== target &&
        !this.dropdownElement.contains(target)
      ) {
        this.isOpen = false;
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    this.dropdownElement = this.$refs.dropdown;
    document.addEventListener("click", this.documentClick);
  },
  unmounted() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}
</style>