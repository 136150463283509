<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t('alarmSetting.device')
                              }}</span>
            </div>
            <div class="input-group-append">
              <v-autocomplete :items="deviceList" v-model="selectedDevice" dense outlined
                              item-value="code"
                              v-on:change="handleSelectDevice">
                <template v-slot:[`item`]="{ item }">
<!--                  <option :key="item.code" :value="item.code" v-if="$i18n.locale === 'en'">{{ item.nameEn }}</option>-->
<!--                  <option :key="item.code" :value="item.code" v-if="$i18n.locale === 'jp'">{{ item.nameJp }}</option>-->
                  <span :key="item.code" :value="item.code">{{ $i18n.locale !== 'jp' ? item.nameEn : item.nameJp }}</span>
                </template>
                <template #selection="{item}">
<!--                  <option v-if="$i18n.locale === 'en'">{{ item.nameEn }}</option>-->
<!--                  <option v-if="$i18n.locale === 'jp'">{{ item.nameJp }}</option>-->
                  <span >{{ $i18n.locale !== 'jp' ? item.nameEn : item.nameJp }}</span>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <div v-if="!isTsDevice" class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t('alarmSetting.properties')
                              }}</span>
            </div>
            <div class="input-group-append">
              <v-select :disabled="selectedDevice === null" v-model="selectedProperty"
                        v-on:change="handleLoadWarningTable"
                        item-value="dataCode"
                        :items="deviceTypes" dense
                        outlined>
                <template v-slot:[`item`]="{ item }">
<!--                  <option :key="item.dataCode" :value="item.dataCode" v-if="$i18n.locale === 'en'">{{-->
<!--                      item.nameEn-->
<!--                    }}-->
<!--                  </option>-->
<!--                  <option :key="item.dataCode" :value="item.dataCode" v-if="$i18n.locale === 'jp'">{{-->
<!--                      item.nameJp-->
<!--                    }}-->
<!--                  </option>-->
                  <span :key="item.dataCode" :value="item.dataCode">{{ $i18n.locale !== 'jp' ? item.nameEn : item.nameJp }}</span>
                </template>
                <template #selection="{item}">
<!--                  <option v-if="$i18n.locale === 'en'">{{ item.nameEn }}</option>-->
<!--                  <option v-if="$i18n.locale === 'jp'">{{ item.nameJp }}</option>-->
                  <span >{{ $i18n.locale !== 'jp' ? item.nameEn : item.nameJp }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div v-else class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t('alarmSetting.stationName')
                              }}</span>
            </div>
            <div class="input-group-append">
              <v-select :disabled="selectedDevice === null" v-model="stationMainTS"
                        v-on:change="handleChangeStationMainTS(e)"
                        item-value="value"
                        :items="stationList" dense
                        outlined>
                <template v-slot:[`item`]="{ item }">
                  <span :key="item.value" :value="item.value">{{ item.label+`(${selectedDevice})` }}</span>
                </template>
                <template #selection="{item}">
                  <span >{{ $i18n.locale !== 'jp' ? item.label : item.label }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div v-if="isTsDevice" class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('exportData.stationTs')
                  }}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="tsStationLst" v-model="tsStationSearch" @change="handleChangeTsStation(e)"
                        :item-text="($i18n.locale === 'en') ? 'name' : 'nameJp'" item-value="value" dense outlined
                        :disabled="stationMainTS == null">
              </v-select>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{$t("exportData.orgNo")}}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="itemOrgNo" v-model="orgNoSearch" :disabled="tsStationSearch == null" @change="handleChangeOrgNo(e)"
                              dense :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'" item-value="orgNo" outlined deletable-chips>
              </v-select>
            </div>
          </div>
        </div>
        <div v-if="isTsDevice" class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('alarmSetting.properties')
                  }}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="propertiesTsLst" v-model="propertiesTs" @change="handleLoadWarningTable"
              :item-text="($i18n.locale === 'en') ? 'nameEn' : 'nameJp'" item-value="value" dense outlined
                        :disabled="orgNoSearch == null">
              </v-select>
            </div>
          </div>
        </div>

      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table responsive bordered
                        :key="index"
                        reactive :headers.sync="headersComputed" :items.sync="itemsSettings"
                        :footer-props="{
                           'items-per-page-options': [15, 30, 50],
                           'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
                        class="elevation-2" mobile-breakpoint="0">
            <template slot="no-data">
              {{ $t("notification.noData") }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="primary"
                  class="mr-2 patrolLight-button-container"
                  style="background-color: #3d76ad"
                  @click="handleOpenPatrolLightModal"
                >
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="mr-1"/>
                  <span style="font-size: 17px" class="font-weight-bold patrolLight-button">{{
                      $t("labels.patrolLight")
                    }}</span>
                </v-btn>
                <v-btn class="add-button-container" depressed color="primary" style="background-color: #3d76ad"
                       @click="handleAddNewWarning()" :disabled="!isFullDataLineTable" v-if="ACTION === 'OPS'">
                  <v-icon color="white" size="20" class="mr-2 dark mt-0 add-button">
                    mdi-plus-box
                  </v-icon>
                  <span class="add-button" style="font-size: 20px">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.id`]="{ id }">
              <p hidden>{{ id }}</p>
            </template>
            <template v-slot:[`item.display`]="{ item }">
              <v-checkbox v-bind:true-value="1" v-bind:false-value="0" style="padding-left:15px !important;"
                          v-model="item.display"
                          :disabled="!item.isEdit"></v-checkbox>
            </template>
            <template v-slot:[`item.siteStationDeviceId`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs"
                    v-on="on">
                    <v-autocomplete style="margin-top:25px !important;" :items="stationList"
                      @change="handleChangeStation(item)"
                      v-model="item.siteStationDeviceId"
                      item-value="valueInt"
                      item-text="label"
                      key="valueInt"
                      :disabled="!item.isEdit" dense outlined>
                      <template v-slot:[`item`]="{ item }">
                        <span>{{ $t(item.label) }}</span>
                      </template>
                    </v-autocomplete>
                  </div>
                </template>
                <span style="font-size: 1.2rem">{{ getNameStation(item.siteStationDeviceId) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.channelNo`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs"
                    v-on="on">
                    <v-select style="max-width: 150px;" v-model="item.channelNo" :items="item.itemChannelList"
                        @change="handleChangeChannelNo(item)"
                        item-text="name" item-value="value"
                        hide-details="true" outlined dense :disabled="!item.isEdit || channelFlg == 0 || item.siteStationDeviceId == null">
                      <template v-slot:[`item`]="{ item }">
                        <span>{{ $t(item.name) }}</span>
                      </template>
                    </v-select>
                  </div>
                </template>
                <span style="font-size: 1.2rem">{{ getNameChannel(item.itemChannelList, item.channelNo, item.siteStationId) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.alarmCategory`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs"
                    v-on="on">
                    <v-autocomplete style="margin-top:25px !important;" :items="categoryList"
                      :disabled="!item.isEdit" v-model="item.alarmCategory" dense outlined item-value="id" :item-text="$i18n.locale !== 'jp' ? 'nameEn' : 'nameJp'">
                    </v-autocomplete>
                  </div>
                </template>
                <span style="font-size: 1.2rem">{{ getNameAlarm(item.alarmCategory) }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.limitation`]="{ item }">
              <v-select style="margin-top:25px !important;" :items="limitListComputed" :disabled="!item.isEdit"
                        item-value="value" item-text="text" key="value"
                        v-model="item.limitation" dense outlined>
              </v-select>
            </template>
            <template v-slot:[`item.primaryThreshold`]="{ item }">
              <v-text-field type="text"
                            style="margin-top:25px !important;" outlined dense :disabled="!item.isEdit"
                            maxlength=5
                            :suffix="unitValue"
                            @keypress="isDecimalNumber($event)"
                            @change="formatFloatNumber($event, item.index, 1)"
                            v-model="item.primaryThreshold"
                            :value="item.primaryThreshold"></v-text-field>
            </template>
            <template v-slot:[`item.secondThreshold`]="{ item }">
              <v-text-field type="text"
                            style="margin-top:25px !important;" outlined dense :disabled="!item.isEdit"
                            maxlength=5
                            :suffix="unitValue"
                            @keypress="isDecimalNumber($event)"
                            @change="formatFloatNumber($event, item.index, 2)"
                            v-model="item.secondThreshold"
                            :value="item.secondThreshold"></v-text-field>
            </template>
            <template v-slot:[`item.tertiaryThreshold`]="{ item }">
              <v-text-field type="text"
                            style="margin-top:25px !important;" outlined dense :disabled="!item.isEdit"
                            maxlength=5
                            :suffix="unitValue"
                            @keypress="isDecimalNumber($event)"
                            @change="formatFloatNumber($event, item.index, 3)"
                            v-model="item.tertiaryThreshold"
                            :value="item.tertiaryThreshold"></v-text-field>
            </template>
            <template v-slot:[`item.intervalSetting`]="{ item }">
              <v-text-field style="margin-top:25px !important;" outlined dense :value="item.intervalSetting"
                            type="number"
                            v-model="item.intervalSetting" :suffix="$t('alarmSetting.min')"
                            @keypress="onlyNumberKey($event)"
                            min="1"
                            :disabled="!item.isEdit || item.alarmCategory === 7"></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="!item.isNew && !item.isEdit" style="display: flex !important;">
                <button :disabled="isManipulating  || isTsDevice" class="m-1" v-on:click="handleUpdateWarning(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button :disabled="isManipulating || isTsDevice" class="m-1" @click="handleRemoveWarning(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
              <div v-else-if="(item.isNew || item.isEdit) && ACTION === 'OPS'" style="display: flex !important;">
                <button class="m-1" @click="handleSaveWarning(item)">
                  <i class="fas fa fa-check-circle text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="handleCloseSavingWarning(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-times-circle text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <PatrolLightModal />
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  getCookiePrivileges,
  getCookieSiteUser,
  setCurrentPageCode
} from "@/utils/auth";
import i18nConfig from "@/i18n.lang";
import _ from 'lodash';
import {ResponseCode, NullInt } from "@/constants/constants"
import {EventBus} from "@/utils/eventBus";
import PatrolLightModal from "@/modals/WarningSetting/PatrolLightModal.vue";

export default {
  name: "AlarmSettings",
  components: {
    PatrolLightModal
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50"},
        {text: this.$t('alarmSetting.active'), align: 'center', sortable: false, value: 'display', width: "100"},
        {text: this.$t('alarmSetting.station'), value: 'siteStationDeviceId', align: 'center', sortable: false, width: "200"},
        {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false, width: '150'},
        {text: this.$t('alarmSetting.alarmCategory'), value: 'alarmCategory', align: 'center', sortable: false,},
        {text: this.$t('alarmSetting.limitation'), value: 'limitation', align: 'center', sortable: false,},
        {text: this.$t('alarmSetting.primaryThreshold'), value: 'primaryThreshold', align: 'center', sortable: false},
        {
          text: this.$t('alarmSetting.secondaryThreshold'),
          value: 'secondThreshold',
          sortable: false,
          align: 'center'
        },
        {text: this.$t('alarmSetting.tertiaryThreshold'), value: 'tertiaryThreshold', sortable: false, align: 'center'},
        {text: this.$t('alarmSetting.alarmEmail'), value: 'intervalSetting', sortable: false, align: 'center'},
        {text: this.$t('alarmSetting.actions'), value: 'actions', sortable: false, align: 'center'}
      ];
    }
  },
  data() {
    return {
      isManipulating: false,
      loading: false,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_WS',
      ACTION: 'OPS',
      index: 0,
      CURRENT_SITE: null,
      selectedDevice: null,
      selectedProperty: null,
      typeSelected: 'rainfall',
      suffixValue: 'mm',
      currentLocale: this.$i18n.locale,
      deviceList: [],
      deviceTypes: [],
      stationListTs:[],
      isTsDevice : false,
      headers: [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50"},
        {text: this.$t('alarmSetting.active'), align: 'center', sortable: false, value: 'display', width: "100"},
        {text: this.$t('alarmSetting.station'), value: 'siteStationDeviceId', align: 'center', sortable: false, width: "200"},
        {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false, width: '150'},
        {text: this.$t('alarmSetting.alarmCategory'), value: 'alarmCategory', align: 'center', sortable: false,},
        {text: this.$t('alarmSetting.limitation'), value: 'limitation', align: 'center', sortable: false,},
        {text: this.$t('alarmSetting.primaryThreshold'), value: 'primaryThreshold', align: 'center', sortable: false},
        {
          text: this.$t('alarmSetting.secondaryThreshold'),
          value: 'secondThreshold',
          sortable: false,
          align: 'center'
        },
        {text: this.$t('alarmSetting.tertiaryThreshold'), value: 'tertiaryThreshold', sortable: false, align: 'center'},
        {text: this.$t('alarmSetting.alarmEmail'), value: 'intervalSetting', sortable: false, align: 'center'},
        {text: this.$t('alarmSetting.actions'), value: 'actions', sortable: false, align: 'center'}
      ],
      stationList: [],
      itemsSettings: [],
      backUpItemsSettings:[],
      selectedTemporaryWaring: null,
      unitValue: "",
      channelFlg: 0,
      categoryList: [],
      stationMainTS: null,
      tsStationLst: [],
      tsStationSearch: null,
      itemOrgNo:[],
      orgNoSearch: null,
      propertiesTsLst: [],
      propertiesTs: null
    }
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    },
    isFullDataLineTable() {
      if (this.selectedProperty === null) {
        return false;
      } else {
        if (this.itemsSettings.length > 0) {
          let countManipulating = 0;
          this.itemsSettings.forEach(element => {
            if(element?.isNew || element?.isEdit) {
              countManipulating++;
            }
          });
          return countManipulating === 0;
        } else {
          return true;
        }
      }
    },
    // alarmCategoryListComputed() {
    //   var categoryList = [];
    //   this.getWarningCategories(this.selectedProperty).then((response) => {
    //     if (response.code === 0) {
    //       categoryList = response.data;
    //     }
    //   }).catch(error => {
    //     if(error.code != undefined)
    //       this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
    //   }).finally(() => {
    //     // console.log('[handleSelectDevice]');
    //   });
    //   return categoryList;
      // if (this?.selectedDevice === 100 && this?.selectedProperty === "rainfall") {
      //   return [
      //     {value: 4, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(1hr)')},
      //     // {value: 5, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(3hr)')},
      //     {value: 6, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(24hr)')},
      //     {value: 7, text: this.$t('alarmSetting.alarmCategoryList.consecutiveCumulativeTotal')},
      //   ]
      // } else if (this?.selectedDevice === 100 && this?.selectedProperty === "wind_speed_avg" ||
      //           this?.selectedDevice === 100 && this?.selectedProperty === "wind_speed_max") {
      //   return [
      //       {value: 1, text: this.$t('alarmSetting.alarmCategoryList.avgWarning')},
      //       {value: 2, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(1s)')},
      //     ]
      // } else if (this.channelFlg == 1) {
      //   return [
      //     {value: 2, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(1s)')},
      //     {value: 3, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(10m)')},
      //     // {value: 4, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(1hr)')},
      //     // {value: 5, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(3hr)')},
      //     // {value: 6, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(24hr)')},
      //   ]
      // } else {
      //    return [
      //     {value: 2, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(1s)')},
      //     {value: 3, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(10m)')},
      //   ]
      // }
    // },
    limitListComputed() {
      return [
        {value: 1, text: this.$t('alarmSetting.limitList.min')},
        {value: 2, text: this.$t('alarmSetting.limitList.max')}
      ]
    },
    headersComputed(){
      // const channelNoColumn = {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false,}
      // const indexColumnChannelNo = this.headers.findIndex((e => e.value === 'channelNo'))
      // const indexColumnSiteStationDeviceId = this.headers.findIndex((e => e.value === 'siteStationDeviceId'))
      if(this.channelFlg == 1){
        return this.headers;
      }
      else{
        return this.headers.filter(x=> x.value !== 'channelNo');
      }
    }
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    this.CURRENT_SITE = getCookieSiteUser();

    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    this.handleInitializeDeviceList();
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/warning/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/warning/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods: {
    ...mapActions('warning', {
        getDeviceList: 'getDeviceList',
        getDeviceProperty: 'getDeviceProperty',
        getStation: 'getStation',
        getWarningList: 'getWarningList',
        addWarning: 'addWarning',
        updateWarning: 'updateWarning',
        removeWarning: 'removeWarning',
        getWarningCategories: "getWarningCategories"
      },
    ),
    ...mapActions("sites", {
        getListChannel: "getListChannel",
    }),
    ...mapActions("station", {
      getCbbStationTSByDevice: "getCbbStationTSByDevice",
      getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr"
    }),
    handleUpdateWarning(item) {
      this.isManipulating = true;
      let itemSelected = this.itemsSettings.findIndex(x => x.index === item.index);
      this.selectedTemporaryWaring = this.itemsSettings[itemSelected];
      this.itemsSettings[itemSelected].isEdit = true;
      this.$set(this.itemsSettings, itemSelected, this.itemsSettings[itemSelected]);
    },
    handleSaveWarning(item) {
      let itemSelected = this.itemsSettings.findIndex(x => x.index === item.index);
      item.deviceCode = this.selectedDevice;
      item.dataCode = this.selectedProperty;

      if (this.itemsSettings[itemSelected].siteStationDeviceId === undefined || this.itemsSettings[itemSelected].siteStationDeviceId === null) {
        this.commonErrorVue(this.ERROR_CODE['E_063_01_01']);
        return;
      }
      if (this.channelFlg == 1 && (this.itemsSettings[itemSelected].channelNo === undefined || this.itemsSettings[itemSelected].channelNo === null)) {
        this.commonErrorVue(this.ERROR_CODE['E_063_01_08']);
        return;
      }

      if (this.itemsSettings[itemSelected].alarmCategory === undefined || this.itemsSettings[itemSelected].alarmCategory === null) {
        this.commonErrorVue(this.ERROR_CODE['E_063_01_02']);
        return;
      }

      if (this.itemsSettings[itemSelected].limitation === undefined || this.itemsSettings[itemSelected].limitation === null) {
        this.commonErrorVue(this.ERROR_CODE['E_063_01_03']);
        return;
      }

      this.itemsSettings[itemSelected].primaryThreshold = this.convertStringToFloat(this.itemsSettings[itemSelected].primaryThreshold)
      this.itemsSettings[itemSelected].secondThreshold = this.convertStringToFloat(this.itemsSettings[itemSelected].secondThreshold)
      this.itemsSettings[itemSelected].tertiaryThreshold = this.convertStringToFloat(this.itemsSettings[itemSelected].tertiaryThreshold)

      if (this.itemsSettings[itemSelected].primaryThreshold === null && this.itemsSettings[itemSelected].secondThreshold === null
        && this.itemsSettings[itemSelected].tertiaryThreshold === null) {
        this.commonErrorVue(this.ERROR_CODE['E_063_01_04']);
        return;
      }

      if ((this.itemsSettings[itemSelected].intervalSetting === undefined || this.itemsSettings[itemSelected].intervalSetting === null)) {
        // this.commonErrorVue(this.ERROR_CODE['E_063_01_07']);
        // return;
        this.itemsSettings[itemSelected].intervalSetting = null;
      }
      else{
        if (this.itemsSettings[itemSelected].intervalSetting !== "" && this.itemsSettings[itemSelected].intervalSetting !== null && this.itemsSettings[itemSelected].intervalSetting < 1) {
          this.commonErrorVue(this.ERROR_CODE['E_063_01_07_01']);
          return;
        }
      }
      this.loading = true;

      if (item.isEdit === true && item.isNew === true) {
        this.addWarning({data: item, siteId: this.CURRENT_SITE}).then((response) => {
          if (response.code === ResponseCode.SAVE_SUCCESS) {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'success');
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        }).finally(() => {
          this.loading = false;
          this.handleGetWarningList();
        })
      } else {
        this.updateWarning({data: item, siteId: this.CURRENT_SITE}).then((response) => {
          if (response.code === ResponseCode.SAVE_SUCCESS) {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'success');

          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        }).finally(() => {
          this.loading = false;
            this.handleGetWarningList();
        })
      }


    },
    handleCloseSavingWarning(item) {
      let itemSelected = this.itemsSettings.findIndex(x => x.index === item.index);
      if (item.isEdit !== undefined && item.isEdit === true && this.selectedTemporaryWaring !== null) {
        var oldData = _.cloneDeep(this.backUpItemsSettings[itemSelected]);
        this.$set(this.itemsSettings, itemSelected, oldData);
        this.itemsSettings = _.cloneDeep(this.backUpItemsSettings)
      }
      if (item.isNew !== undefined && item.isNew === true) {
        this.itemsSettings.splice(itemSelected, 1);
      }
      console.log( this.backUpItemsSettings);
      this.isManipulating = false;
    },
    handleRemoveWarning(item) {
      this.commonConfirmVue(this.ERROR_CODE['CF_063_01_02'], () => {
        this.removeWarning({Id: item.id, siteId: this.CURRENT_SITE}).then((response) => {
          if (response.code === ResponseCode.DELETE_SUCCESS) {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'success');
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        }).finally(() => {
          this.handleGetWarningList();
        })
      });
    },
    handleAddNewWarning() {
      this.isManipulating = true;
      let element = {};
      element.index = [...this.itemsSettings].length + 1;
      element.isNew = true;
      element.isEdit = true;
      element.siteStationDeviceId = null;
      element.channelNo = null;
      element.itemChannelList = [];
      element.alarmCategory = null;
      element.limitation = null;
      this.itemsSettings.push(element);
      this.handleGetListStation();
    },
    handleLoadWarningTable(type) {
      this.isManipulating = false;
      const dataObj = this.deviceTypes.find(element => {
        return element.dataCode.toString() === type.toString() ? element : '';
      });
      this.unitValue = dataObj != null ? dataObj.unit : "";
      if(!this.isTsDevice){
        this.handleGetListStation();
      }
      this.handleSelecteProperty();
      this.handleGetWarningList();
    },
    handleGetWarningList() {
      this.isManipulating = false;
      this.loading = true;
      this.getWarningList({
        siteId: this.CURRENT_SITE,
        deviceCode: this.selectedDevice,
        properties: this.selectedProperty,
        stationId: this.stationMainTS,
        tsStation: this.tsStationSearch,
        orgNo: this.orgNoSearch,
        propertiesTs: this.propertiesTs,
        isTsDevice : this.isTsDevice
      })
        .then((response) => {
          if (response.code === 0) {
            this.itemsSettings = response.data;
            for (let i = 0; i < this.itemsSettings.length; i++) {
              this.itemsSettings[i].index = i;
              this.itemsSettings[i].isEdit = false;
              this.itemsSettings[i].isNew = false;
              this.itemsSettings[i].itemChannelList = this.itemsSettings[i].itemChannelList.map((item, index)=>{
              var channel = {value: item.channelNo, name: item.channelName};
              return channel;
            });
            }
            Object.assign(this.backUpItemsSettings, this.itemsSettings)
            this.backUpItemsSettings = _.cloneDeep(this.itemsSettings)
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        this.loading = false;
      })
    },
    handleInitializeDeviceList() {
      this.getDeviceList(this.CURRENT_SITE).then((response) => {
        if (response.code === 0) {
          this.deviceList = response.data;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        // console.log('[handleInitializeDeviceList]');
      })
    },
    handleSelectDevice(value) {
      this.itemsSettings = [];
      this.selectedProperty = null;
      this.stationMainTS  = null;
      this.tsStationSearch  = null;
      this.orgNoSearch  = null;
      this.propertiesTs  = null;
      const deviceType = this.deviceList.find(item => item.code === value)?.type
      if(deviceType === 1){
        this.isTsDevice = true
        this.handleGetListStation();
      }
      else{
        this.isTsDevice = false
        this.getDeviceProperty(this.selectedDevice).then((response) => {
        if (response.code === 0) {
          this.deviceTypes = response.data;
        }
        }).catch(error => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        }).finally(() => {
          // console.log('[handleSelectDevice]');
        })
      }
      this.channelFlg = this.deviceList?.find(x => x.code === this.selectedDevice)?.channelFlg;
    },
    handleSelecteProperty() {
      this.getWarningCategories(this.selectedProperty).then((response) => {
        if (response.code === 0) {
          this.categoryList = response.data;
        }
        if(this.isTsDevice){
          this.categoryList = [{id: 2,nameEn: "Instantaneous Value (1s)",nameJp:"瞬時値 (1秒毎)"}]
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        // console.log('[handleSelectDevice]');
      })
      this.channelFlg = this.deviceList?.find(x => x.code === this.selectedDevice)?.channelFlg;
    },
    handleGetListStation() {
      this.getStation({siteId: this.CURRENT_SITE, deviceCode: this.selectedDevice}).then((response) => {
        if (response.code === 0) {

          this.stationList = response.data;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        // console.log('[handleGetListStation]');

      })
    },
    convertStringToFloat(data) {
      var dataValue = parseFloat(data).toFixed(1)
        if ( isNaN(dataValue) ) {
          return null;
        } else {
          return dataValue;
        }
    },
    onlyNumberKey: function(evt) {
        console.log(evt);
        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
          evt.preventDefault();
        return true;
    },
    isDecimalNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatFloatNumber: function(value, index, type) {
      let decimalValue = this.convertStringToFloat(value);
      if (decimalValue) {
        decimalValue = decimalValue.replace(',', '.')
      }
      this.itemsSettings.forEach(element => {
        if(element?.index === index) {
          if(type === 1) {
            element.primaryThreshold = decimalValue;
          } else if (type === 2 ){
            element.secondThreshold = decimalValue;
          } else {
            element.tertiaryThreshold = decimalValue;
          }
        }
      });
    },
    handleChangeStation(item) {
      if(!this.isTsDevice){
        var siteStationId = this.stationList?.find(x=> x.valueInt === item.siteStationDeviceId)?.value;
        this.handleGetListChannel(item, siteStationId, this.selectedDevice);
      }
    },
    handleGetListChannel(item, siteStationId, deviceCode){
      let siteID = getCookieSiteUser();
      this.loading = true;
      const request = { siteId: siteID,siteStationId: siteStationId,deviceCode: deviceCode };
      this.getListChannel(request).then((response) => {
          if (response.code === ResponseCode.SUCCESS) {
            let itemSelected = this.itemsSettings.findIndex(x => x.index === item.index);
            this.itemsSettings[itemSelected].itemChannelList = response.data.map((i, index)=>{
              var channel = {value: i.channelNo, name: i.channelName};
              return channel;
            });
            this.$set(this.itemsSettings, itemSelected, this.itemsSettings[itemSelected]);
          }
          }).catch((error) => {
          }).finally(() => {
            this.loading = false;
          })
    },
    getNameStation(id) {
      let vm = this
      if (id) {
        let value = vm.stationList.find(x => x.valueInt === id)?.label;
        return value
      }
    },
    getNameChannel(list, id, station){
      console.log(id+" "+station);
      if (id && station && list.length >= 1) {
        let value = list.find(x => x.value === id && x.value === station)?.name;
        return value
      }
    },
    getNameAlarm(id){
      if (id) {
        let category = this.categoryList.find(x => x.id === id);
        let value = this.$i18n.locale !== 'jp' ? category?.nameEn : category?.nameJp;
        return value
      }
    },
    handleChangeStationMainTS(data){
      this.tsStationSearch  = null;
      this.orgNoSearch  = null;
      this.propertiesTs  = null;
      this.handleGetCbbStationTS(this.stationMainTS, this.selectedDevice, null)
    },
    handleGetCbbStationTS(stationId, deviceCode, orgNo) {
      this.loading = true;
      let siteID = getCookieSiteUser();
      this.getCbbStationTSByDevice({DeviceCode: deviceCode, SiteId: siteID, StationId: stationId, OrgNo: orgNo})
        .then(response => {
          if (response.code === 0) {
            this.tsStationLst = response.data.map((i, index) => {
              var dataI = {value: i.id, name: i.stationName, nameJp: i.stationName };
              return dataI;
            });
            // this.tsStationLst.unshift({value: 'all', name: 'All Points', nameJp: '全測点' })
            // this.tsStationSearch = 'all';
            // this.handleChangeTsStation();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleChangeTsStation(){
      this.loading = true;
      this.orgNoSearch  = null;
      this.propertiesTs  = null;
      let rq = {
        SiteId: getCookieSiteUser(),
        StationId: this.stationMainTS,
        DeviceCode: this.selectedDevice,
        SokGrTs: this.tsStationSearch
      }
      this.getCbbOrgInfoBySokGr(rq)
        .then(response => {
          if (response.code === 0) {
            this.itemOrgNo = response.data;
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleChangeOrgNo(item){
      const orgData = this.itemOrgNo?.find(x=>x.orgNo === this.orgNoSearch)?.orgData;
      this.propertiesTs  = null;
      this.propertiesTsLst = orgData.map((i, index) => {
        var dataI = {value: i.dataMapping, nameEn: i.headerEn, nameJp: i.headerJp, unit: i.unit, disable: false, icon: null};
        return dataI;
      });
    },
    handleOpenPatrolLightModal(){
      EventBus.$emit("handleOpenPatrolLightModal",{
        flag : true
      })
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/warning/${currentLang}.js` )
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/warning/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
    // handler($event) {
    //   console.log('[handleUpdateWarning]', $event);
    // }
  }
}
</script>

<style lang="scss" scoped>
.titleType {
  background-color: #3c8dbc;
  height: 40px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  padding-left: 8px;
  border-radius: 6px
}

.colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}

.btn-add {
  background-color: #0d6efd !important;
  color: white !important;
}

.my_border {
  border-left: 5px solid red;
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}


.custom-select {
  background: none;
  background-color: white;
}

.color-red {
  color: red;
}

@media only screen and (max-width: 540px) and (max-height: 900px) {
  .add-button {
    font-size: 10px !important;
  }
  .patrolLight-button {
    font-size: 8px !important;
  }
  .patrolLight-button-container {
    height: 1.5rem !important;
  }
  .add-button-container {
    height: 1.5rem !important;
    width: 0.5rem !important;
  }
}
::v-deep{
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 1rem !important;
    height: 26px;
    padding: 0px 6px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 6.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
