<template>
  <!-- Navbar -->
  <nav
    id="main-header"
    class="main-header navbar navbar-expand"
    style="background-color: #0761AD"
    :class="{ 'main-header': headerElement }"
  >
    <ul class="navbar-nav toggle-menu" style="padding-left: 0">
      <li class="nav-item">
        <button class="nav-link" role="button" @click="onToggleMenuSidebar">
          <i class="fas fa-bars" style="color: #FFFFFF"></i>
        </button>
      </li>
    </ul>
    <ul class="navbar-nav hide-logo-ipad">
      <img
        v-if="locale === 'jp'"
        class="m-auto logo-desktop logo-ipad"
        src="@/assets/img/bar_jp.png"
        alt=""
      />
      <img
        v-else
        class="m-auto logo-desktop logo-ipad"
        src="@/assets/img/bar_en.png"
        alt=""
      />
    </ul>
    <ul class="navbar-nav ml-auto header-buttons">
      <DigitalClock />
      <div class="d-md-none w-100"></div> 
      <ChangeSite v-if="menuAccess !== '2'" class="d-md-block changeSite" />
      <div class="d-md-none w-100"></div>
      <img
          class="mt-1 logo-iphone"
          src="@/assets/img/bar_mobile.jpg"
        /> 
      <FormatSize class="ml-auto mr-1"/>
      <LanguagesDropdown class="mr-1 flag-button"/>
      <NotificationsDropDown  v-if="menuAccess !== '2'" />
      <UserDropdown ></UserDropdown>
    </ul>
  </nav>
</template>


<script>
import UserDropdown from "./user.vue";
import NotificationsDropDown from "./notification.vue";
import LanguagesDropdown from "./languages.vue";
import FormatSize from "./formatsize.vue"
import ChangeSite from "@/modules/header/changeSite";
import DigitalClock from "@/components/DigitalClock/digitalClock";
import {cookiesGetI18Lang, cookiesSetI18Lang, getMenuAccesses} from "@/utils/auth";
import i18nConfig from "@/i18n.lang";

export default {
  name: "MenuHeader",
  components: {ChangeSite, UserDropdown, NotificationsDropDown, LanguagesDropdown, FormatSize, DigitalClock},
  created() {
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }
    this.locale = this.$i18n.locale;
    this.menuAccess = getMenuAccesses()
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  mounted() {
    this.headerElement = document.getElementById("main-header");
  },
  data: () => ({
    headerElement: null,
    isClosed: false,
    menuAccess: '',
    locale: 'jp'
  }),
  methods: {

    onToggleMenuSidebar() {
      document.body.classList.toggle('sidebar-collapse')
      let listDiv = document.body.classList
      let divHeader = document.getElementById('main-header')
      let linkHeader = document.getElementById('link-display')
      if (!listDiv[1]) {
        if(divHeader != undefined && linkHeader != undefined) {
          divHeader.classList.add('header-ipad')
          linkHeader.classList.add('display-text')
        }
      } else {
        if(divHeader != undefined && linkHeader != undefined) {
          divHeader.classList.remove('header-ipad')
          linkHeader.classList.remove('display-text')
        }
      }
    },
    onToggleControlSidebar() {
      // this.$store.dispatch("ui/toggleControlSidebar");
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {
      this.locale = currentLang;
    }
  }

};
</script>

<style lang="scss" scoped>
#main-header {
  position: sticky;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1400px){
  .changeSite {
    min-width: 300px;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}

@media only screen and (max-width: 820px) {
  #main-header {
    position: inherit;
    position: sticky;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .text-header {
    display: none;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}

.class-header-ipad {
  @media only screen and (max-width: 768px) {
    margin-left: 250px !important;
  }
}

.header-text-ipad {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.v-application ul {
  padding-left: 0px !important;
}


@media only screen and (min-width: 897px) and (orientation: landscape) {
  .logo-desktop {
    width: 250px;
    height: 180%;
  }  
  .logo-iphone {
    display: none;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}

@media only screen and (max-width: 896px) and (max-height: 719px) and (orientation: landscape) {
  .logo-desktop {
    display: none;
  }
  .logo-iphone {
    margin-left: 2rem;
  }
  .toggle-menu {
    margin-bottom: auto !important;
    flex-wrap: wrap !important;
  }
  .changeSite {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }
  .logo-iphone {
    margin-top: 3vh !important;
    width: 100px !important;
    height: 72% !important;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}



@media only screen and (max-height: 1000px) and (max-width: 719px) and (orientation: portrait) {
  .logo-iphone {
    margin-top: 2vh !important;
    width: 100px !important;
    height: 72% !important;
  }
  .toggle-menu {
    margin-bottom: auto !important;
    flex-wrap: wrap !important;
  }
  .changeSite {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }
  .logo-ipad {  
    display: none;
  }
  .header-buttons {
    flex-wrap: wrap !important;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}



@media only screen and (min-width: 720px) and (max-width: 1366px) and (min-height: 500px) and (orientation: portrait) {
  .logo-iphone {
    display: none;
  }
  .logo-ipad {
    width: 175px;
    height: 126%;
  }
  .flag-button {
    margin-top: 1.5px !important;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  .logo-iphone {
    display: none;
  }
  .logo-ipad {
    width: 175px;
    height: 126%;
  }
  .flag-button {
    margin-top: 1.5px !important;
  }
  #main-header {
  position: sticky;
  top: 0;
  left: 0;
  }
}


</style>
