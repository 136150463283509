<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                $t("adminSite.userid")
              }}</span>
            </div>
            <div class="input-group-append ">
              <v-text-field hide-details="auto" v-model="searchInfo.UserId" outlined dense></v-text-field>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="input-group input-group-sm">
            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                $t("adminSite.username")
              }}</span>
            </div>
            <div class="input-group-append">
              <v-text-field hide-details="auto" v-model="searchInfo.UserName" outlined dense></v-text-field>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="input-group input-group-sm">
            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                $t("adminSite.status")
              }}</span>
            </div>
            <v-select :items="optionsStatus" v-model="searchInfo.Status"
                      dense
                      outlined
            >

            </v-select>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="input-group input-group-sm">
            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                $t("adminSite.siteid")
              }}</span>
            </div>
            <v-text-field hide-details="auto" v-model="searchInfo.IdSite" outlined dense></v-text-field>
          </div>
        </div>
        <div class="d-flex justify-content-end mr-3">
          <v-btn
                  class="bg-success mr-3 font-weight-bold"
                  @click="handleGetAdminSiteList"
          >
            <v-icon right dark class="mr-1" >
              fas fa-search
            </v-icon>
            {{$t("labels.search")}}
          </v-btn>
          <v-btn
                  class="bg-secondary font-weight-bold"
                  @click.prevent="clearFilter"
          >
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{$t("labels.clear")}}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 text-nowrap" data-app>
          <v-data-table :headers="headers" :items="adminsiteList" sort-by="calories"
                        class="elevation-2" mobile-breakpoint="0" hide-default-header
                        fixed-header
                        :pagination.sync="pagination"
                        :total-items="pagination.totalItems"
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50],
                            'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
          >
            <template slot="no-data">
              {{ $t("notification.noData") }}
            </template>
            <template #header="{ props: { headers } }" >
              <thead  class="v-data-table-header">
              <tr>
                <th
                        v-for="header in headers"
                        :key="header.value"
                        class="text-uppercase"
                        scope="col"
                >
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead >
            </template>
            <template v-slot:top>
              <v-toolbar
                      flat
              >
                <v-spacer></v-spacer>
                <v-btn
                        depressed
                        color="primary"
                        style="background-color: #3d76ad"
                        @click="createItem(1)" v-if="ACTION === 'OPS'"
                >
                  <v-icon color="white" size="20" class="mr-2 dark mt-0">
                    mdi-plus-box
                  </v-icon>
                  <span style="font-size: 17px" class="font-weight-bold">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.userName.slice(0, 14) }}</span>
                  <span v-if="item.userName.length > 10"> {{ '...' }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.userName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.siteName`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.siteName.slice(0, 20) }}</span>
                  <span v-if="item.siteName.length > 10"> {{ '...' }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.siteName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.siteId`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.siteId.slice(0, 20) }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.siteId }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{item}">
              <font-awesome-icon v-if="item.status == 1" icon="fa-solid fa-circle-check" color="green" size="lg"/>
              <font-awesome-icon v-else icon="fa-solid fa-circle-xmark" color="red" size="lg"/>

            </template>
            <template v-slot:[`item.actions`]="{item}">
              <div>
                <button class="m-1" @click="detailItem(item)">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="editItem(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="confirmDeleteItem(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <AdminSiteEditModal @clicked="onClickChild" @close="modelEdit=false"/>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import AdminSiteEditModal from "@/modals/AdminSite/AdminSiteEditModal"
import {EventBus} from "@/utils/eventBus";
import {cookiesGetI18Lang, cookiesSetI18Lang, getCookiePrivileges, setCurrentPageCode} from "@/utils/auth";
import {mapActions} from "vuex";
import moment from "moment";
import $ from 'jquery';
const i18nConfig = require('../i18n.lang')

export default {
  name: "AdminSiteManager",
  components: {
    AdminSiteEditModal,
  },
  updated() {
        if (this.$i18n.locale !== this.currentLocale) {
            this.currentLocale = this.$i18n.locale;
            this.headers = [
              {text: '#',align: 'center',sortable: true,value: 'index',width: "50px"},
              {text: this.$t('adminSite.userid'), value: 'userId', align: 'center', sortable: true},
              {text: this.$t('adminSite.userName'), value: 'userName', align: 'center', sortable: true},
              {text: this.$t('adminSite.siteid'), value: 'siteId', align: 'center', sortable: true},
              {text: this.$t('adminSite.sitename'), value: 'siteName', align: 'center', sortable: true},
              {text: this.$t('adminSite.status'), value: 'status', align: 'center', sortable: true},
              {text: this.$t('adminSite.createBy'), value: 'createBy', align: 'center', sortable: true},
              {text: this.$t('adminSite.createDate'), value: 'createDate', align: 'center', sortable: true},
              {text: this.$t('adminSite.updateBy'), value: 'updateBy', align: 'center', sortable: true},
              {text: this.$t('adminSite.updateDate'), value: 'updateDate', align: 'center', sortable: true},
              {text: this.$t('adminSite.actions'), value: 'actions', sortable: false, align: 'center'},
            ]
        }

  },
  data() {
    return {
      loading: false,
      ERROR_CODE: '',
      PAGE_CODE: 'SA_SAM',
      ACTION: 'OPS',
      currentLocale: this.$i18n.locale,
      dialogx: false,
      selected1: null,
      selected: null,
      options: [
        {value: null, text: 'Please select an option'},
        {value: 'a', text: 'This is First option'},
        {value: 'b', text: 'Selected Option'},
        {value: {C: '3PO'}, text: 'This is an option with object value'},
        {value: 'd', text: 'This one is disabled'}
      ],
      optionsStatus: [
        {value: 0, text: this.$t('labels.systemUserManager.roleOptions.all')},
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ],
      optionsRole: [
        {value: 1, text: 'Admin'},
        {value: 2, text: 'User'},
      ],
      modelDetail: false,
      modelEdit: false,
      modelCreate: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '#',
          align: 'center',
          sortable: true,
          value: 'index',
          width: "50px"
        },
        {text: 'labels.userid', value: 'userId', align: 'start', sortable: false},
        {text: 'adminSite.userName', value: 'userName', align: 'start', sortable: false},
        {text: 'labels.siteid', value: 'siteId', align: 'start', sortable: false},
        {text: 'labels.sitename', value: 'siteName', align: 'start', sortable: false},
        {text: 'labels.status', value: 'status', align: 'center', sortable: false},
        {text: 'labels.usercreate', value: 'createBy', align: 'start', sortable: false},
        {text: 'labels.createdate', value: 'createDate', align: 'center', sortable: false},
        {text: 'labels.userupdate', value: 'updateBy', align: 'start', sortable: false},
        {text: 'labels.updatedate', value: 'updateDate', align: 'center', sortable: false},
        {text: 'genba.table.action', value: 'actions', sortable: false, align: 'center'},
      ],
      headers2: [
        {text: '', value: 'codConstruction'},
        {text: 'View', value: 'view'},
        {text: 'Edit', value: 'eit'},
      ],
      cosas2: [
        {name: 'Item1', view: '', edit: ''},
      ],
      adminsiteList: [],
      editedIndex: -1,
      editedItem: {
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createUser: '',
        email: '',
        phonenumber: '',
        address: '',
        updateUser: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      },
      defaultItem: {
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createUser: '',
        email: '',
        phonenumber: '',
        address: '',
        updateUser: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      },
      searchInfo : {
        UserId: "",
        UserName: "",
        Status: 0,
        IdSite: "",
        Role: 0,
        UserType: 1,
        SiteId: 1,
        pageNum: 1,
        pageSize: 10
      },
      pagination:
      {
        descending: !!this.$route.query.desc,
        sortBy: 'name',
        rowsPerPage: 5,
        page: 1,
        totalItems: 0,
      },
      userIdDeleteSelected: 0
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  watch: {
    'searchInfo.UserId': function () {
      // this.searchInfo.UserId = this.searchInfo.UserId.split(" ").join("")
    },
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/roles/${currentLang}.js`)
              .then((DATA) => {
                this.ERROR_CODE = DATA.ERROR_CODE
              })
              .catch(async () => {
                await import(`@/utils/errors/roles/${currentLang}.js`)
                        .then((DATA) => {
                          this.ERROR_CODE = DATA.ERROR_CODE
                        })
              })

      this.optionsStatus = [
        {value: 0, text: this.$t('labels.systemUserManager.roleOptions.all')},
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ];
    }
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
  },
  created() {
    this.handleGetAdminSiteList()
    setCurrentPageCode(this.PAGE_CODE);

    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null){
      JSON.parse(menuAction).filter(value => {
        (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
      })
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
            .catch(async () => {
              await import(`@/utils/errors/users/${currentLang}.js`)
                      .then((DATA) => {
                        this.ERROR_CODE = DATA.ERROR_CODE
                      })
            })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods: {
    ...mapActions("users", {
      getAdminSiteList: "getAdminSiteList",
      DeleteAdminSite: "DeleteAdminSite"
    }),
    handleGetAdminSiteList() {
      this.loading = true;
      this.getAdminSiteList(this.searchInfo)
              .then(response => {
                if (response.code === 0) {
                  console.log('[handleGetUserManagementList]', response.data);
                  this.adminsiteList = response.data.userList;
                  this.pagination.rowsPerPage = response.data.meta.pageSize;
                  this.pagination.totalItems = response.data.meta.total;
                  this.pagination.page = response.data.meta.pageNum;
                  if (response.data.userList !== null && response.data.userList.length > 0) {
                    for (let i = 0; i < this.adminsiteList.length; i++) {
                      this.adminsiteList[i].indexUser = this.adminsiteList[i].index;
                      this.adminsiteList[i].index = i + 1;
                      this.adminsiteList[i].siteId = this.adminsiteList[i].siteIDString;
                      this.adminsiteList[i].createDate = moment(this.adminsiteList[i].createdDate).format('YYYY/MM/DD');
                      this.adminsiteList[i].updateDate = moment(this.adminsiteList[i].updatedDate).format('YYYY/MM/DD');
                    }
                  }
                this.loading = false;
                } else {
                  console.log("[handleGetUserManagementList]", response);
                  this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                  this.loading = false;
                }
              })
              .catch(err => {
                console.log(err);
                if(err.code != undefined)
                  this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
                this.loading = false;
              })
              .finally(final => {

                this.isSubmitAction = false;
                this.loading = false;
              });
    },
    onClickChild (value) {
      this.handleGetAdminSiteList();
    },
    // eslint-disable-next-line
    createItem(numer) {
      this.editedItem = {}
      this.editedItem.indexUser = 0
      this.editedItem.status = 1
      this.modelCreate = true
      $('#main-header').css("pointer-events","none");
      EventBus.$emit('handleOpenAdminManagementEditModal', {
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        flagAdd: true,
        editMode: false,
        nameLabel : "labels.adminsite.create"
      });
      this.modelEdit = true;
    },
    clearFilter() {
      this.searchInfo.UserId = "";
      this.searchInfo.UserName = "";
      this.searchInfo.Status = 0;
      this.searchInfo.IdSite = "";
      this.handleGetAdminSiteList();
    },
    editItem(item) {
      this.editedIndex = this.adminsiteList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = "vti@12345";
      this.editedItem.role = 1;
      this.showPassword = false;
      console.log(this.editedItem);
      EventBus.$emit('handleOpenAdminManagementEditModal', {
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        flagAdd: false,
        editMode: true,
        nameLabel : "labels.adminsite.update"
      });
      this.modelEdit = true;
    },
    detailItem(item) {
      this.editedIndex = this.adminsiteList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = "";
      this.editedItem.role = 1;
      EventBus.$emit('handleOpenAdminManagementEditModal', {
        editedItem: this.editedItem,
        flag: true,
        checkDetail: true,
        flagAdd: false,
        editMode: true,
        nameLabel : "labels.adminsite.detail"
      });
      this.modelDetail = true
    },
    confirmDeleteItem(item) {
      this.userIdDeleteSelected = item.userId;
      this.commonConfirmVue(this.$t('labels.siteManager.msgDelete'), () => {
        this.loading = true;
        this.DeleteAdminSite(this.userIdDeleteSelected)
                .then(response => {
                  if (response.code === 0) {
                    // this.dialogDelete = false;
                    this.handleGetAdminSiteList();
                    this.commonConfirmOKVue(this.ERROR_CODE['DELETE_SUCCESS'])
                    this.loading = false;
                  } else {
                    console.log("[handleDeleteItem]", response);
                    this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                    this.loading = false;
                  }
                })
                .catch(err => {
                  console.log(err);
                  if(err.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
                  this.loading = false;
                })
                .finally(final => {

                  this.isSubmitAction = false;
                  this.loading = false;
                });
      }, () => {
        this.userIdDeleteSelected = 0;
      })
    },
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 1180px) {
  /* For ipad 768px: */

}

::v-deep{
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 6.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}



@media (max-width: 820px) {
  .input-ipad{
    margin-right: 10%;
  }
}
</style>

<style lang="scss">
.colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}
td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}

thead tr th {
  border: 2px solid #ebebeb !important;
  text-align: center !important;
  font-size: .85rem !important;
  background-color: #DDDFEB !important;
  color: #000 !important;
}


</style>
