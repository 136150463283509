<template>
    <v-dialog v-model="showDialogChannelDetail" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title class="bg-gray-light">
          <span class="text-h5" style="font-size: 1.2rem !important;">{{ $t("channelDetail.channelName")+ ": " + channelName }}</span>
          <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
            <v-icon id="close-button">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px;">
          <v-container style="max-width:950px !important;" class="pt-0 pb-0">
            <div class="row mt-0">
            <div class="col-12">
              <div class="d-flex justify-content-start ">
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-data-table :headers="headerComputed" :items="listData" sort-by="calories" item-key="index"
                  class="elevation-2" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header hide-default-footer :footer-props="{
                    'items-per-page-options': [200]
                  }">
                  <template slot="no-data">
                    {{ $t("notification.noData") }}
                  </template>
                  <template #header="{ props: {} }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headerComputed" :key="header.value" scope="col">
                          {{ $t(header.text) }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                    <template v-slot:[`item.type`]="{item}">
                      {{item.type}}
                    </template>
                    <template v-slot:[`item.actualValue`]="{item}">
                    <div>
                        <v-text-field maxlength="10" v-model="item.actualValue" outlined dense hide-details="true" @keypress="isDecimalNumber($event)"
                        ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.waterLevelValue`]="{item}">
                    <div>
                        <v-text-field maxlength="10" v-model="item.waterLevelValue" outlined dense hide-details="true" @keypress="isDecimalNumber($event)"
                        ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.zeroBalance`]="{item}">
                    <v-text-field maxlength="15" v-model="item.zeroBalance" outlined dense hide-details="true" :disabled="!item.isEdit" @keypress="isDecimalNumber($event)"
                    ></v-text-field>
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                      <div style="width:120px !important;;">
                        <v-btn class="text-white mr-1" style="background-color: #3d76ad" text @click="handleGetLastValueWaterLevelValueRealtime(item)">
                          {{ $t("channelDetail.valueMeasure") }}
                        </v-btn>
                      </div>
                    </template>
                </v-data-table>
              </v-form>
            </div>
          </div>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-white mr-1" style="background-color: #3d76ad" text @click.prevent="handleSaveDataChannel">
          {{ $t("channelDetail.update") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("channelDetail.cancel") }}
        </v-btn>
      </v-card-actions>
        </v-card>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
        </v-dialog>
        </template>
        <script>
        import { EventBus } from '@/utils/eventBus.js';
        import { mapActions } from 'vuex';
        import moment from "moment";
        import { checkRegexSpaceAndSpecialCharacter, validEmail, validPhone } from "@/utils/validate";
        import $ from 'jquery';
        import {ResponseCode, NullInt } from "@/constants/constants"
        import _ from 'lodash';
        
        export default {
          name: "ChannelDetailModal",
          props: ['visible'],
          components: {},
          data() {
            return {
              valid: false,
              loading: false,
              showDialogChannelDetail: false,
              checkDetail: false,
              ERROR_CODE: '',
              //status 0: new, 1: update, 2: delete
              listTable: [],
              channelName: null,
              siteId: null,
              siteStationId: null,
              deviceCode: null,
              channelNo: null,
              channelCd: null,
              ip: null,
              port: null,
              listData: [
                {type: this.$t('channelDetail.1st'), actualValue: null, waterLevelValue: null},
                {type: this.$t('channelDetail.2st'), actualValue: null, waterLevelValue: null}
              ],
              requiredRule(value, label) {
                const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
                if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
                  return errorMsg
                } else {
                  return [];
                }
              },
              channelNoRules: [
                (value) => !!value || "",
                (value) => /^\d+$/.test(value)||'This field only accept numbers',
              ]
        
            }
          },
          updated() {
            if (this.$i18n.locale !== this.currentLocale) {
              this.currentLocale = this.$i18n.locale;
            }
          },
          beforeDestroy() {
            this.loading = false;
          },
          computed: {
            isFullDataLineTable() {
              if (this.dataList600.length > 0) {
                const item = this.dataList600[this.dataList600.length - 1];
                return item.channelNo !== null && item.channelName !== null;
              }
              return true;
            },
            headerComputed() {
              return [
                { text: this.$t('channelDetail.type'),align: 'center',sortable: false,value: 'type'},
                { text: this.$t('channelDetail.actualValue'),align: 'center',sortable: false,value: 'actualValue',},
                { text: this.$t('channelDetail.waterLevelValue'), value: 'waterLevelValue', align: 'center' },
                { text: "", value: 'action', align: 'center' },
              ]
            }
          },
          watch: {
            '$i18n.locale': async function (currentLang) {
        
              // ========================================================== start import ERROR_CODE by language
              // ==============================================================================================
              await import(`@/utils/errors/site/${currentLang}.js`)
                .then((DATA) => {
                  this.ERROR_CODE = DATA.ERROR_CODE
                })
                .catch(async () => {
                  await import(`@/utils/errors/site/${currentLang}.js`)
                    .then((DATA) => {
                      this.ERROR_CODE = DATA.ERROR_CODE
                    })
                });
                this.listData = [
                {type: this.$t('channelDetail.1st'), actualValue: null, waterLevelValue: null},
                {type: this.$t('channelDetail.2st'), actualValue: null, waterLevelValue: null}
              ];
              // ==============================================================================================
              // =========================================================== stop import ERROR_CODE by language
            },
          },
          async beforeMount() {
            // ========================================================== start import ERROR_CODE by language
            // ==============================================================================================
            const currentLang = this.$i18n.locale
            await import(`@/utils/errors/site/${currentLang}.js`)
              .then((DATA) => {
                this.ERROR_CODE = DATA.ERROR_CODE
              })
              .catch(async () => {
                await import(`@/utils/errors/site/${currentLang}.js`)
                  .then((DATA) => {
                    this.ERROR_CODE = DATA.ERROR_CODE
                  })
              })
        
            // ==============================================================================================
            // =========================================================== stop import ERROR_CODE by language
          },
          beforeCreate() {
            this.loading = true;
          },
          created() {
            EventBus.$on('handleOpenChannelDetailModel', (value) => {

              this.showDialogChannelDetail = value.flag;
              this.channelName = value.channelName
              this.siteId = value.siteId;
              this.siteStationId = value.siteStationId;
              this.deviceCode = value.deviceCode;
              this.channelNo = value.channelNo;
              this.channelCd = value.channelCd;
              // this.ip = value.ip;
              // this.port = value.port;
              if (value.flag) {
                $('#main-header').css("pointer-events", "none");
              } else {
                $('#main-header').css("pointer-events", "");
              }
            });
          },
          mounted() {
          },
          methods: {
            ...mapActions("sites", {
              getLastValueWaterLevelValueRealtime: "getLastValueWaterLevelValueRealtime",
              saveWaterLevelValueRealtime: "saveWaterLevelValueRealtime",
              getListChannel: "getListChannel",
              deleteChannel: "deleteChannel"
            }),
            ...mapActions("device", {
              getCbbDevice: "getCbbDevice"
            }),
            isDecimalNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
              } else {
                return true;
              }
            },
            handleConfirmCloseModal() {
              if (this.checkDetail === false) {
                this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
                  this.showDialogChannelDetail = false
                  $('#main-header').css("pointer-events", "");
                  this.handleResetFormItem();
                });
              } else {
                this.showDialogChannelDetail = false;
                $('#main-header').css("pointer-events", "");
                this.handleResetFormItem();
              }
            },
            handleResetFormItem(){
              this.listData = [
                {type: this.$t('channelDetail.1st'), actualValue: null, waterLevelValue: null},
                {type: this.$t('channelDetail.2st'), actualValue: null, waterLevelValue: null}
              ];
            },
            handleGetLastValueWaterLevelValueRealtime(item){
              this.loading = true;
              const request = { siteId: this.siteId,siteStationId: this.siteStationId,deviceCode: this.deviceCode, channelNo: this.channelNo, channelName: this.channelName };
              this.getLastValueWaterLevelValueRealtime(request).then((response) => {
                  var index = this.listData.findIndex(x=> x.type == item.type);
                  if (response.code === ResponseCode.SUCCESS) {
                    // item.actualValue = response.data?.data2;
                    this.listData[index].actualValue = response.data?.data2;
                    this.$set(this.listData,index, this.listData[index]);
                  }
                  else{
                    this.loading = false;
                    let error_msg = this.ERROR_CODE[response.code];
                    if (response.code === 'NO_DATA_CHANNEL') {
                      let channelNameErr = response.channelNameErr;
                      error_msg = error_msg.replace('%s', channelNameErr);
                      this.listData[index].actualValue = null;
                      this.$set(this.listData,index, this.listData[index]);
                    }
                    this.commonNotifyVue(error_msg, 'warning');
                  }
                  }).catch((error) => {
                  }).finally(() => {
                    this.loading = false;
                  })
            },
            validateChannel(){
              for (let index = 0; index < this.listData.length; index++) {
                let element = this.listData[index];
                let actualValue = element.actualValue;
                let waterLevelValue = element.waterLevelValue;
                let error_msg = this.ERROR_CODE['DATA_CHANNEL_EMPTY_NULL'];
                if (actualValue === undefined || actualValue === null || actualValue === '') {
                  this.commonNotifyVue(error_msg, 'warning');
                  return false;
                }
                if (waterLevelValue === undefined || waterLevelValue === null || waterLevelValue === '') {
                  this.commonNotifyVue(error_msg, 'warning');
                  return false;
                }
              }
              return true;
            },
            handleSaveDataChannel(){
              if(!this.validateChannel()) return;
              let section1 = this.listData[0].waterLevelValue;
              let section2 = this.listData[1].waterLevelValue;

              let slope1 = this.listData[0].actualValue;
              let slope2 = this.listData[1].actualValue;
              this.saveWaterLevelValueRealtime({channelCd: this.channelCd, siteId: this.siteId, siteStationId: this.siteStationId, deviceCode: this.deviceCode, section1: section1, section2: section2,slope1: slope1, slope2: slope2 }).then((response) => {
                  if (response.code === ResponseCode.SUCCESS) {
                    this.commonNotifyVue(this.ERROR_CODE['SAVE_OK'], 'success');
                    this.showDialogChannelDetail = false;
                    $('#main-header').css("pointer-events", "");
                    this.$emit('handleGetChannelList', this.siteId,this.siteStationId, this.deviceCode);
                    this.handleResetFormItem();
                  } else {
                    this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                  }
                }).catch((error) => {
                  if(error.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
                }).finally(() => {
                  this.loading = false;
                })
            },
          }
        }
        </script>
        
<style lang="scss" scoped>
@media (max-width: 500px) {
  .v-data-table>.v-data-table__wrapper>table {
    width: 424px !important;
  }
}

.v-card__actions {
  border-top: 1px solid #0a0909;
}

.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 100px;
  left: 0;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}

.v-input--has-state fieldset {
  border: 2px solid red !important;
}

.v-messages__message {
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}

.input-port input[type='number'] {
  -moz-appearance: textfield;
}

.input-port input::-webkit-outer-spin-button,
.input-port input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.width-150{
  width: 150px !important;
}
::v-deep{
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 1rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 1rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin-left: 4.55rem !important;
  }
  .v-data-table>.v-data-table__wrapper>table {
    width: 1300px !important;
  } 
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
        