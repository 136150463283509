<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light modal-title">
        <span class="text-h5" style="font-size: 1.2rem !important;">{{ title }}</span>
        <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 600px;" class="mt-2">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container class="pt-0 pb-0">
            <div class="row mt-2">
              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">
                  {{
                  $t("labels.systemUserManager.userId")
                  }}<i class="text-danger">{{ !checkDetail ? ' *' : '' }}</i></h6>
                <v-text-field v-model.trim="selectedUser.userId" hide-details="auto"
                              :disabled="checkDetail || editMode === true"
                              maxlength="20" :rules="userIdRule" outlined dense>
                </v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">{{
                  $t("labels.systemUserManager.password")
                  }}<i class="text-danger">{{ !checkDetail ? ' *' : '' }}</i>
                </h6>
                <div v-if="!checkDetail">
                  <v-text-field hide-details="auto"
                                :type="showPassword ? 'text' : 'password'"
                                maxlength="30"
                                :append-outer-icon="editMode ? (editPasswordFlag ? 'mdi-pencil' : 'mdi-window-close') : ''"
                                :append-icon="(showIconEye || !editMode || !editPasswordFlag) ? (showPassword ? 'fas fa-eye' : 'fa-eye-slash') : ''"
                                name="input-10-2" class="input-group--focused"
                                @click:append="showPassword = !showPassword"
                                @click:append-outer="editPasswordFlag = !editPasswordFlag"
                                outlined dense v-model.trim="selectedUser.password"
                                :readonly="editPasswordFlag === true && editMode"
                                placeholder="********"
                                :rules="!(editPasswordFlag === true && editMode) ? passwordRule : []"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-text-field type="password" name="input-10-2"
                                placeholder="********"
                                class="input-group--focused" outlined dense :disabled="checkDetail"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">{{
                  $t("labels.systemUserManager.userName")
                  }}
                  <i class="text-danger">{{ !checkDetail ? ' *' : '' }}</i>
                </h6>
                <v-text-field v-model.trim="selectedUser.userName" hide-details="auto" :disabled="checkDetail"
                              maxLength="50" :rules="fullNameRule"
                              outlined dense>
                </v-text-field>
              </div>

              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">{{
                  $t("labels.systemUserManager.phoneNumber")
                  }}
                </h6>
                <v-text-field v-model.trim="selectedUser.phoneNumber" hide-details="auto" :disabled="checkDetail"
                              maxLength="30" :rules="phoneNumberRule"
                              outlined dense>
                </v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-0">
                <h6 class="mb-1">{{
                  $t("labels.systemUserManager.email")
                  }}
                </h6>
                <v-text-field v-model.trim="selectedUser.email" hide-details="auto" :disabled="checkDetail"
                              maxLength="100" :rules="emailRule"
                              outlined dense>
                </v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">{{
                  $t("labels.systemUserManager.userType")
                  }}
                  <i class="text-danger">{{ !checkDetail ? ' *' : '' }}</i>
                </h6>
                <v-select :items="optionsRoles" v-model="selectedUser.userType" :disabled="checkDetail" outlined
                          item-value="value"
                          item-text="text"
                          key="value"
                          dense></v-select>
              </div>
              <div class="col-xl-6 col-sm-12 pb-0">
                <h6 class="mb-1">
                  {{
                  $t("labels.systemUserManager.status")
                  }}
                  <i class="text-danger">{{ !checkDetail ? ' *' : '' }}</i>
                </h6>
                <v-select :items="optionsStatus" v-model="selectedUser.status" :disabled="checkDetail"
                          item-value="value"
                          item-text="text"
                          key="value"
                          outlined dense></v-select>
              </div>
            </div>
            <div class="row mt-0" v-show="selectedUser.userType === 2">
              <div class="col-12 pt-0 pb-0">
                <h6 class="mb-2">{{ $t("labels.systemUserManager.sitename") }}
                </h6>
                <v-select multiple v-model="selectedUser.siteList" :items="comboboxSite" item-value="value" outlined
                          key="value"
                          dense
                          chips
                          deletable-chips
                          :disabled="checkDetail">
                  <template v-slot:[`item`]="{ item }">
                    <v-icon
                      v-if="selectedUser.siteList!== null && selectedUser.siteList.includes(item.value)"
                      color="primary"
                      class="mr-3">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else class="mr-3">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <!-- <option v-on="on">{{ (item.value + '-' + item.text).slice(0, 40) }} {{ '...' }}111111</option> -->
                        <v-toolbar-title v-on="on">
                          <span>{{ (item.value + '-' + item.text).slice(0, 40) }} {{ '...' }}</span>
                        </v-toolbar-title>
                      </template>
                      <option>{{ item.value + '-' + item.text }}</option>
                    </v-tooltip>
                  </template>
                  <template #selection="{item}">
                    <v-chip
                      :key="JSON.stringify(item.value)"
                      close
                      class="chip--select-multi"
                      @input="selectedUser.siteList.selectItem(item.value)"
                      @click:close="handleRemoveSite(item)"
                    >
                      {{ (item.value + '-' + item.text).slice(0, 20) }}{{'...'}}
                    </v-chip>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-0">
                <h6 class="mb-1">{{ $t("labels.systemUserManager.address") }}</h6>
                <v-textarea v-model="selectedUser.address" auto-grow outlined rows="5" row-height="15"
                            :disabled="checkDetail" :rules="addressRules"
                            maxLength="200">
                </v-textarea>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6">
                <h6 class="mb-1">{{ $t("labels.systemUserManager.createdDate") }}</h6>
                <v-text-field v-model="selectedUser.createdDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6">
                <h6 class="mb-1">{{ $t("labels.systemUserManager.createdUser") }}</h6>
                <v-text-field v-model="selectedUser.createBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6 pb-0">
                <h6 class="mb-1">{{ $t("labels.systemUserManager.updatedDate") }}</h6>
                <v-text-field v-model="selectedUser.updatedDate" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-6 pb-0">
                <h6 class="mb-1">{{ $t("labels.systemUserManager.updatedUser") }}</h6>
                <v-text-field v-model="selectedUser.updateBy" hide-details="auto" outlined dense
                              :disabled="checkDetail"></v-text-field>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" text
               @click.prevent="handleAddUpdateUserInfo">
          {{ $t("labels.systemUserManager.save") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ !checkDetail ? $t("labels.systemUserManager.cancel") : $t("labels.systemUserManager.close")}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from '@/utils/eventBus.js'
import moment from "moment";
import {cookiesGetI18Lang, cookiesSetI18Lang, getCookieUserType, getRememberKCloudID} from "@/utils/auth";
import {mapActions} from 'vuex'
import {checkRegexPassword, checkRegexSpaceAndSpecialCharacter, validEmail, validPhone} from "@/utils/validate";
import $ from 'jquery';

const i18nConfig = require('@/i18n.lang')

export default {
  name: "SystemUserModal",
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  async beforeMount() {

    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  data() {
    return {
      valid: false,
      ERROR_CODE: '',
      title: "",
      loading: false,
      showDialog: false,
      checkDetail: false,
      editMode: false,
      showPassword: false,
      showIconEye: false,
      editPasswordFlag: true,
      selectedUser: {
        index: 0,
        userId: '',
        userName: null,
        password: null,
        status: 1,
        siteList: [],
        siteName: '',
        createdDate: '',
        updatedDate: '',
        device: [''],
        createdUser: '',
        createBy: '管理者',
        email: '',
        phoneNumber: '',
        address: '',
        updatedUser: '',
        updateBy: '管理者',
        userType: 2,
      },
      optionsStatus: [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ],
      optionsRoles: [
        {value: 1, text: this.$t('labels.systemUserManager.roleOptions.admin')},
        {value: 2, text: this.$t('labels.systemUserManager.roleOptions.manager')},
      ],
      userIdRule: [
        (value) => !!value || this.$t("labels.systemUserManager.userId") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.systemUserManager.userId") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) =>/^[a-z0-9]+$/i.test(value) || this.$t("labels.systemUserManager.userId") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) => (value !== undefined && value.length >= 8) || this.checkActionCheckMinLength(this.$t("labels.systemUserManager.userId"), 8),
      ],
      passwordRule: [
        (value) => !!value || this.$t("labels.systemUserManager.password") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => /^[A-Za-z0-9`~!$%^*()_@./#&+-=]*$/i.test(value) || this.$t("labels.systemUserManager.password") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
      ],
      fullNameRule: [
        (value) => !!value || this.$t("labels.systemUserManager.userName") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.systemUserManager.userName") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      phoneNumberRule: [
        (value) =>(/^\d+$/.test(value) || (value === null || value === undefined || value.length === 0)) || this.$t("labels.systemUserManager.phoneNumber") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      emailRule: [
        (value) =>(/^[A-Za-z0-9+_.-]+@(.+)$/.test(value) || (value === null || value === undefined ||  value.length === 0)) || this.ERROR_CODE['EMAIL_NOT_CORRECT_FORMAT'],
      ],
      addressRules: [
        (value) => {
          if (value === undefined || value === null || value.length === 0) {
            return [];
          }
          return value && !!value.trim() || this.$t("labels.systemUserManager.address") + this.ERROR_CODE['NOT_CORRECT_FORMAT'];
        },
      ],
      comboboxSite: [],
      comboboxUser: []
    }
  },
  mounted() {
    this.handleGetSiteList();

  },
  beforeCreate() {

  },
  created() {
    this.loading = true;
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    EventBus.$on('handleOpenSystemUserEditModal', (value) => {
      this.$refs.form?.resetValidation();
      this.handleDetailInitUser(value);
    });

  },
  beforeDestroy() {
    EventBus.$off('handleOpenSystemUserEditModal');
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  methods: {
    ...mapActions("users", {
      getUserList: "getUserList",
      addUser: "addUser",
      updateUser: "updateUser",
      getKnsUserDetail: "getKnsUserDetail",
    }),
    ...mapActions("sites", {
      getCbbSites: "getCbbSites"
    }),
    handleDetailInitUser(value) {
      this.title = value.title;
      console.log('[handleDetailInitUser]', value.selectedUser);
      if(value.selectedUser !== undefined){
        this.handleGetKnsUserDetail(value.selectedUser.userId);
      }
      this.showDialog = value.flag;
      if (value.flag) {
        $('#main-header').css("pointer-events", "none");
      } else {
        $('#main-header').css("pointer-events", "");
      }
      this.checkDetail = value.checkDetail;
      this.editMode = value.editMode;
      if (value.profile !== undefined && this.selectedUser.userType === 2) {
        this.checkDetail = !this.checkDetail;
        this.editMode = !this.editMode;
      }
      this.selectedUser.password = null;

      this.selectedUser.createdDate = moment(this.selectedUser.createdDate).format('YYYY/MM/DD');
      this.selectedUser.updatedDate = moment(this.selectedUser.updatedDate).format('YYYY/MM/DD');
    },
    checkActionCheckMinLength(field, number) {
      let text = this.ERROR_CODE['E_052_01_13'];
      text = text.replace("[X]", this.$t(field)).replace("[Y]", number);
      return text;
    },
    handleGetSiteList() {
      this.getCbbSites(1)
        .then(response => {
          if (response.code === 0) {
            let tempData = response.data ?? [];
            for (let i = 0; i < tempData.length; i++) {
              this.comboboxSite.push({
                value: tempData[i].id,
                text: tempData[i].siteName
              })
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleGetKnsUserDetail(userId) {
      this.getKnsUserDetail(userId).then((response) => {
        if (response.code === 0) {
          this.selectedUser = response.data;
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      })
    },
    handleGetUserList() {
      this.getUserList({
        userId: null,
        username: null,
        siteId: null,
        role: 0
      })
        .then(response => {
          if (response.code === 0) {
            this.usersList = response.data;
            if (response.data !== null && response.data.length > 0) {
              for (let i = 0; i < this.usersList.length; i++) {
                this.comboboxUser.filter(value => {
                  if (this.selectedUser.createdUser === value.createdUser) {
                    this.selectedUser.createdBy = value.userName;
                  }
                  if (this.selectedUser.updatedUser === value.updatedUser) {
                    this.selectedUser.updatedBy = value.userName;
                  }
                })
              }
            }

          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleAddUpdateUserInfo() {
      if(!this.$refs.form.validate()){
        return;
      }

      if (this.selectedUser.userId === undefined || this.selectedUser.userId === null || this.selectedUser.userId === '') {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_01'], 'warning');
        return;
      }

      if (this.selectedUser.userId.length < 8) {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_17'], 'warning');
        return;
      }

      if (checkRegexSpaceAndSpecialCharacter(this.selectedUser.userId)) {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_14'], 'warning');
        return;
      }

      if (this.checkDetail === false && this.editMode === false) {
        if (this.selectedUser.password === undefined || this.selectedUser.password === null || this.selectedUser.password.replace(' ','') === '') {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_08'], 'warning');
          return;
        }

        if (this.selectedUser.password.includes(" ")) {
          this.commonErrorVue(this.ERROR_CODE['PASSWORD_INVALID'], 'warning');
          return;
        }

        if (this.selectedUser.password.length < 8) {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_18'], 'warning');
          return;
        }

        if (checkRegexPassword(this.selectedUser.password)) {
          this.commonErrorVue(this.ERROR_CODE['E_040_04'], 'warning');
          return;
        }
      }

      if (this.editMode === true && this.editPasswordFlag === false) {
        if (this.selectedUser.password === undefined || this.selectedUser.password === null || this.selectedUser.password.replace(' ','') === '') {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_08'], 'warning');
          return;
        }

        if (this.selectedUser.password.includes(" ")) {
          this.commonErrorVue(this.ERROR_CODE['PASSWORD_INVALID'], 'warning');
          return;
        }

        if (this.selectedUser.password.length < 8) {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_18'], 'warning');
          return;
        }

        if (checkRegexPassword(this.selectedUser.password)) {
          this.commonErrorVue(this.ERROR_CODE['E_040_04'], 'warning');
          return;
        }
      }

      if (this.selectedUser.userName === undefined || this.selectedUser.userName === null || this.selectedUser.userName === '') {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_03'], 'warning');
        return;
      }

      if (this.selectedUser.email !== undefined && this.selectedUser.email !== null) {
        if (this.selectedUser.email.length > 0 && !validEmail(this.selectedUser.email)) {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_16'], 'warning');
          return;
        }
      }

      if (this.selectedUser.phoneNumber !== undefined && this.selectedUser.phoneNumber !== null) {
        if (this.selectedUser.phoneNumber.length > 0 && !validPhone(this.selectedUser.phoneNumber)) {
          this.commonErrorVue(this.ERROR_CODE['E_052_01_15'], 'warning');
          return;
        }
      }

      if (this.selectedUser.userType === undefined || this.selectedUser.userType === null || this.selectedUser.userType === 0) {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_06'], 'warning');
        return;
      }

      if (this.selectedUser.status === undefined || this.selectedUser.status === null || this.selectedUser.status === 0) {
        this.commonErrorVue(this.ERROR_CODE['E_052_01_07'], 'warning');
        return;
      }
      this.loading = true;

      if (this.editMode === false) {
        this.addUser(this.selectedUser)
          .then(response => {
            if (response.code === 0) {
              this.commonConfirmOKVue(this.ERROR_CODE['S_052_01_01'])
              EventBus.$emit('handleAddUpdateUserInfo', {code: response.code})
              $('#main-header').css("pointer-events", "");
              this.handleResetUserInfo();
              this.loading = false;
            } else {
              this.commonErrorVue(this.ERROR_CODE[response.code], 'warning');
              this.loading = false;
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
          })
          .finally(final => {
            

          });
      } else {
        this.updateUser(this.selectedUser)
          .then(response => {
            if (response.code === 0) {
              this.commonConfirmOKVue(this.ERROR_CODE['S_052_01_02'])
              EventBus.$emit('handleAddUpdateUserInfo', {code: response.code})
              this.handleResetUserInfo();
              $('#main-header').css("pointer-events", "");

              this.loading = false;
            } else {
              console.log("[handleAddUpdateUserInfo][Update]", response);
              this.commonErrorVue(this.ERROR_CODE[response.code], 'warning');
              this.loading = false;
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
          })
          .finally(final => {
            

          });
      }
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
          this.handleResetUserInfo();
        });
      } else {
        $('#main-header').css("pointer-events", "");
        this.showDialog = false
        this.handleResetUserInfo();
      }
    },
    handleRemoveSite(item) {
      this.selectedUser.siteList.splice(this.selectedUser.siteList.indexOf(item.value), 1);
      this.selectedUser.siteList = [...this.selectedUser.siteList];
    },
    handleResetUserInfo() {
      this.selectedUser = {
        index: 0,
        userId: '',
        userName: '',
        password: '',
        status: 1,
        siteList: [],
        siteName: '',
        createdDate: '',
        updatedDate: '',
        device: [''],
        createdUser: '',
        createdBy: '管理者',
        email: '',
        phoneNumber: '',
        address: '',
        updatedUser: '',
        updatedBy: '管理者',
        userType: 2,
      };
      this.editMode = false;
      this.checkDetail = false;
      this.showPassword = false;
      this.showIconEye  = false;
      this.editPasswordFlag = true;
      this.isSubmitAction = false;
      this.showDialog = false
      this.$refs.form?.resetValidation();
    }
  },

  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/users/${currentLang}.js` )
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      this.optionsStatus = [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ];

      this.optionsRoles = [
        {value: 1, text: this.$t('labels.systemUserManager.roleOptions.admin')},
        {value: 2, text: this.$t('labels.systemUserManager.roleOptions.manager')},
      ];
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
}
</script>

<style scoped lang="scss">
.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  display: flex !important;
}
.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-overlay .theme--dark {
  z-index: 201 !important;
}

.v-chip:focus:not(.v-chip--selected):after {
  background: none !important;
}

@media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .modal-title {
    margin-top: 1.5rem !important;
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
