<template>
  <v-card :height="height">
    <v-card-title class="bg-gray-light card-header">
      <span class="text-h5 card-header-label">{{ $t("labels.layoutSetting.selectComponent") }}</span>
      <v-spacer></v-spacer>
                 <v-btn icon @click="clickClose" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
    </v-card-title>
    <v-card-text :style="(height < 300 ? 'display: none;' : 'padding: 10px 0px 0px 0px !important;')">
      <v-container class="pt-0 pb-0">
        <div class="row">
          <div class="col-12">
            <h6 class="mb-1">{{ $t("labels.layoutSetting.frameType") }}</h6>
            <v-select :items="typeFrameList" v-on:change="handleGetFramesByType"
                      key="code"
                      :item-text="($i18n.locale === 'en') ? 'nameEn': 'nameJp'"
                      v-model="selectedTypeFrame" outlined dense return-object>
            </v-select>
            <h6 class="mb-1">{{ $t("labels.layoutSetting.frame") }}</h6>
            <v-select v-on:change="handleChooseFrame"
                      key="id"
                      item-text="name"
                      v-model="selectedFrame" :items="frameList"
                      :disabled="selectedTypeFrame === null" outlined dense return-object>
            </v-select>
          </div>
        </div>
      </v-container>
    </v-card-text>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-card>

</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import i18nConfig from "@/i18n.lang";
import {cookiesGetI18Lang, cookiesSetI18Lang, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import {EventBus} from "@/utils/eventBus";

export default {
  props: {
    id: Number,
    height: Number,
    layout: Array
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    this.handleGetListTypeFrame();
    this.currentSite = getCookieSiteUser();
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/frames/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/frames/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {
  },
  data() {
    return {
      loading: false,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_LA',
      frameList: [],
      typeFrameList: [],
      usedFrameList: null,
      defaultSize: '',
      selectedTypeFrame: null,
      currentSite: null,
      selectedFrame: null,
      chartData: {
        series: null,
        legend: null
      }
    }
  },
  methods: {
    ...mapMutations('layout', {
      handleReloadChooseFrame: 'handleReloadChooseFrame',
      handleDoneInitFrame: 'handleDoneInitFrame',
    }),
    ...mapActions("frame", {
      getTypeFrame: "getTypeFrame",
      getFrameByType: "getFrameByType",
    }),
    clickClose(){
      this.$emit(`closeFrame`, "InitLayout");
    },
    handleChooseFrame() {

      if (this.selectedTypeFrame !== null && this.selectedFrame !== null) {
        this.handleDoneInitFrame(true);

        let dataInit = {
          id: this.id,
          frameId: this.selectedFrame.id,
          title: this.selectedFrame.title,
          type: this.selectedTypeFrame.code,
          defaultH: this.selectedTypeFrame.defaultSizeObject.defaultH,
          defaultW: this.selectedTypeFrame.defaultSizeObject.defaultW,
          defaultHeightPx: this.selectedTypeFrame.defaultSizeObject.defaultHeightPx,
          defaultWidthPx: this.selectedTypeFrame.defaultSizeObject.defaultWidthPx,
        }
        if (this.selectedFrame.typeFrameCode === 'TC') {
          dataInit.defaultHeightPx = 4;
        }
        if (this.selectedFrame.typeFrameCode === 'MWC') {
          dataInit.defaultW = 3;
        }
        this.handleReloadChooseFrame(true);
        this.$emit('handleChangeTypeComponent', dataInit);
      }

    },
    handleGetListTypeFrame() {
      this.loading = true;
      const siteId = getCookieSiteUser();

      this.getTypeFrame({siteId: siteId, flag: 1}).then(response => {
        if (response.code === 0) {
          this.typeFrameList = response.data;
          this.loading = false;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
        this.loading = false;
      });
    },
    handleGetFramesByType() {
      this.loading = true;
      this.getFrameByType({code: this.selectedTypeFrame.code, siteId: this.currentSite}).then(response => {
        if (response.code === 0) {
          this.frameList = response.data;
          this.layout.filter(e => {
            let frameIndex = this.frameList.findIndex(x => x.id === e.frameId);
            if (frameIndex !== -1) this.frameList.splice(this.frameList[frameIndex], 1);
          })
          this.loading = false;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
        this.loading = false;
      });
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/frames/${currentLang}.js` )
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/frames/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
}
</script>
<style scoped>
.card-header {
  background-color: #3c8dbc;
  color: white;
}

.card-header-label {
  color: white !important;
}
</style>
