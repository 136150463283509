<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <button @click="onExportToFile()" type="button" class="btn bg-white p-0" style="width: 35px;height: 35px;" v-bind="attrs"
                    v-on="on">
                    <svg version="1.1" width="30" height="31" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 109.33 122.88"
                        style="enable-background:new 0 0 109.33 122.88" xml:space="preserve">
                        <g>
                            <path class="st0"
                                d="M82.28,105.33h-10.8V90.47H56.14v14.86H44.99l18.64,17.54L82.28,105.33L82.28,105.33z M102.4,33.95H83.61 c-1.98,0-4.73-1.06-6.03-2.36c-1.3-1.3-2.09-3.65-2.09-5.61V6.95l0,0H25.83c-0.16,0-0.29,0.08-0.37,0.16 c-0.14,0.09-0.18,0.21-0.18,0.38v87.73c0,0.12,0.09,0.29,0.16,0.37c0.09,0.13,0.26,0.16,0.38,0.16h12.96 c-1.03,2.16-1.79,4.49-2.23,6.91H23.7c-1.46,0-2.76-0.58-3.73-1.55c-0.96-0.96-1.54-2.26-1.54-3.73V5.32 c0-1.46,0.59-2.76,1.54-3.73c0.97-0.97,2.31-1.55,3.73-1.55h55.31C79.13,0,79.25,0,79.38,0c0.58,0,1.18,0.25,1.59,0.62h0.08 c0.09,0.05,0.13,0.09,0.21,0.16l27.27,27.6c0.46,0.46,0.8,1.09,0.8,1.8c0,0.21-0.04,0.37-0.08,0.6c0,25.22,0,41.39,0,66.61 c0,1.46-0.59,2.77-1.55,3.73c-0.97,0.96-2.27,1.55-3.73,1.55H90c-0.44-2.43-1.19-4.75-2.23-6.91h14.07c0.16,0,0.16-0.09,0.25-0.16 c0.13-0.09,0.3-0.26,0.3-0.37C102.39,71.78,102.4,57.39,102.4,33.95L102.4,33.95z M81.72,25.43V8.12l19.49,19.74H84.15 c-0.67,0-1.26-0.29-1.72-0.71C82.01,26.73,81.72,26.1,81.72,25.43L81.72,25.43L81.72,25.43z" />
                            <polygon class="st1" points="0,38.51 88.9,38.51 88.9,81.22 0,81.22 0,38.51" />
                            <path class="st2"
                                d="M25.16,61.94l6.2,1.87c-0.42,1.73-1.07,3.17-1.97,4.34s-2.01,2.04-3.34,2.63c-1.33,0.59-3.01,0.89-5.06,0.89 c-2.49,0-4.53-0.36-6.1-1.08c-1.58-0.72-2.94-1.99-4.09-3.8c-1.15-1.81-1.72-4.13-1.72-6.96c0-3.77,1.01-6.68,3.02-8.7 c2.02-2.03,4.87-3.04,8.55-3.04c2.88,0,5.16,0.58,6.8,1.74s2.87,2.95,3.68,5.35l-6.25,1.39c-0.22-0.7-0.45-1.2-0.69-1.53 c-0.39-0.54-0.88-0.95-1.45-1.24s-1.22-0.44-1.92-0.44c-1.6,0-2.83,0.64-3.68,1.93c-0.65,0.96-0.97,2.45-0.97,4.49 c0,2.53,0.38,4.26,1.16,5.2c0.77,0.94,1.86,1.41,3.25,1.41c1.35,0,2.38-0.38,3.07-1.14C24.35,64.48,24.85,63.38,25.16,61.94 L25.16,61.94z M33.24,63.71l6.73-0.42c0.14,1.09,0.44,1.92,0.89,2.49c0.73,0.92,1.77,1.38,3.12,1.38c1.01,0,1.79-0.23,2.34-0.71 c0.54-0.47,0.82-1.02,0.82-1.64c0-0.59-0.26-1.12-0.78-1.59c-0.52-0.47-1.73-0.91-3.63-1.32c-3.1-0.7-5.32-1.61-6.64-2.76 c-1.33-1.15-2-2.62-2-4.4c0-1.17,0.34-2.28,1.02-3.32c0.68-1.04,1.71-1.86,3.08-2.46c1.37-0.59,3.25-0.89,5.63-0.89 c2.93,0,5.16,0.54,6.7,1.62c1.54,1.08,2.45,2.81,2.74,5.17l-6.67,0.39c-0.18-1.02-0.55-1.77-1.12-2.24 c-0.56-0.46-1.35-0.7-2.35-0.7c-0.83,0-1.44,0.18-1.86,0.52c-0.42,0.34-0.62,0.77-0.62,1.27c0,0.36,0.17,0.68,0.51,0.98 c0.34,0.3,1.12,0.58,2.38,0.84c3.09,0.66,5.31,1.34,6.64,2.02c1.34,0.68,2.31,1.52,2.92,2.53c0.61,1,0.91,2.13,0.91,3.37 c0,1.46-0.4,2.81-1.22,4.04c-0.82,1.24-1.95,2.17-3.41,2.81c-1.46,0.64-3.29,0.96-5.51,0.96c-3.9,0-6.59-0.75-8.09-2.24 C34.29,67.91,33.44,66.01,33.24,63.71L33.24,63.71z M55,48.46h7.4l5.16,16.4l5.08-16.4h7.19l-8.5,22.79h-7.67L55,48.46L55,48.46z" />
                        </g>
                    </svg>
                </button>
            </template>
            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ $t("tooltip.CSV") }}</span>
        </v-tooltip>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
    </span>
</template>
<script>
import { mapActions } from 'vuex';
import { getCookieSiteUser } from "@/utils/auth";
export default {
    props: ['frameId', 'frameName'],
    data() {
        return {
            showModalSetting: false,
            loading: false
        }
    },
    methods: {
        ...mapActions("report", {
            exportDataToFile: "exportDataToFile",
            exportDataToFilePDF: "exportDataToFilePDF"
        }),
        pad2(n) { return n < 10 ? '0' + n : n },
        async onExportToFile() {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            if (this.deviceCode === null) {
                return;
            }
            let exportType = "csv";
            let request = {
                "FrameId": this.frameId,
                "ExportType": exportType,
                "SiteId": siteID,
                "orderType": "desc",
                "deviceCode": 0,
                "locale": this.$i18n.locale,
                "reportTitle": null,
                "projectAdministrator": null,
                "startDate": null,
                "endDate": null,
                'interval': 0,
                "SiteIdInput": null,
                "SiteNameInput": null,
                "stationSelectedLst": [],
                "displayIntervalName": null,
                "tsConfigCheck": false,
                "MValue": 0,
                "ThresholdValue": 0,
                "thresholdLegendCheck": 2,
                "FrameType" : "0",
                "IsQuickExport": true
            }
            this.loading = true;
            this.exportDataToFile(request)
                .then(response => {
                    console.log('[exportDataToFile]', response);
                    if (exportType == "csv") {
                        let date = new Date();
                        var fileName = this.frameName + "_" + date.getFullYear().toString() + this.pad2(date.getMonth() + 1) + this.pad2( date.getDate()) + this.pad2( date.getHours() ) + this.pad2( date.getMinutes() ) + this.pad2( date.getSeconds() )+ ".csv"
                        this.downloadFileCsv(response, fileName);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                })
                .finally(final => {
                    
                    this.loading = false;
                });
        },
        downloadFileCsv(response, fileName) {
            const anchor = document.createElement('a');
            anchor.href = URL.createObjectURL(new Blob([response], {type: 'data:text/csv;charset=utf-8'}));
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
            this.loading = false;
        }
    },

}
</script>
<style scoped>
</style>
