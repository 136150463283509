<template>
  <div class="card card-iphone" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;"
                  v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) : "" }}</span>
          </template>
        </v-tooltip>
      </h3>
      <div v-if="(!isFrameSetting)" class="card-tools mb-2 ml-auto">
        <ButtonView v-if="isDashboard" :frameId="frameId"/>
        <ButtonViewSensor v-if="isDashboard" :frameId="frameId"/>
        <ButtonExportPdf v-if="usePdf === 1 && isDashboard" :frameId="frameId"/>
        <ButtonExportCsv v-if="useCsv === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <ButtonExportExcel v-if="useExcel === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <button v-if="isShowEdit"
          type="button"
          class="btn btn-sm" @click="clickClose"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div ref="tagMain" class="card-body">
        <!-- <v-chart
          ref="chart"
          class="chart"
          :option="isDashboard ? {}: option"
          :autoresize="true"
          :manual-update="isDashboard"
        /> -->

    </div>
    <!-- <div class="card-footer bg-transparent">
      <div class="row"></div>
    </div> -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {THEME_KEY} from "vue-echarts";
import {getDataByDeviceCode} from "@/api/device";
import {getCookieSiteUser} from "@/utils/auth";
import {ResponseCode} from "@/constants/constants";
import {mapActions} from "vuex";
import moment from "moment";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import {EventBus} from "@/utils/eventBus";
import {GetWarningSettingSvgByFrameId} from "@/api/warning.js";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "SVGChart",
  components: {
    ButtonView, ButtonViewSensor, ButtonExportCsv, ButtonExportExcel, ButtonExportPdf
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    width: Number,
    height: Number,
    series: Array,
    selectedRowProp: {type: Number, default: -1},
    title: String,
    dataCodes: [],
    frameId: Number,
    isDashboard: Boolean,
    isFrameSetting: Boolean,
    isShowEdit: Boolean,
    content: String,
    link: String,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
  },
  data() {
    return {
      signalRConnection: undefined,
      index: 0,
      heightReal: 0,
      option: {},
      selectedRow: -1,
      request: {
        siteId: null,
        stationId: null,
        deviceCode: null,
        dataCodes: null,
      },
      optionRealTime: {series: []},
      loading: false,
      myChart: null,
      warningSetting: [],
      listPrimaryColor: []
    };
  },
  created() {
    if (this.isDashboard) {
        // clone color for each pointer
        this.series.forEach(element => {
          this.listPrimaryColor.push(element.label.color)
        })
        this.getWarningSettingSvg();
    } else {
      EventBus.$on(`Resize_Layout_${this.frameId}`, value => {
        this.myChart.resize();
      });
    }
  },
  mounted() {
    if (this.frameId !== undefined && this.frameId !== null) {
      this.handleDownloadSVGFile();
    }

  },
  beforeDestroy() {
    this.$dashboardHub.stop(this.signalRConnection);
  },
  watch: {
    series: {
      handler(newValue, oldValue) {
        this.setSeries(newValue);
      },
      deep: true,
    },
    selectedRowProp: function () {
      this.selectedRow = this.selectedRowProp;
    },
    'content'($event) {
      this.handleChangeSVGChart($event);
    }
  },
  methods: {
    ...mapActions("warning", {
      getWarningByFrameId: "getWarningByFrameId",
    }),
    ...mapActions("file", {
      downloadSVGFile: "downloadSVGFile",
    }),
    clickClose(){
      this.$emit(`closeFrame`, this.frameId);
    },
    handleChangeSVGChart($event) {
      try {
        this.loading = true
        this.myChart = echarts.init(this.$refs.tagMain, null, {
          renderer: 'canvas',
          useDirtyRect: false,
          width: 'auto',
          height: 'auto'
        });
        this.myChart.clear()
        let $this = this;
        $this.setSeries($this.series);
        let initOptions = $this.option;
        let self = this;
        this.myChart.getZr().on("click", function (params) {
          let pixelPoint = [params.offsetX, params.offsetY];
          let dataPoint = self.myChart.convertFromPixel({geoIndex: 0}, pixelPoint);
          $this.setPointer(dataPoint);
        });

        echarts.registerMap((this.frameId != undefined ? this.frameId : 'svg'), {svg: $event});
        this.option = {
          toolbox: {
            feature: {
            }
          },
          tooltip: {
            show: true,
            formatter: function (param) {
                let tooltip = param?.data.name + "<br />" + param?.data?.tooltip;
                return tooltip;
            },
            animationDurationUpdate: 100,
            triggerOn: 'mousemove|click'
          },
          geo: {
            tooltip: {
              show: true,
            },
            left: "left",
            top: "center",
            layoutCenter: ['50%', '50%'],
            layoutSize: '180%',
            map: (this.frameId != undefined ? this.frameId : 'svg'),
            roam: true,
          },
          series: initOptions?.series,
        };
        if (this.isDashboard) {
          this.signalRConnection = this.$dashboardHub.init();
          this.$dashboardHub.MonitorTempRealtime(this.signalRConnection, [this.frameId], this.handleGetSVGData, this.$i18n.locale);
          this.optionRealTime.series = cloneDeep(this.option.series);
          this.myChart.setOption(this.option);
          this.$emit(`ChartReady`, this.frameId);
        }else{
          this.myChart.setOption(this.option, true);
          this.loading = false
        }

      } catch (error) {
        // console.log('[handleChangeSVGChart]', error);
      }
    },
    handleDownloadSVGFile() {
      this.loading = true;
      const siteId = getCookieSiteUser();
      this.downloadSVGFile({siteId: siteId, frameId: this.frameId}).then((response) => {
        if (response !== undefined) {
          this.handleChangeSVGChart(response.data);
        } else {
          this.commonNotifyVue(this.ERROR_CODE['E_061_01'], 'warning');
        }
      }).catch((error) => {
        this.commonNotifyVue(this.ERROR_CODE['E_061_01'], 'warning');
      }).finally(() => {

      });
    },
    setPointer(params) {
      if (this.selectedRow !== -1) {
        let myChart = echarts.init(this.$refs.tagMain);
        var dataSelected = [params[0], params[1]];
        const series = this.option.series[this.selectedRow];
        if (series) {
          this.option.series[this.selectedRow].data[0].value = dataSelected;
        }
        const pointer = {
          vertical: Math.round(params[0]),
          horizontal: Math.round(params[1]),
          index: this.selectedRow,
        };
        myChart.setOption(this.option);
        this.index++;
        this.$emit("change-pointer", pointer);
      }
    },
    setSeries(newSeries) {
      this.option.series = newSeries;
      this.updateChart();
    },
    setToolTip(index, dataNames) {
      const siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      this.replaceTooltip(index, dataNames);
    },
    handleGetSVGData(response) {
      try {
        this.loading = false;
        if (response !== undefined) {
          if (response.code === 0) {
            if(response.data[this.frameId] != undefined) {
              this.optionRealTime.series.forEach((serie) => {
                let point = response.data[this.frameId].filter(item=>{return item.index == serie.index});
                let dataRealTime = [];
                let listColor = [];
                let deviceCode = null;
                let isDeviceTS = false;
                point.forEach((item) =>{
                  Object.keys(item.dataSvg).forEach((key,index) => {
                    const value = key.split("_")
                    dataRealTime[index] = value[0];
                    listColor[index] = value[1];
                  });
                  deviceCode = item.deviceCode;
                  isDeviceTS = item.isDeviceTS;
                });
                if(dataRealTime.length > 0){
                  // check warning setting in dashboard screen
                  serie.data[0].tooltip = dataRealTime.map((data, index) =>{
                    return `<span>${data}</span>`
                  }).join('<br>');
                  // let color = this.getWarningSettingColor(point, serie);
                  let color = this.getPointColor(listColor, this.listPrimaryColor[serie.index]);
                  // console.log("color---------------", color);
                  serie.label.color = color;
                  serie.itemStyle.color = color;
                }
                else{
                  if(!serie.data[0].tooltip.includes("/") && isDeviceTS) serie.data[0].tooltip = serie.data[0].tooltip + '<br>' + "----/--/-- --:--:--"
                }
              });
              this.myChart.setOption(this.optionRealTime);
            }
          }
        }
      } catch (error) {
        if(error.result.code != undefined)
          // console.log('[handleMonitorGLSData][ERROR]', error);
          this.commonNotifyVue(this.ERROR_CODE[error.result.code], 'warning');
      }

    },
    getPointColor(listColor, defaultColor){
      const defaultColorConvert = defaultColor.replace("#", "")
      const defaultColorList = [defaultColorConvert, "3BA272", "FAC858", "EE6666"]
      let index = 0
      listColor.forEach(item =>{
        let indexCompare = defaultColorList.indexOf(item)
        if(indexCompare > index){
          index = indexCompare
        }
      })
      return "#" + defaultColorList[index]
    },
    replaceTooltip(index, dataNames) {
      if (this.option != undefined) {
        this.option.series[index].data[0].tooltip = "";
        dataNames.forEach((element) => {
          this.option.series[index].data[0].tooltip +=
            element.split("?")[0] + " : " + "ー" + (element.split("?")[1] != 'null' ? element.split("?")[1] : "") + "<br>";
        });
        this.updateChart();
      }
    },
    setLabel(index, deviceCode, devices, stationId, stationList, channelFlg, tsStation, isDeviceTS) {
      const deviceData = devices?.find((item) => {
        return item?.value === deviceCode;
      });
      const stationInfo = stationList?.find((item) => {
        return item?.value === stationId;
      });
      if(this.option.series) {
        if(isDeviceTS){
          this.option.series[index].data[0].name = `${stationInfo?.label}(${tsStation})`
        }
        else if(deviceCode == 200 || deviceCode == 300){
          this.option.series[index].data[0].name = `${stationInfo?.label}`
        }
        else{
          this.option.series[index].data[0].name = channelFlg == 1 ?  `${stationInfo?.label} ${deviceData?.name}` : deviceData?.name;
        }
        this.option.series[index].data[0].tooltip = [];
        this.updateChart();
      }
    },
    setTextColorTooltip(index, color) {
      // this.option.series[index].tooltip.textStyle.color = color;
      this.option.series[index].label.color = color;
      this.option.series[index].itemStyle.color = color;
      this.updateChart();
    },
    setHideShowPointer(item) {
      const serie = this.option.series[item.index];
      serie.view = !serie?.view
      serie.symbol = serie?.view ? serie?.oldSymbol : 'none'
      this.option.series[item.index].symbol = serie?.view ? serie?.oldSymbol : 'none'
      this.updateChart();
    },
    updateChart() {
      let myChart = echarts.init(this.$refs.tagMain, null, {
          renderer: 'canvas',
          useDirtyRect: false,
          width: 'auto',
          height: 'auto'
        });
      myChart.clear();
      myChart.setOption(this.option);
      this.index++;
    },
    getWarningSettingSvg() {
      GetWarningSettingSvgByFrameId(this.frameId)
        .then((response) => {
          this.groupMailList = [];
          const res = response.data;
          if (res && res.code === ResponseCode.SUCCESS) {
            this.warningSetting = res.data
          } else {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
          }
        })
        .catch((err) => {
          // this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWarningSettingColor(dataRealTime, serie) {
      let warningLevel = 0;
      if(this.warningSetting.length > 0 ) {
        this.warningSetting.forEach(warning => {
          dataRealTime.forEach(data => {
            if(data.deviceCode != 600 && data.deviceCode != 700 && data.deviceCode != 800){
              if(data.siteStationId === warning.siteStationId && data.deviceCode === warning.deviceCode && data.dataCode.includes(warning.dataCode)) {
                const newWarningLevel = this.getWarningLevel(warning, data.valueSvg[warning.dataCode])
                warningLevel = newWarningLevel > warningLevel ? newWarningLevel : warningLevel;
              }
            }
            else{
              if(data.siteStationId === warning.siteStationId && data.deviceCode === warning.deviceCode && data.dataCode === warning.dataCode && data.channelNoLst.includes(warning.channelNo)) {
                // console.log("value--------------- ", data.valueSvg[warning.channelNo]);
                let newWarningLevel = this.getWarningLevel(warning, data.valueSvg[warning.channelNo])
                warningLevel = newWarningLevel > warningLevel ? newWarningLevel : warningLevel;
              }
            }
          })
        });
      }
      switch(warningLevel) {
        case 0:
          return "#376CA5";
        case 1:
          return "#3BA272";
        case 2:
          return "#FAC858";
        case 3:
          return "#EE6666";
        default:
          return this.listPrimaryColor[serie.index];
      }
    },
    getWarningLevel(warningSetting, data) {
      let warningLevel = 0;
      // Limitation max
      if (warningSetting.limitation === 2)
      {
          if (data >= warningSetting.tertiaryThreshold)
          {
              warningLevel = 3;
          }
          else if (data >= warningSetting.secondThreshold)
          {
              warningLevel = 2;
          }
          else if (data >= warningSetting.primaryThreshold)
          {
              warningLevel = 1;
          }
      }
      else if (warningSetting.limitation === 1)
      {
        // Limitation min
          if (data <= warningSetting.tertiaryThreshold)
          {
            warningLevel = 3;
          }
          else if (data <= warningSetting.secondThreshold)
          {
            warningLevel = 2;
          }
          else if (data <= warningSetting.primaryThreshold)
          {
            warningLevel = 1;
          }
      }
      return warningLevel;
    }
  },
};
</script>

<style scoped>

.card-body {
  overflow-x: auto !important;
  overflow-y: auto !important;
}



</style>
