
export default [
  {
    icon: "mdi-view-dashboard",
    name: "labels.dashboard",
    path: "/",
    code: "CS_DA",
    action: "OPS",
    type: 1,
    display: true,
  },
  {
    icon: "mdi-magnify-expand",
    name: "labels.searchingData",
    path: "/search-data",
    code: "CS_DS",
    action: "OPS",
    type: 1,
    display: true,
  },
  {
    icon: "mdi-file-cog",
    name: 'labels.fileManagement',
    path: "/file-manage",
    action: "OPS",
    code: "CS_FM",
    display: true,
    type: 1,
  },
  {
    icon: "mdi-tune-vertical",
    name: "labels.setting",
    code: "CS_CM",
    action: "OPS",
    display: true,
    type: 1,
    children: [
      {
        icon: "mdi-application-outline",
        name: "labels.frameSetting",
        path: "/settingframe",
        code: "CS_GM",
        action: "OPS",
        type: 1,
        display: true,
        active: true,
        bg: 'white'
      },
      {
        icon: "mdi-clipboard-play-outline",
        name: "labels.layoutSetting.title",
        path: "/layout-setting",
        code: "CS_LA",
        action: "OPS",
        type: 1,
        display: true,
        active: false,
        bg: 'white'
      },
      {
        icon: "mdi-devices",
        name: "alarmSetting.scName",
        path: "/alarm-setting",
        code: "CS_WS",
        action: "OPS",
        type: 1,
        display: true,
        active: false,
        bg: 'white'
      },
      {
        icon: "mdi-email-multiple",
        name: "labels.mailSetting.mailsetting",
        path: "/settingmail",
        code: "CS_MS",
        action: "OPS",
        type: 1,
        display: true,
        active: false,
        bg: 'white'
      },
    ],
  },
  {
    icon: "mdi-account-multiple",
    name: "labels.userInfoManagement",
    code: "CS_IM",
    action: "OPS",
    display: true,
    type: 1,
    children: [
      {
        icon: "mdi-account-check",
        name: "labels.roleManagement",
        path: "/role-management",
        code: "CS_RM",
        action: "OPS",
        type: 1,
        display: true,
        active: false,
        bg: 'white'
      },

      {
        icon: "mdi-account-supervisor",
        name: "labels.userManagement",
        path: "/user-management",
        code: "CS_UM",
        action: "OPS",
        type: 1,
        display: true,
        active: false,
        bg: 'white'
      },
    ],
  },
  {
    icon: "mdi-application-cog-outline",
    name: "labels.sitemanager",
    path: "/construction",
    code: "SA_CSM",
    action: "OPS",
    type: 2,
    display: true
  },
  {
    icon: "mdi-account-star",
    name: "labels.adminsitemanager",
    path: "/adminsitemanager",
    code: "SA_SAM",
    action: "OPS",
    type: 2,
    display: true
  },
  {
    icon: "mdi-account-group",
    name: "labels.systemUserManagement",
    path: "/system-user-management",
    code: "SA_UM",
    action: "OPS",
    type: 2,
    display: true
  },
  {
    icon: "mdi-family-tree",
    name: "labels.deviceMasterManagement",
    path: "/device-master",
    code: "SA_DM",
    action: "OPS",
    type: 2,
    display: true
  }
]
