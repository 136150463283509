<template>
  <div ref="StackedLineMain" class="card card-iphone" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;" v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) :
            "" }}</span>
          </template>
        </v-tooltip>
      </h3>
      <div v-if="(!isFrameSetting)" class="card-tools mb-2 ml-auto">
        <ButtonView v-if="isDashboard" :frameId="dataFrameId" />
        <ButtonViewSensor v-if="isDashboard" :frameId="dataFrameId" />
        <ButtonExportPdf v-if="usePdf === 1 && isDashboard" :frameId="frameId" />
        <ButtonExportCsv v-if="useCsv === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <ButtonExportExcel v-if="useExcel === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <button v-if="isShowEdit" type="button" class="btn btn-sm" @click="clickClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div ref="tagMain" class="card-body">
      <v-chart ref="chart" class="chart" :option="(!isFrameSetting && !isDashboard) ? option : {}"
        :manual-update="(isFrameSetting || isDashboard)" :autoresize="true" @legendselectchanged="handleHideLegend" />
    </div>
    <!-- /.card-body -->
    <!-- <div class="card-footer bg-transparent">
      <div class="row"></div>
    </div> -->
    <!-- /.card-footer -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// import { use } from "echarts/core";
// import { SVGRenderer } from "echarts/renderers";
// import { LineChart } from "echarts/charts";
// import {
//   ToolboxComponent,
//   TooltipComponent,
//   GridComponent,
//   LegendComponent,
// } from "echarts/components";
import { THEME_KEY } from "vue-echarts";
// import { UniversalTransition } from "echarts/features";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import { mapActions } from "vuex";
import cloneDeep from 'lodash/cloneDeep';

// use([
//   ToolboxComponent,
//   TooltipComponent,
//   GridComponent,
//   LegendComponent,
//   LineChart,
//   SVGRenderer,
//   UniversalTransition,
// ]);
export default {
  name: "LS",
  components: {
    ButtonView, ButtonViewSensor, ButtonExportCsv, ButtonExportExcel, ButtonExportPdf
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    height: Number,
    legend: Object,
    series: Array,
    title: String,
    xAxis: Array,
    frameId: Number,
    isDashboard: Boolean,
    isFrameSetting: Boolean,
    isShowEdit: Boolean,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
  },
  data() {
    return {
      signalRConnection: undefined,
      dataFrameId: 0,
      index: 0,
      dataLegend: [],
      loading: false,
      heightReal: 0,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_DA',
      ACTION: 'OPS',
      option: {
        renderer: 'svg',
        title: {
          text: ""
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: 60,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100,
            textStyle: {
              fontSize: 0
            }
          },
        ]
      },
      optionRealTime: {
        xAxis: {},
        yAxis: {},
        series: []
      }
    };
  },

  created() {
    this.option.xAxis.data = this.xAxis;
    this.option.legend = this.legend
    this.option.series = this.series;
    this.dataFrameId = this.frameId;
  },
  mounted() {
    if (this.isDashboard) {
      this.loading = true;
      this.option.xAxis.data = [0];
      this.option.series.filter(function (item) {
        item.data = [null];
      });
      this.handleRemoveDefaultWarning().then((response) => {
        if (response.code === 0) {
          this.handleGetWarningByFrameId();
        }
      });
      this.signalRConnection = this.$dashboardHub.init();
      this.$dashboardHub.MonitorTempRealtime(this.signalRConnection, [this.frameId], this.handleMonitorLSData);
      this.$emit(`ChartReady`, this.frameId);
    } else {
      this.$refs.chart.setOption(this.option);
    }
  },
  beforeDestroy() {
    this.$dashboardHub.stop(this.signalRConnection);
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
    'legend': {
      handler(newData) {
        if (!this.isDashboard) {
          this.option.legend = newData;
          if (this.isFrameSetting) {
            this.$refs.chart.setOption(this.option, {
              replaceMerge: ['legend']
            });
          }
        }
      },
      deep: true
    },
    "series": {
      handler(newData) {
        var tsDeviceLst = newData.filter(x => x.dataCode === "d1" || x.dataCode === "d2" || x.dataCode === "d3" || x.dataCode === "d4");
        var isAllTSDevice =  (tsDeviceLst.length > 0);
        this.option.dataZoom[0].start = isAllTSDevice ? 0 : 99;
        this.option.dataZoom[1].start = isAllTSDevice ? 0 : 99;
        this.$refs.chart.setOption(this.option, {
          replaceMerge: ['dataZoom']
        });
        if (!this.isDashboard) {
          this.option.series = newData;
          if (this.isFrameSetting) {
            this.$refs.chart.setOption(this.option, {
              replaceMerge: ['series']
            });
          }
        }
      },
      deep: true
    },
    'yAxis': {
      handler(newData) {
        if (!this.isDashboard) {
          this.option.yAxis = newData;
          if (this.isFrameSetting) {
            this.$refs.chart.setOption(this.option, {
              replaceMerge: ['yAxis']
            });
          }
        }
      },
      deep: true
    },
    'xAxis': {
      handler(newData) {
        if (!this.isDashboard) {
          this.option.xAxis.data = newData;
          if (this.isFrameSetting) {
            this.$refs.chart.setOption(this.option, {
              replaceMerge: ['xAxis']
            });
          }
        }
      },
      deep: true
    },
    'frameId': function ($event) {
      if (!this.isDashboard) {
        this.dataFrameId = $event;
      }
    },
  },
  methods: {
    ...mapActions("dashboard", {
      getRealtimeSingleFrameData: "getRealtimeSingleFrameData",
    }),
    ...mapActions("warning", {
      getWarningByFrameId: "getWarningByFrameId",
    }),
    clickClose() {
      this.$emit(`closeFrame`, this.frameId);
    },
    handleRemoveDefaultWarning() {
      return new Promise((resolve, reject) => {
        try {
          const dataFakeWarning = this.option.series.filter(item => (item.key != undefined));
          dataFakeWarning.forEach((data) => {
            let index = this.option.series.findIndex(item => item.key == data.key);
            this.option.series.splice(index, 1);
          });

          // this.$refs.chart.clear();
          this.$refs.chart.setOption(this.option, true);
          resolve({ code: 0, message: 'DONE' })
        } catch (error) {
          reject({ code: 400, message: error.toString() })
          console.log('[handleRemoveDefaultWarning]', error);
        }
      });
    },
    handleMonitorLSData(response) {
      try {
        if (response !== undefined) {
          if (response.code === 0) {
            if (response.data[this.frameId] != undefined) {
              this.loading = false;
              let xAxis = [];
              Object.keys(response.data[this.frameId][0].data).map((key, index) => { xAxis[index] = key; });
              let indexDuplicate = [];
              let self = this;
              xAxis.forEach(function (arrayItem) {
                // console.log("Date NEW------", arrayItem);
                let indexDUp = self.optionRealTime.xAxis.data.indexOf(arrayItem)
                if (indexDUp != -1) {
                  indexDuplicate.push(arrayItem);
                  let index = xAxis.indexOf(arrayItem);
                  xAxis.splice(index, 1);
                }
              });
              response.data[this.frameId].forEach(value => {
                let seriesIndex = this.optionRealTime.series.findIndex(item => item.index === value.index);
                if (seriesIndex !== -1) {
                  //Add new measurement data
                  let dataRealTime = [];
                  let indexDuplicateByLine = [];
                  // Object.keys(value.data).map((key, index) => { dataRealTime[index] = value.data[key]; });
                  Object.keys(value.data).map((key, index) => {
                    let indexDUp = self.optionRealTime.xAxis.data.indexOf(key);
                    if(indexDUp != -1) indexDuplicateByLine.push(key);
                    let indexKey = xAxis.indexOf(key);
                    if(indexKey != -1) dataRealTime.push(value.data[key]);
                  });
                  indexDuplicateByLine.forEach(function (arrayItem) {
                    let index = xAxis.indexOf(arrayItem);
                    //if(value.isDeviceTS){
                      let indexDUp = self.optionRealTime.xAxis.data.indexOf(arrayItem);
                      console.log("indexDUp "+ `${arrayItem}    ${indexDUp}`);
                      if (indexDUp != -1) {
                        self.optionRealTime.series[seriesIndex].data[indexDUp] = value.data[arrayItem];
                      }
                    //}
                    // dataRealTime.splice(index, 1);
                  });
                  // if(dataRealTime.length > xAxis.length) dataRealTime.shift();
                  let oldDataLength = this.optionRealTime.series[seriesIndex].data.length;
                  this.optionRealTime.series[seriesIndex].data = this.optionRealTime.series[seriesIndex].data.concat(dataRealTime);
                  let warningSeries = this.optionRealTime.series.filter(item => (item.key != undefined && item.dataCode == this.optionRealTime.series[seriesIndex].dataCode));
                  warningSeries.forEach(item => {
                    if (item.data.length > 0)
                      if (item.data.length == oldDataLength) {
                        item.data = item.data.concat(Array(dataRealTime.length).fill(item.valueWarning));
                      } else {
                        item.data = Array(this.optionRealTime.series[seriesIndex].data.length).fill(item.valueWarning);
                      }
                  });
                }
              });
              this.optionRealTime.xAxis.data = this.optionRealTime.xAxis.data.concat(xAxis);
              this.$refs.chart.setOption(this.optionRealTime);
            }
          }
        }
      } catch (error) {
        console.log('[handleMonitorLSData][ERROR]', error);
      }
    },
    handleGetWarningByFrameId() {
      this.option.legend.selected = {};
      this.getWarningByFrameId(this.frameId).then((response) => {
        if (response.code === 0) {
          if (response.data !== undefined && response.data !== null) {
            response.data.forEach(e => {
              let index = this.option.series.findIndex(data => data.dataCode === e.dataCode && data.station == e.siteStationId);
              this.option.series.push({
                color: e.color,
                dataCode: e.dataCode,
                valueWarning: e.value,
                data: Array(this.dataLength).fill(e.value),
                key: e.siteStationId + '_' + e.deviceCode + '_' + (e.channelNo != null ? e.channelNo : e.dataCode) + '_' + e.value,
                name: e.legendWarning,
                smooth: true,
                symbolSize: 0,
                type: "line",
                station: e.siteStationId,
                xAxisIndex: this.option.series[index].xAxisIndex,
                yAxisIndex: this.option.series[index].yAxisIndex,
                channelNo: e.channelNo,
                tsStationOrg : e.tsStationOrg
              });
              let objKey = this.assignObject(e.siteStationId + '_' + e.deviceCode + '_' + (e.channelNo != null ? e.channelNo : e.dataCode) + '_' + e.value, true);
              Object.assign(this.option.legend.selected, objKey);

              // this.option.legend.data.push(e.siteStationId + '_' + e.deviceCode + '_' + e.dataCode + '_' + e.value);
              let warningIndex = this.option.series.findIndex(e => e.index !== undefined);
              if (warningIndex !== -1) {
                this.dataLength = this.option.xAxis.data.length;
                this.option.series.forEach((value) => {
                  if (value.key !== undefined) {
                    value.data = Array(this.dataLength).fill(value.data[0]);
                  }
                })
              }
            });
            if (this.isDashboard) {
              this.option.series.filter(function (item) {
                item.data = [null];
              });
              this.option.xAxis.data = [0];
            }
            this.$refs.chart.setOption(this.option);
            this.optionRealTime.series = cloneDeep(this.option.series);
            this.optionRealTime.xAxis = cloneDeep(this.option.xAxis);
            this.optionRealTime.yAxis = cloneDeep(this.option.yAxis);
          }
        }
      }).catch(error => {
        if (error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        console.log('[handleGetWarningByFrameId]', error)
      });
    },
    handleHideLegend($event) {
      let seriesIndex = this.option.series.findIndex(e => e.name.toLowerCase() === $event.name.toLowerCase());
      if (seriesIndex !== -1) {
        {
          const warningSeries = this.option.series.filter((item) => {
            const series = this.option.series[seriesIndex];
            const itemKeyDefined = item.key !== undefined;
            const dataCodeMatch = item.dataCode === series.dataCode;
            const stationMatch = item.station === series.station;
            const channelNoMatch = item.channelNo === series.channelNo;
            const tsStationOrgMatch = series.tsStationOrg ? item.tsStationOrg === series.tsStationOrg : true;

            return itemKeyDefined && dataCodeMatch && stationMatch && channelNoMatch && tsStationOrgMatch;
          });
          warningSeries.forEach(item => {
            if (!$event.selected[$event.name]) {
              if (this.isDashboard) {
                this.optionRealTime.series.forEach(warningLine => {
                  if (warningLine.name == item.name && warningLine.key == item.key) {
                    warningLine.data = []
                  }
                })
                this.$refs.chart.setOption(this.optionRealTime);
              } else {
                item.data = [];
              }
            } else {
              if (!this.isDashboard) {
                item.data = Array(this.option.series[seriesIndex].data.length).fill(Math.random() * (200 - 100) + 100);
              } else {
                this.optionRealTime.series.forEach(warningLine => {
                  if (warningLine.name == item.name && warningLine.key == item.key) {
                    warningLine.data = Array(this.optionRealTime.series[seriesIndex].data.length).fill(item.valueWarning);
                  }
                })
                this.$refs.chart.setOption(this.optionRealTime);
              }
            }
          });
        }
      }
    },
    assignObject(key, value) {
      let item = {};
      item[key] = value;
      return item;
    }
  }
};
</script>

<style scoped>
.card-iphone {
  overflow-x: auto;
  overflow-y: auto !important;
}

</style>
