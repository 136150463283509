import request from "../utils/request";

const CS_DS = "CS_DS";


export function getReportDataByTime(payload) {
  return request({
    url: `/Report/getReportDataByTime?culture=${payload.request.Locale}`,
    method: "post",
    data: payload.request,
    headers: {
      'PAGE-CODE': payload.PAGE_CODE
    }
  });
}



export function exportDataToFile(payload) {
  return request({
    url: `/Report/ExporDataToFile?culture=${payload.locale}`,
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_DS
    },
    responseType: "blob"
  });
}

export function exportDataToFilePDF(payload) {
  return request({
    url: "/Report/ExporDataToFilePdf",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}

export function SaveReportSetting(payload) {
  return request({
    url: "/Report/SaveReportSetting",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}
export function LoadReportSetting(FrameId) {
  return request({
    url: `/Report/LoadReportSetting/${FrameId}`,
    method: "post",
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}
export function LoadTsConfig(SiteId) {
  return request({
    url: `/Report/LoadTsConfig/${SiteId}`,
    method: "post",
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}
export function SaveTsConfig(payload) {
  return request({
    url: "/Report/SaveTsConfig",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}

export function getDataResponse(response){
  return response({
    data: response.data,
    status: response.status,
    config: {
      responseType: 'blob'
    }
  })
}
export function exporDataToFileByFrame(payload) {
  return request({
    url: "/Report/ExporDataToFileByFrame",
    method: "post",
    data: payload,
    headers: {
      'PAGE-CODE': CS_DS
    }
  });
}
