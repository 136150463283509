<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row mx-2">

          <div class="col-6">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em">
                {{ $t("labels.mailSetting.groupmail") }}
              </span>
            </div>
            <div class="input-group-append">
              <v-text-field
                hide-details="auto"
                v-model="keyWord"
                outlined
                dense
                maxlength="100"
              ></v-text-field>
            </div>
          </div>

          <div class="col-6">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em">
                {{ $t("labels.mailSetting.warningLevel") }}
              </span>
            </div>
            <div class="input-group-append">
              <v-select
                  v-model="warningLevel"
                  :items="warningLevelChoiceComputed"
                  :placeholder="$t('labels.mailSetting.placeholder')"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                ></v-select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse mx-2">
          <v-btn class="bg-secondary mx-2 font-weight-bold" @click.prevent="onClear">
            <font-awesome-icon
              style="margin-right: 9px; font-size: 20px"
              icon="fa-solid fa-rotate-right"
            />
            {{ $t("labels.clear") }}
          </v-btn>
          <v-btn
            class="bg-success mx-2 font-weight-bold"
            @click="initListGroupMail"
          >
            <v-icon right dark class="mr-1"> fas fa-search </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 text-nowrap" data-app>
          <v-data-table
            :headers="headers"
            :items="groupMailList"
            :footer-props="{
               'items-per-page-options': [15, 30, 50],
                'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
            }"
            sort-by="calories"
            class="elevation-2"
            mobile-breakpoint="0"
            hide-default-header
          >
            <template slot="no-data">
              {{ $t("notification.noData") }}
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    class="text-uppercase"
                    scope="col"
                  >
                    {{ $t(header.text) }}
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-toolbar class="mailSetting" flat>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="primary"
                  class="mr-2 btn-mailList"
                  style="background-color: #3d76ad"
                  @click="handleOpenMailListModal"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-address-book"
                    class="mr-1"
                  />
                  <span style="font-size: 17px" class="font-weight-bold mailSetting-mailList">{{
                    $t("labels.mailSetting.mailList")
                  }}</span>
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  class="mr-2 btn-template"
                  style="background-color: #3d76ad"
                  @click="createTemplateItem"
                >
                  <font-awesome-icon icon="fa-solid fa-gears" class="mr-1" />
                  <span style="font-size: 17px" class="font-weight-bold mailSetting-template">{{
                    $t("labels.mailSetting.template")
                  }}</span>
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  class="btn-add"
                  style="background-color: #3d76ad"
                  @click="createItem"
                  v-if="ACTION === 'OPS'"
                >
                  <v-icon color="white" size="20" class="mr-2 dark mt-0">
                    mdi-plus-box
                  </v-icon>
                  <span style="font-size: 17px" class="font-weight-bold mailSetting-add">{{
                    $t("labels.add")
                  }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.groupmail`]="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.groupmail.slice(0, 50) }}</span>
                    <span v-if="item.groupmail.length > 50"> {{ '...' }}</span>
                    </template>
                    <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.groupmail }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.warningLevel`]="{ item, on }">
                <span v-on="on">{{ displaywarningLevel(item.warningLevel) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <button class="m-1" @click="viewGroupMail(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2"> </i>
                </button>
                <button class="m-1" @click="editGroupMail(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="sendMail(item)">
                  <font-awesome-icon icon="fa-solid fa-paper-plane" />
                </button>
                <button class="m-1" @click="deleteGroupMail(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2"> </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <TemplateMailModal @close="modelEdit = false" />
    <MailModal @close="modelEdit = false" @reload-data="initListGroupMail" />
    <MailListModal @close="modelEdit = false" />
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import TemplateMailModal from "@/modals/MailSetting/TemplateMailModal";
import MailModal from "@/modals/MailSetting/MailModal";
import MailListModal from "@/modals/MailSetting/MailListModal";
import {
  GetListGroupMailBySite,
  DeleteGroupMailById,
  SendMailByGroupId,
} from "@/api/groupMail";
import {getCookiePrivileges, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import { ResponseCode, WarningLevelValue } from "@/constants/constants";
import moment from "moment";

export default {
  name: "SettingMail",
  components: {
    TemplateMailModal,
    MailModal,
    MailListModal,
  },
  data() {
    return {
      modelEdit: false,
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "index",
          width: "50px",
        },
        {
          text: "labels.mailSetting.groupmail",
          value: "groupmail",
          align: "start",
          sortable: false,
        },
        {
          text: "labels.mailSetting.warningLevel",
          value: "warningLevel",
          align: "start",
          sortable: false,
        },
        {
          text: "labels.mailSetting.selectmail",
          value: "selectedMail",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.createdUser",
          value: "createdBy",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.createdDate",
          value: "createDate",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.updatedUser",
          value: "updatedBy",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.updatedDate",
          value: "updateDate",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.action",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      groupMailList: [],
      editedItem: {},
      templateItem: {},
      modelCreate: false,
      keyWord: "",
      warningLevel: "",
      loading: false,
      PAGE_CODE: 'CS_MS',
      ACTION: 'OPS'
    };
  },
  computed: {
    warningLevelChoiceComputed() {
      return [
        {
          value: WarningLevelValue.PRIMARY,
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel1'),
        },
        {
          value: WarningLevelValue.SECONDARY,
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel2'),
        },
        {
          value: WarningLevelValue.TERTIARY,
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel3'),
        },
      ]
    }
  },
  watch: {
    "$i18n.locale": async function () {
      // start import ERROR_CODES by language
      await this.getErrorCode("mail");
    },
  },
  async beforeMount() {
    // import ERROR_CODES by language
    await this.getErrorCode("mail");
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    this.initListGroupMail();
  },
  mounted() {},
  methods: {
    createTemplateItem() {
      this.templateItem = {};
      this.modelCreate = true;
      this.handleCommonDialog(true);
      EventBus.$emit("handleOpenTemplateMailEditModal", {
        templateItem: this.templateItem,
        flag: true,
        checkDetail: false,
        nameLabel: "labels.mailSetting.mailtemplate",
      });
      this.modelEdit = true;
    },
    createItem() {
      this.templateItem = {};
      this.modelCreate = true;
      this.editedItem.dataEmail = [];
      this.handleCommonDialog(true);
      EventBus.$emit("handleOpenMailEditModal", {
        templateItem: this.templateItem,
        flag: true,
        checkDetail: false,
        isAddNew: true,
        nameLabel: "labels.mailSetting.addgroupmail",
      });
      this.modelEdit = true;
    },
    handleOpenMailListModal() {
      this.handleCommonDialog(true);
      EventBus.$emit("handleOpenMailListModal", { flag: true });
      this.modelEdit = true;
    },
    viewGroupMail(item) {
      this.editedItem = Object.assign({}, item);
      this.handleCommonDialog(true);
      EventBus.$emit("handleOpenMailEditModal", {
        groupMailInfo: this.editedItem,
        flag: true,
        checkDetail: true,
        isAddNew: false,
        nameLabel: "labels.mailSetting.groupmailinfo",
      });
      this.modelDetail = true;
    },
    editGroupMail(item) {
      this.editedItem = Object.assign({}, item);
      this.handleCommonDialog(true);
      EventBus.$emit("handleOpenMailEditModal", {
        groupMailInfo: this.editedItem,
        flag: true,
        checkDetail: false,
        isAddNew: false,
        nameLabel: "labels.mailSetting.updatgroupmail",
      });
      this.modelDetail = true;
    },
    initListGroupMail() {
      this.loading = true;
      const request = {
        SiteId: getCookieSiteUser(),
        GroupMail: this.keyWord,
        warningLevel: this.warningLevel
      };
      GetListGroupMailBySite(request)
        .then((response) => {
          this.groupMailList = [];
          const res = response.data;
          if (res && res.code === ResponseCode.SUCCESS) {
            res.data.forEach((item, index) => {
              var groupMail = {};
              groupMail.id = item.groupId;
              groupMail.index = index + 1;
              groupMail.groupmail = item.groupMailName;
              groupMail.warningLevel = item.warningLevel;
              groupMail.selectedMail = item.countSelectedMail;
              groupMail.createdBy = item.createdBy;
              groupMail.createDate = moment(item.createdDate).format(
                "YYYY/MM/DD"
              );
              groupMail.updatedBy = item.updatedBy;
              groupMail.updateDate = moment(item.updatedDate).format(
                "YYYY/MM/DD"
              );
              groupMail.note = item.groupMailNote;
              this.groupMailList.push(groupMail);
            });
          } else {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteGroupMail(item) {
      this.commonConfirmVue(
        this.$t("labels.mailSetting.confirmDelete"),
        () => {
          this.loading = true;
          const request = { Id: item.id, SiteId: getCookieSiteUser() };
          DeleteGroupMailById(request)
            .then((response) => {
              if (response.data.code === ResponseCode.DELETE_SUCCESS) {
                this.commonNotifyVue(
                  this.ERROR_CODES[response.data.code],
                  "success"
                );
                this.initListGroupMail();
              } else {
                this.commonNotifyVue(
                  this.ERROR_CODES[response.data.code],
                  "warning"
                );
              }
            })
            .catch((err) => {
              if(err.code != undefined)
                this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
            })
            .finally(() => {
              this.loading = false;
              this.onClear();
            });
        },
        () => {
          return;
        }
      );
    },
    sendMail(item) {
      this.loading = true;
      const request = {
        Id: item.id,
        SiteId: getCookieSiteUser(),
        GroupMail: item.groupmail,
      };
      SendMailByGroupId(request)
        .then((response) => {
          const res = response.data;
          if (res && res.code === ResponseCode.SEND_SUCCESS) {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "success");
          } else {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClear() {
      this.keyWord = ""
      this.warningLevel = ""
      this.initListGroupMail()
    },
    displaywarningLevel(value) {
      if (value == WarningLevelValue.PRIMARY) {
        return this.$t('labels.mailSetting.warningThreshold.warningLevel1')
      } else if (value == WarningLevelValue.SECONDARY) {
        return this.$t('labels.mailSetting.warningThreshold.warningLevel2')
      } else if (value == WarningLevelValue.TERTIARY) {
        return this.$t('labels.mailSetting.warningThreshold.warningLevel3')
      } else {
         return ""
      }
    }
  }

};
</script>

<style lang="scss" scoped>
::v-deep{
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 6.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}

@media only screen and (max-width: 500px) and (max-height: 900px) {

  .mailSetting {
    overflow-x: auto;
  }
  .mailSetting-mailList {
    font-size: 0.5rem !important;
    width: 5.5rem !important;
  }
  .mailSetting-template {
    font-size: 0.5rem !important;
    width: 4rem !important;
  }
  .mailSetting-add {
    font-size: 0.5rem !important;
    width: 0.75rem !important;
  }
  .btn-mailList {
    padding: 0 0 0 5px !important;
  }
  .btn-template {
    padding: 0 0 0 5px !important;
  }
  .btn-add {
    padding: 0 5px 0 0 !important;
  }
}




</style>
