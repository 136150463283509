<template>
  <div class="text-center">

    <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        id="loading"
    ></v-progress-circular>
  </div>
</template>
<script>

export default {
  name: "LoadingPage"
}
</script>
<style>

</style>
