<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <button @click="openWarningThreshold(1)" type="button" class="btn bg-white abc1 mr-2" v-bind="attrs"
                    v-on="on" style="width: 35px;height: 35px;">
                    <i class="fas fa-times" style="visibility: hidden"></i>
                </button>
            </template>
            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ $t("tooltip.thresholdList") }}</span>
        </v-tooltip>
        <ViewDataWarningModal :frameId="frameId" @close="showModalSetting = false" />
    </span>
</template>
<script>
import ViewDataWarningModal from "@/modals/ViewData/ViewDataWarningModal";
import { EventBus } from "@/utils/eventBus";
import $ from 'jquery';
export default {
    props: ['frameId'],
    data() {
        return {
            showModalSetting: false
        }
    },
    components: {
        ViewDataWarningModal
    },
    methods: {
        openWarningThreshold(numer) {
            this.editedItem = {}
            this.modelCreate = true
            EventBus.$emit('handleOpenWarningThreshold'+this.frameId, {
                title: this.$t('warningThreshold.title'),
                flag: true
            });
            $('#main-header').css("pointer-events", "none");
            this.showModalSetting = true;
        },
    },

}
</script>
<style scoped>
</style>
