import request from "../utils/request";

const CS_CM = "CS_CM";

export async function getMailTemplate(payload) {
    return await request({
        url: `/MailTemplate/getDetail`,
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function saveMailTemplate(payload) {
    return await request({
        url: "/MailTemplate/add",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}
