<template>
  <div ref="StackedLineMain" class="card" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;" v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) : ""
            }}</span>
          </template>
        </v-tooltip>
      </h3>
      <div v-if="(!isFrameSetting)" class="card-tools mb-2 ml-auto">
        <ButtonView v-if="(isDashboard)" :frameId="frameId" />
        <ButtonViewSensor v-if="isDashboard" :frameId="frameId" />
        <ButtonExportPdf v-if="usePdf === 1 && isDashboard" :frameId="frameId" />
        <ButtonExportCsv v-if="useCsv === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <ButtonExportExcel v-if="useExcel === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <button v-if="isShowEdit" type="button" class="btn btn-sm" @click="clickClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <!-- <div ref="tagMain" class="card-body"> -->
    <v-layout column style="overflow: auto">
      <v-flex md6 grow style="overflow: auto; height: 100%;">
        <div :class="getTableName()">
        <v-data-table :headers="headerComputed" :items="listTableComputed" sort-by="calories" 
          class="elevation-3 overflow-auto" fixed-header mobile-breakpoint="0" :footer-props="{
            'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数',
            'items-per-page-options': [15, 30, 50]
          }">
          <template v-for="(slot, i) in headerComputed" v-slot:[`item.${slot.value}`]="{ item }">
            <div :class="`text-color-${item[`${slot.value}_COLOR`]}`" :key="i" class="text-center">
              {{ item[`${slot.value}`] }}
            </div>
          </template>
          <template v-slot:[`item.index`]="{ item }">
            <div class="text-center" style="border: 1px solid #696666 !important;
                  text-align: center !important;    
                  font-size: .85rem !important;
                  background-color: #DDDFEB !important;
                  color: #000 !important;">
              {{ item.index }}
            </div>
          </template>
          <template v-slot:[`item.measurementTime`]="{ item }">
            <div class="text-center">
              {{ commonFormatStrToDate(item.measurementTime, 7) }}
            </div>
          </template>
          <template v-slot:[`item.displayDataStation`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox :disabled="isDetailComputed" v-model="item.displayDataStation" color="primary"
                :value="item.displayDataStation"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.data1`]="{ item }">
            <div :class="`text-color-${item.data1Color}`">
              {{ item.data1 }}
            </div>
          </template>
          <template v-slot:[`item.data2`]="{ item }">
            <div :class="`text-color-${item.data2Color}`">
              {{ item.data2 }}
            </div>
          </template>
          <template v-slot:[`item.data3`]="{ item }">
            <div :class="`text-color-${item.data3Color}`">
              {{ item.data3 }}
            </div>
          </template>
          <template v-slot:[`item.data4`]="{ item }">
            <div :class="`text-color-${item.data4Color}`">
              {{ item.data4 }}
            </div>
          </template>
          <template v-slot:[`item.data5`]="{ item }">
            <div :class="`text-color-${item.data5Color}`">
              {{ (deviceCodeProp != 100) ? item.data5 : humanReadableDirection(item.data5) }}
            </div>
          </template>
          <template v-slot:[`item.data6`]="{ item }">
            <div :class="`text-color-${item.data6Color}`">
              {{ item.data6 }}
            </div>
          </template>
          <template v-slot:[`item.data7`]="{ item }">
            <div :class="`text-color-${item.data7Color}`">
              {{ item.data7 }}
            </div>
          </template>
          <template v-slot:[`item.data8`]="{ item }">
            <div :class="`text-color-${item.data8Color}`">
              {{ item.data9 }}
            </div>
          </template>
          <template v-slot:[`item.data9`]="{ item }">
            <div :class="`text-color-${item.data9Color}`">
              {{ item.data9 }}
            </div>
          </template>
          <template v-slot:[`item.data10`]="{ item }">
            <div :class="`text-color-${item.data10Color}`">
              {{ item.data10 }}
            </div>
          </template>
          <!-- TS -->
          <template v-slot:[`item.d1`]="{ item }">
            <div :class="`text-color-${item.d1Color}`">
              {{ item.d1 !== null ? item.d1 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d2`]="{ item }">
            <div :class="`text-color-${item.d2Color}`">
              {{ item.d2 !== null ? item.d2 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d3`]="{ item }">
            <div :class="`text-color-${item.d3Color}`">
              {{ item.d3 !== null ? item.d3 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d4`]="{ item }">
            <div :class="`text-color-${item.d4Color}`">
              {{ item.d4 !== null ? item.d4 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d5`]="{ item }">
            <div :class="`text-color-${item.d5Color}`">
              {{ item.d5 !== null ? item.d5 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d6`]="{ item }">
            <div :class="`text-color-${item.d6Color}`">
              {{ item.d6 !== null ? item.d6 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d7`]="{ item }">
            <div :class="`text-color-${item.d7Color}`">
              {{ item.d7 !== null ? item.d7 : 'NG' }}
            </div>
          </template>
          <template v-slot:[`item.d8`]="{ item }">
            <div :class="`text-color-${item.d8Color}`">
              {{ item.d8 !== null ? item.d8 : 'NG' }}
            </div>
          </template>
          <!-- TS -->
          <div class="no-data-table">
            <template slot="no-data">
            {{ $t("notification.noData") }}
          </template>
          </div>
        </v-data-table>
      </div>
      </v-flex>
    </v-layout>
    <!-- </div> -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { getCookieSiteUser } from "@/utils/auth";
import { initTableChart } from "@/api/frame";
import { ResponseCode } from "@/constants/constants";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import {mapActions} from "vuex";

export default {
  name: "TC",
  components: { ButtonView, ButtonViewSensor, ButtonExportCsv, ButtonExportExcel, ButtonExportPdf },
  props: {
    width: Number,
    height: Number,
    deviceCodeProp: String,
    title: String,
    isDashboard: Boolean,
    isShowEdit: Boolean,
    isFrameSetting: Boolean,
    frameId: Number,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number,
    selectedStationsProp: Array,
    limitTimeProp: Number,
    exportTypeProp: Number,
    channelNoProp: Number,
    isDetailProp: Boolean
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
    headerComputed() {
      if (this.isFrameSetting) {
        console.log("this.headers", this.headers);
        return this.headers.filter(x => x.isDisplay === true);
      } else {
        return this.headers.filter(x => x.isDisplay === true && x.value != "displayDataStation");
      }
    },
    listTableComputed() {
      if (this.isFrameSetting) {
        return this.listTable
      } else {
        return this.listTable.filter(x => x.displayDataStation === true || x.displayDataStation === "true");
      }
    },
    isDetailComputed() {
      return this.isDetailProp;
    }
  },
  data() {
    return {
      signalRConnection: undefined,
      deviceCode: this.deviceCodeProp,
      heightReal: 0,
      headers: [],
      defaultHeaders: [],
      listTable: [],
      loading: false,
      stations: [],
      limitTime: undefined,
      exportType: undefined,
      channelNo: -1,
      deviceTSList: []
    };
  },
  created() {
    if (!this.isFrameSetting) {
      this.deviceCode = this.deviceCodeProp;
      this.stations = this.selectedStationsProp;
      this.limitTime = this.limitTimeProp;
      this.exportType = this.exportTypeProp;
      this.channelNo = this.channelNoProp;
      this.getTableChartData();
    }
    this.getListDeviceMaster();
  },
  watch: {
    deviceCodeProp: function () {
      this.deviceCode = this.deviceCodeProp;
      this.getTableChartData();
    }
  },
  mounted() {
    if (this.isDashboard) {
      this.signalRConnection = this.$dashboardHub.init();
      this.$dashboardHub.MonitorTempRealtimeTable(this.signalRConnection, [this.frameId], this.handleMonitorTableData);
    }
    this.$emit(`ChartReady`, this.frameId);
  },
  beforeDestroy() {
    this.$dashboardHub.stop(this.signalRConnection);
  },
  methods: {
    getTableName(){
      return{
        'table-ts col-xl-12 col-lg-12 col-12': this.isDeviceTsOrSimilarTs(Number(this.deviceCode)),
        'table-other col-xl-12 col-lg-12 col-12': !this.isDeviceTsOrSimilarTs(Number(this.deviceCode))
      }
    },
    ...mapActions("deviceMaster", {
      getDeviceList: "getDeviceList"
    }),
    humanReadableDirection(windDirection) {
      // console.log("this.indexW>>>>", this.indexW);
      // return this.dispHoui16[this.indexW];
      if (windDirection < 11.25) {
        return this.$t("compass.N");
      }
      if (windDirection >= 11.25 && windDirection <= 33.75) {
        return this.$t("compass.NNE");
      }
      if (windDirection > 33.75 && windDirection <= 56.25) {
        return this.$t("compass.NE");
      }
      if (windDirection > 56.25 && windDirection <= 78.75) {
        return this.$t("compass.ENE");
      }
      if (windDirection > 78.75 && windDirection <= 101.25) {
        return this.$t("compass.E");
      }
      if (windDirection > 101.25 && windDirection <= 123.75) {
        return this.$t("compass.ESE");
      }
      if (windDirection > 123.75 && windDirection <= 146.25) {
        return this.$t("compass.SE");
      }
      if (windDirection > 146.25 && windDirection <= 168.75) {
        return this.$t("compass.SSE");
      }
      if (windDirection > 168.75 && windDirection <= 191.25) {
        return this.$t("compass.S");
      }
      if (windDirection > 191.25 && windDirection <= 213.75) {
        return this.$t("compass.SSW");
      }
      if (windDirection > 213.75 && windDirection <= 236.25) {
        return this.$t("compass.SW");
      }
      if (windDirection > 236.25 && windDirection <= 258.75) {
        return this.$t("compass.WSW");
      }
      if (windDirection > 258.75 && windDirection <= 281.25) {
        return this.$t("compass.W");
      }
      if (windDirection > 281.25 && windDirection <= 303.75) {
        return this.$t("compass.WNW");
      }
      if (windDirection > 303.75 && windDirection <= 326.25) {
        return this.$t("compass.NW");
      }
      if (windDirection > 326.25 && windDirection <= 348.75) {
        return this.$t("compass.NNW");
      }
      if (windDirection > 348.75) {
        return this.$t("compass.N");
      }
      return windDirection;
    },
    getTableChartData() {
      if (this.deviceCode) {
        if (!this.isDeviceTsOrSimilarTs(Number(this.deviceCode)) && !this.stations.length > 0) {
          return;
        } else if (this.isDeviceTsOrSimilarTs(Number(this.deviceCode)) && this.channelNo === -1) {
          return;
        }
      }
      this.loading = true;
      const request = {
        stations: this.stations,
        // day: this.limitTime,
        deviceCode: this.deviceCode,
        siteId: getCookieSiteUser(),
        PAGE_CODE: (this.isDashboard) ? "CS_DA" : "CS_GM",
        frameId: this.frameId,
        channelNo: this.channelNo,
        exportType: this.exportType
      };
      initTableChart(request)
        .then((response) => {
          if (response?.data?.code === ResponseCode.SUCCESS) {
            this.generateHeader(response.data.data);
          } else {
            this.commonNotifyVue(
              this.ERROR_CODE[response.data.code],
              "warning"
            );
          }
        })
        .catch((err) => {
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], "warning");
        })
        .finally((final) => {
          this.loading = false;
        });
    },
    handleMonitorTableData(response) {
      try {
        var keysDataTable = this.listTable.map(i => i.measurementTime);
        if (response !== undefined) {
          if (response.code === 0) {
            if (response.data[this.frameId] != undefined) {
              if (!this.isDeviceTsOrSimilarTs(Number(this.deviceCode))) {
                response.data[this.frameId].forEach(value => {
                  var indexDataCheck = keysDataTable.indexOf(value.measurementTime);
                  if (this.listTable.length == 0 && indexDataCheck > -1) {
                    this.listTable.push(value);
                  }
                  if (indexDataCheck == -1) {
                    this.listTable.unshift(value);
                  }
                  if (this.listTable.length > 3600) {
                    this.listTable.splice(-1);
                  }
                });
              } else {
                this.listTable = response.data[this.frameId];
              }
            }
          }
        }
      } catch (error) {
        console.log('[handleMonitorTableData][ERROR]', error);
      }
    },
    clickClose() {
      this.$emit(`closeFrame`, this.frameId);
    },
    generateHeader(response) {
      if (response.headers && response.deviceCode === this.deviceCodeProp) {
        this.headers = JSON.parse(response.headers);
        this.$emit("change-header", this.headers);
      } else {
        this.headers = [];
        if (this.isDeviceTsOrSimilarTs(Number(this.deviceCode))) {
          this.headers.push({
            text: this.$t('labels.framesetting.display'),
            align: "center",
            sortable: false,
            dataName: this.$t('labels.framesetting.display'),
            value: "displayDataStation",
            isDisplay: true
          });
          this.headers.push({
            text: this.$t('labels.framesetting.station'),
            align: "center",
            sortable: false,
            dataName: this.$t('labels.framesetting.station'),
            value: "stationName",
            isDisplay: true
          });
        }
        this.headers.push({
          text: this.$t('labels.framesetting.time'),
          align: "center",
          sortable: false,
          dataName: this.$t('labels.framesetting.time'),
          value: "measurementTime",
          isDisplay: true
        });
        response.defaultHeader.forEach((element, index) => {
          const unit = element?.unit != undefined && element?.unit != null && element?.unit != "" ? " (" + element?.unit + ")" : "";
          const item = {
            index: ++index,
            data: element.dataMapping,
            dataCode: element.dataCode,
            measurementTime: element.measurementTime,
            dataName:
              this.$i18n.locale === "en"
                ? element?.nameEn + unit
                : element?.nameJp + unit,
            text: this.$i18n.locale === "en"
              ? element?.nameEn + unit
              : element?.nameJp + unit,
            value: element?.dataMapping,
            align: "center",
            sortable: false,
            isDisplay: true,
          };
          this.headers.push(item);
        });
        this.$emit("change-header", this.headers);
      }
      // console.log("DV COde", response.deviceCode);
      // console.log("DV lít", this.deviceTSList);
      // console.log("this.isDeviceTsOrSimilarTs(Number(response.deviceCode))", this.isDeviceTsOrSimilarTs(Number(response.deviceCode)));
      console.log(response.deviceCode)
      if (!this.isDeviceTsOrSimilarTs(Number(response.deviceCode))) {
        this.listTable = response?.rows != null ? response?.rows : [];
      }
      else {
        this.headers[0].isDisplay = this.isFrameSetting;
        this.listTable = response?.rowsTs != null ? response?.rowsTs : [];
        if (!this.isFrameSetting) {
          console.log("response.rowsTs", response.rowsTs);
          this.listTable = response.rowsTs.filter(item => item.displayDataStation);
        }
      }
    },
    getDisplayStationTsList() {
      let displayStationTsList = [];
      if (this.listTable && this.listTable?.length > 0) {
        this.listTable.forEach(item => {
          if (item.displayDataStation) {
            displayStationTsList.push(item.stationName);
          }
        })
      }
      return displayStationTsList;
    },
    onChangeHeader(listHeader) {
      this.headers = listHeader;
    },
    getListDeviceMaster() {
      this.getDeviceList({})
        .then(response => {
          if (response.code === 0) {
            this.deviceTSList = response.data.deviceList.map(x => x.code);
            console.log("deviceTSList ", this.deviceTSList);
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    isDeviceTsOrSimilarTs(deviceCode){
      return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(deviceCode);
    },
  },
};
</script>

<style scoped lang="scss">
.table-container {
  display: flex;
  margin-top: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.flex-table>div {
  width: 100%;
}


.text-color-green {
  font-weight: bold;
  background-color: #3BA272;
}
.text-color-yellow {
  font-weight: bold;
  background-color: #FAC858;
}
.text-color-red {
  font-weight: bold;
  background-color: #EE6666;
}
.text-color-376CA5 {
  background-color: #376CA5;
}


::v-deep{

  .v-data-table {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper {
    height: 100% !important;
  } 
  .v-data-table--fixed-header > .v-data-table__wrapper {
    .v-data-table-header{
      tr{
        th{
          border-top: 0 !important;
          top: -2px !important;
          border-left: 0 !important;
        }
      }
    }
    .v-data-table-header{
      tr{
        td{
          border-left: 0 !important;
          top: -2px !important;
          left: -2px !important;
        }
      }
    }
  }
  .table-other, .table-ts {
    height: 100%;
  }

  @media only screen and (min-width: 1500px){
  .table-ts{
    table > tbody > tr > td:nth-child(1) {
    position: sticky !important; 
    left: -2px; 
    z-index: 4;
    background-color: white;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 5.5rem; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1) {
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      position: -webkit-sticky !important;
      top: -2px;
      left: 5.5rem; 
      z-index: 5;
      background-color: white;
    }
  }
  .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
    }
      table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
    }
  }
}
  @media only screen and (min-width: 700px) and (max-width:1500px){
    .table-ts{
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      }
      table > tbody > tr > td:nth-child(2) {
        position: sticky !important; 

        left: 5.3rem; 
        z-index: 4;
        background-color: white;
      }
      table > thead > tr > th:nth-child(1) {
        position: sticky !important; 

        top: -2px;
        left: -2px; 
        z-index: 5;
        background-color: white;
      }
      table > thead > tr > th:nth-child(2) {
        position: sticky !important; 
        position: -webkit-sticky !important;
        top: -2px;
        left: 5.3rem; 
        z-index: 5;
        background-color: white;
      }
  }
    .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
    }
      table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
    }
  }
  }
  @media only screen and (min-width: 500px) and (max-width:700px){
    
    .table-ts{
    table > tbody > tr > td:nth-child(1) {
    position: sticky !important; 
    left: -2px; 
    z-index: 4;
    background-color: white;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 4.9rem; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 4.9rem; 
      z-index: 5;
      background-color: white;
    }
  }
    .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
    }
      table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
    }
  }
    .card{
      overflow-x: auto !important;
      overflow-y: hidden !important;
    }
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.75rem !important;
      height: 26px;
      padding: 0px 6px !important;
      min-width: 5rem !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 0.75rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
      min-width: 5rem !important;
    }
    .v-data-footer__pagination {
      margin-left: 3.55rem !important;
    }
    .v-card__text {
      margin-left: -1rem !important;
      width: 115% !important;
    }
    .table-ts{
    table > tbody > tr > td:nth-child(1) {
    position: sticky !important; 
    left: -2px; 
    z-index: 4;
    background-color: white;
    border-left: 0px !important;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 76px !important; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 76px !important; 
      z-index: 5;
      background-color: white;
    }
  }
    .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
    }
      table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
    }

    }
}
}
</style>


