<template>
  <v-dialog v-model="showDialog" max-width="1200px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ title }}</span>
        <v-btn @click="onCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <div class="row m-1">
            <div class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                    $t('warningThreshold.device')
                                  }}</span>
              </div>
              <div class="input-group-append input1-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12 ">
                        <v-select v-model="selectedDevice" :items="deviceList"
                                  @change="handleSelectDevice"
                                  :item-text="$i18n.locale === 'en' ? `labelEN` : `labelJP`"
                                  item-value="value" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isTsDeviceSelected" class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                  $t('alarmSetting.stationName')
                                }}</span>
              </div>
              <div class="input-group-append input2-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select :disabled="selectedDevice === null" v-model="stationMainTS"
                                  v-on:change="handleChangeStationMainTS(e)" item-value="value"
                                  :item-text="$i18n.locale === 'en' ? `labelEN` : `labelJP`"
                                  :items="stationList" dense outlined>
                          <template v-slot:[`item`]="{ item }">
                            <span class="stationName-span" :key="item.value" :value="item.value">{{ item.label+`(${selectedDevice})` }}</span>
                          </template>
                          <template #selection="{item}">
                            <span class="stationName-span" >{{ $i18n.locale !== 'jp' ? item.label : item.label }}</span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isTsDeviceSelected" class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                  $t('exportData.stationTs')
                                }}</span>
              </div>
              <div class="input-group-append input1-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select :disabled="stationMainTS === null" v-model="tsStationSearch"
                                  @change="handleChangeTsStation(e)" item-value="value"
                                  :item-text="$i18n.locale === 'en' ? 'name' : 'nameJp'"
                                  :items="tsStationLst" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isTsDeviceSelected" class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                  $t('exportData.orgNo')
                                }}</span>
              </div>
              <div class="input-group-append input2-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select :disabled="tsStationSearch === null" v-model="orgNoSearch"
                                  @change="handleChangeOrgNo" item-value="orgNo"
                                  :item-text="$i18n.locale === 'en' ? 'orgNameEn' : 'orgName'"
                                  :items="itemOrgNo" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isTsDeviceSelected" class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                  $t('warningThreshold.properties')
                                }}</span>
              </div>
              <div class="input-group-append input2-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select :disabled="orgNoSearch === null" v-model="propertiesTs"
                                  @change="handleLoadWarningTable" item-value="value"
                                  item-text="name"
                                  :items="propertiesTsLst" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm">
                                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                    $t('warningThreshold.properties')
                                  }}</span>
              </div>
              <div class="input-group-append input2-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select :disabled="selectedDevice === null" v-model="selectedProperty"
                                  @change="handleLoadWarningTable" item-value="dataCode"
                                  :item-text="$i18n.locale === 'en' ? 'nameEn' : 'nameJp'"
                                  :items="deviceTypes" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-1">
            <div class="col-xl-12 col-lg-12 col-12">
              <v-data-table :headers="headersComputed" :items="dataList" sort-by="calories" class="elevation-2"
              :footer-props="{
               'items-per-page-options': [15, 30, 50],
               'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
            }" hide-default-header
            mobile-breakpoint="0"
            >
                <template slot="no-data">
                  {{ $t("notification.noData") }}
                </template>
                <template #header="{ props: {} }">
                  <thead class="v-data-table-header">
                  <tr>
                    <th v-for="header in headersComputed" :key="header.value" class="text-uppercase"
                        scope="col">
                      {{ $t(header.text) }}
                    </th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:[`item.display`]="{ item }">
                  <v-checkbox v-bind:true-value="1" v-bind:false-value="0" style="padding-left:15px !important;"
                              v-model="item.display"
                              :disabled="true"></v-checkbox>
                </template>
                <template v-slot:[`item.siteStationName`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.siteStationName.slice(0, 10) }}</span>
                      <span v-if="item.siteStationName.length > 10"> {{ '...' }}</span>
                    </template>
                    <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{
                        item.siteStationName
                      }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.channelNo`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                          getValueChannelName(item,item.channelNo).slice(0, 10)
                        }}</span>
                      <span v-if="getValueChannelName(item,item.channelNo).length > 10"> {{
                          '...'
                        }}</span>
                    </template>
                    <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{
                        getValueChannelName(item,item.channelNo)
                      }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.alarmCategory`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                                            <span v-on="on">{{
                                                getValueAlarmCategory(item.alarmCategory).slice(0, 10)
                                              }}</span>
                      <span v-if="getValueAlarmCategory(item.alarmCategory).length > 10"> {{
                          '...'
                        }}</span>
                    </template>
                    <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{
                        getValueAlarmCategory(item.alarmCategory)
                      }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.limitation`]="{ item }">
                  {{ getValueLimit(item.limitation) }}
                </template>
                <template v-slot:[`item.primaryThreshold`]="{ item }">
                  {{ item.primaryThreshold }} {{ unitValue }}
                </template>
                <template v-slot:[`item.secondThreshold`]="{ item }">
                  {{ item.secondThreshold }} {{ unitValue }}
                </template>
                <template v-slot:[`item.tertiaryThreshold`]="{ item }">
                  {{ item.tertiaryThreshold }} {{ unitValue }}
                </template>
                <template v-slot:no-data>
                  {{ $t("exportData.noData") }}
                </template>
              </v-data-table>
            </div>

          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click="onCloseModal">
          {{ $t("labels.systemUserManager.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from '@/utils/eventBus.js'
import {mapActions} from 'vuex';
import {getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import $ from 'jquery';
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ViewDataSensorModal",
  props: ['visible', 'titleModal', 'frameId'],
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', value: 'index', align: 'center', sortable: false},
        {text: this.$t('alarmSetting.active'), align: 'center', sortable: false, value: 'display', width: "100"},
        {text: this.$t('warningThreshold.station'), value: 'siteStationName', align: 'center', sortable: false},
        {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false,},
        {text: this.$t('warningThreshold.alarmCategory'), value: 'alarmCategory', align: 'center', sortable: false},
        {text: this.$t('warningThreshold.limitation'), value: 'limitation', align: 'center', sortable: false},
        {
          text: this.$t('warningThreshold.primaryThreshold'),
          value: 'primaryThreshold',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('warningThreshold.secondaryThreshold'),
          value: 'secondThreshold',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('warningThreshold.tertiaryThreshold'),
          value: 'tertiaryThreshold',
          align: 'center',
          sortable: false
        },
      ];
    }
  },
  data() {
    return {
      selectedDevice: null,
      deviceList: [],
      deviceTypes: [],
      dataList: [],
      stationList: [],
      selectedProperty: null,
      stationMainTS: null,
      tsStationSearch: null,
      orgNoSearch: null,
      propertiesTs: null,
      tsStationLst: [],
      itemOrgNo:[],
      propertiesTsLst: [],
      title: "",
      CURRENT_SITE: null,
      showDialog: false,
      isTsDeviceSelected : false,
      headers: [
        {text: '#', value: 'index', align: 'center', sortable: false},
        {text: this.$t('alarmSetting.active'), align: 'center', sortable: false, value: 'display', width: "100"},
        {text: 'warningThreshold.station', value: 'siteStationName', align: 'center', sortable: false},
        {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false,},
        {text: 'warningThreshold.alarmCategory', value: 'alarmCategory', align: 'center', sortable: false},
        {text: 'warningThreshold.limitation', value: 'limitation', align: 'center', sortable: false},
        {text: 'warningThreshold.primaryThreshold', value: 'primaryThreshold', align: 'center', sortable: false},
        {text: 'warningThreshold.secondaryThreshold', value: 'secondThreshold', align: 'center', sortable: false},
        {text: 'warningThreshold.tertiaryThreshold', value: 'tertiaryThreshold', align: 'center', sortable: false},
      ],
      alarmCategoryList: [
        {value: 1, text: this.$t('alarmSetting.alarmCategoryList.avgWarning')},
        {value: 2, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(1s)')},
        {value: 3, text: this.$t('alarmSetting.alarmCategoryList.instantaneousValue(10m)')},
        {value: 4, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(1hr)')},
        {value: 5, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(3hr)')},
        {value: 6, text: this.$t('alarmSetting.alarmCategoryList.unitAccumulation(24hr)')},
        {value: 7, text: this.$t('alarmSetting.alarmCategoryList.consecutiveCumulativeTotal')},
        {value: 8, text: this.$t('alarmSetting.alarmCategoryList.1minuteDisplacementValue')},
      ],
      limitList: [
        {value: 1, text: this.$t('alarmSetting.limitList.min')},
        {value: 2, text: this.$t('alarmSetting.limitList.max')}
      ],
      PAGE_CODE: 'CS_DA',
      channelFlg: 0,
      unitValue: ""
    }

  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    this.CURRENT_SITE = getCookieSiteUser();
    EventBus.$on('handleOpenWarningThreshold' + this.frameId, (value) => {
      this.title = value.title;
      this.showDialog = value.flag;
      this.dataList = []
      this.selectedDevice = null;
      this.selectedProperty = null;
      this.isTsDeviceSelected = false
      this.handleGetCbbDevice();
    });
    // this.initialize();
  },
  computed:{
    headersComputed(){
      // const channelNoColumn = {text: this.$t('alarmSetting.channelName'), value: 'channelNo', align: 'center', sortable: false,}
      // const indexColumnChannelNo = this.headers.findIndex((e => e.value === 'channelNo'))
      // const indexColumnSiteStationDeviceId = this.headers.findIndex((e => e.value === 'siteStationDeviceId'))
      if(this.channelFlg == 1){
        return this.headers;
      }
      else{
        return this.headers.filter(x=> x.value !== 'channelNo');
      }
    },
  },
  methods: {
    ...mapActions("device", {
      getDeviceByFrameId: "getDeviceByFrameId"
    }),
    ...mapActions('warning', {
        getDeviceProperty: 'getDeviceProperty',
        getWarningList: 'getWarningList',
        getStation: 'getStation',
      }
    ),
    ...mapActions("station", {
      getCbbStationTSByDevice: "getCbbStationTSByDevice",
      getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr"
    }),
    handleGetCbbDevice() {
      this.getDeviceByFrameId(this.frameId)
        .then(response => {
          if (response.code === 0) {
            this.deviceList = response.data.map((item, index) => {
              return item;
            })
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleSelectDevice(value) {
      this.selectedProperty = null
      this.stationMainTS = null
      this.tsStationSearch = null
      this.orgNoSearch = null
      this.propertiesTs = null
      this.dataList = []
      const deviceType = this.deviceList.find(item => item.value === value)?.type
      if(deviceType === 1){
        this.isTsDeviceSelected = true
        this.handleGetListStation()
        return
      }
      this.isTsDeviceSelected = false
      this.getDeviceProperty(this.selectedDevice).then((response) => {
        if (response.code === 0) {
          this.deviceTypes = response.data;
        }
      }).catch(error => {
        console.log('[handleSelectDevice]', error);
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
      })
      this.channelFlg = this.deviceList?.find(x => x.value === this.selectedDevice.toString())?.channelFlg;
    },
    handleGetListStation() {
      this.getStation({siteId: this.CURRENT_SITE, deviceCode: this.selectedDevice}).then((response) => {
        if (response.code === 0) {

          this.stationList = response.data;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        // console.log('[handleGetListStation]');

      })
    },
    handleChangeStationMainTS(data){
      this.tsStationSearch  = null;
      this.orgNoSearch  = null;
      this.propertiesTs  = null;
      this.handleGetCbbStationTS(this.stationMainTS, this.selectedDevice, null)
    },
    handleGetCbbStationTS(stationId, deviceCode, orgNo) {
      this.loading = true;
      let siteID = getCookieSiteUser();
      this.getCbbStationTSByDevice({DeviceCode: deviceCode, SiteId: siteID, StationId: stationId, OrgNo: orgNo})
        .then(response => {
          if (response.code === 0) {
            this.tsStationLst = response.data.map((i, index) => {
              var dataI = {value: i.id, name: i.stationName, nameJp: i.stationName };
              return dataI;
            });
            // this.tsStationLst.unshift({value: 'all', name: 'All Points', nameJp: '全測点' })
            // this.tsStationSearch = 'all';
            // this.handleChangeTsStation();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleChangeTsStation(){
      this.loading = true;
      this.orgNoSearch  = null;
      this.propertiesTs  = null;
      let rq = {
        SiteId: getCookieSiteUser(),
        StationId: this.stationMainTS,
        DeviceCode: this.selectedDevice,
        SokGrTs: this.tsStationSearch
      }
      this.getCbbOrgInfoBySokGr(rq)
        .then(response => {
          if (response.code === 0) {
            this.itemOrgNo = response.data;
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleChangeOrgNo(item){
      const orgData = this.itemOrgNo?.find(x=>x.orgNo === this.orgNoSearch)?.orgData;
      this.propertiesTs  = null;
      this.propertiesTsLst = orgData.map((i, index) => {
        var dataI = {value: i.dataMapping, name: this.$i18n.locale === 'en' ? i.headerEn : i.headerJp, unit: i.unit, disable: false, icon: null};
        return dataI;
      });
    },
    handleLoadWarningTSTable(){
      this.isManipulating = false;
      this.loading = true;
      this.getWarningList({
        siteId: this.CURRENT_SITE,
        deviceCode: Number(this.selectedDevice),
        properties: this.selectedProperty,
        stationId: this.stationMainTS,
        tsStation: this.tsStationSearch,
        orgNo: this.orgNoSearch,
        propertiesTs: this.propertiesTs,
        isTsDevice : this.isTsDeviceSelected
      })
        .then((response) => {
          if (response.code === 0) {
            let dataList = response.data;
            for (let i = 0; i < dataList.length; i++) {
              dataList[i].siteStationName = this.stationList.find(item => item.valueInt === dataList[i].siteStationDeviceId).label;
              dataList[i].index = i;
              dataList[i].isEdit = false;
              dataList[i].isNew = false;
              dataList[i].itemChannelList = dataList[i].itemChannelList?.map((item, index)=>{
                let channel = {value: item.channelNo, name: item.channelName};
                return channel;
              });
            }
            this.dataList = cloneDeep(dataList)
          }
        }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        this.loading = false;
      })
    },
    handleLoadWarningTable(type) {

      const dataObj = this.deviceTypes.find(element => {
        return element.dataCode.toString() === type.toString() ? element : '';
      });
      this.unitValue = dataObj != null ? dataObj.unit : "";
      if(this.isTsDeviceSelected){
        this.handleLoadWarningTSTable()
        return
      }
      this.getWarningList({
        siteId: this.CURRENT_SITE,
        deviceCode: this.selectedDevice,
        properties: this.selectedProperty,
        isTsDevice : this.isTsDeviceSelected
      })
        .then((response) => {
          if (response.code === 0) {
            this.dataList = response.data;
            for (let i = 0; i < this.dataList.length; i++) {
              this.dataList[i].index = i + 1;
              this.dataList[i].isEdit = false;
              this.dataList[i].isNew = false;
            }
          }
        }).catch((error) => {
        console.log('[handleLoadWarningTable]', error);
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
      })
    },
    getValueAlarmCategory(value) {
      const dataObj = this.alarmCategoryList.find(element => {
        return element.value.toString() === value.toString() ? element : '';
      });
      return dataObj.text;

    },
    getValueChannelName(item, value) {
      const dataObj = item.itemChannelList.find(element => {
        return element.channelNo.toString() === value.toString() ? element : '';
      });
      return dataObj.channelName;

    },
    getValueLimit(value) {
      const dataObj = this.limitList.find(element => {
        return element.value.toString() === value.toString() ? element : '';
      });
      return dataObj.text;

    },
    initialize() {
    },
    beforeDestroy() {
      this.loading = false;
      EventBus.$off('handleOpenWarningThreshold' + this.frameId);
    },
    onCloseModal() {
      this.showDialog = false;
      $('#main-header').css("pointer-events", "");
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field__details {
  display: none !important;
}

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.checkboxLabel {
  margin-top: 8px !important;
  font-weight: 100;
  color: black;
}

::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.75rem !important;
    height: 40px;
    padding: 0px 0px !important;
    min-width: 60px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 0px !important;
    min-width: 60px !important;
  }
  .v-data-footer__pagination {
    margin-left: 3rem !important;
  }
  .v-card {
    margin-top: 5rem !important;
  }
  .v-card__text {
    padding: 0px 0px 0px !important;
  }
  .v-input__slot {
    max-width: 7rem !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal !important;
    overflow: hidden;
    display: -webkit-box;
  }
  
  .stationName-span {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .v-select.v-text-field:not(.v-text-field--single-line) input {
    display: none;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}


</style>
