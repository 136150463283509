<template>
  <aside class="main-sidebar sidebar-light-primary elevation-4 side-menu side-menu-iphone" style="position: fixed" id="main-sidebar">
    <div class="sidebar p-1">
      <div class="d-flex justify-content-center align-items-center sidebar-ipad">
      </div>
      <div class="mt-3 pb-3 mb-3 ">
      </div>
      <nav class="mt-2 menu-sidebar">
        <ul class="nav nav-pills nav-sidebar flex-column menu-iphone" style="padding-left: 0" data-widget="treeview" role="menu"
            data-accordion="false">
          <app-menu-item v-for="(item, index) in getMenu" :menuItem="item" :key="index"
                         @click.native="handleActiveMainSidebar"
                         class="pl-0">
          </app-menu-item>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import {getCookiePrivileges, getMenuAccesses, setCookiePrivileges} from "@/utils/auth";

export default {
  name: "MenuSidebar",
  components: {
    "app-menu-item": MenuItem,
  },
  props: {
    title: {
      type: String,
      default: 'K-Cloud'
    },
    type: {
      type: String,
      default: 'sidebar',
      validator: (value) => {
        let acceptedValues = ['sidebar', 'navbar']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    logo: {
      type: String,
      default: 'static/img/vue-logo.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.menuAccess = getMenuAccesses();
    this.isSetMobile();
  },
  computed: {
    getMenu() {
      const accessMenu = JSON.parse(getCookiePrivileges());
      if(this.isMobile === true ){
        if (accessMenu !== undefined && accessMenu !== null) {
          
          accessMenu.filter(value => {
            if (value.children !== undefined && value.children.length > 0 && value.code === 'CS_CM') {

              let LAIndex = value.children.findIndex(menu => menu.code === 'CS_LA')
              if (LAIndex !== -1) value.children.splice(LAIndex, 1);
            }

            if (parseInt(this.menuAccess) === value.type) value.display = true

            if (value.code === 'CS_CM' && value.children.length < 1) {
              if (parseInt(this.menuAccess) === value.type) value.display = false
            }
          })
        }
      }else{
        accessMenu.filter(value => {
          if (parseInt(this.menuAccess) === value.type) value.display = true;
        })
      }

      return accessMenu;
    }
  },
  destroyed() {
    // removeMenuAccesses();
  },
  data() {
    return {
      userType: null,
      menuAccess: null,
      isMobile: false
    }
  },
  methods: {
    isSetMobile() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    checkIsMobile() {
      this.isMobile = this.$vuetify.breakpoint.width <= 6;
    },
    handleActiveMainSidebar() {
      const accessMenu = JSON.parse(getCookiePrivileges());
      accessMenu.filter(value => {
        if (value.children !== undefined) {
          value.children.find(child => {
            if (this.$router.currentRoute.path === child.path) {
              child.bg = 'bg-gradient-blue'
            } else {
              child.bg = 'white'
            }
          })
        }
      })

      setCookiePrivileges(accessMenu);
    }
  }
};
</script>
<style lang="scss" scoped>
.sidebar-ipad {
  margin-top: 56px;
}

.side-menu {
  z-index: 9 !important;
}

@media (max-width: 744px) {
  .side-menu-iphone {
    margin-left: 0px;
  }
  .sidebar-collapse .main-sidebar {
    margin-left: -25rem;
  }
  .menu-iphone {
    margin-top: 20px;
    position: fixed;
  }
}
@media (max-height: 744px) {
  .side-menu-iphone {
    margin-left: 0px;
  }
  .sidebar-collapse .main-sidebar {
    margin-left: -25rem;
  }
  .menu-iphone {
    margin-top: 60px;   
  }
}

::v-deep {
  nav.mt-2.menu-sidebar {
    width: 17rem !important;
  }
  a.nav-link.p-0d-flex.align-items-center.justify-content-center {
    width: 15.4rem !important;
    padding-left: 0 !important;
  }
  .sidebar-collapse:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .main-sidebar{
    display: none !important;
  }
  #main-sidebar{
    min-width: 230px !important; 
    font-family: 游ゴシック,sans-serif;
  }
}


</style>
