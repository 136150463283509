module.exports = {
  locales: [
    {
      code: 'en',
      iso: 'en-US',
      short: 'EN',
      name: 'languages.english',
      img: 'english.png',
      file: 'en.json',
      flag: 'flag-icon-us'
    },
    {
      code: 'jp',
      iso: 'jp-JP',
      short: 'JP',
      name: 'languages.japan',
      img: 'japanese.png',
      file: 'jp.json',
      flag: 'flag-icon-jp'
    }
  ],
  defaultLocale: 'jp',
  strategy: 'no_prefix',
  lazy: true,
  detectBrowserLanguage: {
    cookieKey: 'redirected',
    useCookie: true,
    alwaysRedirect: true
  },
  parsePages: false,
  pages: {}
};
