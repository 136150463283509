import request from "../utils/request";

const CS_CM = "CS_CM";

export async function getListMailBySiteId(siteId) {
     return await request({
        url: `/Mail/getListMailBySiteId/${siteId}`,
        method: "get",
        headers: {
            'PAGE-CODE': CS_CM,
        }
    });
}

export async function AddMail(payload) {
    return await request({
        url: "/Mail/add",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function DeleteMail(payload) {
    return await request({
        url: "/Mail/delete",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}
