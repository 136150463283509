<template>
  <v-dialog v-model="showDialog" class="patrol-light-custom-modal" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ $t("labels.patrolLight") }}</span>
        <v-btn @click="onClose" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <div class="mt-3">
            <v-data-table
              :headers="headers"
              :items="patrolLightList"
              sort-by="calories"
              class="elevation-2"
              mobile-breakpoint="0"
              hide-default-header
              :footer-props="{
                 'items-per-page-options': [15, 30, 50],
                 'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
              }"
            >
              <template slot="no-data">
                {{ $t("notification.noData") }}
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    style="background-color: #3d76ad"
                    :disabled="isDisableAddBtn"
                    @click="addNewLight"
                  >
                    <v-icon color="white" size="20" class="mr-2 dark mt-0">
                      mdi-plus-box
                    </v-icon>
                    <span style="font-size: 17px" class="font-weight-bold">{{
                        $t("labels.add")
                      }}</span>
                  </v-btn>
                </v-toolbar>
              </template>
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    class="text-uppercase"
                    scope="col"
                  >
                    {{ $t(header.text) }}
                  </th>
                </tr>
                </thead>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <div style="width: 60px; margin: 0 auto">
                  <v-checkbox
                    v-model="item.active"
                    :disabled="!item.isEdit"
                  >
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:[`item.ip`]="{ item }">
                <div style="width: 250px; margin: 0 auto">
                  <v-text-field
                    v-model="item.lightIp"
                    hide-details="auto"
                    outlined
                    :disabled="!item.isEdit"
                    dense
                    :rules="rules(item, 'alarmSetting.ip', 'ip')"
                    class="m-2"
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:[`item.port`]="{ item }">
                <div style="width : 150px; margin: 0 auto">
                  <v-text-field
                    v-model="item.lightPort"
                    hide-details="auto"
                    outlined
                    :disabled="!item.isEdit"
                    :rules="rules(item, 'alarmSetting.port', 'port')"
                    dense
                    maxlength="8"
                    class="m-2"
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:[`item.warningDistance`]="{ item }">
                <div style="width : 130px; margin: 0 auto">
                  <v-text-field
                    v-model="item.warningDistance"
                    hide-details="auto"
                    outlined
                    :rules="rules(item, 'alarmSetting.warningDistance', 'distance')"
                    :disabled="!item.isEdit"
                    dense
                    maxlength="3"
                    class="m-2"
                    :suffix="$t('labels.second')"
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:[`item.location`]="{ item }">
                <div style="width: 210px; margin: 0 auto">
                  <v-select v-model="item.lightLocation"
                            style="white-space: nowrap;"
                            item-value="value"
                            :items="locationList" dense
                            hide-details="true"
                            :disabled="!item.isEdit"
                            outlined>
                    <template v-slot:[`item`]="{ item }">
                      <span :key="item.value" :value="item.value">{{ $t(item.label) }}</span>
                    </template>
                    <template #selection="{item}">
                      <span >{{ $t(item.label) }}</span>
                    </template>
                  </v-select>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="!item.isEdit" style="width: 120px; margin: 0 auto">
                  <button
                    class="m-1"
                    @click.prevent="testPatrolLight(item)"
                  >
                    <font-awesome-icon v-if="!item.isTesting" icon="fa-solid fa-bolt" size="lg" :style="{ color: isUserAccess ? 'blue' : '#343a40', marginBottom: '-3px' }"/>
                    <font-awesome-icon v-else icon="fa-solid fa-bolt" size="lg" style="color: grey; margin-bottom: -3px;"/>
                  </button>

                  <button
                    class="m-1"
                    @click.prevent="editLight(item)"
                  >
                    <i v-if="isUserAccess" class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                    </i>
                    <font-awesome-icon v-else :icon="['fas', 'pencil']" size="lg" style="color: #343a40;" />
                  </button>

                  <button class="m-1"
                    @click.prevent="handleDeleteItem(item)"
                  >
                    <i v-if="isUserAccess" class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                    </i>
                    <i v-else class="fas fa-trash-alt text-dark fa-lg pt-2 pb-2">
                    </i>
                  </button>
                </div>

                <div v-else-if="item.isEdit" style="width: 120px">
                  <button
                    class="m-1"
                    @click.prevent="confirmChangeField(item)"
                  >
                    <i class="fas fa fa-check-circle text-info fa-lg pt-2 pb-2">
                    </i>
                  </button>
                  <button class="m-1"
                    @click.prevent="discardEditItem(item)"
                  >
                    <i class="fas fa-times-circle text-danger fa-lg pt-2 pb-2">
                    </i>
                  </button>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click="onClose">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import {EventBus} from "@/utils/eventBus";
import {mapActions} from "vuex";
import {getCookieSiteUser} from "@/utils/auth";
import {ResponseCode} from "@/constants/constants";
import cloneDeep from "lodash/cloneDeep";
import {getCookieUserType} from "@/utils/auth";

export default {
  name : "PatrolLightModal",
  computed: {
    isDisableAddBtn(){
      if(!this.isUserAccess){
        return true
      }
      return this.patrolLightList.length > 0 ? !this.patrolLightList[this.patrolLightList.length - 1].id : false
    },
  },
  data (){
    return {
      showDialog : false,
      loading : false,
      isChange: false,
      isUserAccess : true,
      patrolLightList : [],
      originalPatrolLightList : [],
      locationList: [
        {
          label : "labels.patrolLightInside",
          value : 0
        },
        {
          label : "labels.patrolLightOutside",
          value : 1
        }
      ],
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "index",
          width: "50px",
        },
        {
          text: "alarmSetting.active",
          value: "active",
          align: "center",
          sortable: false,
        },
        {
          text: "alarmSetting.ip",
          value: "ip",
          sortable: false,
          align: "center",
        },
        {
          text: "alarmSetting.port",
          value: "port",
          sortable: false,
          align: "center",
        },
        {
          text: "alarmSetting.warningDistance",
          value: "warningDistance",
          sortable: false,
          align: "center",
        },
        {
          text: "alarmSetting.patrolLightType",
          value: "location",
          sortable: false,
          align: "center",
        },
        {
          text: "alarmSetting.actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      ERROR_CODE : "",
      rules(item, label, type) {
        const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
        const ipcheck = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
        if(type === "ip"){
          if ((!item.lightIp && item.lightIp !== 0) || item.lightIp?.toString()?.trim().length === 0) {
            return [""]
          }
        }
        if(type === "port"){
          if ((!item.lightPort && item.lightPort !== 0) || item.lightPort?.toString()?.trim().length === 0) {
            return [""]
          }
          if(!(/^\d+$/.test(item.lightPort) || (item.lightPort === null || item.lightPort === undefined || item.lightPort.length === 0))){
            // return [this.$t(label) + this.ERROR_CODE['NOT_CORRECT_FORMAT']]
            return [""]
          }
          const isExistPort = this.patrolLightList.some(patrol => patrol.lightPort === Number(item.lightPort) && patrol.id !== item.id && patrol.lightIp === item.lightIp)
          if(isExistPort){
            // return [this.$t(label) + this.ERROR_CODE['EXIST']];
            return [""]
          }
        }
        if(type === "distance"){
          if ((!item.warningDistance && item.warningDistance !== 0) || item.warningDistance?.toString()?.trim().length === 0) {
            return [""]
          }
          if(!(/^\d+$/.test(item.warningDistance) || (item.warningDistance === null || item.warningDistance === undefined || item.warningDistance.length === 0))){
            // return [this.$t(label) + this.ERROR_CODE['NOT_CORRECT_FORMAT']]
            return [""]
          }
          // const isSameWarningDistance = this.patrolLightList.filter(patrol => patrol.lightIp === item.lightIp).every(patrol => patrol.warningDistance == item.warningDistance)
          // if(!isSameWarningDistance){
          //   // return [this.$t(label) + this.ERROR_CODE["MUST_BE_THE_SAME"]]
          //   return [""]
          // }
        }
        return [];
      },
    }
  },
  created() {
    EventBus.$on("handleOpenPatrolLightModal", (value)=>{
      this.showDialog = value.flag
    })
    this.getInitialPatrolLightList()
  },
  mounted() {
    const currentUser = getCookieUserType()
    if(currentUser == 4){
      this.isUserAccess = false
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/warning/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/warning/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods : {
    ...mapActions("warning", {
      getPatrolLightList : "getPatrolLightList",
      savePatrolLight : "savePatrolLight",
      deletePatrolLight : "deletePatrolLight",
      testPatrolLightCommand : "testPatrolLightCommand",
      autoTurnOffPatrolLight : "autoTurnOffPatrolLight"
    }),
    getInitialPatrolLightList(){
      const siteId = getCookieSiteUser();
      this.loading = true
      this.getPatrolLightList(siteId).then(response =>{
        const patrolLightList = response.data.sort((a, b)=> a.id - b.id).map((item, index)=>{
          return {
            ...item,
            active : item.active !== 0,
            index : index + 1,
            isEdit : false,
            isTesting : false
          }
        })
        const originalPatrolLightList = response.data.map((item, index)=>{
          return {
            ...item,
            active : item.active !== 0,
            index : index + 1,
            isEdit : false,
            isTesting : false
          }
        })
        this.patrolLightList = [...patrolLightList]
        this.originalPatrolLightList = [...originalPatrolLightList]
        this.loading = false
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.loading = false
      })
    },
    testPatrolLight(item){
      if(!this.isUserAccess){
        return
      }
      this.loading = true
      const status = item.isTesting ? "off" : "on"
      this.testPatrolLightCommand({item, status}).then(response=>{
        if(response && response.code){
          if((response.code !== ResponseCode.TURN_ON_SUCCESS) && (response.code !== ResponseCode.TURN_OFF_SUCCESS)){
            this.commonNotifyVue(this.ERROR_CODE[response.code], "warning");
            return
          }
          if(response.code === ResponseCode.TURN_ON_SUCCESS){
            this.autoTurnOffPatrolLight(item).then(res=>{
              if(res && res.code === ResponseCode.AUTO_TURN_OFF_SUCCESS){
                  item.isTesting = false
                  return
              }
              if(res.code === ResponseCode.CANCEL_AUTO_TURN_OFF){
                return
              }
              this.commonNotifyVue(this.ERROR_CODE[res.code], "warning");
            }).catch(err=>{
              if(err.code != undefined)
                this.commonNotifyVue(this.ERROR_CODE[err.code], "warning");
            })
          }
          this.commonNotifyVue(this.ERROR_CODE[response.code], "success");
          item.isTesting = !item.isTesting
        }
      }).catch(err=>{
        if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], "warning");
      }).finally(()=>{
        this.loading = false
      })
    },
    confirmChangeField(item){
      const ipcheck = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
      const isSameWarningDistance = this.patrolLightList.filter(patrol => patrol.lightIp === item.lightIp).every(patrol => patrol.warningDistance == item.warningDistance)
      const isExistPort = this.patrolLightList.some(patrol => patrol.lightPort === Number(item.lightPort) && patrol.id !== item.id && patrol.lightIp === item.lightIp)
      if ((!item.lightIp && item.lightIp !== 0) || item.lightIp?.toString()?.trim().length === 0) {
        //ip required || ip invalid
        this.commonErrorVue(this.$t("alarmSetting.ip") + this.ERROR_CODE['IS_REQUIRED'])
        return
      }
      if(isExistPort){
        //ip + port must not be same, ip + warning distance must be same
        this.commonErrorVue(this.$t("alarmSetting.port") + this.ERROR_CODE['EXIST'])
        return
      }
      if(
        ((item.lightPort === null || item.lightPort === undefined)) || item.lightPort.toString()?.trim().length === 0){
        this.commonErrorVue(this.$t("alarmSetting.port") + this.ERROR_CODE['IS_REQUIRED'])
        return
      }
      if(!(/^\d+$/.test(item.lightPort))){
        this.commonErrorVue(this.$t("alarmSetting.port") + this.ERROR_CODE['NOT_CORRECT_FORMAT'])
        return
      }
      if(
        ((item.warningDistance === null || item.warningDistance === undefined)) || item.warningDistance.toString()?.trim().length === 0
      ){
        this.commonErrorVue(this.$t("alarmSetting.warningDistance") + this.ERROR_CODE['IS_REQUIRED'])
        return
      }
      if(!(/^\d+$/.test(item.warningDistance))){
        this.commonErrorVue(this.$t("alarmSetting.warningDistance") + this.ERROR_CODE['NOT_CORRECT_FORMAT'])
        return
      }
      // if(!isSameWarningDistance){
      //   const message = this.$i18n.locale === "en" ? (this.ERROR_CODE["CF_063_01_03"] + item.lightIp) : (this.$t("alarmSetting.ip").replace(" ","") + " " + item.lightIp + this.ERROR_CODE["CF_063_01_03"])
      //   this.commonConfirmVue(message, ()=>{
      //     this.updatePatrolLight(item)
      //   }, ()=>{
      //
      //   })
      //   return
      // }
      this.updatePatrolLight(item);
    },
    updatePatrolLight(item){
      this.loading = true
      const data = {
        id : item.id,
        lightIp : item.lightIp,
        lightPort : Number(item.lightPort),
        siteId : item.siteId ? item.siteId : getCookieSiteUser(),
        warningDistance :Number(item.warningDistance),
        active : item.active,
        lightLocation : item.lightLocation
      }
      this.savePatrolLight(data).then((response) => {
        if (response && response.code === ResponseCode.SAVE_SUCCESS) {
          this.commonNotifyVue(this.ERROR_CODE[response.code], "success");
          this.getInitialPatrolLightList()
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], "warning");
        }
      })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], "warning");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    discardEditItem(item){
      const originalItem = this.originalPatrolLightList.find(original => original.id === item.id)
      if(originalItem){
        item.lightIp = originalItem.lightIp
        item.lightPort = originalItem.lightPort
        item.warningDistance = originalItem.warningDistance
        item.lightLocation = originalItem.lightLocation
        item.active = originalItem.active
        item.isEdit = false
        item.isTesting = false
        return
      }
      this.patrolLightList.pop()
    },
    handleDeleteItem(item){
      if(!this.isUserAccess){
        return
      }
      this.commonConfirmVue(this.ERROR_CODE["CF_063_01_02"], ()=>{
        this.deleteItem(item)
      })
    },
    deleteItem(item){
      if(item.id === null){
        this.patrolLightList.pop()
        return
      }
      this.loading = true
      this.deletePatrolLight(item.id).then(response=>{
        if (response && response.code === ResponseCode.DELETE_SUCCESS) {
          this.commonNotifyVue(this.ERROR_CODE[response.code], "success");
          this.getInitialPatrolLightList()
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], "warning");
        }
      }).catch(error=>{
          if(error.code){
            this.commonNotifyVue(this.ERROR_CODE[error.code], "warning");
          }
      }).finally(()=>{
        this.loading = false
      })
    },
    addNewLight(){
      this.patrolLightList.push({
        id : null,
        index : this.patrolLightList.length + 1,
        active : false,
        lightIp : "",
        lightPort : "",
        warningDistance : "",
        lightLocation : 0,
        isEdit: true,
        isTesting : false
      })
    },
    editLight(item){
      if(!this.isUserAccess){
        return
      }
      item.isEdit = true
    },
    onClose() {
      this.commonConfirmVue(
        this.$t("commonPopup.titleConfirmDiscard"),
        () => {
          this.showDialog = false;
          this.patrolLightList = cloneDeep(this.originalPatrolLightList)
        },
        () => {
          return;
        }
      );
    }
  },
  watch : {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      await import(`@/utils/errors/warning/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/warning/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // =========================================================== stop import ERROR_CODE by language
    },
  }
}

</script>
<style lang="scss" scoped>
::v-deep{
  .v-input__slot{
    justify-content: center;
    .v-input--selection-controls__input{
      margin: 0 auto;
    }
  }
  .v-dialog{
    margin-top: 8vh;
    max-width: 1400px;
    max-height: 85%;

    .v-card{
      .v-card__text{
        .container{
          max-width: 1240px !important;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .v-dialog{
      margin-top: 14vh;
    }
  }
  @media (max-width: 700px) {
    .v-dialog{
      margin-top: 20vh;
      max-height: 75%;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 3.25rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
