import request from "../utils/request";

const CS_CM = "CS_CM";

export async function SaveGroupMail(payload, isAddNew) {
    const url = isAddNew ? "/GroupMail/add" : "/GroupMail/update"
    return await request({
        url: url,
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function GetListGroupMailBySite(payload) {
    return await request({
        url: "/GroupMail/getListBySite",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function DeleteGroupMailById(payload) {
    return await request({
        url: "/GroupMail/delete",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function GetGroupMailById(payload) {
    return await request({
        url: "/GroupMail/getById",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}

export async function SendMailByGroupId(payload) {
    return await request({
        url: "/GroupMail/sendMail",
        method: "post",
        data: payload,
        headers: {
            'PAGE-CODE': CS_CM
        }
    });
}
