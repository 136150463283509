import { render, staticRenderFns } from "./UserProfileModal.vue?vue&type=template&id=04e16fac&scoped=true"
import script from "./UserProfileModal.vue?vue&type=script&lang=js"
export * from "./UserProfileModal.vue?vue&type=script&lang=js"
import style0 from "./UserProfileModal.vue?vue&type=style&index=0&id=04e16fac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e16fac",
  null
  
)

export default component.exports