<template>
  <v-dialog v-model="showDialog" max-width="1200px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light modal-title">
        <span class="text-h5">{{ $t("labels.mailSetting.mailList") }}</span>
        <v-btn @click="onClose" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <div class="mt-3">
            <v-data-table
              :headers="headers"
              :items="emailList"
              sort-by="calories"
              class="elevation-2"
              mobile-breakpoint="0"
              hide-default-header
              :footer-props="{
                 'items-per-page-options': [15, 30, 50],
                 'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
              }"
            >
              <template slot="no-data">
                {{ $t("notification.noData") }}
              </template>
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    class="text-uppercase"
                    scope="col"
                  >
                    {{ $t(header.text) }}
                  </th>
                </tr>
                </thead>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <h6 class="font-weight-bold">
                    {{ $t("labels.mailSetting.maillist") }}
                  </h6>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    style="background-color: #3d76ad"
                    @click="handleAddMailRow"
                    v-if="ACTION === 'OPS'"
                  >
                    <v-icon color="white" size="20" class="mr-2 dark mt-0">
                      mdi-plus-box
                    </v-icon>
                    <span style="font-size: 17px" class="font-weight-bold font-size-iphone">{{
                        $t("labels.add")
                      }}</span>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <span v-if="!item.isEdit && !item.isNew">{{ item.email }}</span>
                <v-text-field
                  v-else
                  v-model="item.email"
                  hide-details="auto"
                  outlined
                  dense
                  maxlength="100"
                  @change="handleInputMail"
                  :rules="emailRule(item, 'labels.mailSetting.email')"
                  class="m-2"
                ></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="!item.isEdit && !item.isNew">
                  <button
                    :hidden="!item.isNew"
                    class="m-1"
                    @click="editItem(item)" v-if="ACTION === 'OPS'"
                  >
                    <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                    </i>
                  </button>

                  <button class="m-1" @click="deleteMail(item)" v-if="ACTION === 'OPS'">
                    <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                    </i>
                  </button>
                </div>

                <div v-else-if="item.isNew || item.isEdit">
                  <button
                    class="m-1"
                    @click.prevent="saveMail(item)" v-if="ACTION === 'OPS'"
                    :disabled="item?.isError"
                  >
                    <i class="fas fa fa-check-circle text-info fa-lg pt-2 pb-2">
                    </i>
                  </button>
                  <button class="m-1" @click="closeItem(item)" v-if="ACTION === 'OPS'">
                    <i class="fas fa-times-circle text-danger fa-lg pt-2 pb-2">
                    </i>
                  </button>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click="onClose">
          {{ $t("labels.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/utils/eventBus";
import {getCookiePrivileges, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import {getListMailBySiteId, AddMail, DeleteMail} from "@/api/mail";
import {ResponseCode} from "@/constants/constants";

export default {
  name: "MailListModal",
  created() {
    setCurrentPageCode(this.PAGE_CODE);

    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    EventBus.$on("handleOpenMailListModal", (value) => {
      this.showDialog = value.flag;
      this.handleCommonDialog(value.flag);
      this.initListMail();
    });
  },
  data() {
    return {
      isChange: false,
      showDialog: false,
      isLoading: true,
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "index",
          width: "50px",
        },
        {
          text: "labels.mailSetting.email",
          value: "email",
          align: "start",
          sortable: false,
        },
        {
          text: "genba.table.action",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      emailList: [],
      emailRule(item, label) {
        let errorMsg = [this.$t(label) + this.ERROR_CODES["IS_REQUIRED"]];
        const index = item.index - 1;
        if (!item?.email || item?.email?.toString()?.trim().length === 0) {
          this.emailList[index].isError = true;
          return errorMsg;
        } else if (
          !item?.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.emailList[index].isError = true;
          return [this.$t(label) + this.ERROR_CODES["NOT_CORRECT_FORMAT"]];
        }else if(item.length > 100) {
          return [this.$t(label) + this.ERROR_CODES["NOT_CORRECT_FORMAT"]];
        } else {
          this.emailList[index].isError = false;
          return [];
        }
      },
      ACTION: 'OPS',
      PAGE_CODE: 'CS_MS'
    };
  },
  watch: {
    "$i18n.locale": async function () {
      // start import ERROR_CODES by language
      await this.getErrorCode("mail");
    },
    showDialog: function () {
      this.isChange = false;
      this.emailList = [];
      this.handleCommonDialog(this.showDialog);
    }
  },
  async beforeMount() {
    // import ERROR_CODES by language
    await this.getErrorCode("mail");
  },
  methods: {
    editItem(item) {
      let itemSelected = this.emailList.findIndex(
        (x) => x.index === item.index
      );
      this.emailList[itemSelected].isEdit =
        !this.emailList[itemSelected].isEdit;
    },
    deleteMail(item) {
      this.commonConfirmVue(
        this.$t("labels.mailSetting.confirmDelete"),
        () => {
          this.isLoading = true;
          const request = {
            SiteId: getCookieSiteUser(),
            Email: item.email,
          };
          DeleteMail(request)
            .then((response) => {
              const res = response.data;
              if (res.code === ResponseCode.DELETE_SUCCESS) {
                this.commonNotifyVue(this.ERROR_CODES[res.code], "success");
                this.initListMail();
              } else {
                this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
              }
            })
            .catch((err) => {
              this.commonNotifyVue(err.toString(), "warning");
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      );
    },
    saveMail(item) {
      let itemSelected = this.emailList.findIndex(
        (x) => x.index === item.index
      );
      if (item.isNew) {
        const request = {
          SiteId: getCookieSiteUser(),
          Email: item.email,
        };
        this.isLoading = true;
        AddMail(request)
          .then((response) => {
            const res = response.data;
            if (res.code === ResponseCode.SAVE_SUCCESS) {
              this.isChange = false;
              this.emailList[itemSelected].isEdit = false;
              this.emailList[itemSelected].isNew = false;
              this.commonNotifyVue(this.ERROR_CODES[res.code], "success");
            } else {
              this.emailList[itemSelected].isNew = true;
              this.emailList[itemSelected].isEdit = true;
              this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
            }
          })
          .catch((err) => {
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    closeItem(item) {
      let itemSelected = this.emailList.findIndex(
        (x) => x.index === item.index
      );
      this.emailList[itemSelected].isEdit = false;
      if (item.isNew) {
        this.emailList.splice(itemSelected, 1);
      }
    },
    handleAddMailRow() {
      this.isChange = true;
      let item = {};
      if (this.emailList === undefined) {
        this.emailList = [];
        item.index = 1;
      } else item.index = this.emailList.length + 1;
      item.email = "";
      item.option = false;
      item.isNew = true;
      item.isEdit = true;
      item.isError = false;
      this.emailList.push(item);
    },
    initListMail() {
      this.isLoading = true;
      getListMailBySiteId(getCookieSiteUser())
        .then((response) => {
          this.emailList = [];
          const res = response.data;
          if (res.code === ResponseCode.SUCCESS) {
            const mails = res?.mails;
            mails.forEach((element, index) => {
              let mail = {};
              mail.index = ++index;
              mail.oldEmail = element.email;
              mail.email = element.email;
              mail.option = false;
              mail.isNew = false;
              mail.isEdit = false;
              mail.isError = false;
              this.emailList.push(mail);
            });
          } else {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
     onClose() {
      if (this.isChange) {
        this.commonConfirmVue(
          this.$t("commonPopup.titleConfirmDiscard"),
          () => {
            this.showDialog = false;
          },
          () => {
            return;
          }
        );
      } else {
        this.showDialog = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>

::v-deep{
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
   .v-data-footer__pagination {
    margin-left: 3.25rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  .v-card {
    margin-top: 2rem !important;
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {

   .v-card {
    margin-top: 6rem !important;
   } 
  .font-size-iphone {
    font-size: 0.75rem !important;
  }
  .v-card__text {
    padding: 0 0 0 0 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
    width: 10rem !important;
  }

}
@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {

.v-card {
 margin-top: 6rem !important;
} 
}
}
</style>
