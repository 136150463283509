<template>
  <v-dialog v-model="showDialog" max-width="1500px" persistent scrollable>
    <v-card class="addNewSite-modal">
      <v-card-title class="bg-gray-light">
        <span class="text-h5" style="font-size: 1.2rem !important;">{{ $t(nameLabel) }}</span>
        <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 600px;">
        <v-container style="max-width:1435px !important;" class="pt-0 pb-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="row mt-0">
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.siteid") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="50" v-model="siteInfo.SiteId" hide-details="auto" outlined required dense
                  :disabled="checkDetail || editFlag" @change="onChangeSiteId" :rules="siteIdRules"></v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.phonenumber") }}</h6>
                </div>
                <v-text-field maxlength="30" v-model="siteInfo.PhoneNumber" hide-details="auto" outlined
                  :rules="(siteInfo.PhoneNumber !== undefined && siteInfo.PhoneNumber !== null && siteInfo.PhoneNumber.length > 0) ? phoneNumberRules : []"
                  required dense :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.sitename") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="200" v-model="siteInfo.SiteName" hide-details="auto" outlined
                  :rules="siteNameRules" required dense :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.email") }}</h6>
                </div>
                <v-text-field maxlength="100" v-model="siteInfo.Email" hide-details="auto" outlined required
                  :rules="(siteInfo.Email !== undefined && siteInfo.Email !== null && siteInfo.Email.length > 0) ? emailRules : []"
                  dense :disabled="checkDetail"></v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.status") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <div v-if="checkDetail">
                  <v-select :items="optionsStatusView" item-disabled="customDisabled" v-model="siteInfo.Status" outlined
                    required dense disabled></v-select>
                </div>
                <div v-else>
                  <v-select :items="optionsStatus" item-disabled="customDisabled" v-model="siteInfo.Status" outlined
                    required dense :rules="statusRules"></v-select>
                </div>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.siteManager.address") }}</h6>
                </div>
                <v-text-field maxlength="200" v-model="siteInfo.Address" hide-details="auto" outlined required
                  :rules="(siteInfo.Address !== undefined && siteInfo.Address !== null && siteInfo.Address.length > 0) ? addressRules : []"
                  dense :disabled="checkDetail"></v-text-field>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-2 label-black">{{ $t("labels.siteManager.pic") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-autocomplete v-model="siteInfo.PIC" :items="valuespic" :rules="picRules" outlined chips dense deletable-chips
                  multiple :disabled="checkDetail"></v-autocomplete>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-2 label-black">{{ $t("labels.siteManager.siteadmin") }}</h6>
                </div>
                <v-autocomplete v-model="siteInfo.AdminSiteSelected" :items="valueAdminSite"  outlined chips dense
                  multiple
                  :disabled="checkDetail" deletable-chips></v-autocomplete>
              </div>
            </div>
          </v-form>
          <div class="row mt-0">
            <div class="col-12">
              <div class="d-flex justify-content-start ">
                <h6 class="mb-2 label-black">{{ $t("labels.siteManager.stationAndDevice") }}</h6>
              </div>
              <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-data-table :headers="headers" :items="listTable.filter(x => x.status != 2)" sort-by="calories" show-expand :expanded.sync="expanded" item-key="index"
                  class="elevation-2" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header hide-default-footer :footer-props="{
                    'items-per-page-options': [200]
                  }">
                  <template slot="no-data">
                    {{ $t("notification.noData") }}
                  </template>
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headers" :key="header.value" class="text-uppercase" scope="col">
                          {{ $t(header.text) }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:top v-if="!checkDetail">
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" style="background-color: #3d76ad"
                        :disabled="!isFullDataLineTable" @click="createItem">
                        <v-icon color="white" size="20" class="mr-2 dark mt-0">
                          mdi-plus-box
                        </v-icon>
                        <span style="font-size: 17px" class="font-weight-bold">{{
                            $t("labels.add")
                        }}</span>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.stationid`]="{ item }">
                    <div class="container" style="min-width: 100px">
                      <v-text-field v-model="item.stationid" maxlength="50" hide-details="auto" outlined dense
                        :rules="stationIdRules" :disabled="checkDetail || (editFlag && item.siteStationDeviceId !== 0)"
                        @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.stationname`]="{ item }">
                    <div class="container width-150">
                      <v-text-field v-model="item.stationname" hide-details="auto" outlined dense maxlength="200"
                        :rules="stationNameRules" :disabled="checkDetail" @keypress.13.prevent></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.device`]="{ item }">
                    <div class="container">
                      <v-select v-model="item.device" :items="itemdevices"
                        :item-text="($i18n.locale === 'en') ? 'nameEn' : 'nameJp'" :rules="deviceRules"
                        item-value="code" hide-details="true" outlined dense
                        @change="handleChangeDevice(item)"
                        :disabled="checkDetail || (editFlag && (item.device !== undefined && item.device !== null))"
                        @keypress.13.prevent>
                      </v-select>
                    </div>
                  </template>
                  <template v-slot:[`item.database`]="{ item }">
                    <div class="container">
                      <v-select v-model="item.database" :items="item.databaseList" :item-text="'labelEN'" item-value="value"
                        hide-details="true" outlined dense :rules="item.isDeviceTS ? databaseRules : []"
                        :disabled="!item.isDeviceTS || checkDetail || (editFlag && (item.database !== undefined && item.database !== null))"
                        @keypress.13.prevent>
                      </v-select>
                    </div>
                  </template>
                  <template v-slot:[`item.ip`]="{ item }">
                    <div class="container" style="min-width: 100px">
                      <v-text-field v-model="item.ip" hide-details="auto" outlined dense maxlength="1000" :rules="ipRules"
                        :disabled="checkDetail || item.isDeviceTS" @keypress.13.prevent>

                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.port`]="{ item }">
                    <div class="container" style="min-width: 100px">
                      <v-text-field type="number" onKeyPress="if(this.value.length==5) return false;"
                        oninput="if(this.value < 0 ) this.value = 0; if(this.value > 99999 ) this.value = 99999;"
                        @keypress="isNumber($event)" v-model="item.port" hide-details="false" outlined dense
                        :rules="(item.port !== undefined && item.port !== null && item.port.length > 0) ? portRules : []"
                        :disabled="checkDetail || item.isDeviceTS" @keypress.13.prevent>

                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div style="width:120px !important;">
                      <button v-if="item.siteStationDeviceId !== undefined && item.siteStationDeviceId !== null && item.siteStationDeviceId !== 0 && [600,700,800].includes(item.device)" class="m-1" @click.prevent="openChannelModel(item)">
                        <font-awesome-icon class="fas text-success fa-lg" icon="fa-solid fa-file-medical" />
                      </button>
                      <button v-if="listTable.length > 1" class="m-1" @click.prevent="onDeleteLine(item)">
                        <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                        </i>
                      </button>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <td v-if="item.device === 100 || item.device === '100'" class="p-0" colspan=9>
                      <v-data-table
                        fixed-header locale="ja" hide-default-footer
                        :headers="channelHeaderComputed" hide-default-header
                        :items="desserts"
                        class="elevation-1"
                        mobile-breakpoint="0"
                      >
                      <template #header="{ props: {} }">
                        <thead class="v-data-table-header">
                          <tr>
                            <th style="width:56px !important;"></th>
                            <th style="width:389px !important;">{{ $t("labels.siteManager.dbConnect") }}</th>
                            <th style="width:375px !important;">{{ $t("labels.siteManager.deviceCode") }}</th>
                            <th>{{ $t("labels.siteManager.stationid") }}</th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:[`item.dbConnect`]="{}">
                        <v-text-field maxlength="200" v-model="item.dbConnect100" outlined dense hide-details="true" :disabled="checkDetail" :rules="[]"></v-text-field>
                      </template>
                      <template v-slot:[`item.deviceCode`]="{}">
                        <v-text-field maxlength="5" v-model="item.device" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </template>
                      <template v-slot:[`item.stationCode`]="{}">
                        <v-text-field maxlength="50" v-model="item.stationid" outlined dense hide-details="true" :disabled="true"></v-text-field>
                      </template>

                    </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </v-form>
            </div>
          </div>
          <div class="row mt-0" v-if="checkDetail">
            <div class="col-6">
              <h6 class="mb-1">{{ $t("labels.createdate") }}</h6>
              <v-text-field v-model="siteInfo.createDate" hide-details="auto" outlined dense :disabled="true">
              </v-text-field>
            </div>
            <div class="col-6">
              <h6 class="mb-1">{{ $t("labels.usercreate") }}</h6>
              <v-text-field v-model="siteInfo.createUser" hide-details="auto" outlined dense :disabled="true">
              </v-text-field>
            </div>
          </div>
          <div class="row mt-0" v-if="checkDetail">
            <div class="col-6">
              <h6 class="mb-1">{{ $t("labels.updatedate") }}</h6>
              <v-text-field v-model="siteInfo.updateDate" hide-details="auto" outlined dense :disabled="true">
              </v-text-field>
            </div>
            <div class="col-6">
              <h6 class="mb-1">{{ $t("labels.userupdate") }}</h6>
              <v-text-field v-model="siteInfo.updateUser" hide-details="auto" outlined dense :disabled="true">
              </v-text-field>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" text
          @click.prevent="handleSaveData">
          {{ $t("labels.siteManager.save") }}
        </v-btn>
        <v-btn v-if="checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.siteManager.close") }}
        </v-btn>
        <v-btn v-if="!checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.siteManager.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <ChannelInfoModal @handleGetSitesList="handleGetSitesList" @close="modelEdit = false"/>
  </v-dialog>


</template>

<script>
import { EventBus } from '@/utils/eventBus.js';
import ChannelInfoModal from "@/modals/Site/ChannelInfoModal";
import { mapActions } from 'vuex';
import moment from "moment";
import { checkRegexSpaceAndSpecialCharacter, validEmail, validPhone } from "@/utils/validate";
import $ from 'jquery';
import {ResponseCode, NullInt } from "@/constants/constants"

export default {
  name: "SiteEditModal",
  props: ['visible'],
  components: { ChannelInfoModal },
  data() {
    return {
      valid: false,
      valid2: false,
      loading: false,
      showDialog: false,
      checkDetail: false,
      showPassword: false,
      siteInfo: {
        SiteId: '',
        SiteName: '',
        PhoneNumber: '',
        Email: '',
        Status: 1,
        Address: '',
        PIC: null,
        AdminSiteSelected: [],
        StationSiteInfo: []
      },
      ERROR_CODE: '',
      optionsStatus: [
        { value: 1, text: this.$t('statusOptions.active') },
        { value: 2, text: this.$t('statusOptions.pause') },
        // {value: 3, text: this.$t('statusOptions.deactivate'), customDisabled: true}
      ],
      optionsStatusView: [
        { value: 1, text: this.$t('statusOptions.active') },
        { value: 2, text: this.$t('statusOptions.pause') },
        { value: 3, text: this.$t('statusOptions.deactivate') }
      ],
      valueAdminSite: [],
      valuespic: null,
      nameLabel: "",
      headers: [
        // {text: 'NO', align: 'center', sortable: false, value: 'index', width: "50px"},
        { text: 'labels.siteManager.stationid', value: 'stationid', align: 'start', sortable: false },
        { text: 'labels.siteManager.stationname', value: 'stationname', align: 'start', sortable: false },
        { text: 'labels.siteManager.device', value: 'device', align: 'center', sortable: false },
        { text: 'labels.siteManager.database', value: 'database', align: 'center', sortable: false },
        { text: 'labels.siteManager.ip', value: 'ip', align: 'center', sortable: false },
        { text: 'labels.siteManager.port', value: 'port', align: 'center', sortable: false },
        { text: 'genba.table.action', value: 'actions', sortable: false, align: 'center' },
      ],
      itemdevices: [],
      itemDatabases: [],
      //status 0: new, 1: update, 2: delete
      listTable: [{
        index: 1,
        stationid: null,
        stationname: null,
        device: null,
        isDeviceTS: false,
        database: null,
        ip: null,
        port: null,
        status: 0,
        siteStationDeviceId: 0,
        dbConnect100: null,
        deviceCode100: null,
        stationCode100: null,
        databaseList: []
      }],
      editFlag: false,
      createFlg: false,
      siteIdRules: [
        (value) => !!value || this.$t("labels.siteManager.siteid") + this.ERROR_CODE['IS_REQUIRED'], // null or empty check
        (value) => /^[a-zA-Z0-9]+$/i.test(value) || this.$t("labels.siteManager.siteid") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      siteNameRules: [
        (value) => !!value || this.$t("labels.siteManager.sitename") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.siteManager.sitename") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      phoneNumberRules: [
        (value) => /^\d+$/.test(value) || this.$t("labels.siteManager.phonenumber") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      emailRules: [
        (value) => /^[a-zA-Z0-9.]{1,200}@[a-zA-Z0-9.]{1,200}$/.test(value) || this.ERROR_CODE['EMAIL_NOT_CORRECT_FORMAT']
      ],
      statusRules: [
        (value) => (value !== undefined && value !== null && value !== 3) || this.$t("labels.siteManager.status") + this.ERROR_CODE['IS_REQUIRED']
      ],
      addressRules: [
        (value) => value && !!value.trim() || this.$t("labels.siteManager.address") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      picRules: [
        (value) => (value !== undefined && value !== null && value.length > 0) || this.$t("labels.siteManager.pic") + this.ERROR_CODE['IS_REQUIRED']
      ],
      stationIdRules: [
        (value) => !!value || "",
        (value) => (/^[a-zA-Z0-9]+$/i.test(value)) || "",
        (value) => (!!value && value.length) <= 50 || ""
      ],
      stationNameRules: [
        (value) => !!value || "",
        (value) => value?.trim().length !== 0 || "",
      ],
      deviceRules: [
        (value) => !!value || "",
      ],
      ipRules: [],
      portRules: [
        (value) => !!value || "",
        (value) => /^\d+$/.test(value) || "",
      ],
      databaseRules: [
        (value) => !!value || "",
      ],
      desserts: [
        {
          no:'',
          dbConnect: null,
          deviceCode: null,
          stationCode: null
        }
      ],
      requiredRule(value, label) {
        const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
        if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
          return errorMsg
        } else {
          return [];
        }
      },
      expanded: []

    }
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
    }
  },
  beforeDestroy() {
    this.loading = false;
  },
  computed: {
    isFullDataLineTable() {
      if (this.listTable.length > 0) {
        const item = this.listTable[this.listTable.length - 1];
        return item.stationid !== null && item.stationname !== null && item.device !== null;
      }
      return true;
    },
    channelHeaderComputed() {
      return [
        { text: this.$t('channel.no'),align: 'start',sortable: false,value: 'no'},
        { text: this.$t('channel.no'),align: 'start',sortable: false,value: 'dbConnect',},
        { text: this.$t('channel.no'), value: 'deviceCode' },
        { text: this.$t('channel.name'), value: 'stationCode' },
      ]
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/site/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/site/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      this.optionsStatusView = [
        { value: 1, text: this.$t('statusOptions.active') },
        { value: 2, text: this.$t('statusOptions.pause') },
        { value: 3, text: this.$t('statusOptions.deactivate') },
      ];
      this.optionsStatus = [
        { value: 1, text: this.$t('statusOptions.active') },
        { value: 2, text: this.$t('statusOptions.pause') },
        // this.createFlg === false ? {value: 3, text: this.$t('statusOptions.deactivate'), customDisabled: true} : null
      ];
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
    'checkDetail': function (val) {
      if (val) {
        this.headers = [
          // {text: 'NO', align: 'start', sortable: false, value: 'index', width: "50px"},
          { text: 'labels.siteManager.stationid', value: 'stationid', align: 'start', sortable: false },
          { text: 'labels.siteManager.stationname', value: 'stationname', align: 'start', sortable: false },
          { text: 'labels.siteManager.device', value: 'device', align: 'center', sortable: false },
          { text: 'labels.siteManager.database', value: 'database', align: 'center', sortable: false },
          { text: 'labels.siteManager.ip', value: 'ip', align: 'center', sortable: false },
          { text: 'labels.siteManager.port', value: 'port', align: 'center', sortable: false },
        ]
      } else {
        this.headers = [
          // {text: 'NO', align: 'center', sortable: false, value: 'index', width: "50px"},
          { text: 'labels.siteManager.stationid', value: 'stationid', align: 'start', sortable: false },
          { text: 'labels.siteManager.stationname', value: 'stationname', align: 'start', sortable: false },
          { text: 'labels.siteManager.device', value: 'device', align: 'center', sortable: false },
          { text: 'labels.siteManager.database', value: 'database', align: 'center', sortable: false },
          { text: 'labels.siteManager.ip', value: 'ip', align: 'center', sortable: false },
          { text: 'labels.siteManager.port', value: 'port', align: 'center', sortable: false },
          { text: 'genba.table.action', value: 'actions', sortable: false, align: 'center' },
        ]
      }
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/site/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/site/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    EventBus.$on('handleOpenSiteManagementEditModal', (value) => {
      this.handleGetCbbPic();
      this.handleGetCbbAdminSite();
      this.handleGetCbbDevice();
      this.handleGetCbbDatabase();
      if (value.createFlg) {
        this.optionsStatus = this.optionsStatus.filter(x => x.value !== 3);
        this.optionsStatusView = this.optionsStatusView.filter(x => x.value !== 3);
      } else if (value.checkDetail || value.editFlag) {
        this.handleGetSiteDetails(value);
      }
      this.showDialog = value.flag;
      if (value.flag) {
        $('#main-header').css("pointer-events", "none");
      } else {
        $('#main-header').css("pointer-events", "");
      }
      this.checkDetail = value.checkDetail;
      this.nameLabel = value.nameLabel;
      this.editFlag = value.editFlag;
      this.createFlg = value.createFlg;
      this.$refs.form?.resetValidation();
      this.$refs.form2?.resetValidation();
      this.expanded = [];
    });
  },
  mounted() {
  },
  methods: {
    ...mapActions("sites", {
      addNewSite: "addNewSite",
      getCbbUserList: "getCbbUserList",
      getSiteDetail: "getSiteDetail",
      updateSite: "updateSite",
      getCbbDatabase: "getCbbDatabase",
      saveChannel: "saveChannel"
    }),
    ...mapActions("device", {
      getCbbDevice: "getCbbDevice"
    }),
    onChangeSiteId(e) {
      let res = /^[a-zA-Z]+$/.test(this.siteInfo.SiteId);
      if (!res) {
        return;
      }
    },
    onBlurName(item) {
      this.listTable = this.listTable.map((i) => {
        if (i.stationid === item.stationid) {
          i.stationname = item.stationname
        }
        return i;
      })
    },
    handleGetSiteDetails(value) {
      this.loading = true;
      this.getSiteDetail(value.editedItem.id)
        .then(response => {
          if (response.code === 0) {
            this.siteInfo = {
              SiteId: response.data.siteId,
              SiteName: response.data.siteName,
              PhoneNumber: response.data.phoneNumber,
              Email: response.data.email,
              Status: response.data.status,
              Address: response.data.address,
              PIC: response.data.pic,
              AdminSiteSelected: response.data.adminSiteSelected,
              createDate: moment(response.data.createdDate).format('YYYY/MM/DD'),
              updateDate: moment(response.data.updatedDate).format('YYYY/MM/DD'),
              createUser: response.data.createdBy,
              updateUser: response.data.updatedBy
            }
            this.siteInfo.StationSiteInfo = response.data.stationSiteInfo.map((item, index) => {
              return { ...item, index: index, status: 1, siteStationDeviceId: item.siteStationDeviceId };
            });
            this.listTable = response.data.stationSiteInfo.map((item, index) => {
              return { ...item, index: index + 1, status: 1, siteStationDeviceId: item.siteStationDeviceId };
            });
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    createItem() {
      var stationInfo = {};
      stationInfo.index = this.listTable.length + 1;
      stationInfo.stationid = null;
      stationInfo.stationname = null;
      stationInfo.device = null;
      stationInfo.isDeviceTS = false;
      stationInfo.database = null;
      stationInfo.ip = null;
      stationInfo.port = null;
      stationInfo.status = 0;
      stationInfo.siteStationDeviceId = 0;
      stationInfo.dbConnect100=null;
      stationInfo.deviceCode100=null;
      stationInfo.stationCode100=null;
      stationInfo.databaseList = [];
      this.listTable.push(stationInfo);
    },
    onDeleteLine(item) {
      var index = this.listTable.map(x => {
        return x.index;
      }).indexOf(item.index);
      if (this.listTable[index].status === 0) {
        this.listTable.splice(index, 1);
      } else if (this.listTable[index].status === 1) {
        this.listTable[index].status = 2;
      }
    },
    handleSaveData() {
      this.$refs.form.validate()
      if (!this.$refs.form.validate()) {
        return;
      }
      let reWhiteSpace = new RegExp("^[a-zA-Z0-9]*$");
      let checkStation = true;
      this.listTable.forEach(element => {
        if (!element.stationid || element.stationid?.trim()?.length === 0) {
          this.commonErrorVue(this.$t("labels.siteManager.stationid") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkStation = false;
          return;
        } else if (element.stationid && !reWhiteSpace.test(element?.stationid)) {
          this.commonErrorVue(this.$t("labels.siteManager.stationid") + this.ERROR_CODE['NOT_CORRECT_FORMAT'], 'warning');
          this.$refs.form2.validate()
          checkStation = false;
          return;
        }
        else if (!element.stationname || element.stationname?.trim()?.length === 0) {
          this.commonErrorVue(this.$t("labels.siteManager.stationname") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkStation = false;
        }
        else if (!element.device) {
          this.commonErrorVue(this.$t("labels.siteManager.device") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkStation = false;
        }
        else if (element.isDeviceTS && !element.database) {
          this.commonErrorVue(this.$t("labels.siteManager.database") + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkStation = false;
        }
      });
      if (!checkStation) {
        return;
      }
      this.commonConfirmVue(this.ERROR_CODE['CONFIRM_UPDATE_SITE'], () => {
        this.siteInfo.StationSiteInfo = this.listTable;
        this.loading = true;
        if (this.editFlag) {
          this.handleUpdateSite();
        } else {
          this.handleAddNewSite();
        }
      }, () => { });

    },
    handleAddNewSite() {
      this.addNewSite(this.siteInfo)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetSitesList', {});
            this.loading = false;
            this.handleResetFormItem();
            this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK'])
          } else {
            this.loading = false;
            let error_msg = this.ERROR_CODE[response.code];
            if (response.code === 'E_051_05') {
              let userErr = response.additionalContent.userErr;
              error_msg = error_msg.replace('%s', userErr);
            }
            if (response.code === 'E_051_06') {
              let userErr = response.additionalContent.userErr;
              error_msg = error_msg.replace('%s', userErr);
            }
            this.commonNotifyVue(error_msg, 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleUpdateSite() {
      this.updateSite(this.siteInfo)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetSitesList', {});
            this.loading = false;
            this.handleResetFormItem();
            this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK'])
          } else {
            this.loading = false;
            let error_msg = this.ERROR_CODE[response.code];
            if (response.code === 'E_051_05') {
              let userErr = response.additionalContent.userErr;
              error_msg = error_msg.replace('%s', userErr);
            }
            if (response.code === 'E_051_06') {
              let userErr = response.additionalContent.userErr;
              error_msg = error_msg.replace('%s', userErr);
            }
            this.commonNotifyVue(error_msg, 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleGetCbbPic() {
      this.getCbbUserList(2)
        .then(response => {
          if (response.code === 0) {
            this.valuespic = response.data;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleGetCbbAdminSite() {
      this.getCbbUserList(3)
        .then(response => {
          if (response.code === 0) {
            this.valueAdminSite = response.data
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleGetCbbDevice() {
      this.getCbbDevice({ siteId: 0 })
        .then(response => {
          if (response.code === 0) {
            this.itemdevices = response.data?.deviceList;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleGetCbbDatabase() {
      var request = {
        deviceCode: 1000
      }
      this.getCbbDatabase(request)
        .then(response => {
          if (response.code === 0) {
            this.itemDatabases = response.data;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
          this.showDialog = false
          $('#main-header').css("pointer-events", "");
          this.handleResetFormItem();
        });
      } else {
        this.showDialog = false;
        $('#main-header').css("pointer-events", "");
        this.handleResetFormItem();
      }
    },
    handleResetFormItem() {
      this.siteInfo = {
        SiteId: '',
        SiteName: '',
        PhoneNumber: '',
        Email: '',
        Status: 1,
        Address: '',
        PIC: null,
        AdminSiteSelected: [],
        StationSiteInfo: []
      };
      this.listTable = [{
        index: 1,
        stationid: null,
        stationname: null,
        device: null,
        isDeviceTS: false,
        ip: null,
        port: null,
        status: 0,
        siteStationDeviceId: 0
      }];
      this.$refs.form?.resetValidation();
      this.$refs.form2?.resetValidation();
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleSaveDataChannel(item){
      this.saveChannel({...item, siteId: "123", siteStationId: "ST1", deviceCode: 600}).then((response) => {
          if (response.code === ResponseCode.SAVE_SUCCESS) {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'success');
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        }).catch((error) => {
          if(error.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        }).finally(() => {
          this.loading = false;
          this.handleGetWarningList();
        })
      // item.isEdit = false
    },
    openChannelModel(item) {
      EventBus.$emit('handleOpenChannelModel', {
        flag: true,
        checkDetail: false,
        nameLabel: "labels.siteManager.create",
        editFlag: false,
        createFlg: true,
        siteId: this.siteInfo.SiteId,
        siteStationId: item.stationid,
        deviceCode: item.device,
        ip: item.ip,
        port: item.port
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events", "none");
    },
    handleChangeDevice(item) {
      console.log("abc");

      const curDevice = this.itemdevices.find(x => x.code == item.device);
      const isDeviceTS = curDevice.isDeviceTs;
      console.log(curDevice);
      console.log(isDeviceTS);
      /* eslint-disable */
      debugger;
      let indexTable = this.listTable.findIndex((e => e.index === item.index));
      this.listTable[indexTable].isDeviceTS = isDeviceTS;
      console.log(this.listTable[indexTable]);
      if(isDeviceTS) {
        var request = {
          deviceCode: item.device
        }
        this.getCbbDatabase(request)
          .then(response => {
            if (response.code === 0) {
              this.listTable[indexTable].databaseList = response.data;
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            if (err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
            this.isSubmitAction = false;
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 100px;
  left: 0;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}

.v-input--has-state fieldset {
  border: 2px solid red !important;
}

.v-messages__message {
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}

.input-port input[type='number'] {
  -moz-appearance: textfield;
}

.input-port input::-webkit-outer-spin-button,
.input-port input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.width-150{
  width: 150px !important;
}

@media only screen and (max-width: 500px) and (max-height: 900px) {
  .addNewSite-modal {
    margin-top: 5vh !important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1366px) {
  .v-data-table>.v-data-table__wrapper>table {
    width: 66rem !important;
  }
}

@media only screen and (max-width: 500px) and (max-height: 900px) {
  .v-data-table>.v-data-table__wrapper>table {
    width: 75rem !important;
  }
}
::v-deep{
  .v-dialog{
    overflow: hidden;
  }
  .v-card__text{
    width: 100% !important;
  }
  @media (max-width: 800px) {
    .v-card__text{
      .container{
        padding : 0;
      }
    }
  }
  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.75rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 0.75rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
    .v-application--is-ltr .v-data-footer__pagination {
      margin-left: 4.55rem !important;
    }
    //.v-card__text {
    //  margin-left: -1rem !important;
    //  width: 115% !important;
    //}
    .v-data-table>.v-data-table__wrapper>table {
    width: 1300px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
