<template>
    <div class="row">
        <div class="col-sm-12 card m-0 p-0 mb-5">
            <div class="card-header pt-3 pb-3">
                <div class="row m-1">
                    <div class="col-sm-6 col-lg-6">
                        <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">
                                {{ $t("labels.framesetting.frametype") }}
                            </span>
                        </div>
                        <div class="input-group-append">
                            <v-select
                              :items="optionsTypes"
                              v-model="selectedTypeFrame"
                              item-value="code"
                              :item-text="$i18n.locale === 'en' ? 'nameEn':'nameJp'"
                              dense outlined
                            >
                            </v-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">
                                {{ $t("labels.framesetting.framename") }}
                            </span>
                        </div>
                        <div class="input-group-append">
                            <v-text-field v-model="frameSearchInfo.FrameName" hide-details="auto" outlined dense>
                            </v-text-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mr-3">
                    <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetListFrame">
                        <v-icon right dark class="mr-1">
                            fas fa-search
                        </v-icon>
                        {{ $t("labels.search") }}
                    </v-btn>
                    <v-btn class="bg-secondary font-weight-bold" @click.prevent="clearFilter">
                      <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
                        {{ $t("labels.clear") }}
                    </v-btn>
                </div>
            </div>
            <div class="card-body p-3 m-2">
                <div class="col-sm-12 col-lg-12 text-nowrap" data-app>
                    <v-data-table
                        :headers="headers" :items="frameList" sort-by="calories" class="elevation-2" mobile-breakpoint="0"
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50],
                            'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
                        hide-default-header
                    >
                        <template slot="no-data">
                          {{ $t("notification.noData") }}
                        </template>
                        <template #header="{ props: { headers } }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th v-for="header in headers" :key="header.value" class="text-uppercase"
                                        scope="col">
                                        {{ $t(header.text) }}
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" style="background-color: #3d76ad" @click="createItem" v-if="ACTION === 'OPS'">
                                    <v-icon color="white" size="20" class="mr-2 dark mt-0">
                                        mdi-plus-box
                                    </v-icon>
                                    <span style="font-size: 17px" class="font-weight-bold">{{ $t("labels.add") }}</span>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                            <span v-on="on">{{ item.name.slice(0, 20) }}</span>
                            <span v-if="item.name.length > 20">...</span>
                            </template>
                            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.name }}</span>
                        </v-tooltip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div>
                                <button class="m-1" @click="detailItem(item)">
                                    <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                                    </i>
                                </button>
                                <button class="m-1" @click="editItem(item)" v-if="ACTION === 'OPS'">
                                    <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                                    </i>
                                </button>
                                <button @click="confirmDeleteItem(item)" class="m-1" v-if="ACTION === 'OPS'">
                                    <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                                    </i>
                                </button>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </div>
        <FrameModal @handleGetListFrame="handleGetListFrame" @close="modelEdit = false" :frameList="frameList"></FrameModal>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import FrameModal from "@/modals/FrameSetting/FrameModal"
import { EventBus } from "@/utils/eventBus";
import { mapActions } from 'vuex';
import moment from "moment";
import {
  getCookiePrivileges,
  getCookieSiteUser, setCurrentPageCode
} from "@/utils/auth";
import $ from 'jquery';

export default {
    name: "SettingFrame",
    components: {
        FrameModal
    },
    data() {
        return {
            loading: false,
            modelEdit: false,
            modelCreate: false,
            frameList: [],
            selectedTypeFrame: null,
            headers: [
                { text: '#', align: 'center', sortable: false, value: 'index', width: "50px" },
                { text: 'labels.framesetting.framename', value: 'name', align: 'start', sortable: false },
                { text: 'labels.framesetting.frametype', value: 'frameTypeName', align: 'start', sortable: false },
                { text: 'genba.table.createdUser', value: 'createdBy', align: 'start', sortable: false },
                { text: 'genba.table.createdDate', value: 'createdDate', align: 'center', sortable: false },
                { text: 'genba.table.updatedUser', value: 'updatedBy', align: 'start', sortable: false },
                { text: 'genba.table.updatedDate', value: 'updatedDate', align: 'center', sortable: false },
                { text: 'genba.table.action', value: 'actions', sortable: false, align: 'center' },
            ],
            temps: [],
            editedItem: {},
            frameSearchInfo: {
                SiteId: "SI001",
                FrameName: '',
                FrameType: '',
                pageNum: 1,
                pageSize: 5
            },
            optionsTypes: [
            ],
            PAGE_CODE: 'CS_GM',
            ACTION: 'OPS',
        }
    },
    watch: {
        '$i18n.locale': async function () {
            this.handleGetListFrame();
            // start import ERROR_CODES by language
            await this.getErrorCode("frames");
        }
    },
    async beforeMount() {
        // import ERROR_CODES by language
        await this.getErrorCode("frames");
    },
    created() {
        setCurrentPageCode(this.PAGE_CODE);
        const menuAction = getCookiePrivileges();
        if (menuAction !== undefined && menuAction !== null) {
          JSON.parse(menuAction).filter(value => {
            if (value.children !== undefined && value.children.length > 0) {
              value.children.filter(child => {
                (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
              })
            } else {
              (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
            }
          })
        }

        // this.initialize();
        this.handleGetListTypeFrame();
        this.handleGetListFrame();
    },
    methods: {
        ...mapActions("frame", {
            getListFrame: "getListFrame",
            getTypeFrame: "getTypeFrame",
            deleteFrame: "deleteFrame"
        }),
        handleGetListTypeFrame() {
          const siteId = getCookieSiteUser();
          this.getTypeFrame({siteId: siteId, flag: 0}).then(response => {
                if (response.code === 0) {
                    this.optionsTypes = response.data;
                }
            }).catch(error => {
                this.commonWarningVue(this.ERROR_CODES[error.code], 'warning');
            }).finally(() => {

            })
        },
        clearFilter() {
            this.frameSearchInfo.FrameName = "";
            this.frameSearchInfo.FrameType = "";
            this.selectedTypeFrame = null;
            this.handleGetListFrame();
        },
        handleGetListFrame() {
          const currentLang = this.$i18n.locale
          this.frameSearchInfo.SiteId = getCookieSiteUser();
          this.frameSearchInfo.FrameType = this.selectedTypeFrame;
        //   this.frameSearchInfo.FrameName = this.frameSearchInfo.FrameName.trim();
          this.loading = true;

          this.getListFrame(this.frameSearchInfo)
                .then(response => {
                    if (response.code === 0) {
                        this.frameList = response.data.frameList;
                        if (response.data.frameList !== null && response.data.frameList.length > 0) {
                            for (let i = 0; i < this.frameList.length; i++) {
                                this.frameList[i].index = i + 1;
                                this.frameList[i].frameTypeName = currentLang === 'en' ? this.frameList[i].typeFrameNameEn : this.frameList[i].typeFrameNameJp;
                                this.frameList[i].createdDate = moment(this.frameList[i].createdDate).format('YYYY/MM/DD');
                                this.frameList[i].updatedDate = moment(this.frameList[i].updatedDate).format('YYYY/MM/DD');
                            }
                        }
                    } else {
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                    }
                })
                .catch(err => {
                    if(err.code != undefined)
                        this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                })
                .finally(final => {
                    this.loading = false;
                    this.isSubmitAction = false;
                });
        },
        initialize() {
        },
        createItem() {
            this.editedItem = {}
            this.editedItem.modurn = "LS";
            this.editedItem.listTable = [];
            this.modelCreate = true
            $('#main-header').css("pointer-events","none");
            EventBus.$emit('handleOpenFrameSettingEditModal', {
                editedItem: this.editedItem,
                flag: true,
                checkDetail: false,
                nameLabel: "labels.framesetting.add",
                editFlag: false
            });
            this.modelEdit = true;
            $('#main-header').css("pointer-events","none");
        },
        detailItem(item) {
            this.editedItem = Object.assign({}, item)
            EventBus.$emit('handleOpenFrameSettingEditModal', {
                editedItem: this.editedItem,
                flag: true,
                checkDetail: true,
                nameLabel: "labels.framesetting.detail",
                editFlag: false
            });
            this.modelDetail = true
        },
        editItem(item) {
            this.editedItem = Object.assign({}, item)
            this.modelCreate = true
            $('#main-header').css("pointer-events","none");
            EventBus.$emit('handleOpenFrameSettingEditModal', {
                editedItem: this.editedItem,
                flag: true,
                checkDetail: false,
                nameLabel: "labels.framesetting.update",
                editFlag: true
            });
            this.modelEdit = true;
            $('#main-header').css("pointer-events","none");
        },
        confirmDeleteItem(item) {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            this.commonConfirmVue(this.$t('labels.framesetting.msgDelete'), () => {
                this.deleteFrame({SiteId: siteID, FrameId:item.id})
                    .then(response => {
                        if (response.code === 0) {
                            this.handleGetListFrame();
                            this.commonConfirmOKVue(this.ERROR_CODES['S_CM_01'])
                        } else {
                            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                        }
                    })
                    .catch(err => {
                        if(err.code != undefined)
                            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                    })
                    .finally(final => {
                        this.isSubmitAction = false;
                    });
            }, () => {
                this.siteIdDeleteSelected = 0;
            })
        },

    },
}
</script>

<style lang="scss" scoped>

thead tr th {
  border: 2px solid #ebebeb !important;
  text-align: center !important;
  font-size: .85rem !important;
  background-color: #DDDFEB !important;
  color: #000 !important;
}


::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
    .v-data-footer__pagination {
    margin-left: 6.55rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
