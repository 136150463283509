import request from "../utils/request";

const CS_LA = "CS_LA"

export function updateLayout(data) {
    return request({
        url: "/Layout/updateLayout",
        method: "post",
        data: data.data,
        headers: {
            siteId: data.header,
            'PAGE-CODE': CS_LA
        }
    });
}

export function getLayout(siteId) {
    return request({
        url: "/Layout/getLayout",
        method: "get",
        headers: {
            siteId: siteId,
            'PAGE-CODE': CS_LA
        }
    });
}

export function removeLayout(data) {
    return request({
        url: `/Layout/removeLayout/${data.siteId}`,
        method: "post",
        data: data.data,
        headers: {
            'PAGE-CODE': CS_LA
        }
    });
}
