<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">

        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.frameType")
                }}</span>
            </div>
            <div class="input-group-append">
              <!-- <v-autocomplete v-on:change="onChangeDeviceOptions" :items="deviceList" dense outlined>
              </v-autocomplete> -->
              <v-select v-model="frameTypeSelected" :items="frameTypeList" v-on:change="handleGetFramesByType"
                        :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value" dense outlined
                        :disabled="checkDetail">
              </v-select>
            </div>

          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.frameName")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-select key="id"
                          :item-text="'name'"
                          :item-value="'id'"
                          :no-data-text="$t('exportData.noData')"
                          v-model="selectedFrame" :items="frameList" @change="handleGetCbbDeviceByFrame"
                          :disabled="frameTypeSelected == '0'" outlined dense>
                </v-select>
            </div>
          </div>
        </div>

        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.device")
                }}</span>
            </div>
            <div class="input-group-append">
              <!-- <v-autocomplete v-on:change="onChangeDeviceOptions" :items="deviceList" dense outlined>
              </v-autocomplete> -->
              <v-select v-if="frameTypeSelected === '0'" v-model="deviceCode" :items="frameTypeSelected === '0' ? deviceList : deviceListByFrame" @change="onChangeDeviceOptions"
                        :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value" dense outlined
                        :disabled="checkDetail">
              </v-select>
              <v-autocomplete v-else :items="deviceListByFrame" chips v-model="deviceListByFrame"
                        multiple
                        dense outlined deletable-chips
                        :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'"
                        :disabled="true">
              </v-autocomplete>
            </div>

          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.stationName")
                }}</span>
            </div>
            <div v-if="frameTypeSelected === '0'" class="input-group-append">
              <v-autocomplete v-if="!isDevice1000" :disabled="deviceCode === null" :items="handleLabelItems(stationList)" chips
                              v-model="valueStation"
                              multiple
                              dense item-text="label" item-value="value" outlined deletable-chips>
              </v-autocomplete>
              <v-select v-else v-model="valueStationMainTS" :items="handleLabelItems(stationList)"
                        item-text="label" item-value="value" dense outlined @change="handleChangeStation(e)"
                        :disabled="deviceCode === null">
              </v-select>
            </div>
            <div v-else class="input-group-append">
              <v-autocomplete :items="handleLabelItems(stationList)" chips v-model="stationList"
                        multiple
                        dense outlined deletable-chips
                        item-text="label"
                        :disabled="true">
              </v-autocomplete>
            </div>
          </div>
        </div>

        <div v-if="isDevice1000 && frameTypeSelected === '0'" class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{$t("exportData.stationTs")}}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="tsStationLst" v-model="tsStationSearch" @change="handleChangeTsStation(e)"
                        :item-text="($i18n.locale === 'en') ? 'name' : 'nameJp'" item-value="value" dense outlined
                        :disabled="checkDetail || valueStationMainTS == null">
              </v-select>
            </div>

          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{$t("exportData.orgNo")}}</span>
            </div>
            <div class="input-group-append">
              <v-select :items="ItemOrgNo" v-model="orgNoSearch" :disabled="checkDetail || tsStationSearch == null"
                              dense :item-text="($i18n.locale === 'en') ? 'orgNameEn' : 'orgName'" item-value="orgNo" outlined deletable-chips>
              </v-select>
            </div>
          </div>
        </div>

        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.date")
                }}</span>
            </div>
            <div class="input-group-append input-group-append-ipad">
              <div class="w-100 d-flex">
                <div class="" style="width: 48%;">
                  <div class="row input-date-mg-ip">
                    <div class="col-xl-12 col-lg-12 col-12">
                      <v-menu ref="menu" v-model="menuFrom" :close-on-content-click="false"
                              transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :readonly="true" v-model="fromFormatted"
                                        append-icon="mdi-calendar" v-bind="attrs" v-on="on" hide-details="auto" outlined
                                        dense
                                        @click:append="menuFrom = true" :disabled="checkDetail"
                                        @blur="dateFrom = parseDate(fromFormatted)">
                          </v-text-field>
                        </template>
                        <v-date-picker :disabled="checkDetail" :allowed-dates="disableStartDates" v-model="dateFrom"
                                       no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.menu.save(dateFrom); menuFrom = false">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <div style="width: 8%;" class="d-lg-flex justify-content-center sign-ipad sign-iphone">
                  <b class="m-0 mt-1" style="font-size: 20px;">〜</b>
                </div>
                <div class="" style="width: 48%;">
                  <div class="row justify-content-end input-date-mg-ip">
                    <div class="col-xl-12 col-lg-12 col-12 pl-3 input-end-date">
                      <v-menu ref="menu" v-model="menuTo" :close-on-content-click="false" transition="scale-transition"
                              offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :readonly="true" v-model="toFormatted" :disabled="checkDetail"
                                        append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                        hide-details="auto"
                                        outlined dense @click:append="menuTo = true"
                                        @blur="dateTo = parseDate(toFormatted)">
                          </v-text-field>
                        </template>
                        <v-date-picker :disabled="checkDetail" :allowed-dates="disableEndDates" v-model="dateTo"
                                       no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.menu.save(dateTo); menuTo = false">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                  $t("exportData.outputInterval")
                }}</span>
            </div>
            <div class="input-group-append">
              <v-select v-model="displayInterval" :items="displayIntervalList" :disabled="!isEnableInterval"
                        :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value" dense outlined>
              </v-select>
            </div>
          </div>
        </div>
         <!-- // -->
         <div class="d-flex justify-content-end mr-3" style="padding-right: 18px;">
          <v-btn @click="onSearch()" class="bg-success mr-3 font-weight-bold">
            <v-icon right dark class="mr-1">
              fas fa-search
            </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
          <v-btn class="bg-secondary font-weight-bold" @click.prevent="clearFilter">
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{ $t("labels.clear") }}
          </v-btn>
        </div>
        <div v-if="Number(deviceCode) == 1000" class="d-flex justify-content-start ml-6 select-ts-group mt-10">
          <div class="ts-button">
            <v-checkbox style="margin-right: 0px !important; margin: 0px !important;" v-model="tsConfigCheck"
                        color="primary" hide-details>
            </v-checkbox>
            <span class="checkboxLabel label-black">{{ $t("exportData.tsConfig") }}</span>
          </div>
          <div class="group-ts-config m-information">
            <span class="checkboxLabel label-black ">{{ $t("exportData.mInformation") }}</span>
            <v-select class="select-input-group input-group-custom" v-model="MValue" :items="MList"
                      :disabled="!tsConfigCheck" dense
                      outlined>
            </v-select>
          </div>
          <div class="group-ts-config threshold">
            <span class="checkboxLabel label-black ">{{ $t("exportData.threshold") }}</span>
            <v-select class="select-input-group input-group-custom" v-model="ThresholdValue" :items="ThresholdList"
                      :disabled="!tsConfigCheck"
                      dense outlined>
            </v-select>
          </div>

          <v-btn v-on="on" v-bind="attrs" depressed color="primary" :disabled="!tsConfigCheck"
                 style="background-color: #3c8dbc" class="save-button"
                 @click.prevent="handleSaveTs">
            <span>{{ $t("exportData.save") }}</span>
          </v-btn>
        </div>

      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table v-if="valueStationSelected.length === 0 && valueStationMainTS === null && headers.length === 0 && headersTS.length === 0" hide-default-header hide-default-footer
                        :headers="headers" :items="[]" sort-by="calories" class="elevation-2" mobile-breakpoint="0">
            <template v-slot:no-data>
              <v-toolbar flat>

                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" depressed color="primary" style="background-color: #3c8dbc;"
                           v-if="ACTION === 'OPS'"
                           disabled="true">
                      <span style="font-size: 20px">{{ $t("exportData.displaySetting") }}</span>
                    </v-btn>
                  </template>
                </v-menu>
                <v-btn depressed color="primary" style="background-color: #3c8dbc;margin-left:10px ;" disabled="true" v-if="ACTION === 'OPS'">
                  <span style="font-size: 20px">{{ $t("exportData.export") }}</span>
                </v-btn>
                <v-btn depressed color="primary" style="background-color: #3c8dbc;margin-left:10px ;"
                       v-if="ACTION === 'OPS'"
                       @click="openExportSetting(1)">
                  <span style="font-size: 20px">{{ $t("exportData.settingExport") }}</span>
                </v-btn>

              </v-toolbar>
              {{ $t("exportData.noData") }}
            </template>
          </v-data-table>
          <v-data-table
            v-else :hide-default-header="!isDeviceTsOrSimilarTs(Number(deviceCodeSearched)) ? true : false"
            :headers="!isDeviceTsOrSimilarTs(Number(deviceCodeSearched)) ? headers : headersTS" :items="dataList" sort-by="calories"
            :footer-props="{
              'items-per-page-options': [15, 30, 50],
              'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
            }"
            class="elevation-2" mobile-breakpoint="0" :page="pageNum" :pageCount="pageSize" :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:no-data>
              {{ $t("notification.noData") }}
            </template>
            <template v-slot:top>
              <v-toolbar class="exportData-header" flat>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" depressed color="primary" style="background-color: #3c8dbc;" :disabled="frameTypeSelected !== '0' || channelFlg == 1">
                      <span class="exportData-displaySetting" style="font-size: 20px">{{ $t("exportData.displaySetting") }}</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <p v-if="itemsTreeview.length < 1" class="margin-center">{{ $t("notification.noData") }}</p>
                    <v-treeview selectable :items="itemsTreeview" item-disabled="locked"
                                item-="locked"
                                v-model="selectedManual"
                                selected-color="#0d6efd"
                                selection-type="leaf"
                                open-all
                                :open-on-click="true"
                                :return-object="true">
                    </v-treeview>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="text-white mr-1 mb-0" style="background-color: #3d76ad" text
                             @click="setSelectedItem">
                        {{ $t("exportData.save") }}
                      </v-btn>
                      <v-btn style="color: white !important;background-color: gray;" text @click="cancelSelectItem">
                        {{ $t("exportData.cancel") }}
                      </v-btn>

                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-btn depressed color="primary" style="background-color: #3c8dbc;margin-left:10px ;"
                       @click="openExport(1)">
                  <div class="exportData-export" style="font-size: 20px">{{ $t("exportData.export") }}</div>
                </v-btn>
                <v-btn depressed color="primary" style="background-color: #3c8dbc;margin-left:10px ;"
                       @click="openExportSetting(1)">
                  <div class="exportData-settingExport"  style="font-size: 20px">{{ $t("exportData.settingExport") }}</div>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:header="{ props: {} }">
              <thead v-if="!isDeviceTsOrSimilarTs(Number(deviceCodeSearched))">
                <tr>
                  <th :rowspan="2">
                    {{ $t("exportData.time") }}
                  </th>
                  <th v-if="isTableTs" :rowspan="2">
                    {{ $t("exportData.subStation") }}
                  </th>
                  <!-- <th v-for="item in headersParent" :colspan="item.dataList.length" :key="item.stationId" v-show="selected.includes(item.index)===true"> -->
                  <th v-for="item in (headersParent)" :colspan="getColspan(headersParent,item)" :key="item.stationId">
                    {{ item.isLineStack ? ($i18n.locale === 'en' ? item.parentEn : item.parentJp) : item.stationName }}
                  </th>
                </tr>
                <tr>
                  <th v-for="item in headersChildren" :key="item.dataMapping">
                    {{ $i18n.locale === 'en' ? item.nameEn : item.nameJp }}
                  </th>
                </tr>
              </thead>

            </template>
            <template v-slot:[`item.status`]="{ item }">
              <font-awesome-icon v-if="item.status" icon="fa-solid fa-circle-check" color="green" size="lg"/>
              <font-awesome-icon v-else icon="fa-solid fa-circle-xmark" color="white" size="lg"/>

            </template>
            <!-- TS -->
            <template v-slot:[`item.D1`]="{item}">
              <div>
                {{item.D1 !== null && item.D1 !== "" ? item.D1 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D2`]="{item}">
              <div>
                {{item.D2 !== null  && item.D2 !== ''  ? item.D2 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D3`]="{item}">
              <div>
                {{item.D3 !== null  && item.D3 !== ''  ? item.D3 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D4`]="{item}">
              <div>
                {{item.D4 !== null  && item.D4 !== ''  ? item.D4 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D5`]="{item}">
              <div>
                {{item.D5 !== null  && item.D5 !== ''  ? item.D5 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D6`]="{item}">
              <div>
                {{item.D6 !== null  && item.D6 !== ''  ? item.D6 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D7`]="{item}">
              <div>
                {{item.D7 !== null  && item.D7 !== ''  ? item.D7 : ''}}
              </div>
            </template>
            <template v-slot:[`item.D8`]="{item}">
              <div>
                {{item.D8 !== null  && item.D8 !== ''  ? item.D8 : ''}}
              </div>
            </template>
            <!-- TS -->
            <template v-slot:[`item`]="{item,headers,index}">
                <tr class>
                  <td v-for="header in headers" :key="`${header.value}_${index}`" :class="`text-${header.align}`">
                    {{(header.value.includes('DATA5') && deviceCode == 100) ? humanReadableDirection(item[header.value]) : item[header.value]}}
                  </td>
                </tr>
            </template>
            <template v-slot:[`item.actions`]="{}">
              <div>
                <button class="m-1">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <SettingExportModal @close="showModalSetting = false" :deviceTSList="deviceTSList"/>
    <ExportModal :deviceCode="deviceCode" :startDate="this.dateFrom" :endDate="dateTo"
                 :displayInterval="displayInterval" :stationSelectedLst="valueStation"
                 :displayIntervalList="displayIntervalList"
                 :TsConfigCheck="this.tsConfigCheck" :MValue="MValue" :ThresholdValue="ThresholdValue"
                 :valueStationMainTS="valueStationMainTS" :tsStationSearch="tsStationSearch" :orgNoSearch="orgNoSearch" :FrameType="frameTypeSelected" :FrameId="selectedFrame"
                 :headers="selected" :frameName="frameName" :deviceTSList="deviceTSList" :isTableTs="isTableTs" :deviceListByFrame="deviceListByFrame"
                 @close="showModalExport = false"/>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import SettingExportModal from "@/modals/SettingExport/SettingExportModal";
import ExportModal from "@/modals/SettingExport/ExportModal";
import {EventBus} from "@/utils/eventBus";
import {getDeviceBySite} from '@/api/device';
import {
  getCookieSiteUser,
  getRememberKCloudID,
  setCurrentPageCode,
  setDisplaySetting,
  getDisplaySetting, getCookiePrivileges
} from "@/utils/auth";
import {ResponseCode, FrameTypesValue} from "@/constants/constants"
import {getCbbStation} from "@/api/station";
import {mapActions} from 'vuex';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "SystemUserManagement",
  components: {
    SettingExportModal, ExportModal
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      if (this.isDeviceTS === true) {
        this.generateItemTSTreeview(this.responseData)
      } else {
        this.generateItemTreeview(this.responseData)
      }
    }
  },
  data() {
    return {
      tsConfigCheck: false,
      MList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      MValue: 0,
      ThresholdList: [1, 2, 3, 4, 5],
      ThresholdValue: 1,
      menuFrom: false,
      menuTo: false,
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fromFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      toFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      loading: false,
      headersParent: [],
      headersChildren: [],
      checkDetail: false,
      deviceCode: null,
      deviceCodeSearched: null,
      deviceList: [],
      deviceListByFrame: [],
      stationList: [],
      deviceSelected: '1',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 4,
        sortBy: 'fat',
        totalItems: 90,
        rowsPerPageItems: [100, 300, 500]
      },
      pageNum: 1,
      pageSize: 50,
      totalItems: 0,
      noDeviceStationFlag: false,
      options: {
        itemsPerPage: 50,
        page: 1
      },
      itemsTreeview: [],
      displayIntervalList: [
        // { value: 0, labelEN: "All data", labelJP: "すべてのデータ" },
        {value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
        {value: 60, labelEN: "1 hour", labelJP: "1時間"},
        {value: 120, labelEN: "2 hour", labelJP: "2時間"},
        {value: 180, labelEN: "3 hour", labelJP: "3時間"},
        {value: 360, labelEN: "6 hour", labelJP: "6時間"},
        {value: 720, labelEN: "12 hour", labelJP: "12時間"},
        {value: 1440, labelEN: "24 hour", labelJP: "24時間"}
      ],
      displayInterval: 0,
      currentLocale: this.$i18n.locale,
      // startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      valueStation: [],
      valueStationSelected: [],
      valueStationMainTS: null,
      tsStationSearch: null,
      orgNoSearch: null,
      headers: [],
      headersTS: [
        {text: this.$t('exportData.time'), align: 'center', sortable: false, value: 'measurementTime'},
        {text: this.$t('exportData.subStation'), value: 'subStation', align: 'stacenterrt', sortable: false}
      ],
      dataList: [],
      listMainNode: [],
      listMainNodeValue: [],
      selected: [],
      selectedManual: [],
      selectedManualId: [],
      selectedManualOrg: [],
      responseData: [],
      isDeviceTS: false,
      PAGE_CODE: 'CS_DS',
      ACTION: 'OPS',
      tsStationLst: [],
      ItemOrgNo:[],
      frameTypeList: [
        {value: '0', labelEN: 'None', labelJP: 'なし'},
        {value: 'LS', labelEN: 'Line Stack', labelJP: '折れ線グラフ'},
        {value: 'MAC', labelEN: 'Multiple-y-axis Mix-line-bar', labelJP: '複合グラフ（集合縦棒+折れ線）'},
        {value: 'GLS', labelEN: 'Grid-multiple Line Chart', labelJP: '複数折れ線グラフ'},
        {value: 'GC', labelEN: 'Gauge Chart', labelJP: 'ゲージグラフ'},
        {value: 'LR', labelEN: 'Line Race', labelJP: 'ラインレース'},
        {value: 'TC', labelEN: 'Table', labelJP: 'テーブル'},
        {value: 'SC', labelEN: 'Scatter Chart', labelJP: '散布図'},
        {value: 'SVG', labelEN: 'SVG', labelJP: 'SVG'}
      ],
      frameTypeSelected: '0',
      frameList: [],
      selectedFrame: null,
      frameName: null,
      channelFlg: 0,
      deviceTSList: [],
      isTableTs: false,
      isValidated: false,
    }
  },
  computed: {
    isDevice1000(){
      return this.isDeviceTsOrSimilarTs(Number(this.deviceCode));
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    isEnableInterval() {
      if (this.deviceCode == "0" || this.deviceCode == null) {
        return false
      }
      return true;
    }
  },
  watch: {
    'options.page': {
      handler() {
        this.handleGetReportDataByTime();
      },
    },
    'options.itemsPerPage': {
      handler() {
        this.handleGetReportDataByTime();
      },
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    "$i18n.locale": async function () {
      await this.getErrorCode("report");
    },
    'dateFrom'(val) {
      this.fromFormatted = this.formatDate(this.dateFrom)
    },
    'dateTo'(val) {
      this.toFormatted = this.formatDate(this.dateTo)
    },
    'deviceCode'(val) {
      // if clear data -> device code = 0 -> not set date to default
      if (val == null){
        return;
      }

      //set date to default
      this.valueStationSelected = [];
      // this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      // this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      // this.fromFormatted = this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
      // this.toFormatted = this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
    },
    'selectedFrame'(val){
      // if clear data -> device code = 0 -> not set date to default
      if (val == null){
        return;
      }

      //set date to default
      //this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      //this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      //this.fromFormatted = this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
      //this.toFormatted = this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
    }
  },
  async beforeMount() {
    // import ERROR_CODES by language
    await this.getErrorCode("report");
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    let now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    this.fromFormatted = this.formatDate(now);
    this.toFormatted = this.formatDate(now);
    this.frameTypeSelected = '0';
    this.InitSelectBoxDevice();
    this.InitTsConfigBySite();
    this.getListDeviceMaster();
  },
  mounted() {
    // let vm = this
    // vm.generateItemTreeview()
  },
  methods: {
    log(text){
      console.log(text);
    },
    isDeviceTsOrSimilarTs(deviceCode){
      return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(deviceCode);
    },
    getListDeviceMaster() {
      this.getDeviceList({})
        .then(response => {
          if (response.code === 0) {
            this.deviceTSList = response.data.deviceList.map(x => x.code);
            console.log("deviceTSList ", this.deviceTSList);
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    humanReadableDirection(windDirection) {
      if (windDirection < 11.25) {
        return this.$t("compass.N");
      }
      if (windDirection >= 11.25 && windDirection <= 33.75) {
        return this.$t("compass.NNE");
      }
      if (windDirection > 33.75 && windDirection <= 56.25) {
        return this.$t("compass.NE");
      }
      if (windDirection > 56.25 && windDirection <= 78.75) {
        return this.$t("compass.ENE");
      }
      if (windDirection > 78.75 && windDirection <= 101.25) {
        return this.$t("compass.E");
      }
      if (windDirection > 101.25 && windDirection <= 123.75) {
        return this.$t("compass.ESE");
      }
      if (windDirection > 123.75 && windDirection <= 146.25) {
        return this.$t("compass.SE");
      }
      if (windDirection > 146.25 && windDirection <= 168.75) {
        return this.$t("compass.SSE");
      }
      if (windDirection > 168.75 && windDirection <= 191.25) {
        return this.$t("compass.S");
      }
      if (windDirection > 191.25 && windDirection <= 213.75) {
        return this.$t("compass.SSW");
      }
      if (windDirection > 213.75 && windDirection <= 236.25) {
        return this.$t("compass.SW");
      }
      if (windDirection > 236.25 && windDirection <= 258.75) {
        return this.$t("compass.WSW");
      }
      if (windDirection > 258.75 && windDirection <= 281.25) {
        return this.$t("compass.W");
      }
      if (windDirection > 281.25 && windDirection <= 303.75) {
        return this.$t("compass.WNW");
      }
      if (windDirection > 303.75 && windDirection <= 326.25) {
        return this.$t("compass.NW");
      }
      if (windDirection > 326.25 && windDirection <= 348.75) {
        return this.$t("compass.NNW");
      }
      if (windDirection > 348.75) {
        return this.$t("compass.N");
      }
      return windDirection;
    },
    handleLabelItems(list) {
      list.forEach((item) => {
        if (item.label.length > 100) {
          item.label = `${item.label.substring(0, 100)}...`;
        }
      })
      return list
    },
    getDisplayFromCookie() {
      return getDisplaySetting('DEVICE_' + this.deviceCode)
    },
    setDisplayToCookie() {
      let vm = this
      setDisplaySetting('DEVICE_' + vm.deviceCode, vm.selected)
    },
    getColspan(headersParent, item) {
      let vm = this
      let count = 0
      if(this.frameTypeSelected === '0'){
        for (let index = 0; index < headersParent.length; index++) {
        if (item.stationId === headersParent[index].stationId) {
          for (let i = 0; i < headersParent[index].dataList.length; i++) {
            if (vm.selected.includes(headersParent[index].dataList[i].dataCode)) {
              count += 1
            }
          }
        }
      }
      }
      else{
        count = item.dataList.length;
      }
      return count
    },
    getHeaderFromSettingCookie() {
      let vm = this
      let displayedSetting = vm.getDisplayFromCookie()
      if (displayedSetting !== null && displayedSetting !== undefined) {
        vm.selected = Object.assign([], displayedSetting.split(','))
        let listSelectManualTemp = vm.selectedManual
        vm.selectedManual = []
        for (let i = 0; i < listSelectManualTemp.length; i++) {
          if (vm.selected.filter(n => n === listSelectManualTemp[i].dataCode).length > 0) {
            vm.selectedManual.push(listSelectManualTemp[i])
          }
        }
        vm.selectedManual = vm.selectedManual.slice()
        if (vm.isDeviceTS === true) {
          let listHeaderTSTemp = vm.headersTS
          vm.headersTS = []
          for (let i = 0; i < listHeaderTSTemp.length; i++) {
            let filter = vm.selected.filter(n => n === listHeaderTSTemp[i].dataCode)
            if (filter.length > 0) {
              vm.headersTS.push(Object.assign({}, listHeaderTSTemp[i]))
            }
          }
          vm.setSelectedItem()
        } else {
          let listHeaderTemp = vm.headers
          vm.headers = []
          for (let i = 0; i < listHeaderTemp.length; i++) {
            let filter = vm.selected.filter(n => n === listHeaderTemp[i].dataCode)
            if (filter.length > 0) {
              vm.headers.push(Object.assign({}, listHeaderTemp[i]))
            }
          }
          vm.setSelectedItem()
        }
      }
    },
    setSelectedItem() {
      let vm = this
      vm.menu = false
      vm.selected = []
      if (vm.isDeviceTS === false) {
        for (let i = 0; i < vm.selectedManual.length; i++) {
          let filter = vm.selected.filter(n => n === vm.selectedManual[i].dataCode)
          if (filter.length === 0) {
            vm.selected.push(vm.selectedManual[i].dataCode)
          }
        }
        vm.headersChildren  = [];
        vm.headersParent.forEach(x=>vm.headersChildren.push(...x.dataList.filter(y => vm.selected.includes(y.dataCode))));
        this.headers = [];
        this.headers.push({
          text: this.$t('exportData.time'),
          align: "center",
          sortable: false,
          value: "time",
        });
        for (let i = 0; i < vm.responseData.data.headers.length; i++) {
          let station = vm.responseData.data.headers[i]
          for (let j = 0; j < station.dataList.length; j++) {
            let filterColumn = vm.selectedManual.filter(n => n.dataCode === station.dataList[j].dataCode)
            if (filterColumn.length > 0) {
              const item = {
                text: this.currentLocale === 'jp' ? station.dataList[j].nameJp : station.dataList[j].nameEn,
                value: station.dataList[j]?.dataMapping,
                align: "center",
                sortable: false,
              };
              this.headers.push(item)
            }
          }
        }
      } else {
        this.headersTS = [
          {text: this.$t('exportData.time'), align: 'center', sortable: false, value: 'measurementTime'},
          {text: this.$t('exportData.subStation'), value: 'stationName', align: 'stacenterrt', sortable: false}
        ]
        vm.selected.push('measurementTime')
        vm.selected.push('stationName')
        for (let i = 0; i < vm.responseData.data.headers[0].dataList.length; i++) {
          let header = vm.responseData.data.headers[0].dataList[i]
          let filterColumn = vm.selectedManual.filter(n => n.dataCode === header.dataMapping)
          if (filterColumn.length > 0) {
            const item = {
              text: this.currentLocale === 'jp' ? header.nameJp : header.nameEn,
              value: header?.dataMapping,
              align: "center",
              sortable: false,
            };
            vm.selected.push(header.dataMapping)
            this.headersTS.push(item)
          }
        }
        vm.headersTS = vm.headersTS.filter(x => vm.selected.includes(x.value));
      }
      vm.selectedManualOrg = Object.assign([], vm.selectedManual)
      // vm.selected = vm.selectedManual
      vm.setDisplayToCookie()
    },
    cancelSelectItem() {
      let vm = this
      vm.menu = false
      vm.selectedManual = Object.assign([], vm.selectedManualOrg)
    },
    ...mapActions("station", {
      getCbbStationTSByDevice: "getCbbStationTSByDevice",
      getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr"
    }),
    ...mapActions("report", {
      getReportDataByTime: "getReportDataByTime",
      LoadTsConfig: "LoadTsConfig",
      SaveTsConfig: "SaveTsConfig"
    }),
    ...mapActions("users", {
      getUserList: "getUserList",
      addUser: "addUser",
      updateUser: "updateUser",
      getKnsUserDetail: "getKnsUserDetail",
    }),
    ...mapActions("sites", {
      getCbbSites: "getCbbSites"
    }),
    ...mapActions("frame", {
      getTypeFrame: "getTypeFrame",
      getFrameByType: "getFrameByType",
    }),
    ...mapActions("device", {
      getDeviceByFrameId: "getDeviceByFrameId"
    }),
    ...mapActions("deviceMaster", {
      getDeviceList: "getDeviceList"
    }),
    disableStartDates(val) {
      return this.dateTo == null ? true : val <= this.dateTo;
    },
    disableEndDates(val) {
      return this.dateFrom == null ? true : val >= this.dateFrom;
    },
    clearFilter() {
      this.deviceCode = null;
      this.valueStation = [];
      this.valueStationSelected = [];
      this.displayInterval = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.fromFormatted = null;
      this.toFormatted = null;
      this.headersParent = [];
      this.headersChildren = [];
      this.headers = [];
      this.dataList = [];
      this.deviceCodeSearched = null;
      this.frameTypeSelected = '0';
      this.selectedFrame = null;
      this.valueStationMainTS = null;
      this.tsStationSearch = null;
      this.orgNoSearch = null;
      this.channelFlg = 0;
      this.itemsTreeview = [];
    },
    handleGetReportDataByTime() {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      if (this.deviceCode === null && this.selectedFrame === null) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_02'], 'warning');
        return;
      }
      if (this.valueStation.length == 0 && this.tsStationSearch === null && this.selectedFrame === null) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_09'], 'warning');
        return;
      }
      if (this.fromFormatted === null || this.dateFrom === null || this.fromFormatted === undefined || this.dateFrom === undefined) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_01'], 'warning');
        return;
      }
      if ((this.orgNoSearch === null && this.frameTypeSelected == "0") || (this.noDeviceStationFlag === null && this.frameTypeSelected == "0")){
        this.isValidated = true;
      }
      if (this.isDeviceTsOrSimilarTs(Number(this.deviceCode)) && this.orgNoSearch === null && this.isValidated == true) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_10'], 'warning');
        return;
      }
      const {page, itemsPerPage} = this.options;
      let request = {
        "siteId": siteID,
        "deviceCode": this.deviceCode,
        "stationSelectedLst": this.valueStation,
        "displayInterval": this.displayInterval,
        "startDate": this.dateFrom,
        "endDate": this.dateTo,
        "ThresholdValue": this.ThresholdValue,
        "MValue": this.MValue,
        "TsConfigCheck": this.tsConfigCheck,
        "PageSize": itemsPerPage,
        "PageNumber": page,
        "valueStationMainTS": this.valueStationMainTS,
        "tsStationSearch": this.tsStationSearch,
        "orgNoSearch": this.orgNoSearch,
        "FrameId": this.selectedFrame,
        "SettingHeader": this.selected,
        "FrameType": this.frameTypeSelected,
        "Locale": this.$i18n.locale
      }
      this.loading = true;
      this.getReportDataByTime({PAGE_CODE: this.PAGE_CODE, request: request})
        .then(response => {
          this.responseData = response
          if (response.code === 0) {
            this.noDeviceStationFlag = false;
            this.deviceCodeSearched = this.deviceCode;
            if (!this.isDeviceTsOrSimilarTs(Number(this.deviceCode))) {
              this.isDeviceTS = false
              this.headersParent = []
              this.headersParent = Object.assign([], response.data.headers);
              this.headersChildren = [];
              response.data.headers.forEach((item, index) => {
                item.dataList.forEach((itemChildren, indexC) => {
                  let item = Object.assign({}, itemChildren)
                  this.headersChildren.push(item);
                })
              })
              this.generateHeader(response);
              this.itemsTreeview = []
              this.generateItemTreeview(response);
              this.totalItems = response.meta.total;
              this.pageSize = response.meta.pageSize;

            } else {
              this.isDeviceTS = true
              this.headersTS = [
                {
                  text: this.$t('exportData.time'),
                  align: 'center',
                  sortable: false,
                  value: 'measurementTime',
                  dataCode: 'measurementTime'
                },
                {
                  text: this.$t('exportData.subStation'),
                  value: 'stationName',
                  dataCode: 'stationName',
                  align: 'subStation',
                  sortable: false
                }
              ];
              response.data.headers.forEach((item, index) => {
                item.dataList.forEach((element, indexC) => {
                  const item = {
                    text: this.currentLocale === 'jp' ? element?.nameJp : element?.nameEn,
                    value: element?.dataMapping,
                    align: "center",
                    sortable: false,
                  };
                  this.headersTS.push(item)
                })
              })
              this.dataList = response.data.bodys.map(x => {
                var newObj = {};
                if (x === null) {
                  return {measurementTime: 'NG'}
                }
                if (x.data === null) {
                  return {measurementTime: x.time}
                } else {
                  newObj = {...x.data, measurementTime: x.time, stationName: x.stationName};
                  this.headers.forEach(header => {
                    if(x.data[header.value] === undefined || x.data[header.value] === null || x.data[header.value] === ''){
                        newObj[header.value] = ''
                      }
                  })
                  newObj.measurementTime = x.time;
                  newObj.stationName = x.stationName;
                  return newObj;
                }
              });
              this.totalItems = response.meta.total;
              this.pageSize = response.meta.pageSize;
              this.itemsTreeview = []
              this.generateItemTSTreeview(response);
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    compare( a, b ) {
      if ( a.dataMappingIndex < b.dataMappingIndex ) {
        return -1;
      }
      if ( a.dataMappingIndex > b.dataMappingIndex ) {
        return 1;
      }
      return 0;
    },
    generateItemTreeview(response) {
      let vm = this
      vm.selected = []
      vm.itemsTreeview = []
      vm.selectedManual = []
      let index = 1
      if (response.data !== null && response.data.headers !== null && response.data.headers.length > 0) {
        let timeSelected = {
          id: index,
          name: this.$t('exportData.time'),
          dataCode: 'time',
          locked: true
        }
        vm.selected.push('time')
        vm.selectedManual.push(Object.assign({}, timeSelected))
        index++
        vm.itemsTreeview.push(timeSelected)
        for (let i = 0; i < response.data.headers.length; i++) {
          let itemHeader = response.data.headers[i]
          for (let j = 0; j < itemHeader.dataList.length; j++) {
            const item = {
              id: index,
              name: this.currentLocale === 'en' ? itemHeader.dataList[j]?.nameEn : itemHeader.dataList[j]?.nameJp,
              dataCode: itemHeader.dataList[j]?.dataCode
            };
            vm.selectedManual.push(Object.assign({}, item))
            vm.selected.push(itemHeader.dataList[j]?.dataCode)
            index++
            vm.itemsTreeview.push(item)
          }
          break
        }
        this.getHeaderFromSettingCookie()
      }
    },
    generateItemTSTreeview(response) {
      let vm = this
      vm.selected = []
      vm.itemsTreeview = []
      vm.selectedManual = []
      let index = 1
      if (response.data.headers !== null && response.data.headers.length > 0) {
        let timeSelected = {
          id: index,
          name: this.$t('exportData.time'),
          dataCode: 'measurementTime',
          locked: true
        }
        vm.selectedManual.push(Object.assign({}, timeSelected))
        index++
        vm.itemsTreeview.push(timeSelected)
        let subStation = {
          id: index,
          name: this.$t('exportData.subStation'),
          dataCode: 'subStation',
          locked: true
        }
        vm.selectedManual.push(Object.assign({}, subStation))
        index++
        vm.itemsTreeview.push(subStation)
        for (let i = 0; i < response.data.headers[0].dataList.length; i++) {
          let itemHeader = response.data.headers[0].dataList[i]
          const item = {
            id: index,
            name: this.currentLocale === 'en' ? itemHeader.nameEn : itemHeader.nameJp,
            dataCode: itemHeader.dataMapping
          };
          vm.selectedManual.push(Object.assign({}, item))
          vm.selected.push(itemHeader.dataMapping)
          index++
          vm.itemsTreeview.push(item)
        }
        this.headersTS = [
          {
            text: this.$t('exportData.time'),
            align: 'center',
            sortable: false,
            value: 'measurementTime',
            dataCode: 'measurementTime'
          },
          {
            text: this.$t('exportData.subStation'),
            value: 'stationName',
            dataCode: 'stationName',
            align: 'stacenterrt',
            sortable: false
          }
        ],
        response.data.headers.forEach((item, index) => {
          item.dataList.forEach((element, indexC) => {
            const item = {
              text: this.currentLocale === 'jp' ? element?.nameJp : element?.nameEn,
              value: element?.dataMapping,
              align: "center",
              sortable: false,
            };
            this.headersTS.push(item)
          })
        })
        this.getHeaderFromSettingCookie()
      }
    },
    generateHeader(response) {
      if (response.data.headers.length > 0) {
        this.headers = [];
        this.headers.push({
          text: this.$t('exportData.time'),
          align: "center",
          sortable: false,
          dataCode: "time",
          value: "time",
        });
        this.isTableTs = response.data.isTableTs;
        if (response.data.isTableTs) {
          this.headers.push({
            text: this.$t('exportData.subStation'),
            value: 'stationName',
            dataCode: 'stationName',
            align: 'center',
            sortable: false
          });
        }
        response.data.headers.forEach((item, index) => {
          item.dataList.forEach((element, indexC) => {
            const item = {
              text: this.currentLocale === 'jp' ? element?.nameJp : element?.nameEn,
              value: element?.dataMapping,
              align: "center",
              sortable: false,
            };
            this.headers.push(item)
          })
        })
      }
      this.dataList = response.data.bodys.map(x => {
        var newObj = {};
        if (x === null) {
          return {time: 'NG'}
        }
        if (x.data === null) {
          return {time: x.time}
        } else {
          newObj = {...x.data, time: x.time, stationName: x.stationName };
          this.headers.forEach(header => {
            if(x.data[header.value] === undefined || x.data[header.value] === null || x.data[header.value] === ''){
                newObj[header.value] = ''
              }
              newObj.station = header.value.split("_")[0];
          })
          newObj.time = x.time;
          newObj.stationName = x.stationName;
          return newObj;
        }
      });
    },
    InitTsConfigBySite() {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      this.LoadTsConfig(siteID).then(response => {
        if (response.code === 0) {
          if (response.data === null) {
            this.MValue = 0;
            this.ThresholdValue = 1;
          } else {
            this.MValue = response.data.mValue;
            this.ThresholdValue = response.data.thresholdValue === 0 ? 1 : response.data.thresholdValue;
          }

        } else {
          this.loading = false;
          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
        }
      })
        .catch(err => {
          this.loading = false;
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    InitSelectBoxDevice() {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      let request = {
        "siteId": siteID,
        "isSearchData": true
      }
      getDeviceBySite(request).then(response => {
        if (response.data && response.data.code === ResponseCode.SUCCESS) {
          this.deviceList = response?.data?.data;
        } else {
          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
        }
      })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
        })
    },
    getCbbStationByDevice(deviceSelected) {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      var request = {
        "siteId": siteID,
        "deviceCode": deviceSelected
      }
      getCbbStation(request).then(response => {
        if (response.data && response.data.code === ResponseCode.SUCCESS) {
          this.stationList = response?.data?.data;
        } else {
          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
        }
      })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
        })
        .finally(final => {
        });

    },
    getCbbStationByFrame(frameId, deviceSelected) {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      var request = {
        "siteId": siteID,
        "deviceCode": deviceSelected,
        "FrameId": frameId
      }
      getCbbStation(request).then(response => {
        if (response.data && response.data.code === ResponseCode.SUCCESS) {
          this.stationList = response?.data?.data;
        } else {
          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
        }
      })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
        })
        .finally(final => {
        });

    },
    onChangeDeviceOptions(deviceSelected) {
      this.valueStation = [];
      console.log("deviceSelected ", deviceSelected);
      // this.valueStationSelected = [];
      if (!this.isDeviceTsOrSimilarTs(Number(deviceSelected))) {
        this.displayIntervalList = [
          {value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
          {value: 60, labelEN: "1 hour", labelJP: "1時間"},
          {value: 120, labelEN: "2 hour", labelJP: "2時間"},
          {value: 180, labelEN: "3 hour", labelJP: "3時間"},
          {value: 360, labelEN: "6 hour", labelJP: "6時間"},
          {value: 720, labelEN: "12 hour", labelJP: "12時間"},
          {value: 1440, labelEN: "24 hour", labelJP: "24時間"}
        ];
        this.displayInterval = 0;
      } else {
        this.displayIntervalList = [
          {value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
        ];
        this.displayInterval = 0;
      }
      var channelFlg = this.deviceList.find(x => x.value === deviceSelected)?.channelFlg;
      this.channelFlg = channelFlg;
      this.getCbbStationByDevice(deviceSelected);
    },
    onSearch() {
      this.valueStationSelected = this.valueStation;
      this.handleGetReportDataByTime();
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    parseDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    // eslint-disable-next-line
    openExportSetting(numer) {
      this.editedItem = {}
      this.modelCreate = true
      if (this.noDeviceStationFlag) {
        this.commonNotifyVue(this.ERROR_CODES["E_080_06"], 'warning');
        return;
      }
      EventBus.$emit('handleOpenExportSetting', {
        title: this.$t('exportData.settingExport'),
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        editMode: false
      });
      this.showModalSetting = true;
      $('#main-header').css("pointer-events", "none");
    },
    // eslint-disable-next-line
    openExport(numer) {
      if (this.deviceCode === null && this.selectedFrame === null) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_02'], 'warning');
        return;
      }
      if (this.valueStation.length == 0 && this.tsStationSearch === null && this.selectedFrame === null) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_09'], 'warning');
        return;
      }
      if (this.fromFormatted === null || this.dateFrom === null || this.fromFormatted === undefined || this.dateFrom === undefined) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_01'], 'warning');
        return;
      }
      if ((this.orgNoSearch === null && this.frameTypeSelected == "0") || (this.noDeviceStationFlag === null && this.frameTypeSelected == "0")){
        this.isValidated = true;
      }
      if (this.isDeviceTsOrSimilarTs(Number(this.deviceCode)) && this.orgNoSearch === null && this.isValidated == true) {
        this.commonNotifyVue(this.ERROR_CODES['E_080_10'], 'warning');
        return;
      }
      this.getCbbSites(0)
        .then(response => {
          if (response.code === 0) {
            let allSiteData = [];
            let tempData = response.data ?? [];
            for (let i = 0; i < tempData.length; i++) {
              allSiteData.push({
                value: tempData[i].id,
                text: tempData[i].siteName
              })
            }
            this.editedItem = {}
            this.modelCreate = true
            if (this.noDeviceStationFlag && this.isValidated == true){
              this.commonNotifyVue(this.ERROR_CODES["E_080_06"], 'warning');
              return;
            }
            this.getKnsUserDetail(getRememberKCloudID()).then((response) => {
              if (response.code === 0) {
                let selectedUser = response.data;
                EventBus.$emit('handleOpenExport', {
                  title: this.$t('exportData.exportData'),
                  editedItem: this.editedItem,
                  flag: true,
                  checkDetail: false,
                  editMode: false,
                  allSiteData: allSiteData,
                  deviceSelected: this.deviceSelected,
                  selectedUser: selectedUser
                });
                $('#main-header').css("pointer-events", "none");
                this.showModalExport = true;
              } else {
                this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
              }
            }).catch((error) => {
              if(error.code != undefined)
                this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
            })
          } else {
            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
          }
        })
    },
    handleSaveTs() {
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      this.loading = true;
      var request = {
        "SiteId": siteID,
        "MValue": this.MValue,
        "ThresholdValue": this.ThresholdValue
      }
      this.SaveTsConfig(request)
        .then(response => {
          if (response.code === 0) {
            this.loading = false;
            this.commonConfirmOKVue(this.ERROR_CODES['SAVE_OK']);
          } else {
            this.loading = false;
            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
        })
        .finally(final => {
          this.loading = false;
          this.isSubmitAction = false;
        });

    },
    handleChangeStation(data){
      this.handleGetCbbStationTS(this.valueStationMainTS, this.deviceCode, null)
    },
    handleGetCbbStationTS(stationId, deviceCode, orgNo) {
      this.loading = true;
      let siteID = getCookieSiteUser();
      this.getCbbStationTSByDevice({DeviceCode: deviceCode, SiteId: siteID, StationId: stationId, OrgNo: orgNo})
        .then(response => {
          if (response.code === 0) {
            this.tsStationLst = response.data.map((i, index) => {
              var dataI = {value: i.id, name: i.stationName, nameJp: i.stationName };
              return dataI;
            });
            this.tsStationLst.unshift({value: 'all', name: 'All Points', nameJp: '全測点' })
            this.tsStationSearch = 'all';
            this.handleChangeTsStation();
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleChangeTsStation(){
      this.loading = true;
      let rq = {
        SiteId: getCookieSiteUser(),
        StationId: this.valueStationMainTS,
        DeviceCode: this.deviceCode,
        SokGrTs: this.tsStationSearch
      }
      this.getCbbOrgInfoBySokGr(rq)
        .then(response => {
          if (response.code === 0) {
            this.ItemOrgNo = response.data;
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });

    },
    handleGetFramesByType() {
      this.loading = true;
      this.isValidated = false;
      console.log(this.isValidated)
      // this.clearFilter();
      var currentSite = getCookieSiteUser();
      this.selectedFrame = null;
      this.deviceListByFrame = [];
      this.stationList = [];
      this.deviceCode = null;
      this.getFrameByType({code: this.frameTypeSelected, siteId: currentSite}).then(response => {
        if (response.code === 0) {
          this.frameList = response.data;
          this.loading = false;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
        this.loading = false;
      });
      this.headersParent = [];
      this.headersChildren = [];
      this.headers = [];
      this.dataList = [];
    },
    handleGetCbbDeviceByFrame() {
      this.frameName = this.frameList.find(x=> x.id === this.selectedFrame)?.name;
      this.displayIntervalList = [
          {value: 0, labelEN: "All data", labelJP: "すべてのデータ"},
        ];
      this.displayInterval = 0;
      this.getDeviceByFrameId(this.selectedFrame)
          .then(response => {
              if (response.code === 0) {
                  this.deviceListByFrame = response.data.map((item, index) => {
                      return item;
                  })
              } else {
                  this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
              }
          })
          .catch(err => {
              console.log(err);
              if(err.code != undefined)
                  this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
              this.isSubmitAction = false;
          });
      this.getCbbStationByFrame(this.selectedFrame, null);
      },
  }
}
</script>
<style lang="scss" scoped>
.margin-center{
  margin: 10px;
  text-align: center;
  font-size: 20px;
}
.theme--light.v-chip:not(.v-chip--active) {
  margin-bottom: 4px !important;
}
/*@media (max-width: 820px) {*/
/*  .v-dialog {*/
/*    overflow-y: unset !important;*/
/*  }*/
/*}*/

@media (min-width: 1000px) and (max-width: 1200px) {
  /*.v-dialog {*/
  /*  overflow-x: unset !important;*/
  /*}*/
  .input-date-mg-ip {
    margin-left: 0px !important;
  }

  .input-end-date {
    padding-left: 6px !important;
  }
}
</style>
<style scoped lang="scss">

.v-autocomplete .v-select__selections {
  word-break: break-all;
}

.ts-button {
  display: flex;
  width: 50% !important;
  margin-right: 10px;
}

.group-ts-config {
  display: flex;
  width: 50%;
}

//.character-ipad {
//  padding-left: 5px;
//}

.group-select-search {
  display: flex;
  padding-right: 12%;
}

.input-group-append-ipad {
  margin-left: -2px;
}

@media (max-width: 1180px) {
  .input-group-append-ipad {
    margin-left: -15px;
  }
  .group-ts-config {
    display: grid;
    width: 50%;
  }
}

@media (max-width: 820px) {

  .input-group-append-ipad {
    margin-left: 0px;
  }
  .checkboxLabel {
    margin-top: 3px !important;
    font-weight: 100;
    color: black;
    width: 25%;
  }
  .input-group-custom {
    width: 60%;
  }
  .ts-button {
    display: flex;
    width: 30% !important;
    margin-right: 10px;
  }
  .group-ts-config {
    display: grid;
    width: 25%;
  }
  .group-select-search {
    padding-right: 3% !important;
  }
  .select-input-group {
    padding-right: 3% !important;
  }
}

.v-menu__content {
  max-height: 40vh !important;
}

.select-ts-group {
  width: 96% !important;
}

.select-input-group {
  padding-right: 60%;
}

.colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}

.btn-add {
  background-color: #0d6efd !important;
  color: white !important;
}

.my_border {
  border-left: 5px solid red;
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}


.custom-select {
  background: none;
  background-color: white;
}

.color-red {
  color: red;
}

.checkboxLabel {
  margin-top: 3px !important;
  font-weight: 100;
  color: black;
  width: 100%;
}

.exportData-header {
    overflow-x: auto;
  }

@media only screen and (max-width: 500px) and (max-height: 1000px) {
  .exportData-displaySetting {
    font-size: 0.5rem !important;
    width: 4.8rem !important;
  }
  .exportData-export {
    font-size: 0.5rem !important;
    width: 1.6rem !important;
  }
  .exportData-settingExport {
    font-size: 0.5rem !important;
    width: 4.8rem !important;
  }
  .v-menu__content {
    left: 1rem !important;
  }
}
::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 5.55rem !important;
  }
  .group-ts-config {
    font-size: 0.75em !important;
    width: 25%;
  }
  .ts-button {
    font-size: 0.75em !important;
    display: flex;
    width: 30% !important;
    margin-right: 0em !important;
  }
  .checkboxLabel {
    width: 7em !important;
  }
  .input-group-custom {
    width: 5rem !important;
  }
  .m-information {
    padding-right: 0.5rem !important;
  }
  .save-button {
    margin-right: 0px !important;
    width: 1em !important;
    font-size: 0.75em !important;
  }
  }
  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
    .sign-ipad {
      margin-left: 2rem !important;
    }
  }
}
</style>
