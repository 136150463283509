// eslint-disable-next-line no-unused-vars
import {getLayout, removeLayout, updateLayout} from "@/api/layout";

const actions = {
    updateLayout({commit, state}, data) {
        return new Promise((resolve, reject) => {
            updateLayout(data)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getLayout({commit, state}, header) {
        return new Promise((resolve, reject) => {
            getLayout(header)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeLayout({commit, state}, data) {
        return new Promise((resolve, reject) => {
            removeLayout(data)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

const state = () => ({
    isFlagChooseFrame: false,
    isFlagDoneInitFrame: false
});
const getters = {};
const mutations = {
    handleReloadChooseFrame(state, flag) {
        state.isFlagChooseFrame = flag;
    },
    handleDoneInitFrame(state, flag) {
      state.isFlagDoneInitFrame = flag;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

