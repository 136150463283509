<template>
  <v-dialog v-model="showDialog" max-width="1900px" persistent scrollable>
    <v-card class="addDevice-modal">
      <v-card-title class="bg-gray-light">
        <span class="text-h5" style="font-size: 1.2rem !important;">{{ $t(nameLabel) }}</span>
        <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 600px;">
        <v-container style="max-width:1835px !important;" class="pt-0 pb-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="row mt-0">
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.deviceMaster.deviceName") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="50" v-model="deviceInfo.deviceName" hide-details="auto" outlined required dense
                :disabled="checkDetail || editFlag" @change="onChangeDeviceName" :rules="deviceNameRules"></v-text-field>
              </div>
              <div class="col-xl-6 col-sm-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.deviceMaster.deviceCode") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="4" v-model="deviceInfo.deviceCode" hide-details="auto" outlined required dense
                :disabled="checkDetail || editFlag" @change="onChangeDeviceCode" :rules="deviceCodeRules" @keypress="onKeyDown"
                @paste="onPaste"
                ></v-text-field>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <div class="d-flex justify-content-start ">
                  <h6 class="mb-1 label-black">{{ $t("labels.deviceMaster.databaseType") }}</h6>
                  <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
                </div>
                <v-text-field maxlength="50" v-model="deviceInfo.databaseType" hide-details="auto" outlined
                   required dense :disabled="checkDetail || editFlag" :rules="databaseTypeRules"></v-text-field>
              </div>
            </div>
          </v-form>
          <div class="row mt-10">
            <div class="col-12">
              <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-data-table :headers="headers" :items="listTableFilter" sort-by="calories" item-key="index"
                  class="elevation-2" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header hide-default-footer :footer-props="{
                    'items-per-page-options': [200]
                  }">
                  <template slot="no-data">
                    {{ $t("notification.noData") }}
                  </template>
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headers" :key="header.value" class="text-uppercase" scope="col">
                          {{ $t(header.text) }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:top v-if="!checkDetail">
                    <v-toolbar flat>
                      <div class="d-flex justify-content-start ">
                        <h5 class="mb-2 label-black">{{ $t("labels.deviceMaster.deviceInfo") }}</h5>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" style="background-color: #3d76ad"
                        :disabled="!isFullDataLineTable" @click="createItem">
                        <v-icon color="white" size="20" class="mr-2 dark mt-0">
                          mdi-plus-box
                        </v-icon>
                        <span style="font-size: 17px" class="font-weight-bold">{{
                            $t("labels.add")
                        }}</span>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.orgNo`]="{ item }">
                    <div class="container orgNo flexMargin">
                      <v-text-field v-model="item.orgNo" maxlength="4" hide-details="auto" outlined dense
                      :rules="orgNoRules" :disabled="checkDetail || (editFlag && item.id)" @change="onChangeOrgNo(item)" @keypress="onKeyDown" @paste="onPaste" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.orgName`]="{ item }">
                    <div class="container orgName flexMargin">
                      <v-text-field v-model="item.orgName" maxlength="50" hide-details="auto" outlined dense
                      :rules="Rules" :disabled="checkDetail" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.orgNameEN`]="{ item }">
                    <div class="container orgNameEN flexMargin">
                      <v-text-field v-model="item.orgNameEN" maxlength="50" hide-details="auto" outlined dense
                       :rules="Rules" :disabled="checkDetail" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.dataMapping`]="{ item }">
                    <div class="container dataMapping flexMargin">
                      <v-select class ="mt-6"
                        v-model="item.dataMapping" :items="dataMappingOptions" outlined dense
                        :rules="Rules" :disabled="checkDetail || (editFlag && item.id)" @change="onChangeDataMapping(item)" @click="onSelectOption(item)"
                      ></v-select>
                    </div>
                  </template>
                  <template v-slot:[`item.headerJP`]="{ item }">
                    <div class="container dataJP flexMargin">
                      <v-text-field v-model="item.headerJP" maxlength="50" hide-details="auto" outlined dense
                      :rules="Rules" :disabled="checkDetail" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.headerEN`]="{ item }">
                    <div class="container dataEN flexMargin">
                      <v-text-field v-model="item.headerEN" maxlength="50" hide-details="auto" outlined dense
                      :rules="Rules" :disabled="checkDetail" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.unit`]="{ item }">
                    <div class="container unit flexMargin">
                      <v-text-field v-model="item.unit" maxlength="10" hide-details="auto" outlined dense
                      :rules="Rules" :disabled="checkDetail || (editFlag && item.id)" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.ngFlg`]="{ item }">
                    <div class="container flag flexMargin">
                      <v-text-field v-model="item.ngFlg" maxlength="4" hide-details="auto" outlined dense
                      :rules="flagRules" :disabled="checkDetail || (editFlag && item.id)" @keypress="onKeyDown" @paste="onPaste" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.roundingNumber`]="{ item }">
                    <div class="container roundingNumber flexMargin">
                      <v-text-field v-model="item.roundingNumber" maxlength="1" hide-details="auto" outlined dense
                     :disabled="checkDetail || (editFlag && item.id)" @keypress="onKeyDown" @paste="onPaste" @keypress.13.prevent>
                      </v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div style="width:120px !important;">
                      <button v-if="listTable.length > 0" class="m-1" @click.prevent="onDeleteLine(item)">
                        <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                        </i>
                      </button>
                    </div>
                  </template>
                </v-data-table>
              </v-form>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" text
          @click.prevent="handleSaveData">
          {{ $t("labels.deviceMaster.save") }}
        </v-btn>
        <v-btn v-if="checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.deviceMaster.close") }}
        </v-btn>
        <v-btn v-if="!checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.deviceMaster.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>


</template>

<script>
import { EventBus } from '@/utils/eventBus.js';
import { mapActions } from 'vuex';
import $ from 'jquery';
import {} from "@/utils/validate";
export default {
  name: "DeviceEditModal",
  props: ['visible'],
  data() {
    return {
      dataMappingOptions:[
      {text: 'd1'},
      {text: 'd2'},
      {text: 'd3'},
      {text: 'd4'},
      {text: 'd5'},
      {text: 'd6'},
      {text: 'd7'},
      {text: 'd8'},
      ],
      valid: false,
      valid2: false,
      loading: false,
      showDialog: false,
      checkDetail: false,
      deviceInfo: {
        deviceCode: '',
        deviceName: '',
        databaseType: '',
        deviceMasterInfo: [],
      },
      ERROR_CODE: '',
      nameLabel: "",
      headers: [
        { text: '#', align: 'start', sortable: false, value: 'index', width: "50px" },
        { text: 'labels.deviceMaster.no', value: 'orgNo', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.orgName', value: 'orgName', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.orgNameEn', value: 'orgNameEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataMapping', value: 'dataMapping', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.data', value: 'headerJP', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataEN', value: 'headerEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.unit', value: 'unit', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.flag', value: 'ngFlg', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.roundingNumber', value: 'roundingNumber', align: 'start', sortable: false },
        { text: 'genba.table.action', value: 'actions', sortable: false, align: 'center' },
      ],
      listTable: [{
      index: 1,
      orgNo: null,
      orgName: null,
      orgNameEN: null,
      dataMapping: [],
      headerJP: null,
      headerEN: null,
      unit: null,
      ngFlg: null,
      roundingNumber: null,
      deviceCode: null,
      status: 1,
      dataMappingSelected: null,
    }],
    Rules: [
    (value) => !!value || "",
    ],
    deviceNameRules: [
    (value) => !!value || this.$t("labels.deviceMaster.deviceName") + this.ERROR_CODE['IS_REQUIRED'],
    ],
    deviceCodeRules: [
    (value) => !!value || this.$t("labels.deviceMaster.deviceCode") + this.ERROR_CODE['IS_REQUIRED'],
    ],
    databaseTypeRules: [
    (value) => !!value || this.$t("labels.deviceMaster.databaseType") + this.ERROR_CODE['IS_REQUIRED'],
    ],
    orgNoRules:[
    (value) => !!value || "",
    ],
    flagRules:[
    (value) => !!value || "",
    ],
    roundingNumberRules:[
    value => /^\d*$/.test(value) || "",
    ],
    requiredRule(value, label) {
      const errorMsg = [this.$t(label)];
      if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
        return errorMsg
      } else {
        return [];
      }
    },
      editFlag: false,
      createFlg: false,
    }
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
    }
  },
  beforeDestroy() {
    this.loading = false;
  },
  computed: {
    isFullDataLineTable() {
      if (this.listTable.length > 0) {
        const item = this.listTable[this.listTable.length - 1];
        return item.no !== null && item.name !== null && item.nameEN !== null;
      }
      return true;
    },
    listTableFilter() {
      return this.listTable.filter(item => item.status === 1)
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {
      await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
    'checkDetail': function (val) {
      if (val) {
        this.headers = [
        { text: '#', align: 'start', sortable: false, value: 'index', width: "50px" },
        { text: 'labels.deviceMaster.no', value: 'orgNo', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.orgName', value: 'orgName', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.orgNameEn', value: 'orgNameEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataMapping', value: 'dataMapping', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.data', value: 'headerJP', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataEN', value: 'headerEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.unit', value: 'unit', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.flag', value: 'ngFlg', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.roundingNumber', value: 'roundingNumber', align: 'start', sortable: false },
        ]
      } else {
        this.headers = [
        { text: '#', align: 'start', sortable: false, value: 'index', width: "50px" },
        { text: 'labels.deviceMaster.no', value: 'orgNo', align: 'start', sortable: false},
        { text: 'labels.deviceMaster.orgName', value: 'orgName', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.orgNameEn', value: 'orgNameEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataMapping', value: 'dataMapping', align: 'start', sortable: false},
        { text: 'labels.deviceMaster.data', value: 'headerJP', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.dataEN', value: 'headerEN', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.unit', value: 'unit', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.flag', value: 'ngFlg', align: 'start', sortable: false },
        { text: 'labels.deviceMaster.roundingNumber', value: 'roundingNumber', align: 'start', sortable: false },
        { text: 'genba.table.action', value: 'actions', sortable: false, align: 'center' },
        ]
      }
    }
  },
  async beforeMount() {
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
  },
  beforeCreate() {
    this.loading = true;
  },

  created() {
    EventBus.$on('handleOpenDeviceMasterEditModal', (value) => {
      if (value.checkDetail || value.editFlag){
        this.handleGetDeviceDetails(value);
      }
      if (value.Flg) {
      $('#main-header').css("pointer-events", "none");
      } else {
      $('#main-header').css("pointer-events", "");
      }
      this.showDialog = value.Flg;
      this.checkDetail = value.checkDetail;
      this.nameLabel = value.nameLabel;
      this.editFlag = value.editFlag;
      this.createFlg = value.createFlg;
      this.$refs.form?.resetValidation();
      this.$refs.form2?.resetValidation();
    });
  },
  mounted() {
  },
  methods: {
    ...mapActions("deviceMaster", {
      addDevice: "addDevice",
      updateDevice: "updateDevice",
      getDeviceDetail: "getDeviceDetail",
      getDeviceList: "getDeviceList"
    }),
    onKeyDown(event) {
      console.log(event.key)
      console.log(isNaN(event.key))
      if (isNaN(event.key)) {
        event.preventDefault();
      }
    },
    onPaste(event){
      if (isNaN(event.clipboardData.getData('text'))) {
        event.preventDefault();
      }
    },
    onChangeDeviceCode(e) {
      let res = /^[a-zA-Z]+$/.test(this.deviceInfo.deviceCode);
      if (!res) {
        return;
      }
    },
    onChangeDeviceName(e) {
      let res = /^[a-zA-Z]+$/.test(this.deviceInfo.deviceName);
      if (!res) {
        return;
      }
    },
    onSelectOption(item){
      let dataMappingList = []
      this.listTableFilter.forEach(record =>{
        if(String(record.orgNo) === String(item.orgNo)){
          dataMappingList.push(record.dataMapping)
        }
      })
      this.dataMappingOptions.forEach(option=>{
        if(dataMappingList.includes(option.text)){
          option.disabled = true
          return
        }
        option.disabled = false
      })
    },
    onChangeDataMapping(item){
      let dataMappingList = []
      this.listTableFilter.forEach(record =>{
        if(String(record.orgNo) === String(item.orgNo)){
          dataMappingList.push(record.dataMapping)
        }
      })
      this.dataMappingOptions.forEach(option=>{
        if(dataMappingList.includes(option.text)){
          option.disabled = true
          return
        }
      })
    },
    onChangeOrgNo(item){
      item.dataMappingSelected = item.dataMapping;
      this.listTableFilter.forEach((compareItem) => {
        if(item.index != compareItem.index){
          if(item.orgNo == compareItem.orgNo){
            item.orgName = compareItem.orgName;
            item.orgNameEN = compareItem.orgNameEN;
            item.dataMapping = null;
            this.dataMappingOptions.forEach(option => {
            if(option.text == compareItem.dataMapping){
              option.disabled = true;
            }
          })
          }
          else if(item.orgNo != compareItem.orgNo){
            this.dataMappingOptions.forEach(option => {
              option.disabled = false;
            })
          }

          }
        })
      },
    handleGetDeviceDetails(value) {
      this.showDialog = true;
      this.loading = true;
      this.getDeviceDetail(value.editedItem.code)
        .then(response => {
          if (response.code === 0) {
            this.deviceInfo = {
              deviceCode: response.data.deviceCode,
              deviceName: response.data.deviceName,
              databaseType: response.data.databaseType
            }
            this.deviceInfo.deviceMasterInfo = response.data.deviceMasterInfo.map((item, index) => {
              return { ...item, index: index, status: 1, deviceCode: item.deviceCode };
            });
            this.listTable = response.data.deviceMasterInfo.map((item, index) => {
              return { ...item, index: index + 1, status: 1, deviceCode: item.deviceCode };
            });

            this.loading = false;
          }
          else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    createItem() {
      const deviceMasterInfo = {
        index: this.listTableFilter.length + 1,
        orgNo: null,
        orgName: null,
        orgNameEN: null,
        dataMapping: null,
        headerJP: null,
        headerEN: null,
        unit: null,
        ngFlg: null,
        roundingNumber: null,
        deviceCode: null,
        status: 1,
      };
      this.listTable.push(deviceMasterInfo);
    },
    onDeleteLine(item) {
      var index = this.listTable.map(x => {
        return x.index;
      }).indexOf(item.index);

      if (this.listTable[index].status === 1 && (item.id != null || item.id != undefined)) {
        this.commonConfirmVue(this.ERROR_CODE['CONFIRM_DELETE_ORGINFO'], () => {
          this.listTable[index].status = 2;
          this.resetTableIndex()
      });
      }
      else if (item.id == null || item.id == undefined){
        this.listTable.splice(index, 1)
      }
      this.resetTableIndex()
    },
    resetTableIndex(){
      let rawIndex = 1
      this.listTable.forEach((element) => {
        if(element.status === 1){
          element.index = rawIndex
          rawIndex++
          return
        }
        element.index = null
      })
    },
    handleSaveData() {
      this.$refs.form.validate()
      if (!this.$refs.form.validate()) {
        return;
      }
      let checkDevice = true;
      this.listTable.forEach((element, i) => {
      if(element.status != 2){
        this.listTable.forEach((compareElement, j) => {
          if(i !== j && compareElement.status != 2){
            if (compareElement.orgNo == element.orgNo && compareElement.ngFlg != element.ngFlg){
              this.commonErrorVue(this.$t("labels.deviceMaster.flag").toLocaleUpperCase() + this.ERROR_CODE['INVALID'], 'warning');
              this.$refs.form2.validate()
              checkDevice = false;
            }
            if (compareElement.orgNo == element.orgNo && compareElement.orgNameEN != element.orgNameEN){
              this.commonErrorVue(this.$t("labels.deviceMaster.orgNameEn").toLocaleUpperCase() + this.ERROR_CODE['INVALID'], 'warning');
              this.$refs.form2.validate()
              checkDevice = false;
            }
            if (compareElement.orgNo == element.orgNo && compareElement.orgName != element.orgName){
              this.commonErrorVue(this.$t("labels.deviceMaster.orgName").toLocaleUpperCase() + this.ERROR_CODE['INVALID'], 'warning');
              this.$refs.form2.validate()
              checkDevice = false;
          }}
        })
        if (element.orgNo === null || element.orgNo === undefined || element.orgNo.toString().trim().length === 0 ) {
            const msg = this.$i18n.locale === "en" ? this.$t("labels.deviceMaster.no").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED']
              : "Org" + this.$t("labels.deviceMaster.no").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED']
            this.commonErrorVue(msg, 'warning');
            this.$refs.form2.validate()
            checkDevice = false;
            return;
          }
        if (element.ngFlg === null || element.ngFlg === undefined || element.ngFlg?.toString().trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.flag").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.unit === null || element.unit === undefined || element.unit?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.unit").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.headerEN === null || element.headerEN === undefined || element.headerEN?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.dataEN").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.headerJP === null || element.headerJP === undefined || element.headerJP?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.data").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.dataMapping === null || element.dataMapping === undefined || element.dataMapping?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.dataMapping").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.orgNameEN === null || element.orgNameEN === undefined || element.orgNameEN?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.orgNameEn").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
        if (element.orgName === null || element.orgName?.trim().length === 0) {
          this.commonErrorVue(this.$t("labels.deviceMaster.orgName").toLocaleUpperCase() + this.ERROR_CODE['IS_REQUIRED'], 'warning');
          this.$refs.form2.validate()
          checkDevice = false;
        }
      }
      })

      if (!checkDevice) {
        return;
      }
      this.deviceInfo.deviceMasterInfo = this.listTable;
      this.loading = true;
      if (this.editFlag) {
        this.handleUpdateDevice();
      } else {
        this.handleAddDevice();
      }
    },
    handleAddDevice() {
      for (var i=0; i<this.deviceInfo.deviceMasterInfo.length; i++){
          if (this.deviceInfo.deviceMasterInfo.length > 0) {
          this.deviceInfo.deviceMasterInfo[i].deviceCode = this.deviceInfo.deviceCode;
        }
      }
      this.addDevice(this.deviceInfo)
        .then(response => {
          if (response.code == 200) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetDeviceList', {});
            this.loading = false;
            this.handleResetFormItem();
            this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK'])
          } else {
            this.loading = false;
            let error_msg = this.ERROR_CODE[response.code];
            this.commonNotifyVue(error_msg, 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleUpdateDevice() {
      for (var i=0; i<this.deviceInfo.deviceMasterInfo.length; i++){
          if (this.deviceInfo.deviceMasterInfo.length > 0) {
          this.deviceInfo.deviceMasterInfo[i].deviceCode = this.deviceInfo.deviceCode;
        }
      }
      this.updateDevice(this.deviceInfo)
        .then(response => {
          if (response.code == 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
            this.$emit('handleGetDeviceList', {});
            this.loading = false;
            this.handleResetFormItem();
            this.commonConfirmOKVue(this.ERROR_CODE['SAVE_OK'])
          } else {
            this.loading = false;
            let error_msg = this.ERROR_CODE[response.code];
            this.commonNotifyVue(error_msg, 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
          this.showDialog = false;
          $('#main-header').css("pointer-events", "");
          this.handleResetFormItem();
        });
      } else {
        this.showDialog = false;
        $('#main-header').css("pointer-events", "");
        this.handleResetFormItem();
      }
    },
    handleResetFormItem() {
      this.deviceInfo = {
        deviceCode: '',
        deviceName: '',
        databaseType: '',
        deviceMasterInfo: []
      };
      this.listTable = [{
      index: 1,
      orgNo: null,
      orgName: null,
      orgNameEN: null,
      dataMapping: null,
      headerJP: null,
      headerEN: null,
      unit: null,
      ngFlg: null,
      roundingNumber: null,
      deviceCode: null,
      status: 1,
      dataMappingSelected: null,
      }];
      this.dataMappingOptions.forEach(option => {
        option.disabled = false;
      })
      this.$refs.form?.resetValidation();
      this.$refs.form2?.resetValidation();
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>


.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 100px;
  left: 0;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}

.v-input--has-state fieldset {
  border: 2px solid red !important;
}

.v-messages__message {
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}

.input-port input[type='number'] {
  -moz-appearance: textfield;
}

.input-port input::-webkit-outer-spin-button,
.input-port input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.width-150{
  width: 150px !important;
}


@media only screen and (max-width: 500px) and (max-height: 900px) {
  .addDevice-modal {
    margin-top: 5vh !important;
  }
}


::v-deep{

  .v-data-table__wrapper {
    overflow-y: hidden !important;
  }
  .flexMargin {
    margin: -15px 0px -15px 0px;
    padding: 0px 0px 0px 0px;
  }

  .orgNo {
    width: 60px !important;
  }
  .orgName {
    width: 170px !important;
  }
  .orgNameEN {
    width: 170px !important;
  }
  .dataMapping {
    width: 150px !important;
  }
  .dataJP {
    width: 170px !important;
  }
  .dataEN {
    width: 170px !important;
  }
  .flag {
    width: 100px !important;
  }
  .unit {
    width: 60px !important;
  }
  .roundingNumber {
    width: 150px !important;
  }

  @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 20px;
    padding: 0px 6px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 21px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
      margin-left: 4.55rem !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
