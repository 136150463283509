<template>
  <div class="row">
    <div class="col-sm-12 card p-0 m-0 mb-5">
      <div class="card-header">
        <div class="col-sm-12 text-left">
          <h4 class="mt-2"></h4>
        </div>
        <div class="row justify-content-end">
          <div class="col-xl-2 col-lg-4 col-3 button-container">
            <div style="padding-right:10px;" class="inner buttons-search justify-content-end">
              <v-btn v-if="showEdit" @click="handleAddLayout" class="mx-2" primary color="dark"
                     style="background-color: #3f51b5; color: white;">

                <span style="font-size: 20px">{{ $t("labels.layoutSetting.add") }}</span>
              </v-btn>

              <v-btn v-if="!showEdit && ACTION === 'OPS'" @click="clickButtonEdit" class="mx-2" color="dark"
                     style="background-color: #fb8c00; color: white;">

                <span style="font-size: 20px">{{ $t("labels.layoutSetting.edit") }}</span>
              </v-btn>
              <v-btn v-if="showEdit" class="mx-2" @click="handleSaveLayout" color="dark"
                     style="background-color: #3d76ad; color: white;">
                <span style="font-size: 20px">{{ $t("labels.layoutSetting.save") }}</span>
              </v-btn>
              <v-btn v-if="showEdit" class="mx-2" color="red" @click="handleCancelEditLayout"
                     style="background-color: #dc3545; color: white;">
                <span style="font-size: 20px">{{ $t("labels.layoutSetting.cancel") }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body bg-gray-200 mb-3 card-style">
        <grid-layout v-if="!loading" :layout.sync="layout" :col-num="colNum" :row-height="rowHeight" :margin="margin" :is-draggable="showEdit"
                     :auto-size="true"
                     :is-resizable="showEdit" :responsive="true" :vertical-compact="true" :use-css-transforms="true"
                     :responsive-layouts="layouts">
          <grid-item v-for="item in layout" :static="!showEdit" :x="item.x" :y="item.y" :w="item.w"
                     :h="item.h" :i="item.i" :key.sync="item.type" :id="item.type"
                     @resize="resizeEvent"
                     @resized="resizedEvent"
                     @move="moveEvent"
                     @moved="movedEvent"
                     >
              <component :is="(item.type === 'SVG') ? 'SVGChart' : (item.type === 'MSC') ? 'MWC': item.type"
                         :key="item.id"
                         :frameId="item.frameId"
                         :type="item.type"
                         :title="item.title"
                         :legend.sync="item.legend"
                         :series.sync="item.series"
                         :isShowEdit="showEdit"
                         :link="item.linkIp"
                         :listTable.sync="item.listTable"
                         :yAxis="item.yAxis"
                         :xAxis="item.xAxis"
                         :width.sync="item.width"
                         :height.sync="item.height"
                         :deviceCodeProp="item.deviceCode"
                         :limitTimeProp="item.limitTime"
                         :exportTypeProp="item.exportType"
                         :selectedStationsProp="item.listStation"
                         :isDashboard="false" :channelNoProp="item.orgNo"
                         :item="item" @closeFrame="onDeleteComponent"
                         />
          </grid-item>
        </grid-layout>
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <InitLayoutModal @close="showDialog" @handleChangeTypeComponent="changeType" :layout="layoutDataInfo"></InitLayoutModal>
  </div>
</template>

<script>
import MAC from "../../components/Charts/MAC.vue";
import MWC from "../../components/Charts/MWC.vue";
// import {GridItem, GridLayout} from "vue-grid-layout";
import WindCompass from "@/components/WindCompass/WindCompass";
// import GLS from "@/components/Charts/GLS";
// import GC from "@/components/Charts/GC";
import InitLayout from './InitLayout';
// import LR from "@/components/Charts/LR";
// import LS from "@/components/Charts/LS";
// import SC from "@/components/Charts/SC";
// import SVGChart from "@/components/Charts/SVG";
// import TC from "@/components/Charts/TC";
import {mapActions, mapMutations, mapState} from "vuex";
import i18nConfig from "@/i18n.lang";
import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  getCookiePrivileges,
  getCookieSiteUser,
  setCurrentPageCode
} from "@/utils/auth";
import {EventBus} from "@/utils/eventBus";
import InitLayoutModal from "@/pages/layoutSetting/InitLayoutModal";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "DashboardContent",
  components: {
    InitLayoutModal,
    WindCompass,
    // GridLayout,
    // GridItem,
    // GC,
    // GLS, LR,
    // LS,
    MAC, MWC,
    // SC, SVGChart, TC,
    InitLayout
  },
  computed: {
    ...mapState('layout', {
      isFlagChooseFrame: 'isFlagChooseFrame',
      isFlagDoneInitFrame: 'isFlagDoneInitFrame',
    }),
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    },
    clientHeight(){
      return this.$vuetify.breakpoint.height;
    },
    layouts(){
      return {lg: this.responsiveLayoutsLg, md: this.responsiveLayoutsMd, sm: this.responsiveLayoutsSm, xs: this.responsiveLayoutsXs};
    }
  },
  data() {
    return {
      loading: true,
      showDialog: false,
      isInit: true,
      ERROR_CODE: 0,
      speedData: 0,
      windDirection: 0,
      layout: [],
      layoutDataInfo: [],
      responsiveLayoutsLg: [],
      responsiveLayoutsMd: [],
      responsiveLayoutsSm: [],
      responsiveLayoutsXs: [],
      draggable: true,
      resizable: true,
      responsive: true,
      isResize: true,
      index: 0,
      showEdit: false,
      frameId: 0,
      colNum: 12,
      rowHeight: 30,
      margin: [10, 10],
      currentSiteID: null,
      removedFrames: [],
      isTypeMWC: false,
      PAGE_CODE: 'CS_LA',
      ACTION: 'OPS',
    };
  },
  created() {
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }

    this.currentSiteID = getCookieSiteUser();
    this.handleInitializeLayout();
  },
  mounted() {
    // this.index = this.layout.length;
    this.randomWind();
  },
  async beforeMount() {
    // start import ERROR_CODES by language
    await this.getErrorCode("layout");
  },
  beforeDestroy() {
    this.loading = false;
  },
  methods: {
    calFrameHeight: function(h){
      return this.rowHeight * h + (h - 1) * this.margin[0];
    },
    calFrameH: function(frameHeight){
      return Math.round((frameHeight + this.margin[0])/(this.rowHeight + this.margin[0]));
    },
    ...mapMutations('layout', {
      handleReloadChooseFrame: 'handleReloadChooseFrame',
      handleDoneInitFrame: 'handleDoneInitFrame',
    }),
    ...mapActions('layout', {
      updateLayout: "updateLayout",
      getLayout: "getLayout",
      removeLayout: "removeLayout",
    }),
    ...mapActions('frame', {
      getFrameDataByType: "getFrameDataByType",
    }),
    handleInitializeLayout() {
      const siteId = getCookieSiteUser();
      this.loading = true;

      this.getLayout(siteId).then((response) => {
        if (response.code === 0) {
          let responseLayout = response.data;
          let layoutIndex = 0;
          responseLayout.filter(data => {
            let layoutData = this.handleInitDataChart(data.locationObject, layoutIndex, data.title);
            if (data.frameData !== undefined && data.frameData !== null) {
              layoutData.listTable = data.frameData;
            }
            if (data.frameId !== undefined && data.frameId !== null) {
              layoutData.frameId = data.frameId;
            }
            if (data.deviceCode !== undefined && data.deviceCode !== null) {
              layoutData.deviceCode = data.deviceCode.toString();
            }
            // Frame Table start
            if(data.listStation) {
              layoutData.listStation = data.listStation
            }
            if(data.limitTime) {
              layoutData.limitTime = data.limitTime
            }
            if(data.exportType) {
              layoutData.exportType = data.exportType
            }
            if(data.orgNo !== null) layoutData.orgNo = data.orgNo
            // Frame Table end
            this.layout.push(layoutData);
            this.layoutDataInfo.push({
              id: data.id,
              siteId: siteId,
              order: data.order,
              frameId: data.frameId,
              locationSize: data.locationObject.locationSizeData
            });

            // this.resizeEvent(parseInt(data.locationObject.locationSizeData.id),
            //   parseInt(data.locationObject.locationSizeData.h),
            //   parseInt(data.locationObject.locationSizeData.w),
            //   parseInt(data.locationObject.locationSizeData.height),
            //   parseInt(data.locationObject.locationSizeData.width));

            layoutIndex++;

          });
          this.index = this.layout.length;
        }
      }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
      }).finally(() => {
        this.loading = false;

      })
    },
    handleInitDataChart(layout, layoutIndex, title) {
      layout.locationSizeData.i = layoutIndex;
      layout.locationSizeData.title = title;
      layout.locationSizeData.id = layoutIndex;
      layout.locationSizeData['legend'] = JSON.parse(layout.legend);
      layout.locationSizeData['series'] = JSON.parse(layout.series);
      if (layout.yaxis !== undefined && layout.yaxis !== null) {
        layout.locationSizeData['yAxis'] = JSON.parse(layout.yaxis);
      }
      if (layout.xaxis !== undefined && layout.xaxis !== null) {
        layout.locationSizeData['xAxis'] = JSON.parse(layout.xaxis);
      }
      if (layout.linkIp !== undefined && layout.linkIp !== null) {
        layout.locationSizeData['linkIp'] = layout.linkIp + ((layout.port !== null && layout.port.trim() !== '') ? (":" + layout.port) : "");
      }

      return layout.locationSizeData;
    },
    changeType(value) {
      this.handleRetrieveFrameDataByType(value.frameId, this.currentSiteID);
      this.handleReloadChooseFrame(false);
      this.showDialog = false;
      this.index++;
    },
    clickButtonEdit() {
      this.showEdit = !this.showEdit;
    },
    onDeleteComponent(frameId) {
      const index = this.layout.findIndex(frame => frame.frameId == frameId);
      let frameDataIndex = this.layoutDataInfo.findIndex(event => event.locationSize.frameId == frameId);
      this.removedFrames.push(this.layoutDataInfo[frameDataIndex]);

      this.layoutDataInfo.splice(frameDataIndex, 1);
      this.layoutDataInfo = this.layoutDataInfo.map((info, index)=>{
        return {...info, order: index};
      })
      this.layout.splice(index, 1);
      this.layout = this.layout.map((info, index)=>{
        return {...info, id: index, i: index };
      })
    },
    handleSaveLayout() {
      if (this.showEdit === true) {
        this.layout.filter((frame) => {
          let checkIndex = this.layoutDataInfo.findIndex(event => event.frameId === frame.frameId);
          if (checkIndex !== -1) {
            this.layoutDataInfo[checkIndex].locationSize = {
              x: (frame.x === null) ? 0 : frame.x,
              y: parseInt(frame.y),
              h: parseInt(frame.h),
              w: parseInt(frame.w),
              i: frame.id,
              id: frame.id,
              type: frame.type,
              height: frame.height,
              width: frame.width,
            }
          }
        })
        this.handleUpdateLayout();
        setTimeout(() => {
          this.$router.push({path: '/'});
        }, 500)
      }
    },
    handleAddLayout() {
      this.showDialog = !this.showDialog;
      EventBus.$emit('handleOpenAddFrame', {flag: this.showDialog});
    },
    handleUpdateLayout() {
      this.loading = true;

      this.updateLayout({data: this.layoutDataInfo, header: this.currentSiteID}).then((response) => {
        if (response.code === 0) {
          this.index = 0;
          this.layout.splice(0, this.layout.length);
          this.layoutDataInfo.splice(0, this.layoutDataInfo.length);
          this.removedFrames.splice(0, this.removedFrames.length);
          this.handleInitializeLayout();
          this.commonConfirmOKVue(this.ERROR_CODES[response.code]);
          this.showEdit = false;
          this.loading = false;
        } else {
          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
          this.loading = false;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
        this.loading = false;
      })
    },
    handleRemoveLayout() {
      this.removeLayout({data: this.removedFrames, siteId: this.currentSiteID}).then((response) => {
        if (response !== undefined) {
          if (response.code !== 0) {
            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
            this.loading = false;
          } else {
            this.handleUpdateLayout();
          }
        }
      }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
      })
    },
    resizeEvent: function (i, newH, newW, newHPx, newWPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.h = newH;
      chart.w = newW;
      chart.height = parseInt(newHPx);
      chart.width = parseInt(newWPx);
      this.$set(this.layout, index, chart);
    },
    resizedEvent: function (i, newH, newW, newHPx, newWPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.w = newW;
      chart.h = newH;
      chart.height = parseInt(newHPx);
      chart.width = parseInt(newWPx);
      this.$set(this.layout, index, chart);
      EventBus.$emit(`Resize_Layout_${chart.frameId}`);
    },
    containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.h = newH;
      chart.w = newW;
      chart.height = parseInt(newHPx);
      chart.width = parseInt(newWPx);
      this.$set(this.layout, index, chart);
    },
    moveEvent: function (i, newX, newY) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.x = newX;
      chart.y = newY;
      this.$set(this.layout, index, chart);
    },
    movedEvent: function (i, newX, newY) {
      const index = this.layout.findIndex((x) => x.i === i);
      let chart = this.layout[index];
      chart.x = newX;
      chart.y = newY;
      this.$set(this.layout, index, chart);
    },
    randomWind() {
      const vm = this;
      setInterval(function () {
        vm.speedData = Math.round(Math.random() * 100);
        vm.windDirection = Math.floor(
          Math.random() * (348.75 - 11.25 + 1) + 11.25
        );
      }, 1000);
    },
    handleRetrieveFrameDataByType: function (frameId, siteId) {
      this.loading = true;
      this.getFrameDataByType({frameId: frameId, siteId: siteId}).then(response => {
        if (response.code === 0) {
          let realh = this.calFrameH(500);
          let realHeight = this.calFrameHeight(realh);
          this.layout.push({
            x: 0,
            y: 0,
            w: 5,
            h: realh,
            i: this.index,
            id: this.index,
            frameId: frameId,
            legend: JSON.parse(response.data.legend),
            series: JSON.parse(response.data.series),
            yAxis: JSON.parse(response.data.yaxis),
            xAxis: JSON.parse(response.data.xaxis),
            type: response.data.typeFrameCode,
            height: realHeight,
            width: 900,
            title: response.data.name,
            deviceCode: response.data.deviceCode.toString(),
            listTable: response.data.listTable,
            // Handle for frame table start
            listStation: response.data?.listStation,
            limitTime: response.data?.limitTime,
            exportType: response.data?.exportType,
            // Handle for frame table end
          });
          this.layoutDataInfo.push({
            id: 0,
            siteId: siteId,
            order: this.index,
            frameId: frameId,
            locationSize: {
              x: (this.layout.length * 2) % (this.colNum || 12),
              y: this.layout.length + (this.colNum || 12),
              w: (response.data.typeFrameCode === 'MWC') ?  3 : 5,
              h: 2,
              i: this.index,
              id: this.index,
              type: response.data.typeFrameCode,
              height: 610,
              width: 900,
            }
          })
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning'); //this.ERROR_CODES[error.code]
      }).finally(() => {
        this.loading = false;
      });
    },
    handleCancelEditLayout() {
      this.commonConfirmVue(this.ERROR_CODES['CF_052_01_01'], () => {
        this.showEdit = false;
        this.$router.go(0);
      });
    },
  },
  watch: {
    'layout': {
      handler(newData) {
        this.layout = newData;
        this.responsiveLayoutsLg = cloneDeep(this.layout);
        this.responsiveLayoutsMd = cloneDeep(this.layout);
        let y = 0;
        this.responsiveLayoutsMd.forEach((element,index) => {
          element.w = 10;
          element.x = index;
          element.y = y;
          y += element.h;
        });
        y =0;
        this.responsiveLayoutsSm = cloneDeep(this.layout);
        this.responsiveLayoutsSm.forEach((element,index) => {
          element.w = 6;
          element.x = index;
          element.y = y;
          y += element.h;
        });
        y =0;
        this.responsiveLayoutsXs = cloneDeep(this.layout);
        this.responsiveLayoutsXs.forEach((element,index) => {
          element.w = 4;
          element.x = index;
          element.y = y;
          y += element.h;
        });
        this.index = this.layout.length;
      },
      deep: true
    },
    "$i18n.locale": async function (currentLang) {
      // start import ERROR_CODES by language
      await this.getErrorCode("layout");
    },
  },
};
</script>

<style lang="scss" scoped>
// @media (max-width: 1024px) {
//   .vue-grid-layout{
//     height:96%!important;
//   }
//   .vue-grid-item {
//     min-width: 500px!important;
//     position: relative!important;
//     width: 100%!important;
//     margin-bottom: 30px;
//     transform: translate3d(10px, 10px, 0px)!important;
//   }
// }

.vue-grid-item {
  max-width: 100% !important;
}

.container-fluid .vue-grid-layout .vue-grid-item:not(.vue-grid-placeholder) {
  min-height: 100%;
}

.container-fluid .vue-grid-layout .vue-grid-item.minMax {
  font-size: 12px;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.container-fluid .vue-grid-layout .vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>");
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.card-category {
  text-transform: capitalize;
  font-weight: 400;
  color: #9a9a9a;
  font-size: 0.7em;
}

.card-category {
  font-size: 1em;
}

.min-h-136 {
  min-height: 136px;
}

.disablegrid {
  pointer-events: none;
  opacity: 0.7;
}

@media only screen and (max-width: 500px) and (max-height: 900px) {
  .button-container {
    display: flex;
    flex-direction: row;
    margin-right: 15rem !important;
  }
}

</style>
