<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light modal-title">
        <span class="text-h5">{{ title }}</span>
        <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
      <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
        <v-container class="pt-0 pb-0">
          <div class="row">
            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.userId") }}</h6>
                <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
              </div>
              <v-text-field maxlength="20" v-model="editedItem.userId" hide-details="auto" :disabled="editMode" :rules="userIdRules"
                            outlined dense>
              </v-text-field>
            </div>
            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.password") }}</h6>
                <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
              </div>
              <div v-if="!checkDetail">
                <v-text-field hide-details="auto" :rules="!(editPasswordFlag === true && editMode) ? passwordRules : []"
                              :type="showPassword ? 'text' : 'password'"
                              maxlength="30"
                              :append-outer-icon="editMode ? (editPasswordFlag ? 'mdi-pencil' : 'mdi-window-close') : ''"
                              :append-icon="(showIconEye || !editMode || !editPasswordFlag) ? (showPassword ? 'fas fa-eye' : 'fa-eye-slash') : ''"
                              name="input-10-2" class="input-group--focused"
                              @click:append="showPassword = !showPassword"
                              @click:append-outer="editPasswordFlag = !editPasswordFlag"
                              outlined dense v-model.trim="editedItem.password"
                              :readonly="editPasswordFlag === true && editMode"
                              placeholder="********"
                ></v-text-field>
              </div>
              <div v-else>
                <v-text-field maxlength="30" type="password" v-model="editedItem.password" name="input-10-2"
                              placeholder="********"
                              class="input-group--focused" outlined dense :disabled="checkDetail"></v-text-field>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.userName") }}</h6>
                <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
              </div>
              <v-text-field maxlength="50" v-model.trim="editedItem.userName" hide-details="auto" :disabled="checkDetail" :rules="fullNameRules"
                            outlined dense>
              </v-text-field>
            </div>

            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.phoneNumber") }}</h6>
              </div>
              <v-text-field maxlength="30" v-model.trim="editedItem.phoneNumber" hide-details="auto" :disabled="checkDetail" :rules="phoneNumberRules"
                            outlined dense>
              </v-text-field>
            </div>
          </div>


          <div class="row">
            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.email") }}</h6>
              </div>
              <v-text-field maxlength="100" v-model.trim="editedItem.email" hide-details="auto" :disabled="checkDetail" :rules="emailRules"
                            outlined dense>
              </v-text-field>
            </div>

            <div class="col-xl-6 col-sm-12 pb-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.status") }}</h6>
                <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
              </div>
              <v-select :items="optionsStatus" v-model="editedItem.status" :disabled="checkDetail"
                        outlined dense></v-select>
            </div>
          </div>


          <div class="row">
            <div class="col-12 pt-0">
              <div class="d-flex justify-content-start ">
                <h6 class="mr-1 mb-0 label-black">{{ $t("labels.userManager.role") }}</h6>
                <h5 class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</h5>
              </div>
              <v-autocomplete outlined dense :items="optionsRoles" key="value" item-text="text" item-value="value"
                              v-model="editedItem.role" :disabled="checkDetail" @change="changeRole" :rules="roleRules"
              ></v-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pt-0">
              <h6 class="mb-1 label-black">{{ $t("labels.userManager.address") }}</h6>
              <v-textarea maxlength="200" v-model.trim="editedItem.address" auto-grow outlined rows="5" row-height="15"
                          :disabled="checkDetail" :rules="addressRules">
              </v-textarea>
            </div>
          </div>

          <div v-if="checkDetail" class="row mt-0">
            <div class="col-6">
              <h6 class="mb-1 label-black">{{ $t("labels.userManager.createDate") }}</h6>
              <v-text-field v-model="editedItem.createDate" hide-details="auto" outlined dense
                            :disabled="checkDetail"></v-text-field>
            </div>
            <div class="col-6">
              <h6 class="mb-1 label-black">{{ $t("labels.userManager.createBy") }}</h6>
              <v-text-field v-model="editedItem.createBy" hide-details="auto" outlined dense
                            :disabled="checkDetail"></v-text-field>
            </div>
          </div>
          <div v-if="checkDetail" class="row mt-0">
            <div class="col-6 pb-0">
              <h6 class="mb-1 label-black">{{ $t("labels.userManager.updateDate") }}</h6>
              <v-text-field v-model="editedItem.updateDate" hide-details="auto" outlined dense
                            :disabled="checkDetail"></v-text-field>
            </div>
            <div class="col-6 pb-0">
              <h6 class="mb-1 label-black">{{ $t("labels.userManager.updateBy") }}</h6>
              <v-text-field v-model="editedItem.updateBy" hide-details="auto" outlined dense
                            :disabled="checkDetail"></v-text-field>
            </div>
          </div>
        </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!checkDetail" class="text-white mr-1" style="background-color: #3d76ad" @click="handleSaveData"
               text>
          {{ $t("labels.userManager.save") }}
        </v-btn>
        <v-btn v-if="!checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.userManager.cancel") }}
        </v-btn>
        <v-btn v-if="checkDetail" class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.userManager.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from '@/utils/eventBus.js'
import {mapActions} from "vuex";
import {
  getCookieSiteUser, getRememberKCloudID, setCurrentPageCode
} from "@/utils/auth";
import moment from "moment";
import $ from 'jquery';

export default {
  name: "UserModal",
  props: ['visible', 'titleModal'],
  data() {
    return {
      valid:false,
      loading: false,
      title: "",
      showDialog: false,
      editMode: false,
      checkDetail: false,
      showPassword: false,
      showIconEye: false,
      editPasswordFlag: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      editedItem: {
        indexUser: 0,
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createBy: '',
        email: '',
        phoneNumber: '',
        address: '',
        updateBy: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      },
      optionsStatus: [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ],
      optionsRoles: [],
      userInfo: {
        index: 0,
        siteId: 0
      },
      headerRequest: {
        'PAGE-CODE': 'CS_UM'
      },
      PAGE_CODE: 'CS_UM',
      isSubmitAction: false,
      items: [],
      flagAdd: false,
      userIdRules: [
        (value) => !!value || this.$t("labels.userManager.userId") + this.ERROR_CODE['IS_REQUIRED'],
        (value) =>/^[a-z0-9]+$/i.test(value) || this.$t("labels.userManager.userId") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) => (value !== undefined && value.length >= 8) || this.checkActionCheckMinLength(this.$t("labels.userManager.userId"), 8),
      ],
      fullNameRules: [
        (value) => !!value || this.$t("labels.userManager.userName") + this.ERROR_CODE['IS_REQUIRED'],
        (value) => value && !!value.trim() || this.$t("labels.userManager.userName") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      passwordRules: [
        (value) => !!value || this.$t("labels.userManager.password") + this.ERROR_CODE['IS_REQUIRED'],
        (value) =>/^[A-Za-z0-9`~!$%^*()_@./#&+-=]*$/i.test(value) || this.$t("labels.userManager.password") + this.ERROR_CODE['NOT_CORRECT_FORMAT'],
        (value) => (value !== undefined && value !== null && value.length >= 8) || this.checkActionCheckMinLength(this.$t("labels.userManager.password"), 8),
      ],
      phoneNumberRules: [
        (value) =>(/^\d+$/.test(value) || (value === null || value === undefined || value.length === 0)) || this.$t("labels.userManager.phoneNumber") + this.ERROR_CODE['NOT_CORRECT_FORMAT']
      ],
      emailRules: [
        (value) =>(/^[A-Za-z0-9+_.-]+@(.+)$/.test(value) || (value === null || value === undefined ||  value.length === 0)) || this.ERROR_CODE['EMAIL_NOT_CORRECT_FORMAT']
      ],
      roleRules: [
        (value) => !!value || this.$t("labels.userManager.role") + this.ERROR_CODE['IS_REQUIRED']
      ],
      addressRules: [
        (value) => {
          if (value === undefined || value === null || value.length === 0) {
            return [];
          }
          return value && !!value.trim() || this.$t("labels.userManager.address") + this.ERROR_CODE['NOT_CORRECT_FORMAT'];
        },
      ],
    }

  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/users/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/users/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      this.optionsStatus = [
        {value: 1, text: this.$t('labels.systemUserManager.statusOptions.active')},
        {value: 2, text: this.$t('labels.systemUserManager.statusOptions.deactivate')},
      ]
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  },
  async beforeMount() {
    if (this.checkDetail === true){
      this.loading = true;
    }
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/users/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/users/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    this.handleGetRoleListBySiteId();

    EventBus.$on('handleOpenUserEditModal', (value) => {
      this.title = value.title;
      this.editedItem = value.editedItem;
      this.showDialog = value.flag;
      if (value.flag){
        $('#main-header').css("pointer-events","none");
      }
      else{
        $('#main-header').css("pointer-events","");
      }
      this.checkDetail = value.checkDetail;
      this.flagAdd = value.checkAdd;
      this.editMode = value.editMode;
      this.$refs?.form?.resetValidation();
      if (this.checkDetail || this.editMode) {
        this.handleGetDetailUserManagement();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('handleOpenUserEditModal');
  },
  methods: {
    ...mapActions("users", {
      getDetailUserManagement: "getDetailUserManagement",
      addUserManagement: "addUserManagement",
      updateUserManagement: "updateUserManagement"
    }),
    ...mapActions("roles", {
      getRoleListBySiteId: "getRoleListBySiteId"
    }),
    handleSaveData() {
      if(!this.$refs.form.validate()){
        return;
      }
      if (!this.flagAdd) {
        this.handleUpdateUserManagement();
      } else {
        this.handleAddUser();
      }
    },
    handleUpdateUserManagement() {
      this.loading = true;
      let obj = {
        index: this.editedItem.index,
        UserId: this.editedItem.userId,
        password: this.editedItem.password,
        Username: this.editedItem.userName,
        phoneNumber: this.editedItem.phoneNumber,
        email: this.editedItem.email,
        status: this.editedItem.status,
        siteId: this.editedItem.siteId,
        role: this.editedItem.role,
        address: this.editedItem.address,
      }
      let object = {
        json: obj,
        header: this.headerRequest
      }
      this.updateUserManagement(object)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events","");
            this.$emit('clicked', "ancd");
            this.commonConfirmOKVue(this.ERROR_CODE['SAVED'])
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.handleResetUserInfo();
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    handleAddUser() {
      this.loading = true;

      let obj = {
        userid: this.editedItem.userId,
        password: this.editedItem.password,
        Username: this.editedItem.userName,
        phoneNumber: this.editedItem.phoneNumber,
        email: this.editedItem.email,
        status: this.editedItem.status,
        siteId: getCookieSiteUser(),
        role: this.editedItem.role,
        address: this.editedItem.address,
      }
      let object = {
        json: obj,
        header: this.headerRequest
      }
      this.addUserManagement(object)
        .then(response => {
          if (response.code === 0) {
            this.showDialog = false;
            $('#main-header').css("pointer-events","");
            this.$emit('clicked', "");
            this.commonConfirmOKVue(this.ERROR_CODE['SAVED'])
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.handleResetUserInfo();
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    handleGetRoleListBySiteId() {
      let obj = {
        siteId: getCookieSiteUser(),
        PAGE_CODE: this.PAGE_CODE
      }
      this.optionsRoles = []
      this.getRoleListBySiteId(obj)
        .then(response => {
          if (response.code === 0) {
            if (response.data !== null && response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                let option = {
                  value: response.data[i].id,
                  text: response.data[i].name
                }
                this.optionsRoles.push(option)
              }
            }

          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleGetDetailUserManagement() {
      this.userInfo.userId = this.editedItem.userId;
      this.userInfo.siteId = getCookieSiteUser();
      let object = {
        json: this.userInfo,
        header: this.headerRequest
      }
      this.getDetailUserManagement(object)
        .then(response => {
          if (response.code === 0) {
            this.editedItem = response.data.userdetail;
            if (response.data.userdetail !== null) {
              this.editedItem.createDate = moment(this.editedItem.createdDate).format('YYYY/MM/DD');
              this.editedItem.updateDate = moment(this.editedItem.createdDate).format('YYYY/MM/DD');
              this.editedItem.role = this.editedItem.roleId;
            }
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    handleConfirmCloseModal() {
      if (this.checkDetail === false) {
        this.commonConfirmVue(this.ERROR_CODE['CF_CLOSE'], () => {
          this.showDialog = false
          $('#main-header').css("pointer-events","");
          this.handleResetUserInfo();
        });
      } else {
        this.showDialog = false
        $('#main-header').css("pointer-events","");
        this.handleResetUserInfo();
      }
    },
    checkActionCheckMinLength(field, number) {
      let text = this.ERROR_CODE['E_052_01_13'];
      text = text.replace("[X]", this.$t(field)).replace("[Y]", number);
      return text;
    },
    changeRole(e) {
      this.editedItem.role = e
    },
    handleResetUserInfo() {
      this.editedItem = {
        indexUser: 0,
        index: 0,
        userId: '',
        username: '',
        password: '',
        status: false,
        siteId: 0,
        siteName: '',
        createDate: '0000/00/00',
        updateDate: '0000/00/00',
        device: [''],
        createBy: '',
        email: '',
        phoneNumber: '',
        address: '',
        updateBy: [''],
        role: [
          {
            usertype: 1,
            status: 1
          }
        ],
        curator: ['']
      }
      this.editMode = false;
      this.checkDetail = false;
      this.showPassword = false;
      this.showIconEye  = false;
      this.editPasswordFlag = true;
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  border-top: 1px solid #ebebeb;
}
.label-black {
    color: black !important;
}
.h-text-danger{
  height: 2px !important;
}
.v-input--has-state fieldset{
  border: 2px solid red !important;
}
.v-messages__message{
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}

@media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
  .modal-title {
    margin-top: 5rem !important;
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
