<template>
  <div class="wrappers adminPage-wrapper">
    <v-app-bar style="position: absolute; background-color: rgb(7, 97, 173) !important;">
<!--      <v-app-bar-nav-icon class="d-flex justify-content-start">-->
<!--        <v-toolbar-title class="white&#45;&#45;text d-flex">-->
<!--          <div class="">-->
<!--            <img v-if="$i18n.locale === 'jp'" class="logo" style="width:100%;margin-top: 215px;"-->
<!--                 src="@/assets/img/top_jp.png"-->
<!--                 alt="">-->
<!--            <img v-else class="logo" style="width:100%;margin-top: 215px;" src="../assets/img/top_en.png" alt="">-->
<!--          </div>-->
<!--        </v-toolbar-title>-->
<!--      </v-app-bar-nav-icon>-->
      <v-spacer></v-spacer>
      <div class="d-flex justify-content-end align-content-end align-items-end">
        <div class="row mt-3">
          <v-select @change="handleActionChangeLang(getCurrentLocale)" :items="getAllLocales" v-model="locale"
                          class="font-weight-bold multi-language-select"
                          key="code" item-value="code" style="width: 150px;" dense outlined>
            <template v-slot:[`item`]="{ item }">
                        <span class="large"><i class="flag-icon mr-2" :class="item.flag"
                                               style="width: 32px; height: 24px">
                        </i> {{ $t(item.name) }}</span>
            </template>
            <template #selection="{ item }">
                        <span class="large text-white flag-icon-ipad flag-icon-iphone"><i class="flag-icon" :class="item.flag"
                                                           style="font-size: xxx-large; margin-right: -32px;">
                        </i> </span>
            </template>
          </v-select>
          <v-btn class="btn btn-sm btn-danger ml-2 mr-8 mt-2 font-weight-bold text-white button-logout-ipad button-logout-iphone"
                 outlined
                 style="height: 40px"
                 @click.prevent="handleLogout">
            {{ $t('dashboard.logout') }}
          </v-btn>
        </div>
      </div>
    </v-app-bar>
    <div class="hold-transition container background-full-screen adminPage-container" >
      <div class="row align-items-center adminPage-content">
        <div class="col-sm-12 mx-auto d-flex justify-content-center adminPage-logo">
          <img v-if="$i18n.locale === 'jp'" class="logo" style="width:30%;height:30%;margin-top: 50px;"
               src="@/assets/img/top_jp.png"
               alt="">
          <img v-else class="logo" style="width:30%;height:30%;margin-top: 50px;" src="../assets/img/top_en.png" alt="">
        </div>
        <div class="col-md-8 mx-auto">
          <div class="card h-100 border-primary justify-content-center">
            <div class="card-header ">
              <h2 class="mx-auto _fw-500 align-center card-header-iphone font-size-iphone">
                K-Cloud {{ $t('admin.namePage') }}
              </h2>
            </div>
            <div class="card-body">
              <div class="login-card-body cm-iphone">
                <div ref="form" class="row">
                  <p class="text-font-weight-bold mb-0 font-size-iphone">{{ $t('admin.siteButton') }}</p>
                  <v-col cols="12" data-app>
                    <div class="select2-style font-size-iphone">
                      <Select2 v-model="selectedSite" :options="items" :placeholder="$i18n.locale === 'en' ? 'Select construction site' : '現場を選択してください'"/>
                    </div>
                    <v-card class="rounded button-admin" style="background-color: unset !important">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <button class="btn btn-lg btn-primary p-1 rounded" style="width:100%;" v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="goToDashboardPage">
                            <label class="d-flex align-content-center justify-content-center font-size-iphone"
                                   style="font-size: 1.4rem; color: white">{{ $t('admin.siteButton') }}</label>
                          </button>
                        </template>
                      </v-menu>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-show="userType === 1 || userType === 2">
                    <v-divider class="mx-1 bg-black"></v-divider>
                  </v-col>
                  <p class="text-font-weight-bold mb-0 font-size-iphone" v-show="userType === 1 || userType === 2">
                    {{ $t('admin.systemButton') }}</p>
                  <v-col cols="12 rounded" style="background-color: unset !important"
                         v-show="userType === 1 || userType === 2">
                    <v-card class="button-admin">
                      <button class="btn btn-lg btn-primary p-1 rounded" style="width:100%;"
                              @click.stop="goToAdminManagement">
                        <label class="white--text d-flex align-content-center justify-content-center font-size-iphone"
                               style="font-size: 1.4rem; color: white">{{
                            $t('admin.systemButton')
                          }}</label>
                      </button>
                    </v-card>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterPage className = "adminPage-footer"></FooterPage>

  </div>
</template>
<script>
import i18nConfig from "@/i18n.lang";
import {
  cookiesGetI18Lang, cookiesRemoveI18Lang,
  cookiesSetI18Lang,
  getCookiePrivileges,
  getCookieSiteUser,
  getCookieUserType,
  getRememberKCloudID, removeCookiePrivileges,
  removeCookieSiteUser,
  removeCookieUserType,
  removeCurrentPageCode,
  removeMenuAccesses,
  removeToken,
  setCookieSiteUser
} from "@/utils/auth";
import {getToken, setMenuAccesses} from "@/utils/auth";
import sidebarLinks from "@/sidebarLinks";
import {mapActions} from 'vuex'
import FooterPage from "@/components/Footer.vue";
import Select2 from "v-select2-component"
import {updateCurrentSite} from "@/api/sites";

export default {
  components: {FooterPage, Select2},
  props: {
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      locale: 'jp',
      items: [],
      selectedSite: 0,
      userType: 1,
      checkUserType: false,
      itemsCopy: [],
      searchTerm: "",
    }
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  created() {
    // coding continue ...
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }
    this.locale = this.$i18n.locale;
    this.handleGetUserList()
    sidebarLinks.filter(v => {
      v.display = true;
    })
    this.userType = parseInt(getCookieUserType());
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ============================================================================value==================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/auth/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/auth/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {
    this.itemsCopy = [...this.items];
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout"
    }),
    ...mapActions("sites", {
      getUserSiteList: "getUserSiteList",
      updateCurrentSite: "updateCurrentSite"
    }),
    handleUpdateCurrentSite(){
      this.updateCurrentSite(this.selectedSite).then((response) => {
        if(response.code !== 0 ){
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'error');
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      })
    },
    goToDashboardPage() {
      if (parseInt(getCookieUserType()) !== 4) {
        if (this.selectedSite !== null && this.selectedSite !== 0) {
          // this.handleUpdateCurrentSite();
          setCookieSiteUser(this.selectedSite);
          setMenuAccesses(1)

          setTimeout(() => {
            this.$router.push({path: '/'});
          }, 1000)
        } else {
          this.commonErrorVue(this.ERROR_CODE['E_010_03']);
        }
      } else {
        let accessMenu = JSON.parse(getCookiePrivileges());
        this.handleDirectPageUserType4(accessMenu);
      }
    },
    handleDirectPageUserType4(accessMenu) {
      if (accessMenu[0].children !== undefined && accessMenu[0].children.length > 0) {
        this.$router.push({path: accessMenu[0].children[0].path});
      } else {
        this.$router.push({path: accessMenu[0].path});
      }
    },
    goToAdminManagement() {
      setMenuAccesses(2)
      if (parseInt(getCookieUserType()) === 2 || parseInt(getCookieUserType()) === 3) {
        this.$router.push({path: '/construction'});
      } else {
        setTimeout(() => {
          this.$router.push({path: '/system-user-management'});
        }, 1000)
      }
    },
    handleActionChangeLang(lang) {
      this.$i18n.locale = (lang === 'jp') ? 'en' : 'jp';
      this.locale = (lang === 'jp') ? 'en' : 'jp';
      cookiesSetI18Lang(lang)
    },
    handleGetUserList() {
      this.getUserSiteList()
        .then(response => {
          if (response.code === 0) {
            if (response.data !== null && response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                let obj = {
                  id: response.data[i].id,
                  text: response.data[i].siteName
                }
                this.items.push(obj)
              }
              this.selectedSite = this.items[0].id
              this.itemsCopy = [...this.items];
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log("[handleLogout] err", err);
        })
        .finally(final => {
        });
    },
    handleLogout() {
      this.commonConfirmVue(this.$t('confirm.msgLogout'), () => {
        const userId = getRememberKCloudID();
        const token = getToken();
        console.log('[handleLogout]', userId)

        this.logout({userId: userId, token: token})
        .then(response => {
          removeToken();
          removeMenuAccesses();
          removeCookieUserType();
          removeCookieSiteUser();
          removeCurrentPageCode();
          removeCookiePrivileges();
          cookiesRemoveI18Lang();
          this.$router.push("/login");
          if (response.code === 0) {
            this.commonNotifyVue(this.ERROR_CODE['LOGIN_SUCCESS'], 'info');
          }
        })
        .catch(err => {
          console.log("[handleLogout] err", err);
        })
        .finally(final => {
        });
      });
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {
      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/auth/${currentLang}.js` )
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/auth/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
}
</script>
<style scoped lang="scss">

.adminPage-container {
  margin-bottom: 198px;
}


@media only screen and (max-width: 500px) and (max-height: 896px) {
  .adminPage-content {
    margin-top: 4vh;
  }
  .font-size-iphone {
    font-size: 15px !important;
  }
  .flag-icon {
    width: 4rem !important;
    height: 3rem !important;
  }
  .button-logout-ipad {
    height: 3rem !important;
  }
}

@media only screen and (max-height: 500px) and (max-width: 896px) {
  .adminPage-wrapper {
    min-height: 180vh !important;
    background-color: #FFFF !important;
  }
  .adminPage-content {
    margin-top: 10vh;
  }
  .button-logout-iphone {
    margin-top: 2vh !important;
  }
  .flag-icon-iphone {
    margin-top: 1vh;
  }
  .font-size-iphone {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1024px) and (max-height: 1366px) {
  .adminPage-content {
    margin-top: 10vh;
  }
  .button-logout-ipad {
    margin-top: 0.5vh !important;
    margin-left: 2vh !important;
  }
}

@media only screen and (max-height: 1024px) and (max-width: 1366px) {
  .adminPage-content {
    margin-top: 10vh;
  }
  .button-logout-ipad {
    margin-top: 0.5vh !important;
    margin-left: 2vh !important;
  }
}

.button-admin {
  box-shadow: none !important;
}

.select2-style {
  width: 100% !important;
}

.select2-selection {
  height: 50px !important;
}

//select style
.select2-style {
  padding-bottom: 15px;
}

.v-list-item:hover {
  background-color: rgb(141, 137, 137);
  cursor: pointer;
}


::v-deep {
  .select2-container {
    width: 100% !important;
  }

  .multi-language-select .v-select__selections input {
    display: none;
  }

  .multi-language-select.v-text-field {
    width: auto !important;
  }

  .multi-language-select.v-text-field--outlined fieldset {
    border-style: none !important;
  }

  .mdi-menu-down::before {
    content: none !important;
    display: none;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1366px){
  .flag-icon {
    width: 4rem;
    height: 3rem;
  }
  .button-logout-ipad {
    height: 3rem !important;
  }
}


</style>
