<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div>
          <div class="row m-1">
            <div class="col-sm-6 col-lg-6">
              <div class="input-group input-group-sm">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t("labels.deviceMaster.deviceCode")
                  }}</span>
              </div>
              <div class="input-group-append">
                <v-text-field maxlength="4" hide-details="auto" outlined dense v-model="deviceSearchInfo.deviceCode"></v-text-field>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6">
              <div class="input-group input-group-sm">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t("labels.deviceMaster.deviceName")
                  }}</span>
              </div>
              <div class="input-group-append">
                <v-text-field maxlength="50" hide-details="auto" outlined dense v-model="deviceSearchInfo.deviceName"></v-text-field>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end mr-3 mt-10">
            <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetDeviceList">
              <v-icon right dark class="mr-1">
                fas fa-search
              </v-icon>
              {{ $t("labels.search") }}
            </v-btn>
            <v-btn class="bg-secondary font-weight-bold" @click.prevent="clearFilter">
              <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
              {{ $t("labels.clear") }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 text-nowrap" data-app>
          <v-data-table
            :headers="headers" :items="deviceList" class="elevation-1" mobile-breakpoint="0"
            hide-default-header
            fixed-header
            :footer-props="{
               'items-per-page-options': [15, 30, 50],
               'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
            }"
          >
            <template v-slot:no-data>
              {{ $t("exportData.noData") }}
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th v-for="header in headers" :key="header.value" class="text-uppercase" scope="col">
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" style="background-color: #3d76ad" @click="createItem"
                       v-if="userType !== null && userType === 1">
                  <v-icon color="white" size="20" class="mr-2 dark mt-0">
                    mdi-plus-box
                  </v-icon>
                  <span style="font-size: 17px" class="font-weight-bold">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.id.slice(0, 10) }}</span>
                  <span v-if="item.id.length > 10"> {{ '...' }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.deviceName`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.deviceName.slice(0, 20) }}</span>
                  <span v-if="item.deviceName.length > 20"> {{ '...' }}</span>
                </template>
                <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ item.deviceName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <font-awesome-icon v-if="item.status === 1" color="white" class="mr-2 text-success"
                                 icon="fa-solid fa-circle-check" size="lg">

              </font-awesome-icon>
              <font-awesome-icon v-if="item.status === 2" color="white" class="mr-2 text-warning"
                                 icon="fa-solid fa-circle-pause" size="lg">
              </font-awesome-icon>
              <v-icon v-else-if="item.status === 3" size="30" class="mr-2 text-danger">
                mdi-close-circle
              </v-icon>
              <v-icon v-else-if="item.status === 4" size="30" class="mr-2 text-info">
                mdi-backup-restore
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <button class="m-1 mr-2" @click="detailItem(item)" :disabled="item.status === 4">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2" :class="item.status !== 4 ? 'text-info':'text-dark'">
                  </i>
                </button>
                <button class="m-1 mr-2" @click="editItem(item)" :disabled="item.status === 4">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2" :class="item.status !== 4 ? 'text-warning':'text-dark'">
                  </i>
                </button>
                <button class="m-1" @click="confirmDeleteItem(item)" :disabled="item.isUsed === true" v-if="userType !== null && userType === 1">
                  <i class="fas fa-trash-alt fa-lg pt-2 pb-2" :class="item.isUsed === false ?'text-danger':'text-dark'">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <DeviceEditModal @handleGetDeviceList="handleGetDeviceList" @close="modelEdit = false"/>
  </div>
</template>

<script>

import {EventBus} from "@/utils/eventBus";
import DeviceEditModal from "@/modals/Device/DeviceEditModal";
import {mapActions} from 'vuex';
import moment from "moment";
import {getCookiePrivileges, getCookieUserType, setCurrentPageCode} from "@/utils/auth";
import $ from 'jquery';

export default {
  name: "DeviceMasterPage",
  components: {DeviceEditModal},
  data() {
    return {
      ERROR_CODE: '',
      PAGE_CODE: 'SA_DM',
      ACTION: 'OPS',
      loading: false,
      deviceCodeDeleteSelected: "",
      deviceSearchInfo: {
        deviceCode: "",
        deviceName: "",
        PageSize: 5,
        PageNumber: 1,
        isMasterScreen: true
      },
      deviceList: [],
      modelDetail: false,
      modelEdit: false,
      modelCreate: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50px"},
        {text: 'labels.deviceMaster.deviceCode', value: 'code', align: 'start', sortable: false},
        {text: 'labels.deviceMaster.deviceName', value: 'nameEn', align: 'start', sortable: false},
        {text: 'labels.createdate', value: 'createdDate', align: 'center', sortable: false},
        {text: 'labels.updatedate', value: 'updatedDate', align: 'center', sortable: false},
        {text: 'genba.table.action', value: 'actions', sortable: false, align: 'center'},
      ],
      editedIndex: -1,
      editedItem: {
        deviceCode: '',
        deviceName: '',
      },
      userType: null
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {
      await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
    'deviceSearchInfo.deviceCode': function () {
    }
  },
  async beforeMount() {
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/deviceMaster/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
  },
  mounted() {
  },

  created() {
    this.loading = true;
    setCurrentPageCode(this.PAGE_CODE);
    this.userType = parseInt(getCookieUserType());
    this.handleGetDeviceList();
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
      })
    }
  },
  methods: {
    ...mapActions("deviceMaster", {
      getDeviceDetail: "getDeviceDetail",
      getDeviceList: "getDeviceList",
      deleteDevice: "deleteDevice",
    }),
    handleGetDeviceList() {
      this.loading = true;
      this.getDeviceList(this.deviceSearchInfo)
        .then(response => {
          if (response.code === 0) {
            this.deviceList = response.data.deviceList;
            if (response.data.deviceList !== null && response.data.deviceList.length > 0) {
              for (let i = 0; i < this.deviceList.length; i++) {
                this.deviceList[i].index = i + 1;
                this.deviceList[i].createdDate = moment(this.deviceList[i].createdDate).format('YYYY/MM/DD');
                this.deviceList[i].updatedDate = moment(this.deviceList[i].updatedDate).format('YYYY/MM/DD');
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    confirmDeleteItem(item) {
      this.deviceCodeDeleteSelected = item.code;
      this.commonConfirmVue(this.$t('labels.deviceMaster.msgDelete'), () => {
        this.deleteDevice(this.deviceCodeDeleteSelected)
          .then(response => {
            if (response.code === 0) {
              this.handleGetDeviceList();
              this.commonConfirmOKVue(this.ERROR_CODE['DELETE_SUCCESS'])
            } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
            
            this.isSubmitAction = false;
          });
      }, () => {
        this.deviceCodeDeleteSelected = 0;
      })
    },
    clearFilter() {
      this.deviceSearchInfo.deviceCode = "";
      this.deviceSearchInfo.deviceName = null;
      this.deviceSearchInfo.Status = 0;
      this.isMasterScreen = true;
      this.handleGetDeviceList();
    },
    initialize() {
    },
    createItem() {
      EventBus.$emit('handleOpenDeviceMasterEditModal', {
        checkDetail: false,
        nameLabel: "labels.deviceMaster.create",
        Flg: true,
        editFlag: false,
        createFlg: true,
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events", "none");
    },
    editItem(item) {
      this.getDeviceDetail(item.code)
        .then(response => {
          if (response.code === 0) {
            this.editedIndex = this.deviceList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            EventBus.$emit('handleOpenDeviceMasterEditModal', {
              editedItem: this.editedItem,
              checkDetail: false,
              nameLabel: "labels.deviceMaster.edit",
              Flg: true,
              editFlag: true,
              createFlg: false
            });
            this.modelEdit = true;
            $('#main-header').css("pointer-events", "none");
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
    },
    detailItem(item) {
      this.getDeviceDetail(item.code)
        .then(response => {
          if (response.code === 0) {
            this.editedIndex = this.deviceList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            EventBus.$emit('handleOpenDeviceMasterEditModal', {
              editedItem: this.editedItem,
              checkDetail: true,
              nameLabel: "labels.deviceMaster.detail",
              Flg: true,
              editFlag: false,
              createFlg: false
            });
            this.modelDetail = true
            $('#main-header').css("pointer-events", "none");
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
    },
  }
}
</script>

<style lang="scss" scoped>
td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}

thead tr th {
  text-align: center !important;
}

::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
   .v-data-footer__pagination {
    margin-left: 4rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
