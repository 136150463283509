<template>
    <v-dialog content-class="mt-5 v-dialog--custom" v-model="showDialog" persistent scrollable>
        <v-card>
            <v-card-title class="bg-gray-light">
                <span class="text-h5 label-black">{{ title }}</span>
                <v-btn @click="closeDialog" class="ml-auto" icon>
                    <v-icon id="close-button">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-tabs v-model="tab" style="background-color:gainsboro !important;" centered icons-and-text>
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                        <p style="color: black;">{{ $t("exportData.csv") }}</p>
                    </v-tab>
                    <v-tab href="#tab-2">
                        <p style="color: black;">{{ $t("exportData.report") }}</p>
                    </v-tab>
                    <v-tab href="#tab-3">
                        <p style="color: black;">{{ $t("exportData.graph") }}</p>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" style="min-height: 450px;">
                    <!-- Tab1 -->
                    <v-tab-item key="1" value="tab-1">
                        <v-card flat>
                            <v-card-text
                                style="border:1px solid black; margin-top:5px !important;padding-top: 0px !important;padding-bottom: 0px !important;">
                                <!-- Type  -->
                                <div class="m-1">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-4 input-group input-group-sm">
                                            <span class="input-group-addon mb-1 label-black">{{
                                                    $t("exportData.sortByDate")
                                            }}</span>
                                        </div>
                                        <div class="input-group-append justify-content-center">
                                            <v-radio-group v-model="orderBy" row>
                                                <div style="min-width: 300px" class="col-xl-4 col-lg-4 col-4">
                                                    <v-radio style="margin-right:100px !important;" key="1"
                                                        label="Ascending" value="asc">
                                                        <template v-slot:label>
                                                            <span class="radioLabel label-black">{{ $t("exportData.asc")
                                                            }}</span>
                                                        </template>
                                                    </v-radio>
                                                </div>
                                                <div style="min-width: 300px" class="col-xl-4 col-lg-4 col-4">
                                                    <v-radio key="2" label="Descending" value="desc">
                                                        <template v-slot:label>
                                                            <span class="radioLabel label-black">{{
                                                                    $t("exportData.desc")
                                                            }}</span>
                                                        </template>
                                                    </v-radio>
                                                </div>
                                            </v-radio-group>
                                        </div>

                                    </div>
                                    <div v-if="this.isTableChartTs() || isTableTs || isDeviceTsOrSimilarTs(deviceCode)" class="row">
                                        <div class="col-xl-4 col-lg-4 col-4 input-group input-group-sm">
                                            <span class="input-group-addon mb-1 label-black">{{
                                                    $t("exportData.csvDisplayOption")
                                            }}</span>
                                        </div>
                                        <v-radio-group v-model="csvDisplayOption" row>
                                            <div style="min-width: 300px" class="col-xl-4 col-lg-4 col-4">
                                                <v-radio key="1" label="vertical" value="vertical">
                                                    <template v-slot:label>
                                                        <span class="radioLabel">{{ $t("exportData.vertical") }}</span>
                                                    </template>
                                                </v-radio>
                                            </div>
                                            <div style="min-width: 300px" class="col-xl-4 col-lg-4 col-4">
                                                <v-radio key="2" label="horizonral" value="horizonral">
                                                    <template v-slot:label>
                                                        <span class="radioLabel">{{ $t("exportData.horizonral")
                                                        }}</span>
                                                    </template>
                                                </v-radio>
                                            </div>
                                        </v-radio-group>
                                    </div>
                                </div>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- Tab2 -->
                    <v-tab-item key="2" value="tab-2">
                        <v-card flat>
                            <v-card-text
                                style="border:1px solid black; margin-top:5px !important;padding-top: 0px !important;padding-bottom: 0px !important;">
                                <div class="row m-1">
                                    <div style="padding-top: 0px !important;padding-bottom: 0px !important;"
                                        class="col-xl-12 col-lg-12 col-12">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 label-black" style="min-width: 9em;">{{
                                                    $t("exportData.sortByDate")
                                            }}</span>
                                        </div>
                                        <div class="input-group-append justify-content-center">
                                            <v-radio-group v-model="orderBy" row>
                                                <v-radio style="margin-right:100px !important;" key="1"
                                                    label="Ascending" value="asc">
                                                    <template v-slot:label>
                                                        <span class="radioLabel label-black">{{ $t("exportData.asc")
                                                        }}</span>
                                                    </template>
                                                </v-radio>
                                                <v-radio key="2" label="Descending" value="desc">
                                                    <template v-slot:label>
                                                        <span class="radioLabel label-black">{{ $t("exportData.desc")
                                                        }}</span>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>
                                        </div>

                                    </div>
                                </div>

                            </v-card-text>
                            <v-card-text style="border:1px solid black; margin-top:5px !important;">
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.reportTitle")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field class="inputContent-report" v-model="reportTitle" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.siteId")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field class="inputContent-report" v-model="SiteIdInput" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.siteName")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field class="inputContent-report" v-model="SiteNameInput" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.adminSiteName")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field class="inputContent-report" v-model="projectAdministrator" hide-details="auto" outlined
                                                dense></v-text-field>
                                        </div>
                                    </div>
                                </div>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Tab3 -->
                    <v-tab-item key="3" value="tab-3">
                        <v-card flat>
                            <v-card-text style="border:1px solid black; margin-top:5px !important;">
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.siteId")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field v-model="SiteIdInput" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.siteName")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field v-model="SiteNameInput" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3 splitNumber">
                                        <div class="input-group input-group-sm splitNumber">
                                            <span class="input-group-addon mb-1 labelInput label-black splitNumber">{{
                                                    $t("exportData.splitNumber")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-text-field class="splitNumber-graph" type="number"
                                                onKeyPress="if(this.value.length==5) return false;"
                                                oninput="if(this.value < 1 || this.value.length === 0 ) this.value = 1;if(this.value > 99 ) this.value = 99;"
                                                v-model="splitNumber" hide-details="auto" outlined dense>
                                            </v-text-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-12 splitNumberMoreDetail">
                                        <span class="input-group-addon mb-1 labelInput label-black splitNumberMoreDetail">{{
                                                        $t("exportData.splitNumberMoreDetail")
                                        }}</span>
                                    </div>
                                </div>
                                <div v-if="this.isTableChartTs() || isTableTs || isDeviceTsOrSimilarTs(deviceCode)" class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.min")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-6">
                                        <div class="input-group input-group-sm">
                                            <v-select v-model="displayScaleMin" value="displayScaleMin" type="number"
                                                suffix="mm" :items="displayScaleDropdown" dense outlined>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.isTableChartTs() || isTableTs || isDeviceTsOrSimilarTs(deviceCode)" class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.max")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-6">
                                        <div class="input-group input-group-sm">
                                            <v-select v-model="displayScaleMax" value="displayScaleMax" type="number"
                                                suffix="mm" :items="displayScaleDropdown" dense outlined>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="this.isTableChartTs() || isTableTs || isDeviceTsOrSimilarTs(deviceCode)" class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.auxiliaryLine")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-6">
                                        <div class="input-group input-group-sm">
                                            <v-select v-model="auxiliaryLine" suffix="mm" :items="auxiliaryLineDropdown"
                                                dense outlined>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row m-1">
                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.thresholdLegend")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-checkbox v-model="thresholdLegendCheck">
                                                <template v-slot:label>
                                                    <span class="radioLabel label-black">{{
                                                            $t("exportData.thresholdLegendMsg")
                                                    }}</span>
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-addon mb-1 labelInput label-black">{{
                                                    $t("exportData.ngOption")
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-9">
                                        <div class="input-group input-group-sm">
                                            <v-checkbox v-model="ngCheck">
                                                <template v-slot:label>
                                                    <span class="radioLabel label-black">{{
                                                            $t("exportData.ngText")
                                                    }}</span>
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
                <!-- <div style="width:2100px; height: 400px;" ref="tagMain" class="card-body chart_hidden">
                    <v-chart ref="chart" :option="{}" class="chart" autoresize />
                </div> -->

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!checkDetail" class="text-white mr-1" @click="onExportToFile()"
                    style="background-color: #3d76ad" text>{{
                            $t("exportData.export")
                    }}
                </v-btn>
                <v-btn class="bg-secondary text-white" text @click="closeDialog">
                    {{ $t("labels.systemUserManager.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog content-class="mt-5 v-dialog--custom exportGraph-preview" v-model="showDialogPreview" :max-width="2100" :width="2100"
            persistent>
            <v-card>
                <v-card-title class="bg-gray-light">
                    <span class="text-h5 label-black">{{ $t("exportData.graphPreview") }}</span>
                    <v-btn @click="showDialogPreview = false" class="ml-auto" icon>
                        <v-icon id="close-button">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div>
                    <div style="color:black;font-weight: bold; font-size: 16px" ref="titlePdf"
                        class="sheet padding-10mm">
                        <div>{{ titlePdfPreview }}</div>
                        <div>{{ labelTimePreview }}</div>
                    </div>
                    <div class="exportGraph-previewChart">
                        <div style="width:1800px; height: 400px;" ref="tagMain" class="card-body">
                            <v-chart ref="chartPreview" :option="{}" :initOptions="initOpts" @finished="onFinishedChart"
                                class="chartPreview" :autoresize="true"/>
                        </div>
                    </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { UniversalTransition } from "echarts/features";
import { EventBus } from '@/utils/eventBus.js'
import { mapActions } from 'vuex';
import {
    getCookieSiteUser,
    setCurrentPageCode
} from "@/utils/auth";
import axios from "axios";
import jsPDF from 'jspdf';
import { getDeviceBySite } from '@/api/device';
import { ResponseCode } from "@/constants/constants"
import $ from 'jquery';
import { langEN } from "@/languages/echarts/langEN.js";
import { langJA } from "@/languages/echarts/langJA.js";
// import { amiriFont } from "@/assets/fonts/amiriFont.js";
import html2canvas from "html2canvas";

use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
]);

export default {
    name: "ExportModal",
    props: [
        'visible',
        'titleModal',
        'deviceCode',
        'startDate',
        'endDate',
        'displayInterval',
        'stationSelectedLst',
        'displayIntervalList',
        'TsConfigCheck',
        'MValue',
        'ThresholdValue',
        'valueStationMainTS',
        'tsStationSearch',
        'orgNoSearch',
        'FrameType',
        'FrameId',
        'headers',
        'frameName',
        'deviceTSList',
        'isTableTs',
        'deviceListByFrame'
    ],
    components: {
        VChart
    },
    data() {
        $('#main-header').css("pointer-events", "");
        return {
            loading: false,
            orderBy: "asc",
            csvDisplayOption: "vertical",
            detailOption: "1",
            tab: 1,
            title: "",
            showDialog: false,
            checkDetail: false,
            editMode: false,
            showPassword: false,
            showIconEye: false,
            deviceSelected: '',
            reportTitle: null,
            projectAdministrator: null,
            SiteIdInput: null,
            SiteNameInput: null,
            thresholdLegendCheck: false,
            ngCheck: false,
            PAGE_CODE: 'CS_DS',
            dataChartFake: [],
            showDialogPreview: false,
            initOpts: {
                locale: 'JA'
            },
            isFinishOneChart: false,
            splitNumber: 5,
            titlePdfPreview: null,
            labelTimePreview: null,
            displayScaleMin: -10,
            displayScaleMax: 10,
            displayScaleDropdown:
                [
                    { text: '1000', value: 1000 },
                    { text: '900', value: 900 },
                    { text: '800', value: 800 },
                    { text: '700', value: 700 },
                    { text: '600', value: 600 },
                    { text: '500', value: 500 },
                    { text: '400', value: 400 },
                    { text: '300', value: 300 },
                    { text: '200', value: 200 },
                    { text: '150', value: 150 },
                    { text: '100', value: 100 },
                    { text: '50', value: 50 },
                    { text: '45', value: 45 },
                    { text: '40', value: 40 },
                    { text: '35', value: 35 },
                    { text: '30', value: 30 },
                    { text: '25', value: 25 },
                    { text: '20', value: 20 },
                    { text: '15', value: 15 },
                    { text: '10', value: 10 },
                    { text: '5', value: 5 },
                    { text: '4', value: 4 },
                    { text: '3', value: 3 },
                    { text: '2', value: 2 },
                    { text: '1', value: 1 },
                    { text: '0', value: 0 },
                    { text: '-1', value: -1 },
                    { text: '-2', value: -2 },
                    { text: '-3', value: -3 },
                    { text: '-4', value: -4 },
                    { text: '-5', value: -5 },
                    { text: '-10', value: -10 },
                    { text: '-15', value: -15 },
                    { text: '-20', value: -20 },
                    { text: '-25', value: -25 },
                    { text: '-30', value: -30 },
                    { text: '-35', value: -35 },
                    { text: '-40', value: -40 },
                    { text: '-45', value: -45 },
                    { text: '-50', value: -50 },
                    { text: '-100', value: -100 },
                    { text: '-150', value: -150 },
                    { text: '-200', value: -200 },
                    { text: '-300', value: -300 },
                    { text: '-400', value: -400 },
                    { text: '-500', value: -500 },
                    { text: '-600', value: -600 },
                    { text: '-700', value: -700 },
                    { text: '-800', value: -800 },
                    { text: '-900', value: -900 },
                    { text: '-1000', value: -1000 }],
            auxiliaryLine: 5,
            auxiliaryLineDropdown:
                [{ text: '±1', value: 1 },
                { text: '±2', value: 2 },
                { text: '±5', value: 5 },
                { text: '±10', value: 10 },
                { text: '±20', value: 20 },
                { text: '±50', value: 50 },
                { text: '±100', value: 100 }]
        }
    },
    created() {
        setCurrentPageCode(this.PAGE_CODE);
        EventBus.$on('handleOpenExport', (value) => {
            this.detailOption = "1";
            this.orderBy = "asc";
            this.csvDisplayOption = "vertical";
            this.reportTitle = null;
            let siteID = getCookieSiteUser();
            this.title = value.title;
            this.showDialog = value.flag;
            if (value.flag) {
                $('#main-header').css("pointer-events", "none");
            }
            else {
                $('#main-header').css("pointer-events", "");
            }
            this.checkDetail = value.checkDetail;
            this.editMode = value.editMode;
            this.deviceSelected = value.deviceSelected;
            this.SiteIdInput = siteID;
            this.SiteNameInput = value.allSiteData.find((ele) => ele.value == siteID).text;
            this.thresholdLegendCheck = false;
            this.ngCheck = false;
            this.splitNumber = 5;
            this.projectAdministrator = value.selectedUser.userName;
            this.tab = "tab-1";
        });
    },
    methods: {
        ...mapActions("report", {
            exportDataToFile: "exportDataToFile",
            exportDataToFilePDF: "exportDataToFilePDF"
        }),
        async onExportToFile() {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            if (this.deviceCode === null && this.FrameId === null) {
                return;
            }
            let exportType = "csv";
            if (this.tab === "tab-1") {
                exportType = "csv";
            }
            else if (this.tab === "tab-2") {
                exportType = "excel";
            }
            else if (this.tab === "tab-3") {
                exportType = "graph";
                if (this.isTableChartTs() || this.isTableTs || this.isDeviceTsOrSimilarTs(this.deviceCode)) {
                    if (this.displayScaleMin.value == undefined) {
                        this.displayScaleMin = parseInt(this.displayScaleMin);
                    }
                    else {
                        this.displayScaleMin = parseInt(this.displayScaleMin.value);
                    }
                    if (this.displayScaleMax.value == undefined) {
                        this.displayScaleMax = parseInt(this.displayScaleMax);
                    }
                    else {
                        this.displayScaleMax = parseInt(this.displayScaleMax.value);
                    }
                    if (this.displayScaleMin > this.displayScaleMax) {
                        this.commonNotifyVue(this.$t("exportData.warningMinMax"), 'warning');
                        return;
                    }
                    // if ((this.displayScaleMin + this.displayScaleMax) % 2 != 0) {
                    //     this.commonNotifyVue("Min + Max not even", 'warning');
                    //     return;
                    // }
                }
            }
            var displayInterval = this.displayIntervalList.find(element => element.value === this.displayInterval);
            let request = {
                "ExportType": exportType,
                "SiteId": siteID,
                "orderType": this.orderBy,
                "deviceCode": this.deviceCode,
                "locale": this.$i18n.locale,
                "reportTitle": this.reportTitle,
                "projectAdministrator": this.projectAdministrator,
                "startDate": this.startDate,
                "endDate": this.endDate,
                'interval': this.displayInterval,
                "SiteIdInput": this.SiteIdInput,
                "SiteNameInput": this.SiteNameInput,
                "stationSelectedLst": this.stationSelectedLst,
                "displayIntervalName": this.$i18n.locale === 'en' ? displayInterval.labelEN : displayInterval.labelJP,
                "tsConfigCheck": this.TsConfigCheck,
                "MValue": this.MValue,
                "ThresholdValue": this.ThresholdValue,
                "thresholdLegendCheck": this.thresholdLegendCheck === true ? 1 : 2,
                "splitNumber": this.splitNumber,
                "valueStationMainTS": this.valueStationMainTS,
                "tsStationSearch": this.tsStationSearch,
                "orgNoSearch": this.orgNoSearch,
                "csvDisplayOption": exportType == "csv" ? this.csvDisplayOption : "vertical",
                "FrameType": this.FrameType,
                "FrameId": this.FrameId,
                "SettingHeader": this.headers,
                "IsQuickExport": false
            }
            this.loading = true;
            // get device by site
            await getDeviceBySite(request).then(response => {
                if (response.data && response.data.code === ResponseCode.SUCCESS) {
                    this.deviceList = response?.data?.data;
                    for (let i = 0; i < this.deviceList.length; i++) {
                        if (this.deviceList[i].value == this.deviceCode) {
                            this.deviceName = this.$i18n.locale === 'en' ? this.deviceList[i].labelEN : this.deviceList[i].labelJP;
                        }
                    }
                } else {
                    this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                }
            }).catch(err => {
                if (err.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            })
            if (exportType == "excel") {
                this.loading = true;
                this.downloadFileExcel(request);
            }
            else if (exportType == "csv") {
                this.exportDataToFile(request)
                    .then(response => {
                        if (exportType == "csv") {
                            this.downloadFileCsv(response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                    })
                    .finally(final => {
                        this.loading = false;
                    });
            }
            else if (exportType == "graph") {
                this.exportDataToFilePDF(request)
                    .then(response => {
                        if (response.code === ResponseCode.SUCCESS) {
                            this.downloadFilePdf(request, response);
                        }
                        else {
                            this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                            this.loading = false;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                    })
                    .finally(final => {
                        this.loading = false;
                    });
            }
        },
        closeDialog() {
            this.showDialog = false;
            $('#main-header').css("pointer-events", "");
        },
        isDeviceTsOrSimilarTs(deviceCode){
            return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(Number(deviceCode));
        },
        isTableChartTs() {
            const deviceLst = this.deviceListByFrame.map(x => Number(x.value));
            return deviceLst.every(r => this.deviceTSList.includes(r));
        },
        pad2(n) { return n < 10 ? '0' + n : n },
        downloadFileCsv(response) {
            const anchor = document.createElement('a');
            let date = new Date();
            let fileName = (this.FrameType === '0' ? this.deviceName : this.frameName) + "_" + date.getFullYear().toString() + this.pad2(date.getMonth() + 1) + this.pad2(date.getDate()) + this.pad2(date.getHours()) + this.pad2(date.getMinutes()) + this.pad2(date.getSeconds()) + ".csv"
            anchor.href = URL.createObjectURL(new Blob([response], { type: 'data:text/csv;charset=utf-8' }))
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
            this.loading = false;
        },
        downloadFileExcel(request) {
            this.loading = true;
            // const token = getToken();
            // const CS_DS = "CS_DS";
            this.exportDataToFile(request).then((response) => {
                const url = URL.createObjectURL(new Blob([response], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                }))
                const link = document.createElement('a')
                link.href = url
                let date = new Date();
                let fileName = (this.FrameType === '0' ? this.deviceName : this.frameName) + "_" + date.getFullYear().toString() + this.pad2(date.getMonth() + 1) + this.pad2(date.getDate()) + this.pad2(date.getHours()) + this.pad2(date.getMinutes()) + this.pad2(date.getSeconds()) + ".xlsx"
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                console.log('[exportDataToFile]', error);
            }).finally(() => {
                this.loading = false;
            });
        },
        minChartTs(value) {
            return (this.isTableChartTs() || this.isTableTs || this.isDeviceTsOrSimilarTs(this.deviceCode)) ? ((this.displayScaleMin == 0 && this.displayScaleMax == 0) ? value.min : this.displayScaleMin) : undefined;
        },
        maxChartTs(value) {
            return (this.isTableChartTs() || this.isTableTs || this.isDeviceTsOrSimilarTs(this.deviceCode)) ? ((this.displayScaleMin == 0 && this.displayScaleMax == 0) ? value.max : this.displayScaleMax) : undefined;
        },
        async downloadFilePdf(request, response) {
            this.loading = true;
            var { titlePdf, labelTime } = response;
            var { splitNumber } = request;
            var dataChart = response.data;
            this.titlePdfPreview = titlePdf;
            this.labelTimePreview = labelTime;
            this.showDialogPreview = true;
            this.initOpts.locale = this.$i18n.locale === 'en' ? langEN : langJA;
            await this.sleep(1 * 500);
            let chart = this.$refs.chartPreview;
            let titlePdfE = this.$refs.titlePdf;
            try {
                const doc = new jsPDF({
                    unit: "px",
                    orientation: "landscape",
                    hotfixes: ["px_scaling"]
                });
                const pageMargin = 10;
                const scale = 1/*window.devicePixelRatio;*/
                const marginBottom = 25;
                doc.setFontSize(12);

                const canvas = await html2canvas(titlePdfE, { scale: 1 });
                const dataUrl = canvas.toDataURL();
                let imgTitle = this.loadImage(dataUrl);
                doc.addImage(dataUrl, "JPG", pageMargin, 25, imgTitle.width / (scale * 1.5), imgTitle.height / (scale * 1.75));
                // if (this.FrameType === '0') {
                if (request.deviceCode !== "200" && request.deviceCode !== "300") {
                    var startY = 75;
                    var groupByStationOther = this.groupBy(dataChart, 'siteStationId');
                    for (const station in groupByStationOther) {

                        var dataChartOther = groupByStationOther[station]
                        var legendListOther = [];
                        var seriesListOther = [];
                        for (let index = 0; index < dataChartOther.length; index++) {
                            if (dataChartOther[index].data === undefined || dataChartOther[index].data === null || dataChartOther[index].data.length === 0) {
                                continue;
                            }
                            let dataGraphOther = [];
                            let dataGraphNotNullOther = [];
                            var chartNameOther = this.makeLegendName(dataChartOther[index]);
                            for (const [key, value] of Object.entries(dataChartOther[index].data)) {
                                const dateNumber = this.convertStringToDateNumber(key);
                                dataGraphOther.push([dateNumber, value]);
                                if(value !== null){
                                    dataGraphNotNullOther.push(value);
                                }
                            }
                            var isNoDataOther = (dataGraphNotNullOther.length === 0);
                            var seriesObjOther = {
                                data: dataGraphOther,
                                type: "line",
                                name: chartNameOther,
                                showSymbol: false,
                                color: this.isDevice200or300(dataChartOther[index].deviceCode) ? this.getColor200ByDataCode(dataChartOther[index].dataCode) : '#0000ff',
                                connectNulls: !this.ngCheck
                            }
                            legendListOther.push(chartNameOther);
                            seriesListOther.push(seriesObjOther);
                            if (!this.isDevice200or300(dataChartOther[index].deviceCode) || (this.isDevice200or300(dataChartOther[index].deviceCode) && dataChartOther[index].isBreakPage)) {
                                var dataChartOtherW = [];
                                if (!this.isDevice200or300(dataChartOther[index].deviceCode)){
                                    dataChartOtherW.push(dataChartOther[index]);
                                } else {
                                    dataChartOtherW = dataChartOther;
                                }
                                for (let j = 0; j < dataChartOtherW.length; j++) {
                                    const warningLst = dataChartOtherW[j].warningData;
                                    warningLst.forEach(element => {
                                        var elementType = this.isDevice200or300(dataChartOther[index].deviceCode) ? element.type.split("_")[0] : element.type;
                                        legendListOther.push(elementType);
                                        var warning = {
                                            data: Object.keys(dataChartOtherW[j].data).map((key) => { return [this.convertStringToDateNumber(key), element.value] }),
                                            type: "line",
                                            name: elementType,
                                            showSymbol: false,
                                            symbolSize: 0,
                                            color: element.color,
                                        }
                                        seriesListOther.push(warning);
                                    });
                                }
                                await chart.setOption({
                                    animation: false,
                                    // tooltip: {
                                    //     trigger: 'axis',
                                    //     position: function (pt) {
                                    //         return [pt[0], '10%'];
                                    //     }
                                    // },
                                    textStyle: {
                                        color: "black",
                                        fontSize: 16
                                    },
                                    backgroundColor: "#fff",
                                    legend: { data: legendListOther },
                                    xAxis: {
                                        type: 'time',
                                        boundaryGap: false,
                                        splitLine: {
                                            interval: 1,
                                            show: true,
                                            lineStyle: {
                                                type: "dotted",
                                                color: "black"
                                            }
                                        },
                                        axisTick: { show: false },
                                        axisLabel: {
                                            fontSize: 16,
                                            formatter: {
                                                year: '{yearStyle|{yyyy}/{MM}}',
                                                month: '{MMM}',
                                                day: '{MM}/{dd}',
                                                hour: '{HH}:{mm}',
                                                minute: '{HH}:{mm}',
                                                second: '{HH}:{mm}:{ss}',
                                                millisecond: '{hh}:{mm}:{ss} {SSS}',
                                                none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                                            },
                                            rich: {
                                                yearStyle: {
                                                    // Make yearly text more standing out
                                                    color: '#000',
                                                    fontWeight: 'bold'
                                                }
                                            }
                                        },
                                        axisLine: {
                                            lineStyle: {
                                                color: 'black',
                                                width: 2,
                                            },
                                            show: false
                                        },
                                        splitNumber: splitNumber
                                    },
                                    yAxis: {
                                        name: dataChartOther[index].unit,
                                        type: 'value',
                                        boundaryGap: [0, '100%'],
                                        nameTextStyle: {
                                            color: "black",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        },
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                type: "solid",
                                                color: ['#000000']
                                            }
                                        },
                                        axisLabel: {
                                            fontSize: 16,
                                            fontWeight: "bold"
                                        },
                                        interval: dataChartOther[index].isDeviceTs ? this.auxiliaryLine : undefined,
                                        min: isNoDataOther ? 0 : this.minChartTs(),
                                        max: dataChartOther[index].deviceCode == 100 && dataChartOther[index].dataMapping == "data5" ? 400 : (isNoDataOther ? 100 : this.maxChartTs())
                                    },
                                    series: seriesListOther
                                });
                                await this.sleep(1 * 300);
                                let img = null;
                                if (this.isFinishOneChart) {
                                    img = await this.getChartImage(chart);
                                }
                                var w = 1760 / (scale * 1.5);
                                var h = this.isDevice200or300(dataChartOther[index].deviceCode) ? (360 / (scale * 1.25)) : (360 / (scale * 1.75));
                                doc.addImage(img.src, "JPG", 0, startY, w, h);
                                console.log("width ---- ", img.width);
                                console.log("height-----", img.height);
                                legendListOther = [];
                                seriesListOther = [];
                                chart.clear();
                                startY = startY + (360 / (scale * 1.75)) + marginBottom;
                                if ((startY > 605 || dataChartOther[index].isBreakPage) && !dataChartOther[index].isLastLine) {
                                    startY = 25;
                                    doc.addPage();
                                }
                            }

                        }

                    }
                    this.showDialogPreview = false;
                }
                else {
                    var startY200 = 125;
                    var groupByStation = this.groupBy(dataChart, 'siteStationId');
                    for (const station in groupByStation) {
                        var legendList = [];
                        var seriesList = [];
                        var dataChart200300 = groupByStation[station]
                        for (let index = 0; index < dataChart200300.length; index++) {
                            if (dataChart200300[index].data === undefined || dataChart200300[index].data === null || dataChart200300[index].data.length === 0) {
                                continue;
                            }
                            var chartName200 = this.makeLegendName(dataChart200300[index]);
                            let dataGraphNotNull200 = [];
                            let dataGraph200 = [];
                            for (const [key, value] of Object.entries(dataChart200300[index].data)) {
                                const dateNumber = this.convertStringToDateNumber(key);
                                dataGraph200.push([dateNumber, value]);
                                if(value !== null){
                                    dataGraphNotNull200.push(value);
                                }
                            }
                            var isNoData200 = (dataGraphNotNull200.length === 0);
                            var seriesObj = {
                                data: dataGraph200,
                                type: "line",
                                name: chartName200,
                                showSymbol: false,
                                color: this.getColor200ByDataCode(dataChart200300[index].dataCode),
                                connectNulls: !this.ngCheck
                            }
                            legendList.push(chartName200);
                            seriesList.push(seriesObj);
                        }
                        for (let index = 0; index < dataChart200300.length; index++) {
                            const warningLst = dataChart200300[index].warningData;
                            warningLst.forEach(element => {
                                legendList.push(element.type);
                                var warning = {
                                    data: Object.keys(dataChart200300[index].data).map((key) => { return [this.convertStringToDateNumber(key), element.value] }),
                                    type: "line",
                                    name: element.type,
                                    showSymbol: false,
                                    symbolSize: 0,
                                    color: element.color,
                                }
                                seriesList.push(warning);
                            });

                        }
                        await chart.setOption({
                            animation: false,
                            // tooltip: {
                            //     trigger: 'axis',
                            //     position: function (pt) {
                            //         return [pt[0], '10%'];
                            //     }
                            // },
                            textStyle: {
                                color: "black",
                                fontSize: 16
                            },
                            backgroundColor: "#fff",
                            legend: {
                                data: legendList,
                            },
                            xAxis: {
                                type: 'time',
                                boundaryGap: false,
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: "dotted",
                                        color: "black"
                                    }
                                },
                                axisLabel: {
                                    formatter: {
                                        year: '{yearStyle|{yyyy}/{MM}}',
                                        month: '{MMM}',
                                        day: '{MM}/{dd}',
                                        hour: '{HH}:{mm}',
                                        minute: '{HH}:{mm}',
                                        second: '{HH}:{mm}:{ss}',
                                        millisecond: '{hh}:{mm}:{ss} {SSS}',
                                        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                                    },
                                    rich: {
                                        yearStyle: {
                                            // Make yearly text more standing out
                                            color: '#000',
                                            fontWeight: 'bold'
                                        }
                                    }
                                },
                                splitNumber: splitNumber
                            },
                            yAxis: {
                                name: dataChart200300[0].unit,
                                type: 'value',
                                boundaryGap: [0, '100%'],
                                nameTextStyle: {
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: "dotted",
                                        color: "black"
                                    }
                                },
                                axisLabel: {
                                    fontSize: 16,
                                    // color: "red",
                                    fontWeight: "bold",
                                },
                                min: isNoData200 ? 0 : undefined,
                                max: isNoData200 ? 100 : undefined
                            },
                            series: seriesList
                        });
                        if (seriesList !== undefined && seriesList !== null && seriesList.length > 0) {
                            await this.sleep(1 * 300);
                            let img = null;
                            if (this.isFinishOneChart) {
                                img = await this.getChartImage(chart);
                            }
                            // doc.text(550, 100, dataChart200300[0].siteStationId);
                            doc.addImage(img.src, "JPG", 0, startY200, 1760 / (scale * 1.5), 360 / (scale * 1.25));
                            chart.clear();
                            startY200 = startY200 + (360 / (scale * 1.75)) + marginBottom;
                            doc.addPage();
                            if (startY200 > 655) {
                                startY200 = 25;
                                doc.addPage();
                            }
                        }
                        if (doc.internal.getNumberOfPages() === 0)
                            doc.addPage();
                    }
                    //delete last page
                    var pageCount = doc.internal.getNumberOfPages();
                    doc.deletePage(pageCount)
                    this.showDialogPreview = false;
                }
                var fileNamePdf = (this.FrameType === '0' ? this.deviceName : this.frameName) + "_" + this.formatDate(new Date());

                await doc.save(fileNamePdf, {
                    returnPromise: true
                });
                this.loading = false;
                this.showDialogPreview = false;
            } catch (e) {
                console.error("failed to export", e);
                this.loading = false;
            }
        },
        formatDate(date) {
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
                date.getFullYear(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            ].join('');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        convertStringToDateNumber(dateStr) {
            var d1 = dateStr.split(" ");
            var date = d1[0].split("/");
            var time = d1[1].split(":");
            var dd = parseInt(date[2]);
            var mm = parseInt(date[1] - 1);
            var yyyy = parseInt(date[0]);
            var hh = parseInt(time[0]);
            var min = parseInt(time[1]);
            var ss = parseInt(time[2]);
            var fromdt = +new Date(yyyy, mm, dd, hh, min, ss);
            return fromdt;
        },
        isDevice200or300(deviceCode) {
            return deviceCode == 200 || deviceCode == 300;
        },
        getColor200ByDataCode(dataCode) {
            switch (dataCode) {
                case "vibration_value_max":
                    return "#ff9900";
                case "vibration_value_10":
                    return "#867456";
                case "vibration_value_50":
                    return "#ff00ff";
                case "vibration_value_90":
                    return "#0000ff";
                case "noise_value_max":
                    return "#ff9900";
                case "noise_value_5":
                    return "#867456";
                case "noise_value_50":
                    return "#ff00ff";
                case "noise_value_95":
                    return "#0000ff";
                default:
                    return "red";
            }

        },
        makeLegendName(dataChart) {
            var siteStationIdOther = dataChart.siteStationName;
            var dataNameOther = this.$i18n.locale === 'en' ? dataChart.nameEn : dataChart.nameJp;
            var chartNameOther = null;
            if (dataChart.channelNo != null && dataChart.channelName != null) {
                chartNameOther = dataChart.channelName + "_" + siteStationIdOther;
            } else if (dataChart.isDeviceTs) {
                chartNameOther = dataNameOther + "(" + `${dataChart.subStation}-${dataChart.subNumber}` + ")";
            } else {
                chartNameOther = siteStationIdOther + "_" + dataNameOther;
            }
            return chartNameOther;
        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        },
        getChartImage(chart) {
            return this.loadImage(chart.getDataURL());
        },
        onFinishedChart() {
            this.isFinishOneChart = true;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }
    }
}
</script>
<style scoped lang="scss">
@font-face {
    font-family: "Amiri";
    src: local("Amiri"),
        url('http://fonts.cdnfonts.com/css/amiri') format("truetype");
}

.labelInput {
    min-width: 9em;
    padding: 10px !important;
}

.cell_min-width {
    min-width: 200px;
}

.v-card__actions {
    border-top: 1px solid #ebebeb;
}

.v-input--selection-controls {
    margin-top: 0px !important;
}

.radioLabel {
    margin-top: 8px !important;
    font-weight: 100;
    color: black;
}

.chart_hidden {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
}

.label-black {
    color: black !important;
}

.v-dialog--custom {
    max-width: 1000px;
    width: 800px;
}

@media (max-width: 820px) {
    .v-dialog--custom {
        overflow-y: unset !important;
    }
}

@media (max-width: 1180px) {
    .v-dialog--custom {
        padding-top: 60px;
        max-width: 800px;
        width: 800px;
    }

    .v-dialog--custom {
        overflow-x: unset !important;
    }
}

::v-deep{
    .v-dialog--custom {
        max-width: 1000px;
        width: 800px;
    }
    .splitNumber {
        padding-bottom: 0 !important;
    }
    .splitNumberMoreDetail {
        padding-top: 0 !important;
        padding-left: 6rem !important;
    }
    @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait){
        .v-dialog {
            overflow-y: auto !important;
        }
        .v-slide-group__wrapper{
            position: initial;
            margin-left: -1rem !important;
        }
        .labelInput{
            margin-left: -2.5rem !important;
        }
        .inputContent-report {
            margin-bottom: 0.1rem !important;
        }
        .exportGraph-previewChart {
            overflow: auto;
            width: 100%;
        }
        .v-card {
            margin-top: 5.5rem !important;
        }
        .v-input__control {
            padding-left: 1rem !important;
            min-width: 9rem !important;
        }
    }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  .v-card__title {
    margin-top: 1.25rem !important;
  }
}
</style>
