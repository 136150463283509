import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { getCookieSiteUser, getToken, setSafieToken } from "@/utils/auth";

export default {
  install(Vue) {
    const DASHBOARD_HUB = 'dashboard-hub'
    let dashboardHub = new Vue();
    const DEFAULT_TIMEOUT_IN_MS = 2 * 3600 * 1000;
    Vue.prototype.$dashboardHub = dashboardHub

    dashboardHub.init = () => {
      let connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_URL_HUB}${DASHBOARD_HUB}`, {
          accessTokenFactory() {
            return getToken()
          },
          // skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          logMessageContent: true
        })
        .withAutomaticReconnect([1000])
        .build();
      connection.serverTimeoutInMilliseconds = DEFAULT_TIMEOUT_IN_MS;
      return connection;
    }

    dashboardHub.stop = (connection) => {
      if (connection != undefined)
        connection.stop();
    }

    dashboardHub.MonitorTempRealtime = (connection, frameId, updateFrame) => {
      // if (!startedPromise) return
      const siteId = getCookieSiteUser();
      connection.start().then(() => {
        connection.stream("MonitorTempRealtimeStreamAsync", { siteId: siteId, frameId: frameId})
          .subscribe({
            next: (item) => {
              updateFrame(item);
            },
            complete: () => {
            },
            error: (err) => {
              console.log('err');
              console.log(err);
            },
          });
      });
    }

    dashboardHub.MonitorTempRealtime = (connection, frameId, updateFrame, locale) => {
      // if (!startedPromise) return
      const siteId = getCookieSiteUser();
      connection.start().then(() => {
        connection.stream("MonitorTempRealtimeStreamAsync", { siteId: siteId, frameId: frameId, locale: locale })
          .subscribe({
            next: (item) => {
              updateFrame(item);
            },
            complete: () => {
            },
            error: (err) => {
              console.log('err');
              console.log(err);
            },
          });
      });
    }

    dashboardHub.MonitorTempRealtimeTable = (connection, frameId, updateFrame) => {
      const siteId = getCookieSiteUser();
      connection.start().then(() => {
        connection.stream("MonitorTempRealtimeTableStreamAsync", { siteId: siteId, frameId: frameId})
          .subscribe({
            next: (item) => {
              updateFrame(item);
            },
            complete: () => {
            },
            error: (err) => {
              console.log('err');
              console.log(err);
            },
          });
      });
    }

    dashboardHub.NotifySafieLogin = (connection) => {
      connection.start().then(() => {
        connection.on("SuccesLoginSafie", (token) => {
          setSafieToken(token);
          dashboardHub.$emit(`SafieLogin`);
        });
      });
    }
  }
}


