export const CONSTANTS = {
  SUCCESS: 0,
  TOKEN_INCORRECT: 333,
  TOKEN_EXPIRED: 555,
  TOKEN_NOT_EXIST: 777,
  UNAUTHORIZED: 6699,
  E_CM_01: "E_CM_01",
  E_CM_02: "E_CM_02",
  E_CM_04: "E_CM_04",
  E_CM_06: "E_CM_06",
};

export function SAFIE_URI(clientId, URI) {
  return `https://openapi.safie.link/v1/auth/authorize?client_id=${clientId}&response_type=code&scope=safie-api&redirect_uri=${URI}`

}

export default SAFIE_URI();
