<template>
  <v-dialog v-model="showDialog" max-width="1000px">
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ $t("labels.help") }}</span>
        <v-btn @click="showDialog = false" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-2 pb-0">
          <div class="label font-weight-bold pt-2 text-black">{{ $t('help.labels.manual') }}:</div>
          <div class="label-item pl-4 pt-2 text-black"><a href="https://keinet-cloud.com/help/%E8%A8%88%E6%B8%AC%E3%83%8D%E3%83%83%E3%83%88%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E6%A7%98_K-CLOUD_%E5%8F%96%E6%89%B1%E8%AA%AC%E6%98%8E%E6%9B%B8+_%E7%8F%BE%E5%A0%B4%E7%AE%A1%E7%90%86%E8%80%85.pdf" download="計測ネットサービス様_K-CLOUD_取扱説明書 _現場管理者.pdf">{{ $t('help.labels.download') }}</a>
          </div>
          <div class="label font-weight-bold pt-2 text-black">{{ $t('help.labels.supportedBrowser') }}:</div>
          <div class="label-item pl-4 pt-2 text-black"> Google Chrome</div>
          <div class="label-item pl-4 pt-2 text-black"> Safari</div>
          <div class="label font-weight-bold pt-2 text-black">{{ $t('help.labels.supportedDevice') }}:</div>
          <div class="pt-2 table-help-container">
            <table class="table table-help">
              <thead>
              <tr>
                <th scope="col" style="vertical-align: middle;line-height: normal;">{{ $t('help.labels.measurment') }}
                </th>
                <th scope="col" style="vertical-align: middle;line-height: normal;">{{ $t('help.labels.name') }}</th>
                <th scope="col" style="vertical-align: middle;line-height: normal;">{{ $t('help.labels.brand') }}</th>
                <th scope="col" style="vertical-align: middle;line-height: normal;">{{ $t('help.labels.model') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('help.weather') }}</td>
                <td>Vantage Pro2</td>
                <td>DAVIS</td>
                <td>Vantage Pro2</td>
              </tr>
              <tr>
                <td>{{ $t('help.vibration') }}</td>
                <td rowspan="2">サーカス<br/>（CIRCUS）</td>
                <td rowspan="2">計測ネットサービス<br/>（KEISOKU NET SERVICE Co., Ltd.）</td>
                <td>VM-53A</td>
              </tr>
              <tr>
                <td>{{ $t('help.noise') }}</td>
                <td>NL-42EX</td>
              </tr>
              <tr>
                <td rowspan="2">{{ $t('help.webcam') }}</td>
                <td>HCM series</td>
                <td>Panasonic</td>
                <td>BB-SC382</td>
              </tr>
              <tr>
                <td>Safie</td>
                <td>Safie</td>
                <td>Safie提供機種</td>
              </tr>
              <tr>
                <td rowspan="2">{{ $t('help.ts') }}</td>
                <td>TCA-2003</td>
                <td rowspan="2">TSLeica</td>
                <td>TCA-2003</td>
              </tr>
              <tr>
                <td>TS-15</td>
                <td>TS-15</td>
              </tr>
              <tr>
                <td rowspan="3">{{ $t('help.temperature') }}</td>
                <td>データロガー<br/>（data logger）</td>
                <td>東京測器研究所<br/>（Tokyo Sokki Kenkyujo Co.,Ltd.）</td>
                <td>TC-32K</td>
              </tr>
              <tr>
                <td>スイッチボックス<br/>（Switch box）</td>
                <td></td>
                <td>CSW-5B-05</td>
              </tr>
              <tr>
                <td>熱電対ケーブル<br/>（Thermocouple cable）</td>
                <td>二宮電線工業<br/>（Ninomiya Electric Wire Co., Ltd.）</td>
                <td>0.32 x1P（T-G）</td>
              </tr>
              <tr>
                <td>{{ $t('help.water') }}</td>
                <td>WQC-24</td>
                <td>東亜ディーケーケー<br/>（TOA DKK Co.,Ltd.）</td>
                <td>WQC-24</td>
              </tr>
              <tr>
                <td>{{ $t('help.wbgt') }}</td>
                <td>{{ $t('help.sk') }}</td>
                <td>鶴賀電機株式会社<br/>（TSURUGA ELECTRIC CORPORATION）</td>
                <td>401D</td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click="showDialog = false">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/utils/eventBus";

export default {
  name: "HelpModal",
  created() {
    EventBus.$on("handleOpenHelpModal", (value) => {
      this.showDialog = value.flag;
    });
  },
  data() {
    return {
      showDialog: false,
      headers: [
        {
          text: '計器',
          align: 'start',
          sortable: false,
          value: 'instrument',
        },
        {text: '名称', value: 'name'},
        {text: 'メーカー', value: 'manufacture'},
        {text: '型式', value: 'model'},
      ],
      desserts: [
        {
          instrument: '気象',
          name: 'Vantage Pro2',
          manufacture: 'DAVIS',
          model: 'Vantage Pro2',
        },
        {
          instrument: '振動',
          name: 'サーカス',
          manufacture: '計測ネットサービス',
          model: 'VM-53A',
        },
        {
          instrument: '騒音',
          name: '（CIRCUS）',
          manufacture: '（KEISOKU NET SERVICE Co., Ltd.）',
          model: 'NL-42EX',
        },
        {
          instrument: 'WEBカメラ',
          name: ['HCM series', 'Safie'],
          manufacture: ['Panasonic', 'Safie'],
          model: ['BB-SC382', 'Safie提供機種'],
        },
        {
          instrument: 'Frozen Yogurt',
          name: 159,
          manufacture: 6.0,
          model: 24,
        },
        {
          instrument: 'Frozen Yogurt',
          name: 159,
          manufacture: 6.0,
          model: 24,
        },
        {
          instrument: 'Frozen Yogurt',
          name: 159,
          manufacture: 6.0,
          model: 24,
        },

      ],
    }
  },
}
</script>

<style scoped lang="scss">
.text-black {
  color: black;
}

.table-help {
  color: black !important;
  border-bottom: 1px solid #dee2e6 ;
}

.label {
  font-size: 1.15rem
}

.label-item {
  font-size: 1.15rem
}

tr > td {
  vertical-align: middle;
}

@media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait){
  .table-help-container {
    max-width: 400px !important;
    overflow-x: auto !important;
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}

</style>
