import request from "../utils/request";

export function GetNotifications(payload) {
    return request({
        url: "/Bell/getListBellBySite",
        method: "post",
        data: payload,
    });
}

