<template>
  <div>
    <DashboardContent />
  </div>
</template>

<script>
import DashboardContent from "../modules/dashboard/DashboardContent.vue";

export default {
  name: "DashboardPage",
  components: {
    DashboardContent,
  },
};
</script>
