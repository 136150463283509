<template>
  <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ $t("labels.layoutSetting.selectComponent") }}</span>
        <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 200px;" class="mt-2">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container class="pt-0 pb-0">
            <div class="row">
              <div class="col-12">
                <h6 class="mb-1">{{ $t("labels.layoutSetting.frameType") }}</h6>
                <v-select :items="typeFrameList" v-on:change="handleGetFramesByType"
                          key="code"
                          :item-text="($i18n.locale === 'en') ? 'nameEn': 'nameJp'"
                          :rules="selectedTypeFrameRule"
                          v-model="selectedTypeFrame" outlined dense return-object>
                </v-select>
                <h6 class="mb-1">{{ $t("labels.layoutSetting.frame") }}</h6>
                <v-select key="id"
                          :item-text="'name'"
                          :item-value="'id'"
                          v-model="selectedFrame" :items="frameList"
                          :rules="selectedFrameRule"
                          :disabled="selectedTypeFrame === null" outlined dense>
                </v-select>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-white mr-1" style="background-color: #3d76ad" text
               @click.prevent="handleChooseFrame">
          {{ $t("labels.layoutSetting.save") }}
        </v-btn>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.layoutSetting.cancel") }}
        </v-btn>
      </v-card-actions>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import i18nConfig from "@/i18n.lang";
import {cookiesGetI18Lang, cookiesSetI18Lang, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import {EventBus} from "@/utils/eventBus";
import $ from 'jquery';

export default {
  name: 'InitLayoutModal',
  props: {
    id: Number,
    height: Number,
    layout: Array
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    EventBus.$on('handleOpenAddFrame', (value) => {
      if(value !== undefined){
        this.showDialog = true;
        this.handleGetListTypeFrame();
        this.$refs.form?.resetValidation();
      }
    })
    this.currentSite = getCookieSiteUser();
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/layout/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/layout/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {
  },
  data() {
    return {
      valid: false,
      loading: false,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_LA',
      frameList: [],
      typeFrameList: [],
      usedFrameList: null,
      defaultSize: '',
      selectedTypeFrame: null,
      currentSite: null,
      selectedFrame: null,
      chartData: {
        series: null,
        legend: null
      },
      showDialog: false,
      selectedTypeFrameRule: [
        (value) => !!value || this.ERROR_CODE['E_062_04']
      ],
      selectedFrameRule: [
        (value) => !!value || this.ERROR_CODE['E_062_05']
      ]
    }
  },
  methods: {
    ...mapMutations('layout', {
      handleReloadChooseFrame: 'handleReloadChooseFrame',
      handleDoneInitFrame: 'handleDoneInitFrame',
    }),
    ...mapActions("frame", {
      getTypeFrame: "getTypeFrame",
      getFrameByType: "getFrameByType",
    }),
    handleChooseFrame() {
      if(!this.$refs.form.validate()){
        return;
      }
      if (this.selectedTypeFrame !== null && this.selectedFrame !== null) {
        this.handleReloadChooseFrame(true);
        this.$emit('handleChangeTypeComponent', {
          frameId: this.selectedFrame
        });
        this.handleResetDataInfo();
        this.showDialog = false;
      }
    },
    handleConfirmCloseModal() {
      this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
        this.showDialog = false
        $('#main-header').css("pointer-events", "");
        this.handleResetDataInfo();
      });
    },
    handleResetDataInfo(){
      this.frameList = [];
      this.typeFrameList = [];
      this.selectedTypeFrame = null;
      this.selectedFrame = null;
    },
    handleGetListTypeFrame() {
      this.loading = true;
      const siteId = getCookieSiteUser();

      this.getTypeFrame({siteId: siteId, flag: 1}).then(response => {
        if (response.code === 0) {
          this.typeFrameList = response.data;
          this.loading = false;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
        this.loading = false;
      });
    },
    handleGetFramesByType() {
      this.loading = true;
      this.getFrameByType({code: this.selectedTypeFrame.code, siteId: this.currentSite}).then(response => {
        if (response.code === 0) {
          this.frameList = response.data;
          this.layout.filter(e => {
            let frameIndex = this.frameList.findIndex(x => x.id === e.frameId);
            if (frameIndex !== -1) this.frameList.splice(frameIndex, 1);
          })
          this.loading = false;
        }
      }).catch(error => {
        this.commonWarningVue(this.ERROR_CODE[error.code], 'warning');
        this.loading = false;
      });
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/layout/${currentLang}.js` )
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/layout/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      this.handleGetListTypeFrame();
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
}
</script>
<style scoped>
.card-header {
  background-color: #3c8dbc;
  color: white;
}

.card-header-label {
  color: white !important;
}
</style>
