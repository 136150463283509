<template>
  <v-dialog v-model="showDialog" width="700px" persistent scrollable>
    <v-card style="overflow-y:hidden !important;overflow-x:hidden !important;">
      <v-card-title class="bg-gray-light d-flex">
        <span class="text-h5">{{ $t('fileManager.uploadFile') }}</span>
        <v-btn @click="closeDialog" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-1">
        <v-container class="pt-0 pb-0">
          <v-progress-linear
            :active="show"
            v-model="progress"
            indeterminate
            rounded
            color="primary" height="10">
          </v-progress-linear>
          <!-- <div class="row">
              <div class="col-xl-12 col-sm-12 pb-0"> -->
          <v-row style="height: 200px;" class="d-flex flex-column" dense align="center" justify="center">
            <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              mdi-cloud-upload
            </v-icon>
            <p style="text-align:center !important;">
              {{ $t('fileManager.msgDropFile') }}
            </p>
            <div class="row justify-content-center">
              <v-file-input
                ref="fileupload"
                accept=".svg"
                no-tittle
                hidden
                show-size
                counter
                outlined dense v-model="uploadedFiles"
                clearable
                v-on:change="handleCheckFileSize($event)"
                :label="$t('fileManager.upload')"
              ></v-file-input>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="width: 100px;background-color: rgb(61, 118, 173);" @click.prevent="handleUploadFile">
          <v-icon id="upload-button" style="color: #fff;">mdi-upload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/utils/eventBus";
import {mapActions} from "vuex";
import {getCookieSiteUser} from "@/utils/auth";

export default {
  name: "UploadSVGModal",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    visible: {},
    titleModal: {}
  },
  async beforeMount() {
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/files/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/files/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
  },
  data() {
    return {
      ERROR_CODE: null,
      dragover: false,
      uploadedFiles: null,
      showDialog: false,
      progress: 0,
      show: false,
    }
  },
  created() {
    EventBus.$on('EmitOpenUploadSVGModal', value => {
      this.showDialog = value;
    });
  },
  methods: {
    ...mapActions('file', {
      uploadFile: 'uploadFile'
    }),
    closeDialog() {
      this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
        this.showDialog = false;
        this.uploadedFiles = null;
      });
    },
    handleUploadFile: async function () {
      if (this.uploadedFiles.length === 0) {
        this.commonErrorVue(this.ERROR_CODE['E_070_05']);
        return;
      }

      this.show = true

      const siteId = getCookieSiteUser();
      let formData = new FormData();
      await formData.append(`files`, this.uploadedFiles);

      this.uploadFile({data: formData, siteId: siteId}, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }).then((response) => {
        if (response.code === 0) {
          this.commonConfirmOKVue(this.ERROR_CODE['IMPORT_SUCCESFULLY']);
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      }).catch((error) => {
        console.log('[handleUploadFile]', error);
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        this.uploadedFiles = null;
        this.show = false;
        this.showDialog = false;
      })
    },
    handleCheckFileSize($event) {
      if ($event !== undefined && $event !== null) {
        if ($event.name.toLowerCase().split('.').pop() !== 'svg') {
          this.commonErrorVue(this.ERROR_CODE['INCORRECT_FORMAT'], 'warning');
          this.uploadedFiles = null;
          this.$refs.fileupload.reset()
          return;
        }
        if (!$event.size) return;
        this.handleReadFileContent($event);

        if ($event.size > 100 * 1024 * 1024) {
          this.commonErrorVue(this.ERROR_CODE['E_070_03']);
          this.uploadedFiles = null;
          this.$refs.fileupload.reset()
        }
      }
    },
    handleReadFileContent(file) {
      let reader = new FileReader();
      reader.onload = event => {
        this.$emit('EmitUploadSVG', {flg: true, fileName: this.uploadedFiles.name, content: event.target.result});

      };
      reader.readAsText(file);
    }
  },
  watch: {
    '$i18n.locale': async function () {
      const currentLang = this.$i18n.locale
      await import(`@/utils/errors/files/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/files/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.v-dialog--active {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-dialog__content--active {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.checkboxLabel {
  margin-top: 8px !important;
  font-weight: 100;
  color: black;
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
