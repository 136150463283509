import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueNotify from 'vue-notifyjs';
import i18n from './i18n';
import store from './store';
import './mixinFunc';
import './utils/constant';
import './utils/permission';
import DisableAutocomplete from 'vue-disable-autocomplete';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faFileCsv, faFileExcel,faCircleXmark ,faCircleCheck,faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import './assets/scss/index.scss';
import './assets/css/video-js.css'
import router from './router/router';
import 'admin-lte';
import vuetify from "@/plugins/vuetify";
import VModal from 'vue-js-modal'
import sidebarLinks from "@/sidebarLinks";
import menu from "@/components/menu/index";
import LoadingMainPanel from "@/components/LoadingMainPanel";
import DashboardHub from "@/hubs/dashboard-hub";
import VueGridLayout from 'vue-grid-layout'
import VChart from "vue-echarts";
import LS from "@/components/Charts/LS";
import GLS from "@/components/Charts/GLS";
import GC from "@/components/Charts/GC";
import LR from "@/components/Charts/LR";
import SC from "@/components/Charts/SC";
import SVGChart from "@/components/Charts/SVG";
import TC from "@/components/Charts/TC";
import SCS from "@/components/Charts/SCS"

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, EffectScatterChart } from "echarts/charts";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  GeoComponent
} from "echarts/components";
import { UniversalTransition } from "echarts/features";

use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  GeoComponent,
  LegendComponent,
  LineChart,
  BarChart,
  EffectScatterChart,
  CanvasRenderer,
  UniversalTransition,
]);

Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: {
    clickToClose: true,
    injectModalsContainer: true,
  }
});

library.add(fas, faFacebook, faGooglePlus,faFileCsv, faFileExcel,faCircleXmark,faCircleCheck,faPaperPlane);
// Vue.use(DisableAutocomplete);
Vue.use(DashboardHub);
Vue.component('LS', LS);
Vue.component('GLS', GLS);
Vue.component('GC', GC);
Vue.component('LR', LR);
Vue.component('SC', SC);
Vue.component('SVGChart', SVGChart);
Vue.component('TC', TC);
Vue.component("SCS", SCS);
Vue.component('grid-layout', VueGridLayout.GridLayout)
Vue.component('grid-item', VueGridLayout.GridItem)
Vue.component('v-chart', VChart);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueRouter);
Vue.use(VueNotify);
Vue.use(menu, {sidebarLinks: sidebarLinks});
Vue.component('v-loading', LoadingMainPanel);

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
  i18n,
}).$mount('#app')
