import request from "../utils/request";

export function getFileList(payload, siteId) {
  return request({
    url: `/File/getFileList/${siteId}`,
    method: "post",
    data: payload
  });
}

export function uploadFile(bodyData, siteId) {
  return request({
    url: `/File/uploadFile/${siteId}`,
    method: "post",
    data: bodyData,
    headers: { "Content-Type": `multipart/form-data; boundary=${Math.random().toString().substr(2)}` }
  });
}

export function removeFile(id) {
  return request({
    url: `/File/removeFile/${id}`,
    method: "get"
  });
}

export function downloadFile(data) {
  return request({
    url: `/File/downloadFile/${data.siteId}/${data.id}`,
    method: "post",
    responseType: 'arraybuffer'
  });
}

export function downloadSVGFile(data) {
  return request({
    url: `/File/downloadSVGFile/${data.siteId}/${data.frameId}`,
    method: "post",
    responseType: 'json'
  });
}

export function getBucketSize(siteId) {
  return request({
    url: `/File/getBucketSize/${siteId}`,
    method: "get"
  });
}
