<template>
  <v-dialog v-model="showDialog" max-width="1200px" persistent scrollable>
    <v-card class="add-mail-modal">
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ $t(nameLabel) }}</span>
        <v-btn @click="onClose" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <v-form ref="groupMailForm" v-model="valid" lazy-validation>
            <div class="mt-3">
              <div>
                <h6 class="mb-1 font-weight-bold">
                  {{ $t("labels.mailSetting.groupmail") }}
                  <span class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</span>
                </h6>
                <v-text-field
                  v-model="editedItem.groupmail"
                  @change="isChange = true"
                  hide-details="auto"
                  :disabled="checkDetail"
                  class="text-green"
                  outlined
                  dense
                  maxlength="100"
                  :rules="
                    requiredRule(
                      editedItem.groupmail,
                      'labels.mailSetting.groupmail'
                    )
                  "
                >
                </v-text-field>
              </div>
            </div>
            <div class="mt-3">
              <div>
                <h6 class="mb-1 font-weight-bold">
                  {{ $t("labels.mailSetting.warningLevel") }}
                  <span class="text-danger mb-0 h-text-danger" v-if="!checkDetail">*</span>
                </h6>
                <v-select
                  v-model="editedItem.warninglevel"
                  :items.sync="warningLevelChoiceComputed"
                  item-text="text"
                  item-value="value"
                  class="text-green"
                  outlined
                  dense
                  @change="isChange = true"
                  :placeholder="$t('labels.mailSetting.placeholder')"
                  :disabled="checkDetail"
                  :rules="
                    requiredRule(
                      editedItem.warninglevel,
                      'labels.mailSetting.warningLevel'
                    )
                  "
                ></v-select>
              </div>
            </div>
            <div class="mt-3">
              <v-data-table
                :headers="headers"
                :items="selectedEmails"
                sort-by="calories"
                class="elevation-2"
                mobile-breakpoint="0"
                hide-default-header
                :footer-props="{
                   'items-per-page-options': [15, 30, 50],
                   'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                }"
              >
                <template slot="no-data">
                  {{ $t("notification.noData") }}
                </template>
                <template #header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                  <tr>
                    <th
                      v-for="header in headers"
                      :key="header.value"
                      class="text-uppercase"
                      scope="col"
                    >
                      {{ $t(header.text) }}
                    </th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <h6 class="font-weight-bold">
                      {{ $t("labels.mailSetting.maillist") }}
                    </h6>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="!checkDetail"
                      depressed
                      color="primary"
                      style="background-color: #3d76ad"
                      @click="createItem"
                    >
                      <v-icon color="white" size="20" class="mr-2 dark mt-0">
                        mdi-plus-box
                      </v-icon>
                      <span style="font-size: 17px" class="font-weight-bold">{{
                        $t("labels.add")
                      }}</span>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <span v-if="!item.isEdit && !item.isNew">{{
                    item.email
                  }}</span>
                  <v-select
                    v-else
                    :items="emailListCbb"
                    item-value="email"
                    item-text="email"
                    v-model="item.email"
                    hide-details="auto"
                    outlined
                    dense
                    @change="isChange = true"
                    :rules="
                      requiredRule(item.email, 'labels.mailSetting.email')
                    "
                  ></v-select>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex justify-content-center">
                    <v-checkbox
                      @change="isChange = true"
                      :disabled="checkDetail"
                      v-model="item.status"
                    ></v-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div v-if="!item.isEdit && !item.isNew">
                    <!-- <button class="m-1" @click.prevent="editItem(item)">
                      <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                      </i>
                    </button> -->

                    <button v-if="selectedEmails.length !== 1" class="m-1" @click.prevent="deleteItem(item)">
                      <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                      </i>
                    </button>
                  </div>
                  <div v-else-if="item.isNew || item.isEdit">
                    <!-- <button class="m-1" @click="saveEmail(item)">
                    <i class="fas fa fa-check-circle text-info fa-lg pt-2 pb-2">
                    </i>
                  </button> -->
                    <button v-if="selectedEmails.length !== 1" class="m-1" @click.prevent="closeItem(item)">
                      <i
                        class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2"
                      ></i>
                    </button>
                  </div>
                </template>
              </v-data-table>
            </div>
            <div class="mt-3">
              <h6 class="mb-1 font-weight-bold">
                {{ $t("labels.mailSetting.note") }}
              </h6>
              <v-textarea
                outlined
                dense
                maxlength="500"
                v-model="editedItem.note"
                @change="isChange = true"
                :disabled="checkDetail"
              ></v-textarea>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6">
                <h6 class="mb-1">{{ $t("genba.table.createdDate") }}</h6>
                <v-text-field
                  v-model="editedItem.createdDate"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="checkDetail"
                ></v-text-field>
              </div>
              <div class="col-6">
                <h6 class="mb-1">{{ $t("genba.table.createdUser") }}</h6>
                <v-text-field
                  v-model="editedItem.createdBy"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="checkDetail"
                ></v-text-field>
              </div>
            </div>
            <div v-if="checkDetail" class="row mt-0">
              <div class="col-6 pb-0">
                <h6 class="mb-1">{{ $t("genba.table.updatedDate") }}</h6>
                <v-text-field
                  v-model="editedItem.updatedDate"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="checkDetail"
                ></v-text-field>
              </div>
              <div class="col-6 pb-0">
                <h6 class="mb-1">{{ $t("genba.table.updatedUser") }}</h6>
                <v-text-field
                  v-model="editedItem.updatedBy"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="checkDetail"
                ></v-text-field>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!checkDetail"
          class="text-white mr-1"
          style="background-color: #3d76ad"
          text
          @click="saveGroupMail"
        >
          {{ $t("labels.save") }}
        </v-btn>
        <v-btn
          v-if="!checkDetail"
          class="bg-secondary text-white"
          text
          @click="onClose"
        >
          {{ $t("commonPopup.cancel") }}
        </v-btn>
        <v-btn
          v-if="checkDetail"
          class="bg-secondary text-white"
          text
          @click="showDialog = false"
        >
          {{ $t("commonPopup.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import {getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import { getListMailBySiteId } from "@/api/mail";
import { SaveGroupMail, GetGroupMailById } from "@/api/groupMail";
import { ResponseCode } from "@/constants/constants";

export default {
  name: "MailModal",
  data() {
    return {
      isChange: false,
      isLoading: false,
      showDialog: false,
      checkDetail: false,
      showPassword: false,
      editedItem: {},
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "index",
          width: "50px",
        },
        {
          text: "labels.mailSetting.email",
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: "labels.mailSetting.chossesend",
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: "genba.table.action",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      selectedEmails: [],
      nameLabel: "",
      emailListCbb: [],
      isAddNew: true,
      valid: true,
      PAGE_CODE: 'CS_MS'
    };
  },
  computed: {
    warningLevelChoiceComputed() {
      return [
        {
          value: "primary_threshold",
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel1'),
        },
        {
          value: "secondary_threshold",
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel2'),
        },
        {
          value: "tertiary_threshold",
          text: this.$t('labels.mailSetting.warningThreshold.warningLevel3'),
        },
      ]
    }
  },
  created() {
    // this.initCbbData();
    setCurrentPageCode(this.PAGE_CODE);

    EventBus.$on("handleOpenMailEditModal", (value) => {
      this.createItem();
      if (!value.isAddNew) {
        this.getGroupMailDetail(value.groupMailInfo?.id);
      }
      this.showDialog = value.flag;
      this.checkDetail = value.checkDetail;
      this.nameLabel = value.nameLabel;
      this.isAddNew = value.isAddNew;
      this.initCbbData();
      if (this.checkDetail) {
        this.headers = [
          {
            text: "#",
            align: "center",
            sortable: false,
            value: "index",
            width: "50px",
          },
          {
            text: "labels.mailSetting.email",
            value: "email",
            align: "center",
            sortable: false,
          },
          {
            text: "labels.mailSetting.chossesend",
            value: "status",
            align: "center",
            sortable: false,
          },
        ];
      } else {
        this.headers = [
          {
            text: "#",
            align: "center",
            sortable: false,
            value: "index",
            width: "50px",
          },
          {
            text: "labels.mailSetting.email",
            value: "email",
            align: "center",
            sortable: false,
          },
          {
            text: "labels.mailSetting.chossesend",
            value: "status",
            align: "center",
            sortable: false,
          },
          {
            text: "genba.table.action",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    });
  },
  watch: {
    "$i18n.locale": async function () {
      // start import ERROR_CODES by language
      this.getErrorCode("mail");
    },
    showDialog: function () {
      this.isChange = false
      if (this.showDialog) {
        return;
      }
      this.$refs.groupMailForm?.resetValidation();
      this.editedItem = {};
      this.selectedEmails = [];
      this.valid = true;
      this.handleCommonDialog(this.showDialog);
    }
  },
  async beforeMount() {
    // import ERROR_CODES by language
    this.getErrorCode("mail");
  },
  methods: {
    createItem() {
      this.isChange = true;
      let item = {};
      if (this.selectedEmails === undefined) {
        this.selectedEmails = [];
        item.index = 1;
      } else item.index = this.selectedEmails.length + 1;
      item.email = "";
      item.status = false;
      item.isNew = true;
      item.isEdit = true;
      this.selectedEmails.push(item);
    },
    // editItem(item) {
    //   let itemSelected = this.selectedEmails.findIndex(
    //     (x) => x.index == item.index
    //   );
    //   this.selectedEmails[itemSelected].isEdit =
    //     !this.selectedEmails[itemSelected].isEdit;
    // },
    deleteItem(item) {
      let itemSelected = this.selectedEmails.findIndex(
        (x) => x.index == item.index
      );
      this.selectedEmails.splice(itemSelected, 1);
      let index = 1;
      this.selectedEmails.forEach(element => {
        element.index = index++;
      });
    },
    // saveEmail(item) {
    //   let itemSelected = this.selectedEmails.findIndex(
    //     (x) => x.index == item.index
    //   );
    //   this.selectedEmails[itemSelected].isEdit =
    //     !this.selectedEmails[itemSelected].isEdit;
    //   if (item.isNew) {
    //     this.selectedEmails[itemSelected].isNew =
    //       !this.selectedEmails[itemSelected].isNew;
    //   }
    // },
    closeItem(item) {
      let itemSelected = this.selectedEmails.findIndex(
        (x) => x.index == item.index
      );
      this.selectedEmails[itemSelected].isEdit = false;
      if (item.isNew) {
        this.selectedEmails.splice(itemSelected, 1);
      }
      let index = 1;
      this.selectedEmails.forEach(element => {
        element.index = index++;
      });
    },
    initCbbData() {
      getListMailBySiteId(getCookieSiteUser())
        .then((response) => {
          if (response.data && response.data.code === ResponseCode.SUCCESS) {
            this.emailListCbb = response?.data?.mails;
          } else {
            this.commonNotifyVue(
              this.ERROR_CODES[response.data.code],
              "warning"
            );
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        });
    },
    saveGroupMail() {
      if (!this.$refs.groupMailForm.validate()) {
        return;
      }
      const emails = [];
      this.isLoading = true;
      this.selectedEmails.forEach((element) => {
        emails.push({
          Email: element.email,
          Status: element.status ? 1 : 2,
        });
      });
      const request = {
        Id: this.editedItem?.id,
        SiteId: getCookieSiteUser(),
        GroupMail: this.editedItem?.groupmail,
        WarningLevel: this.editedItem?.warninglevel,
        MailList: emails,
        Note: this.editedItem?.note,
      };
      SaveGroupMail(request, this.isAddNew)
        .then((response) => {
          if (
            response.data &&
            response.data.code === ResponseCode.SAVE_SUCCESS
          ) {
            this.commonNotifyVue(
              this.ERROR_CODES[response.data.code],
              "success"
            );
            this.$emit("reload-data", response);
          } else {
            this.commonNotifyVue(
              this.ERROR_CODES[response.data.code],
              "warning"
            );
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.isLoading = false;
          this.showDialog = false;
        });
    },
    getGroupMailDetail(id) {
      this.isLoading = true;
      const request = {
        Id: id,
        SiteId: getCookieSiteUser(),
      };
      GetGroupMailById(request)
        .then((response) => {
          const res = response.data;
          if (res && res.code === ResponseCode.SUCCESS) {
            const group = res?.data?.group;
            const groupMails = res?.data?.groupMails;
            this.editedItem = {
              id: group?.id,
              groupmail: group?.name,
              warninglevel: group?.warningLevel,
              note: group?.note,
              createdBy: group?.createdBy,
              createdDate: this.commonFormatStrToDate(group?.createdDate, 6),
              updatedBy: group?.updatedBy,
              updatedDate: this.commonFormatStrToDate(group?.updatedDate, 6),
            };
            this.selectedEmails = [];
            groupMails.forEach((element, idx) => {
              this.selectedEmails.push({
                index: ++idx,
                email: element?.email,
                status: element?.status === 1,
                isNew: false,
                isEdit: false,
              });
            });
            if (this.selectedEmails.length === 0 && !this.checkDetail) {
              this.selectedEmails.push({
                index : 1,
                email : "",
                status : false,
                isNew : true,
                isEdit : true,
              })
            }
          } else {
            this.commonNotifyVue(this.ERROR_CODES[res.code], "warning");
          }
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODES[err.code], "warning");
        })
        .finally(() => {
          this.isLoading = false;
          this.isChange = false;
        });
    },
    onClose() {
      if (this.isChange) {
        this.commonConfirmVue(
          this.$t("commonPopup.titleConfirmDiscard"),
          () => {
            this.showDialog = false;
          },
          () => {
            return;
          }
        );
      } else {
        this.showDialog = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1180px) {
  /* For ipad 768px: */
  .text-green input {
    color: rgba(0, 0, 0, 1) !important;
  }
}
@media (max-width: 820px) {
  .text-green input {
    color: rgba(0, 0, 0, 1) !important;
  }
}

@media only screen and (max-width: 500px) and (max-height: 900px) {
  .add-mail-modal {
    margin-top: 5.5rem !important;
    box-shadow: none !important;
  }
}
::v-deep{
  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 1.25rem !important;
  }
  .v-card__text {
    padding: 0 0 0 0 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
    width: 1rem !important
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2),
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(3),
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(4)   {
    width: 3rem !important
  } 
  .v-input__control {
    width: 5rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  .v-card {
    margin-top: 1rem !important;
   } 
   @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) and (orientation: portrait) {

.v-card {
 margin-top: 6rem !important;
} 
}
}
</style>
