<template>
  <v-dialog v-model="showDialog" max-width="1200px" persistent scrollable>
    <v-card>
      <v-card-title class="bg-gray-light">
        <span class="text-h5">{{ title }}</span>
        <v-btn @click="onCloseModal" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <div class="row m-1">
            <div class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm input-iphone">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('viewDataSensor.device')
                }}</span>
              </div>
              <div class="input-group-append input-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select v-model="deviceCode" :items="deviceList" @change="onChangeDeviceOptions"
                          :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'" item-value="value" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isDeviceTsOrSimilarTs(deviceCode)" class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm input-iphone">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('viewDataSensor.station')
                }}</span>
              </div>
              <div class="input-group-append input-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select v-model="station" :items="stationList" :disabled="deviceCode === 0"
                          @change="onChangeStationOptions" item-text="label"
                          item-value="value" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm input-iphone">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t("exportData.outputInterval")
                }}</span>
              </div>
              <div class="input-group-append input-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select v-model="displayInterval" :items="displayIntervalList" :disabled="deviceCode === 0"
                          @change="handleGetReportDataByTime" :item-text="$i18n.locale === 'en' ? 'labelEN' : 'labelJP'"
                          item-value="value" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isDeviceTsOrSimilarTs(deviceCode)" class="row m-1">
            <div class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm input2-iphone">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('viewDataSensor.tsStation')
                }}</span>
              </div>
              <div class="input-group-append input-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select v-model="stationTs" :items="listStationTs" @change="onChangeTsStationOptions"
                          item-text="stationName" item-value="id" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-6">
              <div class="input-group input-group-sm input-iphone">
                <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                    $t('viewDataSensor.orgNo')
                }}</span>
              </div>
              <div class="input-group-append input-iphone">
                <div class="row">
                  <div style="padding-bottom: 0px !important;" class="col-xl-12 col-lg-12 col-12">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-select v-model="orgNo" :items="listOrg" :disabled="deviceCode === 0"
                          @change="onChangeOrgOptions" :item-text="$i18n.locale === 'en' ? 'orgNameEn' : 'orgName'"
                          item-value="orgNo" dense outlined>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-1">
          <v-layout column style="overflow: auto; height: 400px;">
          <v-flex md6 grow style="overflow: auto; height: 100%;">
            <div :class="getTableName()" >
              <v-data-table :hide-default-header="!isDeviceTsOrSimilarTs(deviceCode) ? true : false" 
              fixed-header :headers="!isDeviceTsOrSimilarTs(deviceCode) ? headers : headersTS" 
              :items="listData" sort-by="calories" :footer-props="{
                'items-per-page-options': [15, 30, 50],
                'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
              }" class="elevation-2"
              mobile-breakpoint="0"
              >
                <template v-slot:header="{ props: {} }">
                  <thead v-if="!isDeviceTsOrSimilarTs(deviceCode)">
                    <tr>
                      <th :rowspan="2">
                        {{ $t("exportData.time") }}
                      </th>
                      <th v-for="item in headersP" :colspan="item.dataList.length" :key="item.stationId">
                        {{ item.isLineStack ? ($i18n.locale === 'en' ? item.parentEn : item.parentJp) : item.stationName }}
                      </th>
                    </tr>
                    <tr>
                      <th v-for="item in headersC" :key="item.dataCode" style="width:162 !important;">
                        {{ $i18n.locale === 'en' ? item.nameEn : item.nameJp }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:no-data>
                  {{ $t("exportData.noData") }}
                </template>
              </v-data-table>
            </div>
          </v-flex>
          </v-layout>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click="onCloseModal">
          {{ $t("labels.systemUserManager.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/eventBus.js'
import { mapActions } from 'vuex';
import { getCookieSiteUser, setCurrentPageCode } from "@/utils/auth";
import { getCbbStation } from "@/api/station";
import {ResponseCode} from "@/constants/constants"
import $ from 'jquery';

export default {
  name: "ViewDataWarningModal",
  props: ['visible', 'titleModal', 'frameId'],
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
    }
  },
  data() {
    return {
      title: "",
      showDialog: false,
      headers: [],
      listData: [],
      deviceList: [],
      deviceCode: 0,
      headersP: [],
      headersC: [],
      listStationTs: [],
      listOrg: [],
      stationList: [],
      station: undefined,
      stationTs: undefined,
      orgNo: undefined,
      displayInterval: undefined,
      displayIntervalList: [
        { value: 10, labelEN: "10 minute", labelJP: "10分間" },
        { value: 60, labelEN: "1 hour", labelJP: "1時間" }
      ],
      loading: false,
      PAGE_CODE: 'CS_DA',
      headersTS: [],
      deviceTSList: []
    }
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    EventBus.$on('handleOpenViewSensor' + this.frameId, (value) => {
      this.title = value.title;
      this.showDialog = value.flag;
      this.listData = [];
      this.deviceCode = 0;
      this.headersP = [];
      this.headersC = [];
      this.handleGetCbbDevice();
    });
    // this.initialize();
    this.handleGetCbbDevice();
    this.getListDeviceMaster();
  },
  methods: {
    getTableName(){
      return{
        'table-ts col-xl-12 col-lg-12 col-12': this.isDeviceTsOrSimilarTs(this.deviceCode),
        'table-other col-xl-12 col-lg-12 col-12': !this.isDeviceTsOrSimilarTs(this.deviceCode)
      }
    },
    ...mapActions("device", {
      getDeviceByFrameId: "getDeviceByFrameId"
    }),
    ...mapActions("report", {
      getReportDataByTime: "getReportDataByTime"
    }),
    ...mapActions("station", {
      getCbbStationTSByDevice: "getCbbStationTSByDevice",
      getCbbOrgInfoBySokGr: "getCbbOrgInfoBySokGr",
      getCbbStationTSByFrameId: "getCbbStationTSByFrameId"
    }),
    ...mapActions("deviceMaster", {
      getDeviceList: "getDeviceList"
    }),
    onChangeDeviceOptions(deviceSelected) {
      if (!this.isDeviceTsOrSimilarTs(deviceSelected)) {
        // this.handleGetReportDataByTime();
        this.init();
      }
      else {
        this.init();
        let siteID = getCookieSiteUser();
        let request = {
          "siteId": siteID,
          "deviceCode": deviceSelected,
        };
        this.loading = true;
        getCbbStation(request).then(response => {
          if (response.data && response.data.code === ResponseCode.SUCCESS) {
            this.stationList = response?.data?.data;
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
            this.loading = false;
        })
        .finally(final => {
          this.loading = false;
        });
      }
    },
    onChangeStationOptions() {
      let siteID = getCookieSiteUser();
      this.listStationTs = undefined;
      this.stationTs = undefined;
      this.listOrg = undefined;
      this.orgNo = undefined;
      this.loading = true;
      this.getCbbStationTSByFrameId(this.frameId)
          .then(response => {
          if (response.code === 0) {
              let allElement = {id: 'all', stationName: this.$i18n.locale === 'en' ? 'All data' : 'すべてのデータ'};
              this.listStationTs = response.data;
              this.listStationTs.unshift(allElement);
              this.loading = false;
          } else {
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
              this.loading = false;
          }
          })
      .catch(err => {
      if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
      })
      .finally(final => {
        this.loading = false;
      });
    },
    onChangeTsStationOptions() {
      let rq = {
        SiteId: getCookieSiteUser(),
        StationId: this.station,
        DeviceCode: this.deviceCode,
        SokGrTs: this.stationTs,
        IsViewDataSensor: true,
        frameId: this.frameId
      }
      this.listOrg = undefined;
      this.orgNo = undefined;
      this.loading = true;
      this.getCbbOrgInfoBySokGr(rq)
      .then(response => {
        if (response.code === 0) {
          this.listOrg = response.data;
          this.loading = false;
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          this.loading = false;
        }
      })
      .catch(err => {
        if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          this.loading = false;
      })
      .finally(final => {
        this.loading = false;
      });
    },
    onChangeOrgOptions(){
      if(this.deviceCode != undefined && this.station != undefined && this.stationTs != undefined && this.orgNo != undefined)
        this.handleGetReportDataByTime();
    },
    init(){
      this.displayInterval = undefined
      this.listStationTs = undefined;
      this.stationTs = undefined;
      this.listOrg = undefined;
      this.station = undefined;
      this.orgNo = undefined;
      this.listData = [];
      this.headers = [];
      this.headersTS = [];
      this.headersP = [];
      this.headersC = [];
    },
    handleGetReportDataByTime() {
      this.loading = true;
      let siteID = getCookieSiteUser();
      // site id is not exist
      if (!siteID) {
        return;
      }
      this.listData = [];
      var currentDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      let request = {
        "siteId": siteID,
        "deviceCode": this.deviceCode,
        "stationSelectedLst": [],
        "frameId": this.frameId,
        "displayInterval": this.displayInterval,
        "startDate": currentDate,
        "endDate": currentDate,
        "PageNumber": 1,
        "PageSize": 50000,
        "FrameType": "0",
        "Locale": this.$i18n.locale,
        "IsViewDataSensor": true
      }
      if(this.isDeviceTsOrSimilarTs(this.deviceCode)) {
        request = {
          "siteId": siteID,
          "deviceCode": this.deviceCode,
          "stationSelectedLst": [],
          "displayInterval": 0,
          "startDate": currentDate,
          "endDate": currentDate,
          "ThresholdValue": 3,
          "MValue": 0,
          "TsConfigCheck": false,
          "PageSize": 50000,
          "PageNumber": 1,
          "valueStationMainTS": this.station,
          "tsStationSearch": this.stationTs,
          "orgNoSearch": this.orgNo,
          "FrameType": "0",
          "Locale": this.$i18n.locale,
          "frameId": this.frameId,
          "IsViewDataSensor": true
        }
      }
      this.getReportDataByTime({ PAGE_CODE: this.PAGE_CODE, request: request })
        .then(response => {
          if (response.code === 0) {
            this.headersP = response.data.headers;
            this.headersC = [];
            response.data.headers.forEach((item, index) => {
              item.dataList.forEach((itemC, indexC) => {
                this.headersC.push(itemC);
              })
            });
            if(!this.isDeviceTsOrSimilarTs(this.deviceCode)) this.generateHeader(response)
            else this.generateHeaderTS(response)
            
            this.loading = false;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
        })
        .finally(final => {
          this.isSubmitAction = false;
          this.loading = false;
        });
    },
    generateHeader(response) {
      if (response.data.headers.length > 0) {
        this.headers = [];
        this.headers.push({
          text: "Time",
          align: "center",
          sortable: false,
          value: "time",
          width: "160"
        });
        response.data.headers.forEach((item, index) => {
          item.dataList.forEach((element, indexC) => {
            const item = {
              text: element?.nameEn,
              value: element?.dataMapping,
              align: "center",
              sortable: false,
            };
            this.headers.push(item)
          })
        })
      }
      this.listData = response.data.bodys.map(x => {
        var newObj = {};
        if (x === null) {
          return { time: 'NG' }
        }
        if (x.data === null) {
          return { time: x.time }
        }
        else {
          newObj = { ...x.data, time: x.time };
          return newObj;
        }
      });
    },
    generateHeaderTS(response) {
      if (response.data.headers.length > 0) {
        this.headersTS = [
              {
                text: this.$t('exportData.time'),
                align: 'center',
                sortable: false,
                value: 'measurementTime',
                dataCode: 'measurementTime'
              },
              {
                text: this.$t('exportData.subStation'),
                value: 'stationName',
                dataCode: 'stationName',
                align: 'subStation',
                sortable: false
              }
          ];
          response.data.headers.forEach((item, index) => {
            item.dataList.forEach((element, indexC) => {
              const item = {
                text: this.currentLocale === 'jp' ? element?.nameJp : element?.nameEn,
                value: element?.dataMapping,
                align: "center",
                sortable: false,
              };
              this.headersTS.push(item)
            })
          })
      }
      this.listData = response.data.bodys.map(x => {
        var newObj = {};
        if (x === null) {
          return {measurementTime: 'NG'}
        }
        if (x.data === null) {
          return {measurementTime: x.time}
        } else {
          newObj = {...x.data, measurementTime: x.time, stationName: x.stationName};
          this.headers.forEach(header => {
            if(x.data[header.value] === undefined || x.data[header.value] === null || x.data[header.value] === ''){
                newObj[header.value] = ''
              }
          })
          newObj.measurementTime = x.time;
          newObj.stationName = x.stationName;
          return newObj;
        }
      });

    },
    handleGetCbbDevice() {

      this.getDeviceByFrameId(this.frameId)
        .then(response => {
          if (response.code === 0) {
            this.deviceList = response.data.map((item, index) => {
              return item;
            })
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if (err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    onCloseModal() {
      this.showDialog = false;
      this.deviceCode = 0;
      this.listData = [];
      $('#main-header').css("pointer-events", "");
    },
    getListDeviceMaster() {
      this.getDeviceList({})
        .then(response => {
          if (response.code === 0) {
            this.deviceTSList = response.data.deviceList.map(x => x.code);
            console.log("deviceTSList ", this.deviceTSList);
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          this.isSubmitAction = false;
        });
    },
    isDeviceTsOrSimilarTs(deviceCode){
      return this.deviceTSList !== null && this.deviceTSList.length > 0 && this.deviceTSList.includes(Number(deviceCode));
    },
  }
}
</script>

<style lang="scss" scoped>
.v-text-field__details {
  display: none !important;
}

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.checkboxLabel {
  margin-top: 8px !important;
  font-weight: 100;
  color: black;
}

::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
  .v-data-table {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-ts {
    height: 100%;
  }
  .table-other {
    height: 100%;
  }
  .v-card__text {
    padding: 0 0 0 0 !important;
  }
  .container {
    padding: 0 0 0 0 !important;
  }
  .v-data-table--fixed-header > .v-data-table__wrapper {
    height: 100% !important;
    overflow: auto !important;
  } 
  .v-data-table--fixed-header > .v-data-table__wrapper {
    .v-data-table-header{
      tr{
        th{
          border-top: 0 !important;
          top: -2px !important;
          border-left: 0 !important;
        }
      }
    }
    .v-data-table-header{
      tr{
        td{
          border-left: 0 !important;
          top: -2px !important;
          left: -2px !important;
        }
      }
    }
  }
  .table-other {
    height: 100%;
  }
  @media only screen and (min-width: 1500px){
    .table-ts{
    table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      border-left: 0px !important;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 76 !important; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 76 !important; 
      z-index: 5;
      background-color: white;
    }
  }
  .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
    }
      table > thead > tr:nth-child(1) > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
    }
  }
  }
  @media only screen and (min-width: 700px) and (max-width:1500px){
    .table-ts{
    table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      border-left: 0px !important;
      min-width: 120px !important;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 110px !important; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
      min-width: 120px !important;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 110px !important; 
      z-index: 5;
      background-color: white;
    }
  }
  .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      min-width: 60px !important;
    }
      table > thead > tr:nth-child(1) > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
      min-width: 60px !important;
    }
    .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr:nth-child(2) > th {
      min-width: 100px !important;
    }
  }
  }
  @media only screen and (min-width: 500px) and (max-width:700px){
  .table-ts{
    table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      border-left: 0px !important;
      min-width: 120px !important;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 110px !important; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
      min-width: 120px !important;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 110px !important; 
      z-index: 5;
      background-color: white;
    }
  }
  .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      min-width: 60px !important;
    }
      table > thead > tr:nth-child(1) > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
      min-width: 60px !important;
    }
    table > tbody > tr:nth-child(2) > th {
        top: 0px !important;
      }
  }

  }


  @media (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.75rem !important;
    height: 26px;
    padding: 0px 6px !important; 
    border-top: 0px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 2.5rem !important;
  }
  .v-card__text {
    padding: 0px 0px 0px !important;
  }
  .v-card {
    margin-top: 5rem !important;
  }
  .input-iphone {
    width: 100% !important;
  }
  .table-ts{
    table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      border-left: 0px !important;
      min-width: 60px !important;
    }
    table > tbody > tr > td:nth-child(2) {
      position: sticky !important; 
      left: 52px !important; 
      z-index: 4;
      background-color: white;
    }
    table > thead > tr > th:nth-child(1){
      position: sticky !important; 
      top: -2px;
      left: -2px; 
      z-index: 5;
      background-color: white;
      min-width: 60px !important;
    }
    table > thead > tr > th:nth-child(2) {
      position: sticky !important; 
      top: -2px;
      left: 52px !important; 
      z-index: 5;
      background-color: white;
    }
  }
  .table-other {
      table > tbody > tr > td:nth-child(1) {
      position: sticky !important; 
      left: -2px; 
      z-index: 4;
      background-color: white;
      min-width: 60px !important;
    }
      table > thead > tr:nth-child(1) > th:nth-child(1){
      position: sticky !important; 
      left: -2px; 
      top: -2px;
      z-index: 5;
      background-color: white;
      min-width: 60px !important;
    }
    .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr:nth-child(2) > th {
      top: 24px !important;
      min-width: 60px !important;
      border-top: 0 !important;
    }
    }
  .v-select__selection--comma {
    max-width: 5rem !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 0.8rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
