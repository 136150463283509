// eslint-disable-next-line no-unused-vars
import {
  getFrameByType,
  getFrameDataByType,
  getListFrame,
  getTypeFrame,
  getFrameDetail,
  deleteFrame,
  getListFrameOfLayout,
  getSVGImage, addFrame, updateFrame,
  checkWarningByDataCode
} from "@/api/frame";

const actions = {
  getListFrame({commit, state}, payload) {
    return new Promise((resolve, reject) => {
      getListFrame(payload)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTypeFrame({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getTypeFrame(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getFrameByType({commit, state}, header) {
    return new Promise((resolve, reject) => {
      getFrameByType(header)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getFrameDataByType({commit, state}, params) {
    return new Promise((resolve, reject) => {
      getFrameDataByType(params)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getFrameDetail({commit, state}, req) {
    return new Promise((resolve, reject) => {
      getFrameDetail(req.SiteId,req.FrameId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteFrame({commit, state}, req) {
    return new Promise((resolve, reject) => {
      deleteFrame(req.SiteId, req.FrameId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addFrame({commit, state}, data) {
    return new Promise((resolve, reject) => {
      addFrame(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateFrame({commit, state}, data) {
    return new Promise((resolve, reject) => {
      updateFrame(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getListFrameOfLayout({commit, state}, SiteId) {
    return new Promise((resolve, reject) => {
      getListFrameOfLayout(SiteId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getSVGImage({commit, state}, url) {
    return new Promise((resolve, reject) => {
      getSVGImage(url)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkWarningByDataCode({commit, state}, req) {
    return new Promise((resolve, reject) => {
      checkWarningByDataCode(req.SiteID,req.SiteStationId,req.DeviceCode,req.DataCode)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

