<template>
  <div class="row">
    <div class="col-sm-12 card m-0 p-0 mb-5">
      <div class="card-header pt-3 pb-3">
        <div class="row m-1">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t("labels.roleManager.roleName")
                              }}</span>
            </div>
            <div class="input-group-append roleManagement-roleName">
              <v-text-field hide-details="auto" outlined dense v-model="searchRoleInfo.Name">
              </v-text-field>
            </div>
          </div>
            <div class="col-xl-6 col-lg-6 col-12">
              <div class="input-group input-group-sm">
                            <span class="input-group-addon mb-1" style="min-width: 9em;">{{
                                $t("labels.roleManager.createdDate")
                              }}</span>
              </div>
              <div class="input-group-append input-group-append-ipad">
                <div class="w-100 d-flex">
                  <div class="" style="width: 48%;">
                    <div class="row input-date-mg-ip">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                transition="scale-transition" offset-y
                                min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fromFormatted" append-icon="mdi-calendar"
                                          v-bind="attrs" v-on="on" hide-details="auto" outlined dense
                                          @click:append="menu = true"
                                          @blur="searchRoleInfo.From = parseDate(fromFormatted)"
                                          readonly
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="searchRoleInfo.From" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary"
                                   @click="$refs.menu.save(searchRoleInfo.From)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                  <div style="width: 8%;" class="d-lg-flex justify-content-center sign-ipad sign-iphone ">
                    <b class="m-0 mt-1" style="font-size: 20px;">〜</b>
                  </div>
                  <div class="" style="width: 48%;">
                    <div class="row justify-content-end input-date-mg-ip">
                      <div class="col-xl-12 col-lg-12 col-12 pl-3 input-end-date">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                                transition="scale-transition" offset-y
                                min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="toFormatted" append-icon="mdi-calendar"
                                          v-bind="attrs" v-on="on" hide-details="auto" outlined dense
                                          @click:append="menu2 = true"
                                          @blur="searchRoleInfo.To = parseDate(toFormatted)"
                                          readonly
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="searchRoleInfo.To" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary"
                                   @click="$refs.menu2.save(searchRoleInfo.To)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mr-3">
          <v-btn class="bg-success mr-3 font-weight-bold" @click.prevent="handleGetRoleList">
            <v-icon right dark class="mr-1">
              fas fa-search
            </v-icon>
            {{ $t("labels.search") }}
          </v-btn>
          <v-btn class="bg-secondary font-weight-bold" @click.prevent="clearFilter">
            <font-awesome-icon style="margin-right: 9px; font-size: 20px;" icon="fa-solid fa-rotate-right"/>
            {{ $t("labels.clear") }}
          </v-btn>
        </div>
      </div>
      <div class="card-body p-3 m-2">
        <div class="col-sm-12 col-lg-12 table-date text-nowrap" data-app>
          <v-data-table :headers="headers" :items="rolesList" sort-by="calories" class="elevation-2" mobile-breakpoint="0"
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50],
                             'items-per-page-text': $i18n.locale === 'en' ? 'Rows per page' : '1ページあたりの行数'
                        }"
                        hide-default-header>
            <template v-slot:no-data>
              {{ $t("exportData.noData") }}
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th v-for="header in headers" :key="header.value" class="text-uppercase"
                    scope="col">
                  {{ $t(header.text) }}
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn class="add-button-container" depressed color="primary" style="background-color: #3d76ad"
                       @click="createItem()"
                       v-if="ACTION === 'OPS'">
                  <v-icon color="white" size="20" class="mr-2 dark mt-0 add-button">
                    mdi-plus-box
                  </v-icon>
                  <span class="add-button" style="font-size: 20px">{{ $t("labels.add") }}</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <button class="m-1" @click="detailItem(item)">
                  <i class="fas fa-eye text-info fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="editItem(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                  </i>
                </button>
                <button class="m-1" @click="confirmDeleteItem(item)" v-if="ACTION === 'OPS'">
                  <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                  </i>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <RoleModal @handleGetRoleList="handleGetRoleList" @close="modelEdit = false"/>
  </div>
</template>

<script>
import RoleModal from "@/modals/Roles/RoleModal"
import {EventBus} from "@/utils/eventBus";
import {mapActions} from 'vuex';
import {
  getCookiePrivileges,
  getCookieSiteUser, setCurrentPageCode
} from "@/utils/auth";
// import { getMesErrByCode } from "@/utils/errors/auth";
import moment from "moment";
import $ from 'jquery';

export default {
  name: "RoleManager",
  components: {
    RoleModal,
  },
  updated() {
    if (this.$i18n.locale !== this.currentLocale) {
      this.currentLocale = this.$i18n.locale;
      this.headers = [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50px"},
        {text: 'labels.roleManager.roleName', value: 'name', align: 'start', sortable: false},
        {text: 'labels.roleManager.description', value: 'description', align: 'start', sortable: false},
        {text: 'labels.roleManager.createdDate', value: 'createdDate', align: 'center', sortable: false},
        {text: 'labels.roleManager.actions', value: 'actions', sortable: false, align: 'center'},
      ]
    }

  },
  mounted() {
    this.handleGetRoleList();
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/roles/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/roles/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  data() {
    return {
      fromFormatted: new Date().toISOString().substr(0, 10),
      toFormatted: new Date().toISOString().substr(0, 10),
      dialogDelete: false,
      roleIdDeleteSelected: 0,
      currentLocale: this.$i18n.locale,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dialogx: false,
      selected1: null,
      selected: null,
      modelDetail: false,
      modelEdit: false,
      modelCreate: false,
      dialog: false,
      headers: [
        {text: '#', align: 'center', sortable: false, value: 'index', width: "50px"},
        {text: 'labels.roleManager.roleName', value: 'name', align: 'start', sortable: false},
        {text: 'labels.roleManager.description', value: 'description', align: 'start', sortable: false},
        {text: 'labels.roleManager.createdDate', value: 'createdDate', align: 'center', sortable: false},
        {text: 'labels.roleManager.actions', value: 'actions', sortable: false, align: 'center'},
      ],
      rolesList: [],
      editedIndex: -1,
      editedItem: {},
      searchRoleInfo: {
        Name: "",
        From: new Date().toISOString().substr(0, 10),
        To: new Date().toISOString().substr(0, 10),
        PageSize: 5,
        PageNumber: 1
      },
      ERROR_CODE: '',
      PAGE_CODE: 'CS_RM',
      ACTION: 'OPS',
    }
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/roles/${currentLang}.js`)
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/roles/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
    'searchRoleInfo.From'() {
      this.fromFormatted = this.formatDate(this.searchRoleInfo.From)
    },
    'searchRoleInfo.To'() {
      this.toFormatted = this.formatDate(this.searchRoleInfo.To)
    },
  },
  created() {
    setCurrentPageCode(this.PAGE_CODE);
    const menuAction = getCookiePrivileges();
    if (menuAction !== undefined && menuAction !== null) {
      JSON.parse(menuAction).filter(value => {
        if (value.children !== undefined && value.children.length > 0) {
          value.children.filter(child => {
            (child.code === this.PAGE_CODE) ? this.ACTION = child.action : '';
          })
        } else {
          (value.code === this.PAGE_CODE) ? this.ACTION = value.action : '';
        }
      })
    }
    this.fromFormatted = this.formatDate(this.searchRoleInfo.From)
    this.toFormatted = this.formatDate(this.searchRoleInfo.To)

    this.handleGetRoleList();
  },
  methods: {
    ...mapActions("roles", {
      getRoleDetail: "getRoleDetail",
      getRoleList: "getRoleList",
      deleteRole: "deleteRole"
    }),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    parseDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    confirmDeleteItem(item) {
      // this.dialogDelete = true;
      this.roleIdDeleteSelected = item.id;
      this.commonConfirmVue(this.$t('labels.roleManager.msgDelete'), () => {
        this.deleteRole(this.roleIdDeleteSelected)
          .then(response => {
            if (response.code === 0) {
              // this.dialogDelete = false;
              this.handleGetRoleList();
              this.commonConfirmOKVue(this.ERROR_CODE["DELETE_OK"])
            } else {
              console.log("[handleDeleteItem]", response);
              this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
            }
          })
          .catch(err => {
            console.log(err);
            if(err.code != undefined)
              this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
          })
          .finally(final => {
            
            this.isSubmitAction = false;
          });
      }, () => {
        this.roleIdDeleteSelected = 0;
      })
    },
    handleDeleteItem() {
      this.deleteRole(this.roleIdDeleteSelected)
        .then(response => {
          if (response.code === 0) {
            this.dialogDelete = false;
            this.handleGetRoleList();
            this.commonConfirmOKVue(this.ERROR_CODE[response.code])
          } else {
            console.log("[handleDeleteItem]", response);
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    clearFilter() {
      this.searchRoleInfo.Name = "";
      this.searchRoleInfo.From = "";
      this.searchRoleInfo.To = "";
      this.handleGetRoleList();
    },
    handleGetRoleList() {
      this.searchRoleInfo.SiteId = getCookieSiteUser()
      this.getRoleList(this.searchRoleInfo)
        .then(response => {
          if (response.code === 0) {
            this.rolesList = response.data.roleList;

            if (response.data.roleList !== null && response.data.roleList.length > 0) {
              for (let i = 0; i < this.rolesList.length; i++) {
                this.rolesList[i].index = i + 1;
                this.rolesList[i].createdDate = moment(this.rolesList[i].createdDate).format('YYYY/MM/DD');
                this.rolesList[i].updatedDate = moment(this.rolesList[i].updatedDate).format('YYYY/MM/DD');
              }
            }

          } else {
            console.log("[handleGetRoleList]", response);
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {
          
          this.isSubmitAction = false;
        });
    },
    // eslint-disable-next-line
    createItem() {
      this.editedItem = {}
      this.modelCreate = true
      $('#main-header').css("pointer-events", "none");
      EventBus.$emit('handleOpenRoleEditModal', {
        title: 'labels.roleManager.createPopupTitle',
        editedItem: {
          id: 0,
          name: '',
          description: '',
          SiteId: 1
        },
        flag: true,
        checkDetail: false,
        editFlag: false
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events", "none");
    },
    editItem(item) {
      this.editedIndex = this.rolesList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      EventBus.$emit('handleOpenRoleEditModal', {
        title: 'labels.roleManager.editPopupTitle',
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        editFlag: true
      });
      this.modelEdit = true;
      $('#main-header').css("pointer-events", "none");
    },
    detailItem(item) {
      this.getRoleDetail(item)
        .then(response => {
          if (response.code === 0) {
            // this.dialogDelete = false;
            this.editedIndex = this.rolesList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            EventBus.$emit('handleOpenRoleEditModal', {
              title: 'labels.roleManager.detailPopupTitle',
              editedItem: this.editedItem,
              flag: true,
              checkDetail: true,
              editFlag: false
            });
            this.modelDetail = true
            $('#main-header').css("pointer-events", "none");
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
    },
  }
}
</script>

<style lang="scss" scoped>
/*@media (max-width: 1180px) {*/
/*  .sign-ipad{*/
/*    padding-right: 0px;*/
/*  }*/
/*  .input-group-append-ipad {*/
/*    margin-left: -8px !important;*/
/*    margin-right: -20px !important;*/
/*  }*/
/*}*/
/*@media (max-width: 820px) {*/
/*  .sign-ipad{*/
/*    margin-right: 12px;*/
/*  }*/
/*  .input-group-append-ipad {*/
/*    margin-left: 10px !important;*/
/*    margin-right: -20px !important;*/
/*  }*/
/*}*/
@media (min-width: 1000px) and (max-width: 1200px) {
  /*.v-dialog {*/
  /*  overflow-x: unset !important;*/
  /*}*/
  .input-date-mg-ip {
    margin-left: 0px !important;
  }

  .input-end-date {
    padding-left: 6px !important;
  }
}
.colmd {
  flex: 0 0 auto;
  max-width: 2.333333%;
}

.col-date {
  flex: 0 0 auto;
  max-width: 46%;
}

.buttons-search {
  display: flex;
}

.btn-search {
  margin-right: 10px;
  background-color: #0d6efd !important;
  color: white !important;
}

.btn-clear {
  background-color: gray !important;
  color: white !important;
}

.btn-add {
  background-color: #0d6efd !important;
  color: white !important;
}

.my_border {
  border-left: 5px solid red;
}

.table-date td {
  border-left: 2px solid #ebebeb !important;
  border-right: 2px solid #ebebeb !important;
}


.custom-select {
  background: none;
  background-color: white;
}

.color-red {
  color: red;
}

.v-date-picker-table .v-btn.v-btn--active {
  color: #212529 !important;
}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape) {
}
</style>
<style scoped lang="scss">
.ipad-sign{
  margin-left: -15px;
}
.input-to {
  padding-left: 0px;
}
.input-group-append-ipad {
  margin-left: -2px;
}
@media (max-width: 1180px) {
  .input-group-append-ipad {
    margin-left: -15px;
  }
}

@media (max-width: 820px) {
  .input-group-append-ipad {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 500px) and (max-height: 1000px) {
  // .sign-iphone {
  //   margin-right: 4vh !important;
  // }
  .roleManagement-roleName {
    width: 92% !important;
  }
  .add-button {
    font-size: 10px !important;
  }
  .add-button-container {
    height: 1.5rem !important;
    width: 0.5rem !important;
  }
  .v-menu__content {
    top: 27.5rem !important;
    left: 3rem !important;
  }
}
::v-deep{
  @media only screen and (max-width: 500px) and (max-height: 1000px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 0.5rem !important;
    height: 26px;
    padding: 0px 6px !important; 
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.75rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-data-footer__pagination {
    margin-left: 6.55rem !important;
  }
  }

  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important; 
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
</style>
