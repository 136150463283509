<template>
  <div ref="StackedLineMain" class="card card-iphone" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;"
                  v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) : "" }}</span>
          </template>
        </v-tooltip>
      </h3>

      <div v-if="(!isFrameSetting)" class="card-tools mb-2 ml-auto">
        <ButtonView v-if="isDashboard" :frameId="frameId"/>
        <ButtonViewSensor v-if="isDashboard" :frameId="frameId"/>
        <ButtonExportPdf v-if="usePdf === 1 && isDashboard" :frameId="frameId"/>
        <ButtonExportCsv v-if="useCsv === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <ButtonExportExcel v-if="useExcel === 1 && isDashboard" :frameId="frameId" :frameName="title"/>
        <button v-if="isShowEdit"
          type="button"
          class="btn btn-sm" @click="clickClose"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <v-btn class="m-2 btn btn-lg btn-primary" @click="get1day">1{{ $t('dateLabels.day') }}</v-btn>
      <v-btn class="m-2 btn btn-lg btn-primary" @click="get1month">1{{ $t('dateLabels.month') }}</v-btn>
      <v-btn class="m-2 btn btn-lg btn-primary" @click="get1year">1{{ $t('dateLabels.year') }}</v-btn>
    </div>

    <div ref="tagMain" class="card-body">
      <v-chart ref="chart" class="chart" :option="option" :autoresize="true"
               @legendselectchanged="handleHideLegend"/>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <!-- <div class="card-footer bg-transparent">
      <div class="row"></div>
    </div> -->
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {LineChart} from "echarts/charts";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  TransformComponent
} from 'echarts/components';
import VChart, {THEME_KEY} from "vue-echarts";
import {UniversalTransition, LabelLayout} from "echarts/features";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import {mapActions} from "vuex";
import {getCookieSiteUser} from "@/utils/auth";
import {LR_MODE} from "@/constants/constants"

use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  LabelLayout,
  UniversalTransition
]);
export default {
  name: "LR",
  components: {
    VChart, ButtonView, ButtonViewSensor, ButtonExportCsv, ButtonExportExcel, ButtonExportPdf
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    width: Number,
    height: Number,
    legend: Object,
    series: Array ,
    title: String,
    xAxis: Array,
    typeDefault: Number,
    frameId: Number,
    isDashboard: Boolean,
    isFrameSetting: Boolean,
    isShowEdit: Boolean,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
  },
  data() {
    return {
      dataLegend: [],
      heightReal: 0,
      index: 0,
      loading: false,
      option: {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '50%']
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 99,
        //     end: 100,
        //     xAxisIndex: [0],
        //     throttle: 100
        //   },
        //   {
        //     type: 'inside',
        //     realtime: true,
        //     start: 99,
        //     end: 100,
        //     xAxisIndex: [0],
        //     throttle: 100
        //   }
        // ],
        series: []
      },
      data2: [],
      data: [],
      date: [],
      tempCount: 0,
    };
  },
  mounted() {
    if(this.isDashboard) {
      // this.handleRemoveDefaultWarning().then((response) => {
      //   if (response.code === 0) {
      //     this.handleGetWarningByFrameId();
      //   }
      // });
      this.option.xAxis.data = [];
      this.option.series.filter(function (item) {
        item.data = [];
      });
      this.$emit(`ChartReady`, this.frameId);
      this.get1day();
    }
  },
  beforeDestroy() {
    this.$dashboardHub.$off(`MonitorTempRealtime`)
  },
  created() {
    this.option.xAxis.data = this.xAxis;
    this.option.legend = this.legend
    this.option.series = this.series;
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  watch: {
    "series": function ($event) {
      if(!this.isDashboard){
        this.option.series = $event;
        this.$refs.chart.clear();
        // this.index++;
      }
    },
    'xAxis': function ($event) {
      if(!this.isDashboard){
        this.option.xAxis.data = $event;
      }
    },
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  },
  methods: {
    ...mapActions("dashboard", {
      getLRData: "getLRData",
    }),
    ...mapActions("warning", {
      getWarningByFrameId: "getWarningByFrameId",
    }),
    clickClose(){
      this.$emit(`closeFrame`, this.frameId);
    },
    getLegend(format) {
      let date = [];
      if (format === 1) {
        let base = +new Date(2022, 6, 16, 0, 0, 0);
        let tenMin = 600 * 1000;
        for (let i = 1; i < 144; i++) {
          let now = new Date((base += tenMin));
          let hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
          let minutes =
            now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
          let ss = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
          date.push([hour, minutes, ss].join(':'));
        }
      } else if (format === 2) {
        let now = new Date();
        let start = new Date(now.getFullYear(), now.getMonth(), 0);
        let end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        let loop = new Date(start);
        while (loop < end) {

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
          let day = String(loop.getDate()).padStart(2, '0');
          let month = String(loop.getMonth() + 1).padStart(2, '0');
          let base = +new Date(now.getFullYear(), loop.getMonth(), loop.getDate(), 0, 0, 0);
          date.push(month + "/" + day + " [" + ["00", "00", "00"].join(':') + "]");
          let tenMin = 21600 * 1000;
          for (let i = 1; i < 4; i++) {
            let now = new Date((base += tenMin));
            let hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
            let minutes =
              now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
            let ss = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
            date.push(month + "/" + day + " [" + [hour, minutes, ss].join(':') + "]");
          }

        }
      } else if (format === 3) {
        let now = new Date();
        let start = new Date(now.getFullYear(), 0, 0);
        let end = new Date(now.getFullYear(), 11, 31);
        let loop = new Date(start);
        while (loop < end) {
          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
          let day = String(loop.getDate()).padStart(2, '0');
          let month = String(loop.getMonth() + 1).padStart(2, '0');
          let year = loop.getFullYear()
          date.push(year + "/" + month + "/" + day)
        }
      }
      return date;
    },
    get1day() {
      if(this.isDashboard){
        this.handleMonitorSingleLRData(LR_MODE.ONE_DAY);
      }else{
        this.date = this.getLegend(1);
        this.option.xAxis.data = this.date;
      }
    },
    get1month() {
      if(this.isDashboard){
        this.handleMonitorSingleLRData(LR_MODE.ONE_MONTH);
      }else{
        this.date = this.getLegend(2);
        this.option.xAxis.data = this.date;
      }
    },
    get1year() {
      if(this.isDashboard){
        this.handleMonitorSingleLRData(LR_MODE.ONE_YEAR);
      }else{
        this.date = this.getLegend(3);
        this.option.xAxis.data = this.date;
      }
    },
    handleRemoveDefaultWarning() {
      return new Promise((resolve, reject) => {
        try {
          this.option.series.forEach((data) => {
            if (data.key !== undefined && data.key !== null) {
              this.option.series.splice(this.option.series.indexOf(data), 1);
            }
            let legendIndex = this.option.legend.data.findIndex(e => e === data.key);
            if (legendIndex !== -1) {
              this.option.legend.data.splice(legendIndex, 1);
            }
          });
          this.$refs.chart.clear();
          this.$refs.chart.setOption(this.option, true);
          resolve({code: 0, message: 'DONE'})
        } catch (error) {
          reject({code: 400, message: error.toString()})
          console.log('[handleRemoveDefaultWarning]', error);
        }
      });
    },
    handleMonitorLRData(response) {
      try {
        if (response !== undefined) {
          if (response.result.code === 0) {
            this.option.series.filter(data => {
              if (data.index !== undefined && data.index !== null) {
                response.result.data.filter(value => {
                  let seriesIndex = this.option.series.findIndex(item => item.index === value.index);
                  if (seriesIndex !== -1) {

                    //Add new measurement data
                    if(value.data.length > 0){
                      let newData = value.data.map(function (item) {
                        return parseInt(item, 10);
                      });
                      this.option.series[seriesIndex].data.push(...newData)
                    }
                    if(value.xAxis.length > 0) {
                      let newXAxisData = value.xAxis.map(function (item) {
                        return item;
                      });
                      this.option.xAxis.data.push(...newXAxisData);
                    }
                  }
                });
              } else {
                let warningIndex = this.option.series.findIndex(e => e.key === data.key);
                if (warningIndex !== -1) {
                  this.dataLength = this.option.xAxis.data.length;
                  this.option.series[warningIndex].data = Array(this.dataLength).fill(this.option.series[warningIndex].data[0]);
                  this.index++
                }
              }
            });
          }
        }
      } catch (error) {
        console.log('[handleMonitorGLSData][ERROR]', error);
      }
    },
    handleMonitorSingleLRData(flag) {
      this.loading = true;
      const siteId = getCookieSiteUser();
      this.option.xAxis.data = [];
      this.option.series.filter(function (item) {
        item.data = [];
      });
      this.getLRData({siteId:siteId, frameId:this.frameId, mode: flag}).then(response => {
        if (response.code === 0) {
          if(response.data[this.frameId] != undefined) {
            let xAxis = [];
            Object.keys(response.data[this.frameId][0].data).map((key, index) => {xAxis[index] = key;});
            let indexDuplicate = [];
            let self = this;
            xAxis.forEach(function (arrayItem) {
              let indexDUp = self.option.xAxis.data.indexOf(arrayItem)
              if (indexDUp != -1) {
                indexDuplicate.push(arrayItem);
              }
            });
            response.data[this.frameId].forEach(value => {
              let seriesIndex = this.option.series.findIndex(item => item.index === value.index);
              if (seriesIndex !== -1) {
                //Add new measurement data
                  const dataRealTime = [];
                  Object.keys(value.data).map((key, index) => {dataRealTime[index] =  value.data[key];});
                  indexDuplicate.forEach(function (arrayItem) {
                    let index = xAxis.indexOf(arrayItem);
                    dataRealTime.splice(index,1);
                  });
                  let oldDataLength = this.option.series[seriesIndex].data.length;
                  this.option.series[seriesIndex].data = this.option.series[seriesIndex].data.concat(dataRealTime);
                  const warningSeries = this.option.series.filter(item=>(item.key!=undefined && item.dataCode == this.option.series[seriesIndex].dataCode ));
                  warningSeries.forEach(item=>{
                    if(item.data.length>0)
                      if(item.data.length == oldDataLength){
                        item.data = item.data.concat(Array(dataRealTime.length).fill(item.valueWarning));
                      } else {
                        item.data = Array(this.option.series[seriesIndex].data.length).fill(item.valueWarning);
                      }
                  });
              }
            });
            indexDuplicate.forEach(function (arrayItem) {
              let index = xAxis.indexOf(arrayItem);
              xAxis.splice(index,1);
            });
            this.option.xAxis.data = this.option.xAxis.data.concat(xAxis);
            this.$refs.chart.setOption(this.option);
          }
        }
      })
      .catch(err => {
        if(err.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
      })
      .finally(final => {
        this.loading = false;
      });
    },
    handleGetWarningByFrameId() {
      this.option.legend.selected = {};
      this.getWarningByFrameId(this.frameId).then((response) => {
        if (response.code === 0) {

          if (response.data !== undefined && response.data !== null) {
            response.data.filter(e => {
              let index = this.option.series.findIndex(data => data.dataCode === e.dataCode);

              this.option.series.push({
                color: e.color,
                dataCode: e.dataCode,
                data: Array(this.dataLength).fill(e.value),
                key: e.siteStationId + '_' + e.deviceCode + '_' + e.dataCode + '_' + e.value,
                name: e.legendWarning,
                smooth: true,
                symbolSize: 0,
                type: "line",
                xAxisIndex: this.option.series[index].xAxisIndex,
                yAxisIndex: this.option.series[index].yAxisIndex,
              });
              let objKey = this.assignObject(e.siteStationId + '_' + e.deviceCode + '_' + e.dataCode + '_' + e.value, true);
              Object.assign(this.option.legend.selected, objKey);
              // this.option.legend.data.push(e.siteStationId + '_' + e.deviceCode + '_' + e.dataCode + '_' + e.value);
              let warningIndex = this.option.series.findIndex(e => e.index !== undefined);
              if (warningIndex !== -1) {
                this.dataLength = this.option.xAxis.data.length;
                this.option.series.forEach((value) => {
                  if (value.key !== undefined) {
                    value.data = Array(this.dataLength).fill(value.data[0]);
                  }
                })
              }
            });
          }
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        console.log('[handleGetWarningByFrameId]', error)
      })
    },
    handleHideLegend($event) {
      let seriesIndex = this.option.series.findIndex(e => e.name.toLowerCase() === $event.name.toLowerCase());
      if (seriesIndex !== -1) {
        let legendIndex = this.option.legend.data.findIndex(e => e.toLowerCase() === $event.name.toLowerCase());
        if (legendIndex !== -1) {
          const selectedDataCode = this.option.series[seriesIndex].dataCode;
          this.option.series.forEach((series) => {
            if (series.dataCode === selectedDataCode) {

              let isSelected = this.option.legend.selected[series.name];

              this.option.legend.selected[series.name] = !isSelected;
              $event.selected[series.name] = !isSelected;
            }
          })
        }
      }
    },
    assignObject(key, value) {
      let item = {};
      item[key] = value;
      return item;
    }
  }
}
</script>

<style scoped>
    .chartac {
  background-image: url("../../assets/img/chart.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-iphone {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

</style>
