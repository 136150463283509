import request from "../utils/request";

const CS_UM = "CS_UM";


export function getUserList(payload) {
    return request({
        url: "/Users/getUserList",
        method: "post",
        data: payload
    });
}
export function getKnsUserDetail(userId) {
  return request({
    url: `/Users/getKnsUserDetail/${userId}`,
    method: "get",
  });
}
export function addUser(payload) {
    return request({
        url: "/Users/addUser",
        method: "post",
        data: payload
    });
}

export function updateUser(payload) {
    return request({
        url: "/Users/updateUser",
        method: "put",
        data: payload
    });
}

export function deleteUser(payload) {
    return request({
        url: "/Users/deleteUser",
        method: "post",
        data: payload
    });
}
export function getUserManagementList(payload) {
    return request({
        url: "/Users/getUserManagementList",
        method: "post",
        data: payload.json,
        headers: payload.header
    });
}
export function getDetailUserManagement(payload) {
    return request({
        url: "/Users/getInformationUserManagement",
        method: "post",
        data: payload.json,
        headers: payload.header
    });
}
export function addUserManagement(payload) {
    return request({
        url: "/Users/addUserManagement",
        method: "post",
        data: payload.json,
        headers: payload.header
    });
}

export function updateUserManagement(payload) {
    return request({
        url: "/Users/updateUserManagement",
        method: "post",
        data: payload.json,
        headers: payload.header
    });
}

export function deleteUserManagement(payload) {
    return request({
        url: `/Users/deleteUserManagement/${payload.siteId}/${payload.index}`,
        method: "post",
        data: {},
        headers: payload.header
    });
}
export function getAdminSiteList(payload) {
    return request({
        url: "/Users/getAdminSiteList",
        method: "post",
        data: payload
    });
}
export function adminSiteDetail(payload) {
    return request({
        url: "/Users/adminSiteDetail/" + payload,
        method: "post",
        data: {}
    });
}
export function addAdminSite(payload) {
    return request({
        url: "/Users/addAdminSite",
        method: "post",
        data: payload
    });
}
export function updateAdminSite(payload) {
    return request({
        url: "/Users/updateAdminSite",
        method: "put",
        data: payload
    });
}

export function DeleteAdminSite(payload) {
    return request({
        url: "/Users/DeleteAdminSite/" + payload,
        method: "post",
        data: {},
    });
}
