<template>
  <v-app :class="'sidebar-mini ' +  globalFontTable" data-app :style="'font-size: ' + globalFontsize + '!important'">
    <notifications></notifications>
    <router-view></router-view>
  </v-app>
</template>

<script>
import {EventBus} from "@/utils/eventBus";

import('sweetalert2/dist/sweetalert2.css');
import('vue-notifyjs/themes/default.css');

export default {
  name: "App",
  created() {
    EventBus.$on('handleChangeGlobalFontSize', (value) => {
      this.globalFontsize = value.size;
       if (value.size === '0.8rem') {
        this.globalFontTable = 'small-table weather-fs-sm box-title-sm'
      } else if (value.size === '1.4rem') {
        this.globalFontTable = 'large-table weather-fs-lg box-title-lg'
      } else {
        this.globalFontTable = 'normal-table weather-fs box-title'
      }
    })
  },
  data() {
    return {
      globalFontsize: '1.2rem',
      globalFontTable: 'normal-table'
    }
  }
};
</script>
<style lang="scss">
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }

  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}

.small-table {
  table thead tr th {
    font-size: 1rem !important;

  }

  table tbody tr td {
    font-size: 1rem !important;
  }
}

.normal-table {
  table thead tr th {
    font-size: 1.15rem !important;

  }

  table tbody tr td {
    font-size: 1.15rem !important;
  }
}

.large-table {
  table thead tr th {
    font-size: 1.3rem !important;
  }

  table tbody tr td {
    font-size: 1.3rem !important;
  }
}

.weather-fs-sm .card-header .card-title {
  font-size: 1rem
}

.weather-fs-lg .card-header .card-title {
  font-size: 1.4rem
}

.weather-fs .card-header .card-title {
  font-size: 1.2rem
}

$box-title-sz: 1rem;

.box-title-fs {
  @if $box-title-sz == 0.8rem {
    .small-box .inner .box-title-sm {
      font-size: 0.8rem
    }
  } @else if box-title-sz == 1.4rem {
    .small-box .inner .box-title-lg {
      font-size: 1.2rem
    }
  } @else {
    .small-box .inner .box-title {
      font-size: 1rem
    }
  }
}

h6 {
  color: black !important;
}

body {
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.5 !important;
  font-style: adjust-color(black);
}
// .v-application--wrap {
//   max-width: none !important;
// }
</style>
