<template>
  <div class="card card-gc-height card-iphone" :style="style">
    <div
      class="card-header bg-gray-light border-0 pb-1 d-flex align-items-center"
    >
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <!-- {{ $t("labels.weather") }}  -->
        <v-tooltip top>
          <span
            class="justify-content-end align-content-end"
            style="font-size: 1.2rem"
            >{{ title }}</span
          >
          <template v-slot:activator="{ on }">
            <span
              style="font-weight: 600"
              v-if="title !== undefined && title !== null && title.length > 50"
              >...</span
            >
            <span style="font-weight: 600" v-on="on">{{
              title !== undefined && title !== null ? title.slice(0, 50) : ""
            }}</span>
          </template>
        </v-tooltip>
      </h3>
      <div v-if="!isFrameSetting" class="card-tools mb-2 ml-auto">
        <ButtonView v-if="isDashboard" :frameId="frameId" />
        <ButtonViewSensor v-if="isDashboard" :frameId="frameId" />
        <ButtonExportPdf
          v-if="usePdf === 1 && isDashboard"
          :frameId="frameId"
        />
        <ButtonExportCsv
          v-if="useCsv === 1 && isDashboard"
          :frameId="frameId"
          :frameName="title"
        />
        <ButtonExportExcel
          v-if="useExcel === 1 && isDashboard"
          :frameId="frameId"
          :frameName="title"
        />
        <button
          v-if="isShowEdit"
          type="button"
          class="btn btn-sm"
          @click="clickClose"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body-style d-flex mt-4 mb-2 card-style" style="overflow: auto;">
      <div
        v-for="item in itemsList"
        :key="item.index"
        class="pt-1 pb-0 mb-2 item-children"
      >
        <!-- EDIT HERE 24/09/2022 -->
        <div
          v-if="item.data === 'wind_direction_1' || item.dataCode === 'wind_direction_1'"
          class="small-box bg-gradient-light min-h-130 mb-0"
        >
          <wind-compass
            :windDirection="isNaN(item.dataStr) ? 0 : parseInt(item.dataStr) || windDirection"
            :indexW="indexW"
            arrowAnimationSpeed="1000"
            :speedData="parseInt(item.data) || speedData"
            class=""
            :item="item"
          ></wind-compass>
          <h5 style="text-align: right;"></h5>
        </div>

        <!-- END EDIT HERE 24/09/2022 -->
        <div v-else class="small-box bg-gradient-light min-h-130 mb-0">
          <div class="inner">
            <span
              class="box-title box-title-sm box-title-lg"
              style="color: black"
              v-if="$i18n.locale === 'en'"
              >{{ isFrameSetting ? item.legendName : item.legendName }}</span
            >
            <span
              class="box-title box-title-sm box-title-lg"
              style="color: black"
              v-else-if="$i18n.locale === 'jp'"
              >{{ isFrameSetting ? item.legendName : item.legendName }}</span>
            <h4 :class="`font-weight-bolder text-color-${item.dataColor}`">
              {{
                item.dataStr !== undefined &&
                item.dataStr !== null &&
                isDashboard === true
                  ? item.dataStr
                  : "_ _"
              }}
              {{ item.unit }}
            </h4>
            <!-- <span
              class="box-title box-title-sm box-title-lg"
              style="color: black"
              >----/--/-- --:--:--</span> -->
          </div>
          <div class="icon-GC">
            <i class="ion"
              ><img
                v-if="item.icon !== null && item.icon !== undefined"
                :src="item.icon"
                style="height: 50px; width: 50px"
                alt=""
            /></i>
          </div>

          <h5 v-if="item.isDeviceTS" style="text-align: right;">
              {{
                item.time !== undefined &&
                item.time !== null &&
                isDashboard === true
                  ? item.time
                  : "----/--/-- --:--:--"
              }}</h5>
        </div>
      </div>
    </div>
    <!-- <div class="card-footer bg-transparent" style="border: none">
      <div class="row"></div>
    </div> -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        style="color: #3d76ad !important"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import WindCompass from "../WindCompass/WindCompass";
import ButtonView from "@/components/ButtonView/ButtonView";
import ButtonViewSensor from "@/components/ButtonView/ButtonViewSensor";
import ButtonExportCsv from "@/components/ButtonView/ButtonExportCsv";
import ButtonExportExcel from "@/components/ButtonView/ButtonExportExcel";
import ButtonExportPdf from "@/components/ButtonView/ButtonExportPdf";
import { mapActions } from "vuex";
export default {
  name: "GC",
  components: {
    WindCompass,
    ButtonView,
    ButtonViewSensor,
    ButtonExportCsv,
    ButtonExportExcel,
    ButtonExportPdf,
  },
  provide: {},
  props: {
    height: Number,
    title: String,
    listTable: Array,
    frameId: Number,
    isDashboard: Boolean,
    isShowEdit: Boolean,
    isFrameSetting: Boolean,
    useCsv: Number,
    useExcel: Number,
    usePdf: Number,
  },
  computed: {
    style() {
      return "height: " + this.height + "px";
    },
    speed() {
      return this.speedData;
    },
  },
  mounted() {
    if (!this.isDashboard) {
      this.randomWind();
    }
    if (this.isDashboard) {
      this.loading = true;
      this.signalRConnection = this.$dashboardHub.init();
      this.$dashboardHub.MonitorTempRealtime(this.signalRConnection, [this.frameId], this.handleMonitorGCData);
    }
    this.$emit(`ChartReady`, this.frameId);
  },
  data() {
    return {
      signalRConnection: undefined,
      speedData: 0,
      windDirection: 0,
      selected: null,
      itemsList: [],
      loading: false,
      indexW: 0,
    };
  },
  updated(){
    console.log(this.itemsList);
  },
  beforeDestroy() {
    this.$dashboardHub.$off(`MonitorTempRealtime_${this.frameId}`);
    this.$dashboardHub.stop(this.signalRConnection);
  },
  created() {
    this.itemsList = this.listTable.map((item, index) => {
      return {
        index: item.order,
        dataName: item.dataName,
        dataCode: item.dataCode,
        nameEn: item.nameEn,
        nameJp: item.nameJp,
        unit: item.unit,
        data: item.data,
        icon: item.icon,
        legendName: item.legendName,
        dataStr: "_ _",
        time:"----/--/-- --:--:--",
        deviceCode: item.deviceCode,
        dataColor: "376CA5",
        isDeviceTS: item.isDeviceTS
      };
    });
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale;
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE;
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`).then(
          (DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE;
          }
        );
      });
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  watch: {
    listTable: function ($event) {
      this.itemsList = this.listTable.map((item, index) => {
        return {
          index: item.order,
          dataName: item.dataName,
          nameEn: item.nameEn,
          nameJp: item.nameJp,
          unit: item.unit,
          data: item.data,
          dataCode: item.dataCode,
          icon: item.icon,
          legendName: item.legendName,
          dataStr: "_ _",
          time: "----/--/-- --:--:--",
          deviceCode: item.deviceCode,
          dataColor: "376CA5",
          isDeviceTS: item.isDeviceTS
        };
      });
    },
    "$i18n.locale": async function (currentLang) {
      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE;
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`).then(
            (DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE;
            }
          );
        });
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  },
  methods: {
    ...mapActions("dashboard", {
      getRealtimeSingleFrameGCData: "getRealtimeSingleFrameGCData",
    }),
    clickClose() {
      this.$emit(`closeFrame`, this.frameId);
    },
    randomWind() {
      const vm = this;
      setInterval(function () {
        vm.speedData = Math.round(Math.random() * 100);
        vm.windDirection = Math.floor(
          Math.random() * (348.75 - 11.25 + 1) + 11.25
        );
        vm.indexW = Math.floor(
          ((Math.random() * (360 + 1) + 11.25) % 360) / 22.5
        );
      }, 3000);
    },
    handleMonitorGCData(response) {
      if (response !== undefined) {
        if (response.code === 0) {
          if (response.data[this.frameId] != undefined) {
            this.loading = false;
            response.data[this.frameId].filter((value) => {
              let seriesIndex = this.itemsList.findIndex(
                (item) => item.index === value.index
              );
              const data = [];
              const time = [];
              const color = [];
              Object.keys(value.dataGC).map((key, index) => {
                const valueArr = value.dataGC[key].split("_");
                data[index] = valueArr[0];
                color[index] = valueArr[1];
                time[index] = key;
              });

              if (seriesIndex !== -1)
                this.itemsList[seriesIndex].dataStr = data[data.length - 1] !== undefined &&  data[data.length - 1] !== null ? data[data.length - 1] : this.itemsList[seriesIndex].dataStr;
                this.itemsList[seriesIndex].time = time[time.length - 1] !== undefined &&  time[time.length - 1] !== null ? time[time.length - 1] : this.itemsList[seriesIndex].time;
                this.itemsList[seriesIndex].dataColor = color[color.length - 1] !== undefined &&  color[color.length - 1] !== null ? color[color.length - 1] : this.itemsList[seriesIndex].dataColor;
                this.itemsList[seriesIndex].isDeviceTS = value.isDeviceTS
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-body-style {
  display: flex;
  flex-wrap: wrap;
  container-type: inline-size;
}
.item-children {
  padding: 0 10px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 325px;
  max-width: 100%;
}
@container (inline-size > 650px) {
  .item-children {
    max-width: 50%;
  }
}
@container (inline-size >= 992px) and (inline-size < 1300px) {
  .item-children {
    max-width: 33.33%;
  }
}
@container (inline-size > 1300px) {
  .item-children {
    max-width: 25%;
  }
}

.container-fluid .vue-grid-layout .vue-grid-item:not(.vue-grid-placeholder) {
  min-height: 100%;
}

.container-fluid .vue-grid-layout .vue-grid-item.minMax {
  font-size: 12px;
}

.min-h-130 {
  min-height: 96px;
}

$min-height-resize: 980px;

.weather-chart-height-resize {
  @if $min-height-resize > 980px {
    .col-12 {
      rotate: x;
    }
  } @else {
    .row {
      rotate: y;
    }
  }
}




.card-iphone {
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.text-color-3BA272 {
  color: #3BA272;
}
.text-color-FAC858 {
  color: #FAC858;
}
.text-color-EE6666 {
  color: #EE6666;
}
.text-color-376CA5 {
  color: #376CA5;
}

.inner {
  text-align: left !important;
}
.icon-GC {
  position: absolute !important;
  right: 1rem !important;
  top: 1rem !important;
}


</style>
