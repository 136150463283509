// eslint-disable-next-line no-unused-vars

import {login, logout} from "@/api/auth";
import {removeMenuAccesses, removeToken, setMenuAccesses, setToken} from "@/utils/auth";
import {CONSTANTS} from "@/utils/constant";

const actions = {
    login({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            login(payload)
                .then(response => {
                    const data = response.data;
                    if (data.code === CONSTANTS.SUCCESS) {
                        setToken(data.token); // set token after login
                    }

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    logout({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            logout(payload)
                .then(response => {
                    const data = response.data;
                    if (data.code === CONSTANTS.SUCCESS) {
                        removeToken(data.token); // set token after login
                        removeMenuAccesses(data.menu_access); // set roles after login
                    }

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

