import { render, staticRenderFns } from "./MailModal.vue?vue&type=template&id=5389b31e&scoped=true"
import script from "./MailModal.vue?vue&type=script&lang=js"
export * from "./MailModal.vue?vue&type=script&lang=js"
import style0 from "./MailModal.vue?vue&type=style&index=0&id=5389b31e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5389b31e",
  null
  
)

export default component.exports