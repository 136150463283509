<template>
  <v-dialog v-model="showDialog" width="700px" persistent scrollable>
    <v-card @drop.prevent="onDrop" @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false" :class="{ 'grey lighten-2': dragover }"
            style="overflow-y:hidden !important;overflow-x:hidden !important;">
      <v-card-title class="bg-gray-light d-flex">
        <span class="text-h5">{{ $t('fileManager.uploadFile') }}</span>
        <v-btn @click="closeDialog" class="ml-auto" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-1">
        <v-container class="pt-0 pb-0">
          <v-progress-linear
            :active="show"
            v-model="progress"
            indeterminate
            rounded
            color="primary" height="10">
          </v-progress-linear>
          <!-- <div class="row">
              <div class="col-xl-12 col-sm-12 pb-0"> -->
          <v-row style="height: 200px;" class="d-flex flex-column" dense align="center" justify="center">
            <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              mdi-cloud-upload
            </v-icon>
            <p style="text-align:center !important;">
              {{ $t('fileManager.msgDropFile') }}
            </p>
            <div class="row justify-content-center">
              <v-file-input
                multiple
                no-tittle
                hidden
                show-size
                counter
                outlined dense v-model="uploadedFiles"
                v-on:change="handleCheckFileSize($event)"
                :label="$t('fileManager.upload')"
              ></v-file-input>
            </div>
          </v-row>
          <v-card
            elevation="2"
            max-width="600"
            class="mx-auto"
          >
            <v-virtual-scroll v-if="uploadedFiles.length > 0" :items="uploadedFiles" height="150"
                              :bench="0"
                              item-height="50">
              <template v-slot:default="{ item }">
                <v-list-item :key="item.name" class="m-2">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>{{ item.name }}</strong>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-on:click.stop="removeFile(item.name)" small> mdi-close-circle</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <!--                <v-divider></v-divider>-->
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="width: 100px;background-color: rgb(61, 118, 173);" @click.prevent="handleUploadFile">
          <v-icon id="upload-button" style="color: #fff;">mdi-upload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/utils/eventBus";
import {mapActions} from "vuex";
import {getCookieSiteUser} from "@/utils/auth";

export default {
  name: "UploadFileModal",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    visible: {},
    titleModal: {}
  },
  async beforeMount() {
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/files/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/files/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
  },
  data() {
    return {
      ERROR_CODE: null,
      dragover: false,
      uploadedFiles: [],
      showDialog: false,
      progress: 0,
      show: false,
      loading: false
    }
  },
  created() {
    EventBus.$on('EmitOpenUploadFileModal', value => {
      this.showDialog = value;
    });
  },
  methods: {
    ...mapActions('file', {
      uploadFile: 'uploadFile'
    }),
    closeDialog() {
      this.showDialog = false;
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        file => file.name === fileName
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    onDrop(e) {
      e.preventDefault();
      this.dragover = false;
      this.uploadedFiles = [];
      if (e.dataTransfer.files.length > 0) {
        for (let u =0; u < e.dataTransfer.files.length; u++){
          this.uploadedFiles.push(e.dataTransfer.files[u]);
        }
        this.handleCheckFileSize(e.dataTransfer.files);
      }
    },
    handleUploadFile: async function () {
      if (this.uploadedFiles.length === 0) {
        this.commonErrorVue(this.ERROR_CODE['E_070_05']);
        return;
      }

      this.loading = true
      this.show = true
      const siteId = getCookieSiteUser();
      let formData = new FormData();

      for (let i = 0; i < this.uploadedFiles.length; i++) {
        let file = this.uploadedFiles[i];
        await formData.append(`files[${i}]`, file);
      }

      this.uploadFile({data: formData, siteId: siteId}, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }).then((response) => {
        if (response.code === 0) {
          EventBus.$emit('EmitGetFileList', true);
          EventBus.$emit('EmitGetBucketSize', true);
          this.commonConfirmOKVue(this.ERROR_CODE["IMPORT_SUCCESFULLY"]);
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
        }
      }).catch((error) => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
      }).finally(() => {
        this.uploadedFiles = [];
        this.show = false;
        this.loading = false;
        this.showDialog = false;
      })
    },
    handleCheckFileSize($event) {
      if ($event.length > 0) {
        let allFilesSize =0;
        Array.from($event).forEach(file => {
          // All files is over 100MB
          allFilesSize += file.size;
          if (allFilesSize > 100 * 1000 * 1000) {
            this.commonErrorVue(this.ERROR_CODE['E_070_03']);
            this.uploadedFiles = [];
          }
          //Each file is over 100MB
          if (file.size > 100 * 1000 * 1000) {
            this.commonErrorVue(this.ERROR_CODE['E_070_03']);
            this.uploadedFiles = [];
          }
          // Check if empty file or folder
          if (file.size == 0){
            this.commonErrorVue(this.ERROR_CODE['E_070_06']);
            this.uploadedFiles = [];
          }
        })
      }
    }
  },
  watch: {
    '$i18n.locale': async function () {
      const currentLang = this.$i18n.locale
      await import(`@/utils/errors/files/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/files/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.v-dialog--active {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-dialog__content--active {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.checkboxLabel {
  margin-top: 8px !important;
  font-weight: 100;
  color: black;
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
