import request from "../utils/request";
export function getCbbSiteStation(payload) {
    return request({
        url: "/Station/getCbbStation",
        method: "post",
        data: payload
    });
}

export function getCbbStation(payload) {
    return request({
        url: "/Station/getCbbStation",
        method: "post",
        data: payload
    });
}
export function getCbbSiteDeviceByStation(payload) {
    return request({
        url: "/Station/getCbbDeviceByStation",
        method: "post",
        data: payload
    });
}
export function getCbbDeviceData(payload) {
    return request({
        url: "/Station/getCbbDeviceData",
        method: "post",
        data: payload
    });
}
export function getCbbStationTSByDevice(payload) {
    return request({
        url: "/Station/getCbbStationTSByDevice",
        method: "post",
        data: payload
    });
}
export function getCbbStationTSByFrameId(payload) {
    return request({
        url: `/Station/getCbbStationTSByFrameId/${payload}`,
        method: "get",
    });
}
export function getCbbOrgInfo(payload) {
    return request({
        url: "/Station/getCbbOrgInfo",
        method: "post",
        data: payload
    });
}
export function getCbbOrgInfoBySokGr(payload) {
    return request({
        url: "/Station/getCbbOrgInfoBySokGr",
        method: "post",
        data: payload
    });
}
