<template>
    <v-dialog v-model="showDialogChannel" max-width="1500px" persistent scrollable>
      <v-card>
        <v-card-title class="bg-gray-light">
          <span class="text-h5" style="font-size: 1.2rem !important;">{{ $t("device600.popupTitle") }}</span>
          <v-btn class="ml-auto" icon @click.prevent="handleConfirmCloseModal">
            <v-icon id="close-button">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px;">
          <v-container style="max-width:1435px !important;" class="pt-0 pb-0">
            <div class="row mt-0">
            <div class="col-12">
              <div class="d-flex justify-content-start ">
                <!-- <h6 class="mb-2 label-black">{{ $t("labels.siteManager.stationAndDevice") }}</h6> -->
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-data-table :headers="header600Computed" :items="dataList600" sort-by="calories" item-key="index"
                  class="elevation-2" mobile-breakpoint="0" fixed-header locale="ja" hide-default-header hide-default-footer :footer-props="{
                    'items-per-page-options': [200]
                  }">
                  <template slot="no-data">
                    {{ $t("notification.noData") }}
                  </template>
                  <template #header="{ props: {} }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in header600Computed" :key="header.value" scope="col">
                          {{ $t(header.text) }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:top v-if="!checkDetail">
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" style="background-color: #3d76ad"
                        :disabled="!isFullDataLineTable" @click="createItem">
                        <v-icon color="white" size="20" class="mr-2 dark mt-0">
                          mdi-plus-box
                        </v-icon>
                        <span style="font-size: 17px" class="font-weight-bold">{{
                            $t("labels.add")
                        }}</span>
                      </v-btn>
                    </v-toolbar>
                  </template>
                    <template v-slot:[`item.no`]="{item}">
                      {{item.no}}
                    </template>
                    <template v-slot:[`item.channelNo`]="{item}">
                    <div class="width-150" style="margin: 5px">
                        <v-text-field maxlength="5" v-model="item.channelNo" outlined dense hide-details="true" :disabled="!item.isEdit || item.channelCd !== null" :rules="channelNoRules"
                        @keypress="isDecimalNumber($event)"
                        ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.channelName`]="{item}">
                    <div class="width-150">
                        <v-text-field maxlength="50" v-model="item.channelName" outlined dense hide-details="true" :disabled="!item.isEdit"
                        :rules="requiredRule(item.channelName, 'device600.channelName')"
                        ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.zeroBalance`]="{item}">
                    <div class="width-150">
                      <v-text-field maxlength="15" v-model="item.zeroBalance" outlined dense hide-details="true" :disabled="!item.isEdit"
                                    @keypress="isDecimalNumber($event)"
                      ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.calibrationFactor`]="{item}">
                    <div class="width-150">
                      <v-text-field maxlength="15" v-model="item.calibrationFactor" outlined dense hide-details="true" :disabled="!item.isEdit"
                                    @keypress="isDecimalNumber($event)"
                      ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.correctionFactor`]="{item}">
                    <div class="width-150">
                      <v-text-field maxlength="15" v-model="item.correctionFactor" outlined dense hide-details="true" :disabled="!item.isEdit"
                                    @keypress="isDecimalNumber($event)"
                      ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.dataOffset`]="{item}">
                    <div class="width-150">
                      <v-text-field maxlength="15" v-model="item.dataOffset" outlined dense hide-details="true" :disabled="!item.isEdit"
                                    @keypress="isDecimalNumber($event)"
                      ></v-text-field>
                    </div>
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                    <div v-if="!item.isNew && !item.isEdit" style="width:120px !important;;">
                        <button v-if="deviceCode == 700 || deviceCode == '700'" class="m-1" @click.prevent="openChannelDetailModel(item)">
                          <font-awesome-icon class="fas text-success fa-lg" icon="fa-solid fa-file-medical" />
                        </button>
                        <button :disabled="isManipulating" class="m-1" @click.prevent="onClickEditLine(item)">
                        <i class="fas fa-pencil-alt text-warning fa-lg pt-2 pb-2">
                        </i>
                        </button>
                        <button :disabled="isManipulating" class="m-1" @click.prevent="handleRemoveChannel(item)">
                        <i class="fas fa-trash-alt text-danger fa-lg pt-2 pb-2">
                        </i>
                        </button>
                    </div>
                    <div v-else-if="item.isNew || item.isEdit" style="width:120px !important;">
                        <button class="m-1" @click.prevent="handleSaveDataChannel(item)">
                        <i class="fas fa fa-check-circle text-info fa-lg pt-2 pb-2">
                        </i>
                        </button>
                        <button class="m-1" @click.prevent="onClickCloseEditLine(item)">
                        <i class="fas fa-times-circle text-danger fa-lg pt-2 pb-2">
                        </i>
                        </button>
                    </div>
                    </template>

                </v-data-table>
              </v-form>
            </div>
          </div>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
          {{ $t("labels.siteManager.close") }}
        </v-btn>
      </v-card-actions>
        </v-card>

        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
        <ChannelDetailModal @handleGetChannelList="handleGetListChannel"/>

        </v-dialog>

        </template>
        <script>
        import { EventBus } from '@/utils/eventBus.js';
        import { mapActions } from 'vuex';
        import moment from "moment";
        import ChannelDetailModal from "@/modals/Site/ChannelDetailModal";
        import { checkRegexSpaceAndSpecialCharacter, validEmail, validPhone } from "@/utils/validate";
        import $ from 'jquery';
        import {ResponseCode, NullInt } from "@/constants/constants"
        import _ from 'lodash';

        export default {
          name: "ChannelInfoModal",
          props: ['visible'],
          components: { ChannelDetailModal },
          data() {
            return {
              modelChannelDetail: false,
              valid: false,
              loading: false,
              showDialogChannel: false,
              checkDetail: false,
              ERROR_CODE: '',
              //status 0: new, 1: update, 2: delete
              listTable: [],
              siteId: null,
              siteStationId: null,
              deviceCode: null,
              ip: null,
              port: null,
              channelDefault: {
                  no:'1',
                  channelCd: null,
                  channelNo: null,
                  channelName: null,
                  zeroBalance: null,
                  calibrationFactor: null,
                  correctionFactor: null,
                  dataOffset: null,
                  isNew: true,
                  isEdit: true,
              },
              dataList600: [],
              selectedChannel: null,
              backUpItemsSettings: [],
              isManipulating: false,
              requiredRule(value, label) {
                const errorMsg = [this.$t(label) + this.ERROR_CODE['IS_REQUIRED']];
                if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
                  return errorMsg
                } else {
                  return [];
                }
              },
              channelNoRules: [
                (value) => !!value || "",
                (value) => /^\d+$/.test(value)||'This field only accept numbers',
              ]

            }
          },
          updated() {
            if (this.$i18n.locale !== this.currentLocale) {
              this.currentLocale = this.$i18n.locale;
            }
          },
          beforeDestroy() {
            this.loading = false;
          },
          computed: {
            isFullDataLineTable() {
              if (this.dataList600.length > 0) {
                const item = this.dataList600[this.dataList600.length - 1];
                if(item.isNew){
                  return false
                }
                return !this.dataList600.some(item => item.isEdit)
              }
              return true;
            },
            header600Computed() {
              return [
                { text: '#',align: 'start',sortable: false,value: 'no'},
                { text: this.$t('device600.channelNo'),align: 'start',sortable: false,value: 'channelNo',},
                { text: this.$t('device600.channelName'), value: 'channelName' },
                { text: this.$t('device600.zeroBalance'), value: 'zeroBalance' },
                { text: this.$t('device600.calibrationFactor'), value: 'calibrationFactor' },
                { text: this.$t('device600.correctionFactor'), value: 'correctionFactor' },
                { text: this.$t('device600.dataOffset'), value: 'dataOffset' },
                { text: this.$t('genba.table.action'), value: 'action', align: 'center' },
              ]
            }
          },
          watch: {
            '$i18n.locale': async function (currentLang) {

              // ========================================================== start import ERROR_CODE by language
              // ==============================================================================================
              await import(`@/utils/errors/site/${currentLang}.js`)
                .then((DATA) => {
                  this.ERROR_CODE = DATA.ERROR_CODE
                })
                .catch(async () => {
                  await import(`@/utils/errors/site/${currentLang}.js`)
                    .then((DATA) => {
                      this.ERROR_CODE = DATA.ERROR_CODE
                    })
                })
              // ==============================================================================================
              // =========================================================== stop import ERROR_CODE by language
            },
          },
          async beforeMount() {
            // ========================================================== start import ERROR_CODE by language
            // ==============================================================================================
            const currentLang = this.$i18n.locale
            await import(`@/utils/errors/site/${currentLang}.js`)
              .then((DATA) => {
                this.ERROR_CODE = DATA.ERROR_CODE
              })
              .catch(async () => {
                await import(`@/utils/errors/site/${currentLang}.js`)
                  .then((DATA) => {
                    this.ERROR_CODE = DATA.ERROR_CODE
                  })
              })

            // ==============================================================================================
            // =========================================================== stop import ERROR_CODE by language
          },
          beforeCreate() {
            this.loading = true;
          },
          created() {
            EventBus.$on('handleOpenChannelModel', (value) => {
              this.handleGetListChannel(value.siteId, value.siteStationId, value.deviceCode);
            //   if (value.createFlg) {
            //     this.optionsStatus = this.optionsStatus.filter(x => x.value !== 3);
            //     this.optionsStatusView = this.optionsStatusView.filter(x => x.value !== 3);
            //   } else if (value.checkDetail || value.editFlag) {
            //     this.handleGetSiteDetails(value);
            //   }
              this.showDialogChannel = value.flag;
              this.siteId = value.siteId;
              this.siteStationId = value.siteStationId;
              this.deviceCode = value.deviceCode;
              this.ip = value.ip;
              this.port = value.port;
              if (value.flag) {
                $('#main-header').css("pointer-events", "none");
              } else {
                $('#main-header').css("pointer-events", "");
              }
            //   this.checkDetail = value.checkDetail;
            //   this.nameLabel = value.nameLabel;
            //   this.editFlag = value.editFlag;
            //   this.createFlg = value.createFlg;
            //   this.$refs.form?.resetValidation();
            //   this.$refs.form2?.resetValidation();
            });
          },
          mounted() {
          },
          methods: {
            ...mapActions("sites", {
              saveChannel: "saveChannel",
              getListChannel: "getListChannel",
              deleteChannel: "deleteChannel"
            }),
            ...mapActions("device", {
              getCbbDevice: "getCbbDevice"
            }),
            isDecimalNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
              } else {
                return true;
              }
            },
            createItem() {
              this.isManipulating = true;
              var newItem = {
                no: this.dataList600.length + 1,
                channelCd: null,
                channelNo: null,
                channelName: null,
                zeroBalance: null,
                calibrationFactor: null,
                correctionFactor: null,
                dataOffset: null,
                isNew: true,
                isEdit: true,
              }
              this.dataList600.push(newItem);
            },
            onClickEditLine(item){
              this.isManipulating = true;
              let itemSelected = this.dataList600.findIndex(x=> x.no == item.no);
              this.selectedChannel = this.dataList600[itemSelected];
              this.dataList600[itemSelected].isEdit = true;
              this.$set(this.dataList600, itemSelected, this.dataList600[itemSelected]);
            },
            onClickCloseEditLine(item){
              let itemSelected = this.dataList600.findIndex(x=> x.no == item.no);
              if (item.isEdit && this.selectedChannel !== null) {
                var oldData = this.backUpItemsSettings[itemSelected];
                if(oldData && oldData.isEdit) oldData.isEdit = false;
                this.$set(this.dataList600, itemSelected, oldData);
              }
              if (item.isNew) {
                this.dataList600.splice(itemSelected, 1);
              }
              this.isManipulating = false;
            },
            handleConfirmCloseModal() {
              if (this.checkDetail === false) {
                this.commonConfirmVue(this.ERROR_CODE['CF_052_01_01'], () => {
                  this.showDialogChannel = false
                  $('#main-header').css("pointer-events", "");
                  this.handleResetFormItem();
                });
              } else {
                this.showDialogChannel = false;
                $('#main-header').css("pointer-events", "");
                this.handleResetFormItem();
              }
            },
            handleResetFormItem(){
              this.dataList600 = [];
            },
            handleGetListChannel(siteId, siteStationId, deviceCode){
              this.isManipulating = false;
              this.loading = true;
              const request = { siteId: siteId,siteStationId: siteStationId,deviceCode: deviceCode };
              this.getListChannel(request).then((response) => {
                  if (response.code === ResponseCode.SUCCESS) {
                    this.dataList600 = response.data;
                    if (response.data !== null && response.data.length > 0) {
                      for (let i = 0; i < this.dataList600.length; i++) {
                        this.dataList600[i].no = i + 1;
                        this.dataList600[i].isEdit = false;
                        this.dataList600[i].isNew = false;
                      }
                    }
                    Object.assign(this.backUpItemsSettings, this.dataList600)
                    this.backUpItemsSettings = _.cloneDeep(this.dataList600)
                    var newItem = {
                      no: 1,
                      channelCd: null,
                      channelNo: null,
                      channelName: null,
                      zeroBalance: null,
                      calibrationFactor: null,
                      correctionFactor: null,
                      dataOffset: null,
                      isNew: true,
                      isEdit: true,
                    }
                    if(response.data.length === 0) this.dataList600.push(newItem);
                  }
                  }).catch((error) => {
                  }).finally(() => {
                    this.loading = false;
                  })
            },
            validateChannel(item){
              let itemSelected = this.dataList600.findIndex(x=> x.no == item.no);
              if (this.dataList600[itemSelected].channelNo === undefined || this.dataList600[itemSelected].channelNo === null || this.dataList600[itemSelected].channelNo === '') {
                this.commonErrorVue(`${this.$t('device600.channelNo')} ${this.ERROR_CODE['IS_REQUIRED']}`);
                return false;
              }
              if (this.dataList600[itemSelected].channelName === undefined || this.dataList600[itemSelected].channelName === null || this.dataList600[itemSelected].channelName === '') {
                this.commonErrorVue(`${this.$t('device600.channelName')} ${this.ERROR_CODE['IS_REQUIRED']}`);
                return false;
              }
              return true;
            },
            handleSaveDataChannel(item){
              this.$refs.form.validate();
              if(!this.validateChannel(item)) return;
              this.loading = true;
              this.saveChannel({...item, siteId: this.siteId, siteStationId: this.siteStationId, deviceCode: this.deviceCode, ip: this.ip, port: this.port}).then((response) => {
                  if (response.code === ResponseCode.SUCCESS) {
                    this.commonNotifyVue(this.ERROR_CODE['SAVE_OK'], 'success');
                    var index = this.dataList600.findIndex(x=> x.no == item.no);
                    this.dataList600[index].isEdit = false;
                    this.$set(this.dataList600,index, this.dataList600[index]);
                    this.handleGetListChannel(this.siteId, this.siteStationId, this.deviceCode)
                  } else {
                    this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                  }
                }).catch((error) => {
                  if(error.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
                }).finally(() => {
                  this.loading = false;
                })
              // item.isEdit = false
            },
            handleRemoveChannel(item) {
              let itemSelected = this.dataList600.findIndex(x => x.no === item.no);
              this.commonConfirmVue(this.ERROR_CODE['CF_063_01_02'], () => {
                this.deleteChannel({...item}).then((response) => {
                  if (response.code === ResponseCode.SUCCESS) {
                    this.commonNotifyVue(this.ERROR_CODE['DELETE_SUCCESS'], 'success');
                    this.handleGetListChannel(this.siteId, this.siteStationId, this.deviceCode)
                  } else {
                    this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
                  }
                }).catch((error) => {
                  if(error.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
                }).finally(() => {

                })
              });
            },
            openChannelDetailModel(item) {
              if((item.zeroBalance === undefined || item.zeroBalance === null || item.zeroBalance === '') || (item.calibrationFactor === undefined || item.calibrationFactor === null || item.calibrationFactor === '')){
                this.commonNotifyVue(this.ERROR_CODE['SHOW_POPUP_ERROR'], 'warning');
                return;
              }
              EventBus.$emit('handleOpenChannelDetailModel', {
                flag: true,
                channelName: item.channelName,
                // checkDetail: false,
                // nameLabel: "labels.siteManager.create",
                // editFlag: false,
                // createFlg: true,
                siteId: this.siteId,
                siteStationId: item.siteStationId,
                deviceCode: item.deviceCode,
                channelNo: item.channelNo,
                channelCd: item.channelCd
                // ip: item.ip,
                // port: item.port
              });
              this.modelChannelDetail = true;
              $('#main-header').css("pointer-events", "none");
            },
          }
        }
        </script>

<style lang="scss" scoped>

.v-card__actions {
  border-top: 1px solid #ebebeb;
}

.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 100px;
  left: 0;
}

.label-black {
  color: black !important;
}

.h-text-danger {
  height: 2px !important;
}

.v-input--has-state fieldset {
  border: 2px solid red !important;
}

.v-messages__message {
  color: red !important;
  padding-left: 0px !important;
  font-size: 15px !important;
}

.input-port input[type='number'] {
  -moz-appearance: textfield;
}

.input-port input::-webkit-outer-spin-button,
.input-port input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.width-150{
  width: 150px !important;
}
::v-deep{
  .v-card{
    margin-top: 70px;
  }
  @media (max-width: 220px) {
    .v-card{
      margin-top: 100px
    }
  }
  @media (max-width: 500px) and (max-height: 896px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    font-size: 1rem !important;
    height: 26px;
    padding: 0px 6px !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 1rem !important;
    height: 27px !important;
    padding: 0px 6px !important;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin-left: 4.55rem !important;
  }
  .v-data-table>.v-data-table__wrapper>table {
    width: 1300px !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 0.8rem !important;
      height: 26px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.2rem !important;
      height: 27px !important;
      padding: 0px 6px !important;
    }
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      font-size: 1rem !important;
      height: 52px;
      padding: 0px 6px !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
      font-size: 1.5rem !important;
      height: 54px !important;
      padding: 0px 6px !important;
    }
  }
}
::v-deep{
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
