// eslint-disable-next-line no-unused-vars
import { getCbbDevice, getDeviceByFrameId } from "@/api/device";

const actions = {
    getCbbDevice({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getCbbDevice(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getDeviceByFrameId({ commit, state }, frameId) {
        return new Promise((resolve, reject) => {
            getDeviceByFrameId(frameId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

