<template>
  <div class="wrappers bg-gray-light">
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>404 Error Page</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a>Home</a></li>
                  <li class="breadcrumb-item active">404 Error Page</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="error-page">
            <div class="error-content">
              <h1 class="headline text-warning" style="font-size: 100px !important;"><strong>404</strong></h1><br>
              <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
              <p>
                We could not find the page you were looking for.
                Meanwhile, you may <a href="../../admin">return to dashboard</a> or try using the search form.
              </p>
              <form class="search-form">
                <div class="input-group">
                  <div class="input-group-append">

                  </div>
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>
      <FooterPage></FooterPage>
    </div>
  </div>
</template>

<script>
import FooterPage from "@/components/Footer";

export default {
  name: 'NotFoundPage',
  components: {FooterPage},

};
</script>
