<template>
  <div class="wrapper login-wrapper">
    <v-app-bar style="position: absolute; background-color: rgb(7, 97, 173) !important;">
<!--      <v-app-bar-nav-icon class="d-flex justify-content-start">-->
<!--        <v-toolbar-title class="white&#45;&#45;text d-flex">-->
<!--        </v-toolbar-title>-->
<!--      </v-app-bar-nav-icon>-->
      <v-spacer></v-spacer>
      <div class="d-flex justify-content-end align-content-end align-items-end">
        <v-select class="mt-lg-4 font-weight-bold multi-language-select"
                  @change="handleActionChangeLang(getCurrentLocale)" :items="getAllLocales"
                  v-model="locale"
                  key="code" item-value="code" style="width: 150px;" dense outlined light>
          <template v-slot:[`item`]="{ item }">
                        <span class="large"><i class="flag-icon ms-1" :class="item.flag"
                                               style="width: 32px; height: 24px">
                        </i> {{ $t(item.name) }}</span>
          </template>
          <template #selection="{ item }">
                        <span class="large text-white "><i class="flag-icon mr-2 flag-icon-ipad" :class="item.flag"
                                                           style="font-size: xxx-large">
                        </i> </span>
          </template>
        </v-select>
      </div>
    </v-app-bar>
    <div class="hold-transition background-full-screen container login-content">
      <div class="row align-items-center ">
        <div class="col-sm-12 mx-auto d-flex justify-content-center">
          <img v-if="$i18n.locale === 'jp'" class="logo" style="width:30%;height:30%;margin-top: 50px;"
               src="@/assets/img/top_jp.png"
               alt="">
          <img v-else class="logo" style="width:30%;height:30%;margin-top: 50px;" src="../assets/img/top_en.png" alt="">
        </div>
        <div class="col-md-8 mx-auto modal-ipad modal-iphone mb-5 mt-10">
          <div class="card h-100 d-flex justify-content-center">
            <div class="card-header">
              <div class="login-logo">
                <h1 class="mx-auto _fw-500 text-font-weight-bold mt-2 mb-0 justify-content-center align-items-center login-title">
                  {{ $t('login.title') }}
                </h1>
              </div>
            </div>
            <div class="card-body login-card-body " style="border-radius: 2px !important;">
              <div ref="form">
                <div class="col-12">
                  <p class="text-font-weight-bold font-size-iphone mb-0">{{ $t('login.userIDLabel') }}</p>
                  <div class="input-group input-group-lg">
                    <v-text-field hide-details="auto" outlined dense v-model="loginForm.userId"
                                  v-on:keydown.enter.prevent="handleLogin"
                                  maxlength="20"
                                  ref="username" :autofocus="true"
                                  append-icon="fas fa-user-alt"></v-text-field>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <p class="text-font-weight-bold font-size-iphone mb-0">{{ $t('login.passwordLabel') }}</p>
                  <div class="input-group input-group-lg">
                    <v-text-field hide-details="auto"
                                  :type="showPassword ? 'text' : 'password'"
                                  maxlength="30"
                                  :append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                  @click:append="showPassword = !showPassword"
                                  outlined dense v-model="loginForm.password"
                                  v-on:keydown.enter.prevent="handleLogin"
                                  ref="password"
                    ></v-text-field>
                  </div>
                </div>
                <div class="row login-remember-iphone">
                  <div class="col-sm-12">
                    <div class="form-control-sm input-group-prepend d-flex align-content-start remember-me">
                      <input type="checkbox" class="mr-2" style="height: 20px; width: 20px" v-model="rememberMe">
                      <label class="font-size-remember-iphone" style="font-size:15px">
                        {{ $t('login.remember_me') }}
                      </label>
                    </div>
                    <div class="input-group-append ml-2">
                      <p class="font-weight-thin font-size-remember-iphone" style="font-size:15px">
                        ※{{ $t('login.notes') }}
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block p-1 login-button" @click.prevent="handleLogin"
                            ref="loginButton" @keyup.enter="handleLogin">
                      <i class="fas"
                         v-bind:class="{'fa-unlock': !isSubmitAction, 'fa-refresh fa-spin' : isSubmitAction}"></i>
                      <label style="color: #f8f9fa; text-align: center" class="ml-2 mt-1">{{
                          $t('login.login_btn')
                        }}</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterPage class="footer"></FooterPage>
  </div>
</template>
<script>
import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  getToken,
  setRememberKCloudPass,
  setRememberKCloudID,
  setRememberMe,
  getRememberKCloudPass,
  getRememberKCloudID, getRememberMe, setCookieUserType, setMenuAccesses,
  setCookieSiteUser, setCookiePrivileges
} from "@/utils/auth";
import {mapActions} from 'vuex'
import FooterPage from "@/components/Footer.vue";
import sidebarLinks from "@/sidebarLinks";
import moment from "moment";
// import LanguagesDropdown from "@/modules/header/languages";

const i18nConfig = require('../i18n.lang')
export default {
  name: "NewLoginPage",
  components: {FooterPage},
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    }
  },
  created() {
    // const availableLocales = this.$i18n.availableLocales;
    // const usersLanguage = window.navigator.language
    //
    // availableLocales.find(value => {
    //   if (value === usersLanguage.substring(0, 2)) {
    //     this.$i18n.locale = usersLanguage.substring(0, 2);
    //   }
    // })

    // coding continue ...
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }

    const rememberChecked = getRememberMe();

    if (parseInt(rememberChecked) === 1) {
      this.rememberMe = true;
      this.loginForm.userId = getRememberKCloudID();
      // this.loginForm.password = getRememberKCloudPass();
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/auth/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/auth/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  async mounted() {
    await this.$refs.username.focus();
    await this.$refs.loginButton.focus();
  },
  data() {
    return {
      classLocale: '',
      ERROR_CODE: '',
      isSubmitAction: false,
      loginForm: {
        userId: "",
        password: "",
        skip_login: false
      },
      usernameInputClass: '',
      rememberMe: false,
      showPassword: false,
      locale: this.$i18n.locale,
      sidebarMenu: sidebarLinks,
    }
  },
  methods: {
    ...mapActions("auth", {
      login: "login"
    }),
    handleLogin() {
      if (getToken()) {
        this.$router.push({path: '/admin'});
        return;
      }
      if (this.loginForm.userId === '') {
        this.$refs.username.focus();
        this.commonErrorVue(this.ERROR_CODE["E_010_01_01"], 'warning');
        return;
      }
      if (this.loginForm.password === '') {
        this.$refs.password.focus();
        this.commonErrorVue(this.ERROR_CODE["E_010_01_02"], 'warning');
        return;
      }
      if (this.isSubmitAction) {
        return;
      }
      setRememberKCloudID(this.loginForm.userId);

      if (this.rememberMe) {
        setRememberMe(1, this.rememberMe);
        // setRememberKCloudPass(this.loginForm.password);
      }

      this.login(this.loginForm)
        .then(response => {
          if (response.code === 0) {
            if (response.data['userDetail']['userType'] !== 4) {
              setCookiePrivileges(response.data['userDetail']['privileges']['privileges'])
              setCookieUserType(response.data['userDetail']['userType']);
              this.$router.push({path: '/admin'});
            } else {
              setCookieSiteUser(response.data['userDetail']['siteId'])
              setMenuAccesses(1);
              setCookiePrivileges(response.data['userDetail']['privileges']['privileges'])
              setCookieUserType(response.data['userDetail']['userType']);
              let accessMenu = response.data['userDetail']['privileges']['privileges'];
              this.handleDirectPageUserType4(accessMenu);
              // this.$router.push({path: '/'});
            }
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'info');

          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          }
        })
        .catch(err => {
          console.log(err);
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], 'warning');
        })
        .finally(final => {

          this.isSubmitAction = false;
        });
    },
    handleDirectPageUserType4(accessMenu) {
      if (accessMenu[0].children !== undefined && accessMenu[0].children.length > 0) {
        this.$router.push({path: accessMenu[0].children[0].path});
      } else {
        this.$router.push({path: accessMenu[0].path});
      }
    },
    handleActionChangeLang(lang) {
      console.log('[handleActionChangeLang]', lang);
      this.$i18n.locale = (lang === 'jp') ? 'en' : 'jp';
      this.locale = (lang === 'jp') ? 'en' : 'jp';
      cookiesSetI18Lang(lang)
      console.log('[this.$i18n.locales]', this.$i18n.locale)
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/auth/${currentLang}.js` )
        .then((DATA) => {
          console.log('[watch]', DATA);
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/auth/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
}
</script>
<style scoped lang="scss">
.flag-icon-ipad {
  margin-top: 10px;
}

@media (max-width: 1180px) {
  .modal-ipad {
    width: 85% !important;
  }
  .flag-icon-ipad {
    font-size: 40px !important;
  }
}

@media (max-width: 820px) {
  /* For ipad 768px: */
  .login-content {
    margin-top: 30px !important;
  }
  .flag-icon-ipad {
    font-size: 35px !important;
    margin-top: 24px;
    margin-right: -1.5rem !important;
  }
  .remember-me {
    margin-bottom: 20px;
  }
  .login-remember-iphone {
    margin-left: -1px;
  }
}


@media (max-width: 540px) {
  .login-wrapper {
    min-height: auto;
    background-color: #FFFF !important;
  }
  .login-title {
    font-size: 30px !important;
  }
  .modal-iphone {
    width : 110% !important;
    margin-bottom: 20px !important; 
    margin-top: 10px !important; 
    margin-left: 0.4rem !important;
  }
  .remember-me {
    margin-bottom: 2.2rem !important;
  }
  .logo {
    width: 80% !important; 
    height: auto !important; 
    margin-top: 40px !important; 
    margin-left: 24px !important;
  }
  .modal-iphone {
    width : 100% !important;
    margin-bottom: 20px !important; 
    margin-top: 10px !important; 
  }
  .flag-icon-ipad {
    font-size: 35px !important;
    margin-top: 24px;
    margin-right: -2rem !important;
  }
  .login-content {
    margin-top: 40px !important;
  }
}

@media (max-height : 760px) {
  .login-content {
    margin-bottom: 200px !important;
  }
}

@media (max-height : 540px) {
  .login-wrapper {
    min-height: 800px !important;
    background-color: #FFFF !important;
  }
  .remember-me {
    margin-bottom: 20px;
  }
  .footer {
    display: none;
  }
  .font-size-iphone {
    font-size: 15px !important;
  }
  .font-size-remember-iphone {
    font-size: 10px !important;
  }
  .login-remember-iphone {
    margin-left: -0.5px;
  }
  .login-title {
    font-size: 20px;
  }
}


.select-custom {
  border-radius: inherit !important;
}

.background-full-screen {
  background-color: inherit !important;
  height: 94vh;
}

.text-font-weight-bold {
  font-weight: bold;
  padding-bottom: 5px;
}

::v-deep {
  .v-select__selections input {
    display: none;
  }

  .multi-language-select.v-text-field {
    width: auto !important;
  }

  .multi-language-select.v-text-field--outlined fieldset {
    border-style: none !important;
  }

  .mdi-menu-down::before {
    content: none !important;
    display: none;
  }

}

</style>
