// eslint-disable-next-line no-unused-vars
import { getReportDataByTime, exportDataToFile, getDataResponse, exportDataToFilePDF, SaveReportSetting,LoadReportSetting, LoadTsConfig, SaveTsConfig,exporDataToFileByFrame } from "@/api/report";

const actions = {
    getReportDataByTime({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            getReportDataByTime(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    exportDataToFile({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            exportDataToFile(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    exporDataToFileByFrame({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            exporDataToFileByFrame(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    exportDataToFilePDF({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            exportDataToFilePDF(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SaveReportSetting({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            SaveReportSetting(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    LoadReportSetting({commit, state}, FrameId) {
        return new Promise((resolve, reject) => {
            LoadReportSetting(FrameId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    LoadTsConfig({commit, state}, SiteId) {
        return new Promise((resolve, reject) => {
            LoadTsConfig(SiteId)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SaveTsConfig({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            SaveTsConfig(payload)
                .then(response => {
                    const data = response.data;

                    resolve(data); // resolve
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

const state = () => ({});
const getters = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

