<template>
  <div class="row">
    <div class="col-sm-12 card p-0 m-0 mb-5">
      <div class="card-body bg-gray-200 mb-3">
        <grid-layout v-if="!glbPageIsLoading" :layout.sync="layout" :col-num="colNum" :row-height="rowHeight" :margin="margin"
                     :is-draggable="false"
                     :is-resizable="false" :responsive="true" :vertical-compact="true" :use-css-transforms="true"
                     :responsive-layouts="layouts">
          <grid-item v-for="item in layout" :static="true" :x="item.x" :y="item.y" :w="item.w"
                     :h="item.h" :i="item.i" :key="item.id">
              <component :is="(item.type === 'SVG') ? 'SVGChart' : (item.type === 'MSC') ? 'MWC': item.type"
                         :key="item.frameId"
                         :frameId="item.frameId"
                         :isDashboard="true"
                         :type="item.type"
                         :title="item.title"
                         :legend.sync="item.legend"
                         :series.sync="item.series"
                         :link="item.linkIp"
                         :listTable.sync="item.listTable"
                         :yAxis="item.yAxis"
                         :xAxis="item.xAxis"
                         :width.sync="item.width"
                         :height.sync="item.height"
                         :deviceCodeProp="item.deviceCode"
                         :useCsv="item.useCsv"
                         :useExcel="item.useExcel"
                         :limitTimeProp="item.limitTime"
                         :exportTypeProp="item.exportType"
                         :selectedStationsProp="item.listStation" :channelNoProp="item.orgNo"
                         :usePdf="item.usePdf" @ChartReady="chartReady"/>
          </grid-item>
        </grid-layout>

      </div>
    </div>
    <v-overlay :value="glbPageIsLoading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
// import {GridItem, GridLayout} from "vue-grid-layout";
import WindCompass from "@/components/WindCompass/WindCompass";
import {mapActions} from "vuex";
import {cookiesGetI18Lang, cookiesSetI18Lang, getCookieSiteUser, setCurrentPageCode} from "@/utils/auth";
import GC from "@/components/Charts/GC";
import GLS from "@/components/Charts/GLS";
import LR from "@/components/Charts/LR";
import LS from "@/components/Charts/LS";
import MAC from "@/components/Charts/MAC";
import MWC from "@/components/Charts/MWC";
import SC from "@/components/Charts/SC";
import SVGChart from "@/components/Charts/SVG";
import TC from "@/components/Charts/TC";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "DashboardContent",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    WindCompass,
    // GridLayout, GridItem,
    GC,
    GLS, LR, LS, MAC, MWC, SC, SVGChart, TC,
  },
  data() {
    return {
      glbPageIsLoading: true,
      speedData: 0,
      windDirection: 0,
      layout: [],
      frameRealTimeData: null,
      draggable: true,
      resizable: true,
      responsive: true,
      index: 0,
      CURRENT_SITE: null,
      ERROR_CODE: '',
      PAGE_CODE: 'CS_DA',
      ACTION: 'OPS',
      colNum: 12,
      rowHeight: 30,
      margin: [10, 10],
      responsiveLayoutsLg: [],
      responsiveLayoutsMd: [],
      responsiveLayoutsSm: [],
      responsiveLayoutsXs: [],
    };
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  mounted() {
    this.randomWind();
    // setTimeout(() => {
    //   this.glbPageIsLoading = false;
    // }, 200);

    // setInterval(() => {
    //   this.handleGetRealtimeData();
    // }, 10000);

  },
  created() {
    if (!cookiesGetI18Lang()) {
      cookiesSetI18Lang(this.$i18n.locale)
    }
    setCurrentPageCode(this.PAGE_CODE);
    this.CURRENT_SITE = getCookieSiteUser();
    this.handleGetDashboardLayout();
    // this.startSignalR();
  },
  beforeDestroy() {
    // this.stopSignalR();
    this.layout = null;
  },
  destroyed() {
  },
  computed: {
    clientHeight() {
      return this.$vuetify.breakpoint.height;
    },
    layouts() {
      return {
        lg: this.responsiveLayoutsLg,
        md: this.responsiveLayoutsMd,
        sm: this.responsiveLayoutsSm,
        xs: this.responsiveLayoutsXs
      };
    }
  },
  methods: {
    ...mapActions('dashboard', {
      getDashboardLayout: 'getDashboardLayout',
      getRealtimeData: 'getRealtimeData',
    }),
    chartReady: function (frameId) {
      const index = this.layout.findIndex((x) => x.frameId == frameId);
      this.layout[index].isReady = true;
      if (this.layout.filter(item => {
        return item.isReady == true
      }).length == this.layout.length) {
        // this.$dashboardHub.MonitorTempRealtime(this.layout.map(lay => lay.frameId));
      }
    },
    randomWind() {
      const vm = this;
      setInterval(function () {
        vm.speedData = Math.round(Math.random() * 100);
        vm.windDirection = Math.floor(
          Math.random() * (348.75 - 11.25 + 1) + 11.25
        );
      }, 1000);
    },
    handleGetDashboardLayout() {
      this.glbPageIsLoading = true;
      this.getDashboardLayout(this.CURRENT_SITE).then((response) => {
        if (response.code === 0) {
          let responseLayout = response.data;
          let tempLayout = [];
          responseLayout.forEach(l => {
            let layoutData = this.handleInitDataChart(l.locationObject, l.order, l.title, l.frameId, l.useCsv, l.useExcel, l.usePdf);
            if (layoutData.type === 'MWC') {
              layoutData.isReady = true;
              // layoutData.w = 4
            } else {
              layoutData.isReady = false;
            }
            if (l.frameData !== undefined && l.frameData !== null && l.frameData.length > 0) {
              layoutData.listTable = l.frameData;
              layoutData.series.forEach(e => {
                let index = l.frameData.findIndex(data => data.nameEn.toLowerCase() === e.name.toLowerCase());
                if (index !== -1) e.dataCode = l.frameData[index].dataCode
              });
            }
            if (l.frameId !== undefined && l.frameId !== null) {
              layoutData.frameId = l.frameId;
            }
            if (l.deviceCode !== undefined && l.deviceCode !== null) {
              layoutData.deviceCode = l.deviceCode.toString();
            }
            // Frame Table start
            if (l.listStation) {
              layoutData.listStation = l.listStation
            }
            if (l.limitTime) {
              layoutData.limitTime = l.limitTime
            }
            if (l.exportType) {
              layoutData.exportType = l.exportType
            }
            if(l.orgNo !== null) layoutData.orgNo = l.orgNo
            // Frame Table end
            tempLayout.push(layoutData);
          });
          this.layout = cloneDeep(tempLayout);
          this.index = this.layout.length;
          this.glbPageIsLoading = false;
          this.responsiveLayoutsLg = cloneDeep(tempLayout);
          this.responsiveLayoutsMd = cloneDeep(tempLayout);
          let y = 0;
          this.responsiveLayoutsMd.forEach((element, index) => {
            element.w = 10;
            element.x = index;
            element.y = y;
            y += element.h;
          });
          y = 0;
          this.responsiveLayoutsSm = cloneDeep(tempLayout);
          this.responsiveLayoutsSm.forEach((element, index) => {
            element.w = 6;
            element.x = index;
            element.y = y;
            y += element.h;
          });
          y = 0;
          this.responsiveLayoutsXs = cloneDeep(tempLayout);
          this.responsiveLayoutsXs.forEach((element, index) => {
            element.w = 4;
            element.x = index;
            element.y = y;
            y += element.h;
          });
        } else {
          this.commonNotifyVue(this.ERROR_CODE[response.code], 'warning');
          this.glbPageIsLoading = false;
        }
      }).catch(error => {
        if(error.code != undefined)
          this.commonNotifyVue(this.ERROR_CODE[error.code], 'warning');
        this.glbPageIsLoading = false;
      })
    },
    handleInitDataChart(layout, layoutIndex, title, frameId, useCsv, useExcel, usePdf) {
      try {
        layout.locationSizeData.i = layoutIndex;
        layout.locationSizeData.title = title;
        layout.locationSizeData.id = layoutIndex;
        layout.locationSizeData['frameId'] = frameId;
        layout.locationSizeData['useCsv'] = useCsv;
        layout.locationSizeData['useExcel'] = useExcel;
        layout.locationSizeData['usePdf'] = usePdf;
        if (layout.legend !== undefined && layout.legend !== null) {
          layout.locationSizeData['legend'] = JSON.parse(layout.legend);
        }
        if (layout.series !== undefined && layout.series !== null) {
          // series.forEach((data) => {
          //   data.dataCode = layout.dataCode
          // });
          layout.locationSizeData['series'] = JSON.parse(layout.series);
        }

        if (layout.yaxis !== undefined && layout.yaxis !== null) {
          layout.locationSizeData['yAxis'] = JSON.parse(layout.yaxis);
        }
        if (layout.xaxis !== undefined && layout.xaxis !== null) {
          layout.locationSizeData['xAxis'] = JSON.parse(layout.xaxis);
        }
        if (layout.linkIp !== undefined) {
          layout.locationSizeData['linkIp'] = layout.linkIp;
        }

        return layout.locationSizeData;
      } catch (error) {
        console.log('[handleInitDataChart]', error);
        this.commonNotifyVue(this.ERROR_CODE[6699], 'warning');
      }
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
    },
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  }
};
</script>

<style lang="scss" scoped>
.container-fluid .vue-grid-layout .vue-grid-item:not(.vue-grid-placeholder) {
  min-height: 100%;
}

.container-fluid .vue-grid-layout .vue-grid-item.minMax {
  font-size: 12px;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.container-fluid .vue-grid-layout .vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  padding: 0 8px 8px 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat bottom right;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

</style>
