<template>
  <DropDown style="border-radius: 20px" nameComponent="formatSize" size="150">
    <template v-slot:dropdown-button>
        <v-icon right dark class="mr-1 outlined" :class="flagIcon">
          mdi-format-size
        </v-icon>
    </template>
    <template v-slot:dropdown-menu>
      <a
              class="dropdown-item"
              :class="{ active: formatSelected === item.title }"
              @click="menuActionClick(item)"
              v-for="(item,index) in items"
              v-bind:key="index"
      >
        {{ $i18n.locale == 'en' ? $t(item.title) : $t(item.titleJp) }}
      </a>
    </template>
  </DropDown>
</template>
<script>
import {EventBus} from "@/utils/eventBus";
import DropDown from "../../components/dropdown/dropdown.vue";
export default {
  name: "FormatSize",
  components: { DropDown },
  data: () => ({
    formatSelected: "Normal",
    globalFontsize: '',
    items: [
      {title: 'Small', titleJp: '小', value: '0.8rem'},
      {title: 'Normal', titleJp: '中', value: '1.2rem'},
      {title: 'Large', titleJp: '大', value: '1.4rem'}
    ],
  }),
  computed: {
    flagIcon: function () {
      if (this.globalFontsize === '0.8rem') {
       return 'smallSize'
      } else if (this.globalFontsize === '1.4rem') {
        return 'largeSize'
      } else {
        return 'normalSize'
      }
    },
  },
  methods: {
    menuActionClick(item) {
      this.formatSelected = item.title;
      this.globalFontsize = item.value;
      console.log(this.globalFontsize);
      EventBus.$emit('handleChangeGlobalFontSize', {size: this.globalFontsize})
    }
  },
}
</script>
<style scoped>
  .v-icon.outlined {
    border: 1px solid currentColor;
  }
  .v-icon.smallSize {
    font-size: 0.8rem;
    height: 1.3rem;
    width: 1.3rem;
  }
  .v-icon.normalSize {
    font-size: 1.2rem;
    height: 29px;
    width: 29px;
  }
  .v-icon.largeSize {
    font-size: 1.4rem;
    width: 2rem;
    height: 2rem;
  }
</style>
